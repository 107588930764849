const $ = require('jquery');
const moment = require('moment');
const SPM = require('../../service/spm');

const dateTimeFormat = 'DD/MM/YYYY HH:mm';

const DDMDataUtils = module.exports = function (options = {}) {
  this._config = options.config || window.CONFIG;
  this.maxDaysInterval = this._config.ddm.max_days_interval;
  this.maxHours = this._config.ddm.maxHoursBeforeHidingTime;
};

DDMDataUtils.prototype.getSPMData = function (harborName, beginDate, endDate, selectedSPM) {
  if (harborName === null) {
    return null;
  }
  if (!selectedSPM) {
    return [];
  }

  const tenDaysLater = moment().add(10, 'day');
  if (moment.utc(beginDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ').isAfter(tenDaysLater)) {
    return null;
  }
  const endDateVerif = moment.utc(endDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ').isAfter(tenDaysLater)
    ? tenDaysLater.toISOString()
    : endDate;
  const nbDays = Math.min(this.getNbDaysBetweenDates(beginDate, endDateVerif), this.maxDaysInterval + 1);
  const diffDay = Math.round(moment.utc(endDateVerif).diff(moment.utc(beginDate), 'minutes') / (60 * 24));
  const dataInterval = this.getDataIntervalForSPM(diffDay);

  const preParams = {};
  preParams.type = 'harbor';
  preParams.harbor = '';
  preParams.step = 1;
  preParams['constant-type'] = 'vintage';
  preParams.date = moment.utc(beginDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD');
  preParams.functions = 'wl';
  preParams.utc = 0;
  preParams.duration = nbDays;
  preParams.nbWaterLevels = 1440 / dataInterval;
  preParams.waterThreshold = 0;
  preParams.sign = 0;
  preParams.correlation = 0;
  preParams.harborName = harborName;

  const promise = $.Deferred();

  SPM({
    config: this._config
  }).then(service => {
    service.liveSPM(preParams).then(data => {
      promise.resolve(JSON.parse(data));
    })
      .fail(() => promise.resolve(null));
  }).fail(() => {
    promise.resolve(null);
  });

  return promise;
};

DDMDataUtils.prototype.convert = function (value, unit) {
  const { mantissa } = this._config.ddm.convert;
  if (unit === 'ft') {
    const converted = value * this._config.ddm.convert.rate;
    return +converted.toFixed(mantissa);
  }
  return +parseFloat(value).toFixed(mantissa);
};

DDMDataUtils.prototype.getDataInterval = function (nbJours) {
  if (nbJours <= 1) {
    return 1;
  }

  if (nbJours <= 3) {
    return 5;
  }

  if (nbJours <= 6) {
    return 10;
  }

  if (nbJours <= 10) {
    return 20;
  }

  if (nbJours <= 14) {
    return 30;
  }
  return 60;
};

DDMDataUtils.prototype.getDataIntervalForSPM = function (nbJours) {
  if (nbJours === 1) {
    return 1;
  }

  if (nbJours <= 3) {
    return 5;
  }

  if (nbJours <= 6) {
    return 10;
  }

  if (nbJours <= 10) {
    return 10;
  }

  if (nbJours <= 14) {
    return 30;
  }
  return 60;
};

DDMDataUtils.prototype.getTimeZoneDiff = function (timezone) {
  const timezoneSplitted = timezone.split('UTC');
  let time = 0;
  if (timezoneSplitted.length > 0) {
    time = +timezoneSplitted[1] | 0;
  }
  return time;
};

DDMDataUtils.prototype.setBeginTime = function (date, timezone, ignoreHours) {
  const diff = -this.getTimeZoneDiff(timezone);
  if (ignoreHours) {
    return moment.utc(date).add(diff, 'hour');
  }
  return moment.utc(date).add(diff, 'hour').minute(0).second(0)
    .millisecond(0);
};

DDMDataUtils.prototype.setEndTime = function (date, timezone, ignoreHours, startDate, maxDate) {
  if (startDate && maxDate && (date.isBefore(startDate, 'minute') || date.isAfter(maxDate, 'minute'))) {
    // can't set endDate before startDate or after maxDate
    date = maxDate;
  }
  const diff = -this.getTimeZoneDiff(timezone);
  if (ignoreHours) {
    return moment.utc(date).add(diff, 'hour');
  }
  return moment.utc(date).hour(23).add(diff, 'hour').minute(59)
    .second(59)
    .millisecond(999);
};

DDMDataUtils.prototype.getNewMaxDate = function (startDate, maxPeriod, globalMaxDate) {
  const localMaxDate = moment(startDate).add(maxPeriod, 'days').hour(23).minute(59)
    .second(59)
    .millisecond(999);
  return moment.min(localMaxDate, globalMaxDate);
};

DDMDataUtils.prototype.getNbDaysBetweenDates = function (begin, end) {
  const beginDate = moment.utc(begin, 'YYYY-MM-DDTHH:mm:ss.SSSZ').hour(0).minute(0).second(0)
    .millisecond(0);
  const endDate = moment.utc(end, 'YYYY-MM-DDTHH:mm:ss.SSSZ').hour(0).minute(0).second(0)
    .millisecond(0);
  return endDate.diff(beginDate, 'days') + 1;
};

DDMDataUtils.prototype.isShowHours = function (startDate, endDate) {
  // Displays hours if end and start are at same day, or difference between days is 2
  return Math.abs(moment.utc(startDate, dateTimeFormat).startOf('day').diff(moment.utc(endDate, dateTimeFormat).startOf('day'), 'days')) <= 1;
};

DDMDataUtils.prototype.getColor = function (src, opacity) {
  const { colors } = this._config.ddm;
  let hex = colors[`source${src + 1}`];

  if (!opacity) {
    return hex;
  }

  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r},${g},${b},${opacity})`;
};

DDMDataUtils.prototype._getAverageValue = function (data, precision) {
  precision = 10 ** (precision ? +precision : 2);
  let sum = 0;
  for (const i in data) {
    if (data.hasOwnProperty(i)) {
      sum += +data[i];
    }
  }
  return Math.round(sum / data.length * precision) / precision;
};

DDMDataUtils.prototype.processMainData = function (data, observations, timezone, verticalRef) {
  for (const i in observations.data) {
    if (observations.data.hasOwnProperty(i) && observations.data[i].idsource !== 9) {
      const sourceId = observations.data[i].idsource - 1;
      const timestamp = +this.cleanMoment(observations.data[i].timestamp).add(timezone, 'hours');

      data[sourceId].push({
        date: timestamp,
        value: Math.round((+observations.data[i].value + verticalRef) * 1000) / 1000
      });
    }
  }
  return data;
};

DDMDataUtils.prototype._getNextTimestamp = function (time, interval) {
  return +this.cleanMoment(time).add(interval, 'minutes');
};

DDMDataUtils.prototype._getChunkedData = function (data, timezoneDelta, interval, source) {
  if (data.length <= 0) {
    return {};
  }
  let iTime = +this.cleanMoment(data[0].timestamp || data[0].date).seconds(0);
  let iNextTime = this._getNextTimestamp(iTime, interval);
  // The max date we can display data. Add 11 days to get the 10th day complete
  const maxTimestamp = +moment().utc()
    .hours(0)
    .minutes(0)
    .seconds(0)
    .add(11, 'day');
  let tmpData = [];
  const chunkedData = {};
  for (const i in data) {
    if (!data.hasOwnProperty(i) || (source !== null && data[i].idsource !== source)) {
      continue;
    }

    const timestamp = +this.cleanMoment(data[i].timestamp || data[i].date).seconds(0);

    if (source === 9 && timestamp > maxTimestamp) {
      break;
    } else if (timestamp >= iNextTime) {
      if (tmpData.length > 0) {
        const timeIndex = moment.utc(iTime).add(timezoneDelta, 'hours').toISOString();
        chunkedData[timeIndex] = this._getAverageValue(tmpData, 3);
        tmpData = [];
      }
      do {
        iTime = moment.utc(iNextTime);
        iNextTime = this._getNextTimestamp(iTime, interval);
      } while (timestamp >= iNextTime);
    }

    tmpData.push(+data[i].value);
  }

  return chunkedData;
};

DDMDataUtils.prototype.isThereData = function (data) {
  for (const source in data) {
    if (data.hasOwnProperty(source) && +source !== 6 && data[source].length > 0) {
      return true;
    }
  }
  return false;
};

DDMDataUtils.prototype.isThereObsData = function (data) {
  for (const source in data) {
    if (data.hasOwnProperty(source) && +source < 6 && data[source].length > 0) {
      return true;
    }
  }
  return false;
};

DDMDataUtils.prototype.cleanMoment = function (timestamp) {
  if (typeof timestamp === 'string') {
    const cleanTimestamp = timestamp.replace(/\.[0-9]+$/, '').replace(/\sGMT\+[0-9]+$/, '');
    return moment.utc(cleanTimestamp, 'YYYY/MM/DD HH:mm:ss');
  }
  return moment.utc(timestamp);
};

DDMDataUtils.prototype.processSurcoteData = function (predictions, observations, timezone, interval, verticalRef, source) {
  predictions = this._getChunkedData(predictions, 0, interval, null);
  const observationsX = this._getChunkedData(observations.data, timezone, interval, source);
  const observationsHth = this._getChunkedData(observations.data, timezone, interval, 9);

  const data_x = [];

  for (const i in observationsX) {
    if (observationsX.hasOwnProperty(i) && predictions[i]) {
      data_x.push({
        date: +moment(i),
        value: Math.round((observationsX[i] - predictions[i] + verticalRef) * 1000) / 1000
      });
    }
  }
  const data_hth = [];

  for (const i in observationsHth) {
    if (observationsHth.hasOwnProperty(i)) {
      data_hth.push({
        date: +moment(i),
        value: Math.round((observationsHth[i]) * 1000) / 1000
      });
    }
  }

  return {
    surcote: data_x,
    surcote2: data_hth
  };
};

DDMDataUtils.prototype.getDiffFromNearestPm = function (harborName, utc, datetime, isBm) {
  if (harborName === null) {
    return null;
  }

  const preParams = {};
  preParams.harborName = harborName;
  preParams.utc = utc;
  preParams.datetime = datetime;
  preParams.isBM = isBm;

  const promise = $.Deferred();

  SPM({
    config: this._config
  }).then(
    service => service.diffFromNearestPm(preParams).then(
      data => promise.resolve(data)
    )
  ).fail(
    () => promise.resolve(null)
  );

  return promise;
};
