const _ = require('underscore');
const Layers = require('../collection/layers');
const LayerFactory = require('./layer-factory');

const LayersFactory = {};

LayersFactory.build = function (olLayers, attributes) {
  const collection = new Layers();

  _.each(olLayers, olLayer => {
    collection.add(LayerFactory.build(olLayer, attributes));
  });

  return collection;
};

module.exports = LayersFactory;
