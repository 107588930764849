const Backbone = require('backbone');
const OceanogrammeView = require('../view/oceanogramme/oceanogramme.view.js');

const OceanogrammeMenuRouter = Backbone.Router.extend({
  initialize(options) {
    options = options || {};

    this.route('oceanogramme/spot/:spot', '_openSpot');
    this.route('oceanogramme/point/:lon;:lat', '_openPoint');
    this.route('oceanogramme/buoy/:buoyCode', '_openBuoy');

    this._dynamicRenderer = options.renderer;
    this._staticRenderer = options.staticRenderer;
  },

  _openSpot(spot) {
    this._staticRenderer.hideStaticOLView();
    this._dynamicRenderer.clearAllViews();
    const oceanogrammeView = new OceanogrammeView({
      spot
    });
    this._dynamicRenderer.renderMainView(oceanogrammeView);
  },

  _openPoint(lon, lat) {
    this._staticRenderer.hideStaticOLView();
    this._dynamicRenderer.clearAllViews();
    const oceanogrammeView = new OceanogrammeView({
      lon,
      lat
    });
    this._dynamicRenderer.renderMainView(oceanogrammeView);
  },

  _openBuoy(buoyCode) {
    this._staticRenderer.hideStaticOLView();
    this._dynamicRenderer.clearAllViews();
    const oceanogrammeView = new OceanogrammeView({
      buoyCode
    });
    this._dynamicRenderer.renderMainView(oceanogrammeView);
  }
});

module.exports = OceanogrammeMenuRouter;
