import ImageLayer from 'ol/layer/Image';

import TileLayer from 'ol/layer/Tile';
import { unByKey } from 'ol/Observable.js';

const $ = require('jquery');
const _ = require('underscore');
const moment = require('moment');
const GisView = require('../gis.view');

const OceanoHelper = require('../../utils/gis/oceano-helper.js');
const OceanoNcwmsUtils = require('../../utils/oceano/oceano-ncwms.js');
const OceanoLayerUtils = require('../../utils/oceano/oceano-layer.js');
const OceanoAnimationHelper = require('../../utils/gis/oceano-animation-helper.js');
const transectCtrl = require('../../utils/gis/oceano-draw-control.js');
const OceaHudOnMapView = require('../oceano/ocea-hud-on-map.view');
const CoordsHelper = require('../../utils/gis/coordinates-helper.js');

GisView.prototype._initOceano = function () {
  this._NCWMSTileGrid = {};
  this._initOceanoHudOnMapView();
};

GisView.prototype.resetLayerGroupOptions = function (layerGroup) {
  layerGroup.set('selectedDate', null);
  layerGroup.set('selectedTime', null);
  layerGroup.set('selectedDepth', null);
  layerGroup.set('selectedUnit', null);
  layerGroup.set('selectedUtc', null);
  layerGroup.set('currentModelType', null);
};

GisView.prototype.setZonesVisibility = function (layerGroup, mapBounds, mapZoom) {
  const moduloBoundsMercator = CoordsHelper.moduloBoundsMercator(mapBounds || this.getCurrentViewBounds());
  let zoom = mapZoom || this.getZoom();
  zoom = Math.abs(zoom || 0);

  // get LayerGroup selected options if they exist - else take the default one
  const date = layerGroup.get('selectedDate');
  const time = layerGroup.get('selectedTime');

  const dateISO = (date && time) ? `${date}T${time}` : OceanoHelper.getCurrentTimeFromGroupLayer(layerGroup, zoom);
  const currentDate = dateISO.split('T')[0];
  const currentTime = dateISO.split('T')[1];
  const layers = [];

  // set parameter visible to true if layer should be displayed
  const regLayers = layerGroup.get('olLayer').getLayers().getArray();
  let newModelType;
  let nearestDateAndTime;
  let processedTime;

  for (let i = 0, len = regLayers.length; i < len; i++) {
    const regLayer = regLayers[i];
    const layerModelType = regLayer.get('modelType');

    if (OceanoHelper.isLayerIsInZoom(regLayer, zoom)) {
      processedTime = processedTime || regLayer.getLayers().getArray()[0].get('dimensions').processedTime;
      nearestDateAndTime = nearestDateAndTime || OceanoNcwmsUtils.getNearestDateAndTime(currentDate, currentTime, processedTime);

      newModelType = layerModelType;

      if (OceanoHelper.isRegionLayerIsInBounds(regLayer, moduloBoundsMercator)) {
        layers.push(regLayer);
        this.setTileLoadEventOnLayer(regLayer);
      } else {
        regLayer.set('visible', false);
      }
    } else {
      regLayer.set('visible', false);
    }
  }

  // if no nearestDateAndTime has been found, we set it to the current one
  if (!nearestDateAndTime) {
    nearestDateAndTime = {
      nearestTime: currentTime,
      nearestDate: currentDate
    };
  }

  // test if the model type has changed or the layer date and time
  const hasModelTypeChanged = this.hasModelTypeChanged(layerGroup, newModelType);
  // test if the time resolution has changed. If so, we call an event to potentially update the select date and time with the nearest time available
  const hasResolutionTimeChanged = this.hasResolutionTimeChanged(layerGroup, processedTime);
  const hasModelTimeChanged = (hasResolutionTimeChanged) ? this.hasModelTimeChanged(currentTime, currentDate, nearestDateAndTime) : false;

  const modelChanges = {
    changeModelType: hasModelTypeChanged,
    changeModelTime: hasModelTimeChanged,
    modelTypeName: newModelType
  };

  // replace the date and time because it has potentially change
  OceanoLayerUtils.setNCWMSParamsInGroupLayer(layerGroup, {
    date: nearestDateAndTime.nearestDate,
    time: nearestDateAndTime.nearestTime
  });
  layerGroup.set('selectedDate', nearestDateAndTime.nearestDate);
  layerGroup.set('selectedTime', nearestDateAndTime.nearestTime);

  // only show visible layers at the end
  for (let i = 0; i < layers.length; i++) {
    layers[i].set('visible', true);
  }

  this._NCWMSVisibleLayers = layers;
  return modelChanges;
};

GisView.prototype.hasModelTimeChanged = function (time, date, nearestDateAndTime) {
  const isSameDate = (nearestDateAndTime.nearestDate === date);
  const isSameTime = (nearestDateAndTime.nearestTime === time);

  return (!isSameDate || !isSameTime);
};

GisView.prototype.hasModelTypeChanged = function (layerGroup, modelType) {
  const currentModelType = layerGroup.get('currentModelType');

  if (!currentModelType && modelType) {
    layerGroup.set('currentModelType', modelType);
    return false;
  }

  if (modelType && (currentModelType !== modelType)) {
    layerGroup.set('currentModelType', modelType);
    return true;
  }

  return false;
};

GisView.prototype.hasResolutionTimeChanged = function (layerGroup, processedTime) {
  const currentProcessedTime = layerGroup.get('currentProcessedTime');

  if (!currentProcessedTime && processedTime) {
    this.eventsBus.trigger('change:onOceaZoomTimeChange', {});
    return false;
  }

  if (!_.isEqual(currentProcessedTime, processedTime)) {
    this.eventsBus.trigger('change:onOceaZoomTimeChange', {});
    return true;
  }

  return false;
};

GisView.prototype.needToRefreshNCWMSLayer = function (currentLayerGroup, groupToLoad) {
  return OceanoHelper.needToRefresh(currentLayerGroup, groupToLoad);
};

/** ***************************** */
/** ****** OCEANO TRANSECT ****** */
/** ***************************** */

let mouseCursorInteraction;
GisView.prototype.startTransect = function (firstHandler, finalHandler) {
  transectCtrl.start(this._map, firstHandler, finalHandler);
  mouseCursorInteraction = this._map.on('pointermove', () => {
    this._$mapTarget.css('cursor', 'crosshair');
  });
};

GisView.prototype.stopTransect = function () {
  transectCtrl.stop();
  unByKey(mouseCursorInteraction);
  mouseCursorInteraction = null;
};

/** ***************************** */
/** **** OCEANO FEATURE INFO **** */
/** ***************************** */
let clickMapHandler;
let hoverMapOverHandler;
GisView.prototype.startFeatureInfoMode = function (hoverOverHandler, clickHandler) {
  clickMapHandler = this._map.on('singleclick', clickHandler);
  hoverMapOverHandler = this._map.on('pointermove', hoverOverHandler);

  // return a destructor
  return function () {
    unByKey(clickMapHandler);
    unByKey(hoverMapOverHandler);
  };
};

GisView.prototype.addPopUp = function (lonLat, value) {
  this._closePopupFunction = this.showPopup(lonLat);
  const $popup = this.setPopupContent(value);
  return {
    closeFunc: _.bind(this.hidePopup, this),
    $popup
  };
};

GisView.prototype.getInteractions = function () {
  return this._map.getInteractions();
};

/** ***************************** */
/** ***** OCEANO ANIMATION ****** */
/** ***************************** */

GisView.prototype.animation_load = function (animationParams, animationStructure) {
  animationParams.gisview = this;
  OceanoAnimationHelper.loadAnimation(animationParams, animationStructure);
};

GisView.prototype.animation_play = function () {
  OceanoAnimationHelper.playAnimation();
};

GisView.prototype.animation_pause = function () {
  OceanoAnimationHelper.pauseAnimation();
};

GisView.prototype.animation_stop = function () {
  OceanoAnimationHelper.stopAnimation();
};

GisView.prototype.animation_next = function () {
  OceanoAnimationHelper.showNextFrame();
};

GisView.prototype.animation_previous = function () {
  OceanoAnimationHelper.showPreviousFrame();
};

GisView.prototype.animation_firstFrame = function () {
  OceanoAnimationHelper.showFirstFrame();
};

GisView.prototype.animation_lastFrame = function () {
  OceanoAnimationHelper.showLastFrame();
};

GisView.prototype.animation_setFrame = function (frameNumber) {
  OceanoAnimationHelper.showFrameAt(frameNumber);
};

GisView.prototype.animation_setFramerate = function (value) {
  OceanoAnimationHelper.setFramerate(value);
};

GisView.prototype.animation_setLoop = function (repeatEnabled) {
  OceanoAnimationHelper.setLoop(repeatEnabled);
};

/** ************************************************** */
/** **************** OCEANO DOWNLOAD SELECTOR ******** */
/** ************************************************** */

GisView.prototype.startNCWMSOverMode = function (overHandler, clickHandler) {
  const map = this._map;

  map.on('click', clickHandler);
  map.on('pointermove', overHandler);

  // return a destructor
  return function () {
    map.un('click', clickHandler, false);
    map.un('pointermove', overHandler, false);
  };
};

/** ***************************** */
/** ******* OCEANO UTILS ******** */
/** ***************************** */

GisView.prototype.getNcwmsLayersInView = function () {
  return OceanoHelper.getDisplayedNcwmsLayersInView(this.collection, this._mapView.calculateExtent(this._map.getSize()));
};

GisView.prototype.getPixelDataOnCoord = function (tilesGrid, layer) {
  return OceanoHelper.getPixelDataOnCoord(tilesGrid, layer, this.getZoom(), this.currentCoord);
};

GisView.prototype.getTopNcwmsLayerOnCoord = function (coord, type, tilesGrid, layerGroup, pixel) {
  return OceanoHelper.getTopNcwmsLayerOnCoord(this._map, coord, type, tilesGrid, layerGroup, pixel);
};

GisView.prototype.highlightNcwmsLayers = function (selectedLayer) {
  OceanoHelper.highlightNcwmsLayers(this.collection, selectedLayer);
};

GisView.prototype.obscureNCWMSLayers = function (identifier) {
  OceanoHelper.obscureNcwmsLayers(this.collection, identifier);
};

GisView.prototype.resetNcwmsOpacity = function () {
  OceanoHelper.resetNcwmsOpacity(this.collection);
};

GisView.prototype.isImageLayer = function (layer) {
  return layer instanceof ImageLayer;
};

GisView.prototype.isTiledLayer = function (layer) {
  return layer instanceof TileLayer;
};

GisView.prototype.getDefaultTileSize = function () {
  return this._map.getTileSize();
};

GisView.prototype.getNcwmsLayersMaxExtent = function (layers) {
  const top = [];
  const bottom = [];
  const right = [];
  const left = [];
  _.each(layers, layer => {
    const { bbox } = layer.get('olLayer');
    // bbox =    min horiz(left), min vertic(bottom),
    //          max horiz(right), max vertic(top)
    left.push(bbox[0]);
    bottom.push(bbox[1]);
    right.push(bbox[2]);
    top.push(bbox[3]);
  });

  return [_.min(left), _.min(bottom), _.max(right), _.max(top)];
};

GisView.prototype.setTileLoadEventOnLayer = function (regLayer) {
  const encodingLayer = regLayer.getLayers().getArray()[0];
  const sourceEncoding = encodingLayer.getSource();
  const dataLayer = regLayer.getLayers().getArray()[1];
  const sourceData = dataLayer.getSource();

  [{ layer: encodingLayer, source: sourceEncoding }, { layer: dataLayer, source: sourceData }].forEach(({ source, layer }) => {
    if (this.isTiledLayer(layer)) {
      source.on('tileloadstart', _.bind(function () {
        this.startLoading();
        this.addLoadingTile();
      }, this));
      source.on('tileloadend', _.bind(function (loadingTile) {
        this.removeLoadingTile();
        if (layer === encodingLayer) {
          const tileCoord = loadingTile.tile.getTileCoord();
          const layerName = loadingTile.target.getParams().LAYERS;
          const identifier = OceanoNcwmsUtils.getNcwmsValueWithId(layerName);
          if (!this._NCWMSTileGrid[identifier]) {
            this._NCWMSTileGrid[identifier] = {};
            this._NCWMSTileGrid[identifier][tileCoord[0]] = {};
            this._NCWMSTileGrid[identifier][tileCoord[0]][tileCoord[1]] = {};
          } else if (!this._NCWMSTileGrid[identifier][tileCoord[0]]) {
            this._NCWMSTileGrid[identifier][tileCoord[0]] = {};
            this._NCWMSTileGrid[identifier][tileCoord[0]][tileCoord[1]] = {};
          } else if (!this._NCWMSTileGrid[identifier][tileCoord[0]][tileCoord[1]]) {
            this._NCWMSTileGrid[identifier][tileCoord[0]][tileCoord[1]] = {};
          }
          this._NCWMSTileGrid[identifier][tileCoord[0]][tileCoord[1]][tileCoord[2]] = loadingTile.tile;
        }
      }, this));
      source.on('tileloaderror', _.bind(this.removeLoadingTile, this));
    } else if (this.isImageLayer(layer)) {
      source.on('imageloadstart', _.bind(function () {
        this.startLoading();
        this.addLoadingTile();
      }, this));
      source.on('imageloadend', _.bind(function (loadingImage) {
        this.removeLoadingTile();
        if (layer === encodingLayer) {
          const identifier = loadingImage.target.getParams().LAYERS;
          if (!this._NCWMSTileGrid[identifier]) {
            this._NCWMSTileGrid[identifier] = {};
          }
          this._NCWMSTileGrid[identifier].IMAGE = loadingImage.image;
        }
      }, this));
    }
  });
};

GisView.prototype.getNCWMSTileGrid = function () {
  return this._NCWMSTileGrid;
};

GisView.prototype.setPaletteOnMap = function (pal, model) {
  this._onMapHudView.setAndRenderPalette(pal, model);
};

GisView.prototype.showPaletteOnMap = function () {
  this._onMapHudView.showPaletteOnMap();
};

GisView.prototype.hidePaletteOnMap = function () {
  this._onMapHudView.hidePaletteOnMap();
};

GisView.prototype.getPaletteOnMap = function () {
  return this._onMapHudView.getPaletteOnMapView();
};

GisView.prototype._initOceanoHudOnMapView = function () {
  this._onMapHudView = new OceaHudOnMapView({
    gisview: this
  });
  $('#ui-container #ocea-hud-root').html(this._onMapHudView.render().$el);
};

GisView.prototype.getTimelineView = function () {
  return this._onMapHudView.getTimelineView();
};

GisView.prototype.getGlobalOceanoCurrentMoment = function () {
  if (this._onMapHudView.getTimelineView()) {
    return this._onMapHudView.getGlobalCurrentMoment();
  } // if timeline is not defined, current instant is taken as global moment.
  return moment();
};

GisView.prototype.getOceanoLayerCurrentMoment = function (model) {
  if (this._onMapHudView.getTimelineView()) {
    return this._onMapHudView.getLayerCurrentMoment(model);
  } // if timeline is not defined, current instant is taken as layer moment.
  return moment();
};

GisView.prototype.getGlobalOceanoCurrentUTC = function () {
  if (this._onMapHudView.getTimelineView()) {
    return this._onMapHudView.getTimelineView().getCurrentUTC();
  } // if timeline is not defined, NavUTC is taken as global UTC.
  return OceanoNcwmsUtils.getNavUTC();
};

GisView.prototype.getOceanoDisplayedLayers = function () {
  return this.collection.where({ includedInMap: true, layerType: 'NCWMS' });
};

GisView.prototype.resetSelectedModelParameters = function (model) {
  // Reset palette
  const sortedPalNames = OceanoNcwmsUtils.sortPalettes(model);
  const paletteId = { name: sortedPalNames[0], isAuto: false };
  model.set('selectedPalette', paletteId);

  // Reset elevation (depth) (default is depth at index 0)
  const availableElevations = model.get('olLayer').get('dimensions').elevation;
  model.set('selectedElevation', availableElevations[0]);
};

GisView.prototype.setPaletteOnTop = function (model) {
  const paletteId = model.get('selectedPalette');
  const pal = OceanoLayerUtils.getPaletteFromName(model, paletteId.name);
  this.setPaletteOnMap(pal, model);
  model.set('showPaletteOnMap', true);
  const { cid } = model;
  const displayedLayers = this.getOceanoDisplayedLayers();
  _.each(displayedLayers, displayedLayer => {
    if (displayedLayer.cid !== cid) {
      displayedLayer.set('showPaletteOnMap', false);
    }
  });
};
