const CircleStylePreview = require('./circle-preview.js');
const TextStylePreview = require('./text-preview.js');
const PointStylePreview = require('./point-preview.js');
const PolygonStylePreview = require('./polygon-preview.js');
const LineStylePreview = require('./line-preview.js');

const FeatureStylePreviewFactory = {};

FeatureStylePreviewFactory.getStylePreviewInstance = function (feature) {
  let ViewClass;

  switch (feature.getGeometry().getType()) {
    case 'Point':
      const pointStyleText = Array.isArray(feature.getStyle()) ? feature.getStyle()[0].getText() : feature.getStyle().getText();

      ViewClass = pointStyleText && pointStyleText.getText() ? TextStylePreview : PointStylePreview;
      break;

    case 'Polygon':
      ViewClass = PolygonStylePreview;
      break;

    case 'LineString':
      ViewClass = LineStylePreview;
      break;

    case 'Circle':
      ViewClass = CircleStylePreview;
      break;
  }

  return new ViewClass({ feature });
};

module.exports = FeatureStylePreviewFactory;
