import { CATALOG_ATLAS } from '../constants';

const _ = require('underscore');
const moment = require('moment');
const OceanoLayerUtils = require('../oceano/oceano-layer');
const OceanoNcwmsUtils = require('../oceano/oceano-ncwms');
const CategoryUtil = require('../category');
const AzimuthCoordsDistanceHelper = require('../gis/azimuth-distance-coordinates-helper.js');

const RoseCourantItems = require('../../collection/rose-courant-items');
const RoseCourantItem = require('../../model/rose-courant-item');

const AtlasHelper = function () {};

/**
 * Split atlas dataset id as identified items.
 * For example:
 * ATLASDYN-SURF_TELEMAC3D-SURF_R1111100_PORTBREST-555-30M-15min -> {
 *     thematic: ATLASDYN-SURF,
 *     model: TELEMAC3D-SURF,
 *     grid: R1111100,
 *     name: PORTBREST,
 *     numAtlas: 555,
 *     resolution: 30M,
 *     timestep: 15min
 * }
 * @param datasetId the atlas dataset id
 * @returns {{numAtlas: string, timestep: string, grid: string, name: string, model: string, resolution: string, thematic: string, allInput: string}}
 */
AtlasHelper.prototype.splitAtlasDatasetId = function (datasetId) {
  const regExp = /^([A-Z0-9-]+)_([A-Z0-9-]+)_(R\d{7})_([A-Z-]+)-(\d+)-(\d+[M|KM]+)-(\d+[min|h]+)$/g;
  const groups = regExp.exec(datasetId);
  const [allInput, thematic, model, grid, name, numAtlas, resolution, timestep] = groups;
  return {
    allInput, thematic, model, grid, name, numAtlas, resolution, timestep
  };
};

/**
 * Give atlas number from dataset identifier.
 * @param datasetId the atlas dataset id (for example: ATLASDYN-SURF_TELEMAC3D-SURF_R1111100_PORTBREST-555-30M-15min)
 * @returns {string} the atlas number from given identifier (for example: 555)
 */
AtlasHelper.prototype.getAtlasNum = function (datasetId) {
  return this.splitAtlasDatasetId(datasetId).numAtlas;
};

/**
 * Format translated title for an atlas layer with a pattern
 * @param layer the layer we want to get title
 * @param lang the language used to translate title
 * @returns {string} formatted and translated title
 */
AtlasHelper.prototype.getLayerTitleAtlas = function (layer, lang = 'fr', withCategory = false) {
  const categoryName = CategoryUtil.buildCategoryName(layer, lang);
  const numAtlas = layer.get('numAtlas');
  const atlasName = layer.get('translation')[numAtlas][lang];
  return withCategory ? categoryName + atlasName : atlasName;
};

/**
 * Get english and french translated title of a layer
 * @param layer the catalog layer
 * @returns {{en: string, fr: string}} an object containing french and english titles
 */
AtlasHelper.prototype.getLayerTitlesAtlas = function (layer) {
  return {
    en: this.getLayerTitleAtlas(layer, 'en'),
    fr: this.getLayerTitleAtlas(layer, 'fr')
  };
};

/**
 * Check if given layer is from atlas catalog
 * @param layer the layer to be checked
 * @returns {boolean} true if from atlas catalog, false otherwise
 */
AtlasHelper.prototype.isAtlasLayer = function (layer) {
  return layer.get('catalog') === CATALOG_ATLAS;
};

/**
 * Give formatted url according to identifier
 * @param identifier the layer identifier
 * @returns {string} a string representing metadata url
 */
AtlasHelper.prototype.getMetadataUrl = function (identifier) {
  const { catalogUrl } = window.CONFIG.atlas;
  const metadataFileName = `${identifier.split('/')[0]}.xml`;
  return catalogUrl + metadataFileName;
};

/**
 * Add the formatted metadata url to given layers
 * @param layers array of layers to be processed
 */
AtlasHelper.prototype.addMetadataUrlToLayers = function (layers) {
  layers.each(layer => {
    const firstReg = layer.get('olLayer').getLayers().getArray()[0];
    const identifier = firstReg ? firstReg.get('identifier') : layer.get('identifier');
    const metadataURL = this.getMetadataUrl(identifier);
    layer.set('metadataURL', metadataURL);
  });
};

/**
 * Convert a signed hour float number as HH:mm
 * @param number a signed hour float number
 * @returns {string} 'HH:mm'
 */
AtlasHelper.prototype.convertNumToTime = function (numberToConvert) {
  let number = numberToConvert;

  // PM +/- 6
  const limit = 6;

  // si < -6 => = -6
  if (number < -1 * limit) {
    number = -1 * limit;
  }
  // si > 6 => = 6
  if (number > limit) {
    number = limit;
  }

  // Check sign of given number
  let sign = (number >= 0) ? 1 : -1;

  // Set positive value of number of sign negative
  number *= sign;

  // Separate the int from the decimal part
  const hour = Math.floor(number);
  let decpart = number - hour;

  const min = 1 / 60;
  // Round to nearest minute
  decpart = min * Math.round(decpart / min);

  let minute = `${Math.floor(decpart * 60)}`;

  // Add padding if need
  if (minute.length < 2) {
    minute = `0${minute}`;
  }

  // Add Sign in final result
  sign = sign === 1 ? '' : '-';

  // Concate hours and minutes
  const time = `${sign + hour}:${minute}`;

  return time;
};

/**
 * Build an array of moments with a step of 15 minutes for given day
 * @param currentDay the day as moment object
 * @returns {[]} an array of moments
 */
AtlasHelper.prototype.buildAtlasMoments = function (currentDay) {
  const dateStart = currentDay.clone().hour(0).minute(0).second(0)
    .millisecond(0);
  const dateEnd = currentDay.clone().hour(23).minute(59).second(59)
    .millisecond(999);
  const objectDates = [];
  while (dateStart < dateEnd) {
    objectDates.push(dateStart.clone());
    dateStart.add(15, 'minutes');
  }
  return objectDates;
};

/**
 * Build an array of moments with a step of 15 minutes for given day
 * @param currentDay the day as moment object
 * @returns {[]} an array of moments
 */
AtlasHelper.prototype.buildAtlasMomentsBetweenInterval = function (dateStart, dateEnd) {
  const dateIterator = dateStart.clone();
  const objectDates = [];
  while (dateIterator <= dateEnd) {
    objectDates.push(dateIterator.clone());
    dateIterator.add(15, 'minutes');
  }
  return objectDates;
};

/**
 * Build an array of moments with a step of 1 day between two dates
 * @param minDate the lower bound day as moment object (included)
 * @param maxDate the higher bound day as moment object (excluded)
 * @returns {[]} an array of moments
 */
AtlasHelper.prototype.buildAtlasAvailableDays = function (minDate, maxDate) {
  const dateStart = minDate.clone().hour(0).minute(0).second(0)
    .millisecond(0);
  const dateEnd = maxDate.clone().hour(23).minute(59).second(59)
    .millisecond(999);
  const objectDates = [];
  while (dateStart < dateEnd) {
    objectDates.push(dateStart.clone());
    dateStart.add(1, 'day');
  }
  return objectDates;
};

/**
 * Compute new datetime from difference and reference datetime given
 * @param diffFromNearestSpm the difference to be applied (ex: -5.2)
 * @param timeBounds (optional) the range allowed for layer (ex: { '1950-01-01' : ['00:00:00.000Z', ..., '12:00:00.000Z']}
 * @returns the new datetime moment
 */
AtlasHelper.prototype.computeNewDatetimeFromReference = function (diffFromNearestSpm, timeBounds) {
  const datetime = window.CONFIG.atlas.ncwmsReferenceDateTime; // the reference date time string (ex: 1950-01-01T06:00:00.000Z)
  const dateTimeWithoutOffset = moment.utc(datetime); // convert datetime string to moment object
  const diffToHoursMinutes = this.convertNumToTime(diffFromNearestSpm).split(':'); // convert float to signed HH:mm
  const diffHours = +diffToHoursMinutes[0]; // keep sign and hour
  const diffMinutes = +diffToHoursMinutes[1]; // keep minutes
  dateTimeWithoutOffset.add(diffHours, 'h'); // add positive or negative hour
  if (diffFromNearestSpm >= 0) {
    dateTimeWithoutOffset.add(diffMinutes, 'm'); // if positive hour added then add minutes
  } else {
    dateTimeWithoutOffset.subtract(diffMinutes, 'm'); // if negative hour added then subtract minutes
  }

  if (timeBounds) { // if time bounds provided
    const currentDate = dateTimeWithoutOffset.format('YYYY-MM-DD'); // keep date only
    const valuesForCurrentDate = timeBounds[currentDate]; // get time bounds for this date
    if (valuesForCurrentDate) { // if time bounds exist for this date
      // create first and last date moment objects
      const minBoundDateTime = moment.utc(`${currentDate}T${_.first(valuesForCurrentDate)}`, 'YYYY-MM-DDTHH:mm:ss.SSSZ').utcOffset(0, true);
      const maxBoundDateTime = moment.utc(`${currentDate}T${_.last(valuesForCurrentDate)}`, 'YYYY-MM-DDTHH:mm:ss.SSSZ').utcOffset(0, true);
      if (dateTimeWithoutOffset.isAfter(maxBoundDateTime)) {
        // if requested time is after higher time bound then return higher time bound to avoid 400 http code
        return maxBoundDateTime;
      }

      if (dateTimeWithoutOffset.isBefore(minBoundDateTime)) {
        // if requested time is before lower time bound then return lower time bound to avoid 400 http code
        return minBoundDateTime;
      }
    }
  }
  return dateTimeWithoutOffset;
};

// https://www.joshwcomeau.com/snippets/javascript/range/
/**
 * Generate a range between two values (closed interval) with a given step.
 * @param start the lower bound
 * @param end the higher bound
 * @param step the step for increment
 * @returns {[]} a range as array. For example, generateRange(2, 6, 2) will return [2, 4, 6]
 */
AtlasHelper.prototype.generateRange = (start, end, step = 1) => {
  const range = [];
  if (typeof end === 'undefined') {
    end = start;
    start = 0;
  }
  for (let i = start; i <= end; i += step) {
    range.push(i);
  }
  return range;
};

/**
 * Transform atlas values for display (round dir, convert mag unit)
 * @param values initial map containing values to be transformed
 * @param layer the catalog layer concerned
 * @returns a new RoseCourantItems object with transformed values
 */
AtlasHelper.prototype.transformAtlasValuesForDisplay = (values, layer) => {
  const unitDetails = OceanoLayerUtils.getSelectedUnitFromLayer(layer);
  const isConversionEnabled = unitDetails.conversion && unitDetails.conversion.offset && unitDetails.conversion.rate;
  // produce backbone collection RoseCourantItems from values retrieved by ncwms service
  const items = new RoseCourantItems();
  values.forEach((value, key, map) => {
    const valueMag = value.mag;
    const realValueMag = isConversionEnabled ? OceanoNcwmsUtils.convertUnit(valueMag, unitDetails.conversion.offset, unitDetails.conversion.rate) : valueMag;
    const realValueDir = value.dir;
    items.add(
      new RoseCourantItem(
        {
          datetime: key,
          mag: parseFloat(realValueMag).toFixed(1),
          dir: Math.round(realValueDir),
          realValueMag,
          realValueDir
        }
      )
    );
  });
  return items;
};

/**
 * Constant function to convert angle degrees to radian.
 * @param degrees the value in degrees that must be converted to radian
 * @returns {number} degrees as radian
 */
const radians = degrees => AzimuthCoordsDistanceHelper.degToRad(degrees);

/**
 * Compute coordinates of point representation of magnitude and direction.
 * @param magnitude the magnitude value
 * @param direction the direction in degrees
 * @returns {[number, number]} a 2-length array with x and y coordinates for display
 */
AtlasHelper.prototype.computeRoseCourantCoordFromMagDir = (magnitude, direction) => {
  const roundedMag = Math.round(magnitude * 10) / 10;
  const norme = Math.abs(roundedMag);
  const angle = radians(direction);
  const xValue = Math.sin(angle) * norme;
  const yValue = Math.cos(angle) * norme;
  return [xValue, yValue];
};

/**
 * Round to nearest X minutes interval.
 * See https://github.com/moment/moment/issues/959#issuecomment-301767843
 * @param intervalInMinutes the interval in minutes (ex: 15)
 * @param momentObject the moment object to be rounded
 * @returns a copy of momentObject rounded to desired interval
 */
AtlasHelper.prototype.nearestMinutes = (intervalInMinutes, momentObject) => {
  const roundedMinutes = Math.round(momentObject.clone().minute() / intervalInMinutes) * intervalInMinutes;
  return momentObject.clone().minute(roundedMinutes).second(0);
};

module.exports = new AtlasHelper();
