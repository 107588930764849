const ShomView = require('../../core/shom-view');
const contactTemplate = require('../../../template/contact-menu/contact-menu.hbs');

module.exports = ShomView.build({

  events: {
    'click #close-contact-menu': function () { this.onCloseMenuButtonClick(event, '.btn-contact'); },
    'blur #contact': function () { this.onLastMenuItemBlur(event, '#close-contact-menu'); }
  },

  initialize(options = {}) {
    this._config = options.config || window.CONFIG;
  },

  render() {
    this.$el.html(contactTemplate({
      contact: this._config.contact
    }));
    return this;
  }
});
