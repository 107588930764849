var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form action=\"\">\n\n    <div class=\"row\"><h5><span data-i18n=\"oceano.nav.transect.animation.beginmoment\"></span> "
    + alias4(((helper = (helper = lookupProperty(helpers,"utcInfo") || (depth0 != null ? lookupProperty(depth0,"utcInfo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"utcInfo","hash":{},"data":data,"loc":{"start":{"line":3,"column":93},"end":{"line":3,"column":104}}}) : helper)))
    + "</h5></div>\n\n    <div class=\"row first-moment-container\"></div>\n\n    <div class=\"row\"><h5><span data-i18n=\"oceano.nav.transect.animation.endmoment\"></span> "
    + alias4(((helper = (helper = lookupProperty(helpers,"utcInfo") || (depth0 != null ? lookupProperty(depth0,"utcInfo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"utcInfo","hash":{},"data":data,"loc":{"start":{"line":7,"column":91},"end":{"line":7,"column":102}}}) : helper)))
    + "</h5></div>\n\n    <div class=\"row last-moment-container\"></div>\n\n    <div class=\"row\"><h5 data-i18n=\"oceano.nav.animation.control.framerate.title\"></h5></div>\n    <div class=\"row\">\n        <div class=\"transect-slider-animation col-sm-5\"></div>\n        <div class=\"col-sm-2\">\n            <span class=\"transect-slider-animation-value\">0</span>\n            <span class=\"ips\" data-i18n=\"oceano.nav.animation.control.framerate.dimension\"></span>\n        </div>\n\n        <div class=\"col-sm-4 text-right\">\n            <button type=\"submit\" class=\"btn btn-primary transect-animation-button\" data-i18n=\"oceano.nav.transect.animation.loadAnimation\"></button>\n        </div>\n    </div>\n\n    <div class=\"row\" style=\"display: none\">\n        <div>\n            <ul class=\"errors\">\n                <li data-i18n=\"oceano.nav.transect.animation.badDates\"></li>\n            </ul>\n        </div>\n\n    </div>\n\n</form>\n\n<div class=\"text-center\">\n    <img id=\"transect-animation\" style=\"display: none;\">\n</div>";
},"useData":true});