const $ = require('jquery');
const DDMSensorReader = require('../utils/ddm/ddm-sensor-reader');

const DDMService = {

  getTideGauges(network) {
    const promise = $.Deferred();

    const url = this._url + this.config.ddm.routes.sensors;

    $.get(url).done(response => {
      const parsedReponse = JSON.parse(response);
      let tideGauges = [];
      if (parsedReponse && parsedReponse.length > 0) {
        tideGauges = parsedReponse.filter(tidegauge => {
          const isDisplayed = tidegauge.state !== 'HIDDEN';
          const tdNetwork = DDMSensorReader.getNetwork(tidegauge);
          const hasNetwork = (network === 'RONIM') ? tdNetwork === network : tdNetwork !== 'RONIM';
          return isDisplayed && hasNetwork;
        });
      }

      promise.resolve(tideGauges);
    }).fail(promise.reject);

    return promise;
  },

  getDataObs(tideGaugeId, beginDate, endDate, selectedSources) {
    const promise = $.Deferred();

    let sources = [];
    let route = this.config.ddm.routes.getSourceWithDataInInterval;
    if (selectedSources !== undefined) {
      sources = selectedSources;
      route = this.config.ddm.routes.getNearestObsFromPeriodForSrc;
    }

    let url = `${this._url + route + tideGaugeId}?`;
    if (sources.length > 0) {
      url += `source=${sources.join(',')}&`;
    }
    if (beginDate) {
      url += `dtStart=${beginDate}&`;
    }
    if (endDate) {
      url += `dtEnd=${endDate}&`;
    }
    $.get(url)
      .done(data => {
        promise.resolve(JSON.parse(data));
      })
      .fail(err => {
        promise.reject(err);
      });

    return promise;
  },

  prepareSources(selectedSources, notFetchableSources) {
    const sources = [];
    // set sources
    for (let idx = 0; idx < 10; idx++) {
      // ignore not featcheable sources (SPM, surcote)
      if (notFetchableSources.indexOf(idx + 1) > -1) {
        continue;
      }
      // Works with boolean arrays
      if (typeof selectedSources[idx] === 'boolean') {
        if (selectedSources[idx]) {
          sources.push(idx + 1);
        }
      }
      // Works with non boolean arrays
      else if (typeof selectedSources[idx] === 'string' && Number.isNaN(selectedSources[idx])) {
        sources.push(idx);
      } else if (!Number.isNaN(selectedSources[idx])) {
        const src = idx + 1;
        sources.push(src);
      }
    }
    return sources;
  },

  getNearestObs(tideGaugeId, beginDate, endDate, selectedSources) {
    let finalSources = [];
    if (selectedSources !== undefined) {
      finalSources = this.prepareSources(selectedSources, this.notFetchableObsSources);
    }
    return this.getDataObs(tideGaugeId, beginDate, endDate, finalSources);
  },

  getSourceWithDataInInterval(tideGaugeId, beginDate, endDate) {
    return this.getDataObs(tideGaugeId, beginDate, endDate);
  },

  getJsonData(offering, beginDate, endDate, selectedSources, interval, type) {
    const promise = $.Deferred();

    type = type || 'json';

    // set sources
    let finalSources = [];
    if (selectedSources !== undefined) {
      finalSources = this.prepareSources(selectedSources, this.notFetchableSources);
    }

    const tideGaugeId = offering.replace(/^.*\/([0-9]+)$/g, '$1');
    let url;
    if (finalSources.includes(10)) {
      url = `${this.config.baseServicesUrl}${this.config.defaultKey + this.config.ddm.routes.prevision + type}/${tideGaugeId}?`;
    }else {
      url = `${this._url + this.config.ddm.routes.observation + type}/${tideGaugeId}?`;
    }
    if (finalSources.length > 0) {
      url += `sources=${finalSources.join(',')}&`;
    }
    if (beginDate) {
      url += `dtStart=${beginDate}&`;
    }
    if (endDate) {
      url += `dtEnd=${endDate}&`;
    }
    if (interval) {
      url += `interval=${interval}&`;
    }
    $.get(url)
      .done(data => {
        promise.resolve(JSON.parse(data));
      })
      .fail(err => {
        promise.reject(err);
      });
    return promise;
  },

  getSensorDesc(offeringId) {
    const promise = $.Deferred();

    const url = this._url + this.config.ddm.routes.completetidegauge + offeringId;
    $.get(url)
      .done(data => {
        promise.resolve(JSON.parse(data));
      })
      .fail(err => {
        promise.reject(err);
      });

    return promise;
  },

  getStatistics(tideGaugeId) {
    const promise = $.Deferred();

    const url = this._url + this.config.ddm.routes.statistics + tideGaugeId;
    $.get(url)
      .done(data => {
        promise.resolve(JSON.parse(data));
      })
      .fail(err => {
        promise.reject(err);
      });

    return promise;
  },

  getDownloadObservations(tideGauges, sources, start, end, type, name, mail, isContact) {
    const promise = $.Deferred();

    const url = `${this.config.ddm.url}/dl/observations`;

    const postData = {
      tideGauges,
      sources,
      start,
      end,
      type,
      name,
      mail,
      isContact: !!(isContact)
    };

    $.post({
      url,
      data: JSON.stringify(postData),
      contentType: 'application/json'
    })
      .done(data => {
        promise.resolve(data);
      })
      .fail(err => {
        promise.reject(err);
      });

    return promise;
  },

  searchTideGaugeId(pattern) {
    const promise = $.Deferred();

    const url = this._url + this.config.ddm.routes.searchTideGauge + pattern;
    $.get(url)
      .done(data => {
        const tideGauge = JSON.parse(data);
        promise.resolve(+tideGauge.id);
      })
      .fail(err => {
        promise.reject(err);
      });

    return promise;
  },

  getDlTideGauges() {
    const promise = $.Deferred();

    const url = this._url + this.config.ddm.routes.getDlTideGauge;
    $.get(url)
      .done(data => {
        const tideGauges = JSON.parse(data);
        promise.resolve(tideGauges);
      })
      .fail(err => {
        promise.reject(err);
      });

    return promise;
  },

  getDlStream(data) {
    const promise = $.Deferred();
    const url = this._url + this.config.ddm.routes.getDlStream;
    $.ajax({
      url,
      contentType: 'application/json',
      type: 'POST',
      data: JSON.stringify(data)
    }).done(result => {
      promise.resolve(result);
    })
      .fail(err => {
        promise.reject(err);
      });

    return promise;
  },

  getTidegaugeObservations(pattern, withStartDate) {
    const promise = $.Deferred();
    const url = (withStartDate ? this.config.ddm.textualData.nthsincedate : this.config.ddm.textualData.nth) + pattern;
    $.getJSON(url, tidegaugeObservations => {
      promise.resolve(tidegaugeObservations);
    }).fail(error => {
      promise.resolve({ error: true, messgaeErr: error });
    });
    return promise;
  }
};

module.exports = function (options) {
  const service = Object.create(DDMService);
  service.config = options;
  service.notFetchableSources = [7, 8];
  service.notFetchableObsSources = [7, 8, 9, 10];
  service._url = service.config.ddm.url;
  return service;
};
