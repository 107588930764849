import Collection from 'ol/Collection';
import ImageLayer from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';
import TileWMSSource from 'ol/source/TileWMS';
import TileLayer from 'ol/layer/Tile';
import LayerGroup from 'ol/layer/Group';

import AtlasHelper from '../atlas/atlas-utils';
import OceanoHelper from './oceano-helper';
import LayerModelUtils from '../layer-model-utils';

const $ = require('jquery');
const _ = require('underscore');

const NCWMSService = require('../../service/ncwms');

const OceanoNcwmsUtils = require('../oceano/oceano-ncwms.js');
const OceanoLayerUtils = require('../oceano/oceano-layer.js');

const OceanoAnimationHelper = function () { };

OceanoAnimationHelper.prototype.loadAnimation = function (animationParams, animationStructure) {
  this._tileToLoad = 0;
  this._loadingTimeLock = false;
  this._loadingTimeout = null;
  this._gisview = animationParams.gisview;
  this._ncwmsTileGrid = OceanoHelper.createTileGrid();
  this._animationDisplayedGroups = [];
  this._animationFramerate = animationParams.framerate;
  this._eventFunctions = animationParams.eventFunctions;
  this._animationCurrentFrame_idx = 0;
  this._totalFramesNumber = animationStructure.animationMoments.length;
  this._animationLoop = animationParams.animationLoop;
  this._originLayersHidden = false;

  this._animationStructure = animationStructure;
  // animation structure is as follow :
  // {    animationMoments : *All the moments available for the animation*
  //      oceaLayers : [  {   model                 : *layer model*, -- ocea.layer.group
  //                          layerAnimationMoments : *All the layer moments matching the animationMoments* -- Array
  //                          opacity               : *layer opacity value* -- float
  //                          displayedTiles        : *displayed NCWMS tiles for animation* --Array
  //                      },
  //                      { ... }, ... ]
  // }

  this._loadAnimationLayers();
};

/** *************************************** */
/** *************** EVENTS **************** */
/** *************************************** */

OceanoAnimationHelper.prototype._toLoadCallback = function () {
  this._tileToLoad += 1;
  this._resetLoadingTimeout();
};

OceanoAnimationHelper.prototype._resetLoadingTimeout = function () {
  // workaround to prevent cached tiles related issues.
  this._loadingTimeLock = false;
  clearTimeout(this._loadingTimeout);
  this._loadingTimeout = setTimeout(_.bind(this._loadTimeoutCallBack, this), 1000);
};

OceanoAnimationHelper.prototype._loadCallback = function () {
  this._tileToLoad -= 1;
  if (this._tileToLoad === 0 && this._eventFunctions.animationLoadComplete && this._loadingTimeLock) {
    this._hideStaticLayers();
    this._eventFunctions.animationLoadComplete();
  }
};

OceanoAnimationHelper.prototype._loadTimeoutCallBack = function () {
  this._loadingTimeLock = true;
  if (this._tileToLoad === 0 && this._eventFunctions.animationLoadComplete !== undefined && this._loadingTimeLock) {
    this._hideStaticLayers();
    this._eventFunctions.animationLoadComplete();
  }
};

/** *************************************** */
/** ************* ANIMATION  ************** */
/** *************************************** */

OceanoAnimationHelper.prototype.playAnimation = function () {
  this._showFrame();
  this._setAnimationPlayer();
  this._eventFunctions.animationStarted();
};

OceanoAnimationHelper.prototype.pauseAnimation = function () {
  this._removeAnimationPlayer();
  this._eventFunctions.animationEnded();
};

OceanoAnimationHelper.prototype.stopAnimation = function () {
  this._removeAnimationPlayer();
  this._eventFunctions = {}; // eventually aborts loading
  this._removeDisplayedGroups();
  this._restoreStaticLayers();
  this._animationCurrentFrame_idx = 0;
};

OceanoAnimationHelper.prototype.showPreviousFrame = function () {
  const prevAnimationFrame = this._animationCurrentFrame_idx - 1;
  if (prevAnimationFrame < 0) {
    this._showFrame(this._totalFramesNumber - 1);
  } else {
    this._showFrame(prevAnimationFrame);
  }
};

OceanoAnimationHelper.prototype.showNextFrame = function () {
  const nextAnimationFrame = this._animationCurrentFrame_idx + 1;
  if (nextAnimationFrame === this._totalFramesNumber) {
    this._showFrame(0);
  } else {
    this._showFrame(nextAnimationFrame);
  }

  if (!this._animationLoop && this._animationCurrentFrame_idx + 1 === this._totalFramesNumber) {
    this.pauseAnimation();
  }
};

OceanoAnimationHelper.prototype.showFirstFrame = function () {
  this._showFrame(0);
};

OceanoAnimationHelper.prototype.showLastFrame = function () {
  this._showFrame(this._totalFramesNumber - 1);
};

OceanoAnimationHelper.prototype.showFrameAt = function (frameNumber) {
  const newFrameNumber = (frameNumber >= this._nbLayers || frameNumber < 0) ? 0 : frameNumber;
  this._showFrame(newFrameNumber);
};

OceanoAnimationHelper.prototype._showFrame = function (newFrameIdx) {
  // display each layers with the set opacity
  for (let groupIdx = 0; groupIdx < this._animationStructure.oceaLayers.length; groupIdx++) {
    const animationTiles = this._animationStructure.oceaLayers[groupIdx].displayedTiles;
    const groupOpacity = this._animationStructure.oceaLayers[groupIdx].opacity;

    for (let tileIdx = 0; tileIdx < animationTiles.length; tileIdx++) {
      const groupLayers = animationTiles[tileIdx].getLayers().getArray();

      if (newFrameIdx !== null && newFrameIdx >= 0) {
        groupLayers[this._animationCurrentFrame_idx].set('opacity', 0);
        groupLayers[newFrameIdx].set('opacity', groupOpacity);
      } else {
        groupLayers[this._animationCurrentFrame_idx].set('opacity', groupOpacity);
      }
    }
  }

  if (newFrameIdx !== null && newFrameIdx >= 0) {
    this._animationCurrentFrame_idx = newFrameIdx;
    this._eventFunctions.frameChanged(this._animationCurrentFrame_idx);
  }
};

OceanoAnimationHelper.prototype._setAnimationPlayer = function () {
  const timeInterval = Math.floor(1000 / this._animationFramerate);
  this._removeAnimationPlayer();
  this._animationPlayer = setInterval(_.bind(this.showNextFrame, this), timeInterval);
};

OceanoAnimationHelper.prototype._removeAnimationPlayer = function () {
  if (this._animationPlayer) {
    clearInterval(this._animationPlayer);
    this._animationPlayer = null;
  }
};

OceanoAnimationHelper.prototype.setLoop = function (animationLoop) {
  this._animationLoop = animationLoop;
};

OceanoAnimationHelper.prototype.setFramerate = function (animationFramerate) {
  this._animationFramerate = animationFramerate;
  if (this._animationPlayer) { // in case animation is being played.
    this._setAnimationPlayer();
  }
};

/** *************************************** */
/** ****** OL.LAYER OBJECTS DISPLAY ******* */
/** *************************************** */

OceanoAnimationHelper.prototype._hideStaticLayers = function () {
  if (this._originLayersHidden) { return; }
  for (let groupIdx = 0; groupIdx < this._animationStructure.oceaLayers.length; groupIdx++) {
    //  groupLayer is an ol.layer.group at 'param' level (i.e. 'VAGUES/VAR85-10-2-201_msl')
    const groupLayer = this._animationStructure.oceaLayers[groupIdx].model;
    this._animationStructure.oceaLayers[groupIdx].visibility = groupLayer.get('visibility');
    groupLayer.set('visibility', false);
  }
  this._originLayersHidden = true;
};

OceanoAnimationHelper.prototype._restoreStaticLayers = function () {
  if (!this._originLayersHidden) { return; }
  if (!this._animationStructure.oceaLayers) { return; }
  for (let groupIdx = 0; groupIdx < this._animationStructure.oceaLayers.length; groupIdx++) {
    //  groupLayer is an ol.layer.group at 'param' level (i.e. 'VAGUES/VAR85-10-2-201_msl')
    const groupLayer = this._animationStructure.oceaLayers[groupIdx].model;
    groupLayer.set('visibility', this._animationStructure.oceaLayers[groupIdx].visibility);
  }
  this._originLayersHidden = false;
};

/** *************************************** */
/** ****** OL.LAYER OBJECTS CREATION ****** */
/** *************************************** */

OceanoAnimationHelper.prototype._loadAnimationLayers = function () {
  // palette parameters load happens prior to tiles loading.
  let paletteParamsToLoad = this._animationStructure.oceaLayers.length;
  for (let groupIdx = 0; groupIdx < this._animationStructure.oceaLayers.length; groupIdx++) {
    // according to the specification, the animation module loads any visible layer within the displayed layer groups.
    const groupLayer = this._animationStructure.oceaLayers[groupIdx].model;
    const visibleLayers = groupLayer.get('olLayer').getLayers().getArray().filter(layer => layer.getVisible());
    this._animationStructure.oceaLayers[groupIdx].visibleLayers = visibleLayers;

    this._getPalette(this._animationStructure.oceaLayers[groupIdx])
      .then(_.bind(function (palette) {
        this._animationStructure.oceaLayers[groupIdx].palette = palette;
        paletteParamsToLoad -= 1;
        if (paletteParamsToLoad === 0) {
          this._loadAnimationTiles();
        }
      }, this))
      .fail(_.bind(function () {
        this.stopAnimation();
      }, this));
  }
};

OceanoAnimationHelper.prototype._loadAnimationTiles = function () {
  this._tileToLoad = 0;
  this._loadingTimeLock = false;
  // sort layers based on their index in the map
  this._animationStructure.oceaLayers.sort((layer1, layer2) => layer1.model.get('index') - layer2.model.get('index'));

  for (let groupIdx = 0; groupIdx < this._animationStructure.oceaLayers.length; groupIdx++) {
    //  groupLayer is an ol.layer.group at 'param' level (i.e. 'VAGUES/VAR85-10-2-201_msl')
    const groupLayer = this._animationStructure.oceaLayers[groupIdx].model;
    const groupAnimationMoments = this._animationStructure.oceaLayers[groupIdx].layerAnimationMoments;
    const { palette } = this._animationStructure.oceaLayers[groupIdx];
    const paletteParams = OceanoLayerUtils.getNCWMSPaletteParams(palette.auto, palette.default);
    paletteParams.ELEVATION = groupLayer.get('selectedElevation');
    if (groupLayer.get('currentCoeff')) {
      paletteParams.COEFF = groupLayer.get('currentCoeff');
    }
    this._animationStructure.oceaLayers[groupIdx].displayedTiles = [];
    this._animationStructure.oceaLayers[groupIdx].opacity = groupLayer.get('opacity');

    const { visibleLayers } = this._animationStructure.oceaLayers[groupIdx];

    const isAtlas = AtlasHelper.isAtlasLayer(groupLayer);
    for (let layerIdx = 0; layerIdx < visibleLayers.length; layerIdx++) {
      const visibleLayer = visibleLayers[layerIdx];

      // creation of a synthetic ol.layer.group for each animation layer.
      const displayedTileGroup = this._createLayerGroups(visibleLayer, groupAnimationMoments, paletteParams, isAtlas);
      this._animationStructure.oceaLayers[groupIdx].displayedTiles.push(displayedTileGroup);
    }
  }

  for (let groupIdx = 0; groupIdx < this._animationStructure.oceaLayers.length; groupIdx++) {
    const { displayedTiles } = this._animationStructure.oceaLayers[groupIdx];
    for (let tileIdx = 0; tileIdx < displayedTiles.length; tileIdx++) {
      // add the synthetic ol.layer.group to the OpenLayer map for display.
      this._gisview.addLayer(displayedTiles[tileIdx]);
    }
  }
};

OceanoAnimationHelper.prototype._createLayerGroups = function (visibleLayer, moments, paletteParams, isAtlas) {
  const regNCWMSTileArray = visibleLayer.getLayers().getArray();
  const regNCWMSTile = _.find(regNCWMSTileArray, tile => tile.get('type') === 'NCWMS');
  const displayedGroup = new LayerGroup({
    type: 'NCWMSAnimation',
    title: regNCWMSTile.get('title'),
    identifier: regNCWMSTile.get('identifier'),
    abstract: regNCWMSTile.get('abstract'),
    maxExtent: regNCWMSTile.get('regExtent')
  });
  const animationLayers = this._createLayers(regNCWMSTile, moments, paletteParams, isAtlas);
  displayedGroup.setLayers(new Collection(animationLayers));

  return displayedGroup;
};

OceanoAnimationHelper.prototype._createLayers = function (regNCWMSTile, moments, paletteParams, isAtlas) {
  const layerParams = {
    title: regNCWMSTile.get('title'),
    identifier: regNCWMSTile.get('identifier'),
    abstract: regNCWMSTile.get('abstract'),
    maxExtent: regNCWMSTile.get('regExtent'),
    singleTile: true,
    isAtlas
  };
  const layerUrl = regNCWMSTile.getSource().getUrls()[0];
  const layers = [];
  for (let momentIdx = 0; momentIdx < moments.length; momentIdx++) {
    layers.push(this._createLayer(layerParams, layerUrl, moments[momentIdx].toISOString(), paletteParams));
  }
  return layers;
};

OceanoAnimationHelper.prototype._createLayer = function (layerParams, url, moment, paletteParams) {
  const params = _.extend({
    TIME: moment,
    LAYERS: layerParams.identifier
  }, paletteParams);

  if (layerParams.isAtlas) {
    // we can use Image WMS
    const source = new ImageWMS({
      url,
      crossOrigin: 'anonymous',
      params
    });

    source.on('imageloadend', this._loadCallback.bind(this));
    source.on('imageloadstart', this._toLoadCallback.bind(this));
    return new ImageLayer(_.extend(layerParams, {
      source,
      type: 'NCWMSAnimation',
      opacity: 0
    }));
  }

  // we don't use image WMS for perf issue for oceano layers
  const source = new TileWMSSource({
    url,
    crossOrigin: 'anonymous',
    params,
    tileGrid: this._ncwmsTileGrid
  });

  source.on('tileloadend', this._loadCallback.bind(this));
  source.on('tileloadstart', this._toLoadCallback.bind(this));
  return new TileLayer(_.extend(layerParams, {
    source,
    type: 'NCWMSAnimation',
    opacity: 0
  }));
};

OceanoAnimationHelper.prototype._removeDisplayedGroups = function () {
  if (this._animationStructure === undefined || this._animationStructure.oceaLayers === undefined) { return; }
  for (let groupIdx = 0; groupIdx < this._animationStructure.oceaLayers.length; groupIdx++) {
    const animationTiles = this._animationStructure.oceaLayers[groupIdx].displayedTiles;
    if (!animationTiles) { return; }
    for (let tileIdx = 0; tileIdx < animationTiles.length; tileIdx++) {
      const diplayedTile = animationTiles[tileIdx];
      this._gisview.removeLayer(diplayedTile);
    }
    this._animationStructure.oceaLayers[groupIdx].displayedTiles = [];
  }
};

OceanoAnimationHelper.prototype._getPalette = function (layerGroup) {
  const layerGroupModel = layerGroup.model;
  const paletteId = layerGroupModel.get('selectedPalette');

  if (paletteId.isAuto) {
    return this._getAutoPaletteParams(layerGroup, paletteId.name);
  }
  const jsonPal = OceanoLayerUtils.getPaletteFromName(layerGroupModel, paletteId.name);
  const palette = {
    default: jsonPal,
    auto: jsonPal
  };

  const promise = $.Deferred();
  promise.resolve(palette);

  return promise;
};

OceanoAnimationHelper.prototype._getAutoPaletteParams = function (layerGroup, paletteName) {
  const layerGroupModel = layerGroup.model;
  const options = OceanoLayerUtils.getNCWMSOptions(layerGroupModel);
  const unitLayer = OceanoLayerUtils.getNCWMSUnit(layerGroupModel);
  const conversionLayer = OceanoLayerUtils.getNCWMSConversion(layerGroupModel);
  const paletteAutoParams = OceanoLayerUtils.getPaletteAutoParams(layerGroupModel, paletteName);

  const times = layerGroup.layerAnimationMoments;
  const bbox = this._gisview.getMapExtent();

  const promise = $.Deferred();
  const ncwmsServiceOptions = { ncwmsLayerType: LayerModelUtils.getNcwmsLayerType(layerGroupModel) };
  NCWMSService(ncwmsServiceOptions).then(service => {
    service.getMinMaxLayersMoments(layerGroup.visibleLayers, times, bbox)
      .then(datas => OceanoNcwmsUtils.getMinMax(datas))
      .then(minMax => {
        minMax = OceanoLayerUtils.roundMinMaxValue(
          minMax.min,
          minMax.max,
          options
        );
        const jsonPal = OceanoLayerUtils.getPaletteFromName(layerGroupModel, paletteName);
        const jsonPalAuto = {
          name: paletteName,
          autoName: paletteAutoParams.name,
          min: minMax.min,
          max: minMax.max,
          unit: unitLayer,
          conversion: conversionLayer,
          options,
          numColorBands: paletteAutoParams.numColorBands,
          isAuto: true
        };

        const palette = {
          default: jsonPal,
          auto: jsonPalAuto
        };
        promise.resolve(palette);
      }).fail(err => {
        promise.reject(err);
      });
  });

  return promise;
};

module.exports = new OceanoAnimationHelper();
