const $ = require('jquery');
const _ = require('underscore');
const moment = require('moment');
const ShomView = require('../../../core/shom-view');
const template = require('../../../../template/ddm/ddm-dl/ddm-dl-table.hbs');
const DDM = require('../../../service/ddm.js');
const Loading = require('../../../utils/loading');
const DDMWorkingButton = require('../working-button.js');
const { DATE_DL_FORMAT } = require('../../../utils/constants');

module.exports = ShomView.build({

  initialize(options) {
    this._router = options.router || window.ROUTER;
    this._ddmRouter = options.ddmRouter;
    this.config = options.config || window.CONFIG;
    this._sensorId = options.sensorId;
    this._ddmRouter.on('renderMainView', _.bind(this.renderTable, this));
    this._parentView = options.parentView;
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template());
    return this;
  },

  postTranslate() {
    if (this.tideGaugeTable) {
      this.renderTable();
    }
  },

  renderTable() {
    if (this.tideGaugeTable) {
      this.tideGaugeTable.destroy();
    }

    const $container = this.$el.find('#ddm-dl-table-container');

    Loading.start($container);

    DDM(this.config).getDlTideGauges()
      .then(this._extractSourceStat)
      .then(tideGauges => {
        this._initTable(tideGauges);
        Loading.stop($container);
      })
      .fail(err => {
        console.error(err);
        Loading.stop($container);
      });
  },

  /** ***************************** */
  /** ****** GETTER FUNCTION ****** */
  /** ***************************** */

  getSelectedIds() {
    const selection = this.tideGaugeTable.rows({ selected: true }).data();

    if (selection.length === 0) {
      return [];
    }

    const ids = selection.reduce((tmpIds, row) => {
      tmpIds.push(row.id);
      return tmpIds;
    }, []);

    return ids;
  },

  /** ***************************** */
  /** ****** UTILS FUNCTION ******* */
  /** ***************************** */

  validate() {
    if (this.tideGaugeTable.rows({ selected: true }).data().length > 0) {
      return '';
    }
    return $.i18n.t('ddm.dl.alertForm.table');
  },

  _extractSourceStat(tideGauges) {
    const getSourceStat = function (stat) {
      this[`source${stat.source}_dates`] = `${stat.first_date} ${$.i18n.t('ddm.dl.select.to')} ${stat.last_date}`;
    };

    return tideGauges.map(td => {
      if (!td.stats) {
        return;
      }
      td.stats.map(getSourceStat, td);
      td.min_date = td.min_date ? moment.utc(td.min_date).format(DATE_DL_FORMAT) : undefined;
      td.max_date = td.max_date ? moment.utc(td.max_date).format(DATE_DL_FORMAT) : undefined;
      return td;
    });
  },

  _getTableLanguage() {
    return {
      search: $.i18n.t('ddm.dl.select.search'),
      lengthMenu: $.i18n.t('ddm.dl.select.lengthMenu'),
      infoFiltered: $.i18n.t('ddm.dl.select.infoFiltered'),
      info: $.i18n.t('ddm.dl.select.info'),
      emptyTable: $.i18n.t('ddm.dl.select.emptyTable'),
      paginate: {
        first: $.i18n.t('ddm.dl.select.paginate.first'),
        previous: $.i18n.t('ddm.dl.select.paginate.previous'),
        next: $.i18n.t('ddm.dl.select.paginate.next'),
        last: $.i18n.t('ddm.dl.select.paginate.last')
      },
      aria: {
        sortAscending: $.i18n.t('ddm.dl.select.aria.sortAscending'),
        sortDescending: $.i18n.t('ddm.dl.select.aria.sortDescending')
      },
      select: {
        rows: $.i18n.t('ddm.dl.select.row')
      }
    };
  },

  _getColumnDef() {
    const defaultTranslation = $.i18n.t('ddm.dl.select.default');
    const columns = [{
      data: null,
      className: 'control ddm-select-collapse',
      width: '1%',
      orderable: false,
      defaultContent: '',
      render() {
        return `<button title="${$.i18n.t('ddm.dl.select.tooltip')}" class="button-collapsed-details"><i class="material-icons">add_box</i></button>`;
      }
    }, {
      data: null,
      searchable: false,
      orderable: false,
      className: 'ddm-select-checkbox-cell',
      width: '1%',
      render() {
        return '<input id=\'ddm-dl-select-all\' type=\'checkbox\'>';
      }
    }, {
      data: 'id',
      title: $.i18n.t('ddm.dl.select.title.id'),
      defaultContent: defaultTranslation
    }, {
      data: 'state',
      title: $.i18n.t('ddm.dl.select.title.state'),
      defaultContent: defaultTranslation,
      render(data) {
        return new DDMWorkingButton({
          infoStation: {
            working: data
          }
        }).render().$el.html();
      }
    }, {
      data: 'name',
      title: $.i18n.t('ddm.dl.select.title.name'),
      defaultContent: defaultTranslation
    }, {
      data: 'zone',
      title: $.i18n.t('ddm.dl.select.title.zone'),
      defaultContent: defaultTranslation
    }, {
      data: 'producer',
      title: $.i18n.t('ddm.dl.select.title.producer'),
      defaultContent: defaultTranslation
    }, {
      data: 'min_date',
      title: $.i18n.t('ddm.dl.select.title.min_date'),
      defaultContent: defaultTranslation,
      searchable: false
    }, {
      data: 'max_date',
      title: $.i18n.t('ddm.dl.select.title.max_date'),
      defaultContent: defaultTranslation,
      searchable: false
    }];

    for (let i = 1; i <= 6; i++) {
      columns.push({
        data: `source${i}_dates`,
        title: $.i18n.t(`ddm.labels.source${i}`),
        defaultContent: defaultTranslation,
        searchable: false
      });
    }

    return columns;
  },

  _initTable(tideGauges) {
    const translation = this._getTableLanguage();
    const columns = this._getColumnDef();

    this.tideGaugeTable = $('#tidegauge-table').DataTable({
      retrieve: true,
      data: tideGauges,
      language: translation,
      columns,
      order: [[4, 'asc']],
      select: {
        style: 'multi',
        selector: '.ddm-select-checkbox-cell input'
      },
      responsive: {
        details: {
          type: 'none',
          target: '.button-collapsed-details'
        }
      }
    });

    this._$checkBox = this.$el.find('th.ddm-select-checkbox-cell input');
    this._$checkBox.on('click', _.bind(this._onSelectAllClick, this));

    this.tideGaugeTable
      .on('select', _.bind(this._onChangeSelected, this))
      .on('deselect', _.bind(this._onChangeSelected, this))
      .on('draw', _.bind(this._adjustColumns, this));
    $(window).on('resize', _.bind(this._adjustColumns, (this)));

    if (this._sensorId) {
      const row = this.tideGaugeTable.row((idx, data) => data.id === this._sensorId);
      const node = row.node();

      row.select();

      const pageInfos = this.tideGaugeTable.table().page.info();
      const rowPosition = this.tideGaugeTable.table().rows()[0].indexOf(row.index());

      if (node !== null) {
        const newPage = Math.floor(rowPosition / pageInfos.length);
        this.tideGaugeTable.table().page(newPage).draw('page');
        $('input[type="checkbox"]', node).prop('checked', true);
      }
    }
  },

  /** ***************************** */
  /** ****** EVENT FUNCTION ******* */
  /** ***************************** */

  _onChangeSelected() {
    const selectedRows = this.getSelectedIds();
    this.trigger('change:selectedRows', selectedRows);
  },

  _adjustColumns() {
    this.tideGaugeTable.columns.adjust();
  },

  _onSelectAllClick(ev) {
    const isChecked = ev.target.checked;
    const rows = this.tideGaugeTable.rows({ search: 'applied' });
    if (isChecked) {
      rows.select();
    } else {
      rows.deselect();
    }
    const nodes = rows.nodes();
    $('input[type="checkbox"]', nodes).prop('checked', isChecked);
  },

  _onClose() {
    this._$checkBox && this._$checkBox.off('click');
  }
});
