import AtlasHelper from '../../utils/atlas/atlas-utils';

const $ = require('jquery');
const _ = require('underscore');
const ShomView = require('../../core/shom-view');

const ToastrUtil = require('../../utils/toastr.js');
const OceanoLayerUtils = require('../../utils/oceano/oceano-layer');

const ResultModalView = require('./ocea-transect-result-modal.view.js');
const ResultErrorModalView = require('./ocea-result-error-modal.view');

const template = require('../../../template/oceano/ocea-transect.hbs');

module.exports = ShomView.build({

  events: {
    'click input[type="button"]': '_onButtonClick'
  },

  initialize(options = {}) {
    this._router = options.router || window.ROUTER;
    this._gisView = options.gisView || window.GISVIEW;
    this._parentView = options.parentView;
    this._config = options.config || window.CONFIG;

    this._started = false;
    this._first = false;
    this._layer = null;
    this._index = options.index;

    this._modalView = options.modalView || window.MODALVIEW;
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({
      index: this._index,
      isTransectEnabled: !this._gisView.isGlobeEnabled()
    }));

    this._$toggleButton = this.$el.find(`#ocea-btn-transect-slider-${this._index}`);
    this._$selectorButton = this.$el.find(`#ocea-btn-transect-selector-${this._index}`);

    this._$selectorButton.on('click', _.bind(this._onButtonClick, this));
    return this;
  },

  /** ***************************** */
  /** ****** EVENT FUNCTION ******* */
  /** ***************************** */

  _onButtonClick(evt) {
    if (this._gisView.isGlobeEnabled()) {
      return;
    }
    evt.preventDefault();
    this._started ? this.trigger('change:stopTransect') : this.trigger('change:startTransect');
  },

  onClose() {
    if (this._started) {
      this.stopTransect();
    }
  },

  /** ***************************** */
  /** ***** TRANSECT FUNCTION ***** */
  /** ***************************** */

  _firstPointHandler(coord) {
    const pixel = this._gisView.currentPixel;
    this._layer = this._gisView.getTopNcwmsLayerOnCoord(coord, 'NCWMS', this._parentView.getTileGrid(), this.model, pixel);

    if (this._layer === null) {
      this._buildAndShowError('oceano.modal.transect_error.message', 'oceano.modal.transect_error.title');
    } else {
      this._gisView.highlightNcwmsLayers(this._layer);
    }
  },

  _lastPointHandler(coords) {
    const lineString = this._buildLineCoordString(coords);

    ToastrUtil.clear();
    if (this._isOnlyOnePoint(coords)) {
      this._buildAndShowError('oceano.modal.transect.error', 'oceano.modal.transect.title');
    } else {
      this._buildAndShowResult({
        linestring: lineString
      });
    }

    this._layer = null;

    this._gisView.resetNcwmsOpacity();
  },

  _getLayerTranslatedTitle(layerModel) {
    return (AtlasHelper.isAtlasLayer(layerModel) ? AtlasHelper.getLayerTitlesAtlas(this.model)
      : OceanoLayerUtils.getTitleTranslateLayer(layerModel))[window.portalLang];
  },

  _buildAndShowResult(options) {
    const optionsToUse = options || {};
    const modalTitle = this._getLayerTranslatedTitle(this.model);

    const result = new ResultModalView(
      {
        layer: this._layer,
        model: this.model,
        title: 'oceano.modal.transect.title',
        title_option: modalTitle,
        linestring: optionsToUse.linestring
      }
    );

    this._modalView.showWithoutBackdrop(result);

    this._modalView.$el.one('hide.bs.modal', _.bind(function () {
      this.stopTransect();
      this.startTransect();
    }, this));
  },

  _buildAndShowError(error, title) {
    const result = new ResultErrorModalView(
      {
        message: error,
        title
      }
    );
    this._modalView.show(result);

    this._modalView.$el.one('hide.bs.modal', _.bind(function () {
      this._restartTransect();
    }, this));
  },

  startTransect() {
    if (this._started) {
      return;
    }
    this._started = true;

    ToastrUtil.info($.i18n.t('oceano.toaster.transect'));

    this._$toggleButton.prop('checked', true);

    // start transect with handlers and get destructor
    this._gisView.startTransect(
      _.bind(function (event) {
        this._firstPointHandler(event);
      }, this),
      _.bind(function (event) {
        this._lastPointHandler(event);
      }, this)
    );
  },

  stopTransect() {
    ToastrUtil.clear();

    if (this._started !== true) {
      return;
    }
    this._started = false;
    this._first = false;
    this._layer = null;

    // call destructor
    this._gisView.stopTransect();

    // reset opacity
    this._gisView.resetNcwmsOpacity();

    this._$toggleButton.prop('checked', false);
  },

  _restartTransect() {
    this.stopTransect();
    this.startTransect();
  },

  /** ***************************** */
  /** ****** UTILS FUNCTION ******* */
  /** ***************************** */

  _buildLineCoordString(points) {
    let coords = '';
    for (let i = 0; i < points.length; i++) {
      coords += `${points[i][0]}%20${points[i][1]},`;
    }
    // remove last ,
    coords = coords.substring(0, coords.length - 1);
    return coords;
  },

  _isOnlyOnePoint(points) {
    return points.length === 2
            && points[0][0] === points[1][1]
            && points[0][1] === points[1][1];
  }
});
