const Backbone = require('backbone');
const TopShareMenuView = require('../view/top-share-menu.view');
const LeftMenuView = require('../view/left-menu.view');
const DataMenuRouter = require('./data-menu-router.js');
const DrawingMenuRouter = require('./drawing-menu-router.js');
const OceanogrammeMenuRouter = require('./oceanogramme-menu-router.js');

module.exports = Backbone.Router.extend({
  initialize(options = {}) {
    this._config = options.config || window.CONFIG;

    this.route(/^(^$|affichage|donnees|oceanogramme|contact)$/, 'openMenu');

    this._dynamicRenderer = options.dynamicRenderer;
    this._staticRenderer = options.staticRenderer;

    this._dataMenuRouter = options.dataMenuRouter || new DataMenuRouter({
      dynamicRenderer: this._dynamicRenderer,
      staticRenderer: this._staticRenderer
    });
    this._drawingMenuRouter = options.drawingMenuRouter || new DrawingMenuRouter({
      dynamicRenderer: this._dynamicRenderer,
      menuRouter: this
    });

    this._oceanogrammeRouter = options._oceanogrammeRouter
            || new OceanogrammeMenuRouter({ renderer: this._dynamicRenderer, staticRenderer: this._staticRenderer });
  },

  openMenu(section) {
    if (!this._leftMenuView || this._dynamicRenderer.currentLeftView !== this._leftMenuView) {
      this._leftMenuView = new LeftMenuView();
      this._dynamicRenderer.renderLeftView(this._leftMenuView);
    }

    if (!this._topShareMenuView || this._dynamicRenderer.currentTopView !== this._topShareMenuView) {
      this._topShareMenuView = new TopShareMenuView();
      this._dynamicRenderer.renderTopView(this._topShareMenuView, '#panel-my-share');
    }

    this._staticRenderer.showStaticOLView();

    return this._leftMenuView.openMenu(section);
  },

  getDataMenuRouter() {
    return this._dataMenuRouter;
  }

});
