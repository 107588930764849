const _ = require('underscore');
const moment = require('moment');

const OceanoDimensionUtils = function () {

};

OceanoDimensionUtils.prototype.parseDimensions = function (dimensionsRaw) {
  let elevation = [0];
  let processedTime = {};
  if (!dimensionsRaw || dimensionsRaw.length === 0) {
    return {
      elevation: [0],
      processedTime: {}
    };
  }

  for (let dim = 0, len = dimensionsRaw.length - 1; dim <= len; dim++) {
    if (dimensionsRaw[dim].name === 'time') {
      const values = dimensionsRaw[dim].values.split(',');
      for (const i in values) {
        if (!values.hasOwnProperty(i)) {
          continue;
        }
        processedTime = this._parseOneDimension(values[i], processedTime);
      }
    } else if (dimensionsRaw[dim].name === 'elevation') {
      const elevations = dimensionsRaw[dim].values;
      if (elevations) {
        elevation = elevations.split(',');
      }
    }
  }

  dimensionsRaw.processedTime = processedTime;
  dimensionsRaw.elevation = elevation;
  return dimensionsRaw;
};

OceanoDimensionUtils.prototype._parseOneDimension = function (dimension, processedTime) {
  const splitDimension = this._removeNewlines(dimension).split('/');

  processedTime = (this._isPeriod(splitDimension))
    ? this._parsePeriod(splitDimension, processedTime)
    : this._parseTime(splitDimension, processedTime);

  return processedTime;
};

OceanoDimensionUtils.prototype._parseTime = function (time, processedTime) {
  const splitTime = this._removeNewlines(time).split('T');
  const date = splitTime[0];
  const hour = splitTime[1];
  processedTime = this._addTimeToDate(processedTime, date, hour);
  return processedTime;
};

OceanoDimensionUtils.prototype._parsePeriod = function (splitPeriod, processedTime) {
  const dateStart = new Date(splitPeriod[0]);
  const dateEnd = new Date(splitPeriod[1]);
  const period = splitPeriod[2];
  let dateTmp = dateStart;
  const dictionnaryPeriod = this._getPeriodInDictionnary(period);

  while (dateTmp.getTime() <= dateEnd.getTime()) {
    const strSplitDateISO = dateTmp.toISOString().split('T');
    const strDate = strSplitDateISO[0];
    const strHour = strSplitDateISO[1];
    processedTime = this._addTimeToDate(processedTime, strDate, strHour);
    dateTmp = this._addPeriodToDate(dictionnaryPeriod, dateTmp);
  }

  return processedTime;
};

OceanoDimensionUtils.prototype._addPeriodToDate = function (dictionnaryPeriod, date) {
  const yearToAdd = dictionnaryPeriod.Y || 0;
  const monthToAdd = dictionnaryPeriod.M || 0;
  const dayToAdd = dictionnaryPeriod.D || 0;
  const hourToAdd = dictionnaryPeriod.H || 0;
  const minuteToAdd = dictionnaryPeriod.m || 0;
  const secondToAdd = dictionnaryPeriod.S || 0;

  return moment(date)
    .add(yearToAdd, 'year')
    .add(monthToAdd, 'month')
    .add(dayToAdd, 'day')
    .add(hourToAdd, 'hour')
    .add(minuteToAdd, 'minute')
    .add(secondToAdd, 'second')
    .toDate();
};

OceanoDimensionUtils.prototype._getPeriodInDictionnary = function (period) {
  // split period in dictionnary
  // m for minute, M for month
  const dictionnaryPeriod = {};
  const splitPeriod = period.match(/((\d+)(?=[\D])\D)|[T]/g);
  let isDate = true;
  for (const i in splitPeriod) {
    if (splitPeriod.hasOwnProperty(i)) {
      const element = splitPeriod[i];
      if (element === 'T') {
        isDate = false;
      } else {
        const splitElement = element.split(/(\D)/);
        const value = splitElement[0];
        let control = splitElement[1];
        control = (control === 'M' && !isDate) ? 'm' : control;
        dictionnaryPeriod[control] = +value || 0;
      }
    }
  }
  return dictionnaryPeriod;
};

OceanoDimensionUtils.prototype._removeNewlines = function (str) {
  str = str.toString().trim().replace(/(\r\n|\n|\r)/g, '');
  return str;
};

OceanoDimensionUtils.prototype._addTimeToDate = function (processedTime, date, hour) {
  if (!processedTime[date]) {
    processedTime[date] = [];
  }
  processedTime[date].push(hour);
  return processedTime;
};

OceanoDimensionUtils.prototype._isPeriod = function (element) {
  return element.length === 3;
};

OceanoDimensionUtils.prototype.getUnionDimensions = function (layers) {
  const processedTime = {};
  let elevation = [];

  _.each(layers, layer => {
    const ncwmsLayer = layer.getLayers().getArray()[0];
    const layerTimes = ncwmsLayer.get('dimensions').processedTime;

    const timeDiff = _.difference(
      Object.keys(layerTimes),
      Object.keys(processedTime)
    );

    for (const i in timeDiff) {
      // eslint-disable-next-line no-prototype-builtins
      if (timeDiff.hasOwnProperty(i)) {
        const date = timeDiff[i];
        processedTime[date] = layerTimes[date];
      }
    }

    const layerElevation = ncwmsLayer.get('dimensions').elevation;
    elevation = _.union(elevation, layerElevation);
  });

  return {
    processedTime,
    elevation
  };
};

module.exports = new OceanoDimensionUtils();
