const Backbone = require('backbone');
const TopAccountMenuView = require('../view/top-account-menu.view');
const UserMenuRouter = require('./user-menu-router.js');

module.exports = Backbone.Router.extend({
  initialize(options = {}) {
    this._config = options.config || window.CONFIG;

    this.route(/^(^abonne)$/, 'openMenu');

    this._dynamicRenderer = options.dynamicRenderer;
    this._staticRenderer = options.staticRenderer;

    this._userMenuRouter = options.userMenuRouter || new UserMenuRouter({ renderer: this._dynamicRenderer, menuRouter: this });
  },

  openMenu(section) {
    if (!this._topAccountMenuView || this._dynamicRenderer.currentLeftView !== this._topAccountMenuView) {
      this._topAccountMenuView = new TopAccountMenuView();
      this._dynamicRenderer.renderTopView(this._topAccountMenuView, '#my-account');
    }

    return this._topAccountMenuView.openMenu(section);
  }
});
