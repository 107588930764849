import Collection from 'ol/Collection';
import TileWMSSource from 'ol/source/TileWMS';
import TileLayer from 'ol/layer/Tile';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { default as WMTSSource, optionsFromCapabilities } from 'ol/source/WMTS';
import LayerGroup from 'ol/layer/Group';
import GeoJSON from 'ol/format/GeoJSON';
import { boundingExtent } from 'ol/extent.js';

import { get as getProjection } from 'ol/proj.js';
import Point from 'ol/geom/Point';
import { NCWMS_LAYERTYPE_ATLAS } from '../constants';
import OceanoHelper from './oceano-helper';
import AtlasHelper from '../atlas/atlas-utils';

require('proj4');
const $ = require('jquery');
const _ = require('underscore');

const async = require('async');
const OceanoDimensionUtils = require('../oceano/oceano-dimensions.js');
const CoordsHelper = require('./coordinates-helper.js');
const OceanoNcwmsUtils = require('../oceano/oceano-ncwms.js');
const Utils = require('./gfi-utils');

let _config = null;

const CapabilitiesLayersFactory = function (options) {
  const optionsToUse = options || {};
  _config = optionsToUse.config || window.CONFIG;
};

CapabilitiesLayersFactory.prototype.build = function (capabilities, ogcService, url, version, opts = {}) {
  const rawLayers = this.getRawLayersFromCapabilities(capabilities, ogcService);
  if (rawLayers) {
    return this.createEachLayers(rawLayers, ogcService, url, version, capabilities, opts);
  }

  const promise = $.Deferred();
  console.error('Missing rawLayers');
  promise.reject(new Error('Missing rawLayers'));
  return promise;
};

CapabilitiesLayersFactory.prototype.createEachLayers = function (rawLayers, ogcService, url, version, capabilities, options) {
  const promise = $.Deferred();
  let layers = [];
  let ncwmsLayers = {};
  const isNCWMSService = ogcService === 'NCWMS';

  async.eachSeries(rawLayers, (rawLayer, callback) => {
    const lay = this.instanciateLayer(rawLayer, ogcService, url, version, capabilities, options);
    if (isNCWMSService) {
      const modelSplitChar = (options && options.isFromContextArchive) ? '/' : '_';
      ncwmsLayers = this._addToNcwmsLayers(ncwmsLayers, lay, modelSplitChar, options);
    } else {
      layers.push(lay);
    }

    async.setImmediate(callback);
  }, _.bind(function () {
    if (isNCWMSService) {
      layers = this._buildNCWMSGroups(ncwmsLayers);
    }

    promise.resolve(layers);
  }, this));
  return promise;
};

CapabilitiesLayersFactory.prototype.getRawLayersFromCapabilities = function (capabilities, ogcService) {
  switch (ogcService) {
    case 'NCWMS':
      if (capabilities && capabilities.Capability && capabilities.Capability.Layer
        && capabilities.Capability.Layer.Layer && capabilities.Capability.Layer.Layer.length > 0) {
        return capabilities.Capability.Layer.Layer;
      }
      break;
    case 'WMS':
      if (capabilities && capabilities.Capability && capabilities.Capability.Layer
        && capabilities.Capability.Layer.Layer && capabilities.Capability.Layer.Layer.length > 0) {
        return this._getWMSLayer(capabilities.Capability.Layer.Layer, capabilities.Capability.Request);
      }
      break;
    case 'WFS':
      if (capabilities && capabilities.featureTypeList
        && capabilities.featureTypeList.featureType && capabilities.featureTypeList.featureType.length > 0) {
        return capabilities.featureTypeList.featureType;
      }
      break;
    case 'WMTS':
      if (capabilities && capabilities.Contents && capabilities.Contents.Layer && capabilities.Contents.Layer.length > 0) {
        return capabilities.Contents.Layer;
      }
      break;
  }
  return null;
};

CapabilitiesLayersFactory.prototype._getWMSLayer = function (layers, request) {
  let layersToBuild = [];
  for (let lay = 0, len = layers.length; lay < len; lay++) {
    const layer = layers[lay];
    if (layer.Layer && layer.Layer.length > 0) {
      const subLayers = this._getWMSLayer(layer.Layer, request);
      layersToBuild = layersToBuild.concat(subLayers);
    } else {
      if (request && request.GetFeatureInfo) {
        layer.INFO_FORMAT = request.GetFeatureInfo.Format || [];
      }

      layer.url = Utils.getBaseRequestUrl(request);
      layersToBuild.push(layer);
    }
  }
  return layersToBuild;
};

CapabilitiesLayersFactory.prototype.instanciateLayer = function (rawLayer, ogcService, url, version, capabilities, options) {
  let layerType;
  if (options) {
    layerType = options.layerType || null;
  }
  switch (ogcService) {
    case 'WMS':
      const params = {
        FORMAT: 'image/png',
        VERSION: version,
        LAYERS: rawLayer.Name
      };
      const maxExtent = this._buildWMSExtent(rawLayer);

      const layer = new TileLayer({
        title: rawLayer.Title || rawLayer.Name,
        identifier: rawLayer.Name,
        abstract: rawLayer.Abstract || rawLayer.Name,
        maxExtent,
        external: true,
        source: new TileWMSSource({
          url,
          crossOrigin: 'anonymous',
          params,
          wrapX: true
        })
      });

      return layerType === 'PARTNER' ? this._addPartnerDataOnLayer(layer, rawLayer) : layer;
    case 'WFS':
      const title = `${rawLayer.name.prefix}:${rawLayer.name.localPart}`;
      let sourceUrl = `${url}?service=WFS`;
      sourceUrl += `&srsName=${_config.projection}`;
      sourceUrl += `&version=${version}`;
      sourceUrl += '&request=GetFeature';
      sourceUrl += '&outputFormat=application/json';
      sourceUrl += `&typeName=${title}`;

      return new VectorLayer({
        title,
        identifier: title,
        abstract: rawLayer._abstract,
        external: true,
        source: new VectorSource({
          url: sourceUrl,
          crossOrigin: 'anonymous',
          format: new GeoJSON(),
          wrapX: true
        }),
        url: sourceUrl
      });
    case 'WMTS':
      const wmtsOptions = optionsFromCapabilities(
        capabilities,
        { layer: rawLayer.Identifier, matrixSet: 'EPSG:4326' }
      );
      wmtsOptions.crossOrigin = 'anonymous';
      if (wmtsOptions.urls && wmtsOptions.urls.length > 0) {
        wmtsOptions.urls[0] = url;
      }
      let maxWMTSExtent = null;
      if (rawLayer.WGS84BoundingBox) {
        maxWMTSExtent = this.getMaxExtent(rawLayer);
      }

      wmtsOptions.wrapX = true;
      const layerWMTS = new TileLayer({
        title: rawLayer.Title || rawLayer.Name,
        identifier: rawLayer.Identifier,
        external: true,
        maxExtent: maxWMTSExtent,
        source: new WMTSSource(wmtsOptions)
      });

      return layerType === 'PARTNER' ? this._addPartnerDataOnLayer(layerWMTS, rawLayer) : layerWMTS;
    case 'NCWMS':
      const layers = rawLayer.Layer;
      const olLayers = [];
      const tileGrid = OceanoHelper.createTileGrid();

      for (let i = 0; i < layers.length; i++) {
        let regionParamLayerGroup;
        if (layers[i].Layer) {
          regionParamLayerGroup = this._buildNcwmsRegionLayer(layers[i].Layer[0], url, version, tileGrid, rawLayer, layers[i], options);
        } else {
          regionParamLayerGroup = this._buildNcwmsRegionLayer(layers[i], url, version, tileGrid, rawLayer, null, options);
        }
        olLayers.push(regionParamLayerGroup);
      }
      return olLayers;
    default:
      return false;
  }
};

CapabilitiesLayersFactory.prototype._buildWMSExtent = function (rawLayer) {
  let maxExtent = null;

  if (rawLayer.EX_GeographicBoundingBox) {
    const bounds = rawLayer.EX_GeographicBoundingBox;
    const coord = CoordsHelper.convertLonLatBboxToMercator(bounds);
    maxExtent = boundingExtent(
      [
        [coord[0], coord[1]],
        [coord[2], coord[3]]
      ]
    );
  }

  return maxExtent;
};

CapabilitiesLayersFactory.prototype._buildNCWMSGroups = function (ncwmsLayers) {
  const layers = [];
  for (const model in ncwmsLayers) {
    // eslint-disable-next-line no-prototype-builtins
    if (!ncwmsLayers.hasOwnProperty(model)) {
      continue;
    }
    for (const param in ncwmsLayers[model]) {
      // eslint-disable-next-line no-prototype-builtins
      if (!ncwmsLayers[model].hasOwnProperty(param)) {
        continue;
      }
      const first = ncwmsLayers[model][param][0];
      const unionDim = OceanoDimensionUtils.getUnionDimensions(ncwmsLayers[model][param]);
      const grp = new LayerGroup({
        groupType: 'NCWMS_param',
        title: first.get('title'),
        identifier: `${model}/${param}`,
        abstract: first.get('abstract'),
        dimensions: unionDim,
        layers: ncwmsLayers[model][param]
      });
      layers.push(grp);
    }
  }
  return layers;
};

/**
 * Format layer to right format and append it to ncwmsLayers array
 * @param {object} ncwmsLayers
 * @param {ol.Layer} lay
 * @param {string|undefined} modelSplitChar split chararcter to define limit of model name from layer "identfier" string
 *  all characters before '_' (false)
 * @returns {Array<ol.Layer>}
 * @private
 */
CapabilitiesLayersFactory.prototype._addToNcwmsLayers = function (ncwmsLayers, lay, modelSplitChar = '_', options = {}) {
  for (let i = 0, len = lay.length; i < len; i++) {
    const olLayer = lay[i];
    const identifier = olLayer.get('identifier');
    let model = identifier.split(modelSplitChar)[0];
    const splitId = identifier.split('/');
    const param = splitId[splitId.length - 1];

    const isAtlasLayerType = options.ncwmsLayerType === NCWMS_LAYERTYPE_ATLAS;
    if (isAtlasLayerType) {
      const numAtlas = AtlasHelper.getAtlasNum(splitId[0]);
      model = `${model}_${numAtlas}`;
    }

    if (!ncwmsLayers[model]) {
      ncwmsLayers[model] = {};
      ncwmsLayers[model][param] = [];
    } else if (!ncwmsLayers[model][param]) {
      ncwmsLayers[model][param] = [];
    }
    ncwmsLayers[model][param].push(olLayer);
  }

  return ncwmsLayers;
};

CapabilitiesLayersFactory.prototype._buildNcwmsRegionLayer = function (lay, url, version, tileGrid, parentLayer, group, options = {}) {
  // If layer is loaded from archive context
  const { isFromContextArchive } = options;
  const isAtlasLayerType = options.ncwmsLayerType === NCWMS_LAYERTYPE_ATLAS;
  const bbox = OceanoNcwmsUtils.getMercatorBoundingBoxFromRawLayer(lay);
  const strBbox = bbox.join(',');

  const name = isFromContextArchive ? lay.Name : this._getNcwmsLayerName(lay, parentLayer, group, isAtlasLayerType);
  const title = group ? group.Title : lay.Title;
  const abstract = group ? group.Abstract : lay.Abstract;

  const params = {
    FORMAT: 'image/png',
    VERSION: version,
    LAYERS: name,
    BBOX: strBbox
  };

  const regionExtent = boundingExtent([
    [bbox[0], bbox[1]],
    [bbox[2], bbox[3]]
  ]);
  const tempUrl = isAtlasLayerType ? _config.atlas.ncwms_cached : _config.oceano.ncwms_cached;
  const cachedUrl = isFromContextArchive ? url : (tempUrl || url);

  let model = name.split('_')[0];
  const param = name.split('/')[1];

  if (isAtlasLayerType) {
    const numAtlas = AtlasHelper.getAtlasNum(name.split('/')[0]);
    model = `${model}_${numAtlas}`;
  }
  const parentIdentifier = isFromContextArchive ? name : (`${model}/${param}`);

  // region group
  const layerParams = {
    title: title || name,
    identifier: name,
    abstract: abstract || name,
    parentIdentifier,
    regExtent: regionExtent
  };

  const regionParamLayerGroup = new LayerGroup(layerParams);

  // ncwms layer
  const dim = OceanoDimensionUtils.parseDimensions(lay.Dimension);
  const source = new TileWMSSource({
    url: cachedUrl,
    crossOrigin: 'anonymous',
    params,
    tileGrid,
    wrapX: true
  });

  const NCWMSLayer = new TileLayer(_.extend(layerParams, {
    source,
    type: 'NCWMS',
    opacity: 1,
    dimensions: dim,
    // Ol6 recommandation to avoid false positive with forEachLayerAtPixel
    className: `ol-layer ncwms-tile-${name}`
  }));

  // encoding layer
  const encodingLayerName = OceanoNcwmsUtils.getIdWithNcwmsValue(name);
  const encodingConfig = _config.oceano.encoding;
  const encodingParams = {
    FORMAT: 'image/png',
    VERSION: version,
    LAYERS: encodingLayerName,
    BBOX: strBbox,
    STYLES: `default-scalar/${encodingConfig.pal}`,
    BELOWMINCOLOR: encodingConfig.belowMinColor,
    ABOVEMAXCOLOR: encodingConfig.aboveMaxColor,
    NUMCOLORBANDS: encodingConfig.numColorBands
  };
  const encodingSource = new TileWMSSource({
    url: cachedUrl,
    crossOrigin: 'anonymous',
    params: encodingParams,
    tileGrid,
    wrapX: true
  });
  const encodingNCWMSLayer = new TileLayer(_.extend(layerParams, {
    source: encodingSource,
    type: 'NCWMSEncoding',
    // set a small opacity for gfi
    opacity: 0.005
  }));

  regionParamLayerGroup.setLayers(new Collection([encodingNCWMSLayer, NCWMSLayer]));

  return regionParamLayerGroup;
};

CapabilitiesLayersFactory.prototype._getNcwmsLayerName = function (lay, parentLayer, group, isAtlasLayerType) {
  let parentTitle;
  const splitChar = isAtlasLayerType ? '/' : '_';

  const parentTitleArr = parentLayer.Title ? parentLayer.Title.split(splitChar) : null;

  if (!parentTitleArr) {
    // You should never go into this if branch
    console.error('No ncwms layer title defined for : ', parentLayer, lay);
    parentTitle = '';
  } else {
    if (parentTitleArr.length > 1) {
      parentTitleArr.pop();
    }
    parentTitle = parentTitleArr.join('_');
  }

  const title = group ? group.Title : lay.Title;
  return `${parentTitle}/${title}`;
};

CapabilitiesLayersFactory.prototype._addPartnerDataOnLayer = function (partnerLayer, rawLayer) {
  const legendUrl = Utils.getLegendUrl(rawLayer);
  const metadataURL = Utils.getMetadataUrl(rawLayer);

  partnerLayer.set('queryable', rawLayer.queryable);
  partnerLayer.set('styles', rawLayer.Style);
  partnerLayer.set('CRS', rawLayer.CRS);
  partnerLayer.set('url', rawLayer.url);
  partnerLayer.set('infoFormat', rawLayer.INFO_FORMAT);
  partnerLayer.set('legendUrl', legendUrl);
  partnerLayer.set('metadataURL', metadataURL);
  partnerLayer.set('layerType', 'PARTNER');

  return partnerLayer;
};

CapabilitiesLayersFactory.prototype.getMaxExtent = function (layerConf) {
  if (layerConf && layerConf.WGS84BoundingBox) {
    const bounds = layerConf.WGS84BoundingBox;
    const geographic = getProjection('EPSG:4326');
    const mercator = getProjection('EPSG:3857');
    const point1 = new Point([bounds[0], bounds[1]]);
    const mercatCoord1 = point1.clone().transform(geographic, mercator);
    const point2 = new Point([bounds[2], bounds[3]]);
    const mercatCoord2 = point2.clone().transform(geographic, mercator);
    return boundingExtent(
      [
        mercatCoord1.getCoordinates(),
        mercatCoord2.getCoordinates()
      ]
    );
  }

  return null;
};

module.exports = CapabilitiesLayersFactory;
