const $ = require('jquery');
const ShomView = require('../core/shom-view');

module.exports = ShomView.build({

  tagName: 'a',

  initialize(options) {
    this._message = options.message;
    this._placement = options.placement || 'right';
    this._className = options.classname || 'info-popover-button pull-right';
    this._popoverClassName = options.popoverClassName;
  },

  render() {
    const message = $.i18n.t(this._message);
    this.$el.addClass(this._className);
    this.$el.addClass(this._position);
    this.$el.attr('tabindex', '0');
    this.$el.attr('role', 'button');
    this.$el.attr('data-content', message);
    this.$el.attr('data-toggle', 'popover');
    this.$el.attr('data-trigger', 'hover focus');
    this.$el.popover({
      html: true,
      delay: {show: 50, hide: 400},
      placement: this._placement,
      container: 'body',
      boundary: 'viewport'
    });

    if (this._popoverClassName) {
      this.$el.data('bs.popover').tip().addClass(this._popoverClassName);
    }

    return this;
  },
});
