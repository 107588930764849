var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<p>\n					<span class=\"info-label\" data-i18n=\"ddm.info.city\"></span> : "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"ville_d_hebergement") : depth0), depth0))
    + "\n				</p> \n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<p>\n					<span class=\"info-label\" data-i18n=\"ddm.info.res-observation\"></span> : "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"resMaregraphique") : depth0), depth0))
    + "\n				</p>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<p>\n					<span class=\"info-label\" data-i18n=\"ddm.info.lat\"></span> : "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"latitude") : depth0), depth0))
    + "\n				</p>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<p>\n					<span class=\"info-label\" data-i18n=\"ddm.info.lon\"></span> : "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"longitude") : depth0), depth0))
    + "\n				</p>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<p>\n					<span class=\"info-label\" data-i18n=\"ddm.info.average-level\"></span> : \n					<a href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"acces_niv_moyen") : depth0), depth0))
    + "\" data-i18n=\"ddm.info.more\" target=\"_blank\"></a>\n				</p>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p>\n          <span class=\"info-label\" data-i18n=\"ddm.info.last-value\"></span> : "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"latest_value") : depth0), depth0))
    + "\n        </p>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p>\n          <span class=\"info-label\" data-i18n=\"ddm.doi\"></span> : <a target=\"_blank\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"doi") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"doi") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</a>\n        </p>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<p>\n					<span class=\"info-label\" data-i18n=\"ddm.info.info\"></span> : \n					<a href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"info_maregraphe") : depth0), depth0))
    + "\" data-i18n=\"ddm.info.more\" target=\"_blank\"></a>\n				</p>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<p>\n					<span class=\"info-label\" data-i18n=\"ddm.info.first-observation\"></span> : \n					"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"date_prem_obs") : depth0), depth0))
    + "\n				</p>	\n";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<p>\n					<span class=\"info-label\" data-i18n=\"ddm.info.inventory\"></span> : \n					<a href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"inventaire") : depth0), depth0))
    + "\" data-i18n=\"ddm.info.more\" target=\"_blank\"></a>\n				</p>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"row\"> \n						<div class=\"col-sm-4\"><span class=\"info-label\"> "
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"due_date") : depth0), depth0))
    + "</span></div>\n						<div class=\"col-sm-8\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"content") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n					</div>\n					<hr/>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<p>\n					<span class=\"info-label\" data-i18n=\"ddm.info.specification\"></span> :\n					<a href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"descriptif_capteur") : depth0), depth0))
    + "\" data-i18n=\"ddm.info.more\" target=\"_blank\"></a>\n				</p>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<p>\n					<span class=\"info-label\" data-i18n=\"ddm.info.state\"></span> : "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"state") : depth0), depth0))
    + "\n				</p>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<p>\n					<span class=\"info-label\" data-i18n=\"ddm.info.collocalisation\"></span> : \n					<a href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"collocalisation") : depth0), depth0))
    + "\" data-i18n=\"ddm.info.more\" target=\"_blank\"></a>\n				</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n	<div class=\"col-sm-4\">\n	<div class=\"panel panel-default\">\n	  	<div class=\"panel-heading\"><span data-i18n=\"ddm.info.about\"></span></div>\n	  	<div class=\"panel-body\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ville_d_hebergement") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":5},"end":{"line":10,"column":10}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"resMaregraphique") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":3},"end":{"line":15,"column":10}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"latitude") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":3},"end":{"line":20,"column":10}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"longitude") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":3},"end":{"line":25,"column":10}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"acces_niv_moyen") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":3},"end":{"line":31,"column":10}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"latest_value") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":6},"end":{"line":36,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"doi") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":6},"end":{"line":41,"column":13}}})) != null ? stack1 : "")
    + "	  	</div>\n	</div>\n	<div class=\"panel panel-default\">\n	  	<div class=\"panel-heading\"><span data-i18n=\"ddm.info.history\"></span></div>\n	  	<div class=\"panel-body\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"info_maregraphe") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":5},"end":{"line":52,"column":10}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"date_prem_obs") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":3},"end":{"line":58,"column":10}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"inventaire") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":3},"end":{"line":64,"column":10}}})) != null ? stack1 : "")
    + "	  	</div>\n	</div>\n	</div>\n	<div class=\"col-sm-4\">	\n		<div class=\"panel panel-default\">\n		  	<div class=\"panel-heading\"><span data-i18n=\"ddm.info.journalbord\"></span></div>\n		  	<div class=\"panel-body ddm-events\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"events") : depth0),{"name":"each","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":6},"end":{"line":78,"column":13}}})) != null ? stack1 : "")
    + "		  	</div>\n		</div>\n	</div>\n	<div class=\"col-sm-4\">\n	<div class=\"panel panel-default\">\n	  	<div class=\"panel-heading\"><span data-i18n=\"ddm.info.equipment\"></span></div>\n	  	<div class=\"panel-body\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"descriptif_capteur") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":6},"end":{"line":91,"column":10}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"state") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":92,"column":3},"end":{"line":96,"column":10}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"collocalisation") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":3},"end":{"line":102,"column":10}}})) != null ? stack1 : "")
    + "			\n	  	</div>\n	</div>\n	</div>\n</div>\n";
},"useData":true});