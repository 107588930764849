const _ = require('underscore');
const ShomView = require('../../core/shom-view');
const template = require('../../../template/oceano/ocea-select-depth.hbs');

module.exports = ShomView.build({

  initialize(options) {
    const optionsToUse = options || {};
    this._values = optionsToUse.values;
    this._index = optionsToUse.index;
    this._blockTitle = optionsToUse.blockTitle || 'oceano.nav.depth.title';
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({
      index: this._index
    }));
    // title
    this.$el.find('#ocean-depth-block-title').attr('data-i18n', this._blockTitle).i18n();
    // render depth
    this._$selectDepth = this.$el.find(`#ocean-depth-select-${this._index}`);
    this._$selectDepth.on('change', _.bind(this._onDepthChange, this));
    this._renderSelectDepth();
    return this;
  },

  _renderSelectDepth(previousDepth) {
    let selectDepth = '';
    if (this._values && this._values.length > 0) {
      for (const i in this._values) {
        // eslint-disable-next-line no-prototype-builtins
        if (this._values.hasOwnProperty(i)) {
          const depth = +this._values[i] || 0;
          const value = +(this._values[i] || 0);
          selectDepth += `<option value="${depth}"`;
          selectDepth += (previousDepth === depth) ? 'selected >' : ' >';
          // TOFIX fix for meteo depth values (https://github.com/Reading-eScience-Centre/edal-java/issues/78)
          const windIdentifier = 'METEO/u:v-group';
          selectDepth += `${this.model.get('identifier') === windIdentifier ? (+value * -1) : value} m</option>`;
        }
      }
    } else {
      selectDepth = '<option value="0" selected>0</option>';
    }

    this._$selectDepth.html(selectDepth);
  },

  setValuesAndRender(values, value) {
    this._values = values;
    let previousValue = value || this.getSelectDepth();
    previousValue = +previousValue || 0;
    this._renderSelectDepth(previousValue);
    this._display();
    return this;
  },

  setDepthAndRender(depthValue) {
    this._renderSelectDepth(depthValue);
    this._display();
    return this;
  },

  _hide() {
    this._$selectDepth.addClass('hidden');
  },

  _display() {
    this._$selectDepth.removeClass('hidden');
  },

  resetAndHide() {
    this._values = null;
    this._renderSelectDepth();
    this._hide();
    return this;
  },

  /** ***************************** */
  /** ****** GETTER FUNCTION ****** */
  /** ***************************** */

  getSelectDepth() {
    return this._$selectDepth.val();
  },

  /** ***************************** */
  /** ****** EVENT FUNCTION ******* */
  /** ***************************** */

  _onDepthChange() {
    this.trigger('change:oceaSelectDepth', {
      depth: this.getSelectDepth()
    });
  },

  /** ***************************** */
  /** ****** UTILS FUNCTION ******* */
  /** ***************************** */

  enableSelect(enabled) {
    if (enabled) {
      this._$selectDepth.removeAttr('disabled');
    } else {
      this._$selectDepth.attr('disabled', 'disabled');
    }
  },

  onClose() {
    this._$selectDepth.off('change');
  }
});
