const $ = require('jquery');

let _config;
let _oceanoUrl;

const DownloadService = module.exports = function (options) {
  options = options || {};
  _config = options.config || window.CONFIG;
  _oceanoUrl = _config.oceano.downloadUrl;
};

DownloadService.prototype.getOceanoResourceSize = function (resource) {
  const promise = $.Deferred();
  const url = _oceanoUrl + resource;

  $.ajax({
    url,
    type: 'GET',
    dataType: 'xml'
  }).done(data => {
    const size = $(data).find('fileSize');
    if (size.length === 0) {
      promise.resolve(0);
    } else {
      promise.resolve(size[0].textContent);
    }
  }).fail(() => {
    promise.resolve(0);
  });

  return promise;
};

// "https://services.data.shom.fr/telechargement/prepackagesearch/hasRight?key={{key}}&metadataId={{metadataId}}",
