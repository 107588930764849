const _ = require('underscore');
const ShomView = require('../../core/shom-view');
const LayerView = require('./oceano-layer.view');
const template = require('../../../template/catalog/oceano-category.hbs');

module.exports = ShomView.build({

  className: 'panel-default panel-category',

  events: {
    'click .panel-heading': '_onHeadingClick'
  },

  initialize(options = {}) {
    this._category = options.category;
    this._itemViews = [];
    this.collection = this.collection || window.LAYERS;
  },

  render() {
    this.$el.html(template({
      category: this._category.name,
      categoryTranslate: this._category.translateName
    }));
    this._$layersRoot = this.$el.find('.category-layers-root');
    this._$collapseLink = this.$('.link-collapse-category');

    this._removeItemViews();
    this._createItemViews();
    this._renderItemViews();
    return this;
  },

  _removeItemViews() {
    _.each(this._itemViews, itemView => itemView.remove());
    this._itemViews = [];
    this._$layersRoot.html('');
  },

  _createItemViews() {
    const layers = this._getSortedLayers();
    _.each(layers, layer => {
      this._itemViews.push(new LayerView({
        model: layer,
        oceano: true
      }));
    });
  },

  _renderItemViews() {
    _.each(this._itemViews, itemView => this._$layersRoot.append(itemView.render().$el));
  },

  _getSortedLayers() {
    const layers = this.collection.filter(
      item => (item.get('identifier') ? item.get('identifier').slice(0, item.get('identifier').indexOf('/')) === this._category.name : false)
    );

    return _.sortBy(layers, layer => layer.get('indexInCat'));
  },

  updateTranslateName(translateName) {
    this._category.translateName = translateName;
    this._$collapseLink.text(translateName);
  },

  _onHeadingClick() {
    const expanded = this.$el.find('.collapse').hasClass('collapse in');
    this.$el.find('.collapse').collapse('toggle');
    const $panelTitle = this.$el.find('.panel-title');
    const $panelTitleLink = this.$el.find('.panel-title > a');
    if (expanded) {
      $panelTitleLink.addClass('collapsed');
      $panelTitle.removeClass('layer-included-in-map');
    } else {
      $panelTitle.addClass('layer-included-in-map');
      $panelTitleLink.removeClass('collapsed');
    }
  }
});
