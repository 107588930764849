import {
  DISPLAY_OCEANOGRAM_MODAL,
  DISPLAY_DL_MODAL,
  CATALOG_REFERENCE,
  SEPARATOR_CATEGORY_LEVEL01,
  MY_LAYERS_CATEGORY,
  CATALOG_DEFAULT_ORDER_INDEX
} from '../utils/constants';

const Backbone = require('backbone');
const $ = require('jquery');
const _ = require('underscore');
const CatalogBuilder = require('../utils/catalog-builder');
const Cookies = require('../utils/cookies.js');

const User = Backbone.Model.extend({

  defaults: {
    lastCatalogRoute: null
  },

  initialize(options = {}) {
    this._config = options.config || window.CONFIG;
    User.__super__.initialize.call(this, options);
    this.sessionRefreshTimer = null;
    this.set('sessionState', 'NONE');
    this._layers = options.layers || window.LAYERS;
    this._catalog = options.catalog || window.CATALOG;

    this._myCategory = {
      name: MY_LAYERS_CATEGORY,
      index: CATALOG_DEFAULT_ORDER_INDEX,
      fullpath: CATALOG_REFERENCE + SEPARATOR_CATEGORY_LEVEL01 + MY_LAYERS_CATEGORY
    };
    if (this._catalog && this._catalog.categories && this._catalog.categories.reference) {
      const myLayers = this._catalog.categories.reference.find(cat => cat.id === MY_LAYERS_CATEGORY);
      if (myLayers) {
        this._myCategory.index = myLayers.index;
        this._myCategory.translate = myLayers.name;
      }
    }

    this._context = {
      [DISPLAY_DL_MODAL]: true,
      [DISPLAY_OCEANOGRAM_MODAL]: true
    };
  },

  authenticate() {
    const defer = $.Deferred();
    let targetUrl = this._config.authUrl;
    const self = this;
    targetUrl = targetUrl.replace('{{key}}', this.get('key'));

    const req = $.ajax({
      type: 'POST',
      url: targetUrl,
      headers: {
        Authorization: this.authHeader()
      }
    });

    req.done(response => {
      if ($.trim(response) !== 'OK') {
        defer.reject(User.AUTH_ISSUES.BAD_CREDENTIALS);
      } else {
        defer.resolve(self);
      }
    });

    req.fail(() => defer.reject(User.AUTH_ISSUES.NETWORK));

    return defer;
  },

  fetchLayers() {
    return CatalogBuilder.build({ urlWMC: this._config.urlWMC, getCaps: this._config.userGetCaps }, this)
      .then(layers => {
        layers.each(l => {
          l.set('category', this._myCategory);
          l.set('isToUser', true);
        });
        this._layers.add(layers.models);
        this.set('userIsLoaded', true);
      })
      .fail(()=> {
        console.error('could not retrieve error layers');
        this.set('userIsLoaded', true);
      });
  },

  fetchCustomLayers() {
    return CatalogBuilder.build(this._config, this);
  },

  authToken() {
    return btoa([this.get('username'), this.get('password')].join(':'));
  },

  authHeader() {
    return `Basic ${this.authToken()}`;
  },

  anonymous() {
    this.set('sessionState', 'NONE');
    this.stopSessionRefreshTimer();
    this.killSession();

    this.set({
      key: '000000000000000000000000',
      username: 'anonymous',
      password: '',
      isLoggedIn: false
    });

    this.set('userIsLoaded', false);

    this._retrieveCookiesInfo();

    _.each(this._layers.where(
      {
        category: this._myCategory
      }
    ), layer => {
      layer.set('includedInMap', false);
      this._layers.remove(layer);
    });
  },

  killSession() {
    if (!this.get('sessid')) {
      return;
    }

    const url = `${this._config.sessionUrl + this.get('sessid')}/kill`;

    const pr = this.doReq(url);

    pr.then(() => {
      $.noop();
    });

    pr.fail(() => {
      console.error('could not kill session');
    });

    this.unset('sessid');
    Cookies.remove('sessid');
    Cookies.remove('userkey');
    Cookies.remove('username');
    if (this._config.isLocal) {
      Cookies.remove('password');
    }
    return pr;
  },

  updateSessionId() {
    const url = `${this._config.sessionUrl}create`;
    const pr = this.doReq(url);

    pr.then(sessionXML => {
      const sessId = sessionXML.getElementsByTagName('Session')[0].textContent;
      this.set('sessid', sessId);
      Cookies.setItem('sessid', sessId);
      Cookies.setItem('userkey', this.get('key'));
      Cookies.setItem('username', this.get('username'));
      if (this._config.isLocal) {
        Cookies.setItem('password', this.get('password'));
      }
    });

    pr.fail(() => {
      console.error('failed to retrieve session id');
    });

    return pr;
  },

  reviveSession() {
    const url = `${this._config.sessionUrl + this.get('sessid')}/keep-alive`;

    return this.doReq(url);
  },

  startSessionRefreshTimer() {
    const recFunc = () => {
      const self = this;
      this.set('sessionState', 'WAITING');
      this.reviveSession()
        .then(() => {
          self.set('sessionState', 'OK');
          self._retrieveCookiesInfo();
          self.sessionRefreshTimer = setTimeout(recFunc, 60 * 1000);
        }).fail(() => {
          self.set('sessionState', 'EXPIRED');
          self.anonymous();
        });
    };

    recFunc();
  },

  stopSessionRefreshTimer() {
    if (this.sessionRefreshTimer !== null) {
      clearTimeout(this.sessionRefreshTimer);
      this.sessionRefreshTimer = null;
    }
  },

  loggedIn(usr) {
    this.set({
      key: usr.get('key'),
      username: usr.get('username'),
      password: usr.get('password'),
      sessid: usr.get('sessid'),
      isLoggedIn: true
    });
    this.set('sessionState', 'WAITING');

    // Cas d'une connexion automatique avec cookie de session
    if (this.get('sessid')) {
      this.startSessionRefreshTimer();
      const q = $.Deferred();
      q.resolve(this);
      return q.promise();
    }
    return this.updateSessionId()
      .then(() => {
        this.startSessionRefreshTimer();
        return this;
      });
  },

  fetchPermissions() {
    const def = $.Deferred();
    $.when(
      this.hasRightsOn('CARTODYN', 'CARTODYN'),
      this.hasRightsOn('CARTODYN', 'CHATCARTO')
    ).done((hasCartoDyn, hasDiscuss) => {
      def.resolve({hasCartoDyn, hasDiscuss});
    }).fail(() => {
      def.resolve({
        hasCartoDyn: false,
        hasDiscuss: false
      });
    });
    return def;
  },

  hasRightsOn(service, resource) {
    if (service === 'LOGGED' && resource === 'IN') {
      return $.Deferred().resolve(this.get('isLoggedIn'));
    }

    if (!this.get('isLoggedIn')) {
      return $.Deferred().resolve(false);
    }

    const defer = $.Deferred();

    let targetUrl = this._config.hasRightsURL;
    targetUrl = targetUrl.replace('{{service}}', service);
    targetUrl = targetUrl.replace('{{resource}}', resource);

    this.doReq(targetUrl)
      .then(response => {
        if ($.trim(response) !== 'OK') {
          defer.reject(User.AUTH_ISSUES.UNKNOWN);
        } else {
          defer.resolve(true);
        }
      }).fail(() => {
        defer.reject(User.AUTH_ISSUES.NETWORK);
      });

    return defer;
  },

  doReq(urlOrObject) {
    let obj = {};

    if (typeof urlOrObject === 'string' || urlOrObject instanceof String) {
      obj.url = urlOrObject;
    } else {
      obj = urlOrObject;
    }

    this._cleanReq(obj);
    const sessid = Cookies.getItem('sessid');
    if (sessid) {
      obj.url = obj.url.replace('{{key}}', sessid)
        .replace(':key', sessid);
    } else {
      const key = this.get('key');
      obj.url = obj.url.replace('{{key}}', key)
        .replace(':key', key);
    }

    if (!obj.headers) {
      obj.headers = { Authorization: this.authHeader() };
    } else {
      obj.headers.Authorization = this.authHeader();
    }

    return $.ajax(obj);
  },

  _cleanReq(obj) {
    // FIXME traitement spécial des url cartodyn, qui contiennent la clé de l'utilisateur, et qui sont du coup
    // remplacées par {{key}}
    if (obj.url.indexOf('cartodyn/services') !== -1) {
      const key = this.get('key');
      if (key) {
        obj.url = obj.url.replace(key, '{{key}}');
      }
    }
  },

  // Load user preferences in localSorage
  _retrieveCookiesInfo() {
    if (typeof this.get('key') !== 'undefined') {
      this._context[DISPLAY_DL_MODAL] = (Cookies.getItem(`${this.get('key')}_${DISPLAY_DL_MODAL}`) !== 'false');
      this._context[DISPLAY_OCEANOGRAM_MODAL] = (Cookies.getItem(`${this.get('key')}_${DISPLAY_OCEANOGRAM_MODAL}`) !== 'false');
    } else {
      this._context[DISPLAY_DL_MODAL] = true;
      this._context[DISPLAY_OCEANOGRAM_MODAL] = true;
    }
  },

  setDisplayModal(display, modalType) {
    if (display) {
      this._context[modalType] = true;
      Cookies.remove(`${this.get('key')}_${modalType}`);
    } else {
      this._context[modalType] = false;
      Cookies.setItem(`${this.get('key')}_${modalType}`, 'false');
    }
  },

  isDisplayModal(modalType) {
    return this._context[modalType];
  }

});

User.AUTH_ISSUES = {
  OK: 0,
  BAD_CREDENTIALS: 1,
  NETWORK: 2,
  UNKNOWN: 3
};

module.exports = User;
