const _ = require('underscore');
const Moment = require('moment');
const ShomView = require('../../../core/shom-view');
const template = require('../../../../template/ddm/ddm-detail/ddm-info.hbs');

module.exports = ShomView.build({

  initialize(options) {
    this._router = options.router || window.ROUTER;
    this.config = options.config || window.CONFIG;
    this.parentView = options.parentView;
    this._sensorDescription = options.sensorDesc ? options.sensorDesc.members[0].system : null;
    this._values = {
      latest_value: options.latest_value || 'N/A'
    };
  },

  render() {
    this.$el.html(template(
      this._values
    ));
    return this;
  },

  setValuesAndRender(sensorDesc) {
    this._sensorDescription = sensorDesc;

    _.extend(this._values, this._sensorDescription);
    const doiLabel = `${this.config.ddm.doi.label}#${this._sensorDescription.shom_id}`;
    this._values.doi = {
      url: this.config.ddm.doi.url + doiLabel,
      label: doiLabel
    };
    if (this._values.datePremObs) {
      this._values.datePremObs = this._formatDate(this._values.datePremObs);
    }

    if (this._values.events) {
      // sort events desc
      this._values.events.sort((event1, event2) => Moment(event2.due_date).diff(event1.due_date));
      for (let i = 0, len = this._values.events.length; i < len; i++) {
        this._values.events[i].due_date = this._formatTimestamp(this._values.events[i].due_date);
        this._values.events[i].content = this._formatHttpLinks(this._values.events[i].content);
      }
    }

    this.render();
  },

  _formatDate(oldDate) {
    const newDate = Moment(oldDate, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY');
    return newDate;
  },

  _formatTimestamp(timestamp) {
    const newDate = Moment(timestamp).format('DD-MM-YYYY');
    return newDate;
  },

  _formatHttpLinks(textToFormat) {
    // Regex to check if text is an url
    const re = /(https?:\/\/(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^\s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^\s]{2,}|https?:\/\/(?:www.|(?!www))[a-zA-Z0-9]+.[^\s]{2,}|www.[a-zA-Z0-9]+.[^\s]{2,})/gi;
    const text = textToFormat.replace(re, x => `<a href="${x}" target="_blank">${x}</a>`);
    return text;
  }

});
