import {
  CSS_CLASS_ACTIVE, CSS_CLASS_BORDER, CONTEXT_ISGUEST, CONTEXT_CARTOMODE,
  CONTEXT_REFMARLAYERS, CONTEXT_FORECASTLAYERS
} from '../utils/constants';

const Backbone = require('backbone');
const $ = require('jquery');
const ShomView = require('../core/shom-view');
const template = require('../../template/left-button-bar.hbs');

const LeftButtonBarView = ShomView.build({

  className: 'btn-group-vertical left-button-bar',

  attributes: {
    'data-i18n': '[aria-labelledby]main-menu-label'
  },

  tagName: 'nav',

  events: {
    'click .left-bar-btn': '_onButtonClick'
  },

  initialize(options = {}) {
    this._router = options.router || window.ROUTER;
    this._config = options.config || window.CONFIG;
    this._gisView = options.gisView || window.GISVIEW;
    this._eventBus = options.eventBus || window.EVENTBUS;
    this._context = this._router.getContext();
    this.listenTo(Backbone.history, 'route', this._onRoute.bind(this));
    this.listenTo(this._context, 'change', this._onRouterContextChanged.bind(this));
    this.listenTo(this._eventBus, 'globe-mode:change', globeMode => this._switchGlobeMode(globeMode));
    this.listenTo(this._eventBus, 'globe-mode:change', this.render.bind(this));
    this.listenTo(this._eventBus, 'drawing-mode:change', this.render.bind(this));
    this.globeMode = this._gisView.isGlobeEnabled();
  },

  render() {
    this.$el.html(template({
      hasAccessCartodyn: this._hasAccessCartodyn()
    }));

    this._renderActiveState();
    return this;
  },

  _renderActiveState() {
    let route = Backbone.history.fragment;
    if (route) {
      route = route.split('/')[0];
    }

    this.$('.btn.active').removeClass(CSS_CLASS_ACTIVE);

    if (route !== '' && route !== 'abonne' && route !== 'datacontext') {
      this.$(`.btn-${route}`).addClass(CSS_CLASS_ACTIVE);
      $('.btn-group-vertical').addClass(CSS_CLASS_BORDER);
    } else {
      $('.btn-group-vertical').removeClass(CSS_CLASS_BORDER);
    }
  },

  _switchGlobeMode(globeMode) {
    this.globeMode = globeMode.active;
  },

  _onButtonClick(evt) {
    const $button = $(evt.currentTarget);
    const isDisabled = $button.hasClass('disabled');
    if (!isDisabled) {
      let route = $button.attr('data-href');
      if ($button.hasClass(CSS_CLASS_ACTIVE)) {
        route = '';
      }
      this._router.navigate(route, true);
    }
  },

  _onRoute() {
    this._renderActiveState();
  },

  _onRouterContextChanged(context) {
    if (context.get(CONTEXT_ISGUEST) !== context.previous(CONTEXT_ISGUEST)
            || context.get(CONTEXT_CARTOMODE) !== context.previous(CONTEXT_CARTOMODE)
            || context.get(CONTEXT_REFMARLAYERS) !== context.previous(CONTEXT_REFMARLAYERS)
            || context.get(CONTEXT_FORECASTLAYERS) !== context.previous(CONTEXT_FORECASTLAYERS)) {
      this.render();
    }
  },

  _hasNotContextRefmarLayer() {
    return this._context.get(CONTEXT_REFMARLAYERS) === 0;
  },

  _hasNotContextForecastLayer() {
    return this._context.get(CONTEXT_FORECASTLAYERS) === 0;
  },

  _hasAccessCartodyn() {
    return this._hasNotContextRefmarLayer() && this._hasNotContextForecastLayer() && !this.globeMode;
  },

  remove() {
    this.stopListening(this._context);
    LeftButtonBarView.__super__.remove.apply(this, arguments);
  }
});

module.exports = LeftButtonBarView;
