var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"form-group\">\n              <input class=\"user-old-password\" type=\"password\" name=\"user-old-password\" data-i18n=\"[placeholder]user.oldPassword\"/>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"left-menu-header\">\n	<div class=\"left-menu-title\" data-i18n=\"menu.user.title\"></div>\n  <button id=\"close-change-pass\" type=\"button\" class=\"close close-top-menu\" data-i18n=\"[aria-label]menu.button.close\">×</button>\n</div>\n\n<div class=\"panel-group\" id=\"accordion\">\n  <div class=\"panel panel-default\">\n    <div class=\"panel-heading\">\n      <h4 class=\"panel-title\" data-i18n=\"user.title.newPass\"></h4>\n    </div>\n    <div class=\"panel-collapse\">\n      <div class=\"panel-body\">\n        <form class=\"user-change-pass user-form\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasNotToken") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":10},"end":{"line":18,"column":17}}})) != null ? stack1 : "")
    + "          <div class=\"form-group\">\n            <input class=\"user-new-password\" type=\"password\" name=\"user-new-password\" data-i18n=\"[placeholder]user.newPassword\"/>\n          </div>\n\n          <div class=\"form-group\">\n            <input class=\"user-confirm-password\" type=\"password\" name=\"user-confirm-password\" data-i18n=\"[placeholder]user.confirmPassword\"/>\n          </div>\n\n          <div class=\"connection-error has-error\">\n          </div>\n        </form>\n      </div>\n    </div>\n  </div>\n</div>\n\n\n";
},"useData":true});