import {
  Circle, Fill, Icon, Stroke, Style, Text
} from 'ol/style.js';

const styleHelper = require('./style-helper');
const colorsUtils = require('../../colors');

const styles = {
  /**
   * Generate an OL style function for text feature from parameters
   *
   * @param {string} fillColor
   * @param {string} strokeColor
   * @param {number} strokeWidth
   * @param {string} fontFamily
   * @param {number} fontSize
   * @param {boolean} bold
   * @param {boolean} italic
   * @returns {function(feature?: Feature): [Style]} the generated style
   */
  text(fillColor, strokeColor, strokeWidth = 3, fontFamily = 'Verdana', fontSize = 12, bold = true, italic = false) {
    fillColor = fillColor || 'rgba(0,0,0,1)';
    strokeColor = strokeColor || 'rgba(255,255,255,1)';

    return feature => {
      let fontString = `${fontFamily}`;
      fontString = italic ? `italic ${fontString}` : fontString;
      fontString = bold ? `bold ${fontString}` : fontString;

      const textStyle = new Text({
        font: fontString,
        text: feature ? feature.get('text') : '',
        textAlign: 'center',
        textBaseline: 'middle',
        fill: new Fill({
          color: fillColor
        }),
        stroke: new Stroke({
          color: strokeColor,
          width: strokeWidth
        })
      });
      const styleWrapper = new Style({
        text: textStyle
      });
      styleHelper.setTextStyleExtensions(styleWrapper);

      return [styleWrapper];
    };
  },

  /**
   * Generate an OL style for points with Icon feature from parameters
   *
   * @param {string} icon
   * @returns {ol.style.Style} the generated style
   */
  pointWithIcon(icon) {
    return new Style({
      image: new Icon({
        src: icon,
        crossOrigin: 'anonymous',
        scale: 0.5
      })
    });
  },

  /**
   * Generate an OL style for points feature from parameters
   *
   * @param {string} color
   * @param {number} alpha
   * @returns {ol.style.Style} the generated style
   */
  point(color, alpha = 1) {
    color = color || `rgba(102,255,51,${alpha})`;
    return styles.rawPoint(color, `rgba(0,0,0,${alpha})`, 1, 3);
  },

  rawPoint(color, strokeColor, strokeWidth, radius) {
    const fill = new Fill({
      color
    });
    const stroke = new Stroke({
      color: strokeColor,
      width: strokeWidth
    });

    return new Style({
      image: new Circle({
        fill,
        stroke,
        radius
      })
    });
  },
  line() {
    const stroke = new Stroke({
      color: '#000000',
      width: 2
    });
    return new Style({
      stroke
    });
  },
  polygon() {
    const fill = new Fill({
      color: 'rgba(51,153,204,0.5)'
    });
    const stroke = new Stroke({
      color: '#000000',
      width: 2
    });
    return new Style({
      fill,
      stroke
    });
  },

  highlightedStyleFunction(feature) {
    const style = styleHelper.getStyle(feature);
    const geometryType = feature.getGeometry().getType();
    const orange = [241, 150, 10, 1.0];
    const semiTransparentOrange = [241, 150, 10, 0.6];
    let strokeThickness;

    switch (geometryType) {
      case 'LineString':
        strokeThickness = style.getStroke().getWidth();
        return new Style({
          stroke: new Stroke({
            color: orange, width: strokeThickness
          })
        });
      case 'Polygon':
      case 'MultiPolygon':
        strokeThickness = style.getStroke().getWidth();
        return new Style({
          fill: new Fill({ color: semiTransparentOrange }),
          stroke: new Stroke({
            color: orange, width: strokeThickness
          })
        });
      case 'Point':
        const isText = !!feature.get('text');
        if (isText) {
          const textStyle = style.getText();
          const fontInfo = styleHelper.getTextFontInfo(textStyle);
          return styles.text(
            colorsUtils.getRgbaColorFromRgba(semiTransparentOrange),
            colorsUtils.getRgbaColorFromRgba(orange),
            textStyle.getStroke().getWidth(),
            fontInfo.fontFamily,
            fontInfo.fontSize,
            fontInfo.bold === 'bold',
            fontInfo.italic === 'italic'
          );
        }
        const image = style.getImage();
        const isCircle = image instanceof Circle;
        if (isCircle) {
          const currRadius = image.getRadius();
          strokeThickness = image.getStroke().getWidth();
          return new Style({
            image: new Circle({
              radius: currRadius,
              fill: new Fill({ color: semiTransparentOrange }),
              stroke: new Stroke({
                color: orange, width: strokeThickness
              })
            })
          });
        }
        // Assume it's an icon
        const currentIcon = image.getSrc();
        return new Style({
          image: new Icon({
            src: currentIcon,
            scale: 0.5,
            opacity: 0.6
          })
        });

      default:
        throw 'Unsupported geometry type';
    }
  }
};

module.exports = styles;
