import LayerModelUtils from '../../utils/layer-model-utils';

const _ = require('underscore');
const $ = require('jquery');

const ShomView = require('../../core/shom-view');

const NCWMS = require('../../service/ncwms');

const Loading = require('../../utils/loading.js');
const OceanoNcwmsUtils = require('../../utils/oceano/oceano-ncwms.js');
const NcwmsLayerUtils = require('../../utils/oceano/oceano-layer.js');
const OceanoHelper = require('../../utils/gis/oceano-helper');

const OceaSelectDateView = require('./ocea-select-date.view');

const template = require('../../../template/oceano/ocea-vertical-animation.hbs');

module.exports = ShomView.build({
  events: {
    'submit form': '_launchAnimation'
  },

  /** ***************************** */
  /** ****** INIT FUNCTIONS ******* */
  /** ***************************** */

  initialize(options) {
    const optionsToUse = options || {};
    this._gisView = optionsToUse.gisView || window.GISVIEW;
    this._config = optionsToUse.config || window.CONFIG;
    this._layer = optionsToUse.layer;
    this._depthBounds = optionsToUse.depthBounds;
    this._pointClicked = optionsToUse.pointClicked;

    this._utcValue = this._gisView.getGlobalOceanoCurrentUTC();
    this._animationFirstmoment = this._gisView.getOceanoLayerCurrentMoment(this.model);
    this._animationLastmoment = this._animationFirstmoment;
    this._sortedUniqueMoments = [];
    this._momentsStructure = new Map();
  },

  /** ***************************** */
  /** ***** RENDER FUNCTIONS ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({
      utcInfo: OceanoNcwmsUtils.utcFormat(this._utcValue)
    }));

    this._$firstMomentContainer = this.$el.find('.first-moment-container');
    this._$lastMomentContainer = this.$el.find('.last-moment-container');
    this._$verticalAnimationSlider = this.$el.find('.vertical-slider-animation');
    this._$verticalAnimationSliderValue = this.$el.find('.vertical-slider-animation-value');
    this._$verticalAnimationButton = this.$el.find('.vertical-animation-button');
    this._$verticalAnimationError = this.$('#vertical-animation-error');
    this._$verticalAnimationImage = this.$el.find('img#vertical-animation-content');
    this._$verticalAnimationErrors = this.$el.find('.errors');

    this._buildMomentsStructure();
    this._stopListeningToSelectViews();
    this._selectFirstMomentView = this._momentSelectViewsFactory();
    this._selectLastMomentView = this._momentSelectViewsFactory();

    this._renderMomentSelector(this._selectFirstMomentView, this._$firstMomentContainer);
    this._renderMomentSelector(this._selectLastMomentView, this._$lastMomentContainer);
    this._renderAnimationSlider();

    this._onDateChanged();

    return this;
  },

  _renderMomentSelector(selectMomentView, $container) {
    $container.empty();
    $container.html(selectMomentView.render().$el);

    this.listenTo(selectMomentView, 'change:oceaSelectDate', this._onDateChanged);
  },

  _renderAnimationSlider() {
    const defaultSliderValue = 2;
    const onCreateSlider = () => this._updateSliderValue(null, { value: defaultSliderValue });
    this._$verticalAnimationSlider.slider({
      min: 1,
      value: defaultSliderValue,
      max: 10,
      create: _.bind(onCreateSlider, this),
      slide: _.bind(this._updateSliderValue, this)
    });
  },

  /** ****************************** */
  /** * GETTER / SETTER FUNCTIONS ** */
  /** ****************************** */

  setDepthBounds(depthBounds) {
    this._depthBounds = depthBounds;
  },

  /** ***************************** */
  /** ***** EVENT FUNCTIONS ******* */
  /** ***************************** */

  _updateSliderValue(event, ui) {
    this._$verticalAnimationSliderValue.html(ui.value);
  },

  _onDateChanged(param) {
    this._animationFirstmoment = this._selectFirstMomentView.getSelectedMoment();
    this._animationLastmoment = this._selectLastMomentView.getSelectedMoment();

    if (this._checkIsFormValid()) {
      this._$verticalAnimationButton.removeAttr('disabled');
      param && this._$verticalAnimationErrors.closest('.row').hide();
    } else {
      this._$verticalAnimationButton.attr('disabled', 'disabled');
      param && this._$verticalAnimationErrors.closest('.row').show();
    }
  },

  onClose() {
    this._stopListeningToSelectViews();
  },

  _stopListeningToSelectViews() {
    this.stopListening(this._selectFirstMomentView);
    this.stopListening(this._selectLastMomentView);
  },

  /** ***************************** */
  /** **** REQUEST FUNCTIONS ****** */
  /** ***************************** */

  _launchAnimation(event) {
    event.preventDefault();
    if (!this._checkIsFormValid()) { return false; }

    const isoDates = [this._animationFirstmoment.toISOString(), this._animationLastmoment.toISOString()];
    const ips = this._$verticalAnimationSlider.slider('value');

    const ncwmsServiceOptions = { ncwmsLayerType: LayerModelUtils.getNcwmsLayerType(this.model) };
    NCWMS(ncwmsServiceOptions)
      .then(service => {
        const currentBbox = this._gisView.getCurrentViewBounds();
        const mapSize = this._gisView.getMapSize();
        const selectedUnitName = NcwmsLayerUtils.getSelectedUnitFromLayer(this.model).name;
        const unitConfig = this._config.oceano.units[selectedUnitName];
        const ncwmsUnit = (unitConfig) ? unitConfig.ncWMS : null;
        const depths = this._depthBounds;

        return service.getProfileUrl(this._pointClicked.X, this._pointClicked.Y, this._layer, {
          mapSize,
          bbox: currentBbox,
          unit: ncwmsUnit,
          minDepth: depths[0],
          maxDepth: depths[1]
        }, {
          time: isoDates,
          framerate: ips
        });
      })
      .then(url => {
        this._updateAnimation(url);
      });
  },

  /** ***************************** */
  /** ***** UTILS FUNCTIONS ******* */
  /** ***************************** */

  _momentSelectViewsFactory() {
    return new OceaSelectDateView({
      momentsStructure: this._momentsStructure,
      sortedUniqueMoments: this._sortedUniqueMoments,
      currentLayerMoment: this._animationFirstmoment
    });
  },

  _checkIsFormValid() {
    return this._animationFirstmoment.isBefore(this._animationLastmoment);
  },

  _updateAnimation(url) {
    Loading.start(this.$el);
    this._$verticalAnimationError.hide();
    const $animationPanel = this._$verticalAnimationImage;
    $animationPanel.fadeTo('slow', 0.0, () => {
      $animationPanel.prop('src', url);
      $animationPanel.one('load', () => {
        Loading.stop(this.$el);
        $animationPanel.fadeTo('slow', 1.0);
        this._$verticalAnimationButton.html($.i18n.t('oceano.nav.verticalProfile.animation.reloadAnimation'));
      }).one('error', () => {
        $animationPanel.fadeTo('slow', 1.0);
        Loading.stop(this.$el);
        this._$verticalAnimationError.show();
      });
    });
  },

  _buildMomentsStructure() {
    const dateToStringTemplate = OceanoNcwmsUtils.getDateToStringTemplate();
    const dates = OceanoHelper.getProcessedTimeInZoom(this.model, this._gisView.getZoom());
    this._sortedUniqueMoments = OceanoNcwmsUtils.getSortedMomentArrayFromDatesStructure(dates);

    for (let momentIdx = 0; momentIdx < this._sortedUniqueMoments.length; momentIdx++) {
      const momt = this._sortedUniqueMoments[momentIdx];
      momt.locale(window.portalLang);
      momt.utcOffset(+this._utcValue || 0);
      const day = momt.format(dateToStringTemplate);
      const time = momt.format('HH:mm');

      if (day in this._momentsStructure) {
        this._momentsStructure[day].push([time, momt]);
      } else {
        this._momentsStructure[day] = [[time, momt]];
      }
    }
  }
});
