const Layer = require('../model/layer');

const LayerFactory = {};

LayerFactory.build = (olLayer, attributes) => {
  const model = new Layer();

  if (olLayer) {
    model.set('title', olLayer.get('title'));
    model.set('identifier', olLayer.get('identifier'));
    model.set('abstract', olLayer.get('abstract'));
    model.set('opacity', 1);
    model.set('visibility', true);
    model.set('olLayer', olLayer);

    if (olLayer.get('layerType') === 'PARTNER') {
      model.set('legendUrl', olLayer.get('legendUrl'));
      model.set('metadataURL', olLayer.get('metadataURL'));
      model.set('queryable', olLayer.get('queryable'));
      model.set('layerType', olLayer.get('layerType'));
      model.set('url', olLayer.get('url'));
      model.set('crs', olLayer.get('CRS'));
      model.set('infoFormat', olLayer.get('infoFormat'));
    }
  }

  model.set(attributes);

  return model;
};

module.exports = LayerFactory;
