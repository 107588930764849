var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<i class=\"fas fa-compass compass-coord\"></i>&nbsp;\n<div class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cssCoordXY") || (depth0 != null ? lookupProperty(depth0,"cssCoordXY") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cssCoordXY","hash":{},"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":26}}}) : helper)))
    + "\">\n    <label>&nbsp;X=</label>\n    <input id=\"coord-x\" class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cssCoordInput") || (depth0 != null ? lookupProperty(depth0,"cssCoordInput") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cssCoordInput","hash":{},"data":data,"loc":{"start":{"line":4,"column":31},"end":{"line":4,"column":48}}}) : helper)))
    + "\" type=\"text\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"coordX") || (depth0 != null ? lookupProperty(depth0,"coordX") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"coordX","hash":{},"data":data,"loc":{"start":{"line":4,"column":69},"end":{"line":4,"column":79}}}) : helper)))
    + "\"\n           size=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"sizeX") || (depth0 != null ? lookupProperty(depth0,"sizeX") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sizeX","hash":{},"data":data,"loc":{"start":{"line":5,"column":17},"end":{"line":5,"column":26}}}) : helper)))
    + "\"/>\n    <label class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cssLabelUnitX") || (depth0 != null ? lookupProperty(depth0,"cssLabelUnitX") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cssLabelUnitX","hash":{},"data":data,"loc":{"start":{"line":6,"column":18},"end":{"line":6,"column":35}}}) : helper)))
    + "\">m,&nbsp;&nbsp;Y=</label>\n    <input id=\"coord-y\" class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cssCoordInput") || (depth0 != null ? lookupProperty(depth0,"cssCoordInput") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cssCoordInput","hash":{},"data":data,"loc":{"start":{"line":7,"column":31},"end":{"line":7,"column":48}}}) : helper)))
    + "\" type=\"text\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"coordY") || (depth0 != null ? lookupProperty(depth0,"coordY") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"coordY","hash":{},"data":data,"loc":{"start":{"line":7,"column":69},"end":{"line":7,"column":79}}}) : helper)))
    + "\"\n           size=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"sizeY") || (depth0 != null ? lookupProperty(depth0,"sizeY") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sizeY","hash":{},"data":data,"loc":{"start":{"line":8,"column":17},"end":{"line":8,"column":26}}}) : helper)))
    + "\"/>\n    <label class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cssLabelUnitY") || (depth0 != null ? lookupProperty(depth0,"cssLabelUnitY") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cssLabelUnitY","hash":{},"data":data,"loc":{"start":{"line":9,"column":18},"end":{"line":9,"column":35}}}) : helper)))
    + "\">m</label>\n</div>";
},"useData":true});