import { DoubleClickZoom } from 'ol/interaction.js';
import Feature from 'ol/Feature';
import { unByKey } from 'ol/Observable.js';
import { LineString, Point, Polygon } from 'ol/geom.js';
import { LAYERTYPE_KML_LAYER } from '../../utils/constants';

const $ = require('jquery');
const _ = require('underscore');
const kmlHelper = require('../../utils/gis/kml-helper.js');

const LegendView = require('../drawing/legend/legend.view');
const GisView = require('../gis.view.js');
const CoordsHelper = require('../../utils/gis/coordinates-helper.js');
const kmlStyles = require('../../utils/gis/drawing/discuss-styles.js');

let drawingLayer;
let leaveCurrentModeFunction;
let singleClickListenId;
let legendsView;
let resizeHandler;
let selectedFeature;

/**
 * To store revision of feature at a given moment
 * @type {Map<ol.Feature, number>} | null
 */

// BEGIN SNIPPET
// https://github.com/openlayers/ol3/issues/3610

function onInteractionFinished(map) {
  // Call to double click zoom control function to deactivate zoom event
  controlDoubleClickZoom(map, false);
  // Delay execution of activation of double click zoom function
  setTimeout(() => {
    controlDoubleClickZoom(map, true);
  }, 251);
}

// Control active state of double click zoom interaction
function controlDoubleClickZoom(map, active) {
  // Find double click interaction
  const interactions = map.getInteractions();
  for (let i = 0; i < interactions.getLength(); i++) {
    const interaction = interactions.item(i);
    if (interaction instanceof DoubleClickZoom) {
      interaction.setActive(active);
    }
  }
}

// END SNIPPET

const cartoDynAddLayerHandler = function (layer) {
  this._map.addLayer(layer);
};

GisView.prototype.addDrawingLayer = function () {
  this._isCurrentlyDrawing = false;
  drawingLayer = this.drawingLayer;

  this.listenTo(this._router.getLeftMenuRouter(), 'route', _.bind(this.leaveCurrentDrawMode, this));

  const singleClickOptions = {
    layerFilter(layerCandidate) {
      return layerCandidate === drawingLayer;
    }
  };

  singleClickListenId = this._map.on('singleclick', evt => {
    const clickedFeature = this._map.forEachFeatureAtPixel(evt.pixel, feature => feature, singleClickOptions);

    if (!this._isCurrentlyDrawing && clickedFeature) {
      this.eventsBus.trigger('drawing:feature:clicked', clickedFeature);
    }
  });

  this.addLayer = cartoDynAddLayerHandler;
};

GisView.prototype.clearDrawingLayer = function () {
  this.carto.command('drawing.clear');
};

GisView.prototype.getFeatureFromMouseEvent = function () {
  const coord = this.currentCoord;
  const pixel = this._map.getPixelFromCoordinate(coord);

  return this._map.forEachFeatureAtPixel(
    pixel,
    feature => feature,
    {
      hitTolerance: 10,
      layerFilter(layerCandidate) {
        return layerCandidate === drawingLayer;
      }
    }
  );
};

GisView.prototype.removeDrawingLayer = function () {
  this.leaveCurrentDrawMode();
  unByKey(singleClickListenId);
  this.clearDrawingLayer();
  selectedFeature = null;
  this.addLayer = GisView.prototype.addLayer;
  this.stopListening(this._router.getLeftMenuRouter(), 'route');
  this.stopListening(this.eventsBus, 'drawing:feature:clicked');
};

GisView.prototype.isOnDrawingMode = function () {
  return !!drawingLayer;
};

GisView.prototype.enterDrawMode = function (mode, onDrawingEnd) {
  if (!this[`_enter${mode}Mode`]) {
    return;
  }
  this.leaveCurrentDrawMode();
  this._isCurrentlyDrawing = mode.indexOf('Draw', 0) >= 0;
  leaveCurrentModeFunction = this[`_enter${mode}Mode`].call(this, onDrawingEnd);
};

GisView.prototype.leaveCurrentDrawMode = function () {
  leaveCurrentModeFunction && leaveCurrentModeFunction.call(this);
  this._isCurrentlyDrawing = false;
};

/**
 * Export drawing layer features to kml
 * @return {string} kml document with all features
 */
GisView.prototype.exportKmlData = function () {
  const features = drawingLayer !== undefined ? drawingLayer.getSource().getFeatures() : [];

  this._getDisplayedLayers().forEach(layer => {
    if (layer.get('type') === LAYERTYPE_KML_LAYER) {
      features.push(...layer.get('olLayer').get('source').getFeatures());
    }
  });

  const kmlData = kmlHelper.featuresToKml(features);
  if (legendsView !== undefined) {
    kmlHelper.saveLegendsInKml(kmlData, legendsView.collection);
  }

  return kmlHelper.kmlToString(kmlData);
};

/**
 * Import features from kml into drawing layer
 * @param {XMLDocument | string | $} kmlData
 * @return {boolean} if the operation succeeded
 */
GisView.prototype.importKmlData = function (kmlData) {
  if (!drawingLayer || !kmlData) {
    return false;
  }

  const features = kmlHelper.kmlToFeatures(kmlData);

  this.carto.command('drawing.addFeatures', features);

  const legends = [];
  features.forEach(feat => {
    // eslint-disable-next-line no-prototype-builtins
    if (feat.getProperties().hasOwnProperty('description')) {
      legends.push({
        id: feat.getId(),
        feature: feat,
        label: feat.getProperties().description
      });
    }
  });
  legendsView && legendsView.updateWith(legends);

  return true;
};

GisView.prototype._resizeLegend = function (event, target) {
  !target && legendsView && legendsView.resize();
};

GisView.prototype.addLegends = function (legends, session) {
  this.removeLegends();
  window.LEGEND = legendsView = new LegendView({
    rawLegend: legends,
    featureSource: drawingLayer.getSource(),
    gisView: this,
    session
  }).render();
  $('.ui-container').append(legendsView.$el);
  legendsView.initDraggable();
  resizeHandler = this._resizeLegend.bind(this);
  $(window).on('resize', resizeHandler);
};

GisView.prototype.getLegends = function () {
  if (legendsView) {
    return legendsView.getRawLegend();
  }
  return undefined;
};

GisView.prototype.getLegendsCount = function () {
  if (legendsView) {
    return legendsView.collection.length;
  }
  return 0;
};

GisView.prototype.removeLegends = function () {
  $(window).off('resize', resizeHandler);
  legendsView && legendsView.remove();
};

GisView.prototype.addFeatureToLegend = function (feature) {
  if (legendsView && feature) {
    legendsView.addNewLegendItem(feature);
  }
};

GisView.prototype.addLineFromCoordinates = function (coordinates) {
  const lineString = new LineString(coordinates);
  CoordsHelper.convertGeomLonLatToMercator(lineString);
  const feature = new Feature({
    geometry: lineString
  });
  kmlStyles && feature.setStyle(kmlStyles.line());
  this.carto.command('drawing.addFeature', feature);
  this.leaveCurrentDrawMode();
};

GisView.prototype.addPolygonFromCoordinates = function (coordinates) {
  // we need last point = the first point so we add it
  coordinates[0][coordinates[0].length] = coordinates[0][0];
  const polygon = new Polygon(coordinates);
  CoordsHelper.convertGeomLonLatToMercator(polygon);
  const feature = new Feature({
    geometry: polygon
  });
  kmlStyles && feature.setStyle(kmlStyles.polygon());
  this.carto.command('drawing.addFeature', feature);
  this.leaveCurrentDrawMode();
};

GisView.prototype.getMapAsImage = function (mimeType, options = {}) {
  const { backgroundColor } = options;
  const mapCanvas = document.createElement('canvas');
  const size = this._map.getSize();
  mapCanvas.width = size[0];
  mapCanvas.height = size[1];
  const context = mapCanvas.getContext('2d');
  // Select all map canvas and exlude overview map
  $('.map-container > .ol-viewport > .ol-layers .ol-layer canvas').each((index, canvas) => {
    if (canvas.width > 0) {
      const { opacity } = canvas.parentNode.style;
      context.globalAlpha = opacity === '' ? 1 : Number(opacity);
      const { transform } = canvas.style;
      // Get the transform parameters from the style's transform matrix
      const matrix = transform.match(/^matrix\(([^(]*)\)$/)[1].split(',').map(Number);
      // Apply the transform to the export map context
      CanvasRenderingContext2D.prototype.setTransform.apply(context, matrix);
      context.drawImage(canvas, 0, 0);
    }
  });

  const w = mapCanvas.width;
  const h = mapCanvas.height;
  let data;
  const compositeOperation = context.globalCompositeOperation;
  if (backgroundColor) {
    // get the current ImageData for the canvas.
    data = context.getImageData(0, 0, w, h);
    // store the current globalCompositeOperation
    // set to draw behind current content
    context.globalCompositeOperation = 'destination-over';
    // set background color
    context.fillStyle = backgroundColor;
    // draw background / rect on entire canvas
    context.fillRect(0, 0, w, h);
  }

  // get the image data from the canvas
  const imageData = mapCanvas.toDataURL(mimeType, options.quality);
  if (backgroundColor) {
    // clear the canvas
    context.clearRect(0, 0, w, h);
    // restore it with original / cached ImageData
    context.putImageData(data, 0, 0);
    // reset the globalCompositeOperation to what it was
    context.globalCompositeOperation = compositeOperation;
  }

  // return the Base64 encoded data url string
  return imageData;
};

GisView.prototype.removeAllFeatures = function () {
  this.carto.command('drawing.clear');
};

GisView.prototype.getProjectionExtent = function () {
  return this._map.getView().getProjection().getExtent();
};

GisView.prototype.addPointAtCoordinates = function (coords) {
  const point = new Point(coords);
  const feature = new Feature({ geometry: point });
  kmlStyles && feature.setStyle(kmlStyles.pointWithIcon());
  this.carto.command('drawing.addFeature', feature);
};
