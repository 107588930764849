const _ = require('underscore');
const $ = require('jquery');
const Loading = require('./loading');

const AuthZones = Object.create(null);

AuthZones.hasRightsOn = function (resourceInfo, router, cb) {
  const { user } = router;

  function extracted() {
    if (!user.get('isLoggedIn')) {
      return cb(false);
    }
    if (resourceInfo.serv === 'LOGGED' && resourceInfo.res === 'IN') {
      return cb(user.get('isLoggedIn'));
    }
    return router.user.hasRightsOn(resourceInfo.serv, resourceInfo.res)
      .then(cb)
      .fail(_.partial(cb, false));
  }

  if (user.get('sessionState') !== 'WAITING') {
    extracted();
  } else {
    user.listenTo(user, 'change:sessionState', _.bind(extracted, this));
  }
};

AuthZones.getResourceInfo = function (resourceString) {
  if (!resourceString) {
    return null;
  }

  const splitted = resourceString.split('/');

  return { serv: splitted[0], res: splitted[1] };
};

AuthZones.checkAuth = function ($nodes, options) {
  const optionsToUse = options || {};
  const router = optionsToUse.router || window.ROUTER;

  $nodes.each(function () {
    const childNode = $(this);
    const resourceInfo = AuthZones.getResourceInfo(childNode.data('auth'));

    if (!resourceInfo) {
      return AuthZones.checkAuth(childNode.children(), options);
    }

    Loading.start(childNode);

    function callback(hasRight) {
      if (!hasRight) {
        childNode.addClass('disabled-resource');
      } else {
        AuthZones.checkAuth(childNode.children(), options);
      }
      Loading.stop(childNode);
    }

    if (router.user.get('instanciated')) {
      AuthZones.hasRightsOn(resourceInfo, router, callback);
    } else {
      router.user.listenTo(router.user, 'change:instanciated', _.bind(AuthZones.hasRightsOn, this, resourceInfo, router, callback));
      router.user.listenTo(router.user, 'change:isLoggedIn', _.bind(AuthZones.hasRightsOn, this, resourceInfo, router, callback));
    }
  });
};

module.exports = AuthZones;
