var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "										<option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"form-horizontal external-layers-form\" role=\"form\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">&times;</button>\n				<h4 class=\"modal-title\" data-i18n=\"modal.externalLayers.title\"></h4>\n			</div>\n			<div class=\"modal-body\">\n\n				<ul class=\"nav nav-pills external-nav-list\" role=\"tablist\">\n					<li role=\"presentation\" class=\""
    + alias2(__default(require("../../js/hbs-helpers/ifequalsinline.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"nav") : depth0),"predefined","active",{"name":"ifequalsinline","hash":{},"data":data,"loc":{"start":{"line":11,"column":36},"end":{"line":11,"column":85}}}))
    + "\">\n						<a href=\"#add-predefined-externalLayer\" role=\"tab\" data-toggle=\"tab\"\n							aria-controls=\"add-predefined-externalLayer\" data-i18n=\"modal.externalLayers.btnpredefined\"></a>\n					</li>\n					<li role=\"presentation\" class=\""
    + alias2(__default(require("../../js/hbs-helpers/ifequalsinline.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"nav") : depth0),"personnalised","active",{"name":"ifequalsinline","hash":{},"data":data,"loc":{"start":{"line":15,"column":36},"end":{"line":15,"column":88}}}))
    + "\">\n						<a href=\"#add-personnalised-externalLayer\" role=\"tab\" data-toggle=\"tab\"\n							aria-controls=\"add-personnalised-externalLayer\" data-i18n=\"modal.externalLayers.btncustomized\"></a>\n					</li>\n				</ul>\n				\n				<div class=\"tab-content external-tab-content\">\n					<div role=\"tabpanel\" class=\"tab-pane add-predefined-externalLayer fade "
    + alias2(__default(require("../../js/hbs-helpers/ifequalsinline.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"nav") : depth0),"predefined","in active",{"name":"ifequalsinline","hash":{},"data":data,"loc":{"start":{"line":22,"column":76},"end":{"line":22,"column":128}}}))
    + "\"\n							id=\"add-predefined-externalLayer\">\n						\n						<div class=\"form-group\">\n							<label for=\"external-layers-feeds\" class=\"col-sm-2 control-label\" data-i18n=\"modal.externalLayers.feed.label\"></label>\n							\n							<div class=\"col-sm-10\">\n								<select class=\"form-control\" id=\"external-layers-feeds\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"externalFeeds") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":9},"end":{"line":32,"column":18}}})) != null ? stack1 : "")
    + "								</select>\n							</div>\n						</div>\n					</div>\n					<div role=\"tabpanel\" class=\"tab-pane add-personnalised-externalLayer fade "
    + alias2(__default(require("../../js/hbs-helpers/ifequalsinline.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"nav") : depth0),"personnalised","in active",{"name":"ifequalsinline","hash":{},"data":data,"loc":{"start":{"line":37,"column":79},"end":{"line":37,"column":134}}}))
    + "\"\n							id=\"add-personnalised-externalLayer\">\n						<div class=\"form-group\">\n							<label for=\"external-layers-type\" class=\"col-sm-2 control-label\" data-i18n=\"modal.externalLayers.type.label\"></label>\n							<div class=\"col-sm-10\">\n								<select class=\"form-control\" id=\"external-layers-type\" autofocus "
    + alias2(__default(require("../../js/hbs-helpers/ifinline.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"disableTypeChoice") : depth0),"disabled",{"name":"ifinline","hash":{},"data":data,"loc":{"start":{"line":42,"column":73},"end":{"line":42,"column":119}}}))
    + ">\n									<option value=\"wms\" "
    + alias2(__default(require("../../js/hbs-helpers/ifequalsinline.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"startType") : depth0),"wms","selected",{"name":"ifequalsinline","hash":{},"data":data,"loc":{"start":{"line":43,"column":29},"end":{"line":43,"column":79}}}))
    + ">WMS</option>\n									<option value=\"kml\" "
    + alias2(__default(require("../../js/hbs-helpers/ifequalsinline.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"startType") : depth0),"kml","selected",{"name":"ifequalsinline","hash":{},"data":data,"loc":{"start":{"line":44,"column":29},"end":{"line":44,"column":79}}}))
    + ">KML</option>\n									<option value=\"wmts\" "
    + alias2(__default(require("../../js/hbs-helpers/ifequalsinline.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"startType") : depth0),"wmts","selected",{"name":"ifequalsinline","hash":{},"data":data,"loc":{"start":{"line":46,"column":30},"end":{"line":46,"column":81}}}))
    + ">WMTS</option>\n									<option value=\"ncwms\" "
    + alias2(__default(require("../../js/hbs-helpers/ifequalsinline.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"startType") : depth0),"ncwms","selected",{"name":"ifequalsinline","hash":{},"data":data,"loc":{"start":{"line":47,"column":31},"end":{"line":47,"column":83}}}))
    + ">NCWMS</option>\n								</select>\n							</div>\n						</div>\n					</div>\n					<div class=\"external-layers-content\">\n					</div>\n				</div>\n			</div>\n			<div class=\"modal-footer\">\n				<button type=\"button\" class=\"btn btn-default\" data-dismiss=\"modal\" data-i18n=\"modal.externalLayer.close\"></button>\n				<button class=\"btn btn-primary submit-form\" data-i18n=\"modal.externalLayer.add\"></button>\n			</div>\n		</div>\n	</div>\n</form>\n";
},"useData":true});