const ShomView = require('../../core/shom-view');
const template = require('../../../template/ddm/ddm-detail/ddm-working-button.hbs');

module.exports = ShomView.build({

  initialize(options) {
    this._infoStation = options.infoStation;
    this.colors = {
      OK: '#5cb85c',
      PB: '#fb7200',
      KO: '#ee0000',
      ASYNC: '#be24fb',
      HIDDEN: '#c5c5c5',
      OLD: '#663300'
    };
    this.i18nKeys = {
      OK: '[title]ddm.working.ok',
      PB: '[title]ddm.working.pb',
      KO: '[title]ddm.working.ko',
      ASYNC: '[title]ddm.working.async',
      HIDDEN: '[title]ddm.working.hidden',
      OLD: '[title]ddm.working.old'
    };
  },

  render() {
    this.$el.html(template({ state: this.stateRewriting(this._infoStation.working) }));

    this._workingButton = this.$el.find('.btn-circle');

    if (this._infoStation.working) {
      if (this.colors[this._infoStation.working]) {
        this._workingButton.css('background-color', this.colors[this._infoStation.working]);
        this._workingButton.attr('data-i18n', this.i18nKeys[this._infoStation.working]);
      } else {
        this._workingButton.css('background-color', this.colors.ko);
        this._workingButton.attr('data-i18n', this.i18nKeys.ko);
      }
    }
    return this;
  },

  stateRewriting(state) {
    switch (state) {
      case 'OK':
        return '1';
      case 'PB':
        return '2';
      case 'KO':
        return '3';
      case 'ASYNC':
        return '4';
      case 'HIDDEN':
        return '5';
      case 'OLD':
        return '6';
      default:
        return '';
    }
  }
});
