const $ = require('jquery');

let isInt = false;
let config = {};

/**
 * Get the sources and the period with observations for the given tigegauge
 * @param {Number} tidegaugeId is the tigauge's ID
 * @returns {Promise<{sources: Number[], startYear: Number, endYear: Number}>}
 * @private
 */
const _getSourcesAndPeriodWithDataForTidegauge = function (tidegaugeId) {
  return new Promise((resolve, reject) => {
    let startYear;
    let endYear;
    const url = `${config.ddm.chrono.url}${config.ddm.chrono.getSources}/${tidegaugeId}`;

    $.get(url)
      .done(data => {
        let sources = [];

        if (data.length > 0) {
          sources = data.filter(value => value.source <= 6).map(value => value.source);
          startYear = data.reduce((prev, current) => ((prev.minyear < current.minyear) ? prev : current)).minyear;
          endYear = data.reduce((prev, current) => ((prev.maxyear > current.maxyear) ? prev : current)).maxyear;
          return resolve({ sources, startYear, endYear });
        }
        throw new Error('No source with observations for this tidegauge');
      })
      .fail(reject);
  });
};

const _formatValuesData = function (valuesData) {
  return valuesData.reduce((acc, value) => {
    if (!acc[value.source]) {
      acc[value.source] = [];
    }
    const { year, nb_total_values } = value;
    acc[value.source].push({ year, nb_total_values });
    return acc;
  }, {});
};

/**
 * Get the number of observations by years and by sources for the specified tidegauge in the period given
 * @param {number} tidegaugeId is the tigauge's ID
 * @param {number[]} sources is the list of sources' ID to get observations from
 * @param {number} startYear is the lower bound of the period
 * @param {number} endYear is the upper bound of the period
 * @returns {Promise<Object.<Number, {nb_total_values: Number, year: Number}[]>>}
 * @private
 */
const _getObsNumberByYearBySourcesInPeriodForTidegauge = function (
    tidegaugeId,
    sources,
    startYear,
    endYear
) {
  return new Promise((resolve, reject) => {
    const url = `${config.ddm.chrono.url}${
      config.ddm.chrono.getValues
    }/${tidegaugeId}?sources=${sources.join(
      ','
    )}&startYear=${startYear}&endYear=${endYear}`;

    $.get(url)
      .done(data => resolve(_formatValuesData(data)))
      .fail(reject);
  });
};

/**
 * Get number of observations by years and by sources for the given tidegauge
 * @param {Number} tigaugeId
 * @returns {Promise<{unknown}>}
 */
const getAsyncObsNumberBySourcesAndByYearsForTidegauge = function (tigaugeId) {
  let startYear;
  let endYear;

  return new Promise((resolve, reject) => {
    _getSourcesAndPeriodWithDataForTidegauge(tigaugeId)
      .then(data => {
        startYear = data.startYear;
        endYear = data.endYear;
        // If there is no source with data
        if (data.sources.length === 0) {
          return resolve({
            obsNumberBySourcesAndYears: [],
            startYear,
            endYear
          });
        }
        _getObsNumberByYearBySourcesInPeriodForTidegauge(
          tigaugeId,
          data.sources,
          startYear,
          endYear
        ).then(datas => resolve({
          obsNumberBySourcesAndYears: datas,
          startYear,
          endYear
        }));
      })
      .catch(reject);
  });
};

/**
 * Format observations by sources
 * @param {{source: Number, nb_total_values: Number, year: Number}[]} valuesData
 * @returns {Object.<Number, {nb_total_values: Number, year: Number}[]>}
 * @private
 */

const ChronoSrv = {
  getAsyncObsNumberBySourcesAndByYearsForTidegauge,
  _formatValuesData,
  _getSourcesAndPeriodWithDataForTidegauge,
  _getObsNumberByYearBySourcesInPeriodForTidegauge
};

module.exports = function (options) {
  if (!isInt) {
    ChronoSrv.config = options && options.config ? options.config : window.CONFIG;
    config = ChronoSrv.config;
    isInt = true;
  }

  return ChronoSrv;
};
