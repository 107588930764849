var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n        <div class=\"modal-header\">\n            <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">&times;</button>\n            <h4 id=\"map-properties-title\" class=\"modal-title\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</h4>\n        </div>\n        <div class=\"modal-body\">\n            <p class=\"confirm-text\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "</p>\n        </div>\n        <div class=\"modal-footer\">\n            <button type=\"button\" class=\"confirm-button btn btn-primary\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"confirmLabel") : depth0), depth0))
    + "</button>\n            <button type=\"button\" class=\"cancel-button btn btn-default\" data-dismiss=\"modal\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"cancelLabel") : depth0), depth0))
    + "</button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});