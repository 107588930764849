const ExternalLayers = function (options) {
  options = options || {};
  this._config = options.config || window.CONFIG;
};

ExternalLayers.prototype.setIncludedInMapStatus = function (externalContexts, layer, includedInMap) {
  const externalContext = externalContexts.findWhere({
    context: layer.get('context')
  });

  if (externalContext) {
    externalContext.set('includedInMap', includedInMap);
  }
};

ExternalLayers.prototype.getIncludedInMapStatus = function (externalContexts, layer) {
  const externalContext = externalContexts.findWhere({
    context: layer.get('context')
  });

  if (externalContext) {
    return externalContext.get('includedInMap');
  }

  return false;
};

module.exports = ExternalLayers;
