const $ = require('jquery');
const _ = require('underscore');

const Config = {};

const DefaultConfig = require('../../../config/default.yaml');

const Configs = [
  require('../../../config/local.yaml'),
  require('../../../config/local-validation.yaml'),
  require('../../../config/qualif.yaml'),
  require('../../../config/qualif-internal.yaml'),
  require('../../../config/qualif-validation.yaml'),
  require('../../../config/preprod.yaml'),
  require('../../../config/preprod-internal.yaml'),
  require('../../../config/preprod-prod.yaml'),
  require('../../../config/preprod-validation.yaml'),
  require('../../../config/prod.yaml'),
  require('../../../config/prod-validation.yaml'),
  require('../../../config/prod-webapp.yaml'),
  require('../../../config/prod-internal.yaml')
];

Config.getEnvironment = function () {
  return window.CONFIG.env;
};

Config.load = function () {
  let conf = {};
  const promise = $.Deferred();
  let currentURI = this.getHost() + this.getPathname();
  if (this.getPathname() !== '/') {
    currentURI += '/';
  }

  const cfgs = Configs.filter(cfg => {
    // baseroot could be redefined
    cfg.baseroot = window.BASEROOT || cfg.baseroot;
    let baseUrl = cfg.url;
    if (!cfg.isLocal) {
      baseUrl += cfg.baseroot;
    }
    return currentURI.replace('www.', '').indexOf(baseUrl) === 0;
  });

  if (cfgs.length === 0) {
    console.error(`Could not find environment specific config for ${currentURI}`);
  } else if (cfgs.length > 1) {
    console.error(`Found multiple environment specific configs for ${currentURI}`);
  }

  conf = _.deepObjectExtend(DefaultConfig, cfgs[0]);
  const replaceVariableFunc = this._replaceVariables.bind(this, conf);

  const iteratee = val => {
    if (val instanceof Array) {
      return val.map(item => iteratee(item));
    }

    if (val instanceof Object) {
      return _.mapObject(val, iteratee);
    }

    if (typeof val === 'string') {
      return val.replace(/\(\(([a-zA-Z0-9_\.]+)\)\)/g, replaceVariableFunc);
    }
    return val;
  };

  conf = _.mapObject(conf, iteratee);

  promise.resolve(conf);
  return promise;
};

Config.getHost = function () {
  return window.location.host;
};

Config.getPathname = function () {
  return window.location.pathname;
};

Config._replaceVariables = function (conf, match, variable) {
  if (~variable.indexOf('.')) {
    // Works only if key is declared before current variable
    const keys = variable.split('.');
    let currentVal = conf[keys[0]];
    for (let i = 1; i < keys.length; ++i) {
      currentVal = currentVal[keys[i]];
      if (currentVal === undefined) {
        throw new Error(`Conf variable ((${variable})) not found`);
      }
    }
    return currentVal;
  }
  if (conf.base[variable] !== undefined) {
    return conf.base[variable];
  }
  throw new Error(`Conf variable ((${variable})) not found`);
};
module.exports = Config;
