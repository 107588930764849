const moment = require('moment');
const $ = require('jquery');
const ShomView = require('../../core/shom-view');

const templates = {
  main: require('../../../template/charts/tidegauge-graph.hbs'),
  surcote: require('../../../template/charts/surcote-graph.hbs')
};
const DDMUtils = require('../../utils/ddm/ddm-utils');
const DDMDataUtils = require('../../utils/ddm/ddm-data-utils');
const DDMSensorReader = require('../../utils/ddm/ddm-sensor-reader');
const ChartsUtils = require('../../utils/charts.js');
const Loading = require('../../utils/loading');
const { REFMAR_DATA_URL } = require('../../utils/constants');

module.exports = ShomView.build({

  events: {},

  initialize(options) {
    const optionsToUse = options || {};
    this._config = optionsToUse.config || window.CONFIG;
    this._eventBus = optionsToUse.eventBus || window.EVENTBUS;
    this._ddmDataUtils = optionsToUse.ddmDataUtils || new DDMDataUtils(this._config);
    this._tideGaugeId = optionsToUse.tideGaugeId;
    this._type = optionsToUse.type || 'main';
    this._timezone = optionsToUse.timezone || 0;
    this._verticalRef = optionsToUse.verticalRef || 0;
    this._containerId = `chart-render-${this._type}`;
    this._toolTipId = `chart-tooltip-${this._type}`;
    this._template = templates[this._type];
    this._startDate = this.updateStart(optionsToUse.start, optionsToUse.isDisplayedHours);
    this._endDate = this.updateStop(optionsToUse.stop, optionsToUse.isDisplayedHours);
    this._selectedSources = optionsToUse.sources || [true, true, true, true, true, true, true, true, true, true];
    this._predictions = [];
    this._noSurge = false;
    this.chart = null;
    this._ddmUtils = optionsToUse.ddmUtils || null;
    this._tideGauge = optionsToUse.tideGauge || null;
    this._spmData = optionsToUse.spmData || null;
    this._observations = optionsToUse.observations || null;
    this._enableNpma = optionsToUse.enableNpma || this._selectedSources[10] === true;
    this._zoomDispatcher = optionsToUse.zoomDispatcher || null;
    this._nphma = null;
    this._npbma = null;
    this._nivMoyen = null;
    this._exportButton = optionsToUse.exportButton || false;
    this._dataSource = optionsToUse.dataSource || '';

    const nbDays = this._ddmDataUtils.getNbDaysBetweenDates(this._startDate, this._endDate);
    this._interval = this._ddmDataUtils.getDataInterval(nbDays);

    const chartParams = {
      colors: [
        this._config.ddm.colors.source1,
        this._config.ddm.colors.source2,
        this._config.ddm.colors.source3,
        this._config.ddm.colors.source4,
        this._config.ddm.colors.source5,
        this._config.ddm.colors.source6,
        this._config.ddm.colors.source7,
        this._config.ddm.colors.source8,
        this._config.ddm.colors.source9,
        this._config.ddm.colors.source10
      ],
      title: $.i18n.t(`ddm.charts.${this._type}`),
      horizontalLine: null
    };

    if (this._type === 'surcote') {
      this._selectedSources[9] = false;
      chartParams.colors = [this._config.ddm.colors.source8, this._config.ddm.colors.source9];
      chartParams.horizontalLine = 0;
    }

    this._chartsUtils = optionsToUse.chartsUtils || new ChartsUtils(chartParams);

    this.render();

    this.initData(true);

    $(window).on('resize', this._createChart.bind(this));
  },

  render() {
    this.$el.html(this._template({
      limit: this._config.ddm.max_days_interval
    }));

    return this;
  },

  updateSources(sources) {
    this._selectedSources = sources;
    if (this._type === 'surcote') {
      this._data[0] = this._selectedSources[7] ? this._data[0] : [];
      this._data[1] = this._selectedSources[8] ? this._data[1] : [];
    } else {
      this._deleteDisabledData();
    }
  },

  updateTimezone(timezone) {
    this._timezone = +timezone;
  },

  updateNpma(npma) {
    this._enableNpma = npma === true;
  },

  updateVerticalRef(verticalRef) {
    this._verticalRef = parseFloat((`${verticalRef}`).replace(',', '.'));
  },

  updateStart(startDate, displayHours) {
    if (displayHours) {
      return this._startDate = moment.utc(startDate);
    }
    return this._startDate = moment.utc(startDate).hour(0).minute(0).second(0)
      .millisecond(0);
  },

  updateStop(endDate, displayHours) {
    if (displayHours) {
      return this._endDate = moment.utc(endDate);
    }
    return this._endDate = moment.utc(endDate).hour(23).minute(59).second(59)
      .millisecond(999);
  },

  updateNoSurgeMessage(surgeDisabled) {
    this._noSurge = surgeDisabled;
  },

  _initDdmUtils() {
    this._ddmUtils = new DDMUtils(this._config);
  },

  initData(recreateChart) {
    const action = recreateChart === true ? this._createChart : this.updateChart;

    if (this._ddmUtils === null) {
      this._initDdmUtils();
      this.initData(recreateChart);
    }

    return this._fetchTideGaugeDescription()
      .then(this._setTideGaugeDescription.bind(this))
      .then(this._fetchSPMData.bind(this))
      .then(this.setSpmData.bind(this))
      .then(this._fetchObservations.bind(this))
      .then(this.setObservations.bind(this))
      .then(action.bind(this));
  },

  _fetchTideGaugeDescription() {
    if (this._tideGauge !== null) {
      return Promise.resolve(this._tideGauge);
    }
    return this._ddmUtils.getSensorDesc(this._tideGaugeId);
  },

  _setTideGaugeDescription(tideGauge) {
    this._tideGauge = tideGauge;
    this._dataSource = tideGauge.name ? REFMAR_DATA_URL + tideGauge.name : '';
    if (this._type === 'main') {
      this._nphma = DDMSensorReader.getNPHMA(this._tideGauge);
      this._npbma = DDMSensorReader.getNPBMA(this._tideGauge);
      this._nivMoyen = DDMSensorReader.getNiveauMoyen(this._tideGauge);
    }
    this._watermarks = [];
    for (const i in this._tideGauge.organismes) {
      if (this._tideGauge.organismes.hasOwnProperty(i)) {
        this._watermarks.push(this._tideGauge.organismes[i].logo);
      }
    }
  },

  _fetchSPMData() {
    if (this._spmData !== null) {
      return Promise.resolve(this._spmData);
    }
    return this._ddmDataUtils.getSPMData(
      DDMSensorReader.getSPMHarbor(this._tideGauge),
      moment.utc(this._startDate).subtract(1, 'day').toISOString(),
      moment.utc(this._endDate).add(1, 'day').toISOString(),
      true
    );
  },

  setSpmData(spmData) {
    this._spmData = spmData || this._spmData || [];
    this._predictions = [];
    for (const i in this._spmData) {
      if (!this._spmData.hasOwnProperty(i)) {
        continue;
      }
      const timestamp = moment.utc(this._spmData[i][0]).add(this._timezone, 'hours');
      if (timestamp >= this._startDate && timestamp <= this._endDate) {
        this._predictions.push({
          date: +timestamp,
          value: Math.round((parseFloat(this._spmData[i][1]) + this._verticalRef) * 1000) / 1000
        });
      }
    }
  },

  _fetchObservations() {
    if (this._observations !== null) {
      return Promise.resolve(this._observations);
    }
    const isShowHours = this._ddmDataUtils.isShowHours(this._startDate, this._endDate);
    const nbDays = isShowHours ? 1 : this._ddmDataUtils.getNbDaysBetweenDates(this._startDate, this._endDate);
    this._interval = this._ddmDataUtils.getDataInterval(nbDays);
    return this._ddmUtils.getSimpleObservationsBetweenDates(
      this._tideGaugeId,
      moment.utc(this._startDate).subtract(this._timezone, 'hours').toISOString(),
      moment.utc(this._endDate).subtract(this._timezone, 'hours').toISOString(),
      this._selectedSources,
      this._interval
    );
  },

  setObservations(observations) {
    this._observations = observations;
    return this._createData();
  },

  _createData() {
    if (this._type === 'main') {
      const predictions = this._selectedSources[6] ? this._predictions : [];
      this._data = [[], [], [], [], [], [], predictions, [], [], []];
      this._data = this._ddmDataUtils.processMainData(this._data, this._observations, this._timezone, this._verticalRef);
      this._eventBus && this._eventBus.trigger('build:mainData', this._observations);
      this._deleteDisabledData();
    } else if (this._type === 'surcote') {
      let source = 0;
      for (let i = 0; i < 6; i++) {
        if (this._selectedSources[i]) {
          source = i + 1;
          break;
        }
      }
      const data = this._ddmDataUtils.processSurcoteData(this._predictions, this._observations, this._timezone, this._interval, this._verticalRef, source);
      this._data = [
        this._selectedSources[7] ? data.surcote : [],
        this._selectedSources[8] ? data.surcote2 : []
      ];
    }
  },

  _deleteDisabledData() {
    for (const i in this._selectedSources) {
      // eslint-disable-next-line no-prototype-builtins
      if (this._selectedSources.hasOwnProperty(i) && !this._selectedSources[i]) {
        this._data[i] = [];
      }
    }
  },

  updateChart(recreateCharts) {
    if (recreateCharts === true) {
      this.setSpmData();
      this._createData();
      return this._createChart();
    }
    const options = {
      start: +moment.utc(this._startDate),
      end: +moment.utc(this._endDate),
      enableNpma: this._enableNpma
    };
    this._chartsUtils.updateData(this._data, options);
    this._adjustChart();
  },

  _createChart() {
    const options = {
      xTicks: 25,
      yTicks: 15,
      unit: 'm',
      start: +moment.utc(this._startDate),
      end: +moment.utc(this._endDate),
      nphma: this._nphma + this._verticalRef,
      npbma: this._npbma + this._verticalRef,
      nivMoyen: this._nivMoyen + this._verticalRef,
      watermarks: this._watermarks,
      enableNpma: this._enableNpma,
      zoomDispatcher: this._zoomDispatcher,
      dataSource: this._dataSource,
      selectedSources: this._selectedSources
    };

    Loading.stop($(`#${this._containerId}`));

    const ids = {
      toolTipId: this._toolTipId,
      containerId: this._containerId
    };

    this.chart = this._chartsUtils.drawDotChart(ids, this._data, options);

    this._adjustChart();

    return this.chart;
  },

  _adjustChart() {
    const isThereData = this._ddmDataUtils.isThereData(this._data);
    if (isThereData) {
      $(`#chart-nodata-${this._type}`).css('display', 'none');
    } else {
      $(`#chart-nodata-${this._type}`).css('display', 'inline-block');
    }

    if (this._exportButton) {
      $(`#chart-export-${this._type}`).css('display', 'block');
      $('.xDataSource').css('display', 'none');
      $('.xLegend').css('display', 'none');
    } else {
      $(`#chart-export-${this._type}`).css('display', 'none');
    }
    const timeScale = this.getTimeScale();
    const delta = timeScale[1] - timeScale[0];
    if (delta > 1000 * 3600 * 24 * this._config.ddm.max_days_interval) {
      $(`#chart-warning-${this._type}`).css('display', 'inline-block');
    } else {
      $(`#chart-warning-${this._type}`).css('display', 'none');
    }
    const disp = isThereData && this._noSurge ? 'inline-block' : 'none';
    $(`#chart-nosurge-${this._type}`).css('display', disp);

    const isThereObsData = this._ddmDataUtils.isThereObsData(this._data);
    if (this._type === 'main') {
      this._updateButtonsAndLabels(isThereObsData);
    }
  },

  _updateButtonsAndLabels(isThereData) {
    this._updateButtons(isThereData, $('#chart-button-nearest'));
    if (!isThereData) { // if no data, check available sources with data
      this._getSourceWithDataInInterval(isThereData);
    }
    // refresh message again
    const chartMessageSourcesAvailablesChildId = 'chart-message-sources-availables-child';
    const childNode = $(`#${chartMessageSourcesAvailablesChildId}`);
    const content = childNode.text().trim();

    // force msg hiding if no content
    if (content === '' || content === '0') {
      isThereData = true;
    }
    $('#chart-message-sources-availables').css('display', isThereData ? 'none' : 'inline-block');
  },

  _getSourceWithDataInInterval(isThereData) {
    this._ddmUtils.getSourceWithDataInInterval(
      this._tideGaugeId,
      moment.utc(this._startDate).subtract(this._timezone, 'hours').toISOString(),
      moment.utc(this._endDate).subtract(this._timezone, 'hours').toISOString()
    ).then(
      response => {
        const chartMessageSourcesAvailables = $('#chart-message-sources-availables');
        const srcSelected = this._isObservationSourceSelected();
        const displayMsg = !(isThereData && srcSelected);
        chartMessageSourcesAvailables.css('display', displayMsg ? 'inline-block' : 'none');
        let content = '0';
        const srcList = [];
        const msgList = [];
        const isSrcFound = response && response.length > 0;
        if (isSrcFound) {
          const sourceMax = 6;
          response.forEach(element => {
            const source = +element.source;
            if (source <= sourceMax) {
              srcList.push(source);
              msgList.push($.i18n.t(`ddm.labels.source${source}`));
            }
          });
          content = msgList.join(', ');
        } else {
          // hide msg
          chartMessageSourcesAvailables.css('display', 'none');
        }

        if (content === '0' || content === '') {
          chartMessageSourcesAvailables.css('display', 'none');
        }

        // update sources list (default: 0)
        const chartMessageSourcesAvailablesChildId = 'chart-message-sources-availables-child';
        const childNode = $(`#${chartMessageSourcesAvailablesChildId}`);
        if (childNode.length) {
          childNode.unbind();
          childNode.remove();
        }

        chartMessageSourcesAvailables.append(`<a href="#" id="${chartMessageSourcesAvailablesChildId}">${content}</a>`);
        if (isSrcFound) {
          // selector below should not be replaced with childNode variable because if childNode doesn't exist it will fail
          $(`#${chartMessageSourcesAvailablesChildId}`).click(this._handleClickAvailableSources.bind(this, srcList));
        }
      }
    );
  },

  _handleClickAvailableSources(sources) {
    if (this._type === 'surcote') {
      return;
    }
    this._spmData = null;
    this._observations = null;

    const timeInfo = this._chartsUtils.getTimeInfos();
    const dates = {
      startDate: moment.utc(timeInfo[0]),
      endDate: moment.utc(timeInfo[1])
    };
    this._eventBus.trigger('formChanged', true, dates, sources);
    return false;
  },

  _isObservationSourceSelected() {
    const sourceMax = 6;
    for (let i = 0; i < sourceMax; i++) {
      if (this._selectedSources[i]) {
        return true;
      }
    }
    return false;
  },

  _enableChartButton(button, enabled) {
    if (button) {
      const classBtnEnabled = 'chart-button';
      const classBtnDisabled = 'chart-button-disabled';
      if (enabled) {
        button.removeAttr('disabled');
        button.removeClass(classBtnDisabled);
        button.addClass(classBtnEnabled);
      } else {
        button.attr('disabled', 'true');
        button.removeClass(classBtnEnabled);
        button.addClass(classBtnDisabled);
      }
    }
  },

  _updateButtons(isThereData, button) {
    if (button) {
      const srcSelected = this._isObservationSourceSelected();
      const enabled = !isThereData;
      this._enableChartButton(button, enabled && srcSelected);
    }
  },

  setTimeScale() {
    const timeInfo = this._chartsUtils.getTimeInfos();
    this._cleanDataIfRefreshNeeded(timeInfo);
    this._startDate = moment.utc(timeInfo[0]);
    this._endDate = moment.utc(timeInfo[1]);
  },

  setTimeScaleWithDate(arrayDate) {
    this._cleanDataIfRefreshNeeded(arrayDate);
    this._startDate = moment.utc(arrayDate[0]);
    this._endDate = moment.utc(arrayDate[1]);
  },

  _cleanDataIfRefreshNeeded(newDates) {
    if (!moment.utc(this._startDate).isSame(moment.utc(newDates[0]), 'hour')
            || !moment.utc(this._endDate).isSame(moment.utc(newDates[1]), 'hour')) {
      this._spmData = null;
      this._observations = null;
    }
  },

  getTimeScale() {
    return this._chartsUtils.getTimeInfos();
  }
});
