import { DISPLAY_DL_MODAL, DISPLAY_OCEANOGRAM_MODAL } from '../utils/constants';

const ShomView = require('../core/shom-view');
const templateDl = require('../../template/dl-modal.hbs');
const templateOceanogram = require('../../template/oceanogram-modal.hbs');

module.exports = ShomView.build({
  initialize(options) {
    const optionsToUse = options || {};
    this._user = optionsToUse.user || window.ROUTER.user;
    this._modalType = optionsToUse.modalType;
  },

  events: {
    'click .ok-btn': '_validModal'
  },

  render() {
    if (this._modalType === DISPLAY_DL_MODAL) {
      this.$el.html(templateDl());
    } else if (this._modalType === DISPLAY_OCEANOGRAM_MODAL) {
      this.$el.html(templateOceanogram());
    }
    return this;
  },

  _validModal() {
    if (this.$('#cb-display-modal:checked').length) {
      this._user.setDisplayModal(false, this._modalType);
    }
  }

});
