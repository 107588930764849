const $ = require('jquery');
const _ = require('underscore');
const ShomView = require('../../core/shom-view');

const ToastrUtil = require('../../utils/toastr.js');
const DLService = require('../../utils/download/service');

const ResultErrorModalView = require('./ocea-result-error-modal.view');

const template = require('../../../template/oceano/ocea-download.hbs');
const ResultModalView = require('./ocea-download-modal');

module.exports = ShomView.build({

  events: {
    'click .download-button': '_onButtonClick'
  },

  initialize(options = {}) {
    this._config = options.config || window.CONFIG;
    this._router = options.router || window.ROUTER;
    this._gisView = options.gisView || window.GISVIEW;
    this._parentView = options.parentView;
    this._modalView = options.modalView || window.MODALVIEW;
    this._layers = options.layers || window.LAYERS;

    this._index = options.index;
    this._started = false;
    this._layer = null;
    this._downloadUrl = '';

    this._DLService = new DLService();

    this.model = options.model;
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({
      index: this._index,
      isDownloadEnabled: !this._gisView.isGlobeEnabled()
    }));

    this._$toggleButton = this.$el.find(`#ocea-btn-download-slider-${this._index}`);
    this._$SelectorButton = this.$el.find(`#ocea-btn-download-selector-${this._index}`);

    this._$SelectorButton.on('click', _.bind(this._onButtonClick, this));
    return this;
  },

  /** ***************************** */
  /** ****** EVENT FUNCTION ******* */
  /** ***************************** */

  _onButtonClick(evt) {
    if (this._gisView.isGlobeEnabled()) {
      return;
    }
    evt.preventDefault();
    this._started ? this.trigger('change:stopDownload') : this.trigger('change:startDownload');
  },

  onClose() {
    if (this._started) {
      this.stopDownload();
    }
  },

  /** ***************************** */
  /** ****** DOWNLOAD FUNCTION **** */
  /** ***************************** */

  _downloadClickHandler(event) {
    ToastrUtil.clear();
    const pixel = this._gisView.currentPixel;
    this._layer = this._gisView.getTopNcwmsLayerOnCoord(event.coordinate, 'NCWMS', this._parentView.getTileGrid(), this.model, pixel);

    if (!this._layer) {
      const title = 'oceano.modal.download_error.title';
      const message = 'oceano.modal.download_error.message';
      this._buildAndShowError(title, message);
    } else {
      const date = this.getCurrentDate();
      const identifier = this._layer.get('identifier');
      const resource = this._getResourceIdentifier(identifier, date);
      this._DLService.getOceanoResourceSize(resource).then(
        _.bind(function (size) {
          if (size > 0) {
            const irregularParent = this._getClosestIrregularGrid(this._layer.get('identifier'));
            if (!irregularParent) {
              return this._buildAndShowResult(size, irregularParent, 0);
            }
            const irregularResource = this._getResourceIdentifier(irregularParent, date);
            this._DLService.getOceanoResourceSize(irregularResource).then(
              irregularSize => {
                this._buildAndShowResult(size, irregularParent, irregularSize);
              }
            );
          } else {
            const modalTitleDL = 'oceano.modal.download_nofile.title';
            const modalMessageDL = 'oceano.modal.download_nofile.message';
            this._buildAndShowError(modalTitleDL, modalMessageDL);
          }
        }, this)
      );
    }
  },

  _downloadOverHandler(event) {
    const pixel = this._gisView.currentPixel;
    this._layer = this._gisView.getTopNcwmsLayerOnCoord(event.coordinate, 'NCWMS', this._parentView.getTileGrid(), this.model, pixel);
    if (this._layer) {
      this._gisView.highlightNcwmsLayers(this._layer);
    }
  },

  startDownload() {
    if (this._started) {
      return;
    }
    this._started = true;
    this._gisView.obscureNCWMSLayers(this.model.get('identifier'));

    ToastrUtil.info($.i18n.t('oceano.toaster.download'));

    this._$toggleButton.prop('checked', true);

    // start download with handlers and get destructor
    this._stopDownload = this._gisView.startNCWMSOverMode(
      this._downloadOverHandler.bind(this),
      this._downloadClickHandler.bind(this)
    );
  },

  stopDownload() {
    if (!this._started) {
      return;
    }
    this._started = false;
    this._layer = null;

    if (this._stopDownload) {
      this._stopDownload();
    }
    this._gisView.resetNcwmsOpacity();

    ToastrUtil.clear();

    this._$toggleButton.prop('checked', false);
    this.trigger('change:stopDownload');
  },

  downloadFile(downloadUrl) {
    const downloadUrlToUse = downloadUrl || this._downloadUrl;
    if (!downloadUrlToUse) {
      return;
    }
    window.open(downloadUrlToUse, '_blank');
    this.stopDownload();
  },

  _createDownloadUrl(identifier, date) {
    const resourceIdentifier = this._getResourceIdentifier(identifier, date);
    const filepath = `${this._config.oceano.downloadUrl + resourceIdentifier}/file/`;
    const filename = `${resourceIdentifier}.zip`;
    this._downloadUrl = filepath + filename;
  },

  _getClosestIrregularGrid(name) {
    const irregularGrids = this._layers.findWhere({
      deliveryIdentifier: this._layer.get('parentIdentifier')
    }).get('irregular_grids');

    const gridCode = parseInt(name.replace(/^.*R([0-9]{4}).*$/i, '$1'), 10);

    let maxParent = [0, ''];
    for (const i in irregularGrids) {
      // eslint-disable-next-line no-prototype-builtins
      if (irregularGrids.hasOwnProperty(i)) {
        const parentCode = parseInt(irregularGrids[i].replace(/^.*R([0-9]{4}).*$/i, '$1'), 10);
        if (gridCode >= parentCode && parentCode > maxParent[0]) {
          maxParent = [parentCode, irregularGrids[i]];
        }
      }
    }

    return maxParent[1];
  },

  _buildAndShowResult(regularSize, irregularParent, irregularSize) {
    const date = this.getCurrentDate();
    const name = this._layer.get('identifier');
    const modelType = this.model.get('currentModelType');

    const downloadBlacklist = this.model.get('downloadBlacklist');
    let enableDirectDl = true;
    let enableArchiveDl = true;
    if (downloadBlacklist) {
      const model = name && name.split('_')[1];
      enableDirectDl = !Array.isArray(downloadBlacklist.direct) || !downloadBlacklist.direct.includes(model);
      enableArchiveDl = !Array.isArray(downloadBlacklist.archive) || !downloadBlacklist.archive.includes(model);
    }

    const options = {
      parentView: this,
      regularSize,
      irregularSize,
      identifier: name,
      date,
      irregularParent,
      modalView: this._modalView,
      modelType,
      enableDirectDl,
      enableArchiveDl
    };

    this._createDownloadUrl(name, date);

    const result = new ResultModalView(options);

    this._modalView.show(result);
  },

  _buildAndShowError(title, message) {
    const result = new ResultErrorModalView(
      {
        title,
        message,
        restart: this.stopDownload.bind(this)
      }
    );
    this._modalView.show(result);
  },

  _getResourceIdentifier(identifier, date) {
    const idWithoutParam = identifier.split('/')[0];
    const formattedDate = date.replace(/-/g, '');
    return `${idWithoutParam}_${formattedDate}.dl`;
  },

  getCurrentDate() {
    const currentMoment = this._gisView.getOceanoLayerCurrentMoment(this.model);
    return currentMoment.utc().format('YYYY-MM-DD');
  }
});
