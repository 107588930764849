import LayerModelUtils from '../../utils/layer-model-utils';
import AtlasHelper from '../../utils/atlas/atlas-utils';

const _ = require('underscore');
const $ = require('jquery');
const ShomView = require('../../core/shom-view');

const NcwmsService = require('../../service/ncwms');
const OceanoNcwmsUtils = require('../../utils/oceano/oceano-ncwms.js');
const OceanoLayerUtils = require('../../utils/oceano/oceano-layer');

const template = require('../../../template/oceano/ocea-palette.hbs');

const MAX_LEGEND_MANTISSA = 6;

const s111LegendsValues = [13, 10, 7, 5, 3, 2, 1, 0.5, 0];
const s111LegendsPositions = [-4, 18, 40, 55, 70, 79, 85, 91, 96];

module.exports = ShomView.build({

  id: 'palette',

  events: {
    'click .palette-close-button': '_onClosePalette'
  },

  initialize(options) {
    this._router = options.router || window.ROUTER;
    this._palette = options.palette;
  },

  render() {
    this.removePalette();
    this.setAndRenderPalette(this._palette, this.model);
    $('#palette').draggable({
      handle: '.palette-header'
    });

    return this;
  },

  removePalette() {
    this.$el.empty();
  },

  _renderPalette(min, max, paletteUnit, options, title) {
    if (this._palette) {
      this.$el.html(template({
        paletteURI: this.paletteURI,
        title
      }));
      this.$('img').on('load', _.bind(function () {
        if(this._palette.name.includes('s111')) {
          this._renderS111LegendsValues();
        } else {
          this._renderLegendsValues(options);
        }
      }, this));
    }
  },

  _updateModel(model) {
    if (this.model !== model) {
      if (this.model) {
        this.stopListening(this.model);
      }
      this.model = model;
      if (this.model) {
        this.listenTo(this.model, 'change:selectedUnit', () => {
          if (this.model.get('showPaletteOnMap')) {
            this.render();
          }
        });
        this.listenTo(this.model, 'change:includedInMap', (currentModel, value) => {
          if (!value) {
            currentModel.set('showPaletteOnMap', false);
            this.setAndRenderPalette(null, null);
          }
        });
      }
    }
  },

  getModel() {
    return this.model;
  },

  getPalette() {
    return this._palette;
  },

  getPaletteDisplayContext() {
    return this.$el.attr('class') ? this.$el.attr('class') : '';
  },

  setPaletteDisplayContext(newContext) {
    this.$el.attr('class', newContext);
  },

  setAndRenderPalette(palette, model) {
    this._updateModel(model);
    if (this.model && palette) {
      const unitDetails = OceanoLayerUtils.getSelectedUnitFromLayer(this.model);
      this._palette = palette;
      this._min = this._palette.min;
      this._max = this._palette.max;
      const translation = this.model.get('translation');
      this._unit = translation ? translation.units[unitDetails.name][window.portalLang] : unitDetails.name;
      if (unitDetails.conversion && unitDetails.conversion.offset && unitDetails.conversion.rate) {
        this._min = OceanoNcwmsUtils.convertUnit(this._min, unitDetails.conversion.offset, unitDetails.conversion.rate);
        this._max = OceanoNcwmsUtils.convertUnit(this._max, unitDetails.conversion.offset, unitDetails.conversion.rate);
      }

      const ncwmsServiceOptions = { ncwmsLayerType: LayerModelUtils.getNcwmsLayerType(model) };

      let translationValue;
      if (AtlasHelper.isAtlasLayer(this.model)) {
        translationValue = AtlasHelper.getLayerTitleAtlas(this.model, window.lang);
      } else {
        const translationKey = this.model.get('title');
        translationValue = translation[translationKey][window.portalLang];
      }
      NcwmsService(ncwmsServiceOptions).then(_.bind(function (service) {
        this.paletteURI = service.getPaletteUrl(palette);
        this._renderPalette(this._min, this._max, this._unit, palette.options, translationValue);
      }, this));
    } else {
      this.removePalette();
    }
  },

  _getMiddleValues(min, max, nbValues, mantissa) {
    let mantissaParsed = parseInt(mantissa, 10);
    const diffMinMax = (max - min) / nbValues;

    // If there is no mantissa, adapt mantissa precision
    if (Number.isNaN(mantissaParsed)) {
      mantissaParsed = 0;
      while (mantissaParsed < MAX_LEGEND_MANTISSA && diffMinMax.toFixed(mantissaParsed) < 10 ** -(mantissaParsed)) {
        mantissaParsed += 1;
      }
    }

    const values = {};
    const pixelByValue = 90 / (nbValues - 1);
    const stepByValue = (max - min) / (nbValues - 1);
    let currentPixels = 0;
    let currentValue = max;

    for (let i = 1; i <= nbValues; i++) {
      const key = `middle${i}`;
      currentValue -= stepByValue;
      const value = (Number.isNaN(mantissaParsed)) ? currentValue : currentValue.toFixed(mantissaParsed);
      currentPixels += pixelByValue;
      values[key] = { value, top: `${currentPixels}%` };
    }
    values.min = min.toFixed(mantissaParsed);
    values.max = max.toFixed(mantissaParsed);
    return values;
  },

  _renderLegendsValues(options) {
    const $min = this.$el.find('#min-palette');
    const $max = this.$el.find('#max-palette');
    const $middle1 = this.$el.find('#middle-1-palette');
    const $middle2 = this.$el.find('#middle-2-palette');
    const $middle3 = this.$el.find('#middle-3-palette');
    const $middle4 = this.$el.find('#middle-4-palette');
    const mantissa = options && options.mantissa;
    const values = this._getMiddleValues(this._min, this._max, 6, mantissa);

    $min.text(`${values.min} ${this._unit}`);
    $max.text(`${values.max} ${this._unit}`);

    $middle1.css({ top: values.middle1.top });
    $middle1.text(values.middle1.value);
    $middle2.css({ top: values.middle2.top });
    $middle2.text(values.middle2.value);
    $middle3.css({ top: values.middle3.top });
    $middle3.text(values.middle3.value);
    $middle4.css({ top: values.middle4.top });
    $middle4.text(values.middle4.value);
  },

  _renderS111LegendsValues() {
    this.$el.find('#min-palette').text(`${s111LegendsValues[s111LegendsValues.length - 1]} ${this._unit}`)
      .css({top: `${s111LegendsPositions[s111LegendsValues.length - 1]}%`});
    this.$el.find('#max-palette').text(`${s111LegendsValues[0]} ${this._unit}`)
      .css({top: `${s111LegendsPositions[0]}%`});
    for(let i= 1; i < s111LegendsValues.length -1; i++) {
      this.$el.find(`#middle-${i}-palette`).text(s111LegendsValues[i]).css({top: `${s111LegendsPositions[i]}%`});
    }
  },

  _onClosePalette() {
    this.removePalette();
    this.model.set('showPaletteOnMap', false);
  }
});
