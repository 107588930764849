const _ = require('underscore');
const ShomView = require('../../../core/shom-view');
const template = require('../../../../template/drawing/discuss/session.hbs');

const SessionView = ShomView.build({
  initialize(options) {
    _.bindAll(this, '_onSessionInfoUpdated');
    this._sessionInfo = null;
    this._user = options.user || window.ROUTER.user;
    this._gisView = options.gisView || window.GISVIEW;
    this.listenTo(this.model, 'discuss:session:update', this._onSessionInfoUpdated);
    this.listenTo(this.model, 'discuss:session:closed', this._onSessionClosed);
  },

  render() {
    const context = this.getContext();
    this.$el.html(template(context));
  },

  getContext() {
    const context = {
      isEditor: false,
      isOwner: false,
      username: '',
      owner: '',
      editor: '',
      connectedUsers: [],
      isLoggedIn: false
    };
    if (!this._sessionInfo) {
      this._sessionInfo = this.model.get('lastUpdateEvent');
    }
    if (this._sessionInfo) {
      const currentUser = _.findWhere(this._sessionInfo.users, { key: this._user.get('key') });
      if (currentUser) {
        context.username = currentUser.name;
      }
      const owner = _.findWhere(this._sessionInfo.users, { key: this._sessionInfo.ownerKey });
      if (owner) {
        context.owner = owner.name;
      }
      const editor = _.findWhere(this._sessionInfo.users, { key: this._sessionInfo.editorKey });
      if (editor) {
        context.editor = editor.name;
      }
      context.connectedUsers = this._sessionInfo.users.map(u => _.extend({}, u, { anonymizedKey: `${u.key.substring(0, 5)}****` }));
      context.isEditor = this.model.isCurrentUserEditor();
      context.isOwner = this.model.isCurrentUserOwner();
      const usersCount = this._sessionInfo.users.length;
      context.existOtherUsers = usersCount > 1;
    }
    context.isLoggedIn = this._user.get('isLoggedIn');
    return context;
  },

  _onSessionInfoUpdated(event) {
    // Affiche ou non les outils de dessin en fonction du contexte
    const context = this.getContext();
    if (context && context.isEditor) {
      this._executeCartoModuleCommands([['drawing.enable', false]]);
    } else {
      this._executeCartoModuleCommands([['drawing.disable', false]]);
    }
    this._gisView.setCurrentlyDrawing(true);

    if (event.updateEditor || event.updateUsers) {
      this._sessionInfo = event;
      this.render();
    }
  },

  _onSessionClosed() {
    // Supprime la barre de dessin quand la session est fermée
    this._executeCartoModuleCommands([['drawing.disable']]);
    this._gisView.setCurrentlyDrawing(false);

    this._sessionInfo = null;
  }
});

module.exports = SessionView;
