const _ = require('underscore');
const Backbone = require('backbone');
const drawingConstraint = require('./drawing-route-security-constraints.js');

const RoutesAuthorizationEnforcer = function (options) {
  options = options || {};
  this._permissionsProvider = options.permissionsProvider;
  this._securityConstraints = options.securityConstraints || [drawingConstraint];
  this._router = options.router || window.ROUTER;
  this._routeListener = options.routeListener || Backbone.history;
  this._redirectTo = options.redirectTo || 'abonne';
  this._routeListener.on('route', this._onRoute, this);
};

RoutesAuthorizationEnforcer.prototype._onRoute = function () {
  const { fragment } = this._routeListener;
  _.each(this._securityConstraints, function (constraint) {
    if (!constraint || !constraint.route || !constraint.validate) {
      return;
    }
    if (constraint.route instanceof RegExp && fragment.match(constraint.route) && !this._permissionsAreOk(constraint.validate)) {
      this._router.navigate(this._redirectTo, {
        trigger: true,
        replace: true
      });
    }
  }, this);
};

RoutesAuthorizationEnforcer.prototype._permissionsAreOk = function (validate) {
  const permissions = this._permissionsProvider.getPermissions();
  if (typeof (validate) === 'function') {
    return validate.call(null, permissions);
  }
  if (typeof (validate) === 'string') {
    return permissions.hasOwnProperty(validate) && permissions[validate] === true;
  }
  return true;
};

module.exports = RoutesAuthorizationEnforcer;
