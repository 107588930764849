const _ = require('underscore');

const ShomView = require('../../../core/shom-view');
const template = require('../../../../template/drawing/discuss/collaborative-chat.hbs');
const lineTemplate = require('../../../../template/drawing/discuss/chat-line.hbs');

const ToastrUtil = require('../../../utils/toastr.js');

const ChatView = ShomView.build({
  events: {
    'submit .chat-form': '_onFormSubmit',
    'hide.bs.collapse #collapse-chat': '_onHideChat',
    'show.bs.collapse #collapse-chat': '_onShowChat',
    'shown.bs.collapse #collapse-chat': '_onShownChat'
  },

  initialize(options) {
    window.CHAT = this;
    _.bindAll(this, '_addLines', '_renderLine');
    this._lastChatUpdate = (new Date()).getTime();
    this._unreadLines = 0;
    // this.model must be the session object
    this._user = options.user || window.ROUTER.user;
    this.listenTo(this.model, 'change:collaborative', this._onCollaborativeChange);
    if (this.model.get('collaborative')) {
      this.listenTo(this.model, 'discuss:session:update', this._onSessionUpdate);
      this.listenTo(this.model, 'discuss:session:closed', this._onSessionClosed);
    }
  },

  _onCollaborativeChange() {
    const collaborative = this.model.get('collaborative');
    if (collaborative) {
      this.listenTo(this.model, 'discuss:session:update', this._onSessionUpdate);
      this.listenTo(this.model, 'discuss:session:closed', this._onSessionClosed);
    } else {
      this.stopListening(this.model, 'discuss:session:update');
      this.stopListening(this.model, 'discuss:session:closed');
    }
    this.render();
  },

  render() {
    const collaborative = this.model.get('collaborative');
    if (collaborative) {
      this.$el.html(template());
    } else {
      this.$el.html('');
    }
    return this;
  },

  _onSessionUpdate(updateEvent) {
    if (updateEvent.updateChat) {
      this.model.getChatLines(this._lastChatUpdate)
        .then(this._addLines)
        .fail(this._onGetChatLinesFailed);
      this._lastChatUpdate = updateEvent.chatTimestamp;
    }
  },
  _onSessionClosed() {
    this._lastChatUpdate = (new Date()).getTime();
    this._unreadLines = 0;
  },

  _addLines(chatLines) {
    if (chatLines && chatLines.length) {
      const lines = chatLines.map(l => {
        const d = new Date(l.date);
        return {
          key: l.userKey,
          userName: l.userName,
          messageDate: `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`,
          text: l.text
        };
      });
      lines.forEach(this._renderLine);
      this._updateChatContainer(lines);
    }
  },

  _renderLine(line) {
    const templatedChatLine = lineTemplate(line);
    this.$('.chat-body ul').append(templatedChatLine);
  },

  _updateChatContainer(lines) {
    this._unreadLines += lines.length || 0;
    this._scrollToBottom();
    const $chatPanelTitle = this.$('.chat-container');
    const isChatVisible = this.$('#collapse-chat').hasClass('in');
    const hasNewMessagesFromOthers = this._filterOtherMessages(lines).length > 0;
    if (hasNewMessagesFromOthers) {
      if (isChatVisible) {
        $chatPanelTitle.addClass('chat-blink');
        // Dirty hack : enable to run the animation by adding the class, and remove it just after that.
        setTimeout(() => {
          $chatPanelTitle.removeClass('chat-blink');
        }, 1600);
      } else {
        $chatPanelTitle.addClass('chat-unread');
      }
    }
  },

  _scrollToBottom() {
    const $chatBody = this.$('.chat-body');
    $chatBody.animate({ scrollTop: $chatBody.prop('scrollHeight') }, 300);
  },

  _onGetChatLinesFailed() {
    ToastrUtil.error('Erreur lors de la récupération des lignes de chat');
  },

  _onFormSubmit() {
    const $chatInput = this.$('.chat-input');
    let text = $chatInput.val();
    text = text && text.trim();
    if (text) {
      this.model.putChatLine(text)
        .then(_.bind(function () {
          this.model.forceCheckUpdates();
        }, this));
      $chatInput.val('').focus();
    }
    return false;
  },

  _filterOtherMessages(lines) {
    if (!lines) {
      return [];
    }
    const myKey = this._user.get('key');
    return _.filter(lines, l => l.key !== myKey);
  },

  _onHideChat() {
  },

  _onShowChat() {
    this._unreadLines = 0;
    this.$('.chat-container').removeClass('chat-unread');
  },

  _onShownChat() {
    this._scrollToBottom();
  }
});

module.exports = ChatView;
