const $ = require('jquery');

module.exports = function () {
  return {
    name: {
      validators: {
        notEmpty: {
          message: $.i18n.t('catalog.external.kml.name.empty')
        }
      }
    },
    file: {
      validators: {
        notEmpty: {
          message: $.i18n.t('catalog.external.kml.file.empty')
        }
      }
    },
    url: {
      validators: {
        notEmpty: {
          message: $.i18n.t('catalog.external.kml.url.empty')
        },
        uri: {
          message: $.i18n.t('catalog.external.kml.url.invalid')
        }
      }
    }
  };
};
