import Dialog from 'bootstrap-dialog';

import OceanogrammeUtils from '../../utils/oceano/oceanogramme-utils';
import AtlasHelper from '../../utils/atlas/atlas-utils';
import {
  LAYERTYPE_REFMAR,
  LAYERTYPE_FORECAST,
  DISPLAY_OCEANOGRAM_MODAL,
  CATALOG_OCEANO,
  CATALOG_OBSERVATIONS,
  CATALOG_DEFAULT_ORDER_INDEX
} from '../../utils/constants';

const $ = require('jquery');
const ShomLayerView = require('../../core/shom-layer-view');
const IncludedInMapView = require('../layer-included-in-map.view');
const ToastrUtil = require('../../utils/toastr.js');
const SensorBottomView = require('../ddm/sensor-bottom.view.js');
const OceanoLayerUtils = require('../../utils/oceano/oceano-layer');
const template = require('../../../template/catalog/oceano-layer.hbs');
const CookieModalView = require('../cookie-modal.view');

module.exports = ShomLayerView.build({

  className: 'catalog-layer-item',

  events: {
    'click .layer-title-container': '_onTitleClick',
    'click .layer-help-link': 'onHelpLinkClick',
    'click .cog-oceano-catalog': '_onConfigureClick'
  },

  initialize(options = {}) {
    this.config = options.config || window.CONFIG;
    this._gisView = options.gisView || window.GISVIEW;
    this._gfiModeManager = options.gfiModeManager || window.POI_MODE_MANAGER;
    this._router = options.router || window.ROUTER;
    this._eventBus = options.eventBus || window.EVENTBUS;
    this._user = options.user || window.ROUTER.user;
    this._modalView = options.modalView || window.MODALVIEW;
    this._sensorView = null;
    this._wfsService = null;
    this.searchView = options.searchView || false;
    // keep index in view to sort all views in a category
    this.index = this.model.get('indexInCat') || CATALOG_DEFAULT_ORDER_INDEX;
  },

  render() {
    const translatedTitle = this.model.get('layerType') === LAYERTYPE_REFMAR
      ? $.i18n.t(this.model.get('translationKey'))
      : OceanoLayerUtils.getLayerTitle(this.model, window.portalLang);

    const hasHelpLinks = !!(this.model.attributes.helpLinks);
    this.$el.html(template({
      layer: this.model.toJSON(),
      translatedTitle,
      hasHelpLinks,
      includedInMap: this.model.get('includedInMap')
    }));
    this._$layerTitle = this.$('.layer-title-container');
    this._$layerDescription = this.$('.description');

    this._$addRemoveContainer = this.$('.add-remove-root');
    this._renderAddRemoveView();

    return this;
  },

  _renderAddRemoveView() {
    this._addRemoveView && this._addRemoveView.remove();
    this._addRemoveView = new IncludedInMapView({
      model: this.model,
      parentView: this
    });
    this._$addRemoveContainer.html(this._addRemoveView.render().$el);
  },

  _onTitleClick() {
    const modelLayerType = this.model.get('layerType');
    if (modelLayerType === LAYERTYPE_REFMAR) {
      return this._toggleRefmarLayer();
    }

    if (modelLayerType === LAYERTYPE_FORECAST) {
      return this._toggleForecastLayer();
    }

    const includedInMap = !this.model.get('includedInMap');
    const visibility = (includedInMap) ? true : this.model.get('visibility');

    this.model.set('includedInMap', includedInMap);
    this.model.set('visibility', visibility);
    this._gfiModeManager.startGfiRequestableLayer(false);

    this._showAdditionnalInfo();
  },

  _onConfigureClick() {
    const modelLayerType = this.model.get('layerType');
    if (this._isRefmarOrForecastLayerType(modelLayerType)) {
      return this._toggleRefmarLayer(true);
    }

    // select model
    this.model.set('includedInMap', true);

    // move layer to the top
    this._gisView.moveLayerTotheTop(this.model);

    // show additionnal info
    this._showAdditionnalInfo();

    // go to configuration
    this._router.navigate('donnees', true);
    this._eventBus.trigger('search:clear');
  },

  /// //////// Forecast related ////////////
  _toggleForecastLayer() {
    if (this.config.disabledFeatures && this.config.disabledFeatures.oceanogramme) {
      return Dialog.show({
        type: Dialog.TYPE_DANGER,
        title: 'Attention',
        message: $.i18n.t('oceanogramme.disabled')
      });
    }
    const includedInMap = this.model.get('includedInMap');
    if (!includedInMap) {
      this.showForecastLayer();
    } else {
      this._hideForecastLayer();
    }
    this.model.set('includedInMap', !includedInMap);
  },

  _isInLand(lon, lat) {
    return new OceanogrammeUtils(this.config).isInLand(lon, lat);
  },

  showForecastLayer() {
    this._gisView.initOceanogrammeGis(this._isInLand.bind(this));
    this._gisView.setForecastLayerChildren(this.model);
    this._gisView.createHoverInteraction();
    this._gfiModeManager && this._gfiModeManager.stop();
    this._router.addForecastLayer();
    if (this._user.isDisplayModal(DISPLAY_OCEANOGRAM_MODAL)) {
      this._modalView.show(new CookieModalView({
        modalType: DISPLAY_OCEANOGRAM_MODAL
      }));
    }
  },

  _hideForecastLayer() {
    this._gfiModeManager.startGfiRequestableLayer(true);
    this._gisView.removeOceanogrammeGis();
    this._gisView.clearForecastLayerChildren(this.model);
    this._router.subtractForecastLayer();
  },
  /// /////////////////////////////////////

  _toggleRefmarLayer(isConfigure) {
    if (this._sensorView === null) {
      return this._loadRefmarLayer(isConfigure);
    }

    if (isConfigure === true || !this.model.get('includedInMap')) {
      if (!this.model.get('includedInMap')) {
        this._sensorView.addLayerFeatures();
        this.model.set('includedInMap', true);
        this._router.addRefmarLayer();
      }
      if (isConfigure === true) {
        this._gisView.moveLayerTotheTop(this.model);
        this._router.navigate('donnees', true);
      }
    } else {
      this.model.set('includedInMap', false);
      this._sensorView.removeLayerFeatures();
      this._router.subtractRefmarLayer();
    }

    this._showAdditionnalInfo();

    this._addRemoveView.render();
  },

  _loadRefmarLayer(isConfigure) {
    this._sensorView = new SensorBottomView({
      model: this.model,
      network: this.model.get('identifier').replace(/^REFMAR\//, '')
    });
    this._sensorView.loadFeatures()
      .then(() => this._toggleRefmarLayer(isConfigure));
  },

  _showAdditionnalInfo() {
    if (this.model.get('includedInMap') && this.model.get('additionalInfo')) {
      ToastrUtil.info(this.model.get('additionalInfo')[window.portalLang]);
    }
  },

  _isRefmarOrForecastLayerType(layerType) {
    return [LAYERTYPE_REFMAR, LAYERTYPE_FORECAST].includes(layerType);
  },

  postTranslate(lang) {
    this.showHideHelpLinkIfExistOrNot();
    let translatedCatalog;
    let translatedTitle;
    let translatedCategory;
    let abstract = '';

    const catalogTranslations = {
      [CATALOG_OCEANO]: {
        fr: $.i18n.options.resStore.fr.translation.catalog.tab.oceanoForecast,
        en: $.i18n.options.resStore.en.translation.catalog.tab.oceanoForecast
      },
      [CATALOG_OBSERVATIONS]: {
        fr: $.i18n.options.resStore.fr.translation.catalog.tab.observation,
        en: $.i18n.options.resStore.en.translation.catalog.tab.observation
      }
    };

    const modelLayerType = this.model.get('layerType');
    const isRefmarOrForecast = this._isRefmarOrForecastLayerType(modelLayerType);

    if (isRefmarOrForecast) {
      translatedCatalog = catalogTranslations[this.model.get('catalog') || 'oceano'][lang];
      translatedCategory = this.model.get('translation')[modelLayerType][lang];
      translatedTitle = $.i18n.t(this.model.get('translationKey'));
    } else if (AtlasHelper.isAtlasLayer(this.model)) {
      translatedCategory = OceanoLayerUtils.getCategoryTitle(this.model, lang);
      translatedTitle = this.searchView ? AtlasHelper.getLayerTitleAtlas(this.model, lang, true)
        : AtlasHelper.getLayerTitleAtlas(this.model, lang);
    } else {
      translatedCatalog = catalogTranslations[this.model.get('catalog') || 'oceano'][lang];
      translatedCategory = OceanoLayerUtils.getCategoryTitle(this.model, lang);
      translatedTitle = OceanoLayerUtils.getLayerTitle(this.model, lang);
    }

    if (translatedCatalog) {
      abstract = `${translatedCatalog} - `;
    }
    abstract += `${translatedCategory} - ${translatedTitle}`;

    this._$layerTitle
      .empty()
      .append(translatedTitle)
      .append(`<span class="description">${abstract}</span>`)
      .attr('title', abstract);
  }

});
