const Matches = require('../../../../config/mercator-match.yaml');

const MatchMercatorHelper = function () {

};

MatchMercatorHelper.prototype.getMercatorLayerName = function (layerName4326) {
  return Matches[layerName4326];
};

module.exports = new MatchMercatorHelper();
