var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ocean-select-palette-map\">\n    <a href=\"#\" role=\"button\" class=\"layer-included-in-map ocean-palette-on-map-btn "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showPaletteOnMap") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":84},"end":{"line":14,"column":121}}})) != null ? stack1 : "")
    + "\"\n         data-i18n=\"[title]oceano.nav.button.dispPaletteOnMap.help;[aria-label]oceano.nav.button.dispPaletteOnMap.help\"></a>\n    <div class=\"ocean-palette-on-map-txt\" data-i18n=\"oceano.nav.button.dispPaletteOnMap.label\"></div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "active";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"select-wrapper\">\n    <label for=\"ocean-palette-select-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\" class=\"visually-hidden\" data-i18n=\"oceano.nav.palette.label\"></label>\n    <select class=\"ocean-select ocean-palette-selector\" id=\"ocean-palette-select-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\"></select>\n    <i class=\"fas fa-chevron-down\"></i>\n</div>\n\n<button class=\"btn btn-primary ocean-auto-palette-btn\" id=\"automatic-palette-button-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\">\n    <i class=\"fas fa-magic\"></i>\n    <span data-i18n=\"oceano.nav.button.automaticPalette.title\"></span>\n</button>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hidePalette") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":0},"end":{"line":18,"column":11}}})) != null ? stack1 : "");
},"useData":true});