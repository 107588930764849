import Collection from 'ol/Collection';
import ImageWMSSource from 'ol/source/ImageWMS';
import ImageLayer from 'ol/layer/Image';
import TileLayer from 'ol/layer/Tile';
import LayerGroup from 'ol/layer/Group';
import * as Extent from 'ol/extent';
import TileGrid from 'ol/tilegrid/TileGrid';
import { get as getProjection } from 'ol/proj.js';

const $ = require('jquery');
const _ = require('underscore');
const moment = require('moment');

const CoordsHelper = require('./coordinates-helper');
const OceanoLayerUtils = require('../oceano/oceano-layer');

const OceanoHelper = function () {

};

OceanoHelper.prototype.getDisplayedLayersWithAttributes = function (collection, attributes) {
  return collection.where(attributes);
};

OceanoHelper.prototype.getFirstLayerOnPixel = function (map, pixel, type, id) {
  const topLayers = [];
  map.forEachLayerAtPixel(
    pixel,
    l => topLayers.push(l),
    {
      layerFilter: layer => {
        const isTileLayer = layer instanceof TileLayer || layer instanceof ImageLayer;
        const isLayerTypeOk = (layer.get('type') === type);
        const isLayerParentIdOk = (layer.get('parentIdentifier') === id);
        if (isTileLayer && isLayerTypeOk && isLayerParentIdOk) {
          return true;
        }
      }
    }
  );

  return topLayers[0];
};

OceanoHelper.prototype.getTopNcwmsLayerOnCoord = function (map, coord, returnType, tilesGrid, layerGroup, pixel) {
  const deliveryIdentifier = layerGroup.get('deliveryIdentifier');
  const identifier = layerGroup.get('identifier');

  const parentId = (layerGroup)
    ? (deliveryIdentifier !== undefined ? deliveryIdentifier : identifier) : null;
  const layers = (layerGroup) ? layerGroup.get('olLayer').getLayers().getArray() : [];

  const encodingType = 'NCWMSEncoding';
  returnType = returnType || 'NCWMS';

  // get only the first encoding layer on this pixel
  const topLayer = this.getFirstLayerOnPixel(map, pixel, encodingType, parentId);

  if (!tilesGrid) {
    // return first elt : that's a dirty fix...
    return topLayer;
  }

  if (!topLayer) {
    return null;
  }

  // if the returnType is the encoding one we return the topLayer found
  if (returnType === encodingType) {
    return topLayer;
  }

  // if the return type is not the encoding one, we search the correct region tile
  const index = _.findIndex(layers, l => l.get('identifier') === topLayer.get('identifier'));

  const tiles = layers[index].getLayers().getArray();

  const indexTile = _.findIndex(tiles, tile => tile.get('type') === returnType);

  return tiles[indexTile];
};

OceanoHelper.prototype._getTileBoundsFromUrl = function (url) {
  const regExp = /.*BBOX=([-+]?[0-9]*\.?[0-9]+(?:e-[0-9]+)?)%2C([-+]?[0-9]*\.?[0-9]+(?:e-[0-9]+)?)%2C([-+]?[0-9]*\.?[0-9]+(?:e-[0-9]+)?)%2C([-+]?[0-9]*\.?[0-9]+(?:e-[0-9]+)?).*/;
  const groups = url.match(regExp);
  return groups.slice(1, 5).map(a => parseFloat(a));
};

OceanoHelper.prototype._getCurrentTileFromGrid = function (tilesGrid, layer, zoom, coord) {
  const grid = layer.getSource().getTileGrid();
  const tileCoord = grid.getTileCoordForCoordAndZ(coord, zoom - 2); // we need -2 because tilesize = 512...
  const tile = tilesGrid[layer.get('identifier')][tileCoord[0]][tileCoord[1]][tileCoord[2]];
  return tile;
};

OceanoHelper.prototype.getPixelDataOnCoord = function (tilesGrid, layer, zoom, coord) {
  let tile = null;
  if (layer.get('singleTile')) {
    tile = tilesGrid[layer.get('identifier')].IMAGE;
  } else {
    tile = this._getCurrentTileFromGrid(tilesGrid, layer, zoom, coord);
  }
  const img = tile.getImage();
  const tileBounds = this._getTileBoundsFromUrl(img.src.trim());

  // lonlatBounds : lonmin/latmin/lonmax/latmax
  const lonlatBounds = CoordsHelper.convertMercatorBboxToLonLat(tileBounds);
  // lonlat : lon/lat
  const lonlat = CoordsHelper.convertMercatToLonLat(coord);

  const lonInTile = lonlat[0] - lonlatBounds[0];
  const xInTile = Math.floor((lonInTile * img.width) / (lonlatBounds[2] - lonlatBounds[0]));

  const latInTile = lonlat[1] - lonlatBounds[3];
  const yInTile = Math.floor((latInTile * img.height) / (lonlatBounds[1] - lonlatBounds[3]));

  const canvas = $('<canvas />')[0];
  canvas.width = img.width;
  canvas.height = img.height;
  canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height);
  const pixelData = canvas.getContext('2d').getImageData(xInTile, yInTile, 1, 1).data;

  return pixelData;
};

OceanoHelper.prototype._isTransparentLayerAtCoord = function (tilesGrid, layer, zoom, coord) {
  const pixelData = this.getPixelDataOnCoord(tilesGrid, layer, zoom, coord);
  return pixelData[3] === 0;
};

OceanoHelper.prototype.buildSingleTileRegLayer = function (regLayer) {
  const tiledLayers = regLayer.getLayers().getArray();

  const layerParams = {
    title: regLayer.get('title'),
    identifier: regLayer.get('identifier'),
    abstract: regLayer.get('abstract'),
    maxExtent: regLayer.get('maxExtent'),
    singleTile: true
  };

  const ncwmsTiledLayer = tiledLayers[1];
  const ncwmsTiledSource = ncwmsTiledLayer.getSource();
  const ncwmsTiledParams = ncwmsTiledSource.getParams();
  const url = ncwmsTiledSource.getUrls()[0];

  const source = new ImageWMSSource({
    url,
    crossOrigin: 'anonymous',
    params: ncwmsTiledParams,
    ratio: 1
  });
  const NCWMSLayer = new ImageLayer(_.extend(layerParams, {
    source,
    type: 'NCWMS',
    opacity: 1
  }));

  const encodingTiledLayer = tiledLayers[0];
  const encodingTiledSource = encodingTiledLayer.getSource();
  const encodingTiledParams = encodingTiledSource.getParams();

  const encodingSource = new ImageWMSSource({
    url,
    crossOrigin: 'anonymous',
    params: encodingTiledParams,
    ratio: 1
  });

  const encodingNCWMSLayer = new ImageLayer(_.extend(layerParams, {
    source: encodingSource,
    type: 'NCWMSEncoding',
    opacity: 0
  }));

  const regionParamLayerGroup = new LayerGroup({
    title: regLayer.get('title'),
    identifier: regLayer.get('identifier'),
    abstract: regLayer.get('abstract'),
    maxExtent: regLayer.get('maxExtent')
  });
  regionParamLayerGroup.setLayers(new Collection([encodingNCWMSLayer, NCWMSLayer]));

  return regionParamLayerGroup;
};

OceanoHelper.prototype.getCurrentTimeFromGroupLayer = function (layerGroup, mapZoom) {
  const regLayers = layerGroup.get('olLayer').getLayers().getArray();
  for (let i = 0, len = regLayers.length; i < len; i++) {
    const layer = regLayers[i].getLayers().getArray()[1];
    if (this.isLayerIsInZoom(layer, mapZoom)) {
      return OceanoLayerUtils.getCurrentTimeFromLayer(layer);
    }
  }
  return null;
};

OceanoHelper.prototype.isRegionLayerIsInBounds = function (layer, bounds) {
  const ncwmsLayer = layer.getLayers().getArray()[1];
  return this.isLayerIsInBounds(ncwmsLayer, bounds);
};

OceanoHelper.prototype.needToRefresh = function (currentGroup, newGroup) {
  if (!currentGroup) {
    return true;
  }
  const currentLayers = currentGroup.get('olLayer').getLayers().getArray();
  const currentIds = currentLayers.map(lay => lay.get('identifier'));
  const newLayers = newGroup.get('olLayer').getLayers().getArray();
  const newIds = newLayers.map(lay => lay.get('identifier'));
  const diff = _.difference(newIds, currentIds);
  return newLayers.length === 0 || diff.length > 0;
};

OceanoHelper.prototype.isLayerIsInBounds = function (ncwmsLayer, bounds) {
  const bbox = ncwmsLayer.getSource().getParams().BBOX;
  if (bbox) {
    const layerExtent = bbox.split(',').map(Number);
    const inter = Extent.intersects(layerExtent, bounds);
    return inter;
  }
  return false;
};

OceanoHelper.prototype.isLayerIsInZoom = function (layer, zoom) {
  const zoomAllowed = layer.get('zoomAllowed');
  if (!zoomAllowed) {
    return true;
  }
  const minZoom = zoomAllowed.min;
  const maxZoom = zoomAllowed.max;
  return (zoom >= minZoom && zoom <= maxZoom);
};

OceanoHelper.prototype.isDateExistInLayer = function (ncwmsLayer, dateISO) {
  const layerTimes = ncwmsLayer.get('dimensions').processedTime;
  const dateSplit = dateISO.split('T');
  const date = dateSplit[0];
  const time = dateSplit[1];
  return layerTimes[date] && _.contains(layerTimes[date], time);
};

OceanoHelper.prototype.updateMaxExtent = function (maxExtent, layerExtent) {
  if (maxExtent === null) {
    maxExtent = layerExtent;
  } else {
    maxExtent[0] = Math.min(maxExtent[0], layerExtent[0]);
    maxExtent[1] = Math.max(maxExtent[1], layerExtent[1]);
    maxExtent[2] = Math.min(maxExtent[2], layerExtent[2]);
    maxExtent[3] = Math.max(maxExtent[3], layerExtent[3]);
  }
  return maxExtent;
};

OceanoHelper.prototype.is3DNcwmsLayer = function (layer) {
  // it assumes it's a ncwms layer
  if (!layer) {
    return false;
  }
  return layer.get('dimensions').elevation.length > 1;
};

OceanoHelper.prototype._getDisplayedNcwmsLayers = function (layers) {
  const layerGroup = this.getDisplayedLayersWithAttributes(layers, {
    includedInMap: true,
    layerType: 'NCWMS'
  });
  if (!layerGroup || layerGroup.length === 0) {
    return null;
  }
  let newlayers = [];
  layerGroup.forEach(layer => {
    newlayers = newlayers.concat(layer.get('olLayer').getLayers().getArray());
  });
  return newlayers;
};

OceanoHelper.prototype.getDisplayedNcwmsLayersInView = function (layers, extent) {
  const layerGroup = this.getDisplayedLayersWithAttributes(layers, {
    includedInMap: true,
    layerType: 'NCWMS'
  });
  if (!layerGroup || layerGroup.length === 0) {
    return null;
  }
  const regLayers = layerGroup[0].get('olLayer').getLayers().getArray();
  const displayedInViewLayers = [];
  for (let i = 0, len = regLayers.length; i < len; i++) {
    const regLayer = regLayers[i];
    const regBbox = regLayer.get('regExtent');
    if (regBbox[0] >= extent[0]
            || regBbox[1] <= extent[1]
            || regBbox[2] >= extent[2]
            || regBbox[3] <= extent[3]) {
      displayedInViewLayers.push(regLayer);
    }
  }
  return displayedInViewLayers;
};

OceanoHelper.prototype.resetNcwmsOpacity = function (layers) {
  const displayedLayers = this._getDisplayedNcwmsLayers(layers);
  _.each(displayedLayers, layer => {
    layer.set('opacity', 1);
  });
};

OceanoHelper.prototype.highlightNcwmsLayers = function (layers, selectedLayer) {
  const displayedLayers = this._getDisplayedNcwmsLayers(layers);
  _.each(displayedLayers, layer => {
    if (layer.get('identifier') === selectedLayer.get('identifier')) {
      layer.set('opacity', 1);
    } else {
      layer.set('opacity', 0.1);
    }
  });
};

OceanoHelper.prototype.obscureNcwmsLayers = function (layers, identifier) {
  const displayedLayers = this._getDisplayedNcwmsLayers(layers);
  _.each(displayedLayers, layer => {
    if (layer.get('parentIdentifier') === identifier) {
      layer.set('opacity', 0.1);
    }
  });
};

OceanoHelper.prototype.deactivateInteractions = function (interactions, eventBus) {
  const disabledInteractions = [];
  const interactionsArray = interactions.getArray();
  for (let i = 0, len = interactionsArray.length; i < len; i++) {
    const interaction = interactionsArray[i];
    if (interaction.getActive()) {
      interaction.setActive(false);
      disabledInteractions.push(interaction);
    }
  }
  eventBus.trigger('change:overView', { checked: false });
  eventBus.trigger('change:navigation-bar', { checked: false });
  return disabledInteractions;
};

OceanoHelper.prototype.activateInteractions = function (interactions, eventBus) {
  const enabledInteractions = [];
  const interactionsArray = interactions;
  for (let i = 0, len = interactionsArray.length; i < len; i++) {
    const interaction = interactions[i];
    if (!interaction.getActive()) {
      interaction.setActive(true);
      enabledInteractions.push(interaction);
    }
  }
  eventBus.trigger('change:overView', { checked: true });
  eventBus.trigger('change:navigation-bar', { checked: true });
  return enabledInteractions;
};

OceanoHelper.prototype.getMetadataUrl = function (identifier) {
  const { catalogUrl } = window.CONFIG.oceano;
  identifier = identifier.split('/')[0];
  const date = moment().format('YYYYMMDD');
  const metadataFileName = `${identifier}_${date}.xml`;
  const metadataURL = catalogUrl + metadataFileName;

  return metadataURL;
};

OceanoHelper.prototype.getProcessedTimeInZoom = function (layerGroup, zoom) {
  const layers = layerGroup.get('olLayer').getLayers().getArray();
  for (const i in layers) {
    if (layers.hasOwnProperty(i)) {
      const regLayer = layers[i];
      if (this.isLayerIsInZoom(regLayer, zoom)) {
        return regLayer.getLayers().getArray()[0].get('dimensions').processedTime;
      }
    }
  }

  return null;
};

OceanoHelper.prototype.createTileGrid = function (options) {
  const opts = options || window.CONFIG;
  const projection = getProjection(opts.projection);
  const projectionExtent = projection.getExtent();
  const maxResolution = Extent.getWidth(projectionExtent) / (opts.tileSize * 2);
  const resolutions = [opts.maxZoom];
  for (let z = 0; z < opts.maxZoom; ++z) {
    resolutions[z] = maxResolution / 2 ** z;
  }
  return new TileGrid({
    origin: Extent.getTopLeft(projectionExtent),
    resolutions,
    tileSize: opts.tileSize
  });
};

module.exports = new OceanoHelper();
