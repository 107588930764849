import LayerModelUtils from '../../utils/layer-model-utils';

const _ = require('underscore');

const ShomView = require('../../core/shom-view');

const InfoPopover = require('../info-popover.view');

const NCWMS = require('../../service/ncwms');

const Loading = require('../../utils/loading.js');
const NcwmsLayerUtils = require('../../utils/oceano/oceano-layer.js');
const OceanoNcwmsUtils = require('../../utils/oceano/oceano-ncwms.js');

const template = require('../../../template/oceano/ocea-vertical-result-modal.hbs');

const OceanoVerticalAnimationView = require('./ocea-vertical-animation.view');

module.exports = ShomView.build({

  className: 'hitable modal-dialog',

  events: {
    'click .slider-refresh-result-image': '_resultFilterDepth'
  },

  /** ***************************** */
  /** ****** INIT FUNCTIONS ******* */
  /** ***************************** */

  initialize(options) {
    const optionsToUse = options || {};
    this.model = optionsToUse.model;
    this._config = optionsToUse.config || window.CONFIG;
    this._gisView = optionsToUse.gisView || window.GISVIEW;

    this._title = optionsToUse.title;
    this._title_option = optionsToUse.title_option || '';
    this._coordinates = optionsToUse.coords || '';

    this._layer = optionsToUse.layer;
    this._pointClicked = optionsToUse.pointClicked;
    this._depthBounds = optionsToUse.depthBounds;
    this._layerMoment = this._getLayerCurrentDate();
    this._init3Dcomponents();
  },

  _init3Dcomponents() {
    this._minDepth = this._depthBounds[0];
    this._maxDepth = this._depthBounds[1];
  },

  /** ***************************** */
  /** ***** RENDER FUNCTIONS ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({
      selectedDate: this._layerMoment,
      displayTabs: this._config.oceano.displayVerticalAnimationTab
    }));

    this._$verticalResultTitle = this.$el.find('#ocea-result-title');
    this._$verticalResultTitleOptions = this.$el.find('#ocea-result-title-option');
    this._$verticalResultDiv = this.$el.find('#ocea-result-content');
    this._$verticalResultError = this.$('#ocea-result-content-error');
    this._$verticalStaticimageContainer = this.$el.find('img#vertical-static-img');
    this._$verticalAnimationContainer = this.$el.find('#vertical-animation .vertical-result');
    this._$verticalResultModalContent = this.$el.find('.modal-content');
    this._$doubleSlider = this.$el.find('.double-slider');
    this._$doubleSliderTopHandle = this.$el.find('.double-slider-upper-level');
    this._$doubleSliderBotHandle = this.$el.find('.double-slider-lower-level');
    this._$doubleSliderContainer = this.$el.find('.vertical-slider-container');
    this._$helpDepthSpan = this.$el.find('.help-depth');
    this._$modalBody = this.$el.find('.modal-body');

    this._$verticalResultModalContent.draggable({
      handle: '.modal-header'
    });

    if (this._error && this._error !== '') {
      this._$verticalResultDiv.attr('data-i18n', this._error);
      return this;
    }
    this._renderTitle();
    this._renderAnimationView();
    this._renderSliders();
    this._renderInfoPopover();

    this._updateVerticalProfile(true);
    this._getVerticalProfile(this._depthBounds);
    return this;
  },

  _renderTitle() {
    this._$verticalResultTitle.attr('data-i18n', this._title);

    let titleOption = this._title_option;
    if (this._coordinates) {
      titleOption += ` - ${this._coordinates}`;
    }
    this._$verticalResultTitleOptions.html(titleOption);
  },

  _renderSliders() {
    this._$doubleSlider.slider({
      orientation: 'vertical',
      range: true,
      min: -this._maxDepth,
      max: -this._minDepth,
      values: [-this._maxDepth, -this._minDepth],
      create: _.bind(this._updateSliders, this, -this._minDepth, -this._maxDepth),
      slide: _.bind(this._onSlidersSlideCallBack, this)
    });
  },

  _renderInfoPopover() {
    const depthInfoPopover = new InfoPopover({
      message: 'oceano.popover.transect.depth',
      placement: 'top',
      classname: 'info-popover-button transect-help-depth'
    });

    this._$helpDepthSpan.html(depthInfoPopover.render().$el);
  },

  _renderAnimationView() {
    this._animationView = new OceanoVerticalAnimationView({
      layer: this._layer,
      model: this.model,
      slider: this._$doubleSlider,
      depthBounds: [this._minDepth, this._maxDepth],
      pointClicked: this._pointClicked
    }).render();
    this._$verticalAnimationContainer.empty();
    this._$verticalAnimationContainer.html(this._animationView.$el);
  },

  /** ***************************** */
  /** ***** EVENT FUNCTIONS ******* */
  /** ***************************** */

  _onSlidersSlideCallBack(event, ui) {
    // Prevents the two sliders bounds from getting the same value because ncWMS2 can't handle it and
    // throws a 500 HTTP exception
    if (ui.values[1] === ui.values[0]) {
      return false;
    }
    this._updateSliders(ui.values[1], ui.values[0]);
    return true;
  },

  /** ***************************** */
  /** **** REQUEST FUNCTIONS ****** */
  /** ***************************** */

  _getVerticalProfile(depthBounds) {
    const currentBbox = this._gisView.getCurrentViewBounds();
    const mapSize = this._gisView.getMapSize();

    const ncwmsServiceOptions = { ncwmsLayerType: LayerModelUtils.getNcwmsLayerType(this.model) };
    NCWMS(ncwmsServiceOptions)
      .then(service => {
        const selectedUnitName = NcwmsLayerUtils.getSelectedUnitFromLayer(this.model).name;
        const unitConfig = this._config.oceano.units[selectedUnitName];
        const ncwmsUnit = (unitConfig) ? unitConfig.ncWMS : null;
        return service.getProfileUrl(this._pointClicked.X, this._pointClicked.Y, this._layer, {
          mapSize,
          bbox: currentBbox,
          unit: ncwmsUnit,
          minDepth: depthBounds[0],
          maxDepth: depthBounds[1]
        });
      })
      .then(imgurl => {
        this._imgurl = imgurl;
        this._updateVerticalProfile(false);
      });
  },

  /** ***************************** */
  /** ***** UTILS FUNCTIONS ******* */
  /** ***************************** */

  _getLayerCurrentDate() {
    const oceaLayerUTC = +this._gisView.getGlobalOceanoCurrentUTC() || 0;
    const oceaLayerMoment = this._gisView.getOceanoLayerCurrentMoment(this.model);
    const verticalDate = OceanoNcwmsUtils.getModalFormattedDate(oceaLayerMoment, oceaLayerUTC);
    return verticalDate;
  },

  _updateSliders(valMin, valMax) {
    const bottomPos = Math.abs((+valMin / (this._maxDepth - this._minDepth)) * 100) + 3;
    const topPos = Math.abs((+valMax / (this._maxDepth - this._minDepth)) * 100) - 3;
    this._$doubleSliderTopHandle.css('top', `${topPos}%`).html(-valMax);
    this._$doubleSliderBotHandle.css('bottom', `${100 - bottomPos}%`).html(-valMin);
    this._animationView && this._animationView.setDepthBounds([-valMin, -valMax]);
  },

  _resultFilterDepth() {
    const depthBounds = this.$('.double-slider').slider('values').map(el => -el).reverse();
    this._getVerticalProfile(depthBounds);
  },

  _updateVerticalProfile(skipLoading) {
    this._$verticalResultError.hide();
    if (!skipLoading) {
      Loading.start(this._$modalBody);
    }
    this._$doubleSliderContainer.hide();
    this._$verticalStaticimageContainer.hide()
      .prop('src', this._imgurl)
      .one('load', () => {
        if (!skipLoading) {
          Loading.stop(this._$modalBody);
        }
        this._$verticalStaticimageContainer.fadeTo('slow', 1.0);
        this._$doubleSliderContainer.fadeIn();
      })
      .one('error', () => {
        if (!skipLoading) {
          Loading.stop(this._$modalBody);
        }
        this._$verticalResultError.show();
      });
  }
});
