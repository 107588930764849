import Group from 'ol/layer/Group';
import TileWMSSource from 'ol/source/TileWMS';
import TileLayer from 'ol/layer/Tile';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import WMTSSource from 'ol/source/WMTS';
import GeoJSON from 'ol/format/GeoJSON';

import OceanogrammeUtils from './oceano/oceanogramme-utils';
import LayerModelUtils from './layer-model-utils';
import {
  LAYERTYPE_REFMAR,
  LAYERTYPE_FORECAST,
  LAYERTYPE_TECHNIC,
  LAYERTYPE_INTERNAL_LAYER,
  LAYERTYPE_NCWMS,
  LAYERTYPE_COPERNICUS,
  LAYERTYPE_INTERNAL_NCWMS,
  LAYERTYPE_ARCHIVE_NCWMS
} from './constants';

const _ = require('underscore');
const $ = require('jquery');
const platform = require('platform');
const OceanoLayerUtils = require('./oceano/oceano-layer.js');
const NCWMSService = require('../service/ncwms');
const JqHelper = require('./jquery-helpers.js');
const SensorBottomView = require('../view/ddm/sensor-bottom.view.js');
const LayerFactory = require('./layer-factory.js');

const MAX_IE_LOCATION_HASH_SIZE = 2020;

const startsWith = String.prototype.startsWith || function (searchString, position) {
  position = position || 0;
  return this.indexOf(searchString, position) === position;
};

export default class ContextUrlObserver {
  constructor(options = {}) {
    this._gisView = options.GISVIEW || window.GISVIEW;
    this._gfiModeManager = options.POI_MODE_MANAGER || window.POI_MODE_MANAGER;
    this._layers = options.LAYERS || window.LAYERS;
    this._router = options.ROUTER || window.ROUTER;
    this._eventBus = options.EVENTBUS || window.EVENTBUS;
    this._config = options.CONFIG || window.CONFIG;
    this._listArchiveToRestore = {};
    this._wfsService = null;
  }

  startListening() {
    const listener = this._updateHashSignature.bind(this);

    this._layers.on('change:includedInMap', listener);
    this._layers.on('change:opacity', listener);
    this._layers.on('sort', listener);
    this._gisView.listenToMoveEnd(listener);

    const timedListener = () => {
      listener();
      setTimeout(timedListener, 2000);
    };

    if (this.restoreFromHash()) {
      setTimeout(timedListener, 2000);
    } else {
      timedListener();
    }

    if (Object.keys(this._listArchiveToRestore).length) {
      if (this._ncwmsLayersAvailable) {
        this._loadArchiveLayers();
      } else {
        this._layers.listenTo(this._eventBus, 'build:oceano', this._loadArchiveLayers.bind(this));
      }
    }
  }

  _updateHashSignature() {
    const displayedLayers = this._layers.where({
      includedInMap: true
    });

    let layersIdentifiers = displayedLayers.map(layer => {
      if (layer.get('external')) {
        return this._exportExternalLayer(layer);
      }

      const layerType = layer.get('layerType');
      let type;
      switch (layerType) {
        case LAYERTYPE_REFMAR:
        case LAYERTYPE_FORECAST:
        case LAYERTYPE_TECHNIC:
        case LAYERTYPE_COPERNICUS:
          type = layerType;
          break;

        case LAYERTYPE_NCWMS:
          type = LAYERTYPE_INTERNAL_NCWMS;
          break;

        default:
          type = LAYERTYPE_INTERNAL_LAYER;
      }

      const contextLayer = {
        type,
        identifier: layer.get('identifier'),
        opacity: layer.get('opacity'),
        visibility: layer.get('visibility')
      };

      // We keep a specific path for ncwms because the SHOM has requested
      // to add specific parameter export on it
      if (type === LAYERTYPE_INTERNAL_NCWMS) {
        contextLayer.showPaletteOnMap = layer.get('showPaletteOnMap') || false;
        contextLayer.selectedPalette = layer.get('selectedPalette');
      }

      return contextLayer;
    });

    // Excludes the layers that cannot be exported
    layersIdentifiers = _.without(layersIdentifiers, null);

    const center = this._gisView.getMapCenter();
    const zoom = this._gisView.getZoom();
    const rotation = this._gisView.getRotation();

    const output = {
      c: center,
      z: zoom,
      r: rotation,
      l: layersIdentifiers
    };

    const hash = `#001=${btoa(JSON.stringify(output))}`;
    // fix ie access denied for too long location.hash
    // if there is to much characters in hash, new layers are not added
    if (platform.name === 'IE' && hash.length >= MAX_IE_LOCATION_HASH_SIZE) {
      console.warn('location hash too long');
      return;
    }
    window.location.hash = `#001=${btoa(JSON.stringify(output))}`;
  }

  _exportExternalLayer(layer) {
    const olLayer = layer.get('olLayer');

    if (olLayer instanceof Group) {
      if (layer.get('isArchive')) {
        return {
          type: 'ARCHIVE_NCWMS',
          identifier: layer.get('identifier'),
          opacity: layer.get('opacity'),
          external: true,
          visibility: layer.get('visibility')
        };
      }
      // Ignore NCWMS layers
      return null;
    }

    const src = layer.get('olLayer').getSource();

    if (src instanceof VectorSource) {
      return {
        type: 'EXTERNAL_WFS',
        url: layer.get('olLayer').get('url'),
        title: layer.get('title'),
        identifier: layer.get('identifier'),
        opacity: layer.get('opacity'),
        abstract: layer.get('abstract'),
        external: true,
        visibility: layer.get('visibility')
      };
    }
    // We don't support EXTERNAL_WMTS in context as the GetCapabilities request to the server is mandatory to get
    // the TileMatrixSet.

    if (src instanceof WMTSSource) {
      return null;
    }

    // case WMS
    return {
      type: 'EXTERNAL_WMS',
      url: src.getUrls()[0],
      params: src.getParams(),
      identifier: layer.get('identifier'),
      title: layer.get('title'),
      maxExtent: layer.get('olLayer').get('maxExtent'),
      opacity: layer.get('opacity'),
      external: true,
      visibility: layer.get('visibility'),
      metadataURL: layer.get('metadataURL'),
      legendUrl: layer.get('legendUrl'),
      contextName: layer.get('contextName'),
      service: layer.get('service'),
      layerType: layer.get('layerType'),
      queryable: layer.get('queryable'),
      infoFormat: layer.get('infoFormat')
    };
  }

  restoreFromHash() {
    const h = window.location.hash;
    if (!h || h.length === 0) {
      return;
    }

    // prevent restore from hash if url contains context loading which is not refmar
    if(window.location.href.indexOf('contexte') !== -1 && window.location.href.indexOf('refmar') === -1) {
      return;
    }

    if (!startsWith.apply(h, ['#001='])) {
      // Rétrcompatibilité, les hash sans versions sont considérés comme les anciennes urls
      this._router.navigate(h.substr(1), true);
      return;
    }

    const contextStr = atob(h.substr(5));
    const context = JSON.parse(contextStr);
    this._gisView.removeAllLayers();
    context.l.reverse();

    this._gisView._updateMapView({
      center: context.c,
      zoom: context.z,
      rotation: context.r
    });

    const deferredRestoreLayers = [];

    context.l.forEach(l => {
      deferredRestoreLayers.push(this._restoreLayer(l));
    });

    JqHelper.Deferred.all(deferredRestoreLayers).done(_.bind(function (promisesResponses) {
      $.each(promisesResponses, _.bind(function (i, layer) {
        if (layer !== null) {
          this._gisView.moveLayerTotheTop(layer);
        }
      }, this));
    }, this));
  }

  _restoreLayer(l) {
    const defer = $.Deferred();
    let layer;
    let externalWfsLayer;
    let externalWmsLayer;
    let resLayer;

    switch (l.type) {
      case LAYERTYPE_INTERNAL_NCWMS:
        this._restoreNcwmsLayer(l, defer);
        break;

      case LAYERTYPE_ARCHIVE_NCWMS:
        this._addArchiveNcwmsToRestoreList(l, defer);
        break;

      case 'WMTS':
      case LAYERTYPE_INTERNAL_LAYER:
      case LAYERTYPE_TECHNIC:
        resLayer = this._gisView.loadLayer(l.identifier, true);
        if (resLayer) {
          resLayer.set('opacity', l.opacity);
          resLayer.set('visibility', l.visibility);
        }
        defer.resolve(resLayer);
        break;

      case LAYERTYPE_REFMAR:
        this._router.addRefmarLayer();
        resLayer = this._gisView.loadLayer(l.identifier, true);
        const sensorBottomView = new SensorBottomView({
          model: resLayer,
          network: resLayer.get('identifier').replace(/^REFMAR\//, '')
        });
        sensorBottomView.loadFeatures()
          .then(() => {
            sensorBottomView.addLayerFeatures();
            resLayer.set('includedInMap', true);
            defer.resolve(resLayer);
          });
        break;

      case LAYERTYPE_FORECAST:
        this._router.addForecastLayer();
        this._gisView.initOceanogrammeGis(_.bind(this.isInLand, this));
        this._gisView.createHoverInteraction();
        this._gfiModeManager && this._gfiModeManager.stop();

        resLayer = this._gisView.loadLayer(l.identifier, true);
        resLayer.set('includedInMap', true);

        this._gisView.setForecastLayerChildren(resLayer);

        defer.resolve(resLayer);
        break;

      case LAYERTYPE_COPERNICUS:
        this._gisView.initCopernicusGis(l.identifier);
        break;

      case 'EXTERNAL_WMS':
        layer = new TileLayer({
          identifier: l.identifier,
          title: l.title,
          maxExtent: l.maxExtent,
          source: new TileWMSSource({
            url: l.url,
            crossOrigin: 'anonymous',
            params: l.params
          })
        });
        externalWmsLayer = LayerFactory.build(layer, {});
        this._layers.push(externalWmsLayer);
        externalWmsLayer.set('includedInMap', true);
        externalWmsLayer.set('opacity', l.opacity);
        externalWmsLayer.set('visibility', l.visibility);
        externalWmsLayer.set('external', l.external);
        externalWmsLayer.set('metadataURL', l.metadataURL);
        externalWmsLayer.set('legendUrl', l.legendUrl);
        externalWmsLayer.set('contextName', l.contextName);
        externalWmsLayer.set('queryable', l.queryable);
        externalWmsLayer.set('url', l.url);
        externalWmsLayer.set('layerType', l.layerType);
        externalWmsLayer.set('service', l.service);
        externalWmsLayer.set('infoFormat', l.infoFormat);
        defer.resolve(externalWmsLayer);
        break;

      case 'EXTERNAL_WFS':
        layer = new VectorLayer({
          title: l.title,
          identifier: l.identifier,
          abstract: l.abstract,
          source: new VectorSource({
            url: l.url,
            crossOrigin: 'anonymous',
            format: new GeoJSON()
          }),
          url: l.url
        });
        externalWfsLayer = LayerFactory.build(layer, {});
        this._layers.push(externalWfsLayer);
        externalWfsLayer.set('includedInMap', true);
        externalWfsLayer.set('opacity', l.opacity);
        externalWfsLayer.set('visibility', l.visibility);
        externalWfsLayer.set('external', l.external);
        defer.resolve(externalWfsLayer);
        break;

      default:
        // defer is not rejected because we want the other layers to be displayed
        console.error(`Unsupported layer type: ${l.type}`);
        defer.resolve(null);
        break;
    }
    return defer;
  }

  isInLand(lon, lat) {
    return new OceanogrammeUtils(this._config).isInLand(lon, lat);
  }

  /**
   * Add to restore list
   * This list will restore ncwmsArchive, from their context (wmc files)
   * @param layer
   * @param promise
   * @private
   */
  _addArchiveNcwmsToRestoreList(layer, promise) {
    const contextName = layer.identifier.split('/')[0];
    if (!contextName) {
      promise.resolve(null);
      return;
    }
    const contextLayerEntry = {
      promise,
      identifier: layer.identifier,
      opacity: layer.opacity,
      visibility: layer.visibility
    };
    // Add ncwms layers in list, they will requested after this loop (_loadArchiveLayers)
    if (this._listArchiveToRestore.hasOwnProperty(contextName)) {
      this._listArchiveToRestore[contextName].push(contextLayerEntry);
    } else {
      this._listArchiveToRestore[contextName] = [contextLayerEntry];
    }
  }

  /**
   * Restore nwms layer directly if nwmsLayers are available or add an event listner to wait availability of ncwmsLayers
   * @param {object} layer
   * @param {*} promise
   * @private
   */
  _restoreNcwmsLayer(layer, promise) {
    this._ncwmsLayersAvailable = !!window.ncwmsLayersAvailable;
    const resLayer = this._layers.findWhere({ identifier: layer.identifier });
    if (!resLayer) {
      if (!this._ncwmsLayersAvailable) {
        this._layers.listenTo(
          this._eventBus,
          'build:oceano',
          this._loadNcwmsLayer.bind(this, promise, resLayer, layer, false)
        );
      }
    } else {
      this._loadNcwmsLayer(promise, resLayer, layer, true);
    }
  }

  _loadNcwmsLayer(defer, resLayer, layer, idNcwmsLayersAvailable) {
    if (!idNcwmsLayersAvailable) {
      resLayer = this._layers.findWhere({ identifier: layer.identifier });
      if (!resLayer) {
        this._layers.add(this._layers.models);
        resLayer = this._layers.findWhere({ identifier: layer.identifier });
        this._eventBus.off('build:oceano');
      }
    }

    if (!resLayer) {
      console.error(`Layer not found: ${layer.identifier}`);
      defer.resolve(null);
    } else {
      resLayer.set('selectedPalette', layer.selectedPalette);
      resLayer.set('selectedElevation', resLayer.get('olLayer').getLayers().getArray()[0].getLayers().getArray()[0].get('dimensions').elevation[0]);
      resLayer.set('showPaletteOnMap', layer.showPaletteOnMap);
      resLayer.set('opacity', layer.opacity);
      resLayer.set('visibility', layer.visibility);
      // setZonesVisibility is used to determine which region of the map is to be displayed
      this._gisView.setZonesVisibility(resLayer);
      // When the layer is coming from the context we do not want to recompute default params
      // and palette (cf _addRemoveLayer in gis.view.js) but instead use those in context
      resLayer.set('addToMapFromCtx', true);
      if (layer.selectedPalette.isAuto) {
        this._setUpLayerWithAutoOnMap(resLayer, layer)
          .then(lay => {
            defer.resolve(lay);
          });
      } else {
        this._setUpLayerOnMap(resLayer, layer)
          .then(lay => {
            defer.resolve(lay);
          });
      }
    }
  }

  _setUpLayerWithAutoOnMap(resLayer, l) {
    const defer = $.Deferred();
    const layers = resLayer.get('olLayer').get('layers').getArray();
    const visibleLayers = _.filter(layers, lay => lay.get('visible'));
    const bbox = this._gisView.getMapExtent();
    const ncwmsServiceOptions = { ncwmsLayerType: LayerModelUtils.getNcwmsLayerType(resLayer) };
    NCWMSService(ncwmsServiceOptions)
      .then(_.bind(service => service.getMinMaxLayers(visibleLayers, bbox), this))
      .then(_.bind(function (minMax) {
        const restoredPaletteAutoFromCtx = OceanoLayerUtils.setUpAutoPaletteOnLayer(resLayer, l.selectedPalette.name, minMax);
        resLayer.set('restoredPaletteAutoFromCtx', restoredPaletteAutoFromCtx);
        resLayer = this._gisView.loadLayer(l.identifier, true);
        if (resLayer.get('showPaletteOnMap')) {
          this._gisView.setPaletteOnMap(restoredPaletteAutoFromCtx, resLayer);
        }
        defer.resolve(resLayer);
      }, this))
      .fail(err => {
        // defer is not rejected because we want the other layers to be displayed
        console.error('cannot load palette', err);
        defer.resolve(null);
      });
    return defer;
  }

  _setUpLayerOnMap(resLayer, l) {
    const defer = $.Deferred();
    OceanoLayerUtils.setNCWMSParamsInGroupLayer(resLayer, {
      pal: l.selectedPalette
    });
    resLayer = this._gisView.loadLayer(l.identifier, true);
    if (resLayer.get('showPaletteOnMap')) {
      const palette = OceanoLayerUtils.getPaletteFromName(resLayer, resLayer.get('selectedPalette').name);
      this._gisView.setPaletteOnMap(palette, resLayer);
    }
    defer.resolve(resLayer);
    return defer;
  }

  _loadArchiveLayers() {
    for (const contextName in this._listArchiveToRestore) {
      if (this._listArchiveToRestore.hasOwnProperty(contextName)) {
        const contextUrl = `${this._config.oceano.archive_context}/${contextName}`;
        $.get(contextUrl)
          .done(this._loadArchiveLayersFromContext.bind(this, contextName))
          .fail(this._errorLoadingArchiveContext.bind(this, contextUrl));
      }
    }
  }

  _loadArchiveLayersFromContext(contextName, context) {
    this._gisView.loadContext(context, false, false, null, {
      layersToKeep: this._listArchiveToRestore[contextName].map(entry => entry.identifier),
      includeLayersInMap: false,
      resultCallback: layersCollection => {
        this._listArchiveToRestore[contextName].forEach(layerInfo => {
          const layer = layersCollection.findWhere({
            identifier: layerInfo.identifier
          });
          layer.set('opacity', layerInfo.opacity);
          layer.set('visibility', layerInfo.visibility);
          layer.set('includedInMap', true);
          layerInfo.promise.resolve(layer || null);
        });
      }
    });
  }

  _errorLoadingArchiveContext(contextUrl) {
    this._listArchiveToRestore[contextUrl].forEach(layerInfoEntry => {
      layerInfoEntry.resolve(null);
    });
    console.error(`Error during context loading '${contextUrl}'`);
  }
}
