const ShomView = require('../../core/shom-view');

const template = require('../../../template/modal/user-synchro.hbs');

module.exports = ShomView.build({

  events: {
    'click .diffusion-account': '_onClickHasDiffusionAccount',
    'click .no-diffusion-account': '_onClickHasNoDiffusionAccount'
  },

  initialize(options) {
    const optionsToUse = options || {};
    this.config = optionsToUse.config || window.CONFIG;
    this._parent = optionsToUse.parent;
    this._modalView = optionsToUse.modalView;
    this.hasDiffusionAccount = optionsToUse.hasDiffusionAccount;
    this.hasNoDiffusionAccount = optionsToUse.hasNoDiffusionAccount;
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({
      title: this.title,
      text: this.textContent
    }));
    return this;
  },

  _onClickHasDiffusionAccount() {
    this.hasDiffusionAccount && this.hasDiffusionAccount();
    this._modalView.hide();
  },

  _onClickHasNoDiffusionAccount() {
    this.hasNoDiffusionAccount && this.hasNoDiffusionAccount();
    this._modalView.hide();
  }
});
