const ShomView = require('../../core/shom-view');

const template = require('../../../template/oceano/ocea-result-error-modal.hbs');

module.exports = ShomView.build({

  events: {
    'click .ocea-result-error-btn': '_onCancelClick'
  },

  initialize(options) {
    const optionsToUse = options || {};
    this._restart = optionsToUse.restart;
    this._title = optionsToUse.title;
    this._message = optionsToUse.message;
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template());
    this.$el.find('.modal-title').attr('data-i18n', this._title);
    this.$el.find('#ocea-result-error-content').attr('data-i18n', this._message);
    return this;
  },

  /** ***************************** */
  /** ****** EVENT FUNCTION ******* */
  /** ***************************** */

  _onCancelClick() {
    if (this._restart) {
      this._restart();
    }
  }

});
