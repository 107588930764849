const ShomView = require('../../core/shom-view');

module.exports = ShomView.build({

  id: 'ocea-legend',

  initialize() {},

  render() {
    return this;
  }
});
