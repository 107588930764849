const Backbone = require('backbone');

const RoseCourantItem = Backbone.Model.extend({
  defaults: {
    datetime: '',
    mag: '',
    dir: '',
    realValueMag: '',
    realValueDir: '',
    x: '',
    y: ''
  }
});

module.exports = RoseCourantItem;
