import { NCWMS_LAYERTYPE_OCEANO, NCWMS_LAYERTYPE_ARCHIVE, NCWMS_LAYERTYPE_ATLAS } from './constants';
import AtlasHelper from './atlas/atlas-utils';

const LayerModelUtils = function () {};

LayerModelUtils.prototype.getNcwmsLayerType = function (model) {
  if (model.get('isArchive')) {
    return NCWMS_LAYERTYPE_ARCHIVE;
  }

  if (model.get('isAtlas') || AtlasHelper.isAtlasLayer(model)) {
    return NCWMS_LAYERTYPE_ATLAS;
  }

  return NCWMS_LAYERTYPE_OCEANO;
};

module.exports = new LayerModelUtils();
