const $ = require('jquery');
const ShomView = require('../../core/shom-view.js');
const template = require('../../../template/drawing/print-map-modal.hbs');
const templatePrintPage = require('../../../template/drawing/print-page.hbs');
const coordinatesHelper = require('../../utils/gis/coordinates-helper');

module.exports = ShomView.build({

  events: {
    'click .print-map-button': '_onClickPrint',
    'click .cancel-button': '_onClickCancel'
  },
  initialize(options = {}) {
    this.config = options.config || window.CONFIG;
    this._parent = options.parent;
    this.title = options.title;
    this._gisView = options.gisView || window.GISVIEW;
    this._modalView = options.modalView;
    this.saveCallback = options.saveCallback;
    this._gisView = window.GISVIEW;
    this._router = options.router || window.ROUTER;
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({
      title: this.title,
      okButtonLabel: this.okButtonLabel
    }));
    return this;
  },

  _onClickPrint() {
    const parameters = {
      name: $('#map-name-print').val(),
      description: $('#map-description-print').val()
    };

    parameters.description = parameters.description.replaceAll('\n', '<br>').replaceAll('\\n', '<br>');
    const canvasImageData = this._gisView.getMapAsImage('image/jpeg', { backgroundColor: 'white', quality: 0.9 });
    const coordSystemName = coordinatesHelper.getCoordSystemName(this._gisView.coordSyst);
    const scale = this._gisView.getScale();
    const displayLegend = this._gisView.currentlyDrawing && $('#display-legend')[0] && $('#display-legend')[0].checked;
    const legendsCount = displayLegend ? this._gisView.getLegendsCount() : 0;
    const page = this._openNewHtmlPrintPage(parameters, canvasImageData, coordSystemName, scale, legendsCount);
    setTimeout(() => {
      page.print();
      page.close();
    }, 300);
    this._modalView.hide();
  },

  _openNewHtmlPrintPage(parameters, canvasImageData, coordSystemName, scale, legendsCount) {
    const url = 'print-page';
    let $legendTitleSource = '';
    const newPage = window.open(url, '_blank');
    const $legendPreviewSource = [];
    const $legendTextSource = [];
    const $canvasSource = [];
    let canvasUrl;
    let canvasImage = [];
    if (legendsCount > 0) {
      $legendTitleSource = $('.legend-panel > h2 > span').get(0);
      const $list = $('.legend-panel li');
      $list.each((i, element) => {
        $legendPreviewSource[i] = $(element).find('.legend-style-preview')[0];
        $legendTextSource[i] = $(element).find('.legend-label').val();
        $canvasSource[i] = $(element).find('.legend-preview')[0];
      });
    } else {
      $legendTitleSource = '';
      legendsCount = 0;
    }
    const contentPrintPage = this.$el.html(templatePrintPage({
      titlePage: $.i18n.t('ddm.footer.print.titlePage'),
      title: parameters.name,
      mapSource: canvasImageData,
      coordSystemNameVal: coordSystemName,
      scaleVal: scale,
      titleLegend: legendsCount > 0 ? $legendTitleSource.innerHTML : '',
      legendsNumber: legendsCount,
      legendPreviewContent: $legendPreviewSource,
      footerPrintDescription: $.i18n.t('ddm.footer.print.description'),
      footerPrintCoorSystemName: $.i18n.t('ddm.footer.print.coordSystemName'),
      footerPrintScale: $.i18n.t('ddm.footer.print.scale')
    }));
    const $container = contentPrintPage.find('.print-page');
    const $listDest = $container.find('.legend li');
    let $legendPreviewDest;
    switch (true) {
      case (legendsCount < 1):
        $container.addClass('no-item');
        break;
      case (legendsCount >= 1 && legendsCount <= 5):
        $container.addClass('with-item');
        break;
      case (legendsCount > 5):
        $container.addClass('with-item more-item');
        break;
      default:
        break;
    }

    if (legendsCount > 0) {
      $listDest.each((i, element) => {
        $legendPreviewDest = $(element).find('.legend-style-preview')[0];
        $legendPreviewDest.innerHTML = $legendPreviewSource[i].innerHTML;
        $(element).find('.left-marg')[0].innerHTML = $legendTextSource[i];
        if ($canvasSource[i].tagName === 'CANVAS') {
          canvasUrl = $canvasSource[i].toDataURL();
          canvasImage = newPage.document.createElement('img');
          canvasImage.setAttribute('src', canvasUrl);
          canvasImage.setAttribute('alt', $legendTextSource[i]);
          $legendPreviewDest.append(canvasImage);
          $(element).find('canvas').css('display', 'none');
        }
      });
    }

    contentPrintPage.find('.description')[0].innerHTML = parameters.description;
    newPage.document.write(contentPrintPage.html());
    return newPage;
  }
});
