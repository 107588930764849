const _ = require('underscore');
const ShomView = require('../../core/shom-view');
const Loading = require('../../utils/loading');
const PartnerImportView = require('./partner-import.view');
const CategoryUtil = require('../../utils/category');
const LayerView = require('./partner-layer.view');

module.exports = ShomView.build({

  className: 'panel-group',

  id: 'categories-accordion',

  initialize(options = {}) {
    this._options = options;
    this.collection = options.collection || window.LAYERS;
    this._eventsBus = options.eventsBus;
    this._externalContextsAvailable = !!(options.externalContextsAvailable || window.externalContextsAvailable);
    this._externalContexts = options.EXTERNALCONTEXTS || window.EXTERNALCONTEXTS;
    this._itemViews = [];

    if (!this._externalContextsAvailable) {
      this.listenTo(this._eventsBus, 'build:external', this._initLayersModels.bind(this));
    }
  },

  render() {
    Loading.start(this.$el);
    if (this._externalContextsAvailable) {
      this._initLayersModels();
    }
    return this;
  },

  _initLayersModels() {
    this._externalContexts = this._options.EXTERNALCONTEXTS || window.EXTERNALCONTEXTS;
    this._externalContextsAvailable = true;
    this._categories = this._extractCategoriesOutOfCollection();
    this._refreshItemViews();
    this._eventsBus.off('build:external');
  },

  _removeItemViews() {
    this._itemViews.forEach(itemView => this.$el.append(itemView.remove()));
    this._itemViews = [];
  },

  _createItemViews() {
    if (this._categories) {
      this._categories.forEach(category => {
        this._itemViews.push(new LayerView({
          model: this._externalContexts.findWhere({
            categoryId: category.id
          }),
          collection: this._externalContexts
        }));
      });
    }
    this._itemViews.push(new PartnerImportView());
  },

  _renderItemViews() {
    this._itemViews.forEach(itemView => this.$el.append(itemView.render().$el));
  },

  _refreshItemViews() {
    this._removeItemViews();
    this._createItemViews();
    if (this._itemViews.length > 0) {
      this._renderItemViews();
      Loading.stop(this.$el);
    } else {
      this.$el.html('<div data-i18n="catalog.nodata"></div>');
      Loading.stop(this.$el);
    }
  },

  _extractCategoriesOutOfCollection() {
    if (this._externalContexts) {
      const externalContexts = this._externalContexts.clone();
      return this._getCategoriesByModel(externalContexts.models, window.portalLang);
    }
  },

  _getCategoriesByModel(layers, lang) {
    let categories = _.uniq(_.map(layers, item => {
      const name = item.get('name');
      const id = CategoryUtil.computeKey(name).replace(/\(|\)/g, '_');
      const translations = item.get('translation');
      const translateName = (translations && translations[name]) ? translations[name][lang] : name;
      const priorities = item.get('priorities') || {};
      item.set('categoryId', id);
      return {
        name,
        id,
        translateName,
        priority: priorities.model || 9999
      };
    }), 'name');

    categories = _.sortBy(categories, o => o.priority);

    return categories;
  },

  onClose() {
    this._removeItemViews();
  }
});
