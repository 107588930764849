const _ = require('underscore');
const ShomView = require('../core/shom-view');

module.exports = ShomView.build({

  className: 'opacity-slider',

  initialize(options) {
    const optionsToUse = options || {};
    this._collection = optionsToUse.collection;
    this.listenTo(this.model, 'change:opacity', _.bind(this.render, this));
  },

  render() {
    this.$el.slider({
      min: 0,
      max: 1,
      step: 0.1,
      value: this.model.get('opacity'),
      change: _.bind(function (event, ui) {
        this.model.set('opacity', ui.value);
        if (this._collection) {
          _.each(this._collection, model => {
            model.set('opacity', ui.value);
          });
        }
        const forecastChildren = this.model.get('forecastChildren');
        if (forecastChildren) {
          forecastChildren.forEach(child => child.set('opacity', ui.value));
        }
      }, this)
    });
    return this;
  },

  getSliderValue() {
    return this.$el.slider('value');
  },

  setSliderValue(value) {
    return this.$el.slider('value', value);
  }
});
