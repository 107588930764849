var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "dim3";
},"3":function(container,depth0,helpers,partials,data) {
    return " pull-left ";
},"5":function(container,depth0,helpers,partials,data) {
    return "                            <div class=\"transect-slider-container\" style=\"display: none;\">\n                                <div class=\"double-slider\"></div>\n                                <div class=\"slider-level double-slider-upper-level\">3000</div>\n                                <div class=\"slider-level double-slider-lower-level\">4000</div>\n                                <div>\n                                    <button class=\"slider-refresh-transect-image\">Refresh</button>\n                                    <span class=\"help-depth\"></span>\n                                </div>\n                            </div>\n                            <div class=\"clearfix\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n        <div class=\"modal-header\">\n            <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">&times;</button>\n            <h4 id=\"ocea-result-title\" class=\"modal-title\" data-i18n=\"\"></h4>\n            <span id=\"ocea-result-title-option\"></span>\n        </div>\n        <div class=\"modal-body\">\n            <div id=\"ocea-transect-result-content\">\n                <ul class=\"nav nav-pills\" role=\"tablist\">\n                    <li role=\"presentation\" class=\"active\">\n                        <a href=\"#transect-static\" role=\"tab\" data-toggle=\"tab\"\n                           aria-controls=\"transect-static\">Statique</a>\n                    </li>\n                    <li role=\"presentation\">\n                        <a href=\"#transect-animation\" role=\"tab\" data-toggle=\"tab\" aria-controls=\"transect-animation\">Animation</a>\n                    </li>\n                </ul>\n                <div class=\"tab-content\">\n                    <div role=\"tabpanel\" class=\"tab-pane active fade in transect-static active "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is3D") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":95},"end":{"line":20,"column":118}}})) != null ? stack1 : "")
    + "\"\n                         id=\"transect-static\">\n                        <div class=\"transect-date\"> <span data-i18n=\"oceano.nav.transect.date\"></span> <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"transectDate") || (depth0 != null ? lookupProperty(depth0,"transectDate") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"transectDate","hash":{},"data":data,"loc":{"start":{"line":22,"column":109},"end":{"line":22,"column":125}}}) : helper)))
    + "</span> </div>\n                        <div class=\"transect-result text-center "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is3D") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":64},"end":{"line":23,"column":94}}})) != null ? stack1 : "")
    + "\">\n                            <img id=\"transect-static-img\" style=\"display: none;\" alt=\"Radiale / section\" />\n                        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is3D") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":24},"end":{"line":38,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n\n                    <div role=\"tabpanel\" class=\"tab-pane transect-animation fade\" id=\"transect-animation\">\n                        <div class=\"transect-result\"></div>\n                    </div>\n                </div>\n\n            </div>\n        </div>\n        <div class=\"modal-footer\">\n            <button type=\"button\" class=\"btn btn-default\" data-dismiss=\"modal\" data-i18n=\"oceano.modal.close\"></button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});