const $ = require('jquery');
const Backbone = require('backbone');

const InvitationChecker = Backbone.Model.extend({
  initialize(options) {
    this._user = options.user || window.ROUTER.user;
    this._config = options.config || window.CONFIG;
  },

  checkInvitation(hash) {
    if (!hash) {
      throw 'hash parameter mustn\'t be null';
    }
    const deferred = $.Deferred();
    let url = this._config.cartodyn.url + this._config.cartodyn.discuss.endpoints.checkInvitationUrl;
    url = url.replace('{{hash}}', hash);
    const params = {
      url,
      method: 'POST',
      data: 'data'
    };
    this._user.doReq(params)
      .then(function (data) {
        if (data === 'OK') {
          deferred.resolve(arguments);
        } else {
          deferred.reject(arguments);
        }
      }).fail(function () {
        deferred.reject(arguments);
      });
    return deferred;
  },

  joinSession(hash, nickname) {
    if (!hash) {
      throw 'hash parameter mustn\'t be null';
    }
    if (!nickname || typeof (nickname) !== 'string') {
      throw 'nickname mustn\'t be null, and must be a valid string';
    }
    const deferred = $.Deferred();
    let key = 'anonymous';
    if (this._user.get('isLoggedIn')) {
      key = this._user.get('key');
    }
    let url = this._config.cartodyn.url + this._config.cartodyn.discuss.endpoints.joinSessionUrl;
    url = url.replace('{{key}}', key);
    url = url.replace('{{hash}}', hash);
    const params = {
      url,
      method: 'POST',
      data: nickname
    };
    this._user.doReq(params)
      .then(data => {
        deferred.resolve(data);
      }).fail(function () {
        deferred.reject(arguments);
      });
    return deferred;
  }
});

module.exports = InvitationChecker;
