const LegendPreview = require('./legend-preview.js');
const styleHelper = require('../../../utils/gis/drawing/style-helper.js');

const circlePreviewRadius = 10;

module.exports = LegendPreview.build({
  render() {
    this._style = this._feature.get('scStyle') || styleHelper.getStyle(this._feature);
    this.clear();

    this.renderRegularShape(
      this._style,
      Infinity,
      circlePreviewRadius
    );

    return this;
  }
});
