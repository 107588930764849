const $ = require('jquery');
const ShomView = require('../core/shom-view');
const LeftButtonBarView = require('./left-button-bar.view');
const DisplayMenuView = require('./display-menu.view');
const ContactBaseView = require('./contact-menu/contact-base.view');
const CatalogView = require('./catalog/catalog.view');

const template = require('../../template/left-menu.hbs');

module.exports = ShomView.build({

  className: 'shom-left-menu-container',

  initialize(options = {}) {
    this.leftButtonBarView = new LeftButtonBarView(options);
    this._router = options.router || window.ROUTER;
    this._eventBus = options.eventBus || window.EVENTBUS;
    this._gfiModeManager = options.gfiModeManager || window.POI_MODE_MANAGER;
    this._$paletteOnMap = $('#palette');
    this._$timelineOnMap = $('#timeline-root');
    this.currentMenuView = options.currentMenuView;
  },

  render() {
    this.$el.html(template());

    this._$leftButtonBarViewRoot = this.$('#left-button-bar-root');
    this._$leftMenuContentRoot = this.$('#left-menu-content-root');

    this._$leftButtonBarViewRoot.html(this.leftButtonBarView.render().$el);
    this._gfiModeManager.startGfiRequestableLayer(true);

    return this;
  },

  openMenu(section) {
    this._$leftMenuContentRoot.removeClass('left-menu-content-root-display');
    this.currentMenuView && this.currentMenuView.remove();

    if (typeof section === 'function') {
      this.currentMenuView = this._fromFactory(section);
    } else {
      this.currentMenuView = this._fromMenuName(section);
    }

    let open = false;
    if (this.currentMenuView !== null) {
      open = true;
    }
    this._eventBus.trigger('change:leftView', { open });
    this._menuOpenChanged(open);
    this._$leftMenuContentRoot.html(this.currentMenuView ? this.currentMenuView.render().$el : '');
    this._$leftMenuContentRoot[0].focus();
    this.currentMenuView && this.currentMenuView.$el.find('button').focus();
    return this.currentMenuView;
  },

  _fromFactory(factory) {
    return factory.call();
  },

  _fromMenuName(section) {
    /* jshint ignore:start */
    switch (section) {
      case 'affichage':
        this._$leftMenuContentRoot.addClass('left-menu-content-root-display');
        return new DisplayMenuView();
      case 'donnees':
        return new CatalogView();
      case 'contact':
        return new ContactBaseView();
      default:
        return null;
    }
    /* jshint ignore:end */
  },

  onClose() {
    this.currentMenuView && this.currentMenuView.remove();
    this._$paletteOnMap.removeClass('no-left-menu');
    this._$timelineOnMap.removeClass('no-left-menu');
  },

  _menuOpenChanged(open) {
    if (!open && !this._$paletteOnMap.hasClass('no-left-menu')) {
      this._$paletteOnMap.addClass('no-left-menu');
      this._$timelineOnMap.addClass('no-left-menu');
    } else if (open && this._$paletteOnMap.hasClass('no-left-menu')) {
      this._$paletteOnMap.removeClass('no-left-menu');
      this._$timelineOnMap.removeClass('no-left-menu');
    }
  }
});
