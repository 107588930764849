import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import {
  Circle, Fill, Stroke, Style
} from 'ol/style.js';
import Feature from 'ol/Feature';
import Draw from 'ol/interaction/Draw';
import * as Extent from 'ol/extent';
import { unByKey } from 'ol/Observable.js';
import Polygon from 'ol/geom/Polygon';

const GisView = require('../gis.view.js');

GisView.prototype.selectRectangle = function (onend) {
  const onendCallback = onend;
  const source = new VectorSource();
  const style = new Style({
    stroke: new Stroke({
      color: '#000000',
      width: 3
    }),
    image: new Circle({
      radius: 4,
      fill: new Fill({
        color: '#000000'
      })
    })
  });

  const vectorLayer = new VectorLayer({
    source,
    style,
    title: 'Couche de dessin de rectangle'
  });
  const map = this._map;
  const draw = new Draw({
    source,
    style,
    type: ('LineString'),
    geometryFunction(coordinates, geometry) {
      const [start, end] = coordinates;
      const newCoordinates = [
        [start, [start[0], end[1]], end, [end[0], start[1]], start]
      ];
      if (!geometry) {
        return new Polygon(newCoordinates);
      }
      geometry.setCoordinates(newCoordinates);
      return geometry;
    },
    maxPoints: 2
  });

  map.addInteraction(draw);
  map.addLayer(vectorLayer);

  const listeningKey = draw.on('drawend', event => {
    source.clear();
    source.addFeature(new Feature({
      geometry: event.feature.getGeometry()
    }));
    const extent = event.feature.getGeometry().getExtent();
    onendCallback.call(null, extent, Extent.getWidth(extent), Extent.getHeight(extent), draw);
  });

  return function () {
    unByKey(listeningKey);
    map.removeLayer(vectorLayer);
    map.removeInteraction(draw);
  };
};
