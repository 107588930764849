var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                    <option value=\""
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"rose-courant-container\">\n    <div class=\"rose-courant-container-child\">\n        <div id=\"rose-courant-graph\" class=\"rose-courant-graph\"></div>\n    </div>\n    <div class=\"rose-courant-container-child\">\n        <div class=\"rose-courant-actions-area\">\n            <label for=\"rose-courant-select-coeff\">Coefficient:</label>\n            <select id=\"rose-courant-select-coeff\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"availableCoeffs") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":11,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n        </div>\n        <div class=\"rose-courant-table-area\">\n            <table class=\"table table-striped table-sm table-responsive\">\n                <thead>\n                <tr>\n                    <th>"
    + alias4(((helper = (helper = lookupProperty(helpers,"thDatetime") || (depth0 != null ? lookupProperty(depth0,"thDatetime") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thDatetime","hash":{},"data":data,"loc":{"start":{"line":18,"column":24},"end":{"line":18,"column":38}}}) : helper)))
    + "</th>\n                    <th>"
    + alias4(((helper = (helper = lookupProperty(helpers,"thDir") || (depth0 != null ? lookupProperty(depth0,"thDir") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thDir","hash":{},"data":data,"loc":{"start":{"line":19,"column":24},"end":{"line":19,"column":33}}}) : helper)))
    + "</th>\n                    <th>"
    + alias4(((helper = (helper = lookupProperty(helpers,"thMag") || (depth0 != null ? lookupProperty(depth0,"thMag") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thMag","hash":{},"data":data,"loc":{"start":{"line":20,"column":24},"end":{"line":20,"column":33}}}) : helper)))
    + "</th>\n                </tr>\n                </thead>\n                <tbody id=\"table-body-rose-courant\"></tbody>\n            </table>\n        </div>\n    </div>\n</div>";
},"useData":true});