const Backbone = require('backbone');
const TopShareMenuView = require('../view/top-share-menu.view');
const LeftMenuView = require('../view/left-menu.view');
const ShareService = require('../service/share.js');

module.exports = Backbone.Router.extend({

  initialize(options = {}) {
    this.route('datacontext/:lang/:contextId', 'openLoadContextView');
    this._dynamicRenderer = options.dynamicRenderer;
    this._staticRenderer = options.staticRenderer;
  },

  openLoadContextView(lang, contextId) {
    if (!this._leftMenuView) {
      this._leftMenuView = new LeftMenuView();
      this._dynamicRenderer.renderLeftView(this._leftMenuView);
      this._topShareMenuView = new TopShareMenuView();
      this._dynamicRenderer.renderTopView(this._topShareMenuView, '#panel-my-share');
    }
    this.lang = lang;
    this.contextId = contextId;
    this.topRibbonView = this._staticRenderer.topRibbonView;
    new ShareService(this).loadSharedContext();
  }
});
