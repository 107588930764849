const $ = require('jquery');

const ViewRenderer = module.exports = function () {
  this.$leftViewRoot = $('#left-menu-root');
  this.$bottomViewRoot = $('#bottom-component-root');
  this.$mainViewRoot = $('#main-container');
  this.$legendsViewRoot = $('#ocea-legend');
};

ViewRenderer.prototype.clearLeftView = function () {
  if (this.currentLeftView) {
    this.currentLeftView.remove();
    this.currentLeftView = null;
  }
};

ViewRenderer.prototype.clearBottomView = function () {
  if (this.currentBottomView) {
    this.currentBottomView.remove();
    this.currentBottomView = null;
    this.$bottomViewRoot.empty();
  }
};

ViewRenderer.prototype.clearMainView = function () {
  if (this.currentMainView) {
    this.currentMainView.remove();
    this.currentMainView = null;
    this.$mainViewRoot.empty();
    this.$mainViewRoot.hide();
  }
};

ViewRenderer.prototype.clearAllViews = function () {
  this.clearBottomView();
  this.clearLeftView();
  this.clearMainView();
};

ViewRenderer.prototype.renderBottomView = function (view, clearAll) {
  clearAll = !!(clearAll === undefined || clearAll === null || clearAll === true);
  if (clearAll) {
    this.clearAllViews();
  }
  this.currentBottomView = view;
  this.$bottomViewRoot.html(this.currentBottomView.render().$el);
  return view;
};

ViewRenderer.prototype.renderLeftView = function (view, clearAll) {
  clearAll = !!(clearAll === undefined || clearAll === null || clearAll === true);
  if (clearAll) {
    this.clearAllViews();
  }
  this.currentLeftView = view;
  this.$leftViewRoot.html(this.currentLeftView.render().$el);
  return view;
};

ViewRenderer.prototype.renderTopView = function (view, rootId) {
  this.currentTopView = view;
  this.$myView = $(rootId);
  this.$myView.html(this.currentTopView.render().$el);
  return view;
};

ViewRenderer.prototype.renderLeftViewWithButtonBar = function (leftMenuView, view, clearAll) {
  clearAll = !!(clearAll === undefined || clearAll === null || clearAll === true);
  if (clearAll) {
    this.clearAllViews();
  }
  this.currentLeftView = leftMenuView;
  this.$leftViewRoot.html(leftMenuView.render().$el);
  this.$leftViewRoot.find('.left-menu-content-root').html(view.render().$el);
  return view;
};

ViewRenderer.prototype.renderMainView = function (view, clearAll) {
  clearAll = !!(clearAll === undefined || clearAll === null || clearAll === true);
  if (clearAll) {
    this.clearAllViews();
  }
  this.currentMainView = view;
  this.$mainViewRoot.html(this.currentMainView.render().$el);
  this.$mainViewRoot.show();
  return view;
};

ViewRenderer.prototype.renderLegendView = function (view) {
  this.currentLegendView = view;
  const legendItems = this.$legendsViewRoot.find('.main-legend-panel');
  let shouldAppendView = true;
  let topLeftOffset = 0;
  if (legendItems && legendItems.length) {
    const offset = 30;
    const legendIdList = legendItems.get().map(legend => $(legend).attr('id'));
    const idView = this.currentLegendView.getLegendItemViewElementId();
    shouldAppendView = !legendIdList.includes(idView);
    topLeftOffset = offset * legendItems.length;
  }
  if (shouldAppendView) {
    const $currLegendViewEl = this.currentLegendView.render().$el;
    $currLegendViewEl.css('top', topLeftOffset).css('left', topLeftOffset);
    this.$legendsViewRoot.append($currLegendViewEl);
  }
  this.$legendsViewRoot.show();
  return view;
};
