const Backbone = require('backbone');
const $ = require('jquery');
const ForgotPassView = require('../view/user-menu/forgot-pass.view.js');
const ChangePassView = require('../view/user-menu/change-pass.view.js');
const UserAccountView = require('../view/user-menu.view');

const CartoDynMaps = require('../collection/cartodyn-maps');
const Session = require('../model/cartodyn-session.js');
const AccountDrawList = require('../view/drawing/maps-list.view');
const ConfirmView = require('../view/modal/confirm.view');

module.exports = Backbone.Router.extend({
  initialize(options = {}) {
    this._config = options.config || window.CONFIG;
    this.route('abonne/renewPassword/:token/:key', '_resetPass');
    this._renderer = options.renderer;
    this._menuRouter = options.menuRouter;
    this._modalView = options.modalView || window.MODALVIEW;
    this.eventsBus = options.eventsBus || window.EVENTBUS;

    this._router = options.router || window.ROUTER;
    this._user = options.user || this._router.user;

    this._cartoDynMaps = options.cartoDynMaps || new CartoDynMaps();
    this._session = options.session || new Session({ maps: this._cartoDynMaps, drawingRouter: this });

    this._drawingOpenMapView = new AccountDrawList({ collection: this._cartoDynMaps, session: this._session });
    this.listenTo(this._session, 'change:permissions', this._onUserPermissionsChanged.bind(this));
    this.listenTo(this.eventsBus, 'user:openmenu', this._userAccount.bind(this));
    this.listenTo(this.eventsBus, 'user:forgotpass', this._forgotPass.bind(this));
    this.listenTo(this.eventsBus, 'user:changepass', this._changePass.bind(this));
  },

  _forgotPass() {
    const content = new ConfirmView({
      title: $.i18n.t('user.forgotPass.question'),
      textContent: $.i18n.t('account.synchro.resetPasswordContent'),
      modalView: this._modalView,
      parent: this,
      confirmLabel: $.i18n.t('download.popup.available.buttons.labels.yes'),
      cancelLabel: $.i18n.t('download.popup.available.buttons.labels.no'),
      // If account is up to date, password reset is managed by diffusion
      confirmCallback: () => {
        window.open(this._config.diffusion.resetPassword, '_blank');
      },
      // If not, the old mechanism is used
      cancelCallback: () => {
        this._menuRouter.openMenu(() => new ForgotPassView());
      }
    });
    this._modalView.show(content, { keyboard: false, backdrop: 'static' });
  },

  _changePass() {
    window.open(this._config.diffusion.changePassword, '_blank');
  },

  _resetPass(token, key) {
    this._menuRouter.openMenu(() => new ChangePassView({ token, key }));
  },

  _userAccount() {
    this._menuRouter.openMenu(() => new UserAccountView({ drawList: this._drawingOpenMapView.render().$el }));
  },

  _onUserPermissionsChanged() {
    this._cartoDynMaps.reset();
    if (!this._user.get('isLoggedIn') || !this._session.get('permissions') || !this._session.get('permissions').hasCartoDyn) {
      return;
    }
    this._cartoDynMaps.authHeader = this._user.authHeader();
    this._cartoDynMaps.url = this._config.cartodyn.url + this._config.cartodyn.endpoints.getmaps;
    this._cartoDynMaps.fetch();
  },

  setupMap(map) {
    map.authHeader = this._user.authHeader();
    map.url = this._config.cartodyn.url;
  }
});
