var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a role=\"button\" href=\"#\" class=\"aom-btn-start-animation\"\n   data-i18n=\"[title]oceano.nav.animation.action;[aria-label]oceano.nav.animation.action\">\n    <span class=\"fa-stack\" style=\"vertical-align: top;\">\n		<i class=\"fas fa-circle fa-stack-3x\"></i>\n		<i class=\"fas fa-play fa-stack-2x\"></i>\n	</span>\n</a>\n<div id=\"timeline-selectors\">\n    <div class=\"timeline-select-div\" id=\"timeline-select-day-time-div\">\n        <div class=\"select-wrapper\">\n            <label for=\"timeline-datetimepicker\" class=\"visually-hidden\" data-i18n=\"oceano.nav.animation.control.currenttime\"></label>\n            <input id=\"timeline-datetimepicker\" class=\"timeline-select\" value=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"displayedDateTime") : depth0), depth0))
    + "\"/>\n        </div>\n    </div>\n\n    <div class=\"timeline-select-div\" id=\"timeline-select-utc-div\">\n        <div class=\"select-wrapper\">\n            <label for=\"timeline-select-utc\" class=\"visually-hidden\" data-i18n=\"spm.labels.time-system\"></label>\n            <select class=\"timeline-select\" id=\"timeline-select-utc\"></select>\n            <i class=\"fas fa-chevron-down\"></i>\n        </div>\n    </div>\n</div>\n\n<div class=\"timeline-slider-div\">\n    <div class=\"btn-prev-time timeline-btn-prev\">\n        <i class=\"material-icons\">keyboard_arrow_left</i>\n    </div>\n    <div id=\"timeline-on-map-slider\">\n        <div id=\"timeline-tooltip-div\"></div>\n    </div>\n    <div class=\"btn-next-time timeline-btn-next\">\n        <i class=\"material-icons\">keyboard_arrow_right</i>\n    </div>\n</div>";
},"useData":true});