import LayerModelUtils from '../../utils/layer-model-utils';

import AtlasHelper from '../../utils/atlas/atlas-utils';

const $ = require('jquery');
const moment = require('moment');
const ShomView = require('../../core/shom-view');

const NCWMS = require('../../service/ncwms');

const Loading = require('../../utils/loading.js');
const OceanoLayerUtils = require('../../utils/oceano/oceano-layer');

const template = require('../../../template/oceano/ocea-transect-atlas-animation.hbs');

module.exports = ShomView.build({

  events: {
    'submit form': '_launchAnimation'
  },

  /** ***************************** */
  /** ****** INIT FUNCTIONS ******* */
  /** ***************************** */

  initialize(options = {}) {
    this._gisview = options.gisview || window.GISVIEW;
    this._config = options.config || window.CONFIG;
    this._layer = options.layer;
    this._linestring = options.linestring;
    this._depthBounds = options.depthBounds;

    this._prepareAvailableMoments();
  },

  /** ***************************** */
  /** ***** RENDER FUNCTIONS ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({
      coeff: this.model.get('currentCoeff'),
      availableMoments: this.availableMoments
    }));

    this._$firstMomentSelect = this.$el.find('.first-moment-select');
    this._$lastMomentSelect = this.$el.find('.last-moment-select');
    this._$transectAnimationSlider = this.$el.find('.transect-slider-animation');
    this._$transectAnimationSliderValue = this.$el.find('.transect-slider-animation-value');
    this._$transectAnimationButton = this.$el.find('.transect-animation-button');
    this._$transectAnimationImage = this.$el.find('img#transect-animation');
    this._$transectAnimationErrors = this.$el.find('.errors');

    this._renderAnimationSlider();

    return this;
  },

  _prepareAvailableMoments() {
    this.availableMoments = [];
    this._timerange = this._config.atlas.ncwms_timerange.split('/');
    const timeMoment = moment.utc(this._timerange[0]);
    const lastDate = moment.utc(this._timerange[1]);
    let timeNumber = -6;
    while (timeMoment.isSameOrBefore(lastDate)) {
      this.availableMoments.push({
        value: timeMoment.toISOString(),
        label: `PM ${AtlasHelper.convertNumToTime(timeNumber)}`
      });
      timeMoment.add(15, 'minutes');
      timeNumber += 0.25;
    }
  },

  _renderAnimationSlider() {
    const defaultSliderValue = 2;
    const onCreateSlider = () => this._updateSliderValue(null, { value: defaultSliderValue });
    this._$transectAnimationSlider.slider({
      min: 1,
      value: defaultSliderValue,
      max: 10,
      create: onCreateSlider.bind(this),
      slide: this._updateSliderValue.bind(this)
    });
  },

  /** ****************************** */
  /** * GETTER / SETTER FUNCTIONS ** */
  /** ****************************** */

  setDepthBounds(depthBounds) {
    this._depthBounds = depthBounds;
  },

  /** ***************************** */
  /** ***** EVENT FUNCTIONS ******* */
  /** ***************************** */

  _updateSliderValue(event, ui) {
    this._$transectAnimationSliderValue.html(ui.value);
  },

  /** ***************************** */
  /** **** REQUEST FUNCTIONS ****** */
  /** ***************************** */

  _launchAnimation(event) {
    event.preventDefault();
    this._animationFirstmoment = moment.utc(this._$firstMomentSelect.val());
    this._animationLastmoment = moment.utc(this._$lastMomentSelect.val());
    if (!this._checkIsFormValid()) {
      this._$transectAnimationErrors.closest('.row').show();
      return false;
    }

    this._$transectAnimationErrors.closest('.row').hide();

    const isoDates = [this._animationFirstmoment.toISOString(), this._animationLastmoment.toISOString()];
    const framerate = this._$transectAnimationSlider.slider('value');

    const ncwmsServiceOptions = { ncwmsLayerType: LayerModelUtils.getNcwmsLayerType(this.model) };
    NCWMS(ncwmsServiceOptions)
      .then(service => {
        const selectedUnitName = OceanoLayerUtils.getSelectedUnitFromLayer(this.model).name;
        const unitConfig = this._config.oceano.units[selectedUnitName];

        return service.getTransectUrl(this._linestring, this._layer, {
          time: isoDates,
          framerate,
          unit: (unitConfig) ? unitConfig.ncWMS : null,
          sectionElevation: this._depthBounds,
          layerModel: this.model
        });
      })
      .then(this._updateAnimation.bind(this));
  },

  /** ***************************** */
  /** ***** UTILS FUNCTIONS ******* */
  /** ***************************** */

  _checkIsFormValid() {
    return this._animationFirstmoment.isBefore(this._animationLastmoment);
  },

  _updateAnimation(url) {
    Loading.start(this.$el);

    const $animationPanel = this._$transectAnimationImage;
    $animationPanel.fadeTo('slow', 0.0, () => {
      $animationPanel.prop('src', url);
      $animationPanel.one('load', () => {
        Loading.stop(this.$el);
        $animationPanel.fadeTo('slow', 1.0);
        this._$transectAnimationButton.html($.i18n.t('oceano.nav.transect.animation.reloadAnimation'));
      });
    });
  }
});
