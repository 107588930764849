import { fromCircle } from 'ol/geom/Polygon';
import { Circle } from 'ol/geom';
import ShomProxy from '../proxy';
import kmlStyles from './drawing/discuss-styles';
import KMLFormat from 'ol/format/KML';
import Layer from '../../model/layer';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { LAYERTYPE_KML_LAYER } from '../constants';
import { Fill, Style, Text } from 'ol/style';
import { adaptGeomFunction, getFeatureCenter } from '@shom2/carto/utils/feature';
import Stroke from "ol/style/Stroke";

const $ = require('jquery');
const _ = require('underscore');
const CoordsHelper = require('./coordinates-helper.js');
const styleHelper = require('./drawing/style-helper.js');
const colorsUtils = require('../colors.js');

const defaultFontStrokeColor = '#000000';
const defaultFontStrokeWidth = '3';
const defaultFontStrokeOpacity = 1.0;
const defaultFontSize = 12;
const polygonizeCirclesSideNumber = 64;

const FEATURE_PROJECTION = 'EPSG:3857';

const kmlStyleDefault = `
  <Style id="shomPlacemarkDefaultStyle">
    <IconStyle>
        <color>7766ff33</color>
        <IconStyleSimpleExtensionGroup radius="3" points="Infinity" strokeColor="#66ff33ff" strokeWidth="1"/>
    </IconStyle>
    <LineStyle>
        <color>ff66ff33</color>
        <width>2</width>
    </LineStyle>
    <PolyStyle xmlns="">
        <color>7766ff33</color>
    </PolyStyle>
    <LabelStyle>
        <color>ff66ff33</color>
        <LabelStyleSimpleExtensionGroup fontSize="12" fontFamily="serif" haloRadius="2"/>
    </LabelStyle>
  </Style>`;

const kmlFormat = new KMLFormat({
  showPointNames: false,
  writeStyles: true,
  extractStyles: true,
  extractAttributes: true
});

const STYLE_TEXT_LABEL = new Text({
  text: '',
  scale: 1.2,
  fill: new Fill({
    color: '#fff'
  }),
  stroke: new Stroke({
    color: 'rgba(0,0,0,0.99)',
    width: 3
  })
});

const kmlHelper = {
  /**
   * Prepare the KML before import process
   *
   * @param {$} $kml the kml jquery object
   */
  prepareKml($kml) {
    $kml.find('Placemark > id').each(function () {
      $(this).parent().attr('id', $(this).text());
    });

    // If there is external icon we proxify urls to avoid CORS errors and if there is icon with color, we remove it
    $kml.find('IconStyle').each(function () {
      // working with xml is not fun :(
      const iconStyleEl = $(this)[0];
      const iconEl = iconStyleEl.getElementsByTagName('Icon').item(0);
      if (iconEl && iconEl.getElementsByTagName('href')) {
        const hrefEl = iconEl.getElementsByTagName('href').item(0);
        window.CONFIG.cartodyn.iconRedirections.forEach(icon => {
          if (hrefEl.innerHTML.includes(icon.oldName)) {
            hrefEl.innerHTML = hrefEl.innerHTML.replace(icon.oldName, icon.newName);
          }
        });
        const colorEl = iconStyleEl.getElementsByTagName('color').item(0);
        hrefEl.innerHTML = ShomProxy.getProxifiedUrl(hrefEl.innerHTML);
        if (colorEl) {
          colorEl.innerHTML = '';
        }
      }
    });

    // We seek Placemark without style to add them a default one
    $kml.find('Placemark:not(:has(styleUrl,Style))').each(function () {
      $(this).append(kmlStyleDefault);
    });

    kmlHelper.removeUndefinedCoordinates($kml);
  },

  /**
   * Remove undefined values as third coordinates if there has
   *
   * @param {$} $kml the kml jquery object
   */
  removeUndefinedCoordinates($kml) {
    $kml.find("Placemark").each(function() {
      const formatCoordinates = [];
      $(this).find("coordinates").text().split(' ').forEach(coordinate => {
        const splitCoordinate = coordinate.split(',');
        if (splitCoordinate[2] && splitCoordinate[2] === 'undefined') {
          splitCoordinate[2] = '0';
        }
        formatCoordinates.push(splitCoordinate.join(','));
      });
      $(this).find("coordinates").text(formatCoordinates.join(' '));
    });
  },

  /**
   * Adapt the KML features for data shom specificities
   *
   * @param {array <ol.Feature>} features a KML feature list
   * @param {hash <string, function>} styles the defined style list for the features
   * @returns {array <ol.Feature>} the modified features list
   */
  adaptSomeKmlFeatures(features, styles) {
    if (features) {
      features = kmlHelper.convertMultiGeomToGeomList(features);
      kmlHelper.handleTextFeatures(features, styles);
      kmlHelper.handleCircleFeatures(features);
      kmlHelper.handleExtensions(features);
    }

    return features;
  },

  getTextFeatures(features) {
    let textFeatures = [];
    if (features) {
      textFeatures = _.filter(
        features,
        feature => feature.get('geometry').getType() === 'Point'
            && (
              // Cartodyn text format style
              (feature.get('styleUrl') && feature.get('label'))
              // New way text format style
              || (!feature.get('styleUrl') && feature.get('name'))
            )
      );
    }

    return textFeatures;
  },

  getCircleFeatures(features) {
    let circleFeatures = [];
    if (features) {
      circleFeatures = _.filter(
        features,
        feature => this.isCircleFeature(feature)
      );
    }
    return circleFeatures;
  },

  isCircleFeature(feature) {
    const isPolygon = feature.get('geometry').getType() === 'Polygon';
    const isPoint = feature.get('geometry').getType() === 'Point';
    const isCircle = feature.get('geometry').getType() === 'Circle';
    // Standard circle definition
    const isCircleDefinition = !!feature.get('isCircle') && !!feature.get('center-coord') && !!feature.get('radius');
    // ex Cartodyn circle definition
    const cartodynCenter = feature.get('center');
    const cartodynRadius = feature.get('radius');
    const isCartodynCircleDefinition = !!cartodynCenter && !!cartodynRadius
      && cartodynCenter !== 'undefined' && cartodynRadius !== 'undefined';
    return isCircle || ((isPolygon || isPoint) && (isCircleDefinition || isCartodynCircleDefinition));
  },

  /**
   * Check extensions from features style and handle information from them
   *
   * @param features the list of features
   */
  handleExtensions(features) {
    features.forEach(feature => {
      const style = styleHelper.getStyle(feature);
      styleHelper.setStrokeInfoFromExtensions(style);
      styleHelper.setRegularShapeInfoFromExtensions(style);
    });
  },

  handleTextFeatures(features, styles) {
    const textFeatures = kmlHelper.getTextFeatures(features);
    textFeatures.forEach(feature => {
      feature.set('text', feature.get('name'));

      if (!styles) {
        return;
      }

      const style = styleHelper.getStyle(feature);
      const textStyle = style.getText();
      const ext = textStyle ? textStyle.getExtensions() : null;

      let strokeColor = defaultFontStrokeColor;
      let strokeWidth = style.getStroke() ? style.getStroke().getWidth() : defaultFontStrokeWidth;
      let strokeOpacity = defaultFontStrokeOpacity;
      let fFamily;
      let fSize = defaultFontSize;
      let bold;
      let italic;

      if (ext) {
        fFamily = ext.fontFamily;
        strokeColor = ext.haloColor ? colorsUtils.toHexColor(+(ext.haloColor)) : defaultFontStrokeColor;
        strokeWidth = ext.haloRadius ? +ext.haloRadius : strokeWidth;
        fSize = ext.fontSize ? ext.fontSize : fSize;
        strokeOpacity = ext.haloOpacity ? parseFloat(ext.haloOpacity) : strokeOpacity;
        bold = (ext.bold === 'bold');
        italic = (ext.italic === 'italic');
      }
      const fillColor = textStyle.getFill().getColor();
      const strokeColorRgba = colorsUtils.getRgbaColorFromHexColor(strokeColor, strokeOpacity);

      feature.setStyle(styles.text(
        fillColor,
        strokeColorRgba,
        strokeWidth,
        fFamily,
        fSize,
        bold,
        italic
      ));
    });
  },

  handleCircleFeatures(features) {
    const circleFeatures = kmlHelper.getCircleFeatures(features);

    circleFeatures.forEach(feature => {
      // Get center from properties
      const centerAsString = (feature.get('center-coord') ? feature.get('center-coord') : feature.get('center'));
      const center = centerAsString.split(' ').map(e => +e);
      const centerMercat = CoordsHelper.convertLonLatToMercat(center);

      // Get radius from properties
      const radius = +feature.get('radius');

      feature.setGeometry(new Circle(centerMercat, radius));
    });
  },

  /**
   * get multi geom of the given types
   * @param {array <ol.Feature>} features
   * @param {array <string>} multiGeomTypes is an array with all the multigeom type we are searching for
   * @returns {array <ol.Feature>}
   */
  getMultiGeomFeatures(features, multiGeomTypes) {
    return features.filter(feature => feature.getGeometry() && multiGeomTypes.indexOf(feature.getGeometry().getType()) !== -1);
  },

  /**
   * Generate a list of geom from a multigeom
   * @param {ol.Feature} multiGeom is the multigeom
   * @param geomList is the geom list inside the multigeom
   * @returns {array <ol.Feature>}
   */
  generateGeomListFromMultiGeom(multiGeom, geomList) {
    const featuresWithoutMultiGeom = [];
    geomList.forEach(geom => {
      const newFeature = multiGeom.clone();
      newFeature.setGeometry(geom);
      featuresWithoutMultiGeom.push(newFeature);
    });
    return featuresWithoutMultiGeom;
  },

  /**
   * convert every multigeom handled by the app into simple geom
   * @param {array <ol.Feature>} features
   * @returns {array <ol.Feature>}
   */
  convertMultiGeomToGeomList(features) {
    const multiGeomList = kmlHelper.getMultiGeomFeatures(features, ['MultiLineString', 'MultiPolygon']);
    if (!multiGeomList.length) {
      return features;
    }
    let featuresWithoutMultiGeom = _.difference(features, multiGeomList);

    multiGeomList.forEach(multiGeom => {
      let geomList;
      const multiGeomType = multiGeom.getGeometry().getType();
      if (multiGeomType === 'MultiLineString') {
        geomList = multiGeom.getGeometry().getLineStrings();
      } else if (multiGeomType === 'MultiPolygon') {
        geomList = multiGeom.getGeometry().getPolygons();
      } else {
        return;
      }
      featuresWithoutMultiGeom = featuresWithoutMultiGeom
        .concat(kmlHelper.generateGeomListFromMultiGeom(multiGeom, geomList));
    });

    return featuresWithoutMultiGeom;
  },

  setNoOutlineForPolygonWithoutBorder(kmlData) {
    kmlData.querySelectorAll('Placemark').forEach(placemark => {
      let styleEl = placemark.querySelector('Style');
      if (!styleEl) {
        styleEl = kmlData.createElement('Style');
        placemark.appendChild(styleEl);
      }
      const lineStyleEl = styleEl.querySelector('LineStyle');
      if (!lineStyleEl) {
        const outlineEl = kmlData.createElement('outline');
        outlineEl.appendChild(kmlData.createTextNode('0'));
        let polyStyleEl = styleEl.querySelector('PolyStyle');
        if (!polyStyleEl) {
          polyStyleEl = kmlData.createElement('PolyStyle');
          styleEl.appendChild(polyStyleEl);
        }
        polyStyleEl.appendChild(outlineEl);
      }
    });
  },

  /**
   * Get vertex labels from the features and set them into the kml
   * @param {Array<ol.Feature>} features
   * @param kmlData
   */
  setVertexLabelsInKml(kmlData, features) {
    features.forEach((feature, featureIndex) => {
      if (['Polygon', 'LineString'].includes(feature.get('geometry').getType()) && !this.isCircleFeature(feature)) {
        feature.get('vertexLabels').forEach((vertexLabel, vertexIndex) => {
          const valueEl = kmlData.createElement('value');
          valueEl.setAttribute('index', vertexIndex);
          valueEl.appendChild(kmlData.createTextNode(vertexLabel));
          [...kmlData.getElementsByTagName('Placemark')[featureIndex].getElementsByTagName('Data')].forEach(dataEl => {
            if (dataEl.getAttribute('name') === 'vertexLabels') {
              dataEl.appendChild(valueEl);
            }
          });
        });
      }
    });
  },

  /**
   * Get vertex labels from the kml and set them into the features. Will also set corresponding styles.
   * @param {Array<ol.Feature>} features
   * @param {XMLDocument} kmlData
   */
  setVertexLabelsInFeatures(features, kmlData) {
    features.forEach((feature, featureIndex) => {
      if (['Polygon', 'LineString'].includes(feature.get('geometry').getType()) && !this.isCircleFeature(feature)) {
        feature.set('vertexLabels', []);
        [...kmlData.getElementsByTagName('Placemark')[featureIndex].getElementsByTagName('Data')]
          .filter(dataEl => dataEl.getAttribute('name') === 'vertexLabels')
          .flatMap(dataEl => [...dataEl.children].map(child => child.textContent))
          .forEach((vertexLabel, vertexIndex) => {
            // prevent errors if there is more labels than vertex in the KML
            if(feature.getGeometry().getCoordinates()[0].length - 1 < vertexIndex) {
              return;
            }
            feature.get('vertexLabels').push(vertexLabel);
            const textStyle = _.clone(STYLE_TEXT_LABEL);
            textStyle.setText(vertexLabel);
            feature.getStyle().push(
              new Style({
                text: textStyle,
                // Assignation du label à un point en particulier
                geometry: adaptGeomFunction.bind(null, vertexIndex),
                zIndex: feature.getStyle()[0].getZIndex() + 0.1
              })
            );
          });
      }
    });
  },

  saveLegendsInKml(kmlData, legends) {
    if (!legends) {
      return;
    }

    kmlData.querySelectorAll('Placemark').forEach(placemark => {
      const placemarkId = placemark.getAttribute('id');
      if (placemarkId) {
        const legend = legends.findWhere({featureId: placemarkId});
        if (legend) {
          const legendEl = kmlData.createElement('description');
          legendEl.appendChild(kmlData.createTextNode(legend.get('label')));
          placemark.appendChild(legendEl);
        }
      }
    });
  },

  kmlToString(kmlData) {
    // IE
    if (window.ActiveXObject) {
      return kmlData.xml;
    }
    return (new XMLSerializer()).serializeToString(kmlData);
  },

  /**
   * @param {$ | XMLDocument | string} kml jquery XMLDocument
   * @return {ol.Feature[]} array of features
   */
  kmlToFeatures(kml) {
    let $kml = kml;
    if (typeof kml === 'string') {
      $kml = $($.parseXML(kml));
    } else if (kml instanceof XMLDocument) {
      $kml = $(kml);
    }

    this.prepareKml($kml);

    const options = {
      dataProjection: 'EPSG:4326',
      featureProjection: FEATURE_PROJECTION
    };

    let features = kmlFormat.readFeatures($kml[0], options);
    features = this.adaptSomeKmlFeatures(features, kmlStyles);

    let currIndex = 1000;
    features.forEach(feature => {
      const zIndex = feature.get('zindex') ? parseInt(feature.get('zindex'), 10) : currIndex++;

      const mainStyle = styleHelper.getStyle(feature);
      mainStyle.setZIndex(zIndex);
      mainStyle.getText().setText(feature.get('text'));
      const styles = [
        mainStyle,
        // Extra styles
        new Style({zIndex: zIndex + 0.1}),
        new Style({zIndex: zIndex + 0.1})
      ];

      // Set feature label style
      const textStyle = _.clone(STYLE_TEXT_LABEL);
      textStyle.setText(feature.get('featureLabel'));
      if (feature.getGeometry().getType() === 'Point') {
        textStyle.setTextBaseline('top');
      }
      styles.push(
        new Style({
          text: textStyle,
          geometry: getFeatureCenter.bind(feature),
          zIndex: zIndex + 0.1
        })
      );

      feature.setStyle(styles);
    });

    this.setVertexLabelsInFeatures(features, $kml[0]);

    features.sort((a, b) => a.getStyle()[0].getZIndex() - b.getStyle()[0].getZIndex());

    return features;
  },

  /**
   * Create an ol Layer from an array of features
   * @param {Feature[]} features
   * @return {VectorLayer<VectorSource>}
   */
  createVectorLayer(features) {
    if (!features.length) {
      return null;
    }
    return new VectorLayer({
      source: new VectorSource({
        features,
        wrapX: true,
        projection: FEATURE_PROJECTION
      })
    });
  },

  /**
   * Create an ol Layer from the url of a kml document
   * @param {string} url
   * @return {VectorLayer<VectorSource>}
   */
  createVectorLayerFromUrl(url) {
    return new VectorLayer({
      source: new VectorSource({
        url,
        format: kmlFormat
      })
    });
  },

  /**
   * Create an external kml layer
   * @param olLayer {VectorLayer}
   * @param title {string}
   * @return {Layer}
   */
  createKmlLayer(olLayer, title) {
    if (!olLayer) {
      return null;
    }
    return new Layer({
      olLayer,
      external: true,
      title,
      identifier: LAYERTYPE_KML_LAYER,
      type: LAYERTYPE_KML_LAYER
    });
  },

  /**
   * @param {ol.Feature[]} features array of raw ol features to export
   * @return {XMLDocument} jquery XMLDocument
   */
  featuresToKml(features) {
    const preparedFeatures = features.map(feature => {
      const cloned = feature.clone();

      // we keep original featureId to associate legends
      cloned.setId(feature.getId());

      if (this.isCircleFeature(feature)) {
        const center = cloned.getGeometry().getCenter();
        const centerLonLat = CoordsHelper.convertMercatToLonLat(center);
        cloned.set('center-coord', centerLonLat.join(' '));
        cloned.set('center', centerLonLat);
        cloned.set('radius', cloned.getGeometry().getRadius());
        cloned.set('isCircle', true);
        cloned.set('sides', polygonizeCirclesSideNumber);
        cloned.setGeometry(fromCircle(cloned.getGeometry(), polygonizeCirclesSideNumber));
      }

      cloned.set('zindex', feature.getStyle()[0].getZIndex());

      const style = styleHelper.getStyle(cloned);
      styleHelper.setTextStyleExtensions(style);
      styleHelper.setStrokeExtensions(style);
      styleHelper.setRegularShapeExtensions(style);

      return cloned;
    }).sort((a, b) => a.getStyle()[0].getZIndex() - b.getStyle()[0].getZIndex());

    const kml = kmlFormat.writeFeatures(
      preparedFeatures,
      {dataProjection: 'EPSG:4326', featureProjection: FEATURE_PROJECTION}
    );

    const kmlData = $.parseXML(kml);
    this.setNoOutlineForPolygonWithoutBorder(kmlData);
    this.setVertexLabelsInKml(kmlData, preparedFeatures);

    return kmlData;
  }
};

module.exports = kmlHelper;
