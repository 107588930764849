import LayerModelUtils from '../../utils/layer-model-utils';

const $ = require('jquery');
const _ = require('underscore');
const ShomView = require('../../core/shom-view');

const NcwmsLayerUtils = require('../../utils/oceano/oceano-layer.js');
const ToastrUtil = require('../../utils/toastr.js');
const CoordsHelper = require('../../utils/gis/coordinates-helper');

const NCWMS = require('../../service/ncwms');

const ResultModalView = require('./ocea-vertical-result-modal.view');
const ResultErrorModalView = require('./ocea-result-error-modal.view');

const template = require('../../../template/oceano/ocea-vertical-profile.hbs');

module.exports = ShomView.build({

  events: {
    'click input[type="button"]': '_onButtonClick'
  },

  initialize(options = {}) {
    this._index = options.index;
    this._router = options.router || window.ROUTER;
    this._gisView = options.gisView || window.GISVIEW;
    this._parentView = options.parentView;
    this._modalView = options.modalView || window.MODALVIEW;
    this._config = options.config || window.CONFIG;

    this.initListenners();
  },

  initListenners() {
    const identifier = this.model.get('identifier');
    this.listenTo(this._gisView, `mode-verticalProfile:click${identifier}`, _.bind(this._showNcwmsProfile, this));
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({
      index: this._index,
      isVerticalProfileEnabled: !this._gisView.isGlobeEnabled()
    }));

    this._$toggleButton = this.$el.find(`#ocea-btn-verticalProfile-slider-${this._index}`);
    this._$selectorButton = this.$el.find(`#ocea-btn-verticalProfile-menu-${this._index}`);
    this._$selectorButton.on('click', _.bind(this._onButtonClick, this));
    return this;
  },

  _onButtonClick(evt) {
    if (this._gisView.isGlobeEnabled()) {
      return;
    }
    evt.preventDefault();
    this._started ? this.trigger('change:stopVerticalProfile') : this.trigger('change:startVerticalProfile');
  },

  onClose() {
    this.stopVerticalProfile();
  },

  /** ***************************** */
  /** ***** PROFILE FUNCTION ****** */
  /** ***************************** */

  startVerticalProfile() {
    if (this._started) {
      return;
    }
    this._started = true;
    this._$toggleButton.prop('checked', true);
    this._gisView.startVerticalProfileMode(this.model.get('identifier'));

    ToastrUtil.info($.i18n.t('oceano.toaster.verticalProfile'));
  },

  _showNcwmsProfile(evt) {
    // get displayed oceano layer.
    const tileGrid = this._gisView.getNCWMSTileGrid();
    const coord = evt.coordinate;
    const pixel = this._gisView.currentPixel;
    this._layer = this._gisView.getTopNcwmsLayerOnCoord(coord, 'NCWMS', tileGrid, this.model, pixel);

    if (this._layer === null) {
      this._buildAndShowError('oceano.modal.profile_error.message_no_layer', 'oceano.modal.profile_error.title');
      return;
    }

    const currentBbox = this._gisView.getCurrentViewBounds();
    const mapSize = this._gisView.getMapSize();
    const pointClicked = {
      X: Math.round(evt.pixel[0]),
      Y: Math.round(evt.pixel[1])
    };

    const ncwmsServiceOptions = { ncwmsLayerType: LayerModelUtils.getNcwmsLayerType(this.model) };
    NCWMS(ncwmsServiceOptions)
      .then(service => service.getMaximumDepth(pointClicked.X, pointClicked.Y, this._layer, mapSize, currentBbox)) // slider adjust for better UX.
      .then(maxDepth => {
        const modalTitle = NcwmsLayerUtils.getTitleTranslateLayer(this._layer);
        const coords = CoordsHelper.convertCoordinates(evt.coordSyst, coord);
        const elevations = this._layer.get('dimensions').elevation.map(elevation => +elevation || 0);
        elevations.sort((a, b) => a - b);
        const minDepth = elevations[0];
        const depthBounds = [minDepth, maxDepth];

        const resultView = new ResultModalView({
          model: this.model,
          gisView: this._gisView,
          title: 'oceano.modal.profile.title',
          title_option: modalTitle,
          coords,
          layer: this._layer,
          pointClicked,
          depthBounds,
          parentView: this
        });
        this._modalView.showWithoutBackdrop(resultView);
      }).fail(() => {
        this._buildAndShowError('oceano.modal.profile_error.message_service_unavailable', 'oceano.modal.profile_error.title');
      });
  },

  _buildAndShowError(error, title) {
    const result = new ResultErrorModalView({
      message: error,
      title
    });
    this._modalView.show(result);
  },

  stopVerticalProfile() {
    if (this._started !== true) {
      return;
    }
    this._started = false;
    this._gisView.stopVerticalProfileMode();
    this._$toggleButton.prop('checked', false);
  }
});
