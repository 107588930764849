var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"palette-container\">\n    <div class=\"palette-header\">\n        <div data-i18n=\"[title]menu.button.close\"><i class=\"fas fa-times palette-close-button\"></i></div>\n    </div>\n    <div class=\"palette-content\">\n        <h2 class=\"palette-title\"> "
    + alias1(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":6,"column":35},"end":{"line":6,"column":46}}}) : helper)))
    + "</h2>\n        <img id=\"ocea-palette-img\" class=\"\" src=\""
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"paletteURI") : depth0), depth0))
    + "\" alt=\""
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"paletteURI") : depth0), depth0))
    + "\"/>\n            <div id=\"ocea-palette-legend\">\n                <p id=\"max-palette\"></p>\n                <p id=\"middle-1-palette\" class=\"middle-value\"></p>\n                <p id=\"middle-2-palette\" class=\"middle-value\"></p>\n                <p id=\"middle-3-palette\" class=\"middle-value\"></p>\n                <p id=\"middle-4-palette\" class=\"middle-value\"></p>\n                <p id=\"middle-5-palette\" class=\"middle-value\"></p>\n                <p id=\"middle-6-palette\" class=\"middle-value\"></p>\n                <p id=\"middle-7-palette\" class=\"middle-value\"></p>\n                <p id=\"min-palette\"></p>\n            </div>\n    </div>\n</div>";
},"useData":true});