var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h5 class=\"chrono-graph-title\" data-i18n=\"ddm.labels.source"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"source") : depth0), depth0))
    + "\"></h5>\n<div style=\"display: none;\" class=\"chrono-chart-alert\">\n    <span class=\"chrono-chart-alert-msg\" data-i18n=\"ddm.labels.nodata\"></span>\n</div>\n<div class=\"chrono-graph-histo\"></div>\n";
},"useData":true});