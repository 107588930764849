var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button class=\"switch-button disp-layer-tools-featureInfos-button "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFeatureInfoEnabled") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":66},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + "\"\n        id=\"ocea-btn-feature-selector-"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\" >\n    <span data-i18n=\"oceano.nav.featureinfo.action\" ></span>\n    <!-- Rounded switch -->\n    <label class=\"switch\">\n        <input type=\"checkbox\" id=\"ocea-btn-feature-slider-"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFeatureInfoEnabled") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":75},"end":{"line":6,"column":126}}})) != null ? stack1 : "")
    + ">\n        <div class=\"slider round\"></div>\n    </label>\n</button>\n";
},"useData":true});