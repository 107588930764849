import {
  CATALOG_DEFAULT_ORDER_INDEX, CATALOG_REFERENCE, LAYERTYPE_NCWMS, LAYERTYPE_REFMAR, SEPARATOR_CATEGORY_LEVEL01
} from '../../utils/constants';

import AtlasHelper from '../../utils/atlas/atlas-utils';

const _ = require('underscore');
const ShomView = require('../../core/shom-view');
const SubCategoryView = require('./subcategory.view');
const LayerView = require('./layer.view');
const OceanoLayerView = require('./oceano-layer.view');
const template = require('../../../template/catalog/category.hbs');

// used to display first level category such as: reference#cartographie
module.exports = ShomView.build({

  className: 'panel-default panel-category',

  events: {
    'click .mainpanel-heading': '_onHeadingClick'
  },

  initialize(options = {}) {
    this._category = options.category;
    this._catalog = options.catalog || CATALOG_REFERENCE;
    this._subCategories = this._category.subcategories || [];
    this._itemViews = [];
    this._collection = options.collection || window.LAYERS;
    this.index = this._category.index || CATALOG_DEFAULT_ORDER_INDEX;
  },

  render() {
    this._removeItemViews();
    this._createItemViews();
    if (!this._itemViews || !this._itemViews.length) {
      // no item views, we don't render it
      return this;
    }
    this.$el.html(template({
      category: this._category.id
    }));
    this._$layersRoot = this.$('.category-layers-root');
    this._$collapseLink = this.$('.link-collapse-category');
    this._renderItemViews();
    return this;
  },

  _removeItemViews() {
    _.each(this._itemViews, itemView => itemView.remove());
    this._itemViews = [];
    this._$layersRoot && this._$layersRoot.html('');
  },

  _createItemViews() {
    this._createSubCategoryViews();
    const layers = this._getLayers();
    _.each(layers, layer => {
      if (AtlasHelper.isAtlasLayer(layer) || (layer.get('layerType') && (layer.get('layerType') === LAYERTYPE_NCWMS || layer.get('layerType') === LAYERTYPE_REFMAR))) {
        this._itemViews.push(new OceanoLayerView({ model: layer, oceano: true }));
      } else {
        this._itemViews.push(new LayerView({ model: layer }));
      }
    });
  },

  _createSubCategoryViews() {
    if (!this._subCategories) {
      return;
    }
    _.each(this._subCategories, category => this._itemViews.push(new SubCategoryView({
      category,
      parentCategoryId: this._category.id,
      collection: this._collection
    })));
  },

  _renderItemViews() {
    // we have to order itemsViews before render
    this._itemViews.sort((view1, view2) => view1.index - view2.index);
    _.each(this._itemViews, itemView => this._$layersRoot.append(itemView.render().$el));
  },

  _getLayers() {
    const categoryId = this._catalog + SEPARATOR_CATEGORY_LEVEL01 + this._category.id;
    return this._collection.filter(item => {
      if (item.get('layerType') && item.get('layerType') === LAYERTYPE_NCWMS && !AtlasHelper.isAtlasLayer(item)){
        return item.get('identifier') ? item.get('identifier').slice(0, item.get('identifier').indexOf('/')) === this._category.id : false;
      }
      return item.get('category') ? item.get('category').fullpath === categoryId : false;
    });
  },

  _onHeadingClick() {
    const $collapseItem = this.$el.find('.collapse').first();
    const expanded = $collapseItem.hasClass('collapse in');
    $collapseItem.collapse('toggle');
    const $panelTitle = this.$el.find('.panel-title').first();
    const $panelTitleLink = this.$el.find('.panel-title > a').first();
    if (expanded) {
      $panelTitleLink.addClass('collapsed');
      $panelTitle.removeClass('layer-included-in-map');
    } else {
      $panelTitle.addClass('layer-included-in-map');
      $panelTitleLink.removeClass('collapsed');
    }
  },

  postTranslate(lang) {
    const translatedName = this._category.name && this._category.name[lang] ? this._category.name[lang] : this._category.id;
    this._$collapseLink && this._$collapseLink.text(translatedName);
  },

  onClose() {
    this._removeItemViews();
  }
});
