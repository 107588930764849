const $ = require('jquery');
const _ = require('underscore');
const Backbone = require('backbone');

const ShomView = require('../../../core/shom-view');
const template = require('../../../../template/drawing/discuss/discuss-action-toolbar.hbs');

const ToastrUtil = require('../../../utils/toastr.js');
const CartoDynMap = require('../../../model/cartodyn-map.js');
const InvitationModal = require('./invitation-modal.view');
const SessionUserPickerModalView = require('./session-user-picker-modal.view');
const MapPropertiesModal = require('../map-properties-modal.view');

const SessionView = ShomView.build({
  events: {
    'click .btn-leave-session': '_onLeaveSessionClick',
    'click .btn-invite': '_onInviteClick',
    'click .btn-grant-editor': '_onGrantEditorClick',
    'click .btn-reset-editor': '_onResetEditorClick',
    'click .btn-kick-user': '_onKickUserClick',
    'click .btn-save-copy': '_onSaveMapCopyClick'
  },

  initialize(options) {
    _.bindAll(this, '_onSessionInfoUpdated', '_alert', '_onInviteSubmit');
    this._sessionInfo = null;
    this._drawingRouter = options.drawingRouter || window.ROUTER._leftMenuRouter._drawingMenuRouter;
    this._user = options.user || window.ROUTER.user;
    this._gisView = options.gisView || window.GISVIEW;
    this._modalView = options.modalView || window.MODALVIEW;
    this._config = options.config || window.CONFIG;
    this._maxUsers = this._config.cartodyn.discuss.maxUsers || 1;
    this.listenTo(this.model, 'discuss:session:update', this._onSessionInfoUpdated);
    this.listenTo(this.model, 'discuss:session:closed', this._onSessionClosed);
  },

  render() {
    const context = this.getContext();
    this.$el.html(template(context));
  },

  getContext() {
    const context = {
      isEditor: false,
      isOwner: false,
      username: '',
      owner: '',
      editor: '',
      connectedUsers: [],
      isLoggedIn: false,
      canInviteUser: false
    };
    if (!this._sessionInfo) {
      this._sessionInfo = this.model.get('lastUpdateEvent');
    }
    if (this._sessionInfo) {
      const currentUser = _.findWhere(this._sessionInfo.users, { key: this._user.get('key') });
      if (currentUser) {
        context.username = currentUser.name;
      }
      const owner = _.findWhere(this._sessionInfo.users, { key: this._sessionInfo.ownerKey });
      if (owner) {
        context.owner = owner.name;
      }
      const editor = _.findWhere(this._sessionInfo.users, { key: this._sessionInfo.editorKey });
      if (editor) {
        context.editor = editor.name;
      }
      context.connectedUsers = this._sessionInfo.users;
      context.isEditor = this.model.isCurrentUserEditor();
      context.isOwner = this.model.isCurrentUserOwner();
      const usersCount = this._sessionInfo.users.length;
      context.existOtherUsers = usersCount > 1;
      context.canInviteUser = usersCount < this._maxUsers;
    }
    context.isLoggedIn = this._user.get('isLoggedIn');
    return context;
  },

  _onSessionInfoUpdated(event) {
    if (event.updateEditor || event.updateUsers) {
      this._sessionInfo = event;
      this.render();
    }
  },

  _onSessionClosed() {
    this._sessionInfo = null;
  },

  _onLeaveSessionClick() {
    this.model.set('collaborative', false);
    this._executeCartoModuleCommands([['drawing.enable', false]]);
  },

  _onInviteClick() {
    const invitationModal = new InvitationModal({
      modalView: this._modalView,
      parent: this,
      model: this.model,
      confirmCallback: this._onInviteSubmit
    });
    this._modalView.show(invitationModal);
  },

  _onInviteSubmit(email) {
    this.model.sendInvitation(email)
      .then(_.bind(() => {
        this._alert('discuss.invitation.modal.sent.message');
      }, this))
      .fail(_.bind(() => {
        this._alert('discuss.invitation.modal.sent.error');
      }, this));
  },

  _onGrantEditorClick() {
    const givePermissionModal = new SessionUserPickerModalView({
      titleKey: 'discuss.rights.modal.givePermission.title',
      messageKey: 'discuss.rights.modal.givePermission.message',
      okKey: 'discuss.rights.modal.givePermission.ok',
      cancelKey: 'discuss.rights.modal.givePermission.cancel',
      modalView: this._modalView,
      parent: this,
      collection: new Backbone.Collection(this._sessionInfo.users),
      user: this._user,
      callback: _.bind(user => {
        this.model.giveEditorRights(user.get('key'));
      }, this)
    });
    this._modalView.show(givePermissionModal);
  },

  _onResetEditorClick() {
    this.model.resetEditorRights();
  },

  _onKickUserClick() {
    const kickUserModal = new SessionUserPickerModalView({
      titleKey: 'discuss.kickUser.modal.title',
      messageKey: 'discuss.kickUser.modal.message',
      okKey: 'discuss.kickUser.modal.ok',
      cancelKey: 'discuss.kickUser.modal.cancel',
      modalView: this._modalView,
      parent: this,
      collection: new Backbone.Collection(this._sessionInfo.users),
      user: this._user,
      callback: _.bind(user => {
        this.model.kickUser(user.get('key'));
      }, this)
    });
    this._modalView.show(kickUserModal);
  },

  _onSaveMapCopyClick() {
    const newMap = new CartoDynMap();
    const name = this._drawingRouter.generateNewMapName();
    newMap.set('name', name);
    this._drawingRouter.setupMap(newMap);
    const content = new MapPropertiesModal({
      title: 'drawing.modal.map-properties.title',
      modalView: this._modalView,
      model: newMap,
      parent: this.model,
      okButtonLabel: 'drawing.modal.map-properties.save',
      saveCallback: _.bind(() => {
        this.model.saveMap(newMap)
          .then(_.bind(() => {
            this._alert('drawing.modal.map-saved.message');
          }, this))
          .fail(this._onMapSaveFailed);
      }, this)
    });
    this._modalView.show(content, { keyboard: false, backdrop: 'static' });
  },

  _onMapSaveFailed() {
    ToastrUtil.error($.i18n.t('drawing.modal.map-saved.error-message'));
  },

  _alert(i18nKeyMessage) {
    ToastrUtil.info($.i18n.t(i18nKeyMessage));
  }
});

module.exports = SessionView;
