const $ = require('jquery');
const csw = require('./csw-format.js');

const insertCswParams = url => {
  if (url.indexOf('elementsetname=full') === -1) {
    url += '&elementsetname=full';
  }
  return url;
};

const MetadataRetriever = () => {

};

MetadataRetriever.prototype.load = (url, lang) => {
  const defered = $.Deferred();

  url = insertCswParams(url);

  $.ajax({ url })
    .then(document => {
      const result = csw.read(document, lang);
      defered.resolve(result);
    })
    .fail(() => {
      defered.reject();
    });

  return defered;
};

module.exports = MetadataRetriever;
