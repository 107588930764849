const moment = require('moment');
const _ = require('underscore');
const $ = require('jquery');
const ShomView = require('../../../core/shom-view');
const template = require('../../../../template/ddm/ddm-detail/ddm-data-textual.hbs');
const Loading = require('../../../utils/loading');
const DDMUtils = require('../../../utils/ddm/ddm-utils');
const DDMDataUtils = require('../../../utils/ddm/ddm-data-utils');
const DDMTextualDataItemsView = require('./ddm-data-textual-items.view');

module.exports = ShomView.build({

  events: {
    'change #textual-observation-source': '_onSourceChange',
    'change #textual-observation-length': '_onTypeObservationChange',
    'change #textual-pick-observation': '_onCustomValueChange'
  },

  initialize(options) {
    this._config = options.config || window.CONFIG;
    this._eventBus = options.eventBus || window.EVENTBUS;
    this._idSensor = options.idSensor;
    this._graphDataObservations = [];
    this._tidegaugeName = options.tidegaugeName || window.tidegaugeName;
    this.isCustomValueOnValid = false;
    this._isLikeGraphObservations = false;

    this._ddmUtils = new DDMUtils(this._config);
    this._utils = new DDMDataUtils(this._config);
    this._initDataTemplate();

    this._eventBus.on('build:mainData', this._onReceiveGraphObservation.bind(this));
  },

  render() {
    this.$el.html(template({
      numbersObservations: this._numbersObservations,
      isCustom: this._isCustom,
      observationCustomMin: this._config.ddm.textualData.observationCustomMin,
      observationCustomMax: this._config.ddm.textualData.observationCustomMax,
      sourcesObservations: this._sourcesObservations,
      isCustomValueOnValid: this.isCustomValueOnValid
    }));

    this.$rangeObservationDiv = this.$('#customize-observation');
    this.$startDateDiv = this.$('#startdate-container');
    this.$observationLimit = this.$('#textual-observation-length');
    this.$observationLimitBoxError = this.$('.alert');

    this._renderDateTimePickerControls();
    this._renderTextualDataItemsViews();
    return this;
  },

  _renderTextualDataItemsViews() {
    this._$textualDataItemsView = this.$('#data-textual-items');
    this._textualDataItemsView = new DDMTextualDataItemsView({
      parentView: this,
      tidegaugeObservations: {},
      tidegaugeName: this._tidegaugeName,
      idSensor: this._idSensor
    });
    this._$textualDataItemsView.html(this._textualDataItemsView.render().$el);
  },

  /**
   *
   * @param {Boolean} onStart
   *
   * this method
   *  -update parameters
   *  -fetch new observations
   *  -render the new view
   */
  setValuesAndRender(onStart = true) {
    if (!this._isLikeGraphObservations && !onStart) {
      Loading.start(this._$textualDataItemsView);
      let pattern = `${this._idSensor}?sources=${this._currentSource}&limitValue=${this._numbersObservations}`;
      let wiStartDate = false;
      if (this._isCustom) {
        pattern = `${pattern}&dtStart=${this._startDate.format(this._config.ddm.textualData.observationStartDateFormat)}`;
        wiStartDate = true;
      }
      this._ddmUtils
        .getTidegaugeObservations(pattern, wiStartDate)
        .then(_.bind(this._buildData, this))
        .then(_.bind(this._textualDataItemsView.updateAndRender, this._textualDataItemsView));
    } else if (this._isLikeGraphObservations) {
      const graphDataObservationsOfSource = _.findWhere(this._graphDataObservations, { source: this._currentSource });
      this._textualDataItemsView.updateAndRender(graphDataObservationsOfSource);
    }
  },

  _renderDateTimePickerControls() {
    this._$startDate = this.$('#start-date');
    const i18nLang = $.i18n.options.lng;
    const lang = i18nLang === 'fr' || i18nLang === 'fr_FR' ? 'fr' : 'en';

    const dateTimePickerOptionsDateOnly = {
      format: this._observationStartDateFormat,
      showClose: this._config.ddm.textualData.observationDateShowCase,
      useCurrent: this._config.ddm.textualData.observationDateUseCurrent,
      locale: lang,
      sideBySide: true
    };

    if (!this._$startDate.val()) {
      this._$startDate[0].setAttribute('value', this._startDate.format(this._observationStartDateFormat));
    }
    this._$startDate.datetimepicker(dateTimePickerOptionsDateOnly);
    this._$startDate.on('dp.change', this._onCustomValueChange.bind(this));
  },

  _updateDates() {
    const startInput = this._$startDate.val();
    this._startDate = this._utils.setBeginTime(moment.utc(startInput, this._observationStartDateFormat), 'UTC0', true);
  },

  _onTypeObservationChange() {
    const typeSelectObservation = this.$observationLimit.val();
    switch (typeSelectObservation) {
      case 'lastTenObservations':
        this._hideCustomIntput();
        this._numbersObservations = this._config.ddm.textualData.observationLastTen;
        this._isLikeGraphObservations = false;
        this._isCustom = false;
        break;
      case 'lastHundredObservations':
        this._hideCustomIntput();
        this._isLikeGraphObservations = false;
        this._isCustom = false;
        this._numbersObservations = this._config.ddm.textualData.observationLastHundred;
        break;
      case 'graph':
        this._hideCustomIntput();
        this._isLikeGraphObservations = true;
        this._isCustom = false;
        break;
      case 'customize':
        this._isLikeGraphObservations = false;
        this._isCustom = true;
        this._numbersObservations = this._config.ddm.textualData.observationCustomMin;
        this.$rangeObservationDiv.removeClass('customize-container');
        this.$startDateDiv.removeClass('customize-container');
        break;
    }
    this.setValuesAndRender(false);
  },

  _hideCustomIntput() {
    if (this._isCustom) {
      this.$rangeObservationDiv.addClass('customize-container');
      this.$startDateDiv.addClass('customize-container');
    }
  },

  _onSourceChange(event) {
    this._currentSource = parseInt(event.target.value, 10);
    this.setValuesAndRender(false);
  },

  _onCustomValueChange(event) {
    if (event.target.name === 'start-date') {
      this._updateDates();
      // update and render with new date
      this.setValuesAndRender(false);
    } else if (!event.target.validity.valid) {
      // show alert dialog
      // message value must be less than or equal to
      this.$observationLimitBoxError.removeClass('customize-on-invalid-value');
    } else {
      // update and render with new limit observation
      const newLimitValue = parseInt(event.target.value, 10);
      this._numbersObservations = newLimitValue;
      this.setValuesAndRender(false);
      this.$observationLimitBoxError.addClass('customize-on-invalid-value');
    }
  },

  _initDataTemplate() {
    this._observationStartDateFormat = $.i18n.t('ddm.textual.data.observationStartDateFormat');
    this._sourcesObservations = _.map(_.range(1, this._config.ddm.textualData.numberOfObservationSources + 1), sourceId => ({ id: sourceId, checked: sourceId === 1 }));
    const now = new Date();
    this._startDate = this._utils.setBeginTime(moment.utc(now, this._observationStartDateFormat), 'UTC0', true);
    this._currentSource = 1;
    this._numbersObservations = this._config.ddm.textualData.observationLastTen;
    this._isCustom = false;
  },

  /**
   *
   * @param {List of Observation} tidegaugeObservations
   *
   * with response from  #DDMDataUtils.getTidegaugeObservations format result
   */
  _buildData(tidegaugeObservations) {
    const observationNumber = tidegaugeObservations.error ? -1 : _.size(tidegaugeObservations);
    const tidegaugeObserSource = { source: this._currentSource };
    if (observationNumber > 0) { // if we have observations for this source
      const observationsGroupedBySource = _.groupBy(tidegaugeObservations, observation => observation.source);
      tidegaugeObserSource.observations = observationsGroupedBySource[this._currentSource];
    } else { // if we have no result or no observation for this source
      tidegaugeObserSource.nodata = true;
    }
    Loading.stop(this._$textualDataItemsView);
    return tidegaugeObserSource;
  },

  /**
   *
   * @param {List Of Observation} graphDataObservations
   *
   * with list of observation from graph observation
   * group by source and store in this.graphDataObservations
   *
   */
  _onReceiveGraphObservation(graphDataObservations) {
    this._graphDataObservations = [];
    const observationsGroupedBySource = _.groupBy(graphDataObservations.data, observation => observation.idsource);
    const sourcesWithObservationList = _.keys(observationsGroupedBySource);

    // with observations Like in graph update this._graphDataObservations, this._sourcesObservations
    for (let i = 0; i < sourcesWithObservationList.length; i++) {
      const sourceId = parseInt(sourcesWithObservationList[i], 10);
      const tidegaugeObserSource = {
        source: sourceId,
        observations: _.map(observationsGroupedBySource[sourcesWithObservationList[i]], observation => ({ value: observation.value, time: moment.utc(observation.timestamp).format() }))
      };
      if (tidegaugeObserSource.observations) {
        tidegaugeObserSource.observations.reverse();
      }
      this._graphDataObservations.push(tidegaugeObserSource);
      if (sourceId <= this._config.ddm.textualData.numberOfObservationSources) {
        this._sourcesObservations[sourceId - 1].checked = true;
        this._currentSource = sourceId;
      }
    }

    const allSourceObservation = _.range(1, this._config.ddm.textualData.numberOfObservationSources + 1);
    // convert vector id from string to integer
    const sourcesWithObservationIds = _.chain(sourcesWithObservationList).map(s => parseInt(s, 10)).value();
    // get source id without observations
    const sourcesWithoutObservation = _.difference(allSourceObservation, sourcesWithObservationIds);

    // add empty table observation for each source in sourcesWithoutObservation
    for (let i = 0; i < sourcesWithoutObservation.length; i++) {
      const tidegaugeWithoutObserv = {
        source: sourcesWithoutObservation[i],
        nodata: true
      };
      this._graphDataObservations.push(tidegaugeWithoutObserv);
    }
  }

});
