var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button id=\"chart-export-main\" class=\"chart-export\" data-i18n=\"ddm.labels.export\"></button>\n            <div class=\"chart-warnings\">\n                <span id=\"chart-warning-main\" class=\"chart-warning\"><span data-i18n=\"ddm.datawarning\"></span>&nbsp;"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"limit") || (depth0 != null ? lookupProperty(depth0,"limit") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"limit","hash":{},"data":data,"loc":{"start":{"line":3,"column":115},"end":{"line":3,"column":124}}}) : helper)))
    + ".</span>\n                <span id=\"chart-nodata-main\" class=\"chart-warning\" data-i18n=\"ddm.labels.nodata\"></span><br/>\n                <span id=\"chart-message-sources-availables\" class=\"chart-message\"><span data-i18n=\"ddm.labels.sourcesavailables\"></span></span>                \n            </div>\n            <div id=\"chart-render-main\" class=\"chart-render\"></div>\n            <div id=\"chart-tooltip-main\" class=\"chart-tooltip\"></div>";
},"useData":true});