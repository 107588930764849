const $ = require('jquery');
const moment = require('moment');
const ShomView = require('../../../core/shom-view');
const template = require('../../../../template/ddm/ddm-dl/ddm-dl-control.hbs');
const DDMDataUtils = require('../../../utils/ddm/ddm-data-utils');
const AuthZones = require('../../../utils/auth-zones.js');

module.exports = ShomView.build({

  events: {
    'hide input[name="startDate"]': '_onFormChange',
    'hide input[name="endDate"]': '_onFormChange',
    'change select[name="source"]': '_onFormChange',
    'change select[name="type"]': '_onFormChange',
    'click [type="checkbox"]': '_onFormChange',
    'change #get-all-data': '_onGetAllDataToggle'
  },

  initialize(options) {
    this._router = options.router || window.ROUTER;
    this.config = options.config || window.CONFIG;
    this._modalView = options.modalView || window.MODALVIEW;
    this._utils = new DDMDataUtils(this.config.ddm);
    this._endDate = moment.utc();
    this._startDate = moment.utc(this._endDate).subtract(7, 'days');
    this._selectedSrc = [false, false, false, false, false, false];
    this._selectedType = 'TXT';
    this._isAllDataEnabled = true;
  },

  _addSourceControl(sourceId, isHidden) {
    isHidden = isHidden || false;
    return {
      value: sourceId,
      label: `ddm.labels.source${sourceId}`,
      isHidden
    };
  },

  _initStaticControls() {
    const sources = [];
    sources.push(this._addSourceControl(1));
    sources.push(this._addSourceControl(2));
    sources.push(this._addSourceControl(3));
    sources.push(this._addSourceControl(4));
    sources.push(this._addSourceControl(5));
    sources.push(this._addSourceControl(6));

    const types = [];
    types.push({ value: 'TXT', label: $.i18n.t('ddm.dl.type.TXT') });
    types.push({ value: 'POX', label: $.i18n.t('ddm.dl.type.POX') });
    types.push({ value: 'JSON', label: $.i18n.t('ddm.dl.type.JSON') });
    types.push({ value: 'NC', label: $.i18n.t('ddm.dl.type.NC') });

    return {
      startDate: this._startDate.format('DD/MM/YYYY'),
      endDate: this._endDate.format('DD/MM/YYYY'),
      sources,
      types
    };
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  _renderControls() {
    this.$startDate = this.$el.find("input[name='startDate']");
    this.$endDate = this.$el.find("input[name='endDate']");
    this.$src = this.$el.find("select[name='source']");
    this.$type = this.$el.find("select[name='type']");

    this.$('.pickdate').datepicker({
      format: 'dd/mm/yyyy',
      language: $.i18n.lng(),
      autoclose: true,
      endDate: moment.utc(this._endDate).toDate()
    });
    this.$startDate.datepicker('setStartDate', new Date(this.config.ddm.ddm_dl.dlStartDate));
    this.$endDate.datepicker('setStartDate', this._startDate.toDate());
  },

  render() {
    const controls = this._initStaticControls();
    this.$el.html(template(controls));
    this._renderControls();
    AuthZones.checkAuth(this.$el);

    this.$error = this.$el.find('#error');
    this.$error.hide();

    return this;
  },

  /** ***************************** */
  /** ****** EVENT FUNCTION ******* */
  /** ***************************** */

  _onFormChange() {
    const startInput = this.$startDate.val();
    const newStart = this._utils.setBeginTime(moment.utc(startInput, 'DD/MM/YYYY'), 'UTC');
    if (!newStart.isSame(this._startDate)) {
      this._startDate = newStart;
      if (this._startDate.isValid()) {
        this.$endDate.datepicker('setStartDate', this._startDate.toDate());
      }
    }

    const endInput = this.$endDate.val();
    const newEnd = this._utils.setEndTime(moment.utc(endInput, 'DD/MM/YYYY'), 'UTC');
    if (!newEnd.isSame(this._endDate)) {
      this._endDate = newEnd;
      if (this._endDate.isValid()) {
        this.$startDate.datepicker('setEndDate', this._endDate.toDate());
      }
    }

    for (let i = 0; i < 6; i++) {
      const checkbox = this.$el.find(`input[name='source${i + 1}']`)[0];
      this._selectedSrc[i] = checkbox.checked;
    }

    this._selectedType = this.$type.val();
    if (this._selectedType === 'POX') {
      this.handlePoxControls();
    } else {
      if (this._isAllDataEnabled === true) {
        this.enableAllData();
      }
      this.$endDate.datepicker('setEndDate', moment.utc().toDate());
    }
  },

  handlePoxControls() {
    this.disableAllData(this._isAllDataEnabled);
    if (moment.utc().diff(this._startDate, 'days') <= this.config.ddm.ddm_dl.poxMaxInterval) {
      return;
    }
    const newEndDate = moment.utc(this._startDate).add(this.config.ddm.ddm_dl.poxMaxInterval, 'days');
    this.$endDate.datepicker('setEndDate', newEndDate.toDate());
    if (moment.utc(this._endDate).diff(this._startDate, 'days') > this.config.ddm.ddm_dl.poxMaxInterval) {
      this.$endDate.val(newEndDate.format('DD/MM/YYYY'));
      this._endDate = this._utils.setEndTime(moment.utc(this.$endDate.val(), 'DD/MM/YYYY'), 'UTC');
    }
  },

  /** ***************************** */
  /** ****** GETTER FUNCTION ****** */
  /** ***************************** */

  getControls() {
    const params = {
      type: this._selectedType,
      sources: this._selectedSrc,
      startDate: this._startDate,
      endDate: this._endDate,
      isFullDownload: this.isFullDownload
    };

    return params;
  },

  /** ***************************** */
  /** ****** UTILS FUNCTION ******* */
  /** ***************************** */

  validate() {
    if (this._selectedSrc
      && this._selectedSrc.length > 0
      && this._selectedSrc.filter(isCheck => isCheck).length > 0) {
      return '';
    }
    return $.i18n.t('ddm.dl.alertForm.control');
  },

  _onGetAllDataToggle() {
    const $allDataInput = this.$el.find('#get-all-data');
    this.isFullDownload = $allDataInput.prop('checked');
    this.$startDate.prop('disabled', this.isFullDownload);
    this.$endDate.prop('disabled', this.isFullDownload);
  },

  disableAllData(isAllDataEnabled) {
    this._isAllDataEnabled = isAllDataEnabled;
    const $allDataContainer = this.$el.find('#get-all-data-container');
    const $allDataInput = this.$el.find('#get-all-data');

    $allDataContainer.prop('class', 'disabled');
    $allDataContainer.prop('title', $.i18n.t('ddm.dl.allDataDisabled'));
    $allDataInput.prop('disabled', true);
    $allDataInput.prop('checked', false);
    this.isFullDownload = $allDataInput.prop('checked');
    this.$startDate.prop('disabled', false);
    this.$endDate.prop('disabled', false);
    $('#dl-type option[value="POX"]').prop('disabled', this.isFullDownload);
  },

  enableAllData() {
    this._isAllDataEnabled = true;
    if (this._selectedType === 'POX') {
      return;
    }
    const $allDataContainer = this.$el.find('#get-all-data-container');
    const $allDataInput = this.$el.find('#get-all-data');

    $allDataContainer.prop('class', '');
    $allDataContainer.prop('title', '');
    $allDataInput.prop('disabled', false);
    this._onGetAllDataToggle();
    $('#dl-type option[value="POX"]').prop('disabled', this.isFullDownload);
  }

});
