const $ = require('jquery');
const ShomView = require('../../core/shom-view');
const template = require('../../../template/oceano/ocea-result-popup.hbs');
const Loading = require('../../utils/loading');

module.exports = ShomView.build({

  initialize(options = {}) {
    this._lonlat = options.lonlat;
    this._value = options.value;
    this._estimated = options.estimated;
    this._unit = options.unit;
    this._loading = options.loading;
    this._infoLabelText = options.infoLabelText || '';
    this._warning = options.warning;
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    const lon = this._lonlat[0].toFixed(4);
    const lat = this._lonlat[1].toFixed(4);
    this.$el.html(template({
      lon, lat
    }));
    this._$valueFrame = this.$el.find('#result-popup-info-value');

    if (this._warning) {
      this.$('#result-popup-info-warning').html(`${this._warning}<br/>`).parent().show();
    }

    if (this._loading) {
      this.$el.find('#value-label').attr('data-i18n', 'oceano.popup.label.real');
      this.$el.find('#result-popup-info-label').hide();
      Loading.start(this._$valueFrame, { overlay: false });
    } else {
      const value = this._value === $.i18n.t('oceano.popup.label.no_data') ? this._value : `${this._value} ${this._unit}`;
      this._$valueFrame.text(value);
      if (this._estimated) {
        this.$el.find('#value-label').attr('data-i18n', 'oceano.popup.label.estimated');
        this.$el.find('#result-popup-info-label').attr('data-i18n', this._infoLabelText);
      } else {
        this.$el.find('#value-label').attr('data-i18n', 'oceano.popup.label.real');
        this.$el.find('#result-popup-info-label').hide();
      }
    }

    return this;
  },

  onClose() {
    Loading.stop(this._$valueFrame);
  }

});
