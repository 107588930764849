import AtlasHelper from '../../utils/atlas/atlas-utils';

import { LAYERTYPE_NCWMS } from '../../utils/constants';

const $ = require('jquery');
const moment = require('moment');
const ShomView = require('../../core/shom-view');

const template = require('../../../template/legend/legend.hbs');

const MetadataRetriever = require('../../utils/metadata-retriever.js');

const OceanoLayerUtils = require('../../utils/oceano/oceano-layer.js');
const DDMDataUtils = require('../../utils/ddm/ddm-data-utils');

module.exports = ShomView.build({

  imgRegex: /\.(gif|jpeg|jpg|png)$/i,

  className: 'legend-view hitable',

  events: {
    'click .left-menu-close-button': '_onBackButtonClick',
    'click .legend-button': '_openCurrentLayerLegend'
  },

  initialize(options = {}) {
    this._router = options.router || window.ROUTER;
    this._config = options.config || window.CONFIG;
    this._gisView = options.gisView || window.GISVIEW;
    this._eventBus = options.eventBus || window.EVENTBUS;
    this._metadataRetriever = options.metadataRetriever || new MetadataRetriever();
    this._backRoute = options.backRoute || 'donnees';

    this._isAtlas = AtlasHelper.isAtlasLayer(this.model);

    this._ddmDataUtils = options.ddmDataUtils || new DDMDataUtils(this._config);
    this._spmDiffFromNearestPmFormat = this._config.tfs.diffFromNearestPmDateTimeFormat;
    this._timelineView = this._gisView.getTimelineView();
    if (this._timelineView) {
      this._initListeners();
    } else {
      this._onTimelineCreatedEventFunc = this._onTimelineCreated.bind(this);
      this._eventBus && this._eventBus.once('create:timeline-view', this._onTimelineCreated.bind(this));
    }

    this.initMetadataData().then(this.render.bind(this));
  },

  _initListeners() {
    this.listenTo(this._timelineView, 'change:oceaGlobMoment', this._updateAtlasSection.bind(this));
    this.listenTo(this._timelineView, 'change:oceaGlobUTC', this._updateAtlasSection.bind(this));
  },

  _onTimelineCreated(timelineView) {
    this._timelineView = timelineView;
    this._initListeners();
  },

  refreshLegend() {
    if (this.model && this.model.get('layerType') === LAYERTYPE_NCWMS) {
      if (this._isAtlas && this.currentMtdRef !== this.model.get('metadataURL')) {
        // on atlas layers, we need to refresh if zoom is different (R1111100, R1111110)
        this.currentMtdRef = this.model.get('metadataURL');
        this.initMetadataData(true).then(this.render.bind(this));
      } else if (!this._isAtlas && this.currentMtdRef !== this.model.get('currentModelType')) {
        // on oceano layers, we need to refresh if model is different (MFWAM/WW3)
        this.currentMtdRef = this.model.get('currentModelType') || this.model;
        this.initMetadataData(true).then(this.render.bind(this));
      }
    }
  },

  initMetadataData(forceReload = false) {
    // we have to update metadataURL for geonetwork url
    if ((!this._modelMetadata || forceReload) && this.model.get('metadataURL')) {
      if (this.model.get('metadataURL').match(/.*Id=(.*).xml.*/) && this.model.get('layerType') !== 'PARTNER') {
        this._updateGeonetworkUrl();
        this.rewriteUrl();
      } else {
        this.geonetworkUrl = this.model.get('metadataURL');
      }

      // Load metadata for each language
      return Promise.all([this._loadModalMetadata('fr'), this._loadModalMetadata('en')]).then(values => {
        this._modelMetadataFR = values[0];
        this._modelMetadataEN = values[1];
      });
    }
    return Promise.resolve();
  },

  rewriteUrl() {
    let rewrittenMetadataURL = this.model.get('metadataURL');
    // url already rewritten
    if (rewrittenMetadataURL.indexOf(this._config.downloadService.geonetworkOutputSchema) !== -1) {
      return;
    }
    if (rewrittenMetadataURL.indexOf('catalog/INSPIRE') !== -1) {
      rewrittenMetadataURL = rewrittenMetadataURL.replace('catalog/INSPIRE', this._config.downloadService.geonetworkPath);
    } else if (rewrittenMetadataURL.indexOf('catalog/ISOAP') !== -1) {
      rewrittenMetadataURL = rewrittenMetadataURL.replace('catalog/ISOAP', this._config.downloadService.geonetworkPath);
    } else if (rewrittenMetadataURL.indexOf('catalog/') !== -1) {
      rewrittenMetadataURL = rewrittenMetadataURL.replace('catalog/', this._config.downloadService.geonetworkPath);
    } else if (rewrittenMetadataURL.indexOf('geonetwork/ISOAP') !== -1) {
      rewrittenMetadataURL = rewrittenMetadataURL.replace('geonetwork/ISOAP', this._config.downloadService.geonetworkPath);
    } else if (rewrittenMetadataURL.indexOf('geonetwork/INSPIRE') !== -1) {
      rewrittenMetadataURL = rewrittenMetadataURL.replace('geonetwork/INSPIRE', this._config.downloadService.geonetworkPath);
    } else if (rewrittenMetadataURL.indexOf('csw/ISOAP') !== -1) {
      rewrittenMetadataURL = rewrittenMetadataURL.replace('csw/ISOAP', this._config.downloadService.geonetworkPath);
    } else if (rewrittenMetadataURL.indexOf('csw/INSPIRE') !== -1) {
      rewrittenMetadataURL = rewrittenMetadataURL.replace('csw/INSPIRE', this._config.downloadService.geonetworkPath);
    }
    this.model.set('metadataURL', rewrittenMetadataURL + this._config.downloadService.geonetworkOutputSchema);
  },

  _updateGeonetworkUrl() {
    const lang = window.portalLang;
    const metadataUrl = this.model.get('metadataURL');
    if (metadataUrl) {
      const parsedMetadata = metadataUrl.match(/.*Id=(.*).xml.*/);
      if (parsedMetadata && parsedMetadata.length > 1) {
        const metadataId = `${parsedMetadata[1]}.xml`;
        this.geonetworkUrl = this._config.downloadService.catalogSearch
          .replace('{{lang}}', lang === 'fr' ? 'fre' : 'eng') + metadataId;
      }
    }
  },

  _loadModalMetadata(lang) {
    if (this.model.get('metadataURL')) {
      let metadataUrl = `${this.model.get('metadataURL')}&LANG=${lang.toUpperCase()}`;
      if (lang === 'fr') {
        metadataUrl = metadataUrl.replace('srv/eng', 'srv/fre');
      } else {
        metadataUrl = metadataUrl.replace('srv/fre', 'srv/eng');
      }
      return this._metadataRetriever.load(metadataUrl, lang);
    }
  },

  _updatePortRefUrl() {
    const portRef = this.model.get('portRef');
    const currentPm = this.model.get('currentPm');
    if (!portRef || !currentPm) {
      return $.i18n.t('oceano.toaster.atlas.portRef_not_found');
    }
    return this._config.atlas.portRefUrl
      .replace('{{maree}}', this._config.allSites.maree)
      .replace('{{harborName}}', portRef)
      .replace('{{currentDate}}', currentPm.format(this._config.atlas.portRefDateFormat));
  },

  render() {
    const lang = window.portalLang;
    // we have to update metadataURL for geonetwork url
    if (!this._modelMetadata && this.model.get('metadataURL')) {
      this._updateGeonetworkUrl();
      this.rewriteUrl();

      this._metadataRetriever.load(this.model.get('metadataURL'))
        .then(metadata => {
          this._modelMetadata = metadata;
          this.render();
        })
        .fail(() => this._modelMetadata = null);
    }

    let layerTitle;
    if (this.model.get('layerType') === 'NCWMS') {
      layerTitle = this._isAtlas ? AtlasHelper.getLayerTitleAtlas(this.model, lang) : OceanoLayerUtils.getLayerTitle(this.model, lang);
    } else {
      layerTitle = lang !== 'fr' && this.model.get('englishName') || this.model.get('title');
    }

    this._updateModalMetadata(lang);

    this.$el.html(template({
      layer: this.model.toJSON(),
      title: layerTitle,
      legendIsImage: this.model.get('legendUrl') && this.model.get('legendUrl').match(this.imgRegex),
      metadata: this._modelMetadata,
      metadataURL: this.geonetworkUrl,
      isOceanoLayer: this.model.get('layerType') === 'NCWMS',
      isAtlasLayer: this._isAtlas,
      portRefUrl: this._updatePortRefUrl(),
      portRef: this.model.get('portRef'),
      pmBmLabel: this.model.get('isBm') ? 'BM' : 'PM',
      pmBmValue: this.model.get('currentPm') ? this.model.get('currentPm').format('HH:mm') : '',
      coeff: this.model.get('currentCoeff')
    }));
    this._$layerTitle = this.$('.layer-title');
    this._$limitations = this.$('.limitations-list');

    this._updateAtlasSection();

    this._updateRender();
    const abstractContent = (this.model.get('metadataURL') && this._modelMetadata && this._modelMetadata.abstract) ? this._modelMetadata.abstract : this.model.get('abstract');
    this.$('.layer-abstract').html(abstractContent);

    return this;
  },

  _updateAtlasSection() {
    if (!this._isAtlas) {
      return;
    }

    const _$pm = this.$('.atlas-infos-pm');
    const _$coeff = this.$('.atlas-infos-coeff');
    const _$portrefLink = this.$('.portref-link');
    if (!_$pm || !_$coeff || !_$portrefLink) {
      return;
    }

    // if atlas, first retrieve result from spm then call line below with result given (ex: 01-01-1950T08:45)
    const oceaLayerUTC = +this._gisView.getGlobalOceanoCurrentUTC() || 0;
    const oceaLayerMoment = this._gisView.getOceanoLayerCurrentMoment(this.model);

    this._getDiffFromNearestPm(this.model.get('portRef'), oceaLayerUTC, oceaLayerMoment, this.model.get('isBm'))
      .then(data => {
        this.model.set('currentCoeff', +data.coeff);
        this.model.set('currentPm', moment(data.closestDateTime));

        // keep references below otherwise not displayed because DOM loss
        const _$pm = this.$('.atlas-infos-pm');
        const _$coeff = this.$('.atlas-infos-coeff');
        if (!_$pm || !_$coeff) {
          return;
        }
        _$pm.html(this.model.get('currentPm').format('HH:mm'));
        _$coeff.html(this.model.get('currentCoeff'));

        // update port ref link url
        _$portrefLink.attr('href', this._updatePortRefUrl());
      })
      .fail(err => console.error(err));
  },

  _getDiffFromNearestPm(harborName, utc, datetime, isBm) {
    return this._ddmDataUtils.getDiffFromNearestPm(harborName, utc, datetime.format(this._spmDiffFromNearestPmFormat), isBm);
  },

  _onBackButtonClick() {
    this._router.navigate(this._backRoute, true);
  },

  _openCurrentLayerLegend() {
    this._router.navigate(`donnees/legend/${this.model.get('identifier')}`, true);
  },

  _updateRender() {
    // Get the abstract data
    let abstractContent;
    if (this.model.get('metadataURL') && this._modelMetadata && this._modelMetadata.abstract) {
      abstractContent = this._modelMetadata.abstract;
    } else {
      abstractContent = this.model.get('abstract');
    }

    // Get the limitations
    let limitationsContent = '';
    if (this._modelMetadata) {
      this._modelMetadata.limitations.forEach(limitation => {
        limitationsContent += `<li> ${limitation} </li>`;
      });
    }
    this._$limitations.html(limitationsContent);
    this.$el.find('.layer-abstract').html(abstractContent);
  },

  _updateModalMetadata(lang) {
    this._modelMetadata = lang === 'fr' ? this._modelMetadataFR : this._modelMetadataEN;

    const isFrench = lang === 'fr';
    if (this.geonetworkUrl) {
      this.geonetworkUrl = isFrench ? this.geonetworkUrl.replace('srv/eng', 'srv/fre') : this.geonetworkUrl.replace('srv/fre', 'srv/eng');
    }
  },

  postTranslate(lang) {
    let translatedTitle = '';
    this._updateModalMetadata(lang);
    this._updateRender();
    this.$('.metadata-link').attr('href', this.geonetworkUrl);

    if (this.model.get('layerType') === 'NCWMS') {
      translatedTitle = this._isAtlas ? AtlasHelper.getLayerTitleAtlas(this.model, lang) : OceanoLayerUtils.getLayerTitle(this.model, lang);
    } else {
      const englishName = this.model.get('englishName');
      if (!englishName) {
        return;
      }
      translatedTitle = lang === 'fr' ? this.model.get('title') : englishName;
    }
    this._$layerTitle.text(translatedTitle);
  }
});
