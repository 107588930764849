const $ = require('jquery');
const _ = require('underscore');

let IS_INIT = false;

const SPM = {
  _url: {},
  _config: {},

  url(action) {
    return this._config.tfs.url + this._url[action];
  },

  asGETParams(data) {
    return _.chain(data)
      .pairs()
      .map(pair => pair.join('='))
      .value()
      .join('&');
  },

  listHarbors() {
    const defer = $.Deferred();

    $.get(this.url('listHarbors')).then(data => {
      defer.resolve($(data));
    }, () => {
      defer.reject(0);
    });

    return defer;
  },

  mas(data, user) {
    const defer = $.Deferred();

    let url = data['constant-type'] === 'vintage'
      ? this.url('mas')
      : this.url('masPremium');

    const params = this.asGETParams(data);

    url += `?${params}`;

    const req = user.doReq({
      method: 'POST',
      url,
      contentType: 'application/json'
    });

    req.then(result => {
      defer.resolve(result);
    }, err => {
      defer.reject(err.responseText.split('\n'));
    });

    return defer;
  },

  fromCH(data, user) {
    const defer = $.Deferred();

    let url = this.url('predictionFromCH');

    const file = data.cst;
    data.cst = null;

    const params = this.asGETParams(data);

    url += `?${params}`;

    const form = new FormData();
    form.append('file', file);

    const req = user.doReq({
      method: 'POST',
      url,
      data: form,
      contentType: false,
      processData: false
    });

    req.then(result => {
      defer.resolve(result);
    }, err => {
      defer.reject((err && err.responseText) ? err.responseText.split('\n') : err);
    });

    return defer;
  },

  harmonicAnalysis(data, user) {
    const defer = $.Deferred();
    let url = this.url('harmonicAnalyze');
    const { file } = data;
    data.file = null;

    const params = this.asGETParams(data);

    url += `?${params}`;

    const form = new FormData();
    form.append('file', file);

    const req = user.doReq({
      method: 'POST',
      url,
      data: form,
      contentType: false,
      processData: false
    });

    req.then(result => {
      defer.resolve(result);
    }, err => {
      defer.reject(err.responseText.split('\n'));
    });

    return defer;
  },

  fromPoint(data, user) {
    const defer = $.Deferred();

    let url = this.url('predictionFromPoints');

    const params = this.asGETParams(data);

    url += `?${params}`;

    const req = user.doReq({
      method: 'POST',
      url,
      contentType: 'application/json'
    });

    req.then(result => {
      defer.resolve(result);
    }, err => {
      defer.reject(err.responseText.split('\n'));
    });

    return defer;
  },

  nearestLocalities(data, user) {
    const url = `${this.url('getNearestLocalities')}?${
      [['latitude', data.latitude].join('='),
        ['longitude', data.longitude].join('=')].join('&')}`;

    return user.doReq(url);
  },

  liveSPM(data) {
    const promise = $.Deferred();
    let url = this._config.ddm.spm.endpoint;
    url += this._config.defaultKey;
    url += this._config.ddm.spm.ddm_endpoint;
    const params = this.asGETParams(data);
    url += `?${params}`;
    $.get(url).then(responseData => {
      promise.resolve(responseData);
    }, () => {
      // not reject to draw sos without spm
      promise.resolve(null);
    });

    return promise;
  },

  diffFromNearestPm(data) {
    const promise = $.Deferred();
    const { apiKey } = this._config.ddm.spm;
    const params = this.asGETParams(data);
    let url = this._config.ddm.spm.endpoint + this._config.ddm.spm.diffFromNearestPmUrl;
    url = url.replace('{{apiKey}}', apiKey);
    url = url.replace('{{params}}', params);
    $.get(url).then(responseData => {
      promise.resolve(responseData);
    }, () => {
      promise.resolve(null);
    });
    return promise;
  }
};

module.exports = function (options = {}) {
  const defer = $.Deferred();
  const config = options.config || window.CONFIG;

  if (IS_INIT) {
    defer.resolve(SPM);
  } else {
    $.get(`${config.tfs.url}/config`).then(data => {
      SPM._url = data;
      SPM._config = config;
      IS_INIT = true;
      defer.resolve(SPM);
    }, () => {
      defer.reject(0);
    });
  }

  return defer;
};
