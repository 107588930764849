import { Deferred, i18n } from 'jquery';

const Dialog = require('bootstrap-dialog');
const WFSService = require('../../service/wfs.js');

export default class OceanogrammeUtils {
  constructor(config = {}) {
    if (OceanogrammeUtils.instance) {
      return OceanogrammeUtils.instance;
    }
    OceanogrammeUtils.instance = this;
    this._config = config || window.CONFIG;
    this._loadWfsServiceInstance(this._config);
    return this;
  }

  isInLand(lon, lat) {
    const promise = Deferred();
    this._wfsService.getFeaturesCountOnPoint(lon, lat).then(featuresCount => {
      if (featuresCount) {
        Dialog.show({
          type: Dialog.TYPE_DANGER,
          title: 'Attention',
          message: i18n.t('oceanogramme.inlands')
        });
        promise.reject(false);
        return;
      }
      promise.resolve(true);
    });
    return promise;
  }

  _loadWfsServiceInstance(config = {}) {
    if (!this._wfsService) {
      this._wfsService = new WFSService({
        getFeatureUrl: config.oceanogramme.getFeatureUrl,
        key: config.defaultKey,
        landsLayer: config.oceanogramme.landsLayer
      });
    }
    return this._wfsService;
  }
}
