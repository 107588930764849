const $ = require('jquery');

const CartodynStats = {
  _url: {},

  cartodynHit(key) {
    const defer = $.Deferred();

    const cartodynLog = this.config.cartodynUrl + key;

    $.get(cartodynLog)
      .then(() => {
        defer.resolve();
      })
      .fail(() => {
        defer.reject();
      });

    return defer;
  }

};

module.exports = function (options) {
  const service = Object.create(CartodynStats);
  service.config = {};
  service.config.cartodynUrl = options.cartodynUrl;
  return service;
};
