var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"col-sm-2 "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isHidden") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":35},"end":{"line":51,"column":69}}})) != null ? stack1 : "")
    + "\">\n                <div class=\"checkbox row\">\n                  <label>\n                    <input type=\"checkbox\" name=\"source"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\"/>\n                    <span data-i18n="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "></span>\n                  </label>\n                </div>\n              </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n	<div class=\"col-sm-12\">\n    <div class=\"panel panel-default\">\n        <div class=\"panel-heading\"><span data-i18n=\"ddm.dl.controls\"></span></div>\n        <div class=\"panel-body\">\n\n          <div class=\"row text-center\">\n            <span id=\"warning\" data-i18n=\"ddm.dl.warning\"></span>\n          </div>\n          <div class=\"addOneLineSpace\"></div>\n          <div id=\"get-all-data-container\" class=\"disabled\" data-i18n=\"[title]ddm.dl.allDataDisabled\">\n              <input type=\"checkbox\" id=\"get-all-data\" name=\"get_all_data\" value=\"1\" disabled=\"disabled\" />\n              <label for=\"get-all-data\" data-i18n=\"ddm.dl.fulldownload\"></label>\n          </div>\n          <div class=\"addOneLineSpace\"></div>\n          <div class=\"ddm-dl-params row\">\n            <label for=\"startDate\" class=\"col-sm-1 control-label\"\n                data-i18n=\"ddm.labels.startDate\"></label>\n            <div class=\"col-sm-2 input-cont\">\n              <input id=\"startdate\" name=\"startDate\" class=\"form-control pickdate\"\n                  value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"startDate") : depth0), depth0))
    + "\"/>\n            </div>\n\n            <label for=\"endDate\" class=\"col-sm-1 col-md-offset-1 control-label\"\n                data-i18n=\"ddm.labels.endDate\"></label>\n            <div class=\"col-sm-2 input-cont\">\n              <input id=\"enddate\" name=\"endDate\" class=\"form-control pickdate\"\n                  value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"endDate") : depth0), depth0))
    + "\"/>\n            </div>\n\n            <label for=\"dl-type\" class=\"col-sm-1 col-md-offset-1 control-label\"\n                  data-i18n=\"ddm.labels.type\"></label>\n            <div class=\"col-sm-2 input-cont\">\n              <select name=\"type\" id=\"dl-type\" class=\"form-control\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"types") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":37,"column":25}}})) != null ? stack1 : "")
    + "              </select>\n            </div>\n          </div>\n          <div class=\"addOneLineSpace\"></div>\n\n          <div class=\"row\">\n            <label for=\"source\" class=\"col-sm-12 control-label\"\n                  data-i18n=\"ddm.labels.source\"></label>\n            <div id=\"error\" data-i18n=\"ddm.dl.error.src\"></div>\n          </div>\n\n          <div class=\"row\" id=\"row-for-selects\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"sources") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":12},"end":{"line":59,"column":21}}})) != null ? stack1 : "")
    + "          </div>\n\n        </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});