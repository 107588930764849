import { asString } from 'ol/color.js';

const string_utils = require('./string');

const decimalRegex = /^[\s+]?([0-9]*)[\s+]?/i;
const rgbaRegex = /^r?g?b?a?[\s+]?\(?[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?([0-9]*\.?[0-9]*)/i;
const hexRegex = /^#([A-Fa-f0-9]{2})([A-Fa-f0-9]{2})([A-Fa-f0-9]{2})([A-Fa-f0-9]{2})?$/i;

const ColorsHelper = {
  // rgba(25, 25, 43, 0.5)
  getAlphaFromRgbaColor(rgbaString) {
    const match = rgbaString.match(rgbaRegex);

    return (match && match.length === 5 && match[4])
      ? +match[4]
      : undefined;
  },

  // rgba(25, 25, 43, 0.5)
  getAlphaFromHexColor(hexString) {
    const match = hexString.match(hexRegex);

    return (match && match.length === 5 && match[4])
      ? (parseInt(match[4], 16) / 255.0).toFixed(2)
      : undefined;
  },

  /**
   * Transform RGBA string color into hexadecimal string color
   *
   * @param rgbaString like 'rgba(0,0,0,1)' or 0,0,0,1
   * @return {string|undefined} Color hexa value like '#524685'
   */
  getHexColorFromRgbaColor(rgbaString) {
    const match = rgbaString.match(rgbaRegex);

    return (match && match.length === 5) ? (`#
      ${(`0${parseInt(match[1], 10).toString(16)}`).slice(-2)}
      ${(`0${parseInt(match[2], 10).toString(16)}`).slice(-2)}
      ${(`0${parseInt(match[3], 10).toString(16)}`).slice(-2)}
      ${(`0${Math.round(parseFloat(match[4]).toFixed(2) * 255).toString(16)}`).slice(-2)}`).replace(/(\r\n|\n|\r|\s)/gm, '').trim()
      : undefined;
  },

  /**
   * Transform decimal color into hexadecimal string color
   *
   * @param decimal Decimal color
   * @return {string} Color hexa value like '#524685'
   */
  getHexColorFromDecimalColor(decimal) {
    const decimalAsInteger = Number.isNaN(parseInt(decimal, 10)) ? decimal : parseInt(decimal, 10);
    return `#${string_utils.pad(decimalAsInteger.toString(16), 6)}`;
  },

  getDecimalColorFromRgbaColor(rgbaString) {
    const hexString = this.toHexColor(rgbaString);

    return this.getDecimalColorFromHexColor(hexString);
  },

  getDecimalColorFromHexColor(hexString) {
    return parseInt(hexString.substr(1, 6), 16).toString(10);
  },

  getRgbaColorFromHexColor(hexString, alpha = 1) {
    const match = hexString.match(hexRegex);
    if (match && match.length === 5 && match[3]) {
      const red = parseInt(match[1], 16);
      const green = parseInt(match[2], 16);
      const blue = parseInt(match[3], 16);

      return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    }

    return undefined;
  },

  /**
   * Check if string is a decimal color
   *
   * @param string
   * @return {boolean} true if it is
   */
  isDecimalColor(string) {
    return (string.match !== undefined && string.match(decimalRegex) !== null);
  },

  /**
   * Check if string is a RGBA formatted color
   *
   * @param string
   * @return {boolean} true if it is
   */
  isRgbaColor(string) {
    return (string.match !== undefined && string.match(rgbaRegex) !== null);
  },

  /**
 * Check if string is a Hexadécimal color
 *
 * @param string
 * @return {boolean} true if it is
 */
  isHexColor(string) {
    return (string.match !== undefined && string.match(hexRegex) !== null);
  },

  getRgbaColorFromRgba(rgba) {
    return asString(rgba);
  },

  toHexColor(color, withAlpha = true) {
    color = color.toString();
    let returnColor = null;
    if (this.isHexColor(color)) {
      returnColor = color;
    } else if (this.isRgbaColor(color)) {
      returnColor = this.getHexColorFromRgbaColor(color);
    } else if (this.isDecimalColor(color)) {
      returnColor = `${this.getHexColorFromDecimalColor(color)}ff`;
    }

    if (returnColor && !withAlpha) {
      returnColor = returnColor.substring(0, 7);
    }

    return returnColor;
  }
};

module.exports = ColorsHelper;
