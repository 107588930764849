import Select from 'ol/interaction/Select';

import {
  Fill, RegularShape, Stroke, Style
} from 'ol/style.js';

const GisView = require('../gis.view.js');

const msPerDay = 86400000;

/**
 * Add the copernicus layer and interaction to map
 *
 * @param name technical name of the layer
 */
GisView.prototype.initCopernicusGis = function (name) {
  this.loadLayerSpinner(name);
  this.initAndLoadLayerStyle(name);
  this.createCopernicusBuoyInteraction(name);
};

/**
 * Remove the copernicus layer and interaction from map
 *
 * @param name technical name of the layer
 */
GisView.prototype.removeCopernicusGis = function (name) {
  this.removeCopernicusBuoyInteraction(name);
  this.loadLayer(name, false);
};

/**
 * Set style function to use for the a copernicus layer
 *
 * @param name technical name of the layer
 */
GisView.prototype.initAndLoadLayerStyle = function (name) {
  const layer = this.collection.findWhere({ identifier: name });

  if (name === this._config.copernicus.layerDescWaves.name) {
    layer.get('olLayer').setStyle(this._createCopernicusBuoyStyleFunction.bind(this, this._config.copernicus.layerDescWaves));
  } else if (name === this._config.copernicus.layerDescTemp.name) {
    layer.get('olLayer').setStyle(this._createCopernicusBuoyStyleFunction.bind(this, this._config.copernicus.layerDescTemp));
  } else if (name === this._config.copernicus.layerDescSalinity.name) {
    layer.get('olLayer').setStyle(this._createCopernicusBuoyStyleFunction.bind(this, this._config.copernicus.layerDescSalinity));
  }
};

/**
 * Styling function for copernicus buoy feature
 *
 * @param layerDesc the layer description from configuration, containing :
 *          regularShapePoints, regularShapeRadius, regularShapeRotation
 * @param feature the feature to style
 * @return the constructed style
 */
GisView.prototype._createCopernicusBuoyStyleFunction = function (layerDesc, feature) {
  // Choose buoy color style depending on last observation date
  const updateMsAge = Date.now() - Date.parse(feature.get(this._config.copernicus.lastObservationDateField));

  let buoyFillColor; let
    buoyStrokeColor;
  if (updateMsAge <= (this._config.copernicus.buoyStyle.maxDayTimeForUpToDate * msPerDay)) {
    buoyFillColor = this._config.fillColorOk;
    buoyStrokeColor = this._config.strokeColorOk;
  } else if (updateMsAge <= (this._config.copernicus.buoyStyle.maxDayTimeForMiddleDated * msPerDay)) {
    buoyFillColor = this._config.fillColorPb;
    buoyStrokeColor = this._config.strokeColorPb;
  } else {
    buoyFillColor = this._config.fillColorKo;
    buoyStrokeColor = this._config.strokeColorKo;
  }

  const buoyStrokeWidth = this._config.copernicus.buoyStyle.strokeWidth;

  return [
    new Style({
      image: new RegularShape({
        fill: new Fill({
          color: buoyFillColor
        }),
        stroke: new Stroke({
          color: buoyStrokeColor,
          width: buoyStrokeWidth
        }),
        points: layerDesc.regularShapePoints,
        radius: layerDesc.regularShapeRadius,
        rotation: layerDesc.regularShapeRotation
      })
    })
  ];
};

/**
 * Create and store the copernicus buoy click interaction if it doesn't exist for now
 *
 * @param name Copernicus layername
 */
GisView.prototype.createCopernicusBuoyInteraction = function (name) {
  if (!this._interactiveCopernicusLayers) {
    this._interactiveCopernicusLayers = [];
  }

  if (!this._interactiveCopernicusLayers.length) {
    this._copernicusBuoyInteraction = new Select();
    this._copernicusBuoyInteraction.on('select', e => {
      e.preventDefault();
      const feature = e.target.getFeatures().getArray()[0];
      if (feature && feature.get('buoy_code')) {
        this._copernicusBuoyInteraction.getFeatures().clear();
        this._router.navigate(`oceanogramme/buoy/${feature.get('buoy_code')}`, true);
      }
    });

    this._map.addInteraction(this._copernicusBuoyInteraction);
  }

  if (!this._interactiveCopernicusLayers.includes(name)) {
    this._interactiveCopernicusLayers.push(name);
  }
};

/**
 * Remove the copernicus buoy click interaction if no more layer are concerned
 *
 * @param name Copernicus layername
 */
GisView.prototype.removeCopernicusBuoyInteraction = function (name) {
  if (!this._interactiveCopernicusLayers) {
    this._interactiveCopernicusLayers = [];
  }

  if (this._interactiveCopernicusLayers.includes(name)) {
    this._interactiveCopernicusLayers = this._interactiveCopernicusLayers
      .filter(layername => (layername !== name));
  }

  if (!this._interactiveCopernicusLayers.length) {
    this._map.removeInteraction(this._copernicusBuoyInteraction);
    this._copernicusBuoyInteraction.dispose();
  }
};
