const LegendPreview = require('./legend-preview.js');
const styleHelper = require('../../../utils/gis/drawing/style-helper.js');

const pointPreviewRadius = 8;

module.exports = LegendPreview.build({
  render() {
    this._style = this._feature.get('scStyle') || styleHelper.getStyle(this._feature);
    const imageStyle = this._style.getImage();
    this.clear();

    if (imageStyle) {
      if (imageStyle.getPoints) {
        this.renderRegularShape(
          imageStyle,
          imageStyle.getPoints(),
          pointPreviewRadius,
          // Rotation is applied to make the base of the shape parallel from the ground
          (Math.PI / 2) + (imageStyle.getPoints() % 2 === 0 ? Math.PI / imageStyle.getPoints() : 0)
        );
      } else {
        this.renderIcon(imageStyle);
      }
    }

    return this;
  }
});
