const $ = require('jquery');
const ShomView = require('../../../core/shom-view');
const template = require('../../../../template/ddm/ddm-dl/ddm-dl-contact.hbs');
const cookies = require('../../../utils/cookies');

module.exports = ShomView.build({

  events: {
    'change input#ddm-dl-name': '_onNameChange',
    'change input#ddm-dl-mail': '_onMailChange'
  },

  initialize(options) {
    this._router = options.router || window.ROUTER;
    this.config = options.config || window.CONFIG;
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({}));

    this._inputName = this.$el.find('#ddm-dl-name');
    this._inputMail = this.$el.find('#ddm-dl-mail');
    this._checkboxContact = this.$el.find('#ddm-dl-contact');

    const contact = this._getContactSS();
    if (contact) {
      this._inputName.val(contact.name);
      this._onNameChange();
      this._inputMail.val(contact.email);
      this._onMailChange();
    }

    return this;
  },

  /** ***************************** */
  /** ****** GETTER FUNCTION ****** */
  /** ***************************** */

  getContacts() {
    const name = this._inputName.val();
    const mail = this._inputMail.val();
    const contact = this._checkboxContact.prop('checked');

    return {
      name,
      mail,
      isContact: contact
    };
  },

  /** ***************************** */
  /** ****** UTILS FUNCTION ****** */
  /** ***************************** */

  validate() {
    const name = this._inputName.val();
    const mail = this._inputMail.val();
    let message = '';

    if (!this._nameValidation(name)) {
      message += $.i18n.t('ddm.dl.alertForm.contact.name');
    }

    if (!this._mailValidation(mail)) {
      message += $.i18n.t('ddm.dl.alertForm.contact.mail');
    }

    return message;
  },

  _mailValidation(textValue) {
    if (textValue === '') {
      return false;
    }
    const reg = /^[a-z0-9]+([_|.|-]{1}[a-z0-9]+)*@[a-z0-9]+([_|.|-]{1}[a-z0-9]+)*[.]{1}[a-z]{2,6}$/i;
    return reg.test(textValue);
  },

  _nameValidation(textValue) {
    if (textValue === '') {
      return false;
    }
    const reg = new RegExp(/[A-Za-z0-9]+/);
    return reg.test(textValue);
  },

  saveContactSS() {
    const contact = {
      name: this._inputName.val(),
      email: this._inputMail.val()
    };

    // 60*60*1000 => 1h
    cookies.setItem('ddm-contact', JSON.stringify(contact), 60 * 60 * 1000);
  },

  _getContactSS() {
    const contact = cookies.getItem('ddm-contact');
    return JSON.parse(contact);
  },

  /** ***************************** */
  /** ****** EVENTS FUNCTION ****** */
  /** ***************************** */

  _onNameChange() {
    const name = this._inputName.val();
    const $container = this._inputName.parent();
    if (this._nameValidation(name)) {
      $container.removeClass('has-error');
      $container.addClass('has-success');
    } else {
      $container.removeClass('has-success');
      $container.addClass('has-error');
    }
  },

  _onMailChange() {
    const mail = this._inputMail.val();
    const $container = this._inputMail.parent();
    if (this._mailValidation(mail)) {
      $container.removeClass('has-error');
      $container.addClass('has-success');
    } else {
      $container.removeClass('has-success');
      $container.addClass('has-error');
    }
  }

});
