const ShomView = require('../core/shom-view');
const UserMenuView = require('./user-menu.view');
const template = require('../../template/top-account-menu.hbs');

module.exports = ShomView.build({

  className: 'shom-left-menu-container',

  initialize(options = {}) {
    this.currentMenuView = options.currentMenuView;
    this.drawListContent = options.drawList;
  },

  render() {
    this.$el.html(template());

    this._$topMenuContentAccount = this.$('#top-account-menu');
    this._$topDrawContainer = this.$('#top-draw-container');

    return this;
  },

  openMenu(section) {
    this.currentMenuView && this.currentMenuView.remove();

    if (typeof section === 'function') {
      this.currentMenuView = this._fromFactory(section);
    } else {
      this.currentMenuView = this._fromMenuName(this.drawListContent);
    }

    this._$topMenuContentAccount.html(this.currentMenuView ? this.currentMenuView.render().$el : '');
    return this.currentMenuView;
  },

  setDrawList(drawList) {
    this._$topDrawContainer.html(drawList);
  },

  _fromFactory: factory => factory.call(),

  _fromMenuName(drawList) {
    return new UserMenuView({
      drawList
    });
  }

});
