import {LAYERTYPE_COPERNICUS, LAYERTYPE_REFMAR} from '../../utils/constants';

const $ = require('jquery');
const _ = require('underscore');

const GisView = require('../gis.view.js');
const WmcContextHelper = require('../../utils/gis/context.js');
const Layer = require('../../model/layer');
const MatchMercatorHelper = require('../../utils/gis/match-mercator-helper.js');
const CoordsHelper = require('../../utils/gis/coordinates-helper.js');
const SensorBottomView = require('../ddm/sensor-bottom.view.js');
const OceanoLayerView = require('../catalog/oceano-layer.view');
const Dialog = require('bootstrap-dialog');
const NCWMSService = require('../../service/ncwms');
const OceanoNcwmsUtils = require('../../utils/oceano/oceano-ncwms');
const CapabilitiesLayersFactory = require('../../utils/gis/capabilities-layers-factory');
const OceanoJsonUtils = require('../../utils/oceano/oceano-json.js');
const LayersFactory = require('../../utils/layers-factory');

import {LAYERTYPE_FORECAST, NCWMS_LAYERTYPE_OCEANO} from '../../utils/constants';


let initialized = false;


const initialize = function(options) {
  if (initialized) {
    return;
  }
  initialized = true;

  options = options || this._options || {};
  this._eventBus = options.eventBus || window.EVENTBUS;
  this._config = options.config || window.CONFIG;
  this._user = options.user || window.ROUTER.user;
  this._layers = options.layers || window.LAYERS;
  this._wmcHelper = options.wmcHelper || new WmcContextHelper({ user: this._user });
  this._capabilitiesLayersFactory = options.capabilitiesLayersFactory || new CapabilitiesLayersFactory({config: this._config});
  this._listOceanoArchiveLayers = [];
};

GisView.prototype.loadContext = function(wmcDocument, clear, skipBoundsUpdate, contextName, options={}, model=null) {
  initialize.call(this);
  clear = clear !== false;
  const context = this._wmcHelper.read(wmcDocument);
  if (!this._doesContextContainAnythingInteresting(context)) {
    return false;
  }
  clear && this.removeAllLayers();
  if (model) {
    model.layers = [];
  }
  _.each(context.layersContext, layerContext => {
    this._loadLayerFromContext(layerContext, context, contextName, options, model);
  });

  if (this._listOceanoArchiveLayers.length) {
    if (!!window.ncwmsLayersAvailable) {
      this._addOceanoArchives(context, options);
    } else {
      this.listenTo(this._eventBus, 'build:oceano', this._addOceanoArchives.bind(this, context, options));
    }
  }

  if(!skipBoundsUpdate) {
    this._zoomToContextBound(context);
  }

  return true;
};

GisView.prototype._zoomToContextBound = function(context) {
  if(context.bounds) {
    const mercBounds = (context.projection === 'EPSG:4326' || context.projection === 'CRS:84') ?
      CoordsHelper.convertLonLatBboxToMercator(context.bounds) :
      context.bounds;
    this.zoomToExtent(mercBounds);
  }
};

GisView.prototype.exportContext = function() {
  initialize.call(this);
  return this._wmcHelper.write(this._map, this._getDisplayedLayers().reverse());
};

GisView.prototype._loadLayerFromContext = function(layerContext, context, contextName, options, model) {
  layerContext.name = MatchMercatorHelper.getMercatorLayerName(layerContext.name) || layerContext.name;
  const layer = this.collection.findWhere({
    identifier: layerContext.name
  });

  this._ncwmsLayersAvailable = !!window.ncwmsLayersAvailable;
  this._ncwmsAtlasLayersAvailable = !!window.ncwmsAtlasLayersAvailable;

  if (!layer) {
    layerContext.transparent = true;

    if(layerContext.service === 'OGC:WMTS') {
      this._wmcHelper.instanciateWMTS(layerContext)
        .then(olLayer => {
            this._includeLayerFromContext(layerContext, null, olLayer, contextName);
        });
    } else if (~layerContext.url.indexOf(this._config.oceano.ncwms_cached) && !this._ncwmsLayersAvailable) {
      this.listenTo(this._eventBus, 'build:oceano', _.bind(this._addLayerNcwms, this, layerContext));
    }  else if (~layerContext.url.indexOf(this._config.atlas.ncwms_cached) && !this._ncwmsAtlasLayersAvailable) {
      this.listenTo(this._eventBus, 'build:atlas', _.bind(this._addLayerNcwms, this, layerContext));
    } else if(~layerContext.url.indexOf(this._config.oceano.ncwms_archive)) {
      // Add only if layersToKeep is not set or if layersToKeep contains this layer
      if(!options.layersToKeep || ~options.layersToKeep.indexOf(layerContext.name)){
        this._listOceanoArchiveLayers.push(layerContext);
      }
    } else {
      const olLayer = this._wmcHelper.instanciate(layerContext, context);
      if (model) {
        model.layers.push(olLayer);
      }
      this._includeLayerFromContext(layerContext, null, olLayer, contextName);
    }
    return;
  }
  this._includeLayerFromContext(layerContext, layer, contextName);
};

GisView.prototype._addLayerNcwms = function(layerContext) {
  this.collection.add(this._layers);
  const layer = this.collection.findWhere({ deliveryIdentifier: layerContext.name }) || this.collection.findWhere({ identifier: layerContext.name });
  if(layer){
    this._includeLayerFromContext(layerContext, layer);
    this._updateLayersIndex();
  } else {
    Dialog.show({
      type: Dialog.TYPE_DANGER,
      title: $.i18n.t('error.application.loading.title'),
      message: $.i18n.t('error.application.loading.message')
    });
  }
};

/**
 *
 * @param {object} context
 * @param {object} options
 * @private
 */
GisView.prototype._addOceanoArchives = function(context, options) {
  const includeLayersInMap = options.includeLayersInMap !== false;
  const listGetCapFormatedLayers = [];
  const oceanoCollection = LayersFactory.build();

  this._listOceanoArchiveLayers.forEach((layerContext) => {
    layerContext.layerType = 'NCWMS';
    listGetCapFormatedLayers.push({Layer: [
      this._wmcHelper.convertContextLayerToGetCapRawLayer(layerContext, context.bounds, context.projection)
    ]});
  });

  if(includeLayersInMap) {
    const baseLayer = this.collection.find(catalogLayer => catalogLayer.get('identifier') === this._config.baseLayer);
    baseLayer && baseLayer.set('includedInMap', true);
  }

  NCWMSService({ncwmsLayerType: NCWMS_LAYERTYPE_OCEANO}).then((ncwWMSService) => {
    this.ncwWMSService = ncwWMSService;
    return this._capabilitiesLayersFactory.createEachLayers(
      listGetCapFormatedLayers,
      'NCWMS',
      this._config.oceano.ncwms_archive,
      (this._listOceanoArchiveLayers[0] && this._listOceanoArchiveLayers[0].version) || '1.3.0',
      null,
      {isFromContextArchive: true}
    );
  }).then(layers => {
    oceanoCollection.reset(
      OceanoNcwmsUtils.buildNCWMSCollection(layers, true)
    );
    return this.ncwWMSService.getDeliveriesJSON();
  }).then(deliveriesJSON => {
    OceanoJsonUtils.setParamsFromDeliveriesJSON(
      oceanoCollection,
      deliveriesJSON
    );
    this._addArchiveLayersInCollectionAndMap(oceanoCollection, deliveriesJSON, includeLayersInMap);
    // calls result function with list of imported layers
    options.resultCallback && options.resultCallback(oceanoCollection);
  });
};

GisView.prototype._addArchiveLayersInCollectionAndMap = function(oceanoCollection, deliveriesJSON, includeLayersInMap) {
  oceanoCollection.each(layer => {
    layer.set({
      service: this._listOceanoArchiveLayers[0].service,
      external: true,
      isArchive: true
    });
    this.collection.add(layer);
    if(includeLayersInMap) {
      this._includeLayerFromContext({}, layer);
    }
  });
};


GisView.prototype._includeLayerFromContext = function(layerContext, layer, olLayer, contextName) {
  if(layerContext.url && layerContext.url.includes(this._config.base.services + '/ncwms') && olLayer) {
    layer = this.collection.findWhere({deliveryIdentifier: layerContext.name});
  } else if (olLayer) {
    layer = new Layer({
      identifier: layerContext.name,
      service: layerContext.service,
      title: olLayer.get('title'),
      external: true,
      layerType: layerContext.layerType || 'PARTNER',
      url: layerContext.url,
      queryable: layerContext.queryable,
      abstract: layerContext.abstract,
      metadataURL: layerContext.metadataURL,
      legendUrl: layerContext.legendUrl ?? (layerContext.legend ? layerContext.legend.href : null),
      olLayer,
      contextName
    });
    this.collection.add(layer);
  }

  layer.set('opacity', layerContext.opacity || 1);
  layer.set('visibility', true);
  if(layer.get('layerType') === LAYERTYPE_REFMAR){
    const sensorBottomView = new SensorBottomView({
      model: layer,
      network: layer.get('identifier').replace(/^REFMAR\//, '')
    });
    sensorBottomView.loadFeatures()
      .then(() => {
        sensorBottomView.addLayerFeatures();
        layer.set('includedInMap', true);
      });
  } else if (layer.get('layerType') === LAYERTYPE_FORECAST) {
    const forecastLayer = new OceanoLayerView({
      model: layer,
      oceano: true,
      searchView: true
    })
    forecastLayer.showForecastLayer();
    layer.set('includedInMap', true);
  } else if (layer.get('layerType') === LAYERTYPE_COPERNICUS) {
    this.initCopernicusGis(layer.get('name'));
  } else if (layer.get('title') !== this._drawingLayerTitle) {
    layer.set('includedInMap', true);
    this.zoomToLayerMaxExtent(layer);
  }
};

GisView.prototype._doesContextContainAnythingInteresting = function(context) {
  return context &&
    context.layersContext &&
    $.grep(_.pluck(context.layersContext, 'name'), function(n) {
      return (n);
    }).length > 0;
};
