const $ = require('jquery');
const _ = require('underscore');
const moment = require('moment');
const ShomView = require('../../../core/shom-view');
const template = require('../../../../template/ddm/ddm-detail/ddm-data-control.hbs');

const DDMDataUtils = require('../../../utils/ddm/ddm-data-utils');
const DDMSensorReader = require('../../../utils/ddm/ddm-sensor-reader');
const InfoPopover = require('../../info-popover.view');

const dateFormat = 'DD/MM/YYYY';
const dateTimeFormat = 'DD/MM/YYYY HH:mm';

const dateTimePickerOptionsDateOnly = {
  format: dateFormat,
  showClose: true,
  useCurrent: true,
  locale: $.i18n.lng()
};

const dateTimePickerOptionsDateTime = {
  format: dateTimeFormat,
  showClose: true,
  useCurrent: true,
  locale: $.i18n.lng(),
  sideBySide: true
};

module.exports = ShomView.build({

  events: {
    'click input[type="checkbox"]': '_onFormChange',
    'change select': '_onFormChange',
    'click .reset-parameters-btn': '_onResetButtonClick'
  },

  initialize(options) {
    this._router = options.router || window.ROUTER;
    this.config = options.config || window.CONFIG;
    this._eventBus = options.eventBus || window.EVENTBUS;

    this._parentView = options.parentView;
    this._sensorDescription = options.sensorDesc;
    this._colors = options.colors;

    this._setValuesFromOptions(options);

    this._utils = new DDMDataUtils(this.config);

    // we never can go over today + maxPeriod (31) days
    this._globalMaxDate = moment().utc().add(this._maxPeriod, 'days').hour(23)
      .minute(59)
      .second(59)
      .millisecond(999);

    this.isDisplayedHours = false;
    this.maxHours = this.config.ddm.maxHoursBeforeHidingTime;
    this.onDatePickerChangeCb = this._onFormChange.bind(this);

    this._eventBus.on('formChanged', _.bind(this._onFormChange, this));
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    const controls = this._initStaticControls();

    this.$el.html(template(controls));

    this._renderControls();

    this._renderInfoPopovers(
      [
        { view: this.surcoteInfoPopover, selector: '.surcote-info-button-root', message: 'ddm.popover.surcote' },
        { view: this.spmInfoPopover, selector: '.spm-info-button-root', message: 'ddm.popover.spm' },
        { view: this.predictionInfoPopover, selector: '.prediction-info-button-root', message: 'ddm.popover.source7' },
        { view: this.surcoteCalcInfoPopover, selector: '.surcoteCalc-info-button-root', message: 'ddm.popover.surcoteCalc' },
        { view: this.hthInfoPopover, selector: '.hth-info-button-root', message: 'ddm.popover.hth' },
        { view: this.npbmaInfoPopover, selector: '.npbma-info-button-root', message: 'ddm.popover.npbma' },
        { view: this.source1InfoPopover, selector: '.source1-info-button-root', message: 'ddm.popover.source1' },
        { view: this.source2InfoPopover, selector: '.source2-info-button-root', message: 'ddm.popover.source2' },
        { view: this.source3InfoPopover, selector: '.source3-info-button-root', message: 'ddm.popover.source3' },
        { view: this.source4InfoPopover, selector: '.source4-info-button-root', message: 'ddm.popover.source4' },
        { view: this.source5InfoPopover, selector: '.source5-info-button-root', message: 'ddm.popover.source5' },
        { view: this.source6InfoPopover, selector: '.source6-info-button-root', message: 'ddm.popover.source6' }
      ]
    );

    return this;
  },

  _renderInfoPopovers(popOverList) {
    popOverList.forEach(popover => {
      popover.view && popover.view.remove();
      popover.view = new InfoPopover({
        message: popover.message,
        placement: 'bottom',
        popoverClassName: 'ddm-popover'
      });
      this.$(popover.selector).html(popover.view.render().$el);
    });
  },

  _renderDateTimePickerControls() {
    this.$startDate = this.$("input[name='startDate']");
    this.$endDate = this.$("input[name='endDate']");
    this.$timezone = this.$("select[name='timezone']");
    this.$refVerticale = this.$("select[name='refverticale']");

    this.isDisplayedHours = this.isShowHours(moment.utc(this.$startDate.val(), dateFormat), moment.utc(this.$endDate.val(), dateFormat));

    const newOptions = this.isDisplayedHours ? dateTimePickerOptionsDateTime : dateTimePickerOptionsDateOnly;

    this.$startDate.datetimepicker(newOptions);
    this.$endDate.datetimepicker(newOptions);

    this.$endDate.data('DateTimePicker').minDate(this.$startDate.val());
    this.$endDate.data('DateTimePicker').maxDate(this._globalMaxDate.format('DD/MM/YYYY HH:mm'));
    this.$endDate.data('DateTimePicker').date(this._endDate.format('DD/MM/YYYY HH:mm'));

    this.$startDate.data('DateTimePicker').maxDate(this._globalMaxDate.format('DD/MM/YYYY HH:mm'));
    this.$startDate.data('DateTimePicker').date(this._startDate.format('DD/MM/YYYY HH:mm'));

    this.$startDate.on('dp.hide', this.onDatePickerChangeCb);
    this.$endDate.on('dp.hide', this.onDatePickerChangeCb);
  },

  _renderControls() {
    this._renderDateTimePickerControls();

    this._renderCRVControl();
  },

  _renderCRVControl() {
    this._verticalRef.zh_ref = (`${this._verticalRef.zh_ref}`).replace(/,/, '.');

    let options = '';
    if (this._verticalRef.zero_hydro === 'zero_hydrographique' && this._verticalRef.nom_ref !== undefined) {
      options += `<option value="0" selected="selected">${$.i18n.t('ddm.crv.hydrographicZero')}</option>`;
      options += `<option value="${this._verticalRef.zh_ref}">${this._verticalRef.nom_ref}</option>`;
      this.$refVerticale.html(options);
      this.$refVerticale.val(0);
    } else {
      let zeroLabel = 'ddm.crv.unknownZero';

      if (this._verticalRef.zero_hydro === 'zero_hydrographique') {
        zeroLabel = 'ddm.crv.hydrographicZero';
      } else if (this._verticalRef.zero_hydro === 'zero_hydrographique_provisoire') {
        zeroLabel = 'ddm.crv.provisionalZero';
      }
      options += `<option value="0">${$.i18n.t(zeroLabel)}</option>`;
      this.$refVerticale.html(options);
      this.$refVerticale.prop('disabled', true);
    }

    this._verticalRef = 0;
  },

  /** ***************************** */
  /** ******* EVENT FUNCTION ****** */
  /** ***************************** */

  _onFormChange(isUpdateDates = true, datesToSet = {}, sourcesToActivate = []) {
    if (datesToSet.startDate && datesToSet.endDate) {
      // not used of $startDate and $endDate because of reference issue while coming from trigger
      $("input[name='startDate']").data('DateTimePicker').date(moment.utc(datesToSet.startDate));
      $("input[name='endDate']").data('DateTimePicker').date(moment.utc(datesToSet.endDate));
    }

    if (sourcesToActivate.length > 0) {
      sourcesToActivate.forEach(source => {
        const sourceCheckbox = this.$(`input[name="source${source}"]`);
        const checked = sourceCheckbox.prop('checked');
        sourceCheckbox.prop('checked', !checked);
      });
    }
    const isDateChanged = isUpdateDates && this._updateDates();

    let isNewData = false;
    for (let i = 0; i < 10; i++) {
      const checkbox = this.$(`input[name='source${i + 1}']`)[0];
      const sourceActivated = checkbox && checkbox.checked;
      if (!this._selectedSources[i] && sourceActivated) {
        isNewData = true;
      }
      this._selectedSources[i] = sourceActivated;
      this._disabledSources[i] = checkbox && checkbox.disabled;
    }

    const needToGetData = isDateChanged || isNewData;
    const verticalRefChanged = true;
    this._verticalRef = this.$refVerticale.val();

    this.updateSourcesControl();

    const controls = {};
    controls.startDate = this._startDate;
    controls.endDate = this._endDate;
    controls.timezone = this._timezone;
    controls.selectedSources = this._selectedSources;
    controls.disabledSources = this._disabledSources;
    controls.surcoteEnabled = this._surcoteEnabled;
    controls.verticalRef = this._verticalRef;
    controls.needToGetData = needToGetData;
    controls.verticalRefChanged = verticalRefChanged;
    controls.npmaEnabled = this.$("input[name='sourcenpma']")[0].checked;
    controls.isDisplayedHours = this.isDisplayedHours;

    this._parentView.trigger('change:control', controls);
  },

  /** ***************************** */
  /** ****** UTILS FUNCTION ******* */
  /** ***************************** */

  isShowHours(startDate, endDate) {
    return this._utils.isShowHours(startDate, endDate);
  },

  _updateDates() {
    let isChanged = false;

    this.$startDate.off('dp.hide');
    this.$endDate.off('dp.hide');

    if (this._timezone !== this.$timezone.val()) {
      isChanged = true;
    }
    this._timezone = this.$timezone.val();

    const startInput = this.$startDate.val();
    const endInput = this.$endDate.val();

    let newStart = this._utils.setBeginTime(moment.utc(startInput, this.isDisplayedHours ? dateTimeFormat : dateFormat), 'UTC0', true);
    const newMaxDate = this._utils.getNewMaxDate(newStart, this._maxPeriod, this._globalMaxDate);
    let newEnd = this._utils.setEndTime(moment.utc(endInput, this.isDisplayedHours ? dateTimeFormat : dateFormat), 'UTC0', true, newStart, newMaxDate);
    const isHoursMustBeDisplayed = this._utils.isShowHours(newStart, newEnd);

    if (!isHoursMustBeDisplayed || (!this.isDisplayedHours && isHoursMustBeDisplayed)) {
      newStart = newStart.hour(0).minute(0).second(0).millisecond(0);
      newEnd = newEnd.hour(23).minute(59).second(59).millisecond(999);
    }

    if (!this.isDisplayedHours && isHoursMustBeDisplayed) {
      this.$startDate.data('DateTimePicker').options(dateTimePickerOptionsDateTime);
      this.$endDate.data('DateTimePicker').options(dateTimePickerOptionsDateTime);
      this.isDisplayedHours = true;
    } else if (this.isDisplayedHours && !isHoursMustBeDisplayed) {
      this.$startDate.data('DateTimePicker').options(dateTimePickerOptionsDateOnly);
      this.$endDate.data('DateTimePicker').options(dateTimePickerOptionsDateOnly);
      this.isDisplayedHours = false;
    }

    if (!newStart.isSame(this._startDate) || !newEnd.isSame(this._endDate)) {
      isChanged = true;

      if (newEnd.diff(this._endDate) < 0) {
        // if go back, update min then max
        this.$endDate.data('DateTimePicker').minDate(newStart.format('DD/MM/YYYY HH:mm'));
        this.$endDate.data('DateTimePicker').maxDate(newMaxDate.format('DD/MM/YYYY HH:mm'));
      } else {
        // else, update max then min
        this.$endDate.data('DateTimePicker').maxDate(newMaxDate.format('DD/MM/YYYY HH:mm'));
        this.$endDate.data('DateTimePicker').minDate(newStart.format('DD/MM/YYYY HH:mm'));
      }
      this.$endDate.data('DateTimePicker').date(newEnd.format('DD/MM/YYYY HH:mm'));

      this._startDate = newStart;
      this._endDate = newEnd;
    }

    this.$startDate.on('dp.hide', this.onDatePickerChangeCb);
    this.$endDate.on('dp.hide', this.onDatePickerChangeCb);

    return isChanged;
  },

  _addSourceControl(sourceId, isHidden) {
    if (sourceId === 0) {
      return {};
    }
    const indice = sourceId - 1;
    return {
      value: sourceId,
      label: `ddm.labels.source${sourceId}`,
      isDisabled: this._disabledSources[indice],
      isChecked: this._selectedSources[indice],
      color: this._colors[`source${sourceId}`],
      isHidden: isHidden === true
    };
  },

  _initStaticControls() {
    const sources = [];
    sources.push(this._addSourceControl(1));
    sources.push(this._addSourceControl(2));
    sources.push(this._addSourceControl(3));
    sources.push(this._addSourceControl(4));
    sources.push(this._addSourceControl(5));
    sources.push(this._addSourceControl(6));

    const npma = {
      isChecked: this._npmaIsEnabled
    };

    const spm = {
      isDisabled: this._disabledSources[6],
      isChecked: this._selectedSources[6],
      value: 7,
      color: this._colors.source7
    };

    const surcote = {
      isDisabled: this._disabledSources[7],
      isChecked: this._selectedSources[7],
      value: 8,
      color: this._colors.source8
    };

    const surcoteCalc = {
      isDisabled: this._disabledSources[8],
      isChecked: this._selectedSources[8],
      value: 9,
      color: this._colors.source9
    };

    const hth = {
      isDisabled: this._disabledSources[9],
      isChecked: this._selectedSources[9],
      value: 10,
      color: this._colors.source10
    };

    const timezones = [];
    const timezone = DDMSensorReader.getTimezone(this._sensorDescription);
    for (let i = -12; i < 15; i++) {
      let timezoneValue = `UTC${i}`;
      let defaultValue = false;

      let timezoneLabel = `UTC${i}`;
      if (i === 0) {
        timezoneLabel = 'UTC';
        defaultValue = true;
      }
      if (i > 0) {
        timezoneValue = `UTC+${i}`;
        timezoneLabel = `UTC+${i}`;
      }

      if (timezoneValue === timezone) {
        timezoneLabel = `${timezone} (${$.i18n.t('ddm.timezone.locale')})`;
      }
      timezones.push({ value: timezoneValue, label: timezoneLabel, default: defaultValue });
    }

    return {
      startDate: this._startDate.format('DD/MM/YYYY'),
      endDate: this._endDate.format('DD/MM/YYYY'),
      sources,
      spm,
      surcote,
      surcoteCalc,
      hth,
      npma,
      timezones
    };
  },

  updateSourcesControl(options) {
    if (options) {
      this._setValuesFromOptions(options);
    }

    const spm = this.$("input[name='source7']")[0];
    const surcote = this.$("input[name='source8']")[0];
    const surcoteCalc = this.$("input[name='source9']")[0];

    if (this._noSPMData) {
      spm.checked = false;
      spm.setAttribute('disabled', 'disabled');
      this._disabledSources[6] = true;
      this._selectedSources[6] = false;
      surcote.setAttribute('disabled', 'disabled');
      this._surcoteEnabled = false;
      this._disabledSources[7] = true;
      this._selectedSources[7] = false;
    } else {
      let nbSources = 0;
      for (let i = 0; i < 6; i++) {
        if (this._selectedSources[i]) {
          nbSources += 1;
        }
      }

      if (nbSources === 1) {
        this._disabledSources[7] = false;
        surcote.removeAttribute('disabled');

        this._surcoteEnabled = surcote.checked || surcoteCalc.checked;
      } else {
        this._surcoteEnabled = false;

        this._selectedSources[7] = false;
        this._disabledSources[7] = true;
        surcote.setAttribute('disabled', 'disabled');
      }

      if (!this._selectedSources[6] || this._disabledSources[7]) {
        // disable surcote if spm not checked
        this._selectedSources[7] = false;
        this._disabledSources[7] = true;
        surcote.setAttribute('disabled', 'disabled');
        this._surcoteEnabled = false;
      }
    }
  },

  setValuesAndRender(options) {
    this._setValuesFromOptions(options);
    this._renderControls();
  },

  _setValuesFromOptions(options) {
    this._timezone = options.timezone;
    this._disabledSources = options.disabledSources;
    this._selectedSources = options.selectedSources;
    this._startDate = options.startDate;
    this._endDate = options.endDate;
    this._maxPeriod = options.maxPeriod;
    this._CRV = options.CRV;
    this._verticalRef = options.verticalRef;
    this._noSPMData = options.noSPMData;
    this._npmaIsEnabled = options.npmaIsEnabled;
  },

  _onResetButtonClick() {
    this._parentView.trigger('reset:control');
  }
});
