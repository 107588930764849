const _ = require('underscore');
const CSWGetRecords = require('../mixins/openlayers/csw');

const Format = {};

Format.read = function (xml, language) {
  const lang = language || window.portalLang;
  const xmlIdentificationInfo = xml.querySelector && xml.querySelector('identificationInfo');

  if (!xmlIdentificationInfo) {
    return {
      contact: [],
      abstract: 'error',
      limitations: []
    };
  }

  const xmlPointOfContacts = xmlIdentificationInfo.querySelectorAll('pointOfContact');

  const xmlAbstract = xmlIdentificationInfo.querySelector('abstract');
  let abstractText;
  if (lang !== 'fr') {
    const xmlAbstractLocale = this._getLocalizedText(xmlAbstract, lang);
    if (xmlAbstractLocale) {
      abstractText = xmlAbstractLocale.textContent;
    } else {
      abstractText = xmlAbstract.querySelector('CharacterString').textContent;
    }
  } else {
    abstractText = xmlAbstract.querySelector('CharacterString').textContent;
  }

  const xmlResourceConstraints = xmlIdentificationInfo.querySelectorAll('resourceConstraints');

  const contacts = [];
  const limitations = [];

  _.each(xmlPointOfContacts, pointOfContact => {
    const orgNameNode = pointOfContact.querySelector('CI_ResponsibleParty organisationName CharacterString');
    const emailNode = pointOfContact.querySelector(
      'CI_ResponsibleParty contactInfo CI_Contact address CI_Address electronicMailAddress CharacterString'
    );

    const orgName = orgNameNode ? orgNameNode.textContent : '';
    const email = emailNode ? emailNode.textContent : '';

    contacts.push({
      name: orgName,
      email
    });
  });

  _.each(xmlResourceConstraints, resourceConstraint => {
    const $limitations = resourceConstraint.querySelectorAll('MD_Constraints useLimitation');
    _.each($limitations, limitation => {
      let limitationText;
      if (lang !== 'fr') {
        const localeLimitation = this._getLocalizedText(limitation, lang);
        if (localeLimitation) {
          limitationText = localeLimitation.textContent;
        } else {
          limitationText = limitation.querySelector('CharacterString').textContent;
        }
      } else {
        limitationText = limitation.querySelector('CharacterString').textContent;
      }
      limitations.push(limitationText);
    });
  });

  return {
    contact: contacts,
    abstract: abstractText,
    limitations
  };
};

Format.readGetRecordResponse = function (xml) {
  const format = new CSWGetRecords({
    version: '2.0.2_Shom'
  });

  return format.read(xml);
};

Format._getLocalizedText = function (startNode, lang) {
  const localeAbstracts = startNode.querySelectorAll('LocalisedCharacterString');
  for (const localeAbstract of localeAbstracts) {
    if (localeAbstract.getAttribute('locale') === `#${lang.toUpperCase()}`) {
      return localeAbstract;
    }
  }
};

module.exports = Format;
