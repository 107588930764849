const DEFAULT_TIME_TO_LIVE = 30 * 24 * 60 * 60 * 1000; // 30 days

const CookiesUtils = {};

CookiesUtils.setItem = function (name, value, ttl = DEFAULT_TIME_TO_LIVE) {
  if (navigator.cookieEnabled) {
    const expires = new Date();
    expires.setTime(expires.getTime() + ttl);
    document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires.toGMTString()}; path=/`;
    return true;
  }
  return false;
};

CookiesUtils.getItem = function (name) {
  const regex = new RegExp(`(?:; )?${name}=([^;]*);?`);
  if (regex.test(document.cookie)) {
    return decodeURIComponent(RegExp.$1);
  }
  return null;
};

CookiesUtils.remove = function (name) {
  document.cookie = `${name}=;expires=The, 01 Jan 1970 00:00:01 GMT; path=/`;
};

module.exports = CookiesUtils;
