import { SC_DRAWING_MODULE, SC_MEASUREMENT_MODULE } from '../utils/constants';

const $ = require('jquery');
const _ = require('underscore');
const platform = require('platform');
const ShomView = require('../core/shom-view');
const template = require('../../template/display-menu.hbs');
const chooseCoordView = require('./choose-coord-list.view.js');
const ToastrUtil = require('../utils/toastr');
const Loading = require('../utils/loading');
const GlobalDataStore = require('../core/global-data-store');

module.exports = ShomView.build({

  className: 'left-menu-content',

  events: {
    'click input:checkbox[name=display-reticle]': 'handlerGraticule',
    'click input:checkbox[name=display-overview]': 'handlerOverViewMap',
    'click input:checkbox[name=display-navigation]': 'handlerNavigation',
    'click .globe-mode-btn': '_toggleGlobeMode',
    'input #globe-range-resolution': '_handleResolution',
    'change #chooseCoord': 'chooseCoordinate',
    'click #close-display-menu': function(){this.onCloseMenuButtonClick(event, '.btn-affichage');},
    'blur .globe-mode-btn': function(){this.onLastMenuItemBlur(event, '#close-display-menu');}
  },

  initialize(options = {}) {
    this._router = options.router || window.ROUTER;
    this._gisView = options.gisView || window.GISVIEW;
    this._eventBus = options.eventBus || window.EVENTBUS;
    this._gfiModeManager = options.gfiModeManager || window.POI_MODE_MANAGER;
    this._displayOptions = this._gisView.getDisplayOptions();
    this._globeBtnTranslationKey = this._gisView.isGlobeEnabled()
      ? 'display.globe.action.toFlat' : 'display.globe.action.toGlobe';
    GlobalDataStore.set('globeResolution', GlobalDataStore.get('globeResolution') || 1);
  },

  updateOverviewMapCheckbox(checked) {
    const checkbox = this.$('input[type=checkbox][name=display-overview]');
    checkbox.prop('checked', checked);
  },

  updateNavigationCheckbox(checked) {
    const checkbox = this.$('input[type=checkbox][name=display-navigation]');
    checkbox.prop('checked', checked);
  },

  updateReticleCheckbox(checked) {
    const checkbox = this.$('input[type=checkbox][name=display-reticle]');
    checkbox.prop('checked', checked);
  },

  render() {
    this.$el.html(template({
      graticuleIsEnabled: this._displayOptions.graticule && !this._gisView.isGlobeEnabled(),
      globeBtnTranslationKey: this._globeBtnTranslationKey,
      resolution: {
        value: GlobalDataStore.get('globeResolution')
      },
      disableReticuleCheckbox: this._gisView.isGlobeEnabled()
    }));
    if (!this._$mapContainer) {
      this._$mapContainer = $('.map-container');
    }
    this._$modeGlobeBtn = this.$('.globe-mode-btn');
    const el = this.$el.find('#chooseCoord');
    this.coordView = new chooseCoordView({ el, gisView: this._gisView, eventsBus: this._eventsBus });
    this.coordView.render();

    this.updateOverviewMapCheckbox(this._displayOptions.overviewMap);
    this.updateNavigationCheckbox(this._displayOptions.navigation);
    this.updateReticleCheckbox(this._displayOptions.graticule);

    this.listenTo(this._eventsBus, 'enabled:overView', _.bind(this.updateOverviewMapCheckbox, this, true));
    this.listenTo(this._eventsBus, 'disabled:overView', _.bind(this.updateOverviewMapCheckbox, this, false));
    this._gfiModeManager.startGfiRequestableLayer(true);

    return this;
  },

  handlerGraticule() {
    if ($('input[type=checkbox][name=display-reticle]').is(':checked')) {
      this._gisView.addGraticule();
      this._displayOptions.graticule = true;
    } else {
      this._gisView.deleteGraticule();
      this._displayOptions.graticule = false;
    }
  },

  handlerOverViewMap() {
    const checked = $('input[type=checkbox][name=display-overview]').is(':checked');

    this._displayOptions.overviewMap = checked;
    this._executeCartoModuleCommands(checked ? [['minimap.enable']] : [['minimap.disable']]);
  },

  handlerNavigation() {
    const checked = $('input[type=checkbox][name=display-navigation]').is(':checked');

    this._displayOptions.navigation = checked;
    this._executeCartoModuleCommands(checked
      ? [['zoom.enable'], ['pan.enable']]
      : [['zoom.disable'], ['pan.disable']]);

    const logosRoot = $('#originators-root');
    checked ? logosRoot.addClass('navigation-on-map') : logosRoot.removeClass('navigation-on-map');
  },

  _enableGraticule(enabled) {
    const $chkbxGraticule = $('input[type=checkbox][name=display-reticle]');
    if ($chkbxGraticule.is(':checked')) {
      $chkbxGraticule.click();
    }
    $chkbxGraticule.attr('disabled', !enabled);
  },

  _toggleGlobeMode() {
    if (platform.name === 'IE') {
      ToastrUtil.error($.i18n.t('display.globe.message.oldBrowser'), 0);
      return;
    }
    if (GlobalDataStore.get(SC_DRAWING_MODULE) || GlobalDataStore.get(SC_MEASUREMENT_MODULE)) {
      ToastrUtil.error($.i18n.t('display.globe.message.moduleNotCompatible'), 0);
      return;
    }
    if (this._gisView.isGlobeEnabled()) {
      this._disableGlobeMode();
    } else {
      this._enableGlobeMode();
    }
  },

  _disableGlobeMode() {
    this._enableGraticule(true);
    this._globeBtnTranslationKey = 'display.globe.action.toGlobe';
    this._$modeGlobeBtn.text($.i18n.t(this._globeBtnTranslationKey));
    this._eventBus.trigger('globe-mode:change', { active: false });
    ToastrUtil.clear();
    this._gisView.stopGlobe();
  },

  _enableGlobeMode() {
    Loading.start(this._$mapContainer);
    this._globeBtnTranslationKey = 'display.globe.action.toFlat';
    this._$modeGlobeBtn.text($.i18n.t(this._globeBtnTranslationKey));
    this._gisView.startGlobe(GlobalDataStore.get('globeResolution')).then(() => {
      Loading.stop(this._$mapContainer);
      this._enableGraticule(false);
      this._gisView._map.getView().setZoom(this._gisView._config.zoom);
      this._gisView._map.getView().setCenter([this._gisView._config.lon, this._gisView._config.lat]);
    }).catch(() => {
      Loading.stop(this._$mapContainer);
      ToastrUtil.clear();
      ToastrUtil.error($.i18n.t('display.globe.message.errorLoading', 9000));
      this._disableGlobeMode();
    });
    this._eventBus.trigger('globe-mode:change', { active: true });
    ToastrUtil.info($.i18n.t('display.globe.message.infoChangeMode'), 0);
  },

  _handleResolution(event) {
    const { value } = event.target;
    if (!this._$resolutionValue) {
      this._$resolutionValue = this.$('#range-resolution-value');
    }
    GlobalDataStore.set('globeResolution', value);
    this._$resolutionValue.text(value);
  },

  chooseCoordinate(event) {
    const coordSyst = event.target.value;
    this._gisView.setCoordSyst(coordSyst);
  },

  postTranslate() {
    this._$modeGlobeBtn.text($.i18n.t(this._globeBtnTranslationKey));
  }
});
