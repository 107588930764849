import { STEPPING_ATLAS, STEPPING_OCEANO } from '../../utils/constants';

const $ = require('jquery');
const _ = require('underscore');
const moment = require('moment');
const ShomView = require('../../core/shom-view');

const OceanoNcwmsUtils = require('../../utils/oceano/oceano-ncwms.js');
const OceanoLayerUtils = require('../../utils/oceano/oceano-layer');
const OceanoHelper = require('../../utils/gis/oceano-helper');
const AtlasHelper = require('../../utils/atlas/atlas-utils.js');
const DDMDataUtils = require('../../utils/ddm/ddm-data-utils');

const template = require('../../../template/oceano/ocea-timeline-on-map.hbs');
const tooltipTemplate = require('../../../template/oceano/ocea-timeline-tooltip.hbs');

module.exports = ShomView.build({

  id: 'timeline-on-map',
  className: 'hitable',

  /** ***************************** */
  /** ****** INIT FUNCTIONS ******* */
  /** ***************************** */

  initialize(options = {}) {
    this._config = options.config || window.CONFIG;
    this._router = options.router || window.ROUTER;
    this._gisView = options.gisview || window.GISVIEW;
    this._layers = options.layers || window.LAYERS;

    // If timeline start at first date among all availables
    this._eventsBus = options.eventsBus || this._router.eventsBus;
    this._isStartingOnTheFirstDate = !!options.isStartingOnTheFirstDate;

    // array of ncwms layers (models and moments) displayed
    this._oceaLayers = [];

    // Array of all the time values available (UTC+0) rounded up to the closest minute.
    this._sortedUniqueMoments = [];
    // current time displayed (UTC+0) initialized to current time.
    this._currentMoment = moment();
    // current UTC initialized to the UTC of the current time zone.
    this._currentUTC = OceanoNcwmsUtils.getNavUTC();
    // basically a map with "days" as key and "arrays of hours" as values.
    this._momentsStructure = new Map();
    this._momentToDateStringTemplate = $.i18n.t('oceano.nav.timeline.timelineDateFormat');
    this._momentToTimeStringTemplate = $.i18n.t('oceano.nav.timeline.timelineTimeFormat');
    this._onAnimateButton = this._onAnimateButton.bind(this);
    this._availableDays = [];
    this._timelineDateTimeFormat = $.i18n.t('oceano.nav.timeline.timelineDateTimeFormat');
    this._ddmDataUtils = options.ddmDataUtils || new DDMDataUtils(this._config);
    this._spmDiffFromNearestPmFormat = this._config.tfs.diffFromNearestPmDateTimeFormat;

    this._initListeners();
  },

  _initListeners() {
    this.listenTo(this._eventsBus, 'change:onOceaZoomTimeChange', this._onOceaZoomTimeChange.bind(this));
    this.listenTo(this._eventsBus, 'globe-mode:change', globeMode => {
      globeMode.active ? this._disableAnimateButton() : this._enableAnimateButton();
    });
  },

  /** ***************************** */
  /** ****** RENDER FUNCTIONS ***** */
  /** ***************************** */

  render() {
    this.$el.html(template());

    // template elements
    this._$timelineRoot = this.$('#timeline-on-map');
    this._$timelineSlider = this.$('#timeline-on-map-slider');
    this._$sliderTooltip = this.$el.find('#timeline-tooltip-div');
    this._$prevButton = this.$el.find('.btn-prev-time');
    this._$nextButton = this.$el.find('.btn-next-time');
    this._$selectUTC = this.$el.find('#timeline-select-utc');
    this._$animateButton = this.$('.aom-btn-start-animation');

    // events
    this._$selectUTC.on('change', _.bind(this._onUtcZoneChange, this));
    this._$timelineSlider.on('mousemove', _.bind(this._onTimeSliderHover, this));
    this._$timelineSlider.on('slidestart', _.bind(this._onTimeSliderSlideStart, this));
    this._$timelineSlider.on('slide', _.bind(this._onTimeSliderDragHandle, this));
    this._$timelineSlider.on('slidestop', _.bind(this._onTimeSliderSlideStop, this));
    this._$nextButton.on('click', _.bind(this._onNextButton, this));
    this._$prevButton.on('click', _.bind(this._onPrevButton, this));

    if (this._gisView.isGlobeEnabled()) {
      this._disableAnimateButton();
    } else {
      this._enableAnimateButton();
    }

    // precomputing routines
    this._computeAvailableMoments();
    // First render
    if (this._currentMoment === null) {
      if (this._isStartingOnTheFirstDate && this._sortedUniqueMoments.length) {
        this._currentMoment = this._sortedUniqueMoments[0];
      } else {
        this._currentMoment = moment();
      }
    }
    this._applyUtcOffset();
    this._applyLanguage();
    this._computeNearestCurrentMoment();
    this._buildMomentsStructure();
    this._computeAvailableDays();

    // render
    this._renderDateTimePickerControls();
    this._renderUTCSelector();
    this._renderTimelineSlider();

    if (this._sortedUniqueMoments.length) {
      this._$timelineRoot.removeClass('hidden');
    } else {
      this._$timelineRoot.addClass('hidden');
    }
    return this;
  },

  removeTimeline() {
    this.$el.empty();
  },

  _renderTimelineSlider() {
    const selectedDay = this._currentMoment.format(this._momentToDateStringTemplate);

    const times = this._sortedUniqueMoments.length ? this._momentsStructure[selectedDay] : [];

    let currentTimeIdx = 1;
    let maxSliderValue = 2;
    const disabled = false;

    if (times.length > 1) {
      currentTimeIdx = Math.max(0, _.findIndex(times, time => time[1] === this._currentMoment));
      maxSliderValue = times.length - 1;
    }
    this._$timelineSlider.slider({
      value: currentTimeIdx,
      min: 0,
      max: maxSliderValue,
      step: 1,
      range: 'min',
      animate: 'fast',
      disabled
    });

    // ajout dynamique du tooltip.
    this._$sliderTooltip.html(tooltipTemplate);
    this._$sliderTooltipInfo = this._$sliderTooltip.find('#timeline-tooltip-info');
    this._$timelineSlider.on('mousemove', _.bind(this._onTimeSliderHover, this));
    this._$timelineSlider.removeClass('ui-corner-all');
    this.$('.ui-slider-range').removeClass('ui-corner-all');
    this.$('.ui-slider-handle').removeClass('ui-corner-all');
  },

  _resetTimelineSlider() {
    const selectedDay = this._currentMoment.format(this._momentToDateStringTemplate);
    const times = this._sortedUniqueMoments.length ? this._momentsStructure[selectedDay] : [];

    const currentTimeIdx = Math.max(0, _.findIndex(times, time => time[1] === this._currentMoment));

    this._$timelineSlider.slider('value', currentTimeIdx);
  },

  _renderSliderTooltip(handleMoment, relativeLeftOffset) {
    if (relativeLeftOffset !== undefined) {
      this._$sliderTooltip.css('left', `${relativeLeftOffset}px`);
    }
    this._$sliderTooltipInfo.text(handleMoment.format('HH:mm'));
  },

  _renderDateTimePickerControls() {
    this._$datetimePicker = this.$('#timeline-datetimepicker');
    const i18nLang = $.i18n.options.lng;
    const lang = i18nLang === 'fr' || i18nLang === 'fr_FR' ? 'fr' : 'en';

    this._disabledTimeIntervals = this._buildDisabledTimeIntervals();
    const [minDate, maxDate, stepping, disabledTimeIntervals] = this._disabledTimeIntervals;

    const dateTimePickerOptionsDateTime = {
      format: this._timelineDateTimeFormat,
      showClose: this._config.timeline.datetimePickerShowClose,
      toolbarPlacement: 'top',
      locale: lang,
      sideBySide: true,
      minDate,
      maxDate,
      stepping,
      disabledTimeIntervals
    };

    this._$datetimePicker.datetimepicker(dateTimePickerOptionsDateTime);
    this._$datetimePicker.data('DateTimePicker').date(this.getGlobalCurrentMoment().format(this._timelineDateTimeFormat));
    this._$datetimePicker.on('dp.change', this._onDateTimeChange.bind(this));
  },

  _buildDisabledTimeIntervals() {
    let hasAtlas = false;
    let hasOceano = false;
    for (let layerIdx = 0; layerIdx < this._oceaLayers.length; layerIdx++) {
      if (AtlasHelper.isAtlasLayer(this._oceaLayers[layerIdx].model)) {
        hasAtlas = true;
      } else {
        hasOceano = true;
      }
    }

    let minDate;
    let maxDate;
    const disabledTimeIntervalsArray = [];

    if (hasOceano) {
      const sourceData = this._momentsStructure;
      const uniqTimeArray = [];
      _.each(sourceData, dayElt => {
        // case when first hour for day D is 02:00, we have to disable [D-1 23:59:59;D 02:00]
        if (dayElt[0][1].hour() > 0) {
          const newInterval = [];
          const lowerBound = dayElt[0][1].clone().subtract(1, 'days').hour(23).minute(59)
            .second(59)
            .millisecond(999);
          const upperBound = dayElt[0][1];
          newInterval.push(lowerBound);
          newInterval.push(upperBound);
          disabledTimeIntervalsArray.push(newInterval);
        }
        if (dayElt.length > 1) {
          for (let i = 0; i < dayElt.length - 1; i++) {
            const newInterval = [];
            const lowerBound = dayElt[i][1];
            const upperBound = dayElt[i + 1][1];
            newInterval.push(lowerBound);
            newInterval.push(upperBound);
            disabledTimeIntervalsArray.push(newInterval);
            if (!minDate || minDate > lowerBound) {
              minDate = lowerBound;
            }
            if (!maxDate || maxDate < upperBound) {
              maxDate = upperBound;
            }
          }
        } else if (dayElt.length === 1) { // case when one hour per day
          uniqTimeArray.push(dayElt);
          if (!minDate || minDate > dayElt[0][1]) {
            minDate = dayElt[0][1];
          }
          if (!maxDate || maxDate < dayElt[0][1]) {
            maxDate = dayElt[0][1];
          }
        }
      });

      if (uniqTimeArray) {
        // case when one hour per day, 08/03 13:00, 09/03 13:00
        // we have to disable 08/03 13:01 to 09/03 12:59
        for (let i = 0; i < uniqTimeArray.length - 1; i++) {
          const newInterval = [];
          const lowerBound = uniqTimeArray[i];
          const upperBound = uniqTimeArray[i + 1];
          newInterval.push(lowerBound[0][1].clone().add('1', 'minute'));
          newInterval.push(upperBound[0][1].clone().subtract('1', 'minute'));
          disabledTimeIntervalsArray.push(newInterval);
        }
      }
    }

    if (hasAtlas) {
      minDate = moment().subtract(1, 'y').hour(0).minute(0)
        .second(0);
      maxDate = moment().add(1, 'y').hour(23).minute(59)
        .second(59);
    }

    const stepping = this._getStepping();
    return [
      moment(minDate.format(this._timelineDateTimeFormat), this._timelineDateTimeFormat),
      moment(maxDate.format(this._timelineDateTimeFormat), this._timelineDateTimeFormat),
      stepping,
      disabledTimeIntervalsArray
    ];
  },

  _renderUTCSelector() {
    let UTCvalues = '';

    for (let iUtc = -12; iUtc < 14; iUtc++) {
      if (+this._currentUTC === iUtc) {
        UTCvalues += `<option value="${iUtc}" selected>`;
      } else {
        UTCvalues += `<option value="${iUtc}">`;
      }

      UTCvalues += 'UTC';
      UTCvalues += iUtc >= 0 ? '+' : '-';
      UTCvalues += Math.abs(iUtc);
      UTCvalues += '</option>';
    }

    this._$selectUTC.html(UTCvalues);
  },

  /** ****************************** */
  /** ****** GETTER FUNCTIONS ****** */
  /** ****************************** */

  getCurrentUTC() {
    return this._currentUTC;
  },

  getGlobalCurrentMoment() {
    return moment(this._currentMoment);
  },

  getLayerCurrentMoment(model) {
    const oceaLayer = _.find(this._oceaLayers, layer => layer.model === model);
    if (!oceaLayer || AtlasHelper.isAtlasLayer(oceaLayer.model)) {
      return this.getGlobalCurrentMoment();
    }
    return moment(OceanoNcwmsUtils.getNearestMomentFromSortedMomentArray(this._currentMoment, oceaLayer.sortedMoments));
  },

  /** ****************************** */
  /** ****** SETTER FUNCTIONS ****** */
  /** ****************************** */

  setCurrentMoment(newMoment) {
    if (newMoment) { // allow for reset on current moment.
      this._currentMoment = newMoment;
      this._computeNearestCurrentMoment();
    }

    this._renderTimelineSlider();
    this._renderDateTimePickerControls();

    this._triggerTimeChange();
    this._updateDisplayedLayers();
  },

  /** ***************************** */
  /** ****** EVENT FUNCTIONS ****** */
  /** ***************************** */

  _triggerTimeChange() {
    this.trigger('change:oceaGlobMoment', {});
  },

  _triggerUTCChange() {
    this.trigger('change:oceaGlobUTC', {
      moment: this._currentUTC
    });
  },

  _onDateTimeChange() {
    // compare new day with current one / the same for hour
    const newMoment = moment(this._$datetimePicker.val(), this._timelineDateTimeFormat);
    const newDay = newMoment.format(this._momentToDateStringTemplate);
    const newTime = newMoment.format(this._momentToTimeStringTemplate);
    const currentDay = this._currentMoment.format(this._momentToDateStringTemplate);
    const currentTime = this._currentMoment.format(this._momentToTimeStringTemplate);
    const dayChanged = newDay !== currentDay;
    const timeChanged = newTime !== currentTime;

    if (dayChanged) {
      this._onDayChange();
    } else if (timeChanged) {
      this._onTimeChange();
    }
  },

  _onDayChange() {
    // new "current moment" is first hour of day selected
    const currentDay = moment(this._$datetimePicker.val(), this._timelineDateTimeFormat).format(this._momentToDateStringTemplate);

    // retrieve the closest time available for the new selected day.
    const times = this._getMomentForDay(currentDay).slice();
    const currentTime = this._currentMoment.format(this._momentToTimeStringTemplate);
    this._currentMoment = OceanoNcwmsUtils.getClosestTimeFromTimeStructure(currentTime, times)[1];

    this._renderTimelineSlider();

    this._triggerTimeChange();
    this._updateDisplayedLayers();
  },

  _onTimeChange() {
    // retrieve new "current moment"
    const currentMoment = moment(this._$datetimePicker.val(), this._timelineDateTimeFormat);
    const currentDay = currentMoment.format(this._momentToDateStringTemplate);
    const currentTime = currentMoment.format(this._momentToTimeStringTemplate);

    const eltTimeMoment = this._getMomentForDay(currentDay).find(elt => elt[0] === currentTime);

    if (eltTimeMoment) {
      this._currentMoment = eltTimeMoment[1];

      this._resetTimelineSlider();

      this._triggerTimeChange();
      this._updateDisplayedLayers();
    }
  },

  _getMomentForDay(currentDay) {
    let eltMoment = this._momentsStructure[currentDay];
    if (!eltMoment) {
      // if day isn't in list, it's an atlas one, so add day to this momentsStructure (compute and build)
      const atlasTimeValues = AtlasHelper.buildAtlasMoments(moment(currentDay, this._momentToDateStringTemplate));
      this._sortedUniqueMoments = this._sortedUniqueMoments.concat(atlasTimeValues).sort((a, b) => a.diff(b));
      this._buildMomentsStructure();
      eltMoment = this._momentsStructure[currentDay];
    }
    return eltMoment;
  },

  _onUtcZoneChange() {
    // new utc value
    this._currentUTC = this._$selectUTC.val();
    this._triggerUTCChange();
    this._applyUtcOffset();

    // rebuild timestamp structure and re-render selectors.
    this._buildMomentsStructure();
    this._computeAvailableDays();

    this._renderDateTimePickerControls();
    this._renderTimelineSlider();
  },

  _onTimeSliderSlideStop(event, ui) {
    event.preventDefault();

    this._$timelineSlider.on('mousemove', _.bind(this._onTimeSliderHover, this));
    this._$timelineSlider.toggleClass('active');
    const newMomentIdx = ui.value;

    const selectedDay = this._currentMoment.format(this._momentToDateStringTemplate);
    const times = this._sortedUniqueMoments.length ? this._momentsStructure[selectedDay] : [];

    this._currentMoment = times[newMomentIdx][1];

    this._renderDateTimePickerControls();

    this._triggerTimeChange();
    this._updateDisplayedLayers();
  },

  _onTimeSliderHover(event) {
    event.preventDefault();

    const currentDay = moment(this._$datetimePicker.val(), this._timelineDateTimeFormat).format(this._momentToDateStringTemplate);
    const times = this._momentsStructure[currentDay];
    const stepWidth = this._$timelineSlider.width() / (times.length - 1);
    let offsetLeft = $(event.target).is('span') ? event.target.offsetLeft : event.offsetX;
    if (offsetLeft < 0) {
      offsetLeft = 0;
    }
    const cursorTimeIdx = Math.min(Math.round(offsetLeft / stepWidth), times.length - 1);

    this._renderSliderTooltip(times[cursorTimeIdx][1], offsetLeft);
  },

  _onTimeSliderDragHandle(event, ui) {
    const cursorTimeIdx = ui.value;
    const currentDay = moment(this._$datetimePicker.val(), this._timelineDateTimeFormat).format(this._momentToDateStringTemplate);
    const times = this._momentsStructure[currentDay];
    const stepWidth = this._$timelineSlider.width() / (times.length - 1);

    this._renderSliderTooltip(times[cursorTimeIdx][1], cursorTimeIdx * stepWidth);
  },

  _onTimeSliderSlideStart() {
    this._$timelineSlider.off('mousemove');
    this._$timelineSlider.toggleClass('active');
  },

  hasAtlas() {
    for (let layerIdx = 0; layerIdx < this._oceaLayers.length; layerIdx++) {
      if (AtlasHelper.isAtlasLayer(this._oceaLayers[layerIdx].model)) {
        return true;
      }
    }
    return false;
  },

  goToNextDateTime() {
    this._onNextButton();
  },

  goToPreviousDateTime() {
    this._onPrevButton();
  },

  getSortedUniqueMoments() {
    return this._sortedUniqueMoments;
  },

  _onNextButton() {
    const oldMoment = this._currentMoment;
    let newMomentIdx = this._sortedUniqueMoments.indexOf(oldMoment) + 1;
    if (newMomentIdx >= this._sortedUniqueMoments.length) {
      if (!this.hasAtlas()) {
        return;
      }

      // we go next day, so set datetimepicker to next day at 00:00 if there is an atlas layer
      this._$datetimePicker.data('DateTimePicker').date(this._currentMoment.clone().add(1, 'day').format(this._timelineDateTimeFormat));
      newMomentIdx = this._sortedUniqueMoments.indexOf(oldMoment) + 1;
    }

    this._currentMoment = this._sortedUniqueMoments[newMomentIdx];

    const oldSelectedDay = oldMoment.format(this._momentToDateStringTemplate);
    const newSelectedDay = this._currentMoment.format(this._momentToDateStringTemplate);
    if (oldSelectedDay === newSelectedDay) {
      this._resetTimelineSlider();
    } else {
      this._renderTimelineSlider();
    }

    this._renderDateTimePickerControls();

    this._triggerTimeChange();
    this._updateDisplayedLayers();
  },

  _onPrevButton() {
    const oldMoment = this._currentMoment;
    let newMomentIdx = this._sortedUniqueMoments.indexOf(oldMoment) - 1;
    if (newMomentIdx < 0) {
      if (!this.hasAtlas()) {
        return;
      }

      // we go next day, so set datetimepicker to previous day at 23:45 if there is an atlas layer
      this._$datetimePicker.data('DateTimePicker').date(this._currentMoment.clone().subtract(1, 'day').format(this._timelineDateTimeFormat));
      newMomentIdx = this._sortedUniqueMoments.indexOf(oldMoment) - 1;
    }

    this._currentMoment = this._sortedUniqueMoments[newMomentIdx];

    const oldSelectedDay = oldMoment.format(this._momentToDateStringTemplate);
    const newSelectedDay = this._currentMoment.format(this._momentToDateStringTemplate);
    if (oldSelectedDay === newSelectedDay) {
      this._resetTimelineSlider();
    } else {
      this._renderTimelineSlider();
    }

    this._renderDateTimePickerControls();

    this._triggerTimeChange();
    this._updateDisplayedLayers();
  },

  _onAnimateButton() {
    this.trigger(
      'start:animationOnMapView',
      {
        currentMoment: this._currentMoment,
        sortedUniqueMoments: this._sortedUniqueMoments,
        momentsStructure: this._momentsStructure,
        disabledTimeIntervals: this._disabledTimeIntervals,
        oceaLayers: this._oceaLayers
      }
    );
  },

  _onOceaZoomTimeChange() {
    this.updateTimeline(true);
    this.render();
  },

  postTranslate() {
    this._momentToDateStringTemplate = $.i18n.t('oceano.nav.timeline.timelineDateFormat');
    this._applyLanguage();
    this._buildMomentsStructure();
    this._computeAvailableDays();
    this._renderDateTimePickerControls();
  },

  onClose() {
    this.removeTimeline();
  },

  /** ***************************** */
  /** ****** UTILS FUNCTIONS ****** */
  /** ***************************** */

  _updateDisplayedLayers() {
    // set correct date
    for (let layerIdx = 0; layerIdx < this._oceaLayers.length; layerIdx++) {
      const oceaLayer = this._oceaLayers[layerIdx];

      // needs to be a new object or the moment#utc() method call will reset the utc offset.
      const nearestMoment = moment(OceanoNcwmsUtils.getNearestMomentFromSortedMomentArray(this._currentMoment, oceaLayer.sortedMoments));
      const oceaLayerModel = oceaLayer.model;
      const isAtlas = AtlasHelper.isAtlasLayer(oceaLayerModel);
      if (isAtlas) {
        // if atlas, first retrieve result from spm then call line below with result given (ex: 01-01-1950T08:45)
        this._getDiffFromNearestPm(oceaLayerModel.get('portRef'), this.getCurrentUTC(), this._currentMoment, oceaLayerModel.get('isBm'))
          .then(data => {
            oceaLayerModel.set('currentCoeff', +data.coeff);
            return +data.diff;
          })
          .then(diffFromNearestSpm => AtlasHelper.computeNewDatetimeFromReference(diffFromNearestSpm, oceaLayerModel.get('olLayer').get('dimensions').processedTime))
          .then(datetime => this._loadLayerModel(oceaLayerModel, datetime))
          .fail(err => console.error(err));
      } else {
        this._loadLayerModel(oceaLayerModel, nearestMoment);
      }
    }
  },

  _loadLayerModel(layerModel, requestedMoment) {
    OceanoLayerUtils.setNCWMSParamsInGroupLayer(layerModel, {
      date: requestedMoment.utc().toISOString().split('T')[0],
      time: requestedMoment.utc().toISOString().split('T')[1],
      coeff: layerModel.get('currentCoeff')
    });
  },

  _getDiffFromNearestPm(harborName, utc, datetime, isBm) {
    return this._ddmDataUtils.getDiffFromNearestPm(harborName, utc, datetime.format(this._spmDiffFromNearestPmFormat), isBm);
  },

  updateTimeline(forceUpdate) {
    const newOceaLayers = this._gisView.getOceanoDisplayedLayers();
    const oceaLayersChanged = this._oceaLayers.length !== newOceaLayers.length;

    if (!oceaLayersChanged && !forceUpdate) { return; } // shortcut to prevent useless computing

    this._oceaLayers = [];
    for (let layerIdx = 0; layerIdx < newOceaLayers.length; layerIdx++) {
      const oceaLayerModel = newOceaLayers[layerIdx];
      const isAtlas = AtlasHelper.isAtlasLayer(oceaLayerModel);

      const dates = isAtlas ? [] : OceanoHelper.getProcessedTimeInZoom(oceaLayerModel, this._gisView.getZoom());
      const oceaLayerMoments = OceanoNcwmsUtils.getSortedMomentArrayFromDatesStructure(dates);
      oceaLayerModel.set('currentProcessedTime', dates);
      const oceaLayer = {
        model: oceaLayerModel,
        sortedMoments: oceaLayerMoments
      };
      this._oceaLayers.push(oceaLayer);
    }
  },

  _applyUtcOffset() {
    const newUtcValue = this._currentUTC;
    this._sortedUniqueMoments.map(momt => momt.utcOffset(newUtcValue * 60), this);
  },

  _applyLanguage() {
    this._sortedUniqueMoments.map(momt => momt.locale(window.portalLang), this);
  },

  _computeAvailableMoments() {
    const uniqueTimeValues = [];
    let atlasTimeValues = [];
    let hasAtlas = false;
    for (let layerIdx = 0; layerIdx < this._oceaLayers.length; layerIdx++) {
      const isAtlas = AtlasHelper.isAtlasLayer(this._oceaLayers[layerIdx].model);
      if (isAtlas) {
        hasAtlas = true;
        continue;
      }

      const oceaLayerMoments = OceanoNcwmsUtils.roundMomentsTo10minutes(this._oceaLayers[layerIdx].sortedMoments);
      for (let momentIdx = 0; momentIdx < oceaLayerMoments.length; momentIdx++) {
        const momnt = oceaLayerMoments[momentIdx];
        uniqueTimeValues.push(momnt);
      }
    }

    let sortedArray = this._makeUniq(uniqueTimeValues).sort((a, b) => a.diff(b));

    if (hasAtlas) {
      if (sortedArray.length) {
        let days = sortedArray.reduce((acc, momt) => {
          acc.push(momt.format(this._momentToDateStringTemplate));
          return acc;
        }, []);
        days = this._makeUniq(days);

        // build array for each day in days
        atlasTimeValues = days.reduce((acc, day) => {
          const momtList = AtlasHelper.buildAtlasMoments(moment(day, this._momentToDateStringTemplate));
          return acc.concat(momtList);
        }, []);
      } else {
        // build array for current day
        atlasTimeValues = AtlasHelper.buildAtlasMoments(this.getGlobalCurrentMoment());
      }

      sortedArray = sortedArray.concat(atlasTimeValues).sort((a, b) => a.diff(b));
    }

    this._sortedUniqueMoments = sortedArray;
  },

  _makeUniq(objectArray) {
    return Array.from(new Set(objectArray));
  },

  _computeNearestCurrentMoment() {
    this._currentMoment = OceanoNcwmsUtils.getNearestMomentFromSortedMomentArray(this._currentMoment, this._sortedUniqueMoments);
  },

  _getStepping() {
    let stepping = STEPPING_ATLAS;
    for (let layerIdx = 0; layerIdx < this._oceaLayers.length; layerIdx++) {
      const isAtlas = AtlasHelper.isAtlasLayer(this._oceaLayers[layerIdx].model);
      stepping = Math.min(stepping, isAtlas ? STEPPING_ATLAS : STEPPING_OCEANO);
    }
    return stepping;
  },

  _buildMomentsStructure() {
    // This function builds the TimestampStructure as depicted below :
    // this._momentsStructure  = { "date1" : [ ["hour1" , moment]
    //                                         ["hour2" , moment] ... ],
    //                             "date2" : ... }
    //

    this._momentsStructure = {};

    for (let momentIdx = 0; momentIdx < this._sortedUniqueMoments.length; momentIdx++) {
      const momt = this._sortedUniqueMoments[momentIdx];
      const day = momt.format(this._momentToDateStringTemplate);
      const time = momt.format(this._momentToTimeStringTemplate);

      if (day in this._momentsStructure) {
        this._momentsStructure[day].push([time, momt]);
      } else {
        this._momentsStructure[day] = [[time, momt]];
      }
    }
  },

  _computeAvailableDays() {
    // Compute and sort availables days, order by date
    this._availableDays = [];
    for (const day in this._momentsStructure) {
      // eslint-disable-next-line no-prototype-builtins
      if (this._momentsStructure.hasOwnProperty(day)) {
        this._availableDays.push(day);
      }
    }

    // sort the days array.
    this._availableDays.sort((day1, day2) => {
      const m1 = this._momentsStructure[day1][0][1]; // day1 first moment
      const m2 = this._momentsStructure[day2][0][1]; // day2 first moment
      return m1.diff(m2);
    });
  },

  _enableAnimateButton() {
    this._$animateButton && this._$animateButton
      .removeClass('disabled')
      .on('click', this._onAnimateButton);
  },

  _disableAnimateButton() {
    this._$animateButton && this._$animateButton
      .addClass('disabled')
      .off('click', this._onAnimateButton);
  }
});
