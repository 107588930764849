const $ = require('jquery');

module.exports = function () {
  return {
    url: {
      validators: {
        notEmpty: {
          message: $.i18n.t('catalog.external.kml.url.empty')
        },
        uri: {
          message: $.i18n.t('catalog.external.kml.url.invalid')
        }
      }
    },
    layer: {
      validators: {
        callback: {
          message: $.i18n.t('catalog.external.wms.layer.empty'),
          callback(value) {
            return value !== null && value !== undefined && value !== '';
          }
        }
      }
    }
  };
};
