const _ = require('underscore');
const moment = require('moment');
const ShomView = require('../../core/shom-view');

const OceanoNcwmsUtils = require('../../utils/oceano/oceano-ncwms.js');
const OceanoHelper = require('../../utils/gis/oceano-helper');
const AtlasHelper = require('../../utils/atlas/atlas-utils.js');
const template = require('../../../template/oceano/ocea-select-glob-date.hbs');

module.exports = ShomView.build({

  initialize(options = {}) {
    this._gisView = options.gisView || window.GISVIEW;
    this._eventBus = options.eventBus || window.EVENTBUS;
    this._timelineView = this._gisView.getTimelineView();
    this._parentView = options.parentView;
    this._index = options.index;
    this._isAtlas = AtlasHelper.isAtlasLayer(this.model);

    this._currentUTC = this._gisView.getGlobalOceanoCurrentUTC();
    this._sortedMoments = [];
    this._currentMoment_idx = 0;

    this._initMoments();

    // If 'timeline' is not created for the moment
    if (this._timelineView) {
      this._initListeners();
    } else {
      this._onTimelineCreatedEventFunc = this._onTimelineCreated.bind(this);
      this._eventBus.once('create:timeline-view', this._onTimelineCreated.bind(this));
    }
  },

  // At the end of this method, sortedDateValues should contain an ordered array of iso-date-strings.
  _initMoments() {
    const values = this._isAtlas ? [] : OceanoHelper.getProcessedTimeInZoom(this.model, this._gisView.getZoom());

    this._sortedMoments = OceanoNcwmsUtils.getSortedMomentArrayFromDatesStructure(values);
    this._sortedMoments = OceanoNcwmsUtils.roundMomentsTo10minutes(this._sortedMoments);

    const currentMoment = this._gisView.getGlobalOceanoCurrentMoment() || new moment();

    if (this._isAtlas) {
      this._initAtlasMoments();
    }

    this._applyUtcOffset();

    const nearestMoment = OceanoNcwmsUtils.getNearestMomentFromSortedMomentArray(currentMoment, this._sortedMoments);
    this._currentMoment_idx = this._sortedMoments.indexOf(nearestMoment);
  },

  _initListeners() {
    this.listenTo(this._timelineView, 'change:oceaGlobMoment', _.bind(this._onOceaGlobalMomentChange, this));
    this.listenTo(this._timelineView, 'change:oceaGlobUTC', _.bind(this._onOceaGlobalUTCChange, this));
  },

  _onTimelineCreated(timelineView) {
    this._timelineView = timelineView;
    this._onOceaGlobalMomentChange();
    this._initListeners();
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({
      index: this._index
    }));

    // template elements
    this._$nextTimeBtn = this.$el.find(`#ocea-glob-date-btn-next-${this._index}`);
    this._$prevTimeBtn = this.$el.find(`#ocea-glob-date-btn-previous-${this._index}`);
    this._$spanGlobDate = this.$el.find(`#ocea-glob-date-value-${this._index}`);
    this._$divWarning = this.$el.find(`#ocea-glob-date-warning-${this._index}`);

    // events
    this._$nextTimeBtn.on('click', this._onNextTime.bind(this));
    this._$prevTimeBtn.on('click', this._onPreviousTime.bind(this));

    this._renderMoment();

    return this;
  },

  _renderMoment() {
    const momentToStringTemplate = `${OceanoNcwmsUtils.getDateToStringTemplate()} - HH:mm`;
    const currentMoment = this._sortedMoments[this._currentMoment_idx];
    const strdateValue = currentMoment.format(momentToStringTemplate);

    this._$spanGlobDate.html(strdateValue);
    this._enableNextPrevButton();
    this._renderWarning();
  },

  _renderWarning() {
    // shown only when layer moment and timeline moment differ.
    const localCurrentMoment = this._sortedMoments[this._currentMoment_idx];
    const globalCurrentMoment = this._gisView.getGlobalOceanoCurrentMoment();
    const diffToGlobalTime = Math.abs(localCurrentMoment.diff(globalCurrentMoment));
    this._$divWarning.toggle(diffToGlobalTime > 30000);
  },

  /** ***************************** */
  /** ****** GETTER FUNCTION ****** */
  /** ***************************** */

  getSelectedMoment() {
    return (this._sortedMoments[this._currentMoment_idx]);
  },

  _enableNextPrevButton() {
    const enableNext = this._isAtlas || this._currentMoment_idx < (this._sortedMoments.length - 1);
    const enablePrev = this._isAtlas || this._currentMoment_idx > 0;

    if (!enableNext) {
      this._$nextTimeBtn.attr('disabled', 'disabled');
    } else {
      this._$nextTimeBtn.removeAttr('disabled');
    }

    if (!enablePrev) {
      this._$prevTimeBtn.attr('disabled', 'disabled');
    } else {
      this._$prevTimeBtn.removeAttr('disabled');
    }
  },

  /** ***************************** */
  /** ****** EVENT FUNCTION ******* */
  /** ***************************** */

  _onNextTime() {
    this._timelineView.goToNextDateTime();
  },

  _onPreviousTime() {
    this._timelineView.goToPreviousDateTime();
  },

  _onOceaGlobalMomentChange() {
    if (this._isAtlas) {
      this._sortedMoments = this._timelineView.getSortedUniqueMoments();
      if (!this._sortedMoments || !this._sortedMoments.length) {
        this._initAtlasMoments();
      }
    }
    const globalMoment = this._gisView.getGlobalOceanoCurrentMoment();
    const currentMoment = OceanoNcwmsUtils.getNearestMomentFromSortedMomentArray(globalMoment, this._sortedMoments);
    this._currentMoment_idx = this._sortedMoments.indexOf(currentMoment);

    this._renderMoment();
  },

  _onOceaGlobalUTCChange() {
    this._currentUTC = this._gisView.getGlobalOceanoCurrentUTC();
    this._applyUtcOffset();
    this._renderMoment();
  },

  postTranslate(lang) {
    this._applyLanguage(lang);
    this._renderMoment();
  },

  onClose() {
    this.stopListening();
    this._onTimelineCreatedEventFunc && this._eventBus.off('create:timeline-view', this._onTimelineCreatedEventFunc);
    this._$nextTimeBtn.off('click');
    this._$prevTimeBtn.off('click');
  },

  /** ***************************** */
  /** ****** UTILS FUNCTION ******* */
  /** ***************************** */

  _applyUtcOffset() {
    this._sortedMoments.map(momt => momt.utcOffset(this._currentUTC * 60), this);
  },

  _applyLanguage(lang) {
    this._sortedMoments.map(momt => momt.locale(lang), this);
  },

  _initAtlasMoments() {
    const currentMoment = this._gisView.getGlobalOceanoCurrentMoment() || moment();
    const atlasTimeValues = AtlasHelper.buildAtlasMoments(currentMoment);
    this._sortedMoments = atlasTimeValues.sort((a, b) => a.diff(b));
  }
});
