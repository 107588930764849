const _ = require('underscore');
const moment = require('moment');

const LayersFactory = require('../layers-factory');
const OceanoLayerUtils = require('./oceano-layer');
const OceanoHelper = require('../gis/oceano-helper');
const CoordsHelper = require('../gis/coordinates-helper');
const { CATALOG_DEFAULT_ORDER_INDEX, CATALOG_OCEANO } = require('../constants');

const OceanoNcwmsUtils = function () {

};

OceanoNcwmsUtils.prototype.buildNCWMSCollection = function (layers, isArchive = false) {
  return LayersFactory.build(layers, {
    external: false,
    layerType: 'NCWMS',
    isArchive
  }).models;
};

/**
 * Extract boundingbox from rawLayer and convert it if it needed according to projection
 * @param {object} rawLayer Layer info extracted from getCapabilities
 */
OceanoNcwmsUtils.prototype.getMercatorBoundingBoxFromRawLayer = function (rawLayer) {
  if (rawLayer.BoundingBox && rawLayer.BoundingBox[0]) {
    const bboxInfo = rawLayer.BoundingBox[0];
    const bbox = bboxInfo.extent;
    if (bboxInfo.crs === 'CRS:84' || bboxInfo.crs === 'EPSG:4326') {
      return CoordsHelper.convertLonLatBboxToMercator(bbox);
    }
    return bbox;
  }
  // If no bounding box crs, default is EPSG:4326
  const bounds = rawLayer.EX_GeographicBoundingBox;
  return CoordsHelper.convertLonLatBboxToMercator(bounds);
};

OceanoNcwmsUtils.prototype.getLayerGroupByNCWMSIdentifier = function (layers, identifierNCWMS) {
  return layers.where({ identifier: identifierNCWMS })[0];
};

OceanoNcwmsUtils.prototype.getFirstLayerInZoom = function (layerGroup, zoom) {
  const olGroup = layerGroup.get('olLayer');
  const layers = olGroup.getLayers().getArray();
  for (let i = 0, len = layers.length; i < len; i++) {
    if (OceanoHelper.isLayerIsInZoom(layers[i], zoom)) {
      return layers[i];
    }
  }
  return null;
};

OceanoNcwmsUtils.prototype.getTimeOfISODate = function (date) {
  // return time with ISO format without the 'Z'
  const timeISO = date.toISOString().split('T')[1];
  return timeISO.substring(0, timeISO.length - 1);
};

OceanoNcwmsUtils.prototype.getParamsByModel = function (layers) {
  const groupParam = {};
  _.each(layers, layer => {
    const identifier = layer.get('identifier');
    const title = layer.get('title');
    const NCWMSId = OceanoLayerUtils.getIdentifiersFromLayerId(identifier);
    const translation = layer.get('translation');
    const priorities = layer.get('priorities');
    if (!groupParam[NCWMSId.thematic]) {
      const translateModel = (translation) ? translation[NCWMSId.thematic] : NCWMSId.thematic;
      groupParam[NCWMSId.thematic] = {
        translation: translateModel,
        priority: priorities.model,
        params: {}
      };
    }
    const translateTitle = (translation && translation[title]) ? translation[title] : title;
    groupParam[NCWMSId.thematic].params[title] = {
      param: NCWMSId.param,
      priority: priorities.param,
      translation: translateTitle
    };
  });
  return groupParam;
};

OceanoNcwmsUtils.prototype.getCategoriesByCatalog = function (layers, lang) {
  const catalog = window.CATALOG;
  let categories = _.without(_.uniq(_.map(layers, item => {
    const name = item.get('identifier').slice(0, item.get('identifier').indexOf('/'));
    const translations = item.get('translation');
    let translateName = (translations && translations[name]) ? translations[name][lang] : name;
    let priority = CATALOG_DEFAULT_ORDER_INDEX;

    // couche hors catégorie
    if (item.get('category') === undefined) {
      return {
        name,
        translateName,
        priority
      };
    }

    if (catalog && catalog.categories[CATALOG_OCEANO]) {
      const categoryConf = catalog.categories[CATALOG_OCEANO].find(categoryconf => categoryconf.id === name.toLowerCase());
      if (categoryConf) {
        priority = categoryConf.index || CATALOG_DEFAULT_ORDER_INDEX;
        translateName = categoryConf.name[lang];
        return {
          name,
          translateName,
          priority
        };
      }
    }
  }), 'name'), undefined);

  // categorie dans le catalogue sans couche
  if (catalog && catalog.categories[CATALOG_OCEANO]) {
    catalog.categories[CATALOG_OCEANO].forEach(item => {
      if (!categories.find(c => c.name === item.id.toUpperCase())) {
        const translations = item.name;
        categories.push({
          name: item.id.toUpperCase(),
          translateName: translations[lang],
          priority: item.index
        });
      }
    });
  }

  categories = _.sortBy(categories, cat => cat.priority);
  return categories;
};

OceanoNcwmsUtils.prototype.getRenderingPalettes = function (layerGroup) {
  const values = {};
  const { pals } = layerGroup.get('palette');
  const translate = layerGroup.get('translation');
  for (const i in pals) {
    if (pals.hasOwnProperty(i)) {
      const pal = pals[i];
      const name = i;
      values[name] = {
        priority: pal.priority || 9999,
        translate: (translate && translate.pals) ? translate.pals[name] : undefined
      };
    }
  }
  return values;
};

OceanoNcwmsUtils.prototype.getMinMax = function (datas) {
  const objectMin = datas.reduce((prev, curr) => (prev.min < curr.min ? prev : curr));
  const objectMax = datas.reduce((prev, curr) => (prev.max > curr.max ? prev : curr));

  return {
    min: objectMin.min,
    max: objectMax.max
  };
};

OceanoNcwmsUtils.prototype.evaluateValue = function (rvba, encoding) {
  if (encoding) {
    const encodingFormula = window.CONFIG.oceano.encoding.formula;
    /* eslint-disable no-unused-vars */
    const R = rvba[0];
    const V = rvba[1];
    const B = rvba[2];
    const I = encoding[0];
    const A = encoding[1];
    /* eslint-enable no-unused-vars */
    /* eslint-disable no-eval */
    return eval(encodingFormula);
    /* eslint-enable no-eval */
  }
  return 0;
};

OceanoNcwmsUtils.prototype.convertUnit = function (value, offset, rate) {
  return value * parseFloat(rate) + parseFloat(offset);
};

OceanoNcwmsUtils.prototype.standardizeDate = function (strDate) {
  const date = new moment(strDate);
  const roundInterval = 10;
  const remainder = date.minute() % roundInterval;
  const toAdd = remainder > roundInterval / 2 ? roundInterval : 0;
  date.subtract('minutes', remainder).add('minutes', toAdd);
  return date.toDate();
};

OceanoNcwmsUtils.prototype.sortDates = function (dates) {
  return dates.sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return (dateA > dateB) ? 1 : -1;
  });
};

OceanoNcwmsUtils.prototype.getDateToStringTemplate = function () {
  if (window.portalLang === 'fr') {
    return 'DD-MM-YYYY (ddd)';
  }
  return 'MM-DD-YYYY (ddd)';
};

OceanoNcwmsUtils.prototype.getTimeToStringTemplate = function () {
  return 'HH:mm';
};

OceanoNcwmsUtils.prototype.getNearestDateAndTime = function (date, time, values) {
  const nearestDate = this.getNearestDate(date, values);
  const exactDate = new Date(`${date}T` + '00:00:00.000Z').getTime();
  const exactNearestDate = new Date(`${nearestDate}T` + '00:00:00.000Z').getTime();

  if (exactDate < exactNearestDate) {
    return {
      nearestDate,
      nearestTime: values[nearestDate][0]
    };
  }

  if (exactDate > exactNearestDate) {
    return {
      nearestDate,
      nearestTime: values[nearestDate][values[nearestDate].length - 1]
    };
  }

  return {
    nearestDate: date,
    nearestTime: this.getNearestTime(date, time, values)
  };
};

OceanoNcwmsUtils.prototype.getNearestMomentFromSortedMomentArray = function (instant, moments) {
  if (moments.length === 0) {
    return moment();
  }
  let nearestMoment = moments[0];
  let bestDiff = Math.abs(instant.diff(moments[0]));
  for (let momentIdx = 1; momentIdx < moments.length; momentIdx++) {
    const momnt = moments[momentIdx];
    const diff = Math.abs(instant.diff(momnt));
    if (bestDiff === 0 || diff > bestDiff) { break; } // local optimization because table is sorted.
    if (bestDiff > diff || (bestDiff === diff && momnt.diff(nearestMoment) > 0)) {
      bestDiff = diff;
      nearestMoment = momnt;
    }
  }
  return nearestMoment;
};

OceanoNcwmsUtils.prototype.flattenDateArray = function (dates) {
  const layerIsoTimeValues = [];

  for (const date in dates) {
    if (!Object.prototype.hasOwnProperty.call(dates, date)) {
      continue;
    }
    const times = dates[date]; // table of moments.
    for (let iTime = 0; iTime < times.length; iTime++) {
      const time = times[iTime];
      layerIsoTimeValues.push(`${date}T${time}`);
    }
  }
  return layerIsoTimeValues;
};

// returns the closest date to the "date" parameter among the "values" parameter
OceanoNcwmsUtils.prototype.getNearestDate = function (date, values) {
  if (!values) {
    return null;
  }
  if (values[date]) { // if date exists within the values, return it.
    return date;
  }
  const exactRequiredDate = new Date(`${date}T` + '00:00:00.000Z').getTime();
  let res = null;
  const dateArray = Object.keys(values);
  res = dateArray.reduce(_.bind(function (prev, curr) {
    const absoluteTimePrev = this.getAbsoluteTime(
      exactRequiredDate,
      `${prev}T` + '00:00:00.000Z'
    );
    const absoluteTimeCurr = this.getAbsoluteTime(
      exactRequiredDate,
      `${curr}T` + '00:00:00.000Z'
    );
    return (absoluteTimePrev < absoluteTimeCurr ? prev : curr);
  }, this));
  return res;
};

OceanoNcwmsUtils.prototype.getNearestTime = function (date, previousTime, values) {
  const exactPreviousTimeISO = `${date}T${previousTime}`;
  const exactPreviousTime = new Date(exactPreviousTimeISO).getTime();
  let res = null;
  if (values && values[date]) {
    const times = values[date];
    res = times.reduce(_.bind(function (prev, curr) {
      const absoluteTimePrev = this.getAbsoluteTime(
        exactPreviousTime,
        `${date}T${prev}`
      );
      const absoluteTimeCurr = this.getAbsoluteTime(
        exactPreviousTime,
        `${date}T${curr}`
      );
      return (absoluteTimePrev < absoluteTimeCurr ? prev : curr);
    }, this));
  }
  return res;
};

OceanoNcwmsUtils.prototype.getSortedMomentArrayFromDatesStructure = function (dates) {
  const layerIsoTimeValues = this.flattenDateArray(dates);
  const layerMoments = layerIsoTimeValues.map(isoDate => new moment(isoDate));
  return layerMoments.sort((m1, m2) => m1.diff(m2));
};

OceanoNcwmsUtils.prototype.getAbsoluteTime = function (previousTime, dateISO) {
  const time = new Date(dateISO).getTime();
  return Math.abs(previousTime - time);
};

OceanoNcwmsUtils.prototype.getLastTimeValue = function (values) {
  if (!values) {
    return null;
  }
  const sortedValues = this.sortDates(
    Object.keys(values)
  );

  const lastDate = sortedValues[sortedValues.length - 1];
  const lastTime = values[lastDate][values[lastDate].length - 1];
  return `${lastDate}T${lastTime}`;
};

OceanoNcwmsUtils.prototype.getClosestTimeFromTimeStructure = function (timeStr, timeStruct) {
  const momtAbsDiff = (timeStr1, timeStr2) => Math.abs(moment(timeStr1, 'HH:mm').diff(moment(timeStr2, 'HH:mm')));
  const closestTime = timeStruct.reduce((prev, curr) => (momtAbsDiff(curr[0], timeStr) < momtAbsDiff(prev[0], timeStr) ? curr : prev));
  return closestTime;
};

OceanoNcwmsUtils.prototype.getModalFormattedDate = function (momnt, utc) {
  momnt.utcOffset(utc);
  momnt.locale(window.portalLang);
  const momentToDateStringTemplate = `${this.getDateToStringTemplate()} HH:mm:ss`;
  return `${momnt.format(momentToDateStringTemplate)} ${this.utcFormat(utc)}`;
};

OceanoNcwmsUtils.prototype.addMetadataUrlToLayers = layers => {
  layers.each(layer => {
    const firstReg = layer.get('olLayer').getLayers().getArray()[0];
    const identifier = firstReg ? firstReg.get('identifier') : layer.get('identifier');
    const metadataURL = OceanoHelper.getMetadataUrl(identifier);
    layer.set('metadataURL', metadataURL);
  });
};

OceanoNcwmsUtils.prototype.getIdWithNcwmsValue = function (identifier) {
  const splitId = identifier.split('/');
  const model = splitId[0];
  const param = splitId[1];

  const regex = /^(.*)-group$/g;
  const isGroup = regex.exec(param);

  return (isGroup) ? `${model}/${isGroup[1]}-mag` : `${model}/${param}`;
};

OceanoNcwmsUtils.prototype.getNcwmsValueWithId = function (identifier) {
  const splitId = identifier.split('/');
  const model = splitId[0];
  const param = splitId[1];

  const regex = /^(.*)-mag$/g;
  const isGroup = regex.exec(param);

  return (isGroup) ? `${model}/${isGroup[1]}-group` : `${model}/${param}`;
};

// gets the utc value from the navigator.
// UTC values accepted: any decimal number between -12 and 14 (official extremal values)
OceanoNcwmsUtils.prototype.getNavUTC = function () {
  const currentDate = new Date();
  let localUTC = (-1) * Math.floor(currentDate.getTimezoneOffset() / 60);
  if (localUTC < -12 || localUTC > 14) {
    localUTC = 0; // default UTC.
  }
  return localUTC;
};

OceanoNcwmsUtils.prototype.utcFormat = function (utc) {
  return `(UTC${utc < 0 ? '' : '+'}${utc || 0})`;
};

OceanoNcwmsUtils.prototype.roundMomentsTo10minutes = function (moments) {
  const roundedMoments = [];
  for (let momntIdx = 0; momntIdx < moments.length; momntIdx++) {
    const momnt = moment(moments[momntIdx]);
    const minutesRemainder = momnt.minutes() % 10;
    if (minutesRemainder > 4) {
      momnt.add(10 - minutesRemainder, 'minutes').startOf('minute');
    } else {
      momnt.subtract(minutesRemainder, 'minutes').startOf('minute');
    }
    roundedMoments.push(momnt);
  }
  return roundedMoments;
};

OceanoNcwmsUtils.prototype.binaryFindInSortedMomentArray = function (uniqueSortedTimeValues, momnt, insertIfAbsent) {
  // Performs a binary search through a sorted array of moments.
  // the exactSearch allows one to specify if the function should return -1 if it can't find the right value.
  const compare = uniqueMomnt => {
    const diff = momnt.diff(uniqueMomnt);
    return Math.abs(diff) < 30000 ? 0 : diff;
  };

  let start = 0;
  let end = uniqueSortedTimeValues.length - 1;
  let index = Math.floor((end - start) / 2) + start;

  if (compare(uniqueSortedTimeValues[end]) > 0) {
    index = end + 1;
  } else {
    while (start < end) {
      const diff = compare(uniqueSortedTimeValues[index]);
      if (diff === 0) {
        break;
      } else if (diff < 0) {
        end = index;
      } else {
        start = index + 1;
      }
      index = Math.floor((end - start) / 2) + start;
    }
  }

  if (insertIfAbsent && (index > uniqueSortedTimeValues.length || compare(uniqueSortedTimeValues[index]) !== 0)) {
    uniqueSortedTimeValues.splice(index, 0, momnt);
  }
  return index;
};

OceanoNcwmsUtils.prototype.sortPalettes = function (layer) {
  if (!layer.attributes.palette) {
    return [];
  }
  const palNames = Object.keys(layer.get('palette').pals);
  return palNames.sort(_.bind((a, b) => {
    const pA = layer.attributes.palette.pals[a].priority;
    const pB = layer.attributes.palette.pals[b].priority;
    return (pA < pB ? -1 : (pA > pB ? 1 : 0));
  }, this));
};

module.exports = new OceanoNcwmsUtils();
