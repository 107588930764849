const LegendPreview = require('./legend-preview.js');
const styleHelper = require('../../../utils/gis/drawing/style-helper.js');
const colorsUtils = require('../../../utils/colors.js');

module.exports = LegendPreview.build({
  render() {
    this._style = this._feature.get('scStyle') || styleHelper.getStyle(this._feature);
    this.clear();

    const _fontInfo = this._getFontInfo();
    const _textStyle = this._style.getText();
    const _strokeThickness = _textStyle.getStroke()
      ? _textStyle.getStroke().getWidth()
      : this.noWidthValue;
    const _rgbaStrokeColor = _textStyle.getStroke()
      ? colorsUtils.getRgbaColorFromRgba(_textStyle.getStroke().getColor())
      : this.noColorValue;
    const _rgbaFillColor = _textStyle.getFill()
      ? colorsUtils.getRgbaColorFromRgba(_textStyle.getFill().getColor())
      : this.noColorValue;
    const fontSize = 8;
    const fontFamily = _fontInfo.family;
    const { bold } = _fontInfo;
    const { italic } = _fontInfo;

    this.ctx.save();

    const text = this.config.cartodyn.legendPreviewText;
    const halfY = ~~(this.el.height / 2);
    this.ctx.font = `${(bold ? 'bold ' : '') + (italic ? 'italic ' : '') + fontSize}pt ${fontFamily}`;
    if (_strokeThickness) {
      this.ctx.lineWidth = _strokeThickness;
      this.ctx.strokeStyle = _rgbaStrokeColor;
      this.ctx.strokeText(text, 0, halfY);
    }
    this.ctx.fillStyle = _rgbaFillColor;
    this.ctx.fillText(text, 0, halfY);

    this.ctx.restore();

    return this;
  },

  _getFontInfo() {
    const info = {};
    const fontSizeAndFamilyRegex = /(\d*)px[+\s]?(.*)/i;
    const fontString = this._style.getText().getFont();
    const sizeAndFamilyMatch = fontString.match(fontSizeAndFamilyRegex);
    if (sizeAndFamilyMatch && sizeAndFamilyMatch.length === 3) {
      info.fSize = +sizeAndFamilyMatch[1];
      info.family = sizeAndFamilyMatch[2];
    }
    info.bold = !!fontString.match(/bold\s/i);
    info.italic = !!fontString.match(/italic\s/i);

    return info;
  }
});
