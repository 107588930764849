var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"feature-info-menu\" id=\"ocean-feature-info-menu-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\"></div>\n<div class=\"verticalProfile-menu\" id=\"ocean-verticalProfile-menu-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\"></div>\n<div class=\"transect-menu\" id=\"ocean-transect-menu-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\"></div>\n<div class=\"download-menu\" id=\"ocean-download-menu-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\"></div>\n";
},"useData":true});