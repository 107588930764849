const _ = require('underscore');
const ShomView = require('../../core/shom-view');
const KmlView = require('./external-kml.view');
const OgcView = require('./external-ogc.view');
const template = require('../../../template/catalog/external-layers.hbs');

module.exports = ShomView.build({

  events: {
    'change #external-layers-type': '_updateContentView',
    'change #external-layers-feeds': '_updateContentView',
    'click .external-nav-list li': '_updateSelectedPane'
  },

  initialize(options) {
    const optionsToUse = options || {};
    this._startType = optionsToUse.startType || undefined;
    this._disableTypeChoice = optionsToUse.disableTypeChoice === true || false;
    this._subviewOptions = optionsToUse.subviewOptions || {};
    this._externalFeeds = this.collection || [];
    this._predefinedExternalLayerBtnId = '#add-predefined-externalLayer';
    this._personnalisedExternalLayerBtnId = '#add-personnalised-externalLayer';
    this._activePaneLink = this._startType === 'kml' ? this._personnalisedExternalLayerBtnId : this._predefinedExternalLayerBtnId;
  },

  render() {
    this.$el.html(template({
      startType: this._startType,
      disableTypeChoice: this._disableTypeChoice,
      externalFeeds: this._externalFeeds,
      nav: this._activePaneLink === this._predefinedExternalLayerBtnId ? 'predefined' : 'personnalised'
    }));

    this.$typeSelect = this.$el.find('#external-layers-type');
    this.$content = this.$el.find('.external-layers-content');
    this.$feedSelect = this.$el.find('#external-layers-feeds');

    this._updateContentView();

    return this;
  },

  _updateContentView() {
    this._destroyValidator();
    this._currentView && this._currentView.remove();

    if (this._activePaneLink === this._personnalisedExternalLayerBtnId) {
      this._currentView = this._buildViewByType(this.$typeSelect.val());
    } else {
      this._currentView = this._buildViewByExternalsFeeds(this.$feedSelect.val());
    }

    this.$content.html(this._currentView.render().$el);
    this._currentView.validator = this._buildFormValidatorForView(this._currentView);
  },

  _buildFormValidatorForView(view) {
    const $form = this.$('.external-layers-form').bootstrapValidator({
      feedbackIcons: {
        valid: 'glyphicon glyphicon-ok',
        invalid: 'glyphicon glyphicon-remove',
        validating: 'glyphicon glyphicon-refresh'
      },
      submitButtons: '.submit-form',
      fields: view.getFormValidatorFields()
    }).on('success.form.bv', view._onFormSubmit.bind(view));
    return $form.data('bootstrapValidator');
  },

  _buildViewByType(type) {
    const options = this._subviewOptions;
    options.isFromExternalFeed = false;
    switch (type) {
      case 'ncwms':
      case 'wms':
      case 'wfs':
      case 'wmts':
        options.service = type.toUpperCase();
        return new OgcView(options);
      case 'kml':
        return new KmlView(options);
    }
  },

  _buildViewByExternalsFeeds(selected) {
    const options = this._subviewOptions;
    let type = '';
    const selectedExternalFeed = _.findWhere(this._externalFeeds, { name: selected });

    if (selectedExternalFeed) {
      options.url = selectedExternalFeed.url;
      options.isFromExternalFeed = true;
      options.version = selectedExternalFeed.version;
      type = selectedExternalFeed.type.toLowerCase();

      switch (type) {
        case 'ncwms':
        case 'wms':
        case 'wfs':
        case 'wmts':
          options.service = type.toUpperCase();
          return new OgcView(options);
        case 'kml':
          return new KmlView(options);
      }
    }
  },

  _destroyValidator() {
    const validator = this.$el.find('.external-layers-form').data('bootstrapValidator');
    if (validator) {
      validator.destroy();
    }
  },

  _updateSelectedPane(ev) {
    this._activePaneLink = ev.target.getAttribute('href');
    this._updateContentView();
  }
});
