var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<span data-i18n=\"oceano.popup.label.loading\"></span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    Lat : "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"lat") : depth0), depth0))
    + " <br/>\n	Lon : "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"lon") : depth0), depth0))
    + " <br/>	\n    <span id=\"value-label\" data-i18n=\"\"></span>: <span id=\"result-popup-info-value\"></span> <br>\n    <span class=\"warning\" style=\"display: none;\">\n        <span class=\"glyphicon glyphicon-exclamation-sign\"></span>\n        <span id=\"result-popup-info-warning\"></span>\n    </span>\n    <span id=\"result-popup-info-label\"></span>\n\n\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ocea-result-popup\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loading") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":4,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"loading") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":16,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});