import { unByKey } from 'ol/Observable.js';

import {
  Circle, Fill, Stroke, Style, RegularShape, Text
} from 'ol/style.js';
import Select from 'ol/interaction/Select';

const _ = require('underscore');
const GisView = require('../gis.view.js');
const CoordsHelper = require('../../utils/gis/coordinates-helper.js');
const OceanoThemesPopup = require('../oceano/ocea-layers-popup.view');

GisView.prototype.initOceanogrammeGis = function (isInLandHandler) {
  if (this._config.oceanogramme.enableHoverLayer) {
    this.loadLayer(this._config.oceanogramme.homeLayerHover, true);
    const homeLayerHover = this.collection.findWhere({
      identifier: this._config.oceanogramme.homeLayerHover
    });
    homeLayerHover.set('opacity', 0.01);
  }

  this.initAndLoadOceanogrammeSpotsLayerStyle();
  this.createOceanogrammePointInteraction(isInLandHandler);
  this.createOceanogrammeSpotInteration();
  this._closeCurrentPopUp = null;
};

GisView.prototype.removeOceanogrammeGis = function () {
  this.loadLayer(this._config.oceanogramme.spotsLayer, false);
  this._pointerMoveEvent && unByKey(this._pointerMoveEvent);

  if (this._config.oceanogramme.enableHoverLayer) {
    this.loadLayer(this._config.oceanogramme.homeLayerHover, false);
    this.closePopUp();
  }

  this._map.removeInteraction(this._oceanogrammeSpotInteraction);
  unByKey(this._oceanogrammeMapClickEvent);
};

GisView.prototype.initAndLoadOceanogrammeSpotsLayerStyle = function () {
  const spotsLayer = this.collection.findWhere({
    identifier: this._config.oceanogramme.spotsLayer
  });
  spotsLayer.get('olLayer').setStyle(this._createOceanogrammePointStyleFunction.bind(this));

  this.loadLayer(this._config.oceanogramme.spotsLayer, true);

  return spotsLayer;
};

GisView.prototype._createOceanogrammePointStyleFunction = function (feature, resolution) {
  let outStyle;
  let fill;
  let stroke;
  const refmarPointFillColor = this._config.oceanogramme.point.refmar.fillColor;
  const refmarPointStrokeColor = this._config.oceanogramme.point.refmar.strokeColor;
  const refmarPointStrokeWidth = this._config.oceanogramme.point.refmar.strokeWidth;
  const otherPointFillColor = this._config.oceanogramme.point.others.fillColor;
  const otherPointStrokeColor = this._config.oceanogramme.point.others.strokeColor;
  const otherPointStrokeWidth = this._config.oceanogramme.point.others.strokeWidth;

  let forcedText = false;
  if (this._oceanogrammeHoveredFeature) {
    forcedText = feature.get('name') === this._oceanogrammeHoveredFeature.get('name');
  }

  const textStyle = new Text({
    textAlign: 'center',
    textBaseline: 'middle',
    font: 'bold 10px Arial',
    text: forcedText || resolution < this._config.oceanogramme.spotsNameResolution ? feature.get('toponyme') : '',
    fill: new Fill({ color: '#000000' }),
    stroke: new Stroke({ color: '#ffffff', width: 1 }),
    offsetX: 0,
    offsetY: 15,
    rotation: 0
  });

  if (feature.get('id_maregr')) {
    fill = new Fill({
      color: refmarPointFillColor
    });
    stroke = new Stroke({
      color: refmarPointStrokeColor,
      width: refmarPointStrokeWidth
    });

    outStyle = [new Style({
      image: new Circle({
        fill,
        stroke,
        radius: resolution > this._config.oceanogramme.spotsNameResolution ? 4 : 6
      }),
      fill,
      stroke,
      text: textStyle
    })
    ];
  } else {
    fill = new Fill({
      color: otherPointFillColor
    });
    stroke = new Stroke({
      color: otherPointStrokeColor,
      width: otherPointStrokeWidth
    });

    outStyle = [new Style({
      image: new RegularShape({
        fill,
        stroke,
        points: 3,
        radius: resolution > this._config.oceanogramme.spotsNameResolution ? 6 : 8,
        rotation: 0,
        angle: 0
      }),
      fill,
      stroke,
      text: textStyle
    })
    ];
  }

  return outStyle;
};

GisView.prototype._buildHomeLayerPopupContent = function (feature) {
  const oceanoThemesPopup = new OceanoThemesPopup({
    feature
  });

  return oceanoThemesPopup.render().$el.html();
};

GisView.prototype.closePopUp = function () {
  if (this._closeCurrentPopUp !== null) {
    this._closeCurrentPopUp();
    this._closeCurrentPopUp = null;
  }
};

GisView.prototype.createHoverInteraction = function () {
  this._pointerMoveEvent = this._map.on('pointermove', evt => {
    if (this._oceanogrammeHoveredFeature) {
      // reset style of the old hovered feature
      this._oceanogrammeHoveredFeature.setStyle(null);
      this._oceanogrammeHoveredFeature = null;
    }

    this.closePopUp();

    this._map.forEachFeatureAtPixel(
      evt.pixel,
      (feature, layer) => {
        if (layer && layer.get('title') === this._config.oceanogramme.spotsLayer) {
          // update style of the current hovered feature
          this._oceanogrammeHoveredFeature = feature;
          feature.setStyle(this._createOceanogrammePointStyleFunction(this._oceanogrammeHoveredFeature));
          return true;
        }
      }
    );

    this._map.forEachFeatureAtPixel(
      evt.pixel,
      (feature, layer) => {
        if (layer && layer.get('title') === this._config.oceanogramme.homeLayerHover) {
          // Open popup
          this._closeCurrentPopUp = this.addPopUp(evt.coordinate, this._buildHomeLayerPopupContent(feature)).closeFunc;
          return true;
        }
      }
    );
  });
};

GisView.prototype.createOceanogrammeSpotInteration = function () {
  this._oceanogrammeSpotInteraction = new Select();

  this._oceanogrammeSpotInteraction.on('select', e => {
    this.closePopUp();

    e.preventDefault();
    const feature = e.target.getFeatures().getArray()[0];

    if (!feature) {
      return;
    }

    if (feature.get('cst')) {
      this._router.navigate(`oceanogramme/spot/${feature.get('cst')}`, true);
    } else if (this.featureIsOceano(feature)) {
      const lonlat = CoordsHelper.convertMercatToLonLatModulo(this.currentCoord);
      this._router.navigate(`oceanogramme/point/${lonlat[0]};${lonlat[1]}`, true);
    }
  });

  this._map.addInteraction(this._oceanogrammeSpotInteraction);
};

GisView.prototype.createOceanogrammePointInteraction = function (isNotInLandHandler) {
  this._oceanogrammeMapClickEvent = this._map.on('click', evt => {
    const clickOnSpot = this._map.forEachFeatureAtPixel(
      evt.pixel,
      (feature, layer) => (layer && layer.get('title') === this._config.oceanogramme.spotsLayer)
    );

    // check no spot interaction
    if (!clickOnSpot) {
      const lonlat = CoordsHelper.convertMercatToLonLatModulo(evt.coordinate);

      isNotInLandHandler(lonlat[0], lonlat[1]).then(_.bind(
        () => {
          this._router.navigate(`oceanogramme/point/${lonlat[0]};${lonlat[1]}`, true);
        },
        this
      ));
    }
  });
};

GisView.prototype.isOceanogrammeLayerIdentifier = function (layerIdentifier) {
  const oceanogrammeLayerIdentifiers = [
    this._config.oceanogramme.homeLayerHover,
    this._config.oceanogramme.spotsLayer,
    this._config.oceanogramme.landsLayer
  ];
  return oceanogrammeLayerIdentifiers.includes(layerIdentifier);
};

GisView.prototype.isOceanogrammeLayer = function (layer) {
  return this.isOceanogrammeLayerIdentifier(layer.get('identifier'));
};

GisView.prototype.setForecastLayerChildren = function (parentLayer) {
  const oceanogrammeLayerIdentifiers = [
    this._config.oceanogramme.homeLayerHover,
    this._config.oceanogramme.spotsLayer,
    this._config.oceanogramme.landsLayer
  ];

  const children = [];
  oceanogrammeLayerIdentifiers.forEach(
    id => {
      const child = this.collection.findWhere({
        identifier: id,
        includedInMap: true
      });
      if (child) {
        children.push(child);
      }
    }
  );
  parentLayer.set('forecastChildren', children);
};

GisView.prototype.clearForecastLayerChildren = function (parentLayer) {
  parentLayer.set('forecastChildren', []);
};

/**
 * Teste si une feature est bien un océanogramme
 *
 * @param feature la feature à tester
 * @return {boolean} true si il s'agit bien d'une feature de la couche des spots océanogrammes
 */
GisView.prototype.featureIsOceano = function (feature) {
  // De la forme : OCEANOGRAMME_SPOTS_BDD_WFS:spots_oceanogramme_positions_modeles
  const splittedSpotsLayer = this._config.oceanogramme.spotsLayer.split(':');
  // De la forme : spots_oceanogramme_positions_modeles.XXX
  const splittedFeatureId = feature.getId().split('.');

  const shortSpotsLayer = splittedSpotsLayer.length === 2 ? splittedSpotsLayer[1] : splittedSpotsLayer[0];
  const shortFeatureLayer = splittedFeatureId[0];

  return (shortSpotsLayer === shortFeatureLayer);
};
