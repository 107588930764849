import {
  LAYERTYPE_FORECAST, LAYERTYPE_NCWMS, SC_DRAWING_MODULE, SC_MEASUREMENT_MODULE_ACTIVE
} from '../utils/constants';

import LayerModelUtils from '../utils/layer-model-utils';
import AtlasHelper from '../utils/atlas/atlas-utils';

const $ = require('jquery');
const Backbone = require('backbone');

const GlobalDataStore = require('../core/global-data-store');
const ToastrUtil = require('../utils/toastr.js');
const Loading = require('../utils/loading.js');
const ModalTimeseriesView = require('../view/oceano/ocea-timeseries-modal.view.js');
const CoordsHelper = require('../utils/gis/coordinates-helper.js');
const NCWMS = require('../service/ncwms');
const GfiUtils = require('../utils/gis/gfi-utils');
const DDMDataUtils = require('../utils/ddm/ddm-data-utils');
const OceanoLayerUtils = require('../utils/oceano/oceano-layer');

/**
 * @typedef {import('../view/modal/modal.view.js').default} ModalView
 */

module.exports = Backbone.Model.extend({

  initialize(options = {}) {
    this._config = options.config || window.CONFIG;
    this._gisView = options.gisView || window.GISVIEW;
    this._modalView = options.modalView || window.MODALVIEW;
    this._activeLayer = null;
    this.listenTo(this._modalView, 'hide', this._onModalHide);
    this._isStarted = false;
    this._serviceUrl = `${this._config.baseServicesUrl}{{key}}${this._config.gfi.wms_vector_path}`;
    this._lang = window.portalLang;
    this._ddmDataUtils = options.ddmDataUtils || new DDMDataUtils(this._config);
    this._spmDiffFromNearestPmFormat = this._config.tfs.diffFromNearestPmDateTimeFormat;
  },

  setActiveLayer(layer) {
    this._activeLayer = layer;
  },

  clearActiveLayer() {
    this.setActiveLayer(null);
  },

  isActive() {
    return !!this._activeLayer;
  },

  start(requestable) {
    if (!this._isStarted) {
      this._serviceUrl = `${this._config.baseServicesUrl}{{key}}${this._config.gfi.wms_vector_path}`;
      const { projection } = this._config;
      this._gisView.startGfiMode(this, this._serviceUrl, this._activeLayer, projection, requestable);
      this._isStarted = true;
    }
  },

  stop() {
    if (this._isStarted) {
      this._gisView.hidePopup();
      this._gisView.stopGfiMode();
      this._isStarted = false;
    }
  },

  gfiLayersHandler(layerDataList) {
    this._gfiLayerTypeHandler(layerDataList[0])
      .always(() => {
        layerDataList.shift();
        if (layerDataList.length) {
          // Recursive call, wait call following get and draw 'getFeatureInfo' result
          this.gfiLayersHandler(layerDataList);
        }
      });
  },

  _gfiLayerTypeHandler(dataLayer) {
    if (dataLayer.layerType === 'reference') {
      return this._getFeatureInfoReference(dataLayer);
    }

    if (dataLayer.layerType === 'oceano' || dataLayer.layerType === 'radarhf' || dataLayer.layerType === 'atlas') {
      const oceaLayerModel = dataLayer.catalogLayer;
      if (AtlasHelper.isAtlasLayer(oceaLayerModel)) {
        const utc = +this._gisView.getGlobalOceanoCurrentUTC() || 0;
        return $.Deferred().resolve(this.getDiffFromNearestPm(oceaLayerModel, utc)
          .then(result => {
            oceaLayerModel.set('currentCoeff', +result.coeff);
            return +result.diff;
          })
          .then(diffFromNearestSpm => AtlasHelper.computeNewDatetimeFromReference(diffFromNearestSpm, oceaLayerModel.get('olLayer').get('dimensions').processedTime))
          .then(datetime => OceanoLayerUtils.setNCWMSParamsInGroupLayer(oceaLayerModel, {
            date: datetime.utc().toISOString().split('T')[0],
            time: datetime.utc().toISOString().split('T')[1]
          }))
          .then(() => this._getFeatureInfoOceano(dataLayer))
          .then(() => this._getNCWMSTimeSeriesInfo(dataLayer))
          .then(() => this.getNcwmsMagnitudeDirection(dataLayer, dataLayer.catalogLayer.get('currentCoeff')))
          .then(values => dataLayer.gfiModal.insertAtlasValues(values, dataLayer.layerNumber))
          .fail(e => {
            console.error(e);
            ToastrUtil.error($.i18n.t('oceano.toaster.gfiUnreachable'));
          }));
      }
      this._getFeatureInfoOceano(dataLayer);
      return this._getNCWMSTimeSeriesInfo(dataLayer);
    }

    if (dataLayer.layerType === 'PARTNER') {
      const requestUrl = GfiUtils.getFeatureInfoPartner(dataLayer, this._gisView);
      return this._getFeatureInfoReference(dataLayer, requestUrl);
    }
    return $.Deferred().promise.resolve();
  },

  _getFeatureInfoReference(data, url) {
    const $map = $('#map-container');
    Loading.start($map, { isAbsolute: true });
    return $.get(url || data.url)
      .then(res => {
        Loading.stop($map);
        // text plain or xml
        if (url && !url.includes('INFO_FORMAT=text%2Fhtml')) {
          res = `<pre>${res}</pre>`;
        }
        this._onGetFeatureInfo(res, data);
      })
      .fail(e => {
        Loading.stop($map);
        console.error(e);
        this._onGetFeatureInfo($.i18n.t('oceano.toaster.gfiNoValidLayer'), data);
      });
  },

  _onGetFeatureInfo(content, data, error) {
    let result = content;
    if (!error) {
      try {
        // jshint ignore:line
        if (XMLDocument && content instanceof XMLDocument) {
          this._insertGfiModalData($.i18n.t('gfiWmtsPoi.errors.noData'), data);
          return;
        }
        const pattern = /<body[^>]*>((.|[\n\r])*)<\/body>/im;
        const arrayMatches = pattern.exec(content);
        if (arrayMatches && arrayMatches.length > 0) {
          const body = arrayMatches[1].trim();
          if (body.length === 0) {
            result = $.i18n.t('gfiWmtsPoi.errors.noData');
          }
        } else if (content.trim().length === 0) {
          result = $.i18n.t('gfiWmtsPoi.errors.noData');
        }
      } catch (e) {
        console.error('Error while parsing GFI response : ', e);
      }
    } else {
      console.error('Error during Gfi :', result);
    }
    this._insertGfiModalData(result, data);
  },

  _getFeatureInfoOceano(data) {
    try {
      const selectedDate = data.catalogLayer.get('selectedDate');
      this._getGfiTimeSeriesUrl(data, selectedDate);
    } catch (error) {
      console.error('The oceano layer does not have a timeseries :', error);
    }
  },

  _getGfiTimeSeriesUrl(data, selectedDate) {
    const coordLonLat = CoordsHelper.convertMercatToLonLatModulo(data.coord);
    /**
     * @type {ModalTimeseriesView}
     */
    const modalTimeSeries = new ModalTimeseriesView({
      layer: data.olLayer,
      model: data.catalogLayer,
      gisView: this._gisView,
      title: 'oceano.modal.timeseries.title',
      coord: data.coord,
      tileGrid: this._gisView.getNCWMSTileGrid(),
      scope: 'get-infos-gfi'
    });
    const urlTimeseries = modalTimeSeries.constructUrlTimeseries(data.catalogLayer, coordLonLat);
    this._insertTimeSeriesGfiModalData(data, urlTimeseries, selectedDate);
  },

  /**
   * @param data {{gfiModal: ModalView, coord: [number, number], olLayer: Ol.layer, layerNumber: number}}
   * @returns {Promise}
   * @private
   */
  _getNCWMSTimeSeriesInfo(data) {
    const ncwmsServiceOptions = { ncwmsLayerType: LayerModelUtils.getNcwmsLayerType(data.catalogLayer) };
    return NCWMS(ncwmsServiceOptions).then(NCWMSService => {
      const currentBbox = this._gisView.getCurrentViewBounds();
      const mapSize = this._gisView.getMapSize();
      return NCWMSService.getFeatureInfo(data.olLayer, mapSize, currentBbox, data.coord)
        .then(value => {
          data.gfiModal.insertOceanoTSValues(value, data.layerNumber);
        }).fail(() => {
          this._onGetFeatureInfo($.i18n.t('oceano.toaster.gfiNoValidLayer'), data);
        });
    });
  },

  getNcwmsMagnitudeDirection(data, coeff) {
    const ncwmsServiceOptions = { ncwmsLayerType: LayerModelUtils.getNcwmsLayerType(data.catalogLayer) };
    return NCWMS(ncwmsServiceOptions).then(NCWMSService => NCWMSService.getMagnitudeDirection(data, coeff));
  },

  /**
     * Send request to SPM in order to retrieve difference from nearest PM. For example: data = { diff: 1.23, coeff: 95 }
     * @param model the catalog layer
     * @param utc current utc as int
     * @returns promise
     * @private
     */
  getDiffFromNearestPm(model, utc) {
    return this._ddmDataUtils.getDiffFromNearestPm(
      model.get('portRef'),
      utc,
      this._gisView.getOceanoLayerCurrentMoment(model).format(this._spmDiffFromNearestPmFormat),
      model.get('isBm')
    );
  },

  /**
   * @param result {string}
   * @param data {{gfiModal: ModalView, layerTitle: string, layerNumber: number, layerType: string}}
   * @private
   */
  _insertGfiModalData(result, data) {
    try {
      data.gfiModal.updateLayerInformations({
        html: result, title: data.layerTitle, number: data.layerNumber, type: data.layerType, dataRoseCourant: data
      });
      data.gfiModal.addLayerData();
    } catch (e) {
      console.error('Error while inserting new data in the modal : ', e);
    }
  },

  /**
   * @typedef {import('../view/modal/modal.view.js').default} ModalView
   * @param data {{gfiModal: ModalView, layerTitle: string, layerNumber: number, layerType: string}}
   * @param url {string}
   * @param selectedDate {Date}
   * @private
   */
  _insertTimeSeriesGfiModalData(data, url, selectedDate) {
    try {
      data.gfiModal.updateTimeSeriesInformations({ coord: data.coord, date: selectedDate });
      data.gfiModal.updateLayerInformations({
        html: url, title: data.layerTitle, number: data.layerNumber, type: data.layerType, layer: data.catalogLayer, olLayer: data.olLayer, dataRoseCourant: data
      });
      data.gfiModal.addLayerData();
    } catch (e) {
      console.error('Error while inserting new data in the modal : ', e);
    }
  },

  _onModalHide() {
    if (this._activeLayer) {
      this._gisView.deleteGfiMarker();
    }
  },

  startGfiRequestableLayer(start) {
    const displayedLayers = GlobalDataStore.getDisplayedLayers();
    this.isLayerRequestable(displayedLayers);
    if (start) {
      this.start(this._isThereRequestableLayer);
    } else {
      this._gisView.setRequestableLayer(this._isThereRequestableLayer);
    }

    // If drawing or measurement module from shom-carto is active, we remove layer interrogation
    if (GlobalDataStore.get(SC_DRAWING_MODULE) || GlobalDataStore.get(SC_MEASUREMENT_MODULE_ACTIVE)) {
      this.stop();
    }
  },

  isLayerRequestable(displayedLayers) {
    this._isThereRequestableLayer = displayedLayers.some(layer => {
      const isExternalQuerable = layer.get('layerType') === 'PARTNER' && layer.get('queryable');
      const isLayerTypeRequestable = layer.get('gfiResourceWmsV')
      || layer.get('layerType') === LAYERTYPE_NCWMS
      || layer.get('layerType') === LAYERTYPE_FORECAST
      || isExternalQuerable;
      return isLayerTypeRequestable && layer.get('visibility');
    });
    this._gisView.setRequestableLayer(this._isThereRequestableLayer);
    return this._isThereRequestableLayer;
  }
});
