const _ = require('underscore');
const moment = require('moment');

const OceanoLayerUtils = function () {

};

OceanoLayerUtils.prototype.getIdentifiersFromLayerId = layerId => {
  const [datasetId, param] = layerId.split('/');
  // model can be undefined
  const [thematic, model] = datasetId.split('_');

  return {
    param,
    thematic,
    model,
    datasetId
  };
};

OceanoLayerUtils.prototype.getSelectedUnitFromLayer = function (layer) {
  const chosenUnitName = layer.get('selectedUnit');
  if (chosenUnitName) {
    return _.findWhere(layer.get('palette').units, { name: chosenUnitName });
  }
  return layer.get('palette').units[0];
};

OceanoLayerUtils.prototype.getPaletteFromName = function (layer, paletteName) {
  if (!paletteName) {
    return null;
  }
  const palette = layer.get('palette').pals[paletteName];
  const options = this.getNCWMSOptions(layer);
  const unitLayer = this.getNCWMSUnit(layer);
  const conversionLayer = this.getNCWMSConversion(layer);
  if (palette && palette.range) {
    return {
      name: paletteName,
      min: palette.range[0],
      max: palette.range[1],
      aboveMaxColor: palette.aboveMaxColor,
      belowMinColor: palette.belowMinColor,
      numContours: palette.numContours,
      numColorBands: palette.numColorBands,
      unit: unitLayer,
      conversion: conversionLayer,
      auto: palette.auto,
      options
    };
  }
  return null;
};

OceanoLayerUtils.prototype.getHoverOverTile = function (lonlat, olLayer) {
  let rightTile = null;
  for (const tilerow in olLayer.grid) {
    // eslint-disable-next-line no-prototype-builtins
    if (!olLayer.grid.hasOwnProperty(tilerow)) {
      continue;
    }
    for (const tilei in olLayer.grid[tilerow]) {
      // eslint-disable-next-line no-prototype-builtins
      if (!olLayer.grid[tilerow].hasOwnProperty(tilei)) {
        continue;
      }
      const tile = olLayer.grid[tilerow][tilei];
      if (tile.bounds.containsLonLat(lonlat)) {
        rightTile = tile;
        break;
      }
    }
  }
  return rightTile;
};

OceanoLayerUtils.prototype.getAllTimesBetweenDates = function (layer, beginTime, endTime) {
  const timeValues = layer.get('dimensions').processedTime;
  const times = [];
  for (const day in timeValues) {
    // eslint-disable-next-line no-prototype-builtins
    if (!timeValues.hasOwnProperty(day)) {
      continue;
    }
    for (const time in timeValues[day]) {
      // eslint-disable-next-line no-prototype-builtins
      if (!timeValues[day].hasOwnProperty(time)) {
        continue;
      }
      const dateISO = `${day}T${timeValues[day][time]}`;
      const t = new Date(dateISO);
      if (t >= beginTime && t <= endTime) {
        times.push(t);
      }
    }
  }
  return times;
};

OceanoLayerUtils.prototype.getTitleTranslateLayer = function (layer) {
  const title = layer.get('title');
  if (layer.get('translation') && layer.get('translation')[title]) {
    return layer.get('translation')[title];
  }
  return title;
};

OceanoLayerUtils.prototype.getSelectParamsFromGroupLayer = function (groupLayer) {
  const layers = groupLayer.get('olLayer').getLayers().getArray();
  if (!layers || layers.length === 0) {
    return null;
  }
  const firstReg = layers[0];
  const ncwmsLayer = firstReg.getLayers().getArray()[1];
  const params = ncwmsLayer.getSource().getParams();
  const dateTime = params.TIME && params.TIME.split('T');
  return {
    date: dateTime && dateTime[0],
    time: dateTime && dateTime[1],
    depth: params.ELEVATION,
    palette: params.STYLES
  };
};

OceanoLayerUtils.prototype.getSelectParamsFromLayer = function (layer) {
  const params = layer.get('olLayer').getSource().getParams();
  const dateTime = params.TIME && params.TIME.split('T');
  return {
    date: dateTime && dateTime[0],
    time: dateTime && dateTime[1],
    depth: params.ELEVATION,
    palette: params.STYLES
  };
};

OceanoLayerUtils.prototype.getNCWMSOptions = function (layer) {
  return layer.get('ncwmsOptions');
};

OceanoLayerUtils.prototype.getNCWMSUnit = function (layer) {
  return layer.get('unit');
};

OceanoLayerUtils.prototype.getNCWMSConversion = function (layer) {
  return layer.get('conversion');
};

OceanoLayerUtils.prototype.getNCWMSPaletteParams = function (palette, jsonPal) {
  let aboveMaxColor; let belowMinColor; let numContours; let numColorBands; let
    style;
  const { auto } = jsonPal;
  const colorscalerange = (palette.isAuto)
    ? `${palette.min},${palette.max}`
    : `${jsonPal.min},${jsonPal.max}`;
  if (palette.isAuto && auto) {
    style = auto.name || palette.name;
    aboveMaxColor = auto.aboveMaxColor;
    belowMinColor = auto.belowMinColor;
    numContours = auto.numContours;
    numColorBands = auto.numColorBands;
  } else {
    style = palette.name;
    aboveMaxColor = jsonPal.aboveMaxColor;
    belowMinColor = jsonPal.belowMinColor;
    numContours = jsonPal.numContours;
    numColorBands = jsonPal.numColorBands;
  }

  return {
    STYLES: style,
    COLORSCALERANGE: colorscalerange,
    ABOVEMAXCOLOR: aboveMaxColor || 'extend',
    BELOWMINCOLOR: belowMinColor || 'extend',
    NUMCONTOURS: numContours || 10,
    NUMCOLORBANDS: numColorBands || 250
  };
};

OceanoLayerUtils.prototype.getPaletteAutoParams = function (layer, paletteName) {
  if (!paletteName) {
    return null;
  }
  const palette = layer.get('palette').pals[paletteName];
  const { auto } = palette;
  const params = {};
  params.name = (auto && auto.name) ? auto.name : palette.name;
  if (auto && auto.numColorBands) {
    params.numColorBands = auto.numColorBands;
  } else if (palette.numColorBands) {
    params.numColorBands = palette.numColorBands;
  }
  return params;
};

OceanoLayerUtils.prototype.getCurrentTimeFromLayer = function (layer) {
  // get the nearest layer time from the current time
  const dateMoment = moment();
  const dateISO = dateMoment.toISOString().split('T')[0];
  const time = dateMoment.valueOf();
  const dimensions = layer.get('dimensions').processedTime;
  let res = null;

  if (dimensions) {
    if (!dimensions[dateISO]) {
      // if the current date is not available - we get the first date
      const firstDate = _.keys(dimensions)[0];
      return `${firstDate}T${dimensions[firstDate][0]}`;
    }
    const times = dimensions[dateISO];
    res = times.reduce(_.bind(function (prev, curr) {
      const absoluteTimePrev = this.getAbsoluteTime(
        time,
        `${dateISO}T${prev}`
      );
      const absoluteTimeCurr = this.getAbsoluteTime(
        time,
        `${dateISO}T${curr}`
      );
      return (absoluteTimePrev < absoluteTimeCurr ? prev : curr);
    }, this));
  }

  res = (res) ? `${dateISO}T${res}` : null;
  return res;
};

OceanoLayerUtils.prototype.getAbsoluteTime = function (previousTime, dateISO) {
  const time = moment(dateISO).valueOf();
  return Math.abs(previousTime - time);
};

OceanoLayerUtils.prototype.getDefaultStyleParams = function (layerGroup) {
  const params = {};

  const { pals } = layerGroup.get('palette');

  // get the best priotity pal
  const defaultPalName = _.reduce(Object.keys(pals), (prevPalName, palName) => {
    const prevPal = pals[prevPalName];
    const pal = pals[palName];
    return (prevPal.priority < pal.priority) ? prevPalName : palName;
  });

  const defaultPal = pals[defaultPalName];
  params.style = defaultPalName;
  params.colorScaleRange = `${defaultPal.range[0]},${defaultPal.range[1]}`;
  params.aboveMaxColor = defaultPal.aboveMaxColor || 'extend';
  params.belowMinColor = defaultPal.belowMinColor || 'extend';
  params.numContours = defaultPal.numContours || 10;
  params.numColorBands = defaultPal.numColorBands || 250;

  return params;
};

OceanoLayerUtils.prototype.setZonesNcwmsParams = function (layerGroup, params) {
  const regLayers = layerGroup.get('olLayer').getLayers().getArray();
  const paramsLayers = {};
  const paramsEncoding = {};
  params = params || {};

  if (!params.style) {
    const paramsStyleDefault = this.getDefaultStyleParams(layerGroup);
    paramsLayers.STYLES = paramsStyleDefault.style;
    paramsLayers.COLORSCALERANGE = paramsStyleDefault.colorScaleRange;
    paramsLayers.ABOVEMAXCOLOR = paramsStyleDefault.aboveMaxColor;
    paramsLayers.BELOWMINCOLOR = paramsStyleDefault.belowMinColor;
    paramsLayers.NUMCONTOURS = paramsStyleDefault.numContours;
    paramsLayers.NUMCOLORBANDS = paramsStyleDefault.numColorBands;
  } else {
    paramsLayers.STYLES = params.style;
    paramsLayers.ABOVEMAXCOLOR = params.aboveMaxColor;
    paramsLayers.COLORSCALERANGE = params.colorScaleRange;
    paramsLayers.BELOWMINCOLOR = params.belowMinColor;
    paramsLayers.NUMCONTOURS = params.numContours;
    paramsLayers.NUMCOLORBANDS = params.numColorBands;
    paramsEncoding.ELEVATION = params.elevation;
  }

  const { encoding } = layerGroup.get('palette');
  paramsEncoding.COLORSCALERANGE = `${encoding[0]},${encoding[1]}`;

  let layerGroups = regLayers[0];
  let nextlayerGroups = layerGroups.getLayers();
  while (nextlayerGroups.getLayers) { // in case, layerGroups is a collection of layerGroups
    layerGroups = nextlayerGroups;
    nextlayerGroups = layerGroups.getLayers();
  }

  // get the default "elevation" parameter value.
  const elev = +nextlayerGroups.getArray()[0].getProperties().dimensions.elevation[0];
  paramsEncoding.ELEVATION = elev;
  paramsLayers.ELEVATION = elev;

  if (params.coeff) {
    paramsEncoding.COEFF = params.coeff;
    paramsLayers.COEFF = params.coeff;
  }

  _.each(regLayers, _.bind(function (regLayer) {
    const layers = regLayer.getLayers().getArray();
    const ncwmsLayer = layers[1];
    const encodingLayer = layers[0];
    const ncwmsSource = ncwmsLayer.getSource();
    const encodingSource = encodingLayer.getSource();

    if (!params.time) {
      params.time = this.getCurrentTimeFromLayer(ncwmsLayer);
    }
    paramsEncoding.TIME = params.time;
    paramsLayers.TIME = params.time;

    encodingSource.updateParams(paramsEncoding);
    encodingLayer.setSource(encodingSource);
    ncwmsSource.updateParams(paramsLayers);
    ncwmsLayer.setSource(ncwmsSource);
  }, this));
};

OceanoLayerUtils.prototype.setNCWMSIdentifier = function (layer, model, param) {
  layer.set('identifier', `${model}/${param}`);
};

OceanoLayerUtils.prototype.setNCWMSPalette = function (layer, palette) {
  layer.set('palette', palette);
};

OceanoLayerUtils.prototype.setNCWMSOptions = function (layer, ncwmsOptions) {
  layer.set('ncwmsOptions', ncwmsOptions);
};

OceanoLayerUtils.prototype.setCatalog = function (layer, catalog) {
  layer.set('catalog', catalog);
};

OceanoLayerUtils.prototype.setCategory = function (layer, category) {
  layer.set('category', category);
};

OceanoLayerUtils.prototype.setTimeseriesName = function (layer, name) {
  layer.set('timeseriesName', name);
};

OceanoLayerUtils.prototype.setModelType = function (layer, datasetId, types) {
  let layerTyped;

  // dirty fix for WW3.
  // If no model type is found in the name of the dataset we will take WW3
  for (const i in types) {
    if (types.hasOwnProperty(i)) {
      const type = types[i];

      if (layerTyped && (datasetId.lastIndexOf(layerTyped, 0) !== 0)) {
        break;
      }

      if (datasetId.indexOf(type) !== -1) {
        layerTyped = type;
      }
    }
  }

  layer.set('modelType', layerTyped);
};

OceanoLayerUtils.prototype.setIndexInCat = function (layer, indexInCat) {
  layer.set('indexInCat', indexInCat);
};

OceanoLayerUtils.prototype.setCategory = function (layer, category) {
  layer.set('category', category);
};

OceanoLayerUtils.prototype.setNCWMSLegendUrl = function (layer, legendUrl) {
  layer.set('legendUrl', legendUrl);
};

OceanoLayerUtils.prototype.setCopernicusLink = function (layer, copernicusLink) {
  layer.set('copernicusLink', copernicusLink);
};

OceanoLayerUtils.prototype.setDownloadBlacklist = function (layer, downloadBlacklist) {
  layer.set('downloadBlacklist', downloadBlacklist);
};

OceanoLayerUtils.prototype.setNCWMSParamsInLayer = function (regLayer, values, group) {
  const layers = regLayer.getLayers().getArray();
  const ncwmsLayer = layers[1];
  const encodingLayer = layers[0];
  const ncwmsSource = ncwmsLayer.getSource();
  const encodingSource = encodingLayer.getSource();

  let params = {};
  const encodingParams = {};
  if (values.date !== undefined && values.time !== undefined) {
    encodingParams.TIME = `${values.date}T${values.time}`;
    params.TIME = `${values.date}T${values.time}`;
  }
  if (values.depth !== undefined) {
    encodingParams.ELEVATION = values.depth;
    params.ELEVATION = values.depth;
  }

  if (values.coeff !== undefined) {
    encodingParams.COEFF = values.coeff;
    params.COEFF = values.coeff;
  }

  if (values.pal !== undefined) {
    const jsonPal = this.getPaletteFromName(group, values.pal.name);
    const styleParams = this.getNCWMSPaletteParams(values.pal, jsonPal);
    const { encoding } = group.get('palette');
    encodingParams.COLORSCALERANGE = `${encoding[0]},${encoding[1]}`;
    params = _.extend(params, styleParams);
  }

  encodingSource.updateParams(encodingParams);
  encodingLayer.setSource(encodingSource);

  ncwmsSource.updateParams(params);
  ncwmsLayer.setSource(ncwmsSource);
};

OceanoLayerUtils.prototype.setNCWMSParamsInGroupLayer = function (group, values) {
  if (values.date !== undefined && values.time !== undefined) {
    group.set('selectedDate', values.date);
    group.set('selectedTime', values.time);
  }
  if (values.depth !== undefined) {
    group.set('selectedElevation', values.depth);
  }

  if (values.coeff !== undefined) {
    group.set('selectedCoeff', values.coeff);
  }

  const regLayers = group.get('olLayer').getLayers().getArray();
  _.each(regLayers, _.bind(function (regLayer) {
    this.setNCWMSParamsInLayer(regLayer, values, group);
  }, this));
};

OceanoLayerUtils.prototype.setZoomAllowed = function (layer, zoomAllowed) {
  layer.set('zoomAllowed', zoomAllowed);
};

OceanoLayerUtils.prototype.setPortRef = function (layer, portRef) {
  layer.set('portRef', portRef);
};

OceanoLayerUtils.prototype.setIsBm = function (layer, isBm) {
  layer.set('isBm', isBm);
};

OceanoLayerUtils.prototype.setTitle = function (layer, title) {
  layer.set('title', title);
};

OceanoLayerUtils.prototype.setNumAtlas = function (layer, numAtlas) {
  layer.set('numAtlas', numAtlas);
};

OceanoLayerUtils.prototype.setTranslation = function (layer, translation) {
  layer.set('translation', translation);
};

OceanoLayerUtils.prototype.setUnit = function (layer, unit) {
  layer.set('unit', unit);
};

OceanoLayerUtils.prototype.setConversion = function (layer, conversion) {
  layer.set('conversion', conversion);
};

OceanoLayerUtils.prototype.setIrregularGrids = function (layer, irregular_grids) {
  layer.set('irregular_grids', irregular_grids);
};

OceanoLayerUtils.prototype.setOriginators = function (layer, originators, imageDirectory) {
  const res = [];
  let filename = '';
  _.each(originators, originator => {
    filename = originator.name.split('/')[1].split('.gif')[0];
    res.push({ logo: imageDirectory + originator.name, name: filename, url: originator.url });
  });
  layer.set('originators', res);
};

OceanoLayerUtils.prototype.setLoadEndEvent = function (layer, loadendCallback) {
  const olLayer = layer.get('olLayer');
  const tileOptions = olLayer.tileOptions || {};

  tileOptions.eventListeners = {
    loadend: loadendCallback
  };

  olLayer.tileOptions = tileOptions;
};

OceanoLayerUtils.prototype.setDefaultParams = function (layer) {
  layer.set('opacity', 1);
  layer.set('visibility', true);
};

OceanoLayerUtils.prototype.hideLayer = function (layer) {
  layer.set('includedInMap', false);
};

OceanoLayerUtils.prototype.displayLayer = function (layer) {
  layer.set('includedInMap', true);
};

OceanoLayerUtils.prototype.getCategoryTitle = function (layer, lang = 'fr') {
  const translations = layer.get('translation');
  const { thematic } = this.getIdentifiersFromLayerId(layer.get('identifier'));
  return (translations && translations[thematic]) ? translations[thematic][lang] : thematic;
};

OceanoLayerUtils.prototype.getLayerTitle = function (layer, lang = 'fr') {
  const translations = layer.get('translation');
  const title = layer.get('title');
  return (translations && translations[title]) ? translations[title][lang] : title;
};

OceanoLayerUtils.prototype.getUtcDate = function (layer) {
  const date = moment.utc(
    `${layer.get('selectedDate')} ${layer.get('selectedTime').split('.')[0]}`,
    'YYYY-MM-DD HH:mm'
  ).add(layer.get('selectedUtc'), 'hours');
  return date;
};

OceanoLayerUtils.prototype.setUpAutoPaletteOnLayer = function (layer, paletteName, minMax) {
  const options = this.getNCWMSOptions(layer);
  const unitLayer = this.getNCWMSUnit(layer);
  const conversionLayer = this.getNCWMSConversion(layer);
  const roundMinMax = this.roundMinMaxValue(minMax.min, minMax.max, options);
  const paletteAutoParams = this.getPaletteAutoParams(layer, paletteName);
  const paletteAuto = {
    min: roundMinMax.min,
    max: roundMinMax.max,
    name: paletteName,
    autoName: paletteAutoParams.name,
    unit: unitLayer,
    conversion: conversionLayer,
    options,
    numColorBands: paletteAutoParams.numColorBands,
    isAuto: true
  };
  this.setNCWMSParamsInGroupLayer(layer, {
    pal: paletteAuto
  });
  return paletteAuto;
};

OceanoLayerUtils.prototype.roundMinMaxValue = function (min, max, options) {
  let delta = 0; // % of min-max gap
  let borneMax; let
    borneMin; // min, max value available
  if (options) {
    delta = (options.delta && parseFloat(options.delta)) || delta;
    borneMax = (options.borneMax || options.borneMax === 0) && parseFloat(options.borneMax);
    borneMin = (options.borneMin || options.borneMin === 0) && parseFloat(options.borneMin);
  }
  // add or remove delta
  const offset = ((max - min) * delta) / 100;
  let maxValue = max + offset;
  let minValue = min - offset;

  // apply borneMin/borneMax
  minValue = ((borneMin || borneMin === 0) && minValue < borneMin) ? borneMin : minValue;
  maxValue = ((borneMax || borneMax === 0) && maxValue > borneMax) ? borneMax : maxValue;

  return {
    min: minValue,
    max: maxValue
  };
};

module.exports = new OceanoLayerUtils();
