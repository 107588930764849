var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-group required\">\n	<label for=\"external-kml-name\" class=\"col-sm-2 control-label\" data-i18n=\"catalog.external.kml.name.label\"></label>\n	<div class=\"col-sm-10\">\n		<input type=\"text\" class=\"form-control\" id=\"external-kml-name\" name=\"name\" data-i18n=\"[placeholder]catalog.external.kml.name.placeholder\">\n	</div>\n</div>\n<div class=\"form-group\">\n	<label for=\"external-kml-source\" class=\"col-sm-2 control-label\" data-i18n=\"catalog.external.kml.source.label\"></label>\n	<div class=\"col-sm-10\">\n		<select class=\"form-control\" id=\"external-kml-source\" name=\"source\" "
    + alias2(__default(require("../../js/hbs-helpers/ifinline.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"importKmlToDrawingLayer") : depth0),"disabled",{"name":"ifinline","hash":{},"data":data,"loc":{"start":{"line":10,"column":70},"end":{"line":10,"column":123}}}))
    + ">\n			<option value=\"local\" data-i18n=\"catalog.external.kml.source.local.label\" "
    + alias2(__default(require("../../js/hbs-helpers/ifinline.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"importKmlToDrawingLayer") : depth0),"selected",{"name":"ifinline","hash":{},"data":data,"loc":{"start":{"line":11,"column":77},"end":{"line":11,"column":130}}}))
    + "></option>\n			<option value=\"remote\" data-i18n=\"catalog.external.kml.source.remote.label\"></option>\n		</select>\n	</div>\n</div>\n<div class=\"form-group required kml-source kml-local-source\">	\n	<label for=\"external-kml-local-source-browse\" class=\"col-sm-2 control-label\" data-i18n=\"catalog.external.kml.source.local.browse\"></label>\n	<div class=\"col-sm-10\">\n		<input type=\"file\" id=\"external-kml-local-source-browse\" name=\"file\" />\n	</div>\n</div>\n<div class=\"form-group required kml-source kml-remote-source\">\n	<label for=\"external-kml-local-source-url\" class=\"col-sm-2 control-label\" data-i18n=\"catalog.external.kml.source.remote.url.label\"></label>\n	<div class=\"col-sm-10\">\n		<input type=\"url\" class=\"form-control\" id=\"external-kml-local-source-url\" name=\"url\" data-i18n=\"[placeholder]catalog.external.kml.source.remote.url.placeholder\">\n	</div>\n</div>\n";
},"useData":true});