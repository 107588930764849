const $ = require('jquery');
const Dialog = require('bootstrap-dialog');
const ShomView = require('../../core/shom-view');
const ValidatorFields = require('../../data/forms/external-ogc');
const CapabilitiesFetcher = require('../../utils/gis/capabilities-fetcher');
const LayersFactory = require('../../utils/layers-factory');
const LayersView = require('./external-ogc-layers.view');
const template = require('../../../template/catalog/external-ogc.hbs');

module.exports = ShomView.build({

  events: {
    'click .get-layers-button': '_onUrlChange'
  },

  initialize(options = {}) {
    this._capabilitiesHelper = options.capabilitiesHelper || new CapabilitiesFetcher({ config: window.CONFIG });
    this.collection = this.collection || LayersFactory.build();
    this._applicationLayers = options.applicationLayers || window.LAYERS;
    this._modalView = options.modalView || window.MODALVIEW;
    this._layersView = new LayersView({
      collection: this.collection
    });
    this._service = options.service;
    this._isFromExternalFeed = options.isFromExternalFeed || false;
    this._url = options.url || '';
    this._version = options.version;
    this._gfiModeManager = options.gfiModeManager || window.POI_MODE_MANAGER;
  },

  render() {
    this.$el.html(template({
      isFromExternalFeed: this._isFromExternalFeed
    }));
    this.$fetchButton = this.$el.find("button[name='fetch-capabilities']");
    this.$layerSelect = this.$el.find("select[name='layer']");
    this.$login = this.$el.find("input[name='login']");
    this.$password = this.$el.find("input[name='password']");
    this.$version = this.$el.find("select[name='version']");
    this.$url = this.$el.find("input[name='url']");
    this._layersView.setElement(this.$layerSelect).render();

    if (this._isFromExternalFeed) {
      this.$version.val(this._version);
      this.$url.val(this._url);
    }

    return this;
  },

  getFormValidatorFields() {
    return ValidatorFields();
  },

  _onUrlChange() {
    if (this.validator && this.validator.isValidField('url')) {
      const login = this.$login.val();
      const password = this.$password.val();
      let auth = null;
      if (login !== '' && password !== '') {
        auth = {};
        auth.login = login;
        auth.password = password;
      }
      const version = this.$version.val() === 'AUTO' ? null : this.$version.val();
      this._capabilitiesHelper.getLayersFromCapabilities(this._service, this._getUrlValue(), auth, false, version, { layerType: 'PARTNER' })
        .always(layers => {
          if (!layers || layers.length === 0) {
            Dialog.show({
              type: Dialog.TYPE_DANGER,
              title: $.i18n.t('error.application.unknown.title'),
              message: $.i18n.t('error.ogc.capabilities.empty')
            });
          } else {
            this.collection.reset(LayersFactory.build(layers, {
              external: true
            }).models);
            this._gfiModeManager.startGfiRequestableLayer(false);
          }
        });
    }
  },

  _onFormSubmit(event) {
    (event.preventDefault) ? event.preventDefault() : event.returnValue = false;

    const $form = $(event.target);
    const validator = $form.data('bootstrapValidator');

    if (validator.isValid()) {
      validator.disableSubmitButtons(false);
    }

    const cid = this.$layerSelect.val();
    const layer = this.collection.get(cid);
    this._applicationLayers.add(layer);
    layer.set('includedInMap', true);
    this._modalView.hide();
  },

  _isFetchCapabilitiesButton(button) {
    return button.get(0) === this.$fetchButton.get(0);
  },

  _getUrlValue() {
    return this.$url.val();
  }

});
