import WMC from '../../mixins/openlayers/wmc';

const $ = require('jquery');

const isValidXml = function (doc) {
  try {
    let xml;
    let test = $.isXMLDoc(doc);
    if (!test) {
      xml = $.parseXML(doc);
      test = $.isXMLDoc(xml);
    }
    return !!test;
  } catch (e) {
    return false;
  }
};

const containsWrongNamespace = function (doc) {
  if (typeof doc === 'string') {
    return doc.indexOf('xmlns="http://www.opengeospatial.net/context"') >= 0;
  }
  if (doc.getElementsByTagNameNS) {
    const root = doc.getElementsByTagNameNS('http://www.opengeospatial.net/context', 'ViewContext')[0];
    return !!root;
  }
};

const getFormat = function (doc, user) {
  const format = new WMC(
    {
      wrongNamespace: containsWrongNamespace(doc),
      shomUser: user
    }
  );

  return format;
};

const isValidResult = function (result) {
  return !!result.layersContext;
};

const ContextReader = function (options) {
  this._user = options.user;
};

ContextReader.prototype.read = function (doc) {
  if (!doc || !isValidXml(doc)) {
    return {};
  }
  const format = getFormat(doc, this._user);
  const result = format.read(doc);
  return isValidResult(result) ? result : {};
};

module.exports = ContextReader;
