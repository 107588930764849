const $ = require('jquery');
const _ = require('underscore');
const ShomView = require('../../../core/shom-view');

const template = require('../../../../template/drawing/discuss/invitation-login-modal.hbs');

const FormValidationData = require('../../../data/forms/discuss-join-form');

const Loading = require('../../../utils/loading');
const User = require('../../../model/user');

module.exports = ShomView.build({

  events: {
    'click .confirm-button': '_onClickConfirm',
    'change input[name="do-login"]': '_onConnectClicked'
  },

  initialize(options = {}) {
    this.config = options.config || window.CONFIG;
    this._parent = options.parent;
    this._router = options.router || window.ROUTER;
    this._session = options.session;
    this._modalView = options.modalView;
    this.confirmCallback = options.confirmCallback;
    _.bindAll(this, 'onJoinFormSubmit', '_exitSuccess', '_onPermissionsFetched');
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template());
    this.$('form').bootstrapValidator({
      submitButtons: 'button',
      fields: FormValidationData()
    }).on('success.form.bv', _.bind(this.onJoinFormSubmit, this));
    this._onConnectClicked();
    return this;
  },

  _checkIsValid() {
    const alias = this.$('#alias-session').val();
    return alias.trim() !== '';
  },

  _onClickConfirm() {
    this.$('form').submit();
    return false;
  },

  _onConnectClicked() {
    const isChecked = this.$('input[name="do-login"]').is(':checked');
    this.$('.user-credentials')[isChecked ? 'fadeIn' : 'fadeOut']();
    const $form = this.$('form').data('bootstrapValidator');

    $form.enableFieldValidators('user-login', isChecked);
    $form.enableFieldValidators('user-password', isChecked);
  },

  showError(i18nKey) {
    const $error = this.$('.connection-error');

    $error.empty();

    $("<div class='help-block'></div>")
      .html($.t(i18nKey))
      .appendTo($error);
  },

  onJoinFormSubmit() {
    const connect = this.$('input[name="do-login"]').is(':checked');
    Loading.start(this.$('.modal-content'));
    const { user } = this._router;
    if (user.get('isLoggedIn')) {
      user.anonymous();
    }
    if (connect) {
      const userLogin = this.$('input[name=user-login]').val();
      const getUserKeyUrl = `${this.config.accountSynchro.getUserKeyUrl}/${userLogin}`;
      $.get({
        url: getUserKeyUrl,
        async:false
      }).done(userKey => {
        this.logUser(new User({
          key: userKey,
          username: this.$('input[name=user-login]').val(),
          password: this.$('input[name=user-password]').val()
        }));
        if (this._session.get('permissions')) {
          this._onPermissionsFetched();
        } else {
          this.listenToOnce(this._session, 'change:permissions', this._onPermissionsFetched);
        }
      }).fail(error => {
        const errorKey = error.status === 400 ? 'user.errors.accountNotSynchro' : 'user.errors.requestfailure';
        this.showError(errorKey);
        Loading.stop(this.$('.modal-content'));
      });
    } else {
      this._exitSuccess();
    }
    return false;
  },

  _onPermissionsFetched() {
    const permissions = this._session.get('permissions');
    const hasAccess = permissions && permissions.hasCartoDyn;
    if (hasAccess) {
      this._exitSuccess();
    } else {
      Loading.stop(this.$('.modal-content'));
      this.showError('discuss.invitation.modal.join.hasNotSufficientPermission');
    }
  },

  _exitSuccess() {
    this.$('form');
    Loading.stop(this.$('.modal-content'));
    this._modalView.$el.one('hidden.bs.modal', _.bind(function () {
      this._alias = this.$('#alias-session').val();
      this.confirmCallback && this.confirmCallback({ alias: this._alias });
    }, this));
    this._modalView.hide();
  },

  logUser(usr){
    const deferred = $.Deferred();

    const self = this;
    const promise = usr.authenticate();
    const router = this._router;

    promise
      .then(() => router.user.loggedIn(usr))
      .fail(errorCode => {
        const errorKey = User.AUTH_ISSUES.BAD_CREDENTIALS === errorCode ? 'user.errors.badcredentials'
          : User.AUTH_ISSUES.NETWORK === errorCode ? 'user.errors.requestfailure' : '';

        self.showError(errorKey);
        deferred.reject(errorCode);
      })
      .then(loggedInUser => {
        deferred.resolve(loggedInUser);
      });
    return deferred;
  }

});
