const _ = require('underscore');
const $ = require('jquery');
const ShomView = require('../../core/shom-view');
const template = require('../../../template/drawing/open-map.hbs');
const CartodynStatsService = require('../../service/drawing/cartodyn-stats');

const OpenMapView = ShomView.build({

  events: {
    'click .btn-new-map': '_onNewMapClick',
    'click .goto-login': '_onClickGoToLogin'
  },

  initialize(options) {
    const optionsToUse = options || {};
    this._config = optionsToUse.config || window.CONFIG;
    this._router = optionsToUse.router || window.ROUTER;
    this._session = optionsToUse.session;
    this._user = optionsToUse.user || window.ROUTER.user;
    this.eventsBus = optionsToUse.eventsBus || window.EVENTBUS;

    this._cartodynStatsService = new CartodynStatsService({ cartodynUrl: this._config.cartodyn.statsUrl });

    this.listenTo(this._user, 'change:isLoggedIn', _.bind(this._onUserLogInOrOut, this));
  },

  render() {
    const loggedIn = this._user.get('isLoggedIn');
    let permissions = this._session.get('permissions');
    if (loggedIn) {
      if (!permissions) {
        this.listenToOnce(this._session, 'change:permissions', _.bind(this.render, this));
        return this;
      }
    } else {
      permissions = {
        hasCartoDyn: false,
        hasChatCarto: false
      };
    }
    this.$el.html(template({
      loggedIn,
      hasAccess: permissions.hasCartoDyn || permissions.hasChatCarto,
      createAccountUrl: $.i18n.t('drawing.link.connect.labelCreateAccount', { url: this._config.cartodyn.createAccountUrl })
    }));

    return this;
  },

  _onNewMapClick() {
    this._cartodynStats().then(_.bind(function () {
      this._router.navigate('dessin/edit', true);
    }, this));
  },

  _onUserLogInOrOut() {
    this.render();
  },

  _onClickGoToLogin(ev) {
    ev.preventDefault();
    this.eventsBus.trigger('user:openmenu');
    return false;
  },

  _cartodynStats() {
    const key = this._user.get('username') === 'anonymous' ? this._user.get('username') : this._user.get('key');
    return this._cartodynStatsService.cartodynHit(key);
  }

});

module.exports = OpenMapView;
