var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"datetime") || (depth0 != null ? lookupProperty(depth0,"datetime") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"datetime","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":16}}}) : helper)))
    + "</td>\n<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"dir") || (depth0 != null ? lookupProperty(depth0,"dir") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dir","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":11}}}) : helper)))
    + "</td>\n<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"mag") || (depth0 != null ? lookupProperty(depth0,"mag") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mag","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":11}}}) : helper)))
    + "</td>";
},"useData":true});