import { LAYERTYPE_REFMAR, LAYERTYPE_FORECAST } from '../utils/constants';

const _ = require('underscore');
const $ = require('jquery');
const ShomView = require('../core/shom-view');

module.exports = ShomView.build({

  className: 'add-remove-button',

  events: {
    click: '_onClick'
  },

  initialize(options = {}) {
    this._collection = options.collection;
    this._parentView = options.parentView;
    this.listenTo(this.model, 'change:includedInMap', _.bind(this.render, this));
  },

  render() {
    const $eltLayerTitleContainer = this.$el.parents('.catalog-layer-item').find('.layer-title-container').first();
    const $panelCollapseCategory = this.$el.parents('.panel-category').first();
    const $eltCategoryTitle = $panelCollapseCategory.find('.panel-title').first();
    const $categoryPanel = this.$el.parents('.collapse-category').first();
    const $subjectRoot = this.$el.parents('.subject-accordion').first();
    const $eltSubjectTitle = $subjectRoot.find('.subject-heading > .panel-title > a');
    const $subjectHeadingPanel = $subjectRoot.find('.subject-heading > .panel-title');
    const $warningOceano = $('#warning-oceano-param');

    if (this.model.get('includedInMap')) {
      this.$el.addClass('layer-included-in-map');

      // change layer title color to skyblue to indicate it's included in map
      $eltLayerTitleContainer.addClass('layer-included-in-map');

      // change category title color to skyblue to indicate it has at least one child layer included in map
      $eltCategoryTitle.addClass('layer-included-in-map');

      $eltSubjectTitle.addClass('subject-accordion-expanded');

      $subjectHeadingPanel.addClass('layer-included-in-map');

      $categoryPanel.collapse('show');
      this.$el.parents('.panel-collapse').first().collapse('show');

      $eltCategoryTitle.find('.accordion-toggle').first().removeClass('collapsed');

      // for subcategories
      if (this.$el.parents('.panel-category').length === 2) {
        // on both parents, we have to remove collapsed on the first panel
        const $panelCollapseCategoryParent = this.$el.parents('.panel-category');
        const $eltCategoryTitleParent = $panelCollapseCategoryParent.find('.panel-title').first();
        $eltCategoryTitleParent.addClass('layer-included-in-map');
        $eltCategoryTitleParent.find('.accordion-toggle').removeClass('collapsed');
        const $categoryPanelparent = $panelCollapseCategoryParent.find('.panel-collapse').first();
        $categoryPanelparent.collapse('show');
      }

      if ($panelCollapseCategory.attr('id') === 'oceano-data') {
        $warningOceano.show();
      }
    } else {
      this.$el.removeClass('layer-included-in-map');

      // remove layer title color (skyblue) to indicate it's no more included in map
      $eltLayerTitleContainer.removeClass('layer-included-in-map');
    }
    return this;
  },

  _onClick() {
    if (this.model.get('layerType') === LAYERTYPE_REFMAR) {
      return this._parentView._toggleRefmarLayer();
    }

    if (this.model.get('layerType') === LAYERTYPE_FORECAST) {
      return this._parentView._toggleForecastLayer();
    }

    const includedInMap = !this.model.get('includedInMap');
    const visibility = (includedInMap) ? true : this.model.get('visibility');

    this.model.set('includedInMap', includedInMap);
    this.model.set('visibility', visibility);

    if (this._collection) {
      _.each(this._collection, model => {
        model.set('includedInMap', includedInMap);
        model.set('visibility', visibility);
      });
    }
  }

});
