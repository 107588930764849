const $ = require('jquery');
const _ = require('underscore');
const moment = require('moment');

const Dialog = require('bootstrap-dialog');
const ShomView = require('../../core/shom-view');

const Loading = require('../../utils/loading');
const ToastrUtil = require('../../utils/toastr.js');
const OceanoNcwmsUtils = require('../../utils/oceano/oceano-ncwms.js');
const AtlasHelper = require('../../utils/atlas/atlas-utils.js');
const DDMDataUtils = require('../../utils/ddm/ddm-data-utils');

const OceanoAnimationExportModalView = require('./ocea-animation-export-modal.view.js');

const template = require('../../../template/oceano/ocea-animation-on-map.hbs');

const STATES = {
  STOPPED: 'stopped',
  LOADING: 'loading',
  PAUSED: 'paused',
  PLAYING: 'playing'
};

const START_DATETIMEPICKER_ID = 'aom-start-datetimepicker';
const END_DATETIMEPICKER_ID = 'aom-end-datetimepicker';

module.exports = ShomView.build({

  id: 'animation-on-map',
  className: 'hitable',

  /** ***************************** */
  /** ****** INIT FUNCTIONS ******* */
  /** ***************************** */

  initialize(options = {}) {
    this._config = options.config || window.CONFIG;
    this._gisview = options.gisview || window.GISVIEW;
    this._paletteOnMapView = options.paletteOnMapView;
    this._paletteOnMapContext = {
      palette: null,
      model: null
    };

    this._oceaLayers = []; // array of ncwms layers (models and moments) displayed

    this._currentMoment = options.currentMoment || moment();
    this._firstMoment = this.getGlobalCurrentMoment(); // animation starting moment.
    this._lastMoment = this.getGlobalCurrentMoment(); // animation starting moment.
    this._currentUTC = options.currentUTC; // current UTC initialized to the UTC of the current time zone.
    this._sortedUniqueMoments = options.sortedUniqueMoments; // Array of all the time values available rounded up to the closest minute with currentUTC applied.
    this._momentsStructure = options.momentsStructure; // basically a map with "days" as key and "arrays of hours" as values.
    this._currentFramerate = 2; // current framerate value (integer).

    this._animationState = STATES.STOPPED;
    this._animationCurrentFrame_idx = 0;
    this._animationLoop = false;
    this._animationStructure = {}; // data structure for the animation. cf _buildAnimationStructure.

    this._modalView = options.modalView || window.MODALVIEW;

    this._momentToDateStringTemplate = $.i18n.t('oceano.nav.timeline.timelineDateFormat');
    this._momentToTimeStringTemplate = $.i18n.t('oceano.nav.timeline.timelineTimeFormat');
    this._spmDiffFromNearestPmFormat = this._config.tfs.diffFromNearestPmDateTimeFormat;
    this._ddmDataUtils = options.ddmDataUtils || new DDMDataUtils(this._config);
    this._timelineDateTimeFormat = $.i18n.t('oceano.nav.timeline.timelineDateTimeFormat');
    this._disabledTimeIntervals = options.disabledTimeIntervals;
    this._oceaLayers = options.oceaLayers;
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template());

    // template DOM elements
    this._$timelineOnMapDiv = this.$el;
    this._$closebutton = this.$('.aom-top-left-close-button');
    this._$selectFramerate = this.$('#aom-select-framerate');
    this._$downloadButton = this.$('#aom-download-button');

    // display UTC used
    const utcDisplayed = Math.abs(this._currentUTC);
    const utcSign = utcDisplayed >= 0 ? '+' : '-';
    this.$('.aom-utc-info').each(function () { // do not use arrow function otherwise ${this} reference will be lost
      $(this).text(`UTC${utcSign}${utcDisplayed}`);
    });

    // animation buttons and slider
    this._$animationBtn_repeat = this.$('#btn-repeat');
    this._$animationBtn_first = this.$('#btn-first');
    this._$animationBtn_previous = this.$('#btn-previous');
    this._$animationBtn_playPause = this.$('#btn-play-pause');
    this._$animationBtn_playPause_i = this.$('#btn-play-pause .material-icons');
    this._$animationBtn_next = this.$('#btn-next');
    this._$animationBtn_last = this.$('#btn-last');
    this._$animationBtn_stop = this.$('#btn-stop');
    this._$animationFrameSlider = this.$('#aom-frame-slider');
    this._$animationFrameInfo = this.$('#aom-current-time');

    // events
    this._$closebutton.on('click', _.bind(this._onCloseAnimation, this));
    this._$selectFramerate.on('change', _.bind(this._onFramerateChange, this));
    this._$downloadButton.on('click', _.bind(this._onExportAnimation, this));
    // animation buttons and slider events
    this._$animationBtn_repeat.on('click', _.bind(this._onRepeatBtnClick, this));
    this._$animationBtn_playPause.on('click', _.bind(this._onPlayPauseBtnClick, this));
    this._$animationBtn_previous.on('click', _.bind(this._onPrevBtnClick, this));
    this._$animationBtn_next.on('click', _.bind(this._onNextBtnClick, this));
    this._$animationBtn_first.on('click', _.bind(this._onFirstBtnClick, this));
    this._$animationBtn_last.on('click', _.bind(this._onLastBtnClick, this));
    this._$animationBtn_stop.on('click', _.bind(this._onStopBtnClick, this));
    this._$animationFrameSlider.on('slidestop', _.bind(this._onFrameSliderChange, this));

    // render
    this._renderDateTimePickerControls();
    this._renderFrameRateSelector();
    this._renderAnimationUI();
    this._renderFrameSlider();

    this._$timelineOnMapDiv.draggable({
      containment: 'parent'
    });
    return this;
  },

  _renderDateTimePickerControls() {
    this._$datetimePickerStart = this.$(`#${START_DATETIMEPICKER_ID}`);
    this._$datetimePickerEnd = this.$(`#${END_DATETIMEPICKER_ID}`);
    const i18nLang = $.i18n.options.lng;
    const lang = i18nLang === 'fr' || i18nLang === 'fr_FR' ? 'fr' : 'en';

    const [minDate, maxDate, stepping, disabledTimeIntervals] = this._disabledTimeIntervals;

    const dateTimePickerOptionsDateTime = {
      format: this._timelineDateTimeFormat,
      showClose: this._config.timeline.datetimePickerShowClose,
      toolbarPlacement: 'top',
      locale: lang,
      sideBySide: true,
      minDate,
      maxDate,
      stepping,
      disabledTimeIntervals
    };

    this._$datetimePickerStart.datetimepicker(dateTimePickerOptionsDateTime);
    this._$datetimePickerEnd.datetimepicker(dateTimePickerOptionsDateTime);

    const initMoment = this.getGlobalCurrentMoment();
    const initDate = initMoment.format(this._timelineDateTimeFormat);
    this._$datetimePickerStart.data('DateTimePicker').date(initDate);
    this._$datetimePickerEnd.data('DateTimePicker').date(initDate);
    this._setMomentToDatetimepicker(START_DATETIMEPICKER_ID, initMoment);
    this._setMomentToDatetimepicker(END_DATETIMEPICKER_ID, initMoment);

    this._$datetimePickerStart.on('dp.change', this._onDateTimeChange.bind(this));
    this._$datetimePickerEnd.on('dp.change', this._onDateTimeChange.bind(this));
  },

  _onDateTimeChange(event) {
    const targetId = event.target.id;
    if (targetId === START_DATETIMEPICKER_ID || targetId === END_DATETIMEPICKER_ID) {
      const $target = this._getJqueryEltById(targetId);

      // compare new day with former one / the same for hour
      const newMoment = moment($target.val(), this._timelineDateTimeFormat);
      const newDay = newMoment.format(this._momentToDateStringTemplate);
      const newTime = newMoment.format(this._momentToTimeStringTemplate);
      const selectedMoment = this._getSelectedMomentFromDatetimepicker(targetId);
      const selectedDay = selectedMoment.format(this._momentToDateStringTemplate);
      const selectedTime = selectedMoment.format(this._momentToTimeStringTemplate);

      const dayChanged = newDay !== selectedDay;
      const timeChanged = newTime !== selectedTime;

      if (dayChanged) {
        this._onDayChange(targetId);
      } else if (timeChanged) {
        this._onTimeChange(targetId);
      }
    }
  },

  _getSelectedMomentFromDatetimepicker(datetimepickerId) {
    if (datetimepickerId === START_DATETIMEPICKER_ID) {
      return this._firstMoment;
    }

    if (datetimepickerId === END_DATETIMEPICKER_ID) {
      return this._lastMoment;
    }

    return null;
  },

  _setMomentToDatetimepicker(datetimepickerId, newMoment) {
    if (datetimepickerId === START_DATETIMEPICKER_ID) {
      this._firstMoment = newMoment;
    } else if (datetimepickerId === END_DATETIMEPICKER_ID) {
      this._lastMoment = newMoment;
    }
  },

  _getJqueryEltById(targetId) {
    return this.$(`#${targetId}`);
  },

  _onDayChange(targetId) {
    const $target = this._getJqueryEltById(targetId);
    // new "current moment" is first hour of day selected
    const currentDay = moment($target.val(), this._timelineDateTimeFormat).format(this._momentToDateStringTemplate);

    // retrieve the closest time available for the new selected day.
    const times = this._getMomentForDay(currentDay).slice();
    const selectedMoment = this._getSelectedMomentFromDatetimepicker(targetId);
    const currentTime = selectedMoment.format(this._momentToTimeStringTemplate);
    this._setMomentToDatetimepicker(targetId, OceanoNcwmsUtils.getClosestTimeFromTimeStructure(currentTime, times)[1]);
  },

  _onTimeChange(targetId) {
    const $target = this._getJqueryEltById(targetId);
    // retrieve new "current moment"
    const currentMoment = moment($target.val(), this._timelineDateTimeFormat);
    const currentDay = currentMoment.format(this._momentToDateStringTemplate);
    const currentTime = currentMoment.format(this._momentToTimeStringTemplate);

    const eltTimeMoment = this._getMomentForDay(currentDay).find(elt => elt[0] === currentTime);

    if (eltTimeMoment) {
      this._setMomentToDatetimepicker(targetId, eltTimeMoment[1]);
    }
  },

  _getMomentForDay(currentDay) {
    let eltMoment = this._momentsStructure[currentDay];
    if (!eltMoment) {
      // if day isn't in list, it's an atlas one, so add day to this momentsStructure (compute and build)
      const atlasTimeValues = AtlasHelper.buildAtlasMoments(moment(currentDay, OceanoNcwmsUtils.getDateToStringTemplate()));
      this._sortedUniqueMoments = this._sortedUniqueMoments.concat(atlasTimeValues).sort((a, b) => a.diff(b));
      this._buildMomentsStructure();
      eltMoment = this._momentsStructure[currentDay];
    }
    return eltMoment;
  },

  _buildMomentsStructure() {
    // This function builds the TimestampStructure as depicted below :
    // this._momentsStructure  = { "date1" : [ ["hour1" , moment]
    //                                         ["hour2" , moment] ... ],
    //                             "date2" : ... }
    //

    this._momentsStructure = {};

    for (let momentIdx = 0; momentIdx < this._sortedUniqueMoments.length; momentIdx++) {
      const momt = this._sortedUniqueMoments[momentIdx];
      const day = momt.format(this._momentToDateStringTemplate);
      const time = momt.format(this._momentToTimeStringTemplate);

      if (day in this._momentsStructure) {
        this._momentsStructure[day].push([time, momt]);
      } else {
        this._momentsStructure[day] = [[time, momt]];
      }
    }
  },

  _renderFrameRateSelector() {
    let framerateValues = '';
    const frameratesAvailable = [1, 2, 5, 10, 15, 24, 30];
    for (let iFps = 0; iFps < frameratesAvailable.length; iFps++) {
      const fps = frameratesAvailable[iFps];
      if (fps === this._currentFramerate) { // selected by default
        framerateValues += `<option value="${fps}" selected>`;
      } else {
        framerateValues += `<option value="${fps}">`;
      }
      framerateValues += `${fps}</option>`;
    }
    this._$selectFramerate.html(framerateValues);
    this._$animationFrameInfo.text('');
  },

  _renderAnimationUI() {
    this._disableButton(this._$animationBtn_first);
    this._disableButton(this._$animationBtn_previous);
    this._disableButton(this._$animationBtn_playPause);
    this._disableButton(this._$animationBtn_next);
    this._disableButton(this._$animationBtn_last);
    this._disableButton(this._$animationBtn_stop);

    if (this._animationState === STATES.STOPPED) {
      this._renderFrameSlider();
      this._renderPlayPauseButton('play');
      this._enableButton(this._$animationBtn_playPause);
      this._enableButton(this._$animationBtn_repeat);
      this._enableDropLists();
    } else if (this._animationState === STATES.LOADING) {
      this._renderFrameSlider();
      this._disableButton(this._$animationBtn_playPause);
      this._enableButton(this._$animationBtn_stop);
      this._disableDropLists();
    } else if (this._animationState === STATES.PLAYING) {
      this._configureAnimationSliderValue({ disabled: true });
      this._renderPlayPauseButton('pause');
      this._enableButton(this._$animationBtn_repeat);
      this._enableButton(this._$animationBtn_playPause);
      this._enableButton(this._$animationBtn_stop);
      this._disableDropLists();
    } else if (this._animationState === STATES.PAUSED) {
      this._renderPlayPauseButton('play');
      this._configureAnimationSliderValue({ disabled: false });
      this._enableButton(this._$animationBtn_repeat);
      this._enableButton(this._$animationBtn_first);
      this._enableButton(this._$animationBtn_previous);
      this._enableButton(this._$animationBtn_playPause);
      this._enableButton(this._$animationBtn_next);
      this._enableButton(this._$animationBtn_last);
      this._enableButton(this._$animationBtn_stop);
      this._disableDropLists();
    } else {
      // Error: not supposed to happen

    }
  },

  _savePaletteOnMap() {
    if (this._paletteOnMapContext.palette && this._paletteOnMapContext.model) {
      this._paletteOnMapContext = {
        palette: this._paletteOnMapView.getPalette(),
        model: this._paletteOnMapView.getModel()
      };
      return true;
    }
    return false;
  },

  _restorePaletteOnMap() {
    if (this._paletteOnMapContext.palette && this._paletteOnMapContext.model) {
      this._paletteOnMapView.setAndRenderPalette(this._paletteOnMapContext.palette, this._paletteOnMapContext.model);
    }
  },

  _renderPlayPauseButton(value) {
    if (value === 'play') {
      this._$animationBtn_playPause_i.text('play_arrow');
    } else if (value === 'pause') {
      this._$animationBtn_playPause_i.text('pause');
    }
  },

  _renderFrameSlider() {
    this._$animationFrameSlider.slider({
      value: 0,
      min: 0,
      max: 1,
      step: 1,
      animate: 'fast',
      disabled: 'true'
    });
  },

  _renderAnimationFrameInfo() {
    if (this._animationState !== STATES.STOPPED && this._animationStructure.animationMoments) {
      this._$animationFrameInfo.text(
        this._animationStructure.animationMoments[this._animationCurrentFrame_idx].format(
          this._momentToStringTemplate
        )
      );
    } else {
      this._$animationFrameInfo.text('');
    }
  },

  /** ***************************** */
  /** ****** EVENT FUNCTION ******* */
  /** ***************************** */

  _onFramerateChange() {
    // retrieve new framerate
    this._currentFramerate = this._$selectFramerate.val();
    this._gisview.animation_setFramerate(this._currentFramerate);
  },

  _onFrameSliderChange(e, ui) {
    const newFrameIdx = ui.value;
    this._gisview.animation_setFrame(newFrameIdx);
  },

  _onExportAnimation() {
    if (this._firstMoment.isAfter(this._lastMoment)) {
      Dialog.show({
        type: Dialog.TYPE_DANGER,
        title: $.i18n.translate('oceano.error.animation.configuration.title'),
        message: $.i18n.translate('oceano.error.animation.configuration.message')
      });
      return;
    }

    const animationExportOptions = {
      firstMoment: moment(this._firstMoment),
      lastMoment: moment(this._lastMoment),
      framerate: this._currentFramerate,
      layersAvailable: this._oceaLayers.slice(),
      currentUtc: this._currentUTC
    };
    const modalAnimationExport = new OceanoAnimationExportModalView(animationExportOptions);
    this._modalView.show(modalAnimationExport);
  },

  postTranslate() {
    this._momentToDateStringTemplate = $.i18n.t('oceano.nav.timeline.timelineDateFormat');
    this._momentToTimeStringTemplate = $.i18n.t('oceano.nav.timeline.timelineTimeFormat');
    this._momentToStringTemplate = `${this._momentToDateStringTemplate} ${this._momentToTimeStringTemplate}`;
    this._applyLanguage();
    this._renderAnimationFrameInfo();
  },

  _applyLanguage() {
    this._sortedUniqueMoments.map(momt => momt.locale(window.portalLang));
  },

  _onCloseAnimation() {
    this.trigger('close:animationOnMapView', {});
  },

  onClose() {
    this._onStopBtnClick();
  },

  /** ***************************** */
  /** **** ANIMATION FUNCTION ***** */
  /** ***************************** */

  _onAnimationLoadComplete() {
    ToastrUtil.clear();
    Loading.stop($('.map-container'));

    // set Animation Palette
    if (this._savePaletteOnMap()) { // only if there was a palette previously displayed
      const oceaLayerOnTop = _.find(this._animationStructure.oceaLayers, layerGroup => layerGroup.model === this._paletteOnMapView.getModel());
      this._paletteOnMapView.setAndRenderPalette(oceaLayerOnTop.palette.auto, oceaLayerOnTop.model);
    }

    this._configureAnimationSliderValue({
      frame_idx: 0,
      max: this._animationStructure.animationMoments.length - 1,
      disabled: true
    });
    this._onAnimationStarted();
    this._gisview.animation_play();
  },

  _onAnimationEnded() {
    this._changeState(STATES.PAUSED);
  },

  _onAnimationStarted() {
    this._changeState(STATES.PLAYING);
  },

  _onRepeatBtnClick() {
    if (this._animationLoop) {
      this._animationLoop = false;
      this._$animationBtn_repeat.removeClass('enable');
    } else {
      this._animationLoop = true;
      this._$animationBtn_repeat.addClass('enable');
    }
    this._gisview.animation_setLoop(this._animationLoop);
  },

  _onPlayPauseBtnClick() {
    if (this._animationState === STATES.STOPPED) {
      this._buildAnimation();
    } else if (this._animationState === STATES.PAUSED) {
      this._gisview.animation_play();
    } else if (this._animationState === STATES.PLAYING) {
      this._gisview.animation_pause();
    }
  },

  _onPrevBtnClick() {
    this._gisview.animation_previous();
  },

  _onNextBtnClick() {
    this._gisview.animation_next();
  },

  _onFirstBtnClick() {
    this._gisview.animation_firstFrame();
  },

  _onLastBtnClick() {
    this._gisview.animation_lastFrame();
  },

  _onStopBtnClick() {
    if (this._animationState === STATES.LOADING) { // abort loading
      Loading.stop($('.map-container'));
      ToastrUtil.clear();
      ToastrUtil.info($.i18n.t('oceano.toaster.animation.loadingAborted'));
    }
    this._gisview.animation_stop();
    this._changeState(STATES.STOPPED);
    this._renderAnimationFrameInfo();
    this._restorePaletteOnMap();
  },

  _onFrameChanged(frame_idx) {
    this._animationCurrentFrame_idx = frame_idx;
    this._configureAnimationSliderValue({
      frame_idx
    });
  },

  _changeState(newState) {
    this._animationState = newState;
    this._renderAnimationUI();
  },

  _buildAnimation() {
    // 1 - Basic animation requirements
    if (this._firstMoment.isAfter(this._lastMoment)) {
      Dialog.show({
        type: Dialog.TYPE_DANGER,
        title: $.i18n.translate('oceano.error.animation.configuration.title'),
        message: $.i18n.translate('oceano.error.animation.configuration.message')
      });
      return;
    }

    // 2 - build animation data structure
    this._animationCurrentFrame_idx = 0;
    this._changeState(STATES.LOADING);
    this._buildAnimationStructure();

    // 3 - bind event functions for the animation.
    const eventFunctions = {
      animationLoadComplete: _.bind(this._onAnimationLoadComplete, this),
      frameChanged: _.bind(this._onFrameChanged, this),
      animationEnded: _.bind(this._onAnimationEnded, this),
      animationStarted: _.bind(this._onAnimationStarted, this)
    };

    const animationParams = {
      eventFunctions,
      animationLoop: this._animationLoop,
      framerate: this._currentFramerate
    };

    // 4 - inform user loading might take a while.
    Loading.start($('.map-container'));
    ToastrUtil.clear();
    ToastrUtil.info($.i18n.t('oceano.toaster.animation.loading'));

    // 5 - update moments for atlas layers if any (ex: 01-01-1950T08:45 instead of 01-04-2021T10:15)
    const promises = [];
    const newAnimationMoments = new Map();
    for (let idxOceaLayer = 0; idxOceaLayer < this._animationStructure.oceaLayers.length; idxOceaLayer++) {
      const oceaLayer = this._animationStructure.oceaLayers[idxOceaLayer];
      const groupAnimationMoments = this._animationStructure.oceaLayers[idxOceaLayer].layerAnimationMoments;
      const oceaLayerModel = oceaLayer.model;
      const isAtlas = AtlasHelper.isAtlasLayer(oceaLayerModel);
      if (isAtlas) {
        newAnimationMoments.set(idxOceaLayer, []); // init empty array for this key
        // if atlas, first retrieve result from spm then call line below with result given (ex: 01-01-1950T08:45)
        groupAnimationMoments.forEach(animationMoment => {
          const promise = this._ddmDataUtils.getDiffFromNearestPm(
            oceaLayerModel.get('portRef'),
            this._currentUTC,
            animationMoment.format(this._spmDiffFromNearestPmFormat),
            oceaLayerModel.get('isBm')
          )
            .then(data => +data.diff)
            .then(diffFromNearestSpm => AtlasHelper.computeNewDatetimeFromReference(diffFromNearestSpm, oceaLayerModel.get('olLayer').get('dimensions').processedTime))
            .then(datetime => {
              // add datetime (ex: 01-01-1950T08:45)
              newAnimationMoments.get(idxOceaLayer).push({ momentIdx: animationMoment, datetime });
            })
            .fail(err => console.error(err));
          promises.push(promise);
        });
      }
    }

    // 6 - set new atlas animation moments if any, then start animation
    Promise.all(promises)
      .then(() => {
        newAnimationMoments.forEach((value, key, map) => {
          // order values {momentIdx, value} from momentIdx and keep only the DatetimeFromReference
          this._animationStructure.oceaLayers[key].layerAnimationMoments = value.sort((a, b) => a.momentIdx.diff(b.momentIdx)).map(result => result.datetime);
        });
      })
      .then(() => this._gisview.animation_load(animationParams, this._animationStructure))
      .catch(err => this._displayDialogAnimationError(err));
  },

  /** ***************************** */
  /** ****** UTILS FUNCTION ******* */
  /** ***************************** */

  _hasAtlasInOceaLayers() {
    return this._oceaLayers.some(lay => AtlasHelper.isAtlasLayer(lay.model));
  },

  _buildAnimationStructure() {
    let animationMoments;

    if (this._hasAtlasInOceaLayers()) {
      animationMoments = AtlasHelper.buildAtlasMomentsBetweenInterval(this._firstMoment, this._lastMoment);
    } else {
      // build array containing all the moments between first and last moments (both included)
      const sortedMomentsFormat = this._sortedUniqueMoments.map(m => m.format());
      const firstMomentIdx = sortedMomentsFormat.indexOf(this._firstMoment.format());
      const lastMomentIdx = sortedMomentsFormat.indexOf(this._lastMoment.format());

      // get subArray containing all the moments between first and last moments (both included)
      animationMoments = this._sortedUniqueMoments.slice(firstMomentIdx, lastMomentIdx + 1);
    }

    const animationStructure = {
      animationMoments,
      oceaLayers: []
    };

    // for each layer, compute the closest available moment from each moment of the animation.
    for (let layerIdx = 0; layerIdx < this._oceaLayers.length; layerIdx++) {
      const isAtlas = AtlasHelper.isAtlasLayer(this._oceaLayers[layerIdx].model);
      const layerSortedMoments = isAtlas ? animationMoments.sort((a, b) => a.diff(b)) : this._oceaLayers[layerIdx].sortedMoments;
      const startingLayerMoment = OceanoNcwmsUtils.getNearestMomentFromSortedMomentArray(this._firstMoment, layerSortedMoments);
      let layerMomntIdx = layerSortedMoments.indexOf(startingLayerMoment);
      const layerAnimationSortedMomnts = [];

      for (let animationMomntIdx = 0; animationMomntIdx < animationMoments.length; animationMomntIdx++) {
        const animationMomnt = animationMoments[animationMomntIdx];
        const lastClosestMoment = layerSortedMoments[layerMomntIdx];
        const nextClosestMoment = layerSortedMoments[Math.min(layerMomntIdx + 1, layerSortedMoments.length - 1)];

        if (Math.abs(lastClosestMoment.diff(animationMomnt)) <= Math.abs(nextClosestMoment.diff(animationMomnt))) {
          layerAnimationSortedMomnts.push(lastClosestMoment);
        } else {
          layerAnimationSortedMomnts.push(nextClosestMoment);
          layerMomntIdx += 1;
        }
      }

      animationStructure.oceaLayers.push({
        model: this._oceaLayers[layerIdx].model,
        layerAnimationMoments: layerAnimationSortedMomnts
      });
    }

    this._animationStructure = animationStructure;
    //   _animationStructure is as follow :
    // {    animationMoments : *All the moments available for the animation*
    //      oceaLayers : [  {   model                 : *layer model*,
    //                          layerAnimationMoments : *All the layer moments matching the animationMoments* },
    //                      { ... }, ... ]
    // }
  },

  _disableButton(button) {
    button.attr('disabled', 'disabled');
  },

  _enableButton(button) {
    button.removeAttr('disabled');
  },

  _disableDropLists() {
    this._disableButton(this._$datetimePickerStart);
    this._disableButton(this._$datetimePickerEnd);
  },

  _enableDropLists() {
    this._enableButton(this._$datetimePickerStart);
    this._enableButton(this._$datetimePickerEnd);
  },

  _configureAnimationSliderValue(params) {
    if (params.frame_idx !== undefined) {
      this._$animationFrameSlider.slider('option', 'value', params.frame_idx);
      this._renderAnimationFrameInfo();
    }
    if (params.disabled !== undefined) {
      this._$animationFrameSlider.slider('option', 'disabled', params.disabled);
    }
    if (params.max !== undefined) {
      this._$animationFrameSlider.slider('option', 'max', params.max);
    }
  },

  getGlobalCurrentMoment() {
    return moment(this._currentMoment);
  },

  _displayDialogAnimationError(errMsg) {
    this._displayDialogError('oceano.error.animation.loading.title', errMsg);
  },

  _displayDialogError(i18nTitleKey, message = '') {
    Dialog.show({
      type: Dialog.TYPE_DANGER,
      title: $.i18n.translate(i18nTitleKey),
      message
    });
  }
});
