const _ = require('underscore');
const moment = require('moment');

const ShomView = require('../../core/shom-view');

const InfoPopover = require('../info-popover.view');

const OceanoNcwmsUtils = require('../../utils/oceano/oceano-ncwms.js');

const template = require('../../../template/oceano/ocea-select-date.hbs');

module.exports = ShomView.build({

  dateContainerClass: 'row',
  timeContainerClass: 'row',

  initialize(options) {
    const optionsToUse = options || {};
    this._momentsStructure = optionsToUse.momentsStructure;
    this._sortedUniqueMoments = optionsToUse.sortedUniqueMoments;
    this._currentMoment = OceanoNcwmsUtils.getNearestMomentFromSortedMomentArray(optionsToUse.currentLayerMoment, this._sortedUniqueMoments);

    this._momentToDateStringTemplate = OceanoNcwmsUtils.getDateToStringTemplate();
    this._momentToTimeStringTemplate = OceanoNcwmsUtils.getTimeToStringTemplate();
  },

  /** ****************************** */
  /** ****** RENDER FUNCTIONS ****** */
  /** ****************************** */

  render() {
    this.$el.html(template());

    this._$blockTitle = this.$el.find('#ocean-date-block-title');
    this._$infoButton = this.$el.find('.info-button-root');
    this._$selectDay = this.$el.find('#ocean-date-select');
    this._$selectTime = this.$el.find('#ocean-time-select');
    this._$nextTimeBtn = this.$el.find('#btn-next-time');
    this._$prevTimeBtn = this.$el.find('#btn-previous-time');
    this._$nextDateBtn = this.$el.find('#btn-next-date');
    this._$prevDateBtn = this.$el.find('#btn-previous-date');

    this._$nextTimeBtn.on('click', _.bind(this._onNextTime, this));
    this._$prevTimeBtn.on('click', _.bind(this._onPreviousTime, this));
    this._$nextDateBtn.on('click', _.bind(this._onNextDate, this));
    this._$prevDateBtn.on('click', _.bind(this._onPreviousDate, this));
    this._$selectDay.on('change', _.bind(this._onDayChange, this));
    this._$selectTime.on('change', _.bind(this._onTimeChange, this));

    this._renderInfoPopover();
    this._renderSelectDay();
    this._renderSelectTime();
    this._enableNextPrevButton();

    return this;
  },

  _renderInfoPopover() {
    this.infoPopover && this.infoPopover.remove();
    this.infoPopover = new InfoPopover({
      message: 'oceano.popover.date'
    });
    this._$infoButton.html(this.infoPopover.render().$el);
  },

  _renderSelectDay() { // lists all the keys of the 'this._momentsStructure' in the date selector.
    const days = [];
    for (const day in this._momentsStructure) {
      // eslint-disable-next-line no-prototype-builtins
      if (this._momentsStructure.hasOwnProperty(day)) {
        days.push(day);
      }
    }
    if (days.length === 0) {
      return;
    }

    // sort the days array.
    const thisView = this;
    days.sort((day1, day2) => {
      const m1 = thisView._momentsStructure[day1][0][1]; // day1 first moment
      const m2 = thisView._momentsStructure[day2][0][1]; // day2 first moment
      return (m1.diff(m2) >= 0);
    });

    // build date select html content
    const selectedDay = this._currentMoment.format(this._momentToDateStringTemplate);
    let htmlSelectDay = '';
    for (let dayIdx = 0; dayIdx < days.length; dayIdx++) {
      const day = days[dayIdx];
      if (day === selectedDay) {
        htmlSelectDay += `<option value='${day}' selected>`;
      } else {
        htmlSelectDay += `<option value='${day}'>`;
      }
      htmlSelectDay += `${day}</option>`;
    }
    this._$selectDay.html(htmlSelectDay);
  },

  _resetSelectDay() { // sets the day selected without re-rendering.
    const selectedDay = this._currentMoment.format(this._momentToDateStringTemplate);
    this._$selectDay.val(selectedDay);
  },

  _renderSelectTime() { // lists all the hours values associated with the selected date.
    const selectedDay = this._currentMoment.format(this._momentToDateStringTemplate);
    const selectedTime = this._currentMoment.format(this._momentToTimeStringTemplate);
    const times = this._sortedUniqueMoments.length ? this._momentsStructure[selectedDay] : [];

    // build time select html content
    let htmlSelectTime = '';
    for (let timeIdx = 0; timeIdx < times.length; timeIdx++) {
      const time = times[timeIdx];
      if (time[0] === selectedTime) {
        htmlSelectTime += `<option value='${time[1]}' selected>`;
      } else {
        htmlSelectTime += `<option value='${time[1]}'>`;
      }
      htmlSelectTime += `${time[0]}</option>`;
    }
    this._$selectTime.html(htmlSelectTime);

    // disable time selection if there is only one hour available.
    if (times.length === 1) {
      this._$selectTime.attr('disabled', 'disabled');
    } else {
      this._$selectTime.removeAttr('disabled');
    }
  },

  _enableNextPrevButton() { // handles the availability of the time navigation buttons.
    const nextTime = this._$selectTime.find('option:selected').next().val();
    const nextDate = this._$selectDay.find('option:selected').next().val();
    const prevTime = this._$selectTime.find('option:selected').prev().val();
    const prevDate = this._$selectDay.find('option:selected').prev().val();

    if (!nextDate) {
      this._$nextDateBtn.attr('disabled', 'disabled');
      if (!nextTime) {
        this._$nextTimeBtn.attr('disabled', 'disabled');
      } else {
        this._$nextTimeBtn.removeAttr('disabled');
      }
    } else {
      this._$nextDateBtn.removeAttr('disabled');
      this._$nextTimeBtn.removeAttr('disabled');
    }

    if (!prevDate) {
      this._$prevDateBtn.attr('disabled', 'disabled');
      if (!prevTime) {
        this._$prevTimeBtn.attr('disabled', 'disabled');
      } else {
        this._$prevTimeBtn.removeAttr('disabled');
      }
    } else {
      this._$prevDateBtn.removeAttr('disabled');
      this._$prevTimeBtn.removeAttr('disabled');
    }
  },

  /** ****************************** */
  /** ****** GETTER FUNCTIONS ****** */
  /** ****************************** */

  getSelectedMoment() {
    return this._currentMoment;
  },

  /** ****************************** */
  /** ****** EVENT FUNCTIONS ******* */
  /** ****************************** */

  _onDayChange(event) {
    const currentDay = event.value ? event.value : this._$selectDay.val();

    // retrieve the closest time available for the new selected day.
    const oldTime = this._currentMoment.format('HH:mm');
    const times = this._momentsStructure[currentDay];
    const momtAbsDiff = (timeStr1, timeStr2) => Math.abs(moment(timeStr1, 'HH:mm').diff(moment(timeStr2, 'HH:mm')));
    const closestTimeFromCurrent = times.reduce((prev, curr) => (momtAbsDiff(curr[0], oldTime) < momtAbsDiff(prev[0], oldTime) ? curr : prev));
    this._currentMoment = closestTimeFromCurrent[1];

    this._renderSelectTime();
    this._enableNextPrevButton();
    this._triggerTimeChange();
  },

  _onTimeChange() {
    // retrieve new 'current moment'
    const currentDay = this._$selectDay.val();
    const currentTimeIdx = this._$selectTime.prop('selectedIndex');
    this._currentMoment = this._momentsStructure[currentDay][currentTimeIdx][1];

    this._enableNextPrevButton();
    this._triggerTimeChange();
  },

  _onNextTime() {
    const oldMoment = this._currentMoment;
    const newMomentIdx = this._sortedUniqueMoments.indexOf(oldMoment) + 1;
    if (newMomentIdx >= this._sortedUniqueMoments.length) {
      return;
    }

    this._currentMoment = this._sortedUniqueMoments[newMomentIdx];

    this._resetSelectDay();
    this._renderSelectTime();
    this._enableNextPrevButton();
    this._triggerTimeChange();
  },

  _onPreviousTime() {
    const oldMoment = this._currentMoment;
    const newMomentIdx = this._sortedUniqueMoments.indexOf(oldMoment) - 1;
    if (newMomentIdx < 0) {
      return;
    }

    this._currentMoment = this._sortedUniqueMoments[newMomentIdx];

    this._resetSelectDay();
    this._renderSelectTime();
    this._enableNextPrevButton();
    this._triggerTimeChange();
  },

  _onNextDate() {
    const selected = this._$selectDay.find('option:selected');
    const newDate = selected.next().val();
    if (newDate) {
      this._onDayChange({ value: newDate });
      this._resetSelectDay();
      this._enableNextPrevButton();
    }
  },

  _onPreviousDate() {
    const selected = this._$selectDay.find('option:selected');
    const newDate = selected.prev().val();
    if (newDate) {
      this._onDayChange({ value: newDate });
      this._resetSelectDay();
      this._enableNextPrevButton();
    }
  },

  onClose() {
    this._$nextTimeBtn.off('click');
    this._$prevTimeBtn.off('click');
    this._$nextDateBtn.off('click');
    this._$prevDateBtn.off('click');
    this._$selectDay.off('change');
    this._$selectTime.off('change');
  },

  _triggerTimeChange() {
    this.trigger('change:oceaSelectDate', {
      moment: this._currentMoment
    });
  }
});
