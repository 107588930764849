const _ = require('underscore');
const ShomView = require('../../core/shom-view');
const template = require('../../../template/atlas/rose-courant-item.hbs');

module.exports = ShomView.build({

  tagName: 'tr',

  initialize(options = {}) {
    this._config = options.config || window.CONFIG;

    // Ensure our methods keep the `this` reference to the view itself
    _.bindAll(this, 'render');

    this.model.bind('change', this.render);
  },

  render() {
    this.$el.empty();
    if (this.model) {
      this.$el.html(template(this.model ? this.model.attributes : null));
    }
    return this;
  }
});
