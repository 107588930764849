import SimpleBar from 'simplebar';

const _ = require('underscore');
const $ = require('jquery');
const Dialog = require('bootstrap-dialog');

const ShomView = require('../../core/shom-view');
const template = require('../../../template/catalog/catalog.hbs');
const CategoriesView = require('./categories.view');
const OceanoCategoriesView = require('./oceano-categories.view');
const ObservationCategoriesView = require('./observation-categories.view');
const PartnerCategoriesView = require('./partner-categories.view');
const SearchView = require('./catalog-search.view');
const SearchResultsView = require('./catalog-search-results.view');

const CatalogView = ShomView.build({

  className: 'catalog-panel-view',

  events: {
    'click .btn-back': '_onBackButtonClick',
    'click .nav-tabs a': '_onTabClick',
    'click .left-menu-close-button': '_onBackButtonClick',
    'click #warning-oceano-param': '_onOceanoWarningClick',
    'click .subject-accordion > .panel-heading': '_onSubjectClick',
    'click .add-external-layer': '_onAddExternalLayersButtonClick',
    'click #close-catalog': function(){this.onCloseMenuButtonClick(event, '.btn-donnees');},
    'blur .add-external-layer': function(){this.onLastMenuItemBlur(event, '#close-catalog');},
    'blur #help_partner_data': '_onBlurPartnerCategory'
  },

  initialize(options = {}) {
    this._config = options.config || window.CONFIG;
    this._router = options.router || window.ROUTER;
    this._eventsBus = options.eventsBus || window.EVENTBUS;
    this._collection = this.collection || window.LAYERS;
    this._gfiModeManager = options.gfiModeManager || window.POI_MODE_MANAGER;
    this._context = this._router.getContext();
    this.listenTo(this._context, 'change', _.bind(this._onRouterContextChanged, this));
    this.listenTo(this._eventsBus, 'catalog:refresh', _.bind(this._refreshCatalog, this));
    this._user = options.user || window.ROUTER.user;
    this._eventBus = options.eventBus || window.EVENTBUS;
    this._layers = options.collection || window.LAYERS;
    this._catalogDataTrigger = 'catalog-search:changed';
  },

  render() {
    this._cleanSubviews();
    const showOceanoAndDdm = !this._gfiModeManager.isActive() && !this._context.get('cartoMode');
    this.$el.html(template({
      showOceanoAndDdm
    }));

    this._$categoriesContainer = this.$('#catalog-data');
    this._$oceanoContainer = this.$('#oceano-data');
    this._$observationContainer = this.$('#observation-data');
    this._$partnerContainer = this.$('#partner-data');
    this._$searchContainer = this.$('#catalog-search-root');
    this._$searchResultsContainer = this.$('#catalog-search-result-root');
    this._$warningOceano = this.$('#warning-oceano-param');
    this._$navTabsCategoriesContainer = this.$('#catalog-data-container');

    this._oceanoCategoriesView && this._oceanoCategoriesView.remove();
    this._oceanoCategoriesView = new OceanoCategoriesView();
    this._observationCategoriesView && this._observationCategoriesView.remove();
    this._observationCategoriesView = new ObservationCategoriesView({ config: this._config });
    this._partnerCategoriesView && this._partnerCategoriesView.remove();
    this._partnerCategoriesView = new PartnerCategoriesView({ eventsBus: this._eventBus });
    this._categoriesView && this._categoriesView.remove();
    this._categoriesView = new CategoriesView({ config: this._config });

    this._$categoriesContainer.html(this._categoriesView.render().$el);
    this._$oceanoContainer.html(this._oceanoCategoriesView.render().$el);
    this._$observationContainer.html(this._observationCategoriesView.render().$el);
    this._$partnerContainer.html(this._partnerCategoriesView.render().$el);

    $('#palette').addClass('catalog');
    $('#timeline-root').addClass('catalog');
    $('.left-menu-content-root').addClass('catalog');

    this._createSubviews();
    this._renderSubviews();

    this._$navTabsCategoriesContainer.show();

    this._addScrollbar();

    return this;
  },

  _refreshCatalog() {
    this.render();
  },

  openTab(tab) {
    tab = tab || 'featured';
    if (tab === 'oceano') {
      this._$warningOceano.show();
    } else {
      this._$warningOceano.hide();
    }
    this.$(`.nav-tabs a[data-route='${tab}']`).tab('show');
  },

  _onBackButtonClick() {
    this._router.navigate('donnees', true);
  },

  _onTabClick(evt) {
    evt.preventDefault();
    const $elt = this.$el.find(evt.target);
    const route = $elt.attr('data-route');
    this._router.navigate(`donnees/catalogue/${route}`, true);
    this._user.set('lastCatalogRoute', route);
  },

  _onBlurPartnerCategory(e) {
    e.preventDefault();
    if(this._$partnerContainer.is(":hidden")) {
        $('#close-catalog').focus();
    }
  },

  _onSubjectClick(evt) {
    const $elt = this.$el.find(evt.target);
    const idToBeToggled = $elt.attr('data-parent');
    const $eltCollapse = $(idToBeToggled).find('.collapse-category');
    const wasCollapsed = !$eltCollapse.hasClass('collapse in');
    $eltCollapse.collapse('toggle');
    const $subjectHeadingLink = $(idToBeToggled).find('.subject-heading > .panel-title > a');
    const $subjectHeadingPanel = $(idToBeToggled).find('.subject-heading > .panel-title');
    if (wasCollapsed) {
      $subjectHeadingLink.addClass('subject-accordion-expanded');
      $subjectHeadingPanel.addClass('layer-included-in-map');
    } else {
      $subjectHeadingLink.removeClass('subject-accordion-expanded');
      $subjectHeadingPanel.removeClass('layer-included-in-map');
    }

    if (idToBeToggled === '#subject-oceanodata' && wasCollapsed) {
      this._$warningOceano.show();
    } else {
      this._$warningOceano.hide();
    }
  },

  _onRouterContextChanged(context) {
    if (context.get('isEditor') !== context.previous('isEditor')) {
      this.render();
    }
  },

  remove() {
    this.stopListening(this._context);
    CatalogView.__super__.remove.apply(this, arguments);
  },

  _onOceanoWarningClick() {
    let message = '<p class="ocean-params-warning-modal-message">';
    message += $.i18n.t('oceano.modal.params_warning.message');
    message += '</p>';

    Dialog.show({
      message,
      type: Dialog.TYPE_WARNING,
      title: $.i18n.t('oceano.modal.params_warning.title'),
      buttons: [{
        label: $.i18n.t('oceano.modal.params_warning.closeBtn'),
        cssClass: 'btn-warning',
        action(dial) {
          dial.close();
        }
      }]
    });
  },

  onClose() {
    $('#palette').removeClass('catalog');
    $('#timeline-root').removeClass('catalog');
    this._cleanSubviews();
  },

  _cleanSubviews() {
    if (this._searchView) {
      this.stopListening(this._searchView, this._catalogDataTrigger);
      this._searchView.remove();
    }
    this._searchResultsView && this._searchResultsView.remove();
  },

  _createSubviews() {
    this._searchView = new SearchView({
      eventBus: this._eventBus,
      triggerEventSearchName: this._catalogDataTrigger
    });
    this._searchResultsView = new SearchResultsView({
      config: this._config,
      eventBus: this._eventBus,
      collection: this._layers
    });
    this.listenTo(this._searchView, this._catalogDataTrigger, this._onSearchTermChanged.bind(this));
  },

  _renderSubviews() {
    this._$searchContainer.html(this._searchView.render().$el);
    this._$searchResultsContainer.html(this._searchResultsView.render().$el);
  },

  _onSearchTermChanged(term) {
    if (term) {
      this._$searchResultsContainer.show();
      this._$navTabsCategoriesContainer.hide();
    } else {
      this._$searchResultsContainer.hide();
      this._$navTabsCategoriesContainer.show();
    }
    this._searchResultsView.filterLayers(term);
  },

  _addScrollbar() {
    // eslint-disable-next-line no-new
    new SimpleBar(this.$('.catalog-tab-content')[0], {
      autoHide: false,
      forceVisible: true,
      scrollbarMaxSize: 50
    });
    // eslint-disable-next-line no-new
    new SimpleBar(this.$('.catalog-search-result-list')[0], {
      autoHide: false,
      forceVisible: true,
      scrollbarMaxSize: 50
    });
  }

});

module.exports = CatalogView;
