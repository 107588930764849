const $ = require('jquery');
const CategoryUtil = require('./category');
const { LAYERTYPE_REFMAR, LAYERTYPE_FORECAST } = require('./constants');

const Layer = {};

Layer.buildLayerNameWithCategory = (layer, lang) => {
  const categoryName = CategoryUtil.buildCategoryName(layer, lang);

  let layerName = '';
  const englishName = layer.get('englishName');
  if (!englishName) {
    const layerType = layer.get('layerType');
    if (layerType === LAYERTYPE_REFMAR || layerType === LAYERTYPE_FORECAST) {
      layerName = $.i18n.t(layer.get('translationKey'));
    } else {
      layerName = layer.get('title');
    }
  } else {
    layerName = lang === 'fr' ? layer.get('title') : englishName;
  }

  return categoryName + layerName;
};

module.exports = Layer;
