const Backbone = require('backbone');
const _ = require('underscore');
const $ = require('jquery');

let timerHandle = null;
const SessionUpdater = Backbone.Model.extend({
  defaults() {
    return {
      kmlTimestamp: null,
      wmcTimestamp: null,
      legendTimestamp: null,
      chatTimestamp: null,
      editorKey: '',
      usersList: [],
      sessionId: null
    };
  },

  interval: 5000,

  initialize() {
    _.bindAll(this, '_onSuccess', '_tickTimer', '_onFailure');
  },

  start() {
    timerHandle = setInterval(this._tickTimer, this.interval);
  },

  stop() {
    clearInterval(timerHandle);
  },

  update() {
    return this._tickTimer();
  },

  _tickTimer() {
    const deferred = $.Deferred();
    this.sendUpdateRequest()
    .done(data => {
      if (typeof (data) === 'string') {
        this._onFailure(data);
        deferred.reject(arguments);
      } else {
        this._onSuccess(data);
        deferred.resolve();
      }
    })
    .fail(args => {
      this._onFailure(args.responseText);
      deferred.reject(arguments);
    });
    return deferred;
  },

  sendUpdateRequest() {
    const url = this.get('getUpdatesUrl');

    const params = {
      method: 'POST',
      url,
      data: 'data'
    };
    return this.get('user').doReq(params);
  },

  _onSuccess(json) {
    if (json._id === this.get('sessionId')) {
      const timestampProperties = ['kmlTimestamp', 'wmcTimestamp', 'chatTimestamp', 'legendTimestamp'];

      for (let i = 0; i < timestampProperties.length; ++i) {
        if (!this.get(timestampProperties[i])) {
          this.set(timestampProperties[i], -Infinity);
        }
      }

      const updateEvent = {};
      updateEvent.updateKML = json.lastUpdateOfCroquisFile && json.lastUpdateOfCroquisFile > this.get('kmlTimestamp');
      updateEvent.updateWMC = json.lastUpdateOfContextFile && json.lastUpdateOfContextFile > this.get('wmcTimestamp');
      updateEvent.updateChat = json.lastUpdateOfChat && json.lastUpdateOfChat > this.get('chatTimestamp');
      updateEvent.updateLegend = json.lastUpdateOfLegendsFile && json.lastUpdateOfLegendsFile > this.get('legendTimestamp');
      updateEvent.updateEditor = json.editorKey && json.editorKey !== this.get('editorKey');
      updateEvent.updateUsers = this._usersHaveChanged(json.connectedUsers);
      updateEvent.KMLTimestamp = json.lastUpdateOfCroquisFile;
      updateEvent.WMCTimestamp = json.lastUpdateOfContextFile;
      updateEvent.legendsTimestamp = json.lastUpdateOfLegendsFile;
      updateEvent.chatTimestamp = json.lastUpdateOfChat;
      updateEvent.ownerKey = json.ownerKey;
      updateEvent.editorKey = json.editorKey;
      this.set('kmlTimestamp', json.lastUpdateOfCroquisFile);
      this.set('wmcTimestamp', json.lastUpdateOfContextFile);
      this.set('legendTimestamp', json.lastUpdateOfLegendsFile);
      this.set('chatTimestamp', json.lastUpdateOfChat);
      this.set('editorKey', json.editorKey);
      this.set('usersList', json.connectedUsers);
      updateEvent.users = this.get('usersList');
      if (this._checkUpdateEventRequire(updateEvent)) {
        this.trigger('discuss:session:update', updateEvent);
      }
      return updateEvent;
    }
    return null;
  },

  _checkUpdateEventRequire(updateEvent) {
    return updateEvent.updateChat || updateEvent.updateEditor || updateEvent.updateKML
           || updateEvent.updateLegend || updateEvent.updateUsers || updateEvent.updateWMC;
  },

  _onFailure(error) {
    switch (error) {
      case 'SESSION_UNFOUND':
      case 'ACCESS_FORBIDDEN':
        this.trigger('discuss:session:closed', error);
        break;
      default:
        console.error('Unexpected error when checking session updates : ', error);
        break;
    }
  },

  _usersHaveChanged(newUsers) {
    const usersList = this.get('usersList');
    if (newUsers.length !== usersList.length) {
      return true;
    }
    for (let i = 0; i < newUsers.length; i++) {
      const possiblyNewUser = newUsers[i];
      const found = _.findWhere(usersList, { key: possiblyNewUser.key });
      if (!found) {
        return true;
      }
    }
    return false;
  },

  reset() {
    this.stop();
    this.clear();
    this.set(this.defaults());
  }
});
module.exports = SessionUpdater;
