import { SEPARATOR_CATEGORY_LEVEL01, SEPARATOR_CATEGORY_LEVEL02 } from './constants';

const Category = {};

/*
https://gist.github.com/alisterlf/3490957
 */
const removeAccents = function (strAccents) {
  strAccents = strAccents.split('');
  let strAccentsOut = [];
  const strAccentsLen = strAccents.length;
  const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  const accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';
  for (let y = 0; y < strAccentsLen; y++) {
    if (accents.indexOf(strAccents[y]) !== -1) {
      strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
    } else {
      strAccentsOut[y] = strAccents[y];
    }
  }
  strAccentsOut = strAccentsOut.join('');
  return strAccentsOut;
};

Category.computeKey = function (fullName) {
  if (!fullName) {
    return null;
  }
  const key = fullName.replace(/ /g, '_');
  return removeAccents(key).toLowerCase();
};

Category.prepareCategories = function (categories) {
  categories.sort((cat1, cat2) => cat1.index && cat2.index && cat1.index < cat2.index);
  return categories;
};

Category.findCategoryForLayer = function (globalCatalog, layerCategory) {
  // layerCategory should be like catalog#category or catalog#category##subcategory

  const splitLayerCategory = layerCategory.split(SEPARATOR_CATEGORY_LEVEL01);

  if (splitLayerCategory.length < 2) {
    // category not like catalog#categoryname for example reference#carto
    return;
  }

  const catalog = splitLayerCategory[0];
  if (!globalCatalog[catalog]) {
    // catalog (reference, observations, ...) not defined in catalog.json
    return;
  }

  const category = splitLayerCategory[1];
  const categoryInCatalog = globalCatalog[catalog].find(cat => cat.id === category);
  if (!categoryInCatalog) {
    // category not defined in catalog
    return;
  }

  const splitLayerSubCategory = layerCategory.split(SEPARATOR_CATEGORY_LEVEL02);
  if (splitLayerSubCategory.length > 1 && categoryInCatalog.subcategories) {
    // category is like catalog#category##subcategory
    return categoryInCatalog.subcategories.find(cat => cat.id === splitLayerSubCategory[1]);
  }

  // category is like catalog#category
  return categoryInCatalog;
};

Category.buildCategoryName = (layer, lang) => {
  let categoryName = '';
  if (layer.get('category')) {
    const categoryTranslation = layer.get('category').translate;
    if (categoryTranslation) {
      categoryName = lang === 'fr' ? categoryTranslation.fr : categoryTranslation.en;
    }
    if (categoryName && categoryName.length) {
      categoryName += ' - ';
    }
  }
  return categoryName;
};

module.exports = Category;
