const $ = require('jquery');
const ShomView = require('../core/shom-view');
const ToastrUtil = require('../utils/toastr.js');

const AnonymousView = require('./user-menu/anonymous.view');
const MemberView = require('./user-menu/member.view');
const UserSynchoView = require('./modal/user-synchro.view');

module.exports = ShomView.build({

  className: 'left-menu-content',

  events: {
    'click #forgot-password-link': '_onForgotPass',
    'click .user-synchro-btn': '_onUserSynchro',
    'click #close-account': function(){this.onCloseMenuButtonClick(event, '.my-account');},
    'blur .user-synchro-btn': function(){this.onLastMenuItemBlur(event, '#close-account');}
  },

  initialize(options = {}) {
    this._router = options.router || window.ROUTER;
    this._additionnalInfos = options.additionnalInfo || (window.ADDITIONALINFO && window.ADDITIONALINFO.abonne);
    this._router.hasDisplayedUserAdditionnalInfo = this._router.hasDisplayedUserAdditionnalInfo || {};
    this._gfiModeManager = options.gfiModeManager || window.POI_MODE_MANAGER;
    this._drawList = options.drawList;
    this.eventsBus = options.eventsBus || window.EVENTBUS;
    this._modalView = options.modalView || window.MODALVIEW;
    this._config = options.config || window.CONFIG;

    this._user = options.user || window.ROUTER.user;
    this.listenTo(this._user, 'change:userIsLoaded', this._refreshDrawList.bind(this));
  },

  transitionBetweenNodes(inputNode, outputNode, delay) {
    inputNode.fadeOut(delay / 2, () => {
      this.$el.html(outputNode);
      this.$el.children().fadeIn();
    });
  },

  render(withTransition) {
    if (!this.subviews) {
      this.subviews = {
        anonymous: new AnonymousView({ router: this._router }),
        member: new MemberView({ router: this._router, drawList: this._drawList })
      };
    }

    const usr = this._router.user;
    const lang = window.portalLang;
    this._gfiModeManager.startGfiRequestableLayer(true);

    $('.my-account i').addClass('active');

    if (
      (this._additionnalInfos && !this._router.hasDisplayedUserAdditionnalInfo.all)
        && (this._additionnalInfos[lang] || this._additionnalInfos.all)) {
      ToastrUtil.info(this._additionnalInfos[lang] || this._additionnalInfos.all[lang]);
      this._router.hasDisplayedUserAdditionnalInfo.all = true;
    }

    const content = this._contentToRender(usr, lang);

    if (withTransition) {
      this.transitionBetweenNodes(this.$el.children(), content.$el, 400);
    } else {
      this.$el.html(content.$el);
    }

    return this;
  },

  _onForgotPass() {
    this.eventsBus.trigger('user:forgotpass');
  },

  _refreshDrawList() {
    this.subviews = {
      anonymous: new AnonymousView({ router: this._router }),
      member: new MemberView({ router: this._router, drawList: this._drawList })
    };
    this.render();
  },

  _contentToRender(usr, lang) {
    if (usr.get('isLoggedIn')) {
      this._showToastInfo('logged', lang);
      return this.subviews.member.render();
    }
    this._showToastInfo('anonymous', lang);
    return this.subviews.anonymous.render();
  },

  _showToastInfo(viewType, lang) {
    if (this._additionnalInfos && this._additionnalInfos[viewType]
          && !this._router.hasDisplayedUserAdditionnalInfo[viewType]) {
      ToastrUtil.info(this._additionnalInfos[lang] || this._additionnalInfos[viewType][lang]);
      this._router.hasDisplayedUserAdditionnalInfo[viewType] = true;
    }
  },

  _onUserSynchro() {
    const content = new UserSynchoView({
      modalView: this._modalView,
      parent: this,
      hasDiffusionAccount: () => {
        window.location.href = this._config.diffusion.connection;
      },
      hasNoDiffusionAccount: () => {
        window.location.href = this._config.diffusion.createAccount;
      }
    });
    this._modalView.show(content, { keyboard: false, backdrop: 'static' });
  }
});
