import ContextUrlObserver from '../../utils/context-url-observer';
import { LAYERTYPE_FORECAST } from '../../utils/constants';

const _ = require('underscore');
const ShomView = require('../../core/shom-view');
const CategoryView = require('./oceano-category.view');
const LayerView = require('./oceano-layer.view');
const OceanoNcwmsUtils = require('../../utils/oceano/oceano-ncwms');
const Loading = require('../../utils/loading');

module.exports = ShomView.build({

  className: 'panel-group',

  id: 'categories-accordion',

  initialize(options = {}) {
    this.collection = this.collection || window.LAYERS;
    this._eventsBus = options.eventsBus || window.EVENTBUS;
    this._contextUrlObserver = new ContextUrlObserver({});
    this._itemViews = [];
    this._ncwmsLayersAvailable = !!(options.ncwmsLayersAvailable || window.ncwmsLayersAvailable);

    if (!this._ncwmsLayersAvailable) {
      this.listenTo(this._eventsBus, 'build:oceano', _.bind(this._initLayersModels, this));
    }
  },

  render() {
    Loading.start(this.$el);
    if (this._ncwmsLayersAvailable) {
      this._initLayersModels();
    }
    return this;
  },

  _initLayersModels() {
    this._categories = this._extractCategoriesOutOfCollection();
    this._refreshItemViews();
    this._eventsBus.off('build:oceano');
  },

  _removeItemViews() {
    _.each(this._itemViews, itemView => {
      itemView.remove();
    });
    this._itemViews = [];
  },

  _createItemViews() {
    _.each(this._categories, function (category) {
      // Forecast category do not exists so we add it directly as a layer
      if (category.name === LAYERTYPE_FORECAST) {
        this._itemViews.push(new LayerView({
          model: this.collection.findWhere({
            layerType: LAYERTYPE_FORECAST
          }),
          oceano: true
        }));
      } else {
        this._itemViews.push(new CategoryView({
          collection: this.collection,
          category
        }));
      }
    }, this);
  },

  _renderItemViews() {
    _.each(this._itemViews, function (itemView) {
      this.$el.append(itemView.render().$el);
    }, this);
  },

  _refreshItemViews() {
    this._removeItemViews();
    this._createItemViews();
    if (this._itemViews.length > 0) {
      this._renderItemViews();
      Loading.stop(this.$el);
    } else {
      this.$el.html('<div data-i18n="catalog.nodata"></div>');
      Loading.stop(this.$el);
    }
    this._contextUrlObserver.restoreFromHash();
  },

  _extractCategoriesOutOfCollection() {
    if (this.collection) {
      this.collection = this.collection.clone();
      this.collection.reset(this.collection.filter(item => (item.get('layerType') === 'NCWMS' || item.get('layerType') === LAYERTYPE_FORECAST)
          && item.get('catalog') === 'oceano' && !item.get('isArchive')));
      return OceanoNcwmsUtils.getCategoriesByCatalog(this.collection.models, window.portalLang);
    }
  },

  _updateCategoriesTranslateName(categories) {
    this._itemViews.forEach((itemView, i) => {
      if (!(itemView instanceof LayerView) && categories[i] && categories[i].translateName) {
        itemView.updateTranslateName(categories[i].translateName);
      }
    });
  },

  postTranslate(lang) {
    if (this.collection && this._itemViews.length) {
      const categoriesTranslated = OceanoNcwmsUtils.getCategoriesByCatalog(this.collection.models, lang);
      this._updateCategoriesTranslateName(categoriesTranslated);
    }
  }
});
