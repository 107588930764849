const $ = require('jquery');

const OceanogrammeService = {
  /**
     * Get a buoy oceanogram URL from params
     *
     * @param buoyCode the buoy code
     * @param params the params
     * @return {string} the constructed URL
     */
  getBuoyOceanogrammeUrl(buoyCode, params) {
    let urlParams = `duration=${params.duration}`;
    urlParams += `&delta-date=${params.delta}`;
    urlParams += `&buoy_code=${buoyCode}`;
    urlParams += `&lang=${params.lang}`;
    urlParams += `&forecastComp=${params.forecastComp}`;

    return `${this.buoyUrl}?${urlParams}`;
  },

  /**
     * Get a spot oceanogram URL from params
     *
     * @param spotId the spot identifier
     * @param params the params
     * @return {string} the constructed URL
     */
  getSpotOceanogrammeUrl(spotId, params) {
    let urlParams = `duration=${params.duration}`;
    urlParams += `&delta-date=${params.delta}`;
    urlParams += `&spot=${spotId}`;
    urlParams += `&lang=${params.lang}`;

    return `${this.graphUrl}?${urlParams}`;
  },

  /**
     * Get a point (lon, lat) oceanogram URL from params
     *
     * @param lon longitude of the point
     * @param lat latitude of the point
     * @param params the params
     * @return {string} the constructed URL
     */
  getLonLatOceanogrammeUrl(lon, lat, params) {
    let urlParams = `duration=${params.duration}`;
    urlParams += `&delta-date=${params.delta}`;
    urlParams += `&lon=${lon}`;
    urlParams += `&lat=${lat}`;
    urlParams += `&lang=${params.lang}`;

    return `${this.graphUrl}?${urlParams}`;
  },

  downloadArchive(model, mail, date) {
    const promise = $.Deferred();

    let urlParams = `date=${date}`;
    urlParams += `&model=${model}`;
    urlParams += `&mail=${mail}`;
    urlParams += '&generateVisu=true';

    const url = `${this.archiveUrl}?${urlParams}`;
    $.get(url)
      .done(data => {
        promise.resolve(data);
      })
      .fail(err => {
        promise.reject(err);
      });

    return promise;
  },

  isArchiveExist(model, date) {
    const promise = $.Deferred();

    let urlParams = `date=${date}`;
    urlParams += `&model=${model}`;

    const url = `${this.archiveExistUrl}?${urlParams}`;
    $.get(url)
      .done(data => {
        promise.resolve(data);
      })
      .fail(err => {
        promise.reject(err);
      });

    return promise;
  }
};

module.exports = function (options) {
  options = options || {};

  const defer = $.Deferred();
  const config = options.config || window.CONFIG;

  const service = Object.create(OceanogrammeService);
  service.config = config;
  service.graphUrl = config.oceanogramme.graphUrl;
  service.buoyUrl = config.oceanogramme.buoyUrl;
  service.archiveUrl = config.oceanogramme.archiveUrl;
  service.archiveExistUrl = config.oceanogramme.archiveExistUrl;
  defer.resolve(service);

  return defer;
};
