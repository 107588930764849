const Layers = require('../collection/layers');
/**
 * @static {object} GlobalDataStore
 */
const GlobalDataStore = {};

// List defined methods in GlobalDataStore
// These list is used only for cleanning object
const definedMethods = [
  'init',
  'get',
  'set',
  'add',
  'clean',
  'setLayers',
  'setDisplayedLayers',
  'getLayers',
  'getDisplayedLayers',
  '_initLayersCollectionEvents'
];

/**
 *
 * @param {Backbone.Collection} attributes.layers
 * @param {Backbone.Collection} attributes.displayedLayers
 * @param {string} attributes.lang
 */
GlobalDataStore.init = function (attributes = {}) {
  this._layers = attributes.layers || new Layers();
  if (attributes.displayedLayers) {
    this._displayedLayers = attributes.displayedLayers;
  } else {
    this._displayedLayers = new Layers(this._layers.filter(layer => layer.get('includedInMap')));
  }
  this._lang = attributes.lang;
  this._initLayersCollectionEvents();
};

/**
 * Get value of gloabalStorage attribute
 * @param {String} attributeName
 */
GlobalDataStore.get = function (attributeName) {
  return this[`_${attributeName}`];
};

/**
 * Set attribute value, create if not exists
 * @param attributeName
 * @param value
 */
GlobalDataStore.set = function (attributeName, value) {
  this[`_${attributeName}`] = value;
};
/**
 * Add attribute in storage
 * @param {String} attributeName
 * @param {string} value
 * @returns {boolean} false attributeName exist
 */
GlobalDataStore.add = function (attributeName, value) {
  if (this[`_${attributeName}`]) {
    return false;
  }
  this[`_${attributeName}`] = value;
  return true;
};
/**
 * Remove all created attributes in datastore
 */
GlobalDataStore.clean = function () {
  for (const attributeName in GlobalDataStore) {
    if (!GlobalDataStore.hasOwnProperty(attributeName) || ~definedMethods.indexOf(attributeName)) {
      continue;
    }
    delete GlobalDataStore[attributeName];
  }
};

/* ************************************************************
*************************** Setters ***************************
************************************************************* */
/**
 * @param {Backbone.Collection} layers
 */
GlobalDataStore.setLayers = function (layers) {
  this._layers = layers;
};

/**
 *
 * @param {Backbone.Collection} displayedLayers
 */
GlobalDataStore.setDisplayedLayers = function (displayedLayers) {
  this._displayedLayers = displayedLayers;
};

/* ************************************************************
*************************** Getters ***************************
************************************************************* */
/**
 * @returns {Backbone.Collection}
 */
GlobalDataStore.getLayers = function () {
  return this._layers;
};

/**
 * @returns {Backbone.Collection}
 */
GlobalDataStore.getDisplayedLayers = function () {
  return this._displayedLayers;
};

/* ************************************************************
*************************** Privates ***************************
************************************************************* */
/**
 * Init events when add or update layer in _layers
 * Sychnronization between _layers and _displayedLayers
 * @private
 */
GlobalDataStore._initLayersCollectionEvents = function () {
  this._layers.on('remove', layer => {
    if (layer.get('includedInMap')) {
      this._displayedLayers.remove(layer);
    }
  });
  this._layers.on('change:includedInMap', layer => {
    if (layer.get('includedInMap')) {
      this._displayedLayers.add(layer);
    } else {
      this._displayedLayers.remove(layer);
    }
  });
};

module.exports = GlobalDataStore;
