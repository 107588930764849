var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none;\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"form-group required\" id=\"content-external-wms-url\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFromExternalFeed") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":63},"end":{"line":2,"column":118}}})) != null ? stack1 : "")
    + ">\n	<label for=\"external-wms-url\" class=\"col-sm-2 control-label\" data-i18n=\"catalog.external.wms.url.label\"></label>\n	<div class=\"col-sm-10\">\n		<input type=\"url\" class=\"form-control\" id=\"external-wms-url\" name=\"url\" data-i18n=\"[placeholder]catalog.external.wms.url.placeholder\">\n			<!-- http://geoservices.brgm.fr/geologie-->\n	</div>\n</div>\n\n<div class=\"form-group\" id=\"content-external-wms-version\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFromExternalFeed") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":58},"end":{"line":10,"column":113}}})) != null ? stack1 : "")
    + ">\n    <label for=\"version\" class=\"col-sm-2 control-label\" data-i18n=\"catalog.external.wms.version.label\"></label>\n    <div class=\"col-sm-10\">\n        <select class=\"form-control\" id=\"external-wms-version\" name=\"version\">\n            <option value=\"AUTO\">AUTO</option>\n            <option value=\"1.3.0\">1.3.0</option>\n            <option value=\"1.1.0\">1.1.0</option>\n            <option value=\"1.1.0\">1.1.1</option>\n            <option value=\"1.0.0\">1.0.0</option>\n        </select>\n    </div>\n</div>\n\n<div class=\"form-group\">\n	<label for=\"login\" class=\"col-sm-2 control-label\" data-i18n=\"catalog.external.wms.login.label\"></label>\n	<div class=\"col-sm-10\">\n		<input type=\"text\" class=\"form-control\" id=\"external-wms-login\" name=\"login\" data-i18n=\"[placeholder]catalog.external.wms.login.placeholder\">\n	</div>\n</div>\n\n<div class=\"form-group\">\n	<label for=\"password\" class=\"col-sm-2 control-label\" data-i18n=\"catalog.external.wms.password.label\"></label>\n	<div class=\"col-sm-10\">\n		<input type=\"password\" class=\"form-control\" id=\"external-wms-password\" name=\"password\" data-i18n=\"[placeholder]catalog.external.wms.password.placeholder\">\n	</div>\n</div>\n\n<div class=\"form-group  col-sm-12\">\n    <input type=\"button\" class=\"btn btn-primary get-layers-button pull-right\" data-i18n=\"[value]catalog.external.wms.getlayers\" />\n</div>\n<div class=\"clearfix\"></div>\n<div class=\"form-group required external-wms-form-group\">\n	<label for=\"external-wms-layer\" class=\"col-sm-2 control-label\" data-i18n=\"catalog.external.wms.layer.label\"></label>\n	<div class=\"col-sm-10\">\n		<select class=\"form-control\" id=\"external-wms-layer\" name=\"layer\" size=\"10\">\n		</select>\n	</div>\n</div>";
},"useData":true});