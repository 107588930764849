const ShomView = require('../../../core/shom-view.js');
const template = require('../../../../template/drawing/discuss/session-user-picker-modal.hbs');

module.exports = ShomView.build({

  events: {
    'click .ok-button': '_onClickOk',
    'click .cancel-button': '_onClickCancel'
  },

  titleKey: '',
  messageKey: '',
  okKey: '',
  cancelKey: '',

  initialize(options) {
    const optionsToUse = options || {};
    this.titleKey = optionsToUse.titleKey;
    this.messageKey = optionsToUse.messageKey;
    this.okKey = optionsToUse.okKey;
    this.cancelKey = optionsToUse.cancelKey;
    this.config = optionsToUse.config || window.CONFIG;
    this._parent = optionsToUse.parent;
    this._modalView = optionsToUse.modalView;
    this._callback = optionsToUse.callback;
    this._user = optionsToUse.user || window.ROUTER.user;
    this.collection.remove(this.collection.findWhere({ key: this._user.get('key') }));
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({
      collection: this.collection.toJSON(),
      titleKey: this.titleKey,
      messageKey: this.messageKey,
      okKey: this.okKey,
      cancelKey: this.cancelKey
    }));
    return this;
  },

  _onClickOk() {
    const user = this._getUser();
    if (user !== null) {
      this._modalView.hide();
      this._callback && this._callback(user);
    }
  },

  _onClickCancel() {
    this._modalView.hide();
  },

  _getUser() {
    let user = null;
    this.$('.help-inline').addClass('hidden');
    const userKey = this.$('#user-list').val();
    if (userKey) {
      user = this.collection.findWhere({ key: userKey }) || null;
    }
    return user;
  }
});
