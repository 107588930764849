var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel-heading subpanel-heading\">\n  <h4 class=\"panel-title\">\n    <a class=\"accordion-toggle collapsed link-collapse-category\" data-toggle=\"collapse\" data-parent=\"#collapse-category-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"parentCategoryId") : depth0), depth0))
    + "\" href=\"#collapse-category-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"category") : depth0), depth0))
    + "\">\n    </a>\n  </h4>\n</div>\n<div id=\"collapse-category-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"category") : depth0), depth0))
    + "\" class=\"panel-collapse collapse\">\n  <div class=\"panel-body category-layers-root\">\n  	\n  </div>\n</div>\n";
},"useData":true});