const ShomView = require('../../core/shom-view');

const template = require('../../../template/modal/alert.hbs');

module.exports = ShomView.build({
  events: {
    'click .ok-button': '_hide'
  },

  initialize(options) {
    const optionsToUse = options || {};
    this.config = optionsToUse.config || window.CONFIG;
    this._parent = optionsToUse.parent;
    this.title = optionsToUse.title;
    this.message = optionsToUse.message;
    this._modalView = optionsToUse.modalView;
    this._hideOKButton = !!optionsToUse.hideOKButton;
  },

  _hide() {
    this._modalView.hide();
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({
      title: this.title,
      message: this.message,
      hideOKButton: this._hideOKButton
    }));
    return this;
  }
});
