import LayerModelUtils from '../../utils/layer-model-utils';

const $ = require('jquery');
const _ = require('underscore');
const Dialog = require('bootstrap-dialog');

const OceanoLayerUtils = require('../../utils/oceano/oceano-layer.js');
const OceanoNcwmsUtils = require('../../utils/oceano/oceano-ncwms');
const OceanoHelper = require('../../utils/gis/oceano-helper');
const ToastrUtil = require('../../utils/toastr.js');
const AtlasHelper = require('../../utils/atlas/atlas-utils.js');
const DDMDataUtils = require('../../utils/ddm/ddm-data-utils');

const NCWMSService = require('../../service/ncwms');

const ShomView = require('../../core/shom-view');
const template = require('../../../template/oceano/ocea-animation-export-modal.hbs');

module.exports = ShomView.build({

  className: 'hitable modal-dialog modal-timeseries',

  /** ***************************** */
  /** ****** INIT FUNCTIONS ******* */
  /** ***************************** */

  initialize(options = {}) {
    this._gisview = options.gisview || window.GISVIEW;
    this._modalView = options.modalView || window.MODALVIEW;
    this._config = options.config || window.CONFIG;

    // moments are updated to match ncwms2 STRICT COMPARISON policy
    this._firstMoment = options.firstMoment.subtract(1, 'minutes');
    this._lastMoment = options.lastMoment.add(1, 'minutes');

    this._availableLayers = options.layersAvailable;
    this._selectedLayer_idx = 0;

    this._availableFramerates = [1, 2, 5, 10, 15, 24, 30];
    this._animationFramerate = Number(options.framerate);

    this._animationMaxHeight = options.animationMaxHeight || this._config.oceano.animationExport.maxHeight;
    this._animationMaxWidth = options.animationMaxWidth || this._config.oceano.animationExport.maxWidth;

    this._currentUtc = options.currentUtc;

    this._spmDiffFromNearestPmFormat = this._config.tfs.diffFromNearestPmDateTimeFormat;
    this._ddmDataUtils = options.ddmDataUtils || new DDMDataUtils(this._config);
  },

  /** ***************************** */
  /** ***** RENDER FUNCTIONS ****** */
  /** ***************************** */

  render() {
    this.$el.html(template());

    this._$selectFramerate = this.$('#aemv-framerate-select');
    this._$selectOceaLayers = this.$('#aemv-ocea-layers-select');
    this._$modalBtn_exportAnimation = this.$('#aemv-btn-ocea-animation-export');
    this._$warningMessageDiv = this.$('#aemv-mono-layer-export-warning');

    this._$modalBtn_exportAnimation.on('click', _.bind(this._onExportAnimation, this));
    this._$selectFramerate.on('change', _.bind(this._onFramerateChange, this));
    this._$selectOceaLayers.on('change', _.bind(this._onOceaLayerChange, this));

    this._renderSelectFramerate();
    this._renderSelectLayers();
    this._renderWarningMessage();

    this.$('.modal-content').draggable({
      handle: '.modal-header'
    });

    return this;
  },

  _renderSelectFramerate() {
    let framerateValues = '';
    for (let fpsIdx = 0; fpsIdx < this._availableFramerates.length; fpsIdx++) {
      const fps = this._availableFramerates[fpsIdx];
      if (fps === this._animationFramerate) { // selected by default
        framerateValues += `<option value='${fps}' selected>`;
      } else {
        framerateValues += `<option value='${fps}'>`;
      }
      framerateValues += `${fps}</option>`;
    }
    this._$selectFramerate.html(framerateValues);
  },

  _renderSelectLayers() {
    this._availableLayers.sort((layer1, layer2) => layer2.model.get('index') - layer1.model.get('index'));
    let framerateValues = '';
    for (let layerIdx = 0; layerIdx < this._availableLayers.length; layerIdx++) {
      const layerModel = this._availableLayers[layerIdx].model;
      const layerName = AtlasHelper.isAtlasLayer(layerModel) ? AtlasHelper.getLayerTitleAtlas(layerModel, window.lang)
        : OceanoLayerUtils.getLayerTitle(layerModel, window.portalLang);
      if (layerIdx === 0) { // selected by default
        framerateValues += `<option value='${layerIdx}' selected>`;
      } else {
        framerateValues += `<option value='${layerIdx}'>`;
      }
      framerateValues += `${layerName}</option>`;
    }
    this._$selectOceaLayers.html(framerateValues);
  },

  _renderWarningMessage() {
    if (this._availableLayers.length === 1) {
      this._$warningMessageDiv.hide();
    }
  },

  /** ***************************** */
  /** ***** EVENT FUNCTIONS ******* */
  /** ***************************** */

  _onFramerateChange() {
    this._animationFramerate = this._$selectFramerate.val();
  },

  _onOceaLayerChange() {
    this._selectedLayer_idx = this._$selectOceaLayers.val();
  },

  _retrieveAnimationDates(oceaLayerModel, currentUtc, selectedDate, newAnimationMoments = []) {
    return this._ddmDataUtils.getDiffFromNearestPm(
      oceaLayerModel.get('portRef'),
      currentUtc,
      selectedDate.format(this._spmDiffFromNearestPmFormat),
      oceaLayerModel.get('isBm')
    )
      .then(data => +data.diff)
      .then(diffFromNearestSpm => AtlasHelper.computeNewDatetimeFromReference(diffFromNearestSpm, oceaLayerModel.get('olLayer').get('dimensions').processedTime))
      .then(datetime => newAnimationMoments.push(datetime))
      .fail(err => this._displayDialogAnimationError(err));
  },

  _onExportAnimation() {
    const promises = [];
    const newAnimationDates = [];

    const oceaLayerModel = this._availableLayers[this._selectedLayer_idx].model;
    const isAtlas = AtlasHelper.isAtlasLayer(oceaLayerModel);

    if (!isAtlas) {
      this._launchAnimationExport();
      return;
    }

    const animationMomentsForAtlas = [this._firstMoment, this._lastMoment];
    // if atlas, first retrieve result from spm then call line below with result given (ex: 01-01-1950T08:45)
    animationMomentsForAtlas.forEach(animationMoment => {
      const promise = this._retrieveAnimationDates(oceaLayerModel, this._currentUtc, animationMoment, newAnimationDates);
      promises.push(promise);
    });
    Promise.all(promises)
      .then(() => this._launchAnimationExport(isAtlas, newAnimationDates))
      .catch(err => this._displayDialogAnimationError(err));
  },

  _launchAnimationExport(isAtlas = false, newAnimationDates = []) {
    const layerGroup = this._availableLayers[this._selectedLayer_idx].model;

    const currentBbox = this._gisview.getCurrentViewBounds();
    const projection = this._gisview.getMapProjection();
    const mapSize = this._gisview.getMapSize();
    const mapDimensions = this._rescaleAnimationDimensions(mapSize[1], mapSize[0]);

    // animation timesteps processing.
    let layerGroupSortedDates = [];

    // if atlas layer selected, then keep moments from animation structure
    if (isAtlas) {
      // sort moments ascending
      layerGroupSortedDates = newAnimationDates.sort((m1, m2) => m1.diff(m2));
      // firstMoment / lastMoment updated
      this._firstMoment = _.first(newAnimationDates);
      this._lastMoment = _.last(newAnimationDates);
    } else {
      const dates = OceanoHelper.getProcessedTimeInZoom(layerGroup, this._gisview.getZoom());
      layerGroupSortedDates = OceanoNcwmsUtils.getSortedMomentArrayFromDatesStructure(dates);
    }

    const animationMoments = layerGroupSortedDates.filter(momnt => momnt.isBetween(this._firstMoment, this._lastMoment, null, '[]'));
    animationMoments.sort((m1, m2) => m1.diff(m2));

    const timeBounds = `${this._firstMoment.utc().toISOString()}/${this._lastMoment.utc().toISOString()}`;

    const animationParams = {
      animationMoments,
      mapDimensions,
      projection,
      layergroup: layerGroup,
      bbox: currentBbox,
      framerate: this._animationFramerate,
      time: timeBounds
    };

    const ncwmsServiceOptions = { ncwmsLayerType: LayerModelUtils.getNcwmsLayerType(layerGroup) };
    NCWMSService(ncwmsServiceOptions)
      .then(service => {
        let urlForDownload = '';
        service.getAnimationExportUrl(animationParams)
          .then(downloadUrl => {
            urlForDownload = downloadUrl;
            // Check if url can be reached
            return $.ajax({
              type: 'HEAD',
              url: downloadUrl
            });
          })
          .then(() => window.open(urlForDownload, '_self'))
          .fail(err => this._displayDialogAnimationError(err));
      })
      .fail(err => this._displayDialogNcwmsError(err));

    this._modalView.hide();
    ToastrUtil.clear();
    ToastrUtil.info($.i18n.t('oceano.nav.animation.modalexport.downloadToasterInfo'));
  },

  /** ***************************** */
  /** ***** UTILS FUNCTIONS ******* */
  /** ***************************** */

  _rescaleAnimationDimensions(height, width) {
    let finalHeight = this._animationMaxHeight;
    let finalWidth = this._animationMaxWidth;

    if (height >= width) {
      finalWidth = Math.round((finalHeight / height) * width);
    } else {
      finalHeight = Math.round((finalWidth / width) * height);
    }

    const scaledDimensions = {
      height: finalHeight,
      width: finalWidth
    };
    return scaledDimensions;
  },

  _displayDialogAnimationError(errMsg) {
    this._displayDialogError('oceano.error.animation.loading.title', errMsg);
  },

  _displayDialogNcwmsError(errMsg) {
    this._displayDialogError('oceano.error.animation.ncwms.title', errMsg);
  },

  _displayDialogError(i18nTitleKey, message = '') {
    Dialog.show({
      type: Dialog.TYPE_DANGER,
      title: $.i18n.translate(i18nTitleKey),
      message
    });
  }
});
