import { getDistance } from 'ol/sphere';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Draw from 'ol/interaction/Draw';
import {
  Circle, Fill, Stroke, Style
} from 'ol/style.js';
import { LineString } from 'ol/geom.js';
import { transform as olTransform } from 'ol/proj';

const source = new VectorSource();

let sketch = null;

let map = null;

let handler = null;

const vector = new VectorLayer({
  source,
  style: new Style({
    stroke: new Stroke({
      color: '#FF4500',
      width: 2
    }),
    image: new Circle({
      radius: 7,
      fill: new Fill({
        color: '#FF4500'
      })
    })
  })
});

const drawInteraction = new Draw({
  type: 'LineString',
  source,
  style: new Style({
    stroke: new Stroke({
      color: '#FF4500',
      width: 2
    }),
    image: new Circle({
      radius: 2,
      stroke: new Stroke({
        color: '#FF4500'
      }),
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)'
      })
    })
  })
});

drawInteraction.on('drawstart', evt => {
  source.clear();
  sketch = evt.feature;
});

drawInteraction.on('drawend', () => {
  sketch = null;
});

const computeLength = function (line) {
  let length = 0;
  const coordinates = line.getCoordinates();
  const sourceProj = map.getView().getProjection();
  for (let i = 0, ii = coordinates.length - 1; i < ii; ++i) {
    const c1 = olTransform(coordinates[i], sourceProj, 'EPSG:4326');
    const c2 = olTransform(coordinates[i + 1], sourceProj, 'EPSG:4326');
    length += getDistance(c1, c2);
  }
  return length;
};

const pointerMoveHandler = function (evt) {
  if (evt.dragging) {
    return;
  }

  if (sketch) {
    let length;
    const geom = (sketch.getGeometry());
    if (geom instanceof LineString) {
      length = computeLength(geom);
      handler && handler.call(null, length);
    }
  }
};

const measureControl = {
  start(mapp, handlerr) {
    map = mapp;
    handler = handlerr;
    map.addLayer(vector);
    map.addInteraction(drawInteraction);
    map.on('pointermove', pointerMoveHandler);
  },
  stop() {
    source.clear();
    map.removeLayer(vector);
    map.removeInteraction(drawInteraction);
    map.un('pointermove', pointerMoveHandler);
  }
};

module.exports = measureControl;
