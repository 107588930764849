const normalize = require('normalize-strings');

module.exports = {
  pad(num, size) {
    let s = `${num}`;
    while (s.length < size) {
      s = `0${s}`;
    }
    return s;
  },

  removeDiacritics(str) {
    return normalize(str);
  },

  // Check if string contains all string elements of an array
  isWordsIncludedInString(searchWords, stringInWhichSearch) {
    for (let i = 0; i < searchWords.length; ++i) {
      if (stringInWhichSearch.indexOf(searchWords[i]) === -1) {
        return false;
      }
    }
    return true;
  }
};
