const $ = require('jquery');
const Dialog = require('bootstrap-dialog');

const ShomLayerView = require('../../core/shom-layer-view');
const ExternalLayersHelper = require('../../utils/external-contexts');

const template = require('../../../template/catalog/external-context-search-result.hbs');

module.exports = ShomLayerView.build({

  className: 'catalog-layer-item',

  events: {
    'click .layer-title-container': '_onTitleClick',
    'click .add-remove-root': '_onTitleClick'
  },

  initialize(options = {}) {
    this._markTerm = null;
    this._gisView = options.gisView || window.GISVIEW;
    this._externalContexts = options.EXTERNALCONTEXTS || window.EXTERNALCONTEXTS;
    this._layers = options.LAYERS || window.LAYERS;
    this._helper = options.helper || new ExternalLayersHelper();
  },

  render() {
    this.$el.html(template({
      layer: this.model.toJSON(),
      external: this.external
    }));

    this._$layerTitle = this.$('.layer-title-container');
    this._$layerDescription = this.$('.description');
    this._$addRemoveButton = this.$('.add-remove-button');

    this._renderAddRemoveView();

    if (this._markTerm) {
      this.setMark(this._markTerm);
    }

    return this;
  },

  _renderAddRemoveView() {
    const includedInMap = this._helper.getIncludedInMapStatus(this._externalContexts, this.model);
    if (includedInMap) {
      this._$addRemoveButton.addClass('layer-included-in-map');
    } else {
      this._$addRemoveButton.removeClass('layer-included-in-map');
    }
  },

  _onTitleClick() {
    const includedInMap = this._helper.getIncludedInMapStatus(this._externalContexts, this.model);

    if (!includedInMap) {
      this._loadContext();
    } else {
      this._helper.setIncludedInMapStatus(this._externalContexts, this.model, false);
      const contextName = this.model.get('context');

      this._layers.each(layer => {
        if (layer && layer.get('contextName') === contextName) {
          layer.set('includedInMap', false);
        }
      });
      this._$addRemoveButton.removeClass('layer-included-in-map');
    }
  },

  _loadContext() {
    const url = `${this.model.get('baseUrl')}/${this.model.get('context')}`;

    $.get(url)
      .then(wmcDocument => {
        this._gisView.loadContext(wmcDocument, false, null, this.model.get('context'));
        this._helper.setIncludedInMapStatus(this._externalContexts, this.model, true);
        this._$addRemoveButton.addClass('layer-included-in-map');
      })
      .fail(() => {
        Dialog.show({
          type: Dialog.TYPE_DANGER,
          title: $.i18n.t('error.application.external.title'),
          message: $.i18n.t('error.application.external.message')
        });
      });
  }
});
