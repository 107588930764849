const $ = require('jquery');
const ShomView = require('../core/shom-view');
const ToastrUtil = require('../utils/toastr.js');
const template = require('../../template/top-share-menu.hbs');
const ShareService = require('../service/share.js');

module.exports = ShomView.build({

  className: 'shom-left-menu-container',

  events: {
    'click #anchor-mail': '_onMailHandler',
    'click #copy-view': '_onCopyViewHandler',
    'click #share-facebook': '_onFacebookHandler',
    'click #share-twitter': '_onTwitterHandler',
    'click #close-share-menu': function(){this.onCloseMenuButtonClick(event, '.my-share');},
    'blur #copy-view': function(){this.onLastMenuItemBlur(event, '#close-share-menu');}
  },

  initialize(options = {}) {
    this._lastHref = null;
    this._gisView = options.gisView || window.GISVIEW;
    this._config = options.config || window.CONFIG;
    this._gisView._map.on('moveend', this._onMapChange.bind(this));
    this._defaultZoomValue = this._gisView._map.getView().getZoom();
    this._shareService = new ShareService({
      config: this._config,
      gisView: this._gisView
    });
  },

  render() {
    this.$el.html(template());
    $('#panel-my-share').hide();
    this._copyViewLink = this.$('.copy-view-link');
    this.$shareFacebook = this.$('#share-facebook');
    this.$shareTwitter = this.$('#share-twitter');
    return this;
  },

  _toggleLink() {
    if (this._copyViewLink.hasClass('show')) {
      this._copyViewLink.removeClass('show');
    }
  },

  _onMapChange() {
    if (this._gisView._map.getView().getZoom() !== this._defaultZoomValue) {
      this._toggleLink();
      this._defaultZoomValue = this._gisView._map.getView().getZoom();
    }
  },

  postTranslate() {
    this._toggleLink();
  },

  _getCurrentUrl() {
    return `${window.location.origin}/datacontext/${window.portalLang}/${this._shareService.getIdContext()}`;
  },

  _mailToParams() {
    const mailSubject = $.i18n.t('share.mail.subject');
    const mailBody = $.i18n.t('share.mail.body', this._getCurrentUrl());
    return `mailto:?subject=${mailSubject}&body=${mailBody}`;
  },

  _refreshMailHref() {
    window.location.href = this._mailToParams();
  },

  _onMailHandler(e) {
    e.preventDefault();
    this._shareService.sendExportContextFile()
      .then(data => {
        if (this._onSuccessHandler(data)) {
          this._refreshMailHref();
        }
      }, err => {
        this._onErrorHandler(err);
      });
  },

  _onCopyViewHandler(e) {
    e.preventDefault();
    this._shareService.sendExportContextFile()
      .then(data => {
        if (this._onSuccessHandler(data)) {
          this._copyViewLinkHandler();
        }
      }, err => {
        this._onErrorHandler(err);
      });
  },

  // open/hide  input with share link
  _copyViewLinkHandler() {
    this._copyViewLink.val(this._getCurrentUrl());
    this._copyViewLink.addClass('show');
    // close input on click outside
    $(document).on('click', function (event) {
      const $copyView = $(this).find('#copy-view');
      const $copyViewLink = $(this).find('.copy-view-link');
      if (!$(event.target).closest($copyView).length && !$(event.target).closest($copyViewLink).length) {
        $($copyViewLink).removeClass('show');
      }
    });
    // close input on zoom
    this._onMapChange();
    try {
      navigator.clipboard.writeText(this._getCurrentUrl()).then(
        this._showCopySuccessMsg.bind(this),
        this._showCopyErrorMsg.bind(this)
      );
    } catch (err) {
      this._showCopyErrorMsg($.i18n.t('share.copyView.failed'));
      console.error('Failed to copy: ', err);
    }
  },

  _showCopySuccessMsg() {
    ToastrUtil.clear();
    ToastrUtil.info($.i18n.t('share.copyView.success'));
  },

  _showCopyErrorMsg(msg) {
    ToastrUtil.clear();
    ToastrUtil.error(msg);
  },

  // change the url when context change
  _socialShareLink(socialName) {
    this._shareService.sendExportContextFile()
      .then(data => {
        if (this._onSuccessHandler(data)) {
          this._openSocialShareLink(socialName);
        }
      }, err => {
        this._onErrorHandler(err);
      });
  },

  // open social media url
  _openSocialShareLink(socialName) {
    let url;
    if (socialName === 'facebook') {
      url = $.i18n.t('share.facebook') + this._getCurrentUrl();
      this.$shareFacebook.attr('href', url);
    } else {
      url = `${$.i18n.t('share.twitter') + this._getCurrentUrl()}&hashtags=shom`;
      this.$shareTwitter.attr('href', url);
    }
    window.open(url, '_blank');
  },

  _onFacebookHandler(e) {
    e.preventDefault();
    this._socialShareLink('facebook');
  },

  _onTwitterHandler(e) {
    e.preventDefault();
    this._socialShareLink('twitter');
  },

  _onErrorHandler(err) {
    switch (err.status) {
      case 400:
      case 404:
        this._showCopyErrorMsg($.i18n.t('share.error_validation'));
        break;
      case 500:
        this._showCopyErrorMsg($.i18n.t('share.error_server'));
        break;
      default:
        this._showCopyErrorMsg($.i18n.t('share.error_server'));
        break;
    }
  },

  _onSuccessHandler(data) {
    if (typeof data === 'object' && Object.keys(data).length === 0) {
      this._lastHref = window.location.href;
      return true;
    }
    this._showCopyErrorMsg($.i18n.t('share.error_server'));
    return false;
  }
});
