import LayerModelUtils from '../../utils/layer-model-utils';

import AtlasHelper from '../../utils/atlas/atlas-utils';

const $ = require('jquery');
const ShomView = require('../../core/shom-view');

const ToastrUtil = require('../../utils/toastr.js');
const OceanoLayerUtils = require('../../utils/oceano/oceano-layer.js');
const OceanoNcwmsUtils = require('../../utils/oceano/oceano-ncwms.js');
const OceaResultPopup = require('./ocea-result-popup.view');

const GfiView = require('../gfi-modal.view.js');

const template = require('../../../template/oceano/ocea-feature-info.hbs');
const CoordsHelper = require('../../utils/gis/coordinates-helper.js');

module.exports = ShomView.build({

  events: {
    "click input[type='button']": '_onButtonClick'
  },

  initialize(options = {}) {
    this._gisView = options.gisView || window.GISVIEW;
    this._parentView = options.parentView;
    this._config = options.config || window.CONFIG;
    this._gfiModeManager = options.gfiModeManager || window.POI_MODE_MANAGER;
    this._started = false;
    this._layer = null;
    this._closeCurrentPopUp = null;
    this._hasTimeseriesName = this.model.get('timeseriesName');

    this._currentGroupLayer = null;
    this._index = options.index;

    this._modalView = options.modalView || window.MODALVIEW;
    this._isAtlas = AtlasHelper.isAtlasLayer(this.model);
    this._initMinMaxColors();
    this._resultPopupView = null;
  },

  _initMinMaxColors() {
    let belowMinColorHexa = this._config.oceano.encoding.belowMinColor;
    let aboveMaxColorHexa = this._config.oceano.encoding.aboveMaxColor;

    belowMinColorHexa = belowMinColorHexa.substring(2, 4);
    this._belowMinColor = parseInt(belowMinColorHexa, 16);

    aboveMaxColorHexa = aboveMaxColorHexa.substring(2, 4);
    this._aboveMaxColor = parseInt(aboveMaxColorHexa, 16);
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({
      index: this._index,
      isFeatureInfoEnabled: !this._gisView.isGlobeEnabled()
    }));

    this._$toggleButton = this.$el.find(`#ocea-btn-feature-slider-${this._index}`);
    this._$selectorButton = this.$el.find(`#ocea-btn-feature-selector-${this._index}`);
    this._$selectorButton.on('click', this._onButtonClick.bind(this));
    return this;
  },

  /** ***************************** */
  /** ****** EVENT FUNCTION ******* */
  /** ***************************** */

  _onButtonClick(evt) {
    if (this._gisView.isGlobeEnabled()) {
      return;
    }
    evt.preventDefault();
    this._started ? this.trigger('change:stopFeatureInfo') : this.trigger('change:startFeatureInfo');
  },

  onClose() {
    if (this._started) {
      this.stopFeatureInfo();
    }
  },

  /** ***************************** */
  /** *** FEATURE INFO FUNCTION *** */
  /** ***************************** */

  startFeatureInfo() {
    if (this._started) {
      return;
    }
    this._started = true;

    this._$toggleButton.prop('checked', true);

    this._currentGroupLayer = this.model;

    // start feature info mode with click handler and get destructor
    this._stopFeatureInfoMode = this._gisView.startFeatureInfoMode(
      this._featureInfoHoverOverHandler.bind(this),
      this._featureInfoClickHandler.bind(this)
    );
  },

  _featureInfoHoverOverHandler() {
    if (this._currentCoord && this._currentCoord[0] === this._gisView.currentCoord[0]
            && this._currentCoord[1] === this._gisView.currentCoord[1]) {
      // no move : to keep exact value
      return;
    }

    this._currentGroupLayer = this.model;
    this._currentCoord = this._gisView.currentCoord;
    const pixel = this._gisView.currentPixel;
    this._layer = this._gisView.getTopNcwmsLayerOnCoord(this._currentCoord, 'NCWMSEncoding', this._parentView.getTileGrid(), this.model, pixel);

    if (!this._layer) {
      this._destroyPopUp();
      return;
    }

    let pixelData;
    // getPixelDataOnCoord is not working perfectly with wrapX (in particular around the bounds of the map (+/- 180°))
    // If the user uses hoverInfo in this area and we don't find any data, we display a warning popup
    try {
      pixelData = this._gisView.getPixelDataOnCoord(this._parentView.getTileGrid(), this._layer);
    } catch (e) {
      this._processResultPopup(null, true, null, $.i18n.t('oceano.popup.label.warning_no_data_wrap_x'));
      return;
    }

    if (pixelData[3] === 0) {
      this._destroyPopUp();
    } else {
      let value;
      let minMax;
      const encoding = this._currentGroupLayer.get('palette') ? this._currentGroupLayer.get('palette').encoding : undefined;
      if (pixelData[0] <= this._belowMinColor) {
        value = encoding[0];
        minMax = '<';
      } else if (pixelData[0] >= this._aboveMaxColor) {
        value = encoding[1];
        minMax = '>';
      } else {
        value = OceanoNcwmsUtils.evaluateValue(pixelData, encoding);
      }

      this._processResultPopup(value, true, minMax);
    }
  },

  _featureInfoClickHandler() {
    const clickCoord = this._gisView.currentCoord;
    const pixel = this._gisView.currentPixel;
    const gfiModal = new GfiView();
    const layer = this._gisView.getTopNcwmsLayerOnCoord(clickCoord, 'NCWMS', this._parentView.getTileGrid(), this.model, pixel);
    const layerConfiguration = {
      layerType: LayerModelUtils.getNcwmsLayerType(this.model),
      coord: clickCoord,
      layerTitle: AtlasHelper.isAtlasLayer(this.model) ? AtlasHelper.getLayerTitlesAtlas(this.model)
        : this.model.get('translation')[layer.get('title')],
      layerNumber: 1,
      gfiModal,
      catalogLayer: this.model,
      olLayer: layer
    };
    this._modalView.show(gfiModal);
    gfiModal.startLoading();
    this._gfiModeManager.gfiLayersHandler([layerConfiguration], gfiModal);
  },

  _processResultPopup(value, estimated, minMax, warningMessage) {
    this._destroyPopUp();
    const selectedUnit = OceanoLayerUtils.getSelectedUnitFromLayer(this.model);
    const unitLabel = this.model.get('translation').units[selectedUnit.name][window.portalLang];

    const conversion = selectedUnit.conversion || null;
    const options = OceanoLayerUtils.getNCWMSOptions(this._currentGroupLayer);
    let newValue = null;
    if (!value) {
      newValue = $.i18n.t('oceano.popup.label.no_data');
    } else {
      if (conversion) {
        newValue = OceanoNcwmsUtils.convertUnit(value, conversion.offset, conversion.rate);
      }
      if (options) {
        const { mantissa } = options;
        newValue = (mantissa) ? parseFloat(newValue || value).toFixed(mantissa) : newValue || value;
      }

      if (minMax) {
        newValue = `${minMax} ${newValue || value}`;
      }
    }
    const htmlContent = this._buildHTMLPopupContent(newValue, estimated, unitLabel || selectedUnit.name, warningMessage);

    // add popup and get destructor
    this._closeCurrentPopUp = this._gisView.addPopUp(this._gisView._map.getCoordinateFromPixel(this._gisView.currentPixel), htmlContent).closeFunc;
  },

  _buildHTMLPopupContent(value, estimated, unit, warningMessage) {
    const oceaResultPopup = new OceaResultPopup({
      lonlat: CoordsHelper.convertMercatToLonLat(this._currentCoord),
      value,
      estimated,
      unit,
      warning: warningMessage,
      infoLabelText: this._hasTimeseriesName ? 'oceano.popup.label.info' : 'oceano.popup.label.info_no_forecast'
    });
    return oceaResultPopup.render().$el.html();
  },

  _destroyPopUp() {
    if (this._closeCurrentPopUp !== null) {
      this._closeCurrentPopUp();
    }
  },

  stopFeatureInfo() {
    if (this._started !== true) {
      return;
    }
    this._started = false;
    this._layer = null;

    // call popup destructor
    if (this._closeCurrentPopUp !== null) {
      this._closeCurrentPopUp();
      this._closeCurrentPopUp = null;
    }

    this._$toggleButton.prop('checked', false);

    this._gisView.resetNcwmsOpacity();
    // call mode destructor
    if (this._stopFeatureInfoMode) {
      this._stopFeatureInfoMode();
    }

    ToastrUtil.clear();
  }
});
