import LayerModelUtils from '../utils/layer-model-utils';

import AtlasHelper from '../utils/atlas/atlas-utils';

const $ = require('jquery');
const _ = require('underscore');
const Dialog = require('bootstrap-dialog');
const ShomView = require('../core/shom-view');
const template = require('../../template/displayed-ocea-layer.hbs');
const OpacitySliderView = require('./layer-opacity-slider.view');
const VisibilityView = require('./layer-visibility.view');
const OceaSelectDepthView = require('./oceano/ocea-select-depth.view');
const OceaSelectUnitView = require('./oceano/ocea-select-unit.view');
const OceaSelectPaletteView = require('./oceano/ocea-select-palette.view');
const OceaToolsView = require('./oceano/ocea-tools.view');
const OceaSelectGlobDateView = require('./oceano/ocea-select-glob-date.view');
const OceanoLayerUtils = require('../utils/oceano/oceano-layer.js');
const OceanoNcwmsUtils = require('../utils/oceano/oceano-ncwms.js');
const OceanoHelper = require('../utils/gis/oceano-helper');
const GlobalDataStore = require('../core/global-data-store');

const LegendView = require('./legend/legend.view');

const NCWMSService = require('../service/ncwms');

const DisplayedOceaLayerView = ShomView.build({
  tagName: 'li',
  className: 'list-unstyled-item ui-state-default',

  events: {
    'click .more-info-button': '_onMoreInfoButtonClick',
    'click .download-button': '_onDownloadButtonClick',
    'click .remove-button': '_onRemoveLayer',
    'click .zoom-to-max-extend > i': '_onZoomToMaxExtentClick',
    'click .catalog-tools-parameters': '_onCatalogToolParametersClick',
    'click .catalog-tools-actions': '_onCatalogToolActionsClick',
    'click .catalog-tools-palette': '_onCatalogToolPaletteClick',
    'click .ocean-copernicus-layer-btn': '_onCopernicusLayerButtonClick'
  },

  initialize(options = {}) {
    this._parentView = options.parentView;
    this._gisView = options.gisView || window.GISVIEW;
    this._gfiModeManager = options.gfiModeManager || window.POI_MODE_MANAGER;
    this._router = options.router || window.ROUTER;
    this._eventsBus = options.eventsBus || this._router.eventsBus;
    this._context = this._router.getContext();
    this._first = options.first === true;
    this._last = options.last === true;
    this._layerType = 'oceano';
    // index is used to identify tools/button between oceano layer
    this.index = options.index;

    this._utcValue = this.model.get('selectedUtc');
    this._isAtlas = AtlasHelper.isAtlasLayer(this.model);

    // oceano layer title
    this._customTitle = this._isAtlas ? AtlasHelper.getLayerTitleAtlas(this.model, window.lang) : OceanoLayerUtils.getLayerTitle(this.model, window.portalLang);

    this._user = options.user || window.ROUTER.user;

    this.moreInfoanimationShowSpeed = 100;
  },

  _initListenner() {
    this.listenTo(this._selectDepthView, 'change:oceaSelectDepth', _.bind(this._onSelectDepthChange, this));
    this.listenTo(this._selectPaletteView, 'change:oceaSelectPalette', _.bind(this._onSelectPaletteChange, this));
    this.listenTo(this._selectPaletteView, 'change:oceaAutoPaletteParameters', _.bind(this._onUpdatePaletteMinMax, this));
    this.listenTo(this._selectUnitView, 'change:oceaSelectUnit', this._onSelectUnitChange.bind(this));
    this.listenTo(this._toolsView, 'change:oceaToolsState', this._onOceaToolsStateChange.bind(this));
    this.listenTo(this._selectPaletteView, 'showPaletteOnMap', this._onShowPaletteOnMap.bind(this));
    this.listenTo(this._eventsBus, 'change:onOceaZoomTimeChange', this._onOceaZoomTimeChange.bind(this));
    this.listenTo(this._eventsBus, 'discuss:editor:update', this._onEditorUpdate.bind(this));
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    const isLoggedIn = this._user.get('isLoggedIn');
    this.$el.html(template({
      layer: this.model.toJSON(),
      customTitle: this._customTitle,
      itemIndex: this.index,
      isMaxExtentDisabled: !this._isAtlas,
      isLoggedIn,
      layerType: this._layerType,
      isCopernicusLayerDisplayed: this._isCopernicusLayerDisplayed()
    }));
    this.$el.attr('id', this.model.get('identifier'));

    this._$layerTitle = this.$('.displayed-layer-title-container');

    this._$opacitySliderContainer = this.$('.opacity-slider-root');
    this._$visibilityContainer = this.$('.visibility-root');
    this._$orderRoot = this.$('.order-root');
    this._$downloadButton = this.$('.download-button');
    this._$catalogToolsPanel = this.$('.catalog-tools-panel');

    this._$moreToolsContainer = this.$('.ocea-more-tools-container');
    this._$moreInfoContainer = this.$('.more-info-container');
    this._$moreInfoContainer.hide();

    this._$copernicusLayerButton = this.$('.ocean-copernicus-layer-btn');

    const canEdit = this._context.get('isEditor');

    this._renderSubViews();

    if (!canEdit) {
      this._disableLayerButtons();
    }

    this._initListenner();

    setTimeout(() => {
      this.$('.catalog-tools-parameters a').tab('show');
    }, 0);

    return this;
  },

  _renderSubViews() {
    this._renderSelectGlobDateView();
    this._renderSelectDepthView();
    this._renderSelectUnitView();
    this._renderSelectPaletteView();
    this._renderToolsView();
    this._renderOpacitySliderView();
    this._renderVisibilityView();
    this._renderMoreInfoView();
  },

  _renderToolsView() {
    this._toolsView = new OceaToolsView({
      parentView: this,
      model: this.model,
      index: this.index
    });
    this._$tools = this.$(`#ocea-tools-container-${this.index}`);
    this._$tools.html(this._toolsView.render().$el);
  },

  _renderSelectPaletteView() {
    const values = OceanoNcwmsUtils.getRenderingPalettes(this.model);
    this._selectPaletteView = new OceaSelectPaletteView({
      model: this.model,
      values,
      index: this.index
    });
    this._$selectPalette = this.$(`#ocea-palette-container-${this.index}`);
    this._$selectPalette.html(this._selectPaletteView.render().$el);
  },

  _renderSelectDepthView() {
    const { elevation } = this.model.get('olLayer').get('dimensions');
    const values = (elevation) || undefined;
    this._selectDepthView = new OceaSelectDepthView({
      model: this.model,
      values,
      index: this.index
    });
    this._$selectDepth = this.$(`#ocea-depth-container-${this.index}`);
    this._$selectDepth.html(this._selectDepthView.render().$el);
  },

  _renderSelectUnitView() {
    this._selectUnitView = new OceaSelectUnitView({
      model: this.model,
      index: this.index
    });
    this._$selectUnit = this.$(`#ocea-unit-container-${this.index}`);
    this._$selectUnit.html(this._selectUnitView.render().$el);
  },

  _renderSelectGlobDateView() {
    this._selectGlobDateView = new OceaSelectGlobDateView({
      model: this.model,
      index: this.index,
      utc: this._utcValue,
      parentView: this
    });
    this._$selectGlobDate = this.$(`#ocea-glob-date-container-${this.index}`);
    this._$selectGlobDate.html(this._selectGlobDateView.render().$el);
  },

  _renderOpacitySliderView() {
    this._opacitySliderView && this._opacitySliderView.remove();
    this._opacitySliderView = new OpacitySliderView({
      model: this.model
    });
    this._$opacitySliderContainer.html(this._opacitySliderView.render().$el);
  },

  _renderVisibilityView() {
    this._visibilityView && this._visibilityView.remove();
    this._visibilityView = new VisibilityView({
      model: this.model
    });
    this._$visibilityContainer.html(this._visibilityView.render().$el);
  },

  _renderMoreInfoView() {
    this._moreInfoView && this._moreInfoView.remove();
    this._moreInfoView = new LegendView({
      model: this.model
    });
    this._$moreInfoContainer.html(this._moreInfoView.render().$el);
  },

  /** ***************************** */
  /** ****** EVENTS FUNCTION ****** */
  /** ***************************** */

  _onRemoveLayer() {
    const includedInMap = !this.model.get('includedInMap');
    this.model.set('selectedPalette', null);
    this.model.set('includedInMap', includedInMap);
    this._gfiModeManager.startGfiRequestableLayer(false);
  },

  _onMoreInfoButtonClick() {
    this._$moreToolsContainer.toggle(this.moreInfoanimationShowSpeed);
    const layers = this.model.get('olLayer').get('layers').getArray();
    const visibleLayers = _.filter(
      layers,
      layer => layer.get('visible')
    );

    if (visibleLayers && visibleLayers.length > 0) {
      const firstVisibleLayer = visibleLayers[0];
      const identifier = firstVisibleLayer.get('identifier');
      const url = this._isAtlas ? AtlasHelper.getMetadataUrl(identifier) : OceanoHelper.getMetadataUrl(identifier);
      this.model.set('metadataURL', url);
      this._moreInfoView.refreshLegend();
    }

    this._$moreInfoContainer.toggle(this.moreInfoanimationShowSpeed);
  },

  _onDownloadButtonClick() {
    this._$downloadButton.toggleClass('active');
  },

  _onSelectDepthChange() {
    const newDepth = this._selectDepthView.getSelectDepth();
    OceanoLayerUtils.setNCWMSParamsInGroupLayer(this.model, {
      depth: newDepth
    });
    this.model.set('selectedElevation', newDepth);
    this._refreshLayers();
  },

  _onSelectPaletteChange() {
    const palette = this._selectPaletteView.getPalette();
    this.model.set('selectedPalette', palette);
    if (palette.isAuto) {
      this._startAutoPalette(palette.name, true);
    } else {
      OceanoLayerUtils.setNCWMSParamsInGroupLayer(this.model, {
        pal: palette
      });
      this._refreshLayers();
    }
  },

  _onUpdatePaletteMinMax(palette) {
    if (palette && palette.isAuto) {
      this._startAutoPalette(palette.name, false);
    }
  },

  _onOceaToolsStateChange(event) {
    // todo disable layer configuration to prevent errors.

    if (event.buttonActivated) { // notifying the parentview that ocean-tool-view state changed
      this.trigger('displayedViewToolsState', {
        layerIndex: this.index
      });
    }
  },

  _onSelectUnitChange() {
    const unit = this._selectUnitView.getSelectedUnit();
    this.model.set('selectedUnit', unit);
  },

  _onShowPaletteOnMap(display) {
    this.model.set('showPaletteOnMap', display);
  },

  _onOceaZoomTimeChange() {
    this._renderSelectGlobDateView();
  },

  _onZoomToMaxExtentClick() {
    this._gisView.zoomToLayerRegionMaxExtent(this.model);
  },

  /** ***************************** */
  /** ** GETTER/SETTER FUNCTION *** */
  /** ***************************** */

  getFirstSelectedLayer() {
    return this.model;
  },

  getLayerType() {
    return this._layerType;
  },

  setValuesAndRender(values) {
    if (values.depth) {
      this._selectDepthView.setDepthAndRender(+values.depth);
    }
    // todo : add palette context restauration
  },

  resetToolsState() {
    this._toolsView.changeState('default');
  },

  /** ***************************** */
  /** ******* UTILS FUNCTION ****** */
  /** ***************************** */

  _refreshLayers() {
    _.each(this.model.get('olLayer').get('layers').getArray(), layer => {
      const visible = layer.get('visible');
      layer.set('visible', false);
      layer.set('visible', visible);
    });
  },

  _startAutoPalette(paletteName, isErrorDisplayable) {
    const layers = this.model.get('olLayer').get('layers').getArray();
    const visibleLayers = _.filter(layers, layer => layer.get('visible'));
    const bbox = this._gisView.getMapExtent();

    const ncwmsServiceOptions = { ncwmsLayerType: LayerModelUtils.getNcwmsLayerType(this.model) };
    NCWMSService(ncwmsServiceOptions)
      .then(service => service.getMinMaxLayers(visibleLayers, bbox, this.model.get('isArchive')))
      .then(minMax => this._refreshLayersAutoPalette(minMax, paletteName))
      .fail(() => {
        if (isErrorDisplayable) {
          const title = $.i18n.translate('oceano.error.auto.title');
          const mess = $.i18n.translate('oceano.error.auto.message');
          this._displayErrorMessage(title, mess);
        }
      });
  },

  _displayErrorMessage(title, message) {
    Dialog.show({
      type: Dialog.TYPE_DANGER,
      title,
      message
    });
  },

  _refreshLayersAutoPalette(minMax, paletteName) {
    const paletteAuto = OceanoLayerUtils.setUpAutoPaletteOnLayer(this.model, paletteName, minMax);
    this._selectPaletteView.renderPaletteAuto(paletteAuto);
    this._refreshLayers();
  },

  onClose() {
    // prevents memory leaks
    this.stopListening();
    // removes views
    this._visibilityView && this._visibilityView.remove();
    this._opacitySliderView && this._opacitySliderView.remove();
    this._selectDepthView && this._selectDepthView.remove();
    this._selectUnitView && this._selectUnitView.remove();
    this._selectPaletteView && this._selectPaletteView.remove();
    this._selectGlobDateView && this._selectGlobDateView.remove();
    this._toolsView && this._toolsView.remove();
  },

  postTranslate(lang) {
    this.model && this.model.trigger('change:selectedUnit');
    if (this._customTitle) {
      this._customTitle = this._isAtlas ? AtlasHelper.getLayerTitleAtlas(this.model, lang, true) : OceanoLayerUtils.getLayerTitle(this.model, lang);
      this._$layerTitle.text(this._customTitle);
      this._$layerTitle.attr('title', this._customTitle);
    }
  },

  _disableLayerButtons() {
    this._$opacitySliderContainer.addClass('layer-button-disabled');
    this._$visibilityContainer.addClass('layer-button-disabled');
    this.$('.more-info-button').addClass('hide');
  },

  _onEditorUpdate(isEditorUpdate) {
    if (isEditorUpdate) {
      this.render();
    }
  },

  /**
     *
     * @private
     */
  _onCopernicusLayerButtonClick() {
    if (this._isCopernicusLayerDisplayed()) {
      this._gisView.removeCopernicusGis(this.model.get('copernicusLink'));
    } else {
      this._gisView.initCopernicusGis(this.model.get('copernicusLink'));
    }

    this.render();
  },

  /**
     * Check if the copernicus layer linked to the current oceano layer is displayed or not
     *
     * @returns {boolean} true if the copernicus layer is displayed
     * @private
     */
  _isCopernicusLayerDisplayed() {
    return GlobalDataStore.getDisplayedLayers()
      .find(layer => this.model.get('copernicusLink') !== undefined
                    && this.model.get('copernicusLink') === layer.get('name')) !== undefined;
  }
});

module.exports = DisplayedOceaLayerView;
