import AtlasHelper from '../utils/atlas/atlas-utils';

import { NCWMS_LAYERTYPE_OCEANO, NCWMS_LAYERTYPE_RADARHF, NCWMS_LAYERTYPE_ATLAS } from '../utils/constants';

const $ = require('jquery');
const ShomView = require('../core/shom-view');
const template = require('../../template/gfi-modal.hbs');
const CoordsHelper = require('../utils/gis/coordinates-helper.js');
const OceanoLayerUtils = require('../utils/oceano/oceano-layer.js');
const OceanoNcwmsUtils = require('../utils/oceano/oceano-ncwms.js');
const GfiModalContentUtils = require('../utils/gfi-modal-content');
const Loading = require('../utils/loading');
const GlobalDataStore = require('../core/global-data-store');

const RoseCourantItemsView = require('./atlas/rose-courant-items.view');

const MODAL_MIN_WIDTH = 350;
const MODAL_MIN_HEIGHT = 300;

module.exports = ShomView.build({

  className: 'hitable modal-dialog gfi-modal-result',

  events: {
    'click .gfi-accordion-toggle': '_collapseHandler'
  },

  initialize(options = {}) {
    this._htmlContent = options.html;
    this._title = options.title;
    this._gisView = options.gisView || window.GISVIEW;
  },

  render() {
    this.$el.html(template({ isNoData: !!this._isNoData }));
    this.$el.find('.modal-content').draggable({
      handle: '.modal-header'
    }).resizable({
      minHeight: MODAL_MIN_WIDTH,
      minWidth: MODAL_MIN_HEIGHT
    });
    this._$accordion = this.$('#accordion-gfi');
    this._maxLayerCount = 0;
    return this;
  },

  /**
   * @typedef import('../model/layer.js').default Layer
   * @param options
   * @param options.html {string}
   * @param options.title {string | {fr?: string, en?: string}}
   * @param options.type {string}
   * @param options.number {number}
   * @param options.layerType {string}
   * @param options.layer {Layer}
   * @param options.olLayer {ol.Layer}
   */
  updateLayerInformations(options = {}) {
    this._content = options.html;
    this._layerType = options.type;
    this._title = (options.title && this._isOceanoOrAtlasType()) ? options.title[GlobalDataStore.get('lang')] : options.title;
    this._number = options.number;
    this._layer = options.layer;
    this._olLayer = options.olLayer;
    this._isAtlas = this._layer && AtlasHelper.isAtlasLayer(this._layer);
    this._data = options.dataRoseCourant;
  },

  _isOceanoOrAtlasType() {
    return this._layerType === NCWMS_LAYERTYPE_OCEANO || this._layerType === NCWMS_LAYERTYPE_RADARHF || this._layerType === NCWMS_LAYERTYPE_ATLAS;
  },

  updateTimeSeriesInformations(options) {
    this._coord = options.coord;
    this._coordWGS84 = CoordsHelper.convertMercatToLonLatModulo(this._coord);
    this._date = options.date;
  },

  addLayerData() {
    let htmlTSContent = '';
    let collapseIn = '';
    let toogleCollapse = ' collapsed';
    let contentHeight = 300;

    if (this._isOceanoOrAtlasType()) {
      htmlTSContent = this._isAtlas ? GfiModalContentUtils.addHtmlAtlasContent(this._number) : GfiModalContentUtils.addHtmlOceanoContent(this._number);
      contentHeight = this._layerType === NCWMS_LAYERTYPE_RADARHF ? 10 : 415;
    }
    if (this._number === 1) {
      collapseIn = ' in ';
      toogleCollapse = '';
    }

    const newHtmlContent = this._isAtlas
      ? GfiModalContentUtils.addHtmlAtlasBaseContent(
        this._title,
        this._number,
        toogleCollapse,
        collapseIn,
        htmlTSContent,
        this._isAtlas
      )
      : GfiModalContentUtils.addHtmlBaseContent(
        this._title,
        this._number,
        toogleCollapse,
        collapseIn,
        htmlTSContent,
        this._layerType === 'radarhf'
      );

    this._addHtmlContent(newHtmlContent, contentHeight);

    if (this._number > this._maxLayerCount) {
      this._maxLayerCount = this._number;
      this.setLayerCount(this._maxLayerCount);
    }
  },

  /**
   * Add htmlConent at end of gfi modal
   * @param htmlContent {string}
   * @param contentHeight {number}
   * @private
   */
  _addHtmlContent(htmlContent, contentHeight) {
    this._$accordion.append(htmlContent);
    const $iframe = this.$(`#iframe-${this._number}`);

    if (this._layerType === 'reference' || this._layerType === 'PARTNER') {
      const doc = $iframe[0].contentWindow.document;
      doc.open();
      doc.write(this._content);
      doc.close();
      this.stopLoading();
    } else if (this._layerType === NCWMS_LAYERTYPE_OCEANO || this._layerType === NCWMS_LAYERTYPE_ATLAS) {
      $iframe.attr('src', this._content);
      this._prepareTimeSeriesInfo();
      this.$('.modal-dialog').addClass('modal-oceano');
      $iframe.on('load', this.stopLoading.bind(this));
      if (this._isAtlas) {
        this._addEventsForAtlas();
      }
    } else {
      this.stopLoading();
    }

    setTimeout(() => {
      $iframe
        .width('100%')
        .height(contentHeight);
    }, 300);
  },

  _addEventsForAtlas() {
    // tab click, modal resize, accordion click: reload iframe to have nice timeseries display
    this._addTabClickEvent();
    this._addTitleAccordionClick();
    this._addModalResize();
  },

  _addTabClickEvent() {
    // tab click: reload iframe to have nice timeseries display
    const $tabTimeseries = this.$(`#tabTimeseries-${this._number}`);
    this.$(`#a-timeseries-${this._number}`).click(() => this._reloadTimeseriesIframe(!$tabTimeseries.hasClass('active')));
  },

  _addTitleAccordionClick() {
    // title accordion click: reload iframe to have nice timeseries display
    const $aToggleAccordion = this.$(`#a-toggle-accordion-${this._number}`);
    $aToggleAccordion.click(() => this._reloadTimeseriesIframe($aToggleAccordion.hasClass('collapsed')));
  },

  _addModalResize() {
    // modal resize event: reload iframe to have nice timeseries display
    let resizeTimer;
    this.$('.modal-content').on('resize', () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => this._reloadTimeseriesIframe(), 250);
    });
  },

  _reloadTimeseriesIframe(forceReload = true) {
    if (!forceReload) {
      return;
    }
    const $iframe = this.$(`#iframe-${this._number}`);
    this.startLoading();
    // force reload to have correct timeseries display
    $iframe.attr('src', $iframe.attr('src'));
  },

  _prepareTimeSeriesInfo() {
    if (!this._olLayer) {
      this.$(`#gfi-real-value-${this._number}`).attr('data-i18n', 'oceano.toaster.featureinfo.error.no_layer');
    }
  },

  insertAtlasValues(values, number) {
    // set tabs label
    this.$(`#a-timeseries-${number}`).append($.i18n.t('oceano.modal.tabs.timeseries'));
    this.$(`#a-rose-${number}`).append($.i18n.t('oceano.modal.tabs.rose_courant'));

    try {
      this.$(`#gfi-portref-${number}`)
        .append(
          `${$.i18n.t('oceano.modal.rose.port_ref')
          } : <span class="oceano-data-value">${this._layer.get('portRef')}</span>`
        );
    } catch (error) {
      this.$(`#iframe-${this._number}`).attr(error);
    }

    this._addRoseCourantTableView(values, number);
  },

  _addRoseCourantTableView(values, number) {
    // create table view and add it to tab
    const roseCourantItemsView = new RoseCourantItemsView({
      config: this._config,
      collection: AtlasHelper.transformAtlasValuesForDisplay(values, this._layer),
      data: this._data
    });
    const $roseCourantContainer = this.$(`#contentRoseCourant-${number}`);
    $roseCourantContainer.html(roseCourantItemsView.render().$el);
  },

  insertOceanoTSValues(value, number) {
    const oceaLayerUTC = +this._gisView.getGlobalOceanoCurrentUTC() || 0;
    const oceaLayerMoment = this._gisView.getOceanoLayerCurrentMoment(this._layer);
    this._selectedDate = OceanoNcwmsUtils.getModalFormattedDate(oceaLayerMoment, oceaLayerUTC);
    const selectedUnit = OceanoLayerUtils.getSelectedUnitFromLayer(this._layer);
    const unitLabel = this._layer.get('translation').units[selectedUnit.name][window.portalLang];
    const conversion = selectedUnit.conversion || null;
    const options = OceanoLayerUtils.getNCWMSOptions(this._layer);

    let newValue;
    newValue = conversion ? OceanoNcwmsUtils.convertUnit(value, conversion.offset, conversion.rate) : value;

    if (options) {
      const { mantissa } = options;
      newValue = (mantissa) ? parseFloat(newValue).toFixed(mantissa) : newValue;
    }

    try {
      this.$(`#gfi-real-value-${number}`)
        .append(
          `${$.i18n.t('oceano.modal.timeseries.exact_value')
          } : <span class="oceano-data-value">${newValue} ${unitLabel}</span>`
        );
      this.$(`#gfi-coord-lng-result-${number}`)
        .append(
          `${$.i18n.t('oceano.modal.timeseries.longitude')
          } : <span class="oceano-data-value">${this._coordWGS84[0].toString().substring(0, 7)}</span>`
        );
      this.$(`#gfi-coord-lat-result-${number}`)
        .append(
          `${$.i18n.t('oceano.modal.timeseries.latitude')
          } : <span class="oceano-data-value">${this._coordWGS84[1].toString().substring(0, 7)}</span>`
        );
      this.$(`#gfi-date-selected-${number}`)
        .html(`${$.i18n.t('oceano.modal.timeseries.date_selected')
        } : <span class="oceano-data-value">${this._selectedDate}</span>`);
    } catch (error) {
      this.$(`#iframe-${this._number}`).attr(error);
    }
  },

  setLayerCount(number) {
    if (number === 1) {
      this.$('#gfi-layer-count').html(`${$.i18n.t('gfiWmtsPoi.popup.interrogation.informations')} ${$.i18n.t('gfiWmtsPoi.popup.interrogation.layerCount.one')}`);
    } else {
      this.$('#gfi-layer-count').html(`${$.i18n.t('gfiWmtsPoi.popup.interrogation.informations')} ${$.i18n.t('gfiWmtsPoi.popup.interrogation.layerCount.other', number)}`);
    }
  },

  _collapseHandler(evt) {
    const hrefEl = evt.target.href;
    const layedId = hrefEl.substring(hrefEl.length - 1, hrefEl.length);

    for (let layerCount = 1; layerCount <= this._maxLayerCount; layerCount++) {
      if (layerCount !== layedId) {
        if (this.$(`#collapse${layerCount}`).hasClass('in')) {
          this.$(`#accordion-gfi #collapse${layerCount}`).collapse('toggle');
        }
      }
    }
  },

  setNoData(isNoData) {
    this._isNoData = isNoData;
  },

  startLoading() {
    Loading.start($('#accordion-gfi'));
  },

  stopLoading() {
    Loading.stop($('#accordion-gfi'));
  },

  onClose() {
    this.$(`#iframe-${this._number}`).off('load');

    if (this._isAtlas) {
      this.$(`#tabTimeseries-${this._number}`).off('click');
      this.$(`#a-toggle-accordion-${this._number}`).off('click');
      this.$('.modal-content').off('resize');
    }
  }

});
