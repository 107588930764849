import { unByKey } from 'ol/Observable.js';

const _ = require('underscore');
const GisView = require('../gis.view.js');

let vProfileClickHandler;

GisView.prototype.startVerticalProfileMode = function (identifier) {
  vProfileClickHandler = this._map.on('singleclick', _.bind(function (evt) {
    this.trigger(`mode-verticalProfile:click${identifier}`, evt);
  }, this));
};

GisView.prototype.stopVerticalProfileMode = function () {
  unByKey(vProfileClickHandler);
};
