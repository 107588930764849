const $ = require('jquery');
const ShomView = require('../../core/shom-view');
const template = require('../../../template/user-menu/forgot-pass.hbs');
const FormValidationData = require('../../data/forms/login-form');
const ToastrUtil = require('../../utils/toastr.js');

const ForgotPassView = ShomView.build({

  className: 'left-menu-content',

  events: {
    'click #close-forgot-pass': '_onCrossClick',
    'click .btn-reinit-pass': 'click _onAskReinitPass'
  },

  initialize(options = {}) {
    this._router = options.router || window.ROUTER;
    this._config = options.config || window.CONFIG;
    this.eventsBus = options.eventsBus || window.EVENTBUS;
  },

  render() {
    this.$el.html(template({
      user: this._router.user.toJSON()
    }));

    this.$('form.user-reinit-pass').bootstrapValidator({
      submitButtons: 'button.btn-reinit-pass',
      fields: FormValidationData()
    }).on('success.form.bv', this._onAskReinitPass.bind(this));

    return this;
  },

  _onCrossClick() {
    this.eventsBus.trigger('user:openmenu');
  },

  _onAskReinitPass(event) {
    (event.preventDefault) ? event.preventDefault() : event.returnValue = false;
    const key = this.$('input[name=user-key]').val();

    const targetUrl = this._config.askNewPassUrl.replace('{{key}}', key);

    const req = $.ajax({
      type: 'PUT',
      url: targetUrl
    });

    req.fail(() => {
      ToastrUtil.error($.i18n.t('user.forgotPass.errorAskNewPass'), 8000);
    });

    ToastrUtil.info($.i18n.t('user.forgotPass.infoAskNewPass'));
    this.eventsBus.trigger('user:openmenu');
  }

});

module.exports = ForgotPassView;
