var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n        <div class=\"modal-header\">\n            <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">&times;</button>\n            <h4 id=\"map-properties-title\" class=\"modal-title\" data-i18n=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "\"></h4>\n        </div>\n        <div class=\"modal-body\">\n            <form class=\"form-horizontal\">\n                <div id=\"map-properties-modal-content\">\n                    <div class=\"form-group\">\n                        <label for=\"map-name\" class=\"control-label col-sm-4\"\n                               data-i18n=\"drawing.modal.map-properties.mapName\" required> </label>\n                        <div class=\"col-sm-8\">\n                            <input id=\"map-name\" type=\"text\" class=\"form-control map-name\" name=\"map-name\"\n                                   value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\"/>\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"map-description\" class=\"control-label col-sm-4\"\n                               data-i18n=\"drawing.modal.map-properties.description\"></label>\n\n                        <div class=\"col-sm-8\">\n                        <textarea id=\"map-description\" class=\"form-control\"\n                                  rows=\"10\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "</textarea>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"text-right\">\n                    <!-- fix issue bootstrap-validator https://github.com/1000hz/bootstrap-validator/issues/104 -->\n                    <button class=\"save-map-button btn btn-primary\" type=\"submit\" data-i18n=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"okButtonLabel") : depth0), depth0))
    + "\"></button>\n                    <button class=\"cancel-button btn btn-primary\" type=\"button\" data-dismiss=\"modal\"\n                            data-i18n=\"drawing.modal.map-properties.cancel\"></button>\n                </div>\n            </form>\n        </div>\n    </div>\n</div>\n";
},"useData":true});