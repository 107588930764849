const _ = require('underscore');
const $ = require('jquery');

const ShomView = require('../../../core/shom-view.js');
const colorsUtils = require('../../../utils/colors.js');

module.exports = {};

/**
 * # The SHOM Layer View is simalar to
 *   the ShomView, It centralize shared methods on layer views
 *   We modify the parameter override to add our new methods in build function
 */
module.exports.build = overrides => {
  const shared = {
    noWidthValue: 0,
    noColorValue: [0, 0, 0, 0],

    className: 'legend-preview',
    tagName: 'canvas',

    initialize(options) {
      this._feature = options.feature;
      this.gisview = options.gisview || window.GISVIEW;
      this.eventBus = options.eventBus || window.EVENTBUS;
      this.config = options.config || window.CONFIG;
      this.el.width = this.el.height = this.config.cartodyn.legendPreviewSize;
      this.ctx = this.el.getContext('2d');
      this.gisview.carto.command('drawing.onchangestyle')
        .subscribe(this._onFeatureStyleChanged.bind(this));
    },

    clear() {
      this.ctx.clearRect(0, 0, this.el.width, this.el.height);
    },

    /**
         * Render a regular shape for legend
         *
         * @param style the style from which extracting colors and thickness
         * @param npoints the number of points of the shape (Infinity = Circle)
         * @param radius the radius of the shape
         * @param shapeRotation the rotation to apply (considering that the point 0 is the middle right one)
         */
    renderRegularShape(style, npoints, radius, shapeRotation = 0) {
      const _strokeThickness = style.getStroke()
        ? style.getStroke().getWidth()
        : this.noWidthValue;
      const _rgbaStrokeColor = style.getStroke()
        ? colorsUtils.getRgbaColorFromRgba(style.getStroke().getColor())
        : this.noColorValue;
      const _rgbaFillColor = style.getFill()
        ? colorsUtils.getRgbaColorFromRgba(style.getFill().getColor())
        : this.noColorValue;
      const centerX = this.el.width / 2;
      const centerY = this.el.height / 2;

      this.ctx.save();
      this.ctx.strokeStyle = _rgbaStrokeColor;
      this.ctx.lineWidth = _strokeThickness;
      this.ctx.fillStyle = _rgbaFillColor;
      this.ctx.beginPath();

      // Circle case
      if (npoints === Infinity) {
        this.ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);

        // Other shape cases
      } else {
        const firstPoint = this.getRegularShapePoint(centerX, centerY, radius, npoints - 1, npoints, shapeRotation);
        this.ctx.moveTo(firstPoint[0], firstPoint[1]);
        for (let index = 0; index < npoints; index++) {
          const point = this.getRegularShapePoint(centerX, centerY, radius, index, npoints, shapeRotation);
          this.ctx.lineTo(point[0], point[1]);
        }
      }

      this.ctx.fill();
      this.ctx.stroke();
      this.ctx.restore();
    },

    /**
         * Get the coordinates of a point in a regular shape drawing
         *
         * @param centerX center X of the shape
         * @param centerY center Y of the shape
         * @param radius distance between center and points
         * @param npoint current point index (between 0 and npoints - 1)
         * @param npoints number of points of the shape
         * @param shapeRotation rotation of the shape
         * @return {[2]} X and Y values of position for the point
         */
    getRegularShapePoint(centerX, centerY, radius, npoint, npoints, shapeRotation) {
      return [
        centerX + (radius * Math.cos((Math.PI * 2 / npoints * npoint) + shapeRotation)),
        centerY - (radius * Math.sin((Math.PI * 2 / npoints * npoint) + shapeRotation))
      ];
    },

    renderIcon(imageStyle) {
      const customOffset = 6;
      const image = imageStyle.getImage();
      if (!this.isImageLoaded(image)) {
        $(image).one('load', _.bind(this.renderIcon, this, imageStyle));
      }
      let ratio;
      if (image.width > image.height) {
        ratio = this.el.width / image.width;
      } else {
        ratio = this.el.height / image.height;
      }
      image.crossOrigin = 'anonymous';
      const width = Math.round(image.width * ratio + customOffset);
      const height = Math.round(image.height * ratio + customOffset);
      const x = ((this.el.width + customOffset) / 2) - (width / 2) - (customOffset / 2);
      const y = ((this.el.height + customOffset) / 2) - (height / 2) - (customOffset / 2);
      this.ctx.drawImage(image, 0, 0, image.width, image.height, x, y, width, height);
    },

    _onFeatureStyleChanged(feature) {
      if (feature && feature === this._feature) {
        this.render();
      }
    },

    isImageLoaded(img) {
      // During the onload event, IE correctly identifies any images that
      // weren’t downloaded as not complete. Others should too. Gecko-based
      // browsers act like NS4 in that they report this incorrectly.
      if (!img.complete) {
        return false;
      }

      // However, they do have two very useful properties: naturalWidth and
      // naturalHeight. These give the true size of the image. If it failed
      // to load, either of these should be zero.
      if (typeof img.naturalWidth !== 'undefined' && img.naturalWidth === 0) {
        return false;
      }

      // No other way of checking: assume it’s ok.
      return true;
    }
  };

  overrides = { ...shared, ...overrides };
  return ShomView.build(overrides);
};
