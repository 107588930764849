import AtlasHelper from '../../utils/atlas/atlas-utils';

const $ = require('jquery');
const _ = require('underscore');
const d3 = require('d3');
const ShomView = require('../../core/shom-view');
const template = require('../../../template/atlas/rose-courant-items.hbs');
const RoseCourantView = require('./rose-courant-item.view');
const ToastrUtil = require('../../utils/toastr');

module.exports = ShomView.build({

  events: {
    'change #rose-courant-select-coeff': 'getRoseCourantItems'
  },

  initialize(options = {}) {
    this._config = options.config || window.CONFIG;
    this._gfiModeManager = options.gfiModeManager || window.POI_MODE_MANAGER;
    this._collection = options.collection || new Backbone.Collection([]);
    this._data = options.data;
    this._selectedCoeff = this._data.catalogLayer.get('currentCoeff') || 20;
    this._coeffRange = AtlasHelper.generateRange(20, 120);

    // Ensure our methods keep the `this` reference to the view itself
    _.bindAll(this, 'render');

    // Bind collection changes to re-rendering
    this._collection.bind('reset', this.render);
    this._collection.bind('add', this.render);
    this._collection.bind('remove', this.render);
    this._collection.bind('shift', this.render);
  },

  render() {
    this.$el.empty();
    this.$el.html(template({
      thDatetime: $.i18n.t('oceano.modal.rose.table.th.datetime'),
      thDir: $.i18n.t('oceano.modal.rose.table.th.dir'),
      thMag: $.i18n.t('oceano.modal.rose.table.th.mag'),
      availableCoeffs: this._coeffRange,
      selectedCoeff: this._selectedCoeff
    }));

    // update selected option
    this.$(`#rose-courant-select-coeff option[value="${this._selectedCoeff}"]`).prop('selected', true);

    const $tableBody = this.$('#table-body-rose-courant');
    this._collection.each(model => {
      const roseCourantView = new RoseCourantView({ router: this._router, config: this._config, model });
      $tableBody.append(roseCourantView.render().el);
    });
    this._displayRoseCourantGraph();
    return this;
  },

  _displayRoseCourantGraph() {
    // set the dimensions and margins of the graph
    const margin = {
      top: 15, right: 30, bottom: 10, left: 30
    };
    const width = 460 - margin.left - margin.right;
    const height = 380 - margin.top - margin.bottom;

    const svgContainer = d3.select(this.el).select('#rose-courant-graph');

    const newCollection = this._collection.byHourOnly();
    newCollection.each(model => {
      const [xValue, yValue] = AtlasHelper.computeRoseCourantCoordFromMagDir(model.get('realValueMag'), model.get('realValueDir'));
      model.set('x', xValue);
      model.set('y', yValue);
    });

    const dataset = newCollection.models.map(item => ({
      datetime: item.get('datetime').replace(':00', '').replace('0', ''),
      dir: item.get('dir'),
      mag: item.get('mag'),
      x: item.get('x'),
      y: item.get('y')
    }));

    // set domain values to have a nice ratio, and keep axis centered
    const xValues = _.pluck(dataset, 'x');
    const yValues = _.pluck(dataset, 'y');
    const bound = Math.max(Math.abs(d3.min(xValues)), Math.abs(d3.max(xValues)), Math.abs(d3.min(yValues)), Math.abs(d3.max(yValues)));

    const x = d3.scale.linear()
      .domain([-bound, bound])
      .range([0, width])
      .nice();

    const y = d3.scale.linear()
      .domain([-bound, bound])
      .range([height, 0])
      .nice();

    const xAxis = d3.svg.axis().scale(x).orient('bottom').tickFormat('');

    const yAxis = d3.svg.axis().scale(y).orient('left').tickFormat('');

    // Add the line
    const line = d3.svg.line()
    // .interpolate('basis-closed')
      .interpolate('cardinal-closed')
      .x(d => x(d.x))
      .y(d => y(d.y));

    // append the svg object to the body of the page
    const svg = svgContainer.append('svg')
      .attr('id', 'svg-rose-courant')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom);

    const chartGroup = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // append path between points
    chartGroup.append('path')
      .attr('class', 'rose-courant-path')
      .attr('d', line(dataset));

    // append x axis
    chartGroup.append('g')
      .attr('class', 'rose-courant-axis')
      .attr('transform', `translate(0,${(height / 2)})`)
      .call(xAxis);

    chartGroup.append('g')
      .attr('class', 'rose-courant-axis')
      .attr('transform', `translate(${(width / 2)},0)`)
      .call(yAxis);

    // Now add labels to each axis
    const xAxisLabelPositionFromTop = (height / 2) + 10;
    const yAxisLabelPositionFromLeft = (width / 2) + 40;
    const xAxisLabelWestFromLeft = 30;
    const xAxisLabelEstFromLeft = width + xAxisLabelWestFromLeft;
    const yAxisLabelNorthFromTop = 20;
    const yAxisLabelSouthFromTop = height + yAxisLabelNorthFromTop;

    svg.append('text')
      .attr('text-anchor', 'middle') // This makes it easy to center the text as the transform is applied to the anchor.
      .attr('transform', `translate(${xAxisLabelWestFromLeft},${xAxisLabelPositionFromTop})`)
      .text('W');

    svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('transform', `translate(${xAxisLabelEstFromLeft},${xAxisLabelPositionFromTop})`)
      .text('E');

    svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('transform', `translate(${yAxisLabelPositionFromLeft},${yAxisLabelNorthFromTop})`)
      .text('N');

    svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('transform', `translate(${yAxisLabelPositionFromLeft},${yAxisLabelSouthFromTop})`)
      .text('S');

    // create a tooltip
    const tooltip = svgContainer
      .append('div')
      .style('opacity', 0)
      .attr('class', 'tooltip')
      .style('background-color', 'white')
      .style('border', 'solid')
      .style('border-width', '1px')
      .style('border-radius', '5px')
      .style('padding', '5px');

    // Three function that change the tooltip when user hover / move / leave a point
    // note that we cannot use arrow function because it fails when used
    const mouseover = function (d) {
      tooltip.style('opacity', 1);
    };

    const mousemove = function (d) {
      const pos = d3.mouse(this);
      tooltip.html(`Dir: ${d.dir}, Mag: ${d.mag}`)
        .style('position', 'absolute')
        .style('left', `${pos[0] + 70}px`)
        .style('top', `${pos[1]}px`);
    };

    const mouseleave = function (d) {
      tooltip.style('opacity', 0);
    };

    // Add the points
    const elemEnter = chartGroup
      .append('g')
      .selectAll('dot')
      .data(dataset)
      .enter()
      .append('g');

    elemEnter.append('circle')
      .attr('cx', d => x(d.x))
      .attr('cy', d => y(d.y))
      .attr('r', 5)
      .attr('class', 'rose-courant-point')
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseleave', mouseleave);

    elemEnter.append('text') // append text
      .attr('font-size', 12)
      .style('fill', 'black') // fill the text with the colour black
      .attr('dx', -14) // set offset y position
      .attr('dy', 5) // set offset y position
      .attr('text-anchor', 'middle') // set anchor y justification
      .attr('x', d => x(d.x))
      .attr('y', d => y(d.y))
      .text(d => `${d.datetime}`); // define the text to display
  },

  getRoseCourantItems() {
    // get selected option and store new coeff
    this._selectedCoeff = this.$('#rose-courant-select-coeff').children('option:selected').val();

    // fetch items
    this._gfiModeManager.getNcwmsMagnitudeDirection(this._data, this._selectedCoeff)
      .then(values => this._collection.reset(AtlasHelper.transformAtlasValuesForDisplay(values, this._data.catalogLayer).models))
      .fail(e => {
        console.error(e);
        ToastrUtil.error($.i18n.t('oceano.toaster.gfiUnreachable'));
      });
  }
});
