const ShomView = require('../../core/shom-view');

module.exports = ShomView.build({

  tagName: 'option',

  render() {
    this.$el.html(this.model.get('title'));
    this.$el.attr('title', this.model.get('abstract'));
    this.$el.attr('value', this.model.cid);
    return this;
  }
});
