import LayerModelUtils from '../../utils/layer-model-utils';

import AtlasHelper from '../../utils/atlas/atlas-utils';

const _ = require('underscore');
const $ = require('jquery');

const ShomView = require('../../core/shom-view');

const NCWMS = require('../../service/ncwms');

const Loading = require('../../utils/loading.js');
const OceanoHelper = require('../../utils/gis/oceano-helper.js');
const OceanoLayerUtils = require('../../utils/oceano/oceano-layer');
const OceanoNcwmsUtils = require('../../utils/oceano/oceano-ncwms.js');

const InfoPopover = require('../info-popover.view');
const OceanoTransectAnimationView = require('./ocea-transect-animation.view');
const OceanoTransectAtlasAnimationView = require('./ocea-transect-atlas-animation.view');

const template = require('../../../template/oceano/ocea-transect-result-modal.hbs');

module.exports = ShomView.build({

  className: 'hitable modal-dialog',

  events: {
    'click .slider-refresh-transect-image': '_transectFilterDepth',
    'click .nav a': '_openTab'
  },

  /** ***************************** */
  /** ****** INIT FUNCTIONS ******* */
  /** ***************************** */

  initialize(options) {
    const optionsToUse = options || {};
    this._title = optionsToUse.title;
    this._title_option = optionsToUse.title_option || '';
    this._layer = optionsToUse.layer;
    this._config = optionsToUse.config || window.CONFIG;
    this._gisView = window.GISVIEW;
    this._linestring = optionsToUse.linestring;

    this._is3DLayer = OceanoHelper.is3DNcwmsLayer(this._layer);
    if (this._is3DLayer) {
      this._init3Dcomponents();
    }
  },

  _init3Dcomponents() {
    const elevations = this._layer.get('dimensions').elevation.map(elevation => +elevation || 0);
    elevations.sort((a, b) => a - b);
    this._minDepth = elevations[0];
    this._maxDepth = elevations[elevations.length - 1];
    if (this._minDepth === this._maxDepth) {
      this._is3DLayer = false;
    }
  },

  /** ***************************** */
  /** ***** RENDER FUNCTIONS ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({
      is3D: this._is3DLayer,
      transectDate: this._getLayerCurrentDate()
    }));

    this._$transectResultDiv = this.$el.find('#transect-animation .transect-result');
    this._$transectResultTitle = this.$el.find('#ocea-result-title');
    this._$transectResultTitleOptions = this.$el.find('#ocea-result-title-option');
    this._$transectResultModalContent = this.$el.find('.modal-content');
    this._$doubleSlider = this.$el.find('.double-slider');
    this._$doubleSliderTopHandle = this.$el.find('.double-slider-upper-level');
    this._$doubleSliderBotHandle = this.$el.find('.double-slider-lower-level');
    this._$doubleSliderContainer = this.$el.find('.transect-slider-container');
    this._$helpDepthSpan = this.$el.find('.help-depth');
    this._$modalContent = this.$el.find('.modal-body');
    this._$imageContainer = this.$el.find('img#transect-static-img');

    this._$transectResultModalContent.draggable({
      handle: '.modal-header'
    });
    this._$transectResultTitle.attr('data-i18n', this._title);
    this._$transectResultTitleOptions.html(this._title_option);

    if (this._error && this._error !== '') {
      this._$transectResultDiv.attr('data-i18n', this._error);
      return this;
    }

    this._updateStaticGraph(true);
    if (this._is3DLayer) {
      this._renderSliders();
      this._renderInfoPopover();
    }
    this._renderAnimationView();

    if (this._is3DLayer) {
      this._transectFilterDepth();
    } else {
      this._getTransect();
    }
    return this;
  },

  _renderSliders() {
    this._$doubleSlider.slider({
      orientation: 'vertical',
      range: true,
      min: -this._maxDepth,
      max: -this._minDepth,
      values: [-this._maxDepth, -this._minDepth],
      create: _.bind(this._updateSliders, this, -this._minDepth, -this._maxDepth),
      slide: _.bind(this._onSlidersSlideCallBack, this)
    });
  },

  _renderInfoPopover() {
    const depthInfoPopover = new InfoPopover({
      message: 'oceano.popover.transect.depth',
      placement: 'top',
      classname: 'info-popover-button transect-help-depth'
    });

    this._$helpDepthSpan.html(depthInfoPopover.render().$el);
  },

  _renderAnimationView() {
    const depthBounds = this._is3DLayer ? this._$doubleSlider.slider('values').map(el => -el).reverse() : undefined;
    const params = {
      model: this.model,
      layer: this._layer,
      linestring: this._linestring,
      depthBounds
    };
    if (AtlasHelper.isAtlasLayer(this.model)) {
      this._animationView = new OceanoTransectAtlasAnimationView(params);
    } else {
      this._animationView = new OceanoTransectAnimationView(params);
    }
    this._$transectResultDiv.empty();
    this._$transectResultDiv.html(this._animationView.render().$el);
  },

  /** ***************************** */
  /** ***** EVENT FUNCTIONS ******* */
  /** ***************************** */

  _onSlidersSlideCallBack(event, ui) {
    // Prevents the two sliders bounds from getting the same value because ncWMS2 can't handle it and
    // throws a 500 HTTP exception
    if (ui.values[1] === ui.values[0]) {
      return false;
    }
    this._updateSliders(ui.values[1], ui.values[0]);
    return true;
  },

  _openTab(ev) {
    ev.preventDefault();
    $(ev.currentTarget).tab('show');
  },

  /** ***************************** */
  /** **** REQUEST FUNCTIONS ****** */
  /** ***************************** */

  _getTransect(depthBounds) {
    const ncwmsServiceOptions = { ncwmsLayerType: LayerModelUtils.getNcwmsLayerType(this.model) };
    NCWMS(ncwmsServiceOptions)
      .then(_.bind(service => {
        const selectedUnitName = OceanoLayerUtils.getSelectedUnitFromLayer(this.model).name;
        const unitConfig = this._config.oceano.units[selectedUnitName];
        return service.getTransectUrl(this._linestring, this._layer, {
          sectionElevation: depthBounds,
          unit: (unitConfig) ? unitConfig.ncWMS : null,
          layerModel: this.model
        });
      }, this))
      .then(_.bind(function (url) {
        this._img = url;
        this._updateStaticGraph();
      }, this));
  },

  /** ***************************** */
  /** ***** UTILS FUNCTIONS ******* */
  /** ***************************** */

  _getLayerCurrentDate() {
    const oceaLayerUTC = +this._gisView.getGlobalOceanoCurrentUTC() || 0;
    const oceaLayerMoment = this._gisView.getOceanoLayerCurrentMoment(this.model);
    const transectDate = OceanoNcwmsUtils.getModalFormattedDate(oceaLayerMoment, oceaLayerUTC);
    return transectDate;
  },

  _updateSliders(valMin, valMax) {
    const bottomPos = Math.abs((+valMin / (this._maxDepth - this._minDepth)) * 100);
    const topPos = Math.abs((+valMax / (this._maxDepth - this._minDepth)) * 100);
    this._$doubleSliderTopHandle.css('top', `${topPos}%`).html(-valMax);
    this._$doubleSliderBotHandle.css('bottom', `${100 - bottomPos}%`).html(-valMin);
    this._animationView && this._animationView.setDepthBounds([-valMin, -valMax]);
  },

  _transectFilterDepth() {
    const depthBounds = this._$doubleSlider.slider('values').map(el => -el).reverse();
    this._getTransect(depthBounds);
  },

  _updateStaticGraph(skipLoading) {
    if (!skipLoading) {
      Loading.start(this._$modalContent);
    }
    this._$doubleSliderContainer.hide();
    this._$imageContainer.hide()
      .prop('src', this._img)
      .one('load', () => {
        if (!skipLoading) {
          Loading.stop(this._$modalContent);
        }
        this._$imageContainer.fadeTo('slow', 1.0);
        this._$doubleSliderContainer.fadeIn();
      });
  }
});
