const Backbone = require('backbone');
const $ = require('jquery');
const _ = require('underscore');

const CartoDynMap = module.exports = Backbone.Model.extend({
  idAttribute: '_id',

  defaults: {
    sessionId: null,
    ownerKey: null,
    title: null,
    description: null,
    name: null,
    _id: null,
    graticuleOption: false,
    getContext: null,
    putContext: null,
    getCroquis: null,
    putCroquis: null,
    getLegends: null,
    putLegends: null
  },

  initialize(options = {}) {
    this._config = window.CONFIG;
    this._user = options.user || window.ROUTER.user;
  },

  getContext() {
    return this._doGet('getContext');
  },

  getCroquis() {
    return this._doGet('getCroquis');
  },

  getLegends() {
    return this._doGet('getLegends');
  },

  _doGet(data) {
    const url = this.url + this.get(data);

    const params = {
      method: 'POST',
      url
    };
    return this._user.doReq(params);
  },

  _doPut(urlSuffix, bodyContent) {
    const url = this.url + urlSuffix;

    const params = {
      method: 'POST',
      url,
      data: bodyContent
    };
    return this._user.doReq(params);
  },

  _doDelete(urlSuffix, bodyContent) {
    const url = this.url + urlSuffix;

    const params = {
      method: 'POST',
      url,
      data: bodyContent
    };
    return this._user.doReq(params);
  },

  putContext(context) {
    return this._doPut(this.get('putContext'), context);
  },

  putCroquis(croquis) {
    return this._doPut(this.get('putCroquis'), croquis);
  },

  putLegends(legends) {
    return this._doPut(this.get('putLegends'), legends);
  },

  saveMap(userKey) {
    if (!!this.get('sessionId') && this.get('ownerKey') !== userKey) {
      const deferred = $.Deferred();
      deferred.resolve({});
      return deferred;
    }
    return this._doPut(
      `/${userKey}${this.isNew() ? '/createMap' : '/saveMap'}`,
      JSON.stringify(this.toJSON())
    );
  },

  deleteMap(userKey) {
    return this._doDelete(
      `/${userKey}/${this.id}/deleteMap`,
      JSON.stringify(this.toJSON())
    );
  },

  destroy(options) {
    this.trigger('destroy', this, this.collection, options);
  },

  createSession(user) {
    let targetUrl = this._config.cartodyn.discuss.endpoints.createSessionUrl;
    targetUrl = targetUrl.replace('{{key}}', user.get('key'));
    targetUrl = targetUrl.replace('{{mapId}}', this.id);
    return this._doPut(targetUrl, user.get('username'));
  },

  /**
   * Nullify properties related to discuss session
   */
  cleanMapFromSession() {
    this.set('sessionId', '');
    const propertiesToNullify = [
      'getChat',
      'getUpdates',
      'giveEditorRigths',
      'leaveSession',
      'putChat',
      'sendInvitation'
    ];
    propertiesToNullify.forEach(_.bind(function (prop) {
      this[prop] = null;
    }, this));
  }
});

CartoDynMap.MAP_PROPERTIES = [
  'getContext',
  'putContext',
  'title',
  'description',
  'name',
  'getCroquis',
  'putCroquis',
  'getLegends',
  'putLegends',
  'sessionId',
  'graticuleOption',
  'ownerKey',
  '_id'
];
