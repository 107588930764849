const _ = require('underscore');
const ShomView = require('../../core/shom-view');
const ItemView = require('./maps-list-item.view.js');
const Loading = require('../../utils/loading');
const emptyTemplate = require('../../../template/drawing/empty-maps-list.hbs');

const MapsListView = ShomView.build({

  tagName: 'ul',

  className: 'list-unstyled',

  events: {},

  initialize(options) {
    const optionsToUse = options || {};
    this._session = optionsToUse.session;
    this._itemViews = [];
    this.listenTo(this.collection, 'sync', _.bind(this.render, this));
    this.listenTo(this.collection, 'error', _.bind(this.render, this));
    this._gfiModeManager = optionsToUse.gfiModeManager || window.POI_MODE_MANAGER;
  },

  render() {
    if (this.collection && this.collection.loading) {
      Loading.start(this.$el);
      return this;
    }
    Loading.stop(this.$el);
    this._removeItemViews();
    this._createItemViews();
    if (this._itemViews.length > 0) {
      this._renderItemViews();
    } else {
      this.$el.html(emptyTemplate());
    }

    return this;
  },

  _removeItemViews() {
    _.each(this._itemViews, itemView => {
      itemView.remove();
    });
    this._itemViews = [];
  },

  _createItemViews() {
    this.collection && this.collection.forEach(function (displayedLayer) {
      this._itemViews.push(new ItemView({
        model: displayedLayer,
        session: this._session
      }));
    }, this);

    this._itemViews.sort((a, b) => {
      const aName = a.model.get('name');
      const bName = b.model.get('name');
      return aName.localeCompare(bName, undefined, {numeric: true, sensitivity: 'base'});
    });
  },

  _renderItemViews() {
    _.each(this._itemViews, function (itemView) {
      this.$el.append(itemView.render().$el);
    }, this);
  }
});

module.exports = MapsListView;
