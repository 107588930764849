var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <span data-i18n=\"oceanogramme.buoyTitle\"></span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "oceanogramme.button.buoyBack";
},"5":function(container,depth0,helpers,partials,data) {
    return "oceanogramme.button.back";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"oceanogramme-header-container\">\n    <div class=\"oceanogramme-spot-label\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isBuoy") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":5,"column":15}}})) != null ? stack1 : "")
    + "        <span class=\"oceanogramme-label\"></span>\n    </div>\n\n    <button type=\"button\" class=\"oceanogramme-back-button btn btn-default btn-back btn-header-refmar-oceano btn-back-refmar-oceano\">\n        <span class=\"glyphicon glyphicon-chevron-left\"></span>\n        <span data-i18n=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isBuoy") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":11,"column":25},"end":{"line":11,"column":111}}})) != null ? stack1 : "")
    + "\"></span>\n    </button>\n\n    <button type=\"button\" class=\"oceanogramme-refresh-button btn btn-default btn-refresh btn-header-refmar-oceano\">\n        <span class=\"glyphicon glyphicon-refresh\" aria-hidden=\"true\"></span> <span data-i18n=\"oceanogramme.button.refresh\"></span>\n    </button>\n\n    <a target=\"_blank\" type=\"button\" class=\"oceanogramme-mobile-link btn btn-default btn-mobile btn-header-refmar-oceano\" data-i18n=\"[title]oceanogramme.button.title.mobile\" >\n        <span class=\"glyphicon glyphicon-chevron-left\"></span> <span data-i18n=\"oceanogramme.button.mobile\"></span>\n    </a>\n\n    <div class=\"oceanogramme-clear-float\"></div>\n</div>\n<div class=\"oceanogramme-container\">\n    <iframe class=\"oceanogramme-iframe\" frameborder=\"0\"></iframe>\n</div>\n";
},"useData":true});