var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"connected\"></span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dark-blue float-left top-ribbon-logos-container\">\n    <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"logoLink") || (depth0 != null ? lookupProperty(depth0,"logoLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"logoLink","hash":{},"data":data,"loc":{"start":{"line":2,"column":13},"end":{"line":2,"column":25}}}) : helper)))
    + "\" target=\"_blank\" class=\"top-ribbon-shom-logo\" data-i18n=\"[aria-label]allSites.institutional\"></a>\n    <span class=\"top-ribbon-separator\"></span>\n</div>\n<div class=\"float-left top-ribbon-title-container\">\n    <h1 class=\"top-ribbon-title\"><a data-i18n=\"header.title\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"titleLink") || (depth0 != null ? lookupProperty(depth0,"titleLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"titleLink","hash":{},"data":data,"loc":{"start":{"line":6,"column":67},"end":{"line":6,"column":80}}}) : helper)))
    + "\"></a></h1>\n</div>\n<div class=\"float-right top-ribbon-links-container\">\n    <p class=\"top-ribbon-links-container-p\">\n        <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"helpPage") || (depth0 != null ? lookupProperty(depth0,"helpPage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"helpPage","hash":{},"data":data,"loc":{"start":{"line":10,"column":17},"end":{"line":10,"column":29}}}) : helper)))
    + "\" target=\"_blank\" id=\"help-page\" data-i18n=\"[title]header.links.help;[aria-label]header.links.help\"\n            class=\"right-padded\"><i class=\"fas fa-lg fa-question\"></i></a>&nbsp;\n        <a id=\"drawing-action-print-button\" href=\"#\" role=\"button\" class=\"right-padded btn-print-map\" data-i18n=\"[title]drawing.button.printmap.label;[aria-label]drawing.button.printmap.label\">\n            <i class=\"fas fa-lg fa-print\"></i></a>&nbsp;\n        <a data-i18n=\"[title]header.links.share;[aria-label]header.links.share\" href=\"#\" role=\"button\" class=\"right-padded my-share\" >\n      <i class=\"fas fa-lg fa-share-alt\"></i></a>&nbsp;\n\n        <a data-i18n=\"[title]header.links.login;[aria-label]header.links.login\" href=\"#\" role=\"button\" class=\"right-padded my-account\" >\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loggedIn") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":20,"column":19}}})) != null ? stack1 : "")
    + "            <i class=\"fas fa-lg fa-user\"></i>\n        </a>&nbsp;\n\n        <a class=\"change-lng-fr\" href=\"#\" role=\"button\" data-i18n=\"[title]header.links.flagFr;[aria-label]header.links.flagFr\"><span class=\"img-fr\"></span></a>&nbsp;\n        <a class=\"change-lng-en right-padded\" href=\"#\" role=\"button\" data-i18n=\"[title]header.links.flagEn;[aria-label]header.links.flagEn\"><span class=\"img-en\"></span></a>&nbsp;\n        <span class=\"top-ribbon-separator-links\"></span>\n        <a href=\"#\" role=\"button\" class=\"view-all-sites left-padded right-padded\" data-i18n=\"[title]header.links.ourSites\" >\n            <span class=\"view-all-sites-label\" data-i18n=\"header.links.ourSites\"></span>&nbsp;\n            <span id=\"triangle-bottom-right\"></span>\n        </a>\n    </p>\n    <div class=\"view-all-sites-container dark-blue\">\n        <ul>\n            <li>\n        <a href=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"links") : depth0)) != null ? lookupProperty(stack1,"institute") : stack1), depth0))
    + "\" target='_blank' data-i18n=\"allSites.institutional\"></a>\n            </li>\n            <li>\n                <a href=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"links") : depth0)) != null ? lookupProperty(stack1,"maree") : stack1), depth0))
    + "\" target='_blank' data-i18n=\"allSites.tideGauge\"></a>\n            </li>\n            <li>\n                <a href=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"links") : depth0)) != null ? lookupProperty(stack1,"diffusion") : stack1), depth0))
    + "\" target='_blank' data-i18n=\"allSites.diffusion\"></a>\n            </li>\n            <li>\n                <a href=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"links") : depth0)) != null ? lookupProperty(stack1,"ping") : stack1), depth0))
    + "\" id=\"all-sites-last\" target='_blank' data-i18n=\"[title]allSites.ping\">PING</a>\n            </li>\n        </ul>\n    </div>\n</div>\n";
},"useData":true});