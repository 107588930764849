const $ = require('jquery');

module.exports = {
  start($target, options = {}) {
    const isFirst = $target.is('body');
    const withOverlay = (options.overlay !== false);
    $target.addClass('is-loading');

    $target.data('previous-position', $target.css('position'))
      .append($(`<div class='loading ${isFirst ? 'first-loading' : ''}'>`))
      .append((withOverlay ? $(`<div class='loading-overlay ${isFirst ? 'first-loading' : ''}'>`) : ''));
    if (!options.isAbsolute) {
      $target.css('position', 'relative');
    }
  },

  stop($target) {
    $target.removeClass('is-loading');
    $target
      .css('position', $target.data('previous-position'))
      .data('previous-position', null)
      .children('.loading, .loading-overlay')
      .remove();
  }
};
