const ShomView = require('./shom-view');
const StringUtils = require('../utils/string.js');

module.exports = {};

/**
 * # The SHOM Layer View is simalar to
 *   the ShomView, It centralize shared methods on layer views
 *   We modify the parameter override to add our new methods in build function
 */
module.exports.build = overrides => {
  const sharedMethodsByLayers = {
    setMark(term) {
      this._markTerm = term;
      if (this._markTerm) {
        const layerTitle = this._$layerTitle.text().toLowerCase();
        const layerDescription = this._$layerDescription.text().toLowerCase();
        const currentLayerWords = StringUtils.removeDiacritics(`${layerTitle} ${layerDescription}`);
        const searchWords = StringUtils.removeDiacritics(term).toLowerCase().split(' ');
        if (StringUtils.isWordsIncludedInString(searchWords, currentLayerWords)) {
          this.$el.mark(this._markTerm);
        }
      } else {
        this.$el.unmark();
      }
    },

    postTranslate(lang) {
      this.showHideHelpLinkIfExistOrNot();
      const englishName = this.model.get('englishName');
      if (!englishName) {
        return;
      }
      const translatedTitle = lang === 'fr' ? this.model.get('title') : englishName;
      const abstract = this.model.get('abstract');

      this._$layerTitle
        .empty()
        .append(translatedTitle)
        .append(`<span class="description">${abstract}</span>`);
    },

    showHideHelpLinkIfExistOrNot() {
      const helpLink = this.$('div[class^="layer-help-link"]').first();
      const hasHelpLinks = !!(this.model.get('helpLinks') && this.model.get('helpLinks')[window.portalLang]);
      if (helpLink) {
        if (hasHelpLinks) {
          helpLink.removeClass('hide-help-link');
        } else {
          helpLink.addClass('hide-help-link');
        }
      }
    },

    onHelpLinkClick() {
      if (this.model.get('helpLinks')) {
        window.open((this.model.get('helpLinks')[window.portalLang]), '_blank');
      }
    }
  };

  overrides = { ...sharedMethodsByLayers, ...overrides };
  return ShomView.build(overrides);
};
