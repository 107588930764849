const _ = require('underscore');
const $ = require('jquery');
const ShomView = require('../../core/shom-view');
const template = require('../../../template/drawing/edit-map.hbs');
const ActionToolBarView = require('./action-tool-bar.view.js');
const Loading = require('../../utils/loading.js');
const DiscussView = require('./discuss/discuss.view.js');

const EditMapView = ShomView.build({

  className: 'panel-group',

  id: 'edit-map-accordion',

  events: {
    'click .btn-back': '_onBackButtonClick'
  },

  initialize(options) {
    const optionsToUse = options || {};
    this._router = optionsToUse.router || window.ROUTER;
    this._drawingRouter = optionsToUse.drawingRouter;
    this._eventBus = optionsToUse.eventBus || window.EVENTBUS;
    this._gisView = optionsToUse.gisView || window.GISVIEW;
    this._user = optionsToUse.user || window.ROUTER.user;
    this._maps = optionsToUse.maps;
    this._drawingMenuView = optionsToUse.drawingMenuView;
    this.listenTo(this.model, 'data:fetch', _.bind(this.render, this));
    this.listenTo(this.model, 'change:map', _.bind(this.render, this));
    this.listenTo(this.model, 'data:fetch:done', _.bind(this.render, this));
    this.listenTo(this.model, 'data:fetch:error', _.bind(this.render, this));
    this.listenTo(this._maps, 'sync', _.bind(this._onMapsSync, this));
  },

  extraSetup() {
    this.listenTo(this.model.get('map'), 'change', _.bind(this.render, this));
  },

  render() {
    if (!this.model.get('dataFetched') && this.model.get('fetchingData')) {
      Loading.start(this.$el);
      return this;
    }
    Loading.stop(this.$el);

    const map = this.model.get('map');
    if (!map) {
      return;
    }
    this.$el.html(template({
      model: this.model.toJSON(),
      map: map.toJSON(),
      loggedIn: this._user.get('isLoggedIn'),
      inError: this.model.get('dataFetchError')
    }));
    if (this.model.get('dataFetchError')) {
      return;
    }
    if (!this._actionToolBarView) {
      this._actionToolBarView = new ActionToolBarView({
        model: this.model,
        drawingRouter: this._drawingRouter,
        maps: this._maps
      });
      this.listenTo(this._actionToolBarView, 'cartodyn:saved-as', this.render);
    }
    if (!this._discussView) {
      this._discussView = new DiscussView({ model: this.model });
    }

    $('.legend-checkbox').css('display', 'block');
    this._drawingMenuView.handlerLegend();

    this._actionToolBarView.setElement(this.$el.find('#action-tool-bar-root'));
    this._actionToolBarView.render();
    this._discussView.setElement(this.$('#discuss-root'));
    this._discussView.render();
    return this;
  },

  _onMapsSync() {
    const map = this.model.get('map');
    if (map && map.isNew()) {
      const name = this._drawingRouter.generateNewMapName();
      map.set('name', name);
    }
  }
});

module.exports = EditMapView;
