/// /////// CONTEXT ////////////
export const CONTEXT_ISGUEST = 'isGuest';
export const CONTEXT_CARTOMODE = 'cartoMode';
export const CONTEXT_REFMARLAYERS = 'refmarLayers';
export const CONTEXT_FORECASTLAYERS = 'forecastLayers';

/// /////// CSS ////////////
export const CSS_CLASS_ACTIVE = 'active';
export const CSS_CLASS_BORDER = 'btn-group-vertical-border';

/// /////// CATALOG ////////////
export const CATALOG_REFERENCE = 'reference';
export const CATALOG_OCEANO = 'oceano';
export const CATALOG_COTIER = 'cotier';
export const CATALOG_OBSERVATIONS = 'observations';
export const CATALOG_ATLAS = 'atlas';
export const CATALOG_DEFAULT_ORDER_INDEX = 9999;

/// /////// CATEGORIES ////////////
export const SEPARATOR_CATEGORY_LEVEL01 = '#';
export const SEPARATOR_CATEGORY_LEVEL02 = '##';
export const SEPARATOR_OCEANO_ID = '/';
export const MY_LAYERS_CATEGORY = 'my_layers';

/// /////// LAYERS ////////////
export const LAYERTYPE_REFMAR = 'REFMAR';
export const LAYERTYPE_FORECAST = 'FORECAST';
export const LAYERTYPE_COPERNICUS = 'COPERNICUS';
export const LAYERTYPE_TECHNIC = 'TECHNIC';
export const LAYERTYPE_NCWMS = 'NCWMS';
export const LAYERTYPE_INTERNAL_NCWMS = 'INTERNAL_NCWMS';
export const LAYERTYPE_INTERNAL_LAYER = 'INTERNAL_LAYER';
export const LAYERTYPE_KML_LAYER = 'KML_LAYER';
export const LAYERTYPE_ARCHIVE_NCWMS = 'ARCHIVE_NCWMS';

/// /////// MODAL ////////////
export const DISPLAY_DL_MODAL = 'display_dl_modal';
export const DISPLAY_OCEANOGRAM_MODAL = 'display_oceanogram_modal';

/// /////// CARTO MODULES ////////////
export const SC_MEASUREMENT_MODULE_ACTIVE = 'sc-measurement-module-active';
export const SC_MEASUREMENT_MODULE = 'sc-measurement-module';
export const SC_DRAWING_MODULE = 'sc-drawing-module';

/// /////// NCWMS ////////////
export const NCWMS_LAYERTYPE_OCEANO = 'oceano';
export const NCWMS_LAYERTYPE_ARCHIVE = 'archive';
export const NCWMS_LAYERTYPE_ATLAS = 'atlas';
export const NCWMS_LAYERTYPE_RADARHF = 'radarhf';

/// /////// TIMELINE //////////
export const STEPPING_ATLAS = 15;
export const STEPPING_OCEANO = 10;

/// /////// URL ////////////
export const REFMAR_DATA_URL = 'https://data.shom.fr/donnees/refmar/';

/// /////// DATE ///////////
export const DATE_DL_FORMAT = 'YYYY-MM-DD';

/// /////// GLOBAL ////////////
export const DATA_SHOM_TITLE = 'DATA.SHOM.FR';
