var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"checked") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":15,"column":67}}})) != null ? stack1 : "")
    + " data-i18n=\"ddm.labels.source"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n                  </option>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "selected=\"selected\" ";
},"4":function(container,depth0,helpers,partials,data) {
    return " customize-container";
},"6":function(container,depth0,helpers,partials,data) {
    return " customize-on-invalid-value";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"textual-data\">\n  <div class=\"row\">\n    <div class=\"col-md-4\">\n      <div class=\"panel panel-default\">\n        <div class=\"panel-heading\">\n          <span class=\"info-label\" data-i18n=\"ddm.textual.parameters.title\"></span>\n        </div>\n        <div class=\"panel-body\">\n          <div class=\"row\">\n            <div class=\"col-md-6\">\n              <label for=\"textual-observation-source\" data-i18n=\"ddm.textual.parameters.label\"></label>\n              <select id= \"textual-observation-source\" name=\"checkSource\" class=\"form-control\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"sourcesObservations") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":17,"column":25}}})) != null ? stack1 : "")
    + "              </select>\n            </div>\n            <div class=\"form-group col-md-6\">\n              <label  for=\"textual-observation-length\" data-i18n=\"ddm.textual.data.limit\"></label>\n              <select id= \"textual-observation-length\" name=\"textual-observation-length\" class=\"form-control\">\n                <option value='lastTenObservations' data-i18n=\"ddm.textual.data.lastTen\" selected=\"selected\"></option>\n                <option value='lastHundredObservations' data-i18n=\"ddm.textual.data.lastHundred\"></option>\n                <option value='graph' data-i18n=\"ddm.textual.data.likeGraph\"></option>\n                <option value='customize' data-i18n=\"ddm.textual.data.customize\"></option>\n              </select>\n            </div>\n          </div>\n          <div class=\"row\">\n\n            <div id=\"customize-observation\" class=\"form-group col-md-6 "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCustom") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":71},"end":{"line":32,"column":127}}})) != null ? stack1 : "")
    + "\">\n              <label for=\"textual-pick-observation\" class=\"control-label\" data-i18n=\"ddm.textual.data.customChoiceValues\"></label>\n              <input id=\"textual-pick-observation\" type=\"number\" name=\"observationCustom\"\n                    min=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"observationCustomMin") || (depth0 != null ? lookupProperty(depth0,"observationCustomMin") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"observationCustomMin","hash":{},"data":data,"loc":{"start":{"line":35,"column":25},"end":{"line":35,"column":49}}}) : helper)))
    + "\" max=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"observationCustomMax") || (depth0 != null ? lookupProperty(depth0,"observationCustomMax") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"observationCustomMax","hash":{},"data":data,"loc":{"start":{"line":35,"column":56},"end":{"line":35,"column":80}}}) : helper)))
    + "\"\n                    value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"numbersObservations") || (depth0 != null ? lookupProperty(depth0,"numbersObservations") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"numbersObservations","hash":{},"data":data,"loc":{"start":{"line":36,"column":27},"end":{"line":36,"column":50}}}) : helper)))
    + "\" class=\"form-control\" />\n              <div class=\"alert alert-info "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCustomValueInvalid") : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":43},"end":{"line":37,"column":118}}})) != null ? stack1 : "")
    + "\">\n                <label data-i18n=\"ddm.textual.data.customMessageValidation\"></label>\n                <label>[ "
    + alias4(((helper = (helper = lookupProperty(helpers,"observationCustomMin") || (depth0 != null ? lookupProperty(depth0,"observationCustomMin") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"observationCustomMin","hash":{},"data":data,"loc":{"start":{"line":39,"column":25},"end":{"line":39,"column":49}}}) : helper)))
    + " , "
    + alias4(((helper = (helper = lookupProperty(helpers,"observationCustomMax") || (depth0 != null ? lookupProperty(depth0,"observationCustomMax") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"observationCustomMax","hash":{},"data":data,"loc":{"start":{"line":39,"column":52},"end":{"line":39,"column":76}}}) : helper)))
    + " ]</label>\n              </div>\n            </div>\n            <div id=\"startdate-container\" class=\"form-group col-md-6 "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"observationCustom") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":69},"end":{"line":42,"column":134}}})) != null ? stack1 : "")
    + "\">\n              <label for=\"start-date\" class=\"control-label\" data-i18n=\"ddm.labels.startDate\"></label>\n              <input name=\"start-date\" id=\"start-date\" class=\"form-control\"/>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"col-md-8\">\n      <div class=\"panel panel-default\">\n        <div class=\"panel-heading\">\n          <span class=\"info-label\" data-i18n=\"ddm.textual.data.title\"></span>\n        </div>\n        <div class=\"panel-body table-observation-scroll\">\n          <div id=\"data-textual-items\"></div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});