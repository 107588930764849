const $ = require('jquery');
const ShomView = require('../../core/shom-view');
const template = require('../../../template/user-menu/change-pass.hbs');
const FormValidationData = require('../../data/forms/reset-pass-form');
const ToastrUtil = require('../../utils/toastr.js');
const Loading = require('../../utils/loading');

const ForgotPassView = ShomView.build({

  className: 'left-menu-content',

  events: {
    'click #close-change-pass': '_onCrossClick'
  },

  initialize(options = {}) {
    this._router = options.router || window.ROUTER;
    this._config = options.config || window.CONFIG;
    this.eventsBus = options.eventsBus || window.EVENTBUS;
    this._token = options.token;
    this._userKey = options.key;
    this._hasToken = this._token !== null && typeof this._token !== 'undefined';
  },

  render() {
    this.$el.html(template({
      hasNotToken: !this._hasToken
    }));
    this.$form = this.$('form.user-change-pass');
    this.$form.bootstrapValidator({
      submitButtons: 'button.btn-change-pass',
      fields: FormValidationData()
    }).on('success.form.bv', this._onAskChangePass.bind(this));

    return this;
  },

  _onCrossClick() {
    this.eventsBus.trigger('user:openmenu');
  },

  _onAskChangePass(event) {
    (event.preventDefault) ? event.preventDefault() : event.returnValue = false;
    const newPass = this.$('input[name=user-new-password]').val();

    let dataToSend;
    let targetUrl;
    if (this._hasToken) {
      targetUrl = this._config.changePassWithTokenUrl.replace('{{key}}', this._userKey);
      dataToSend = {
        token: this._token,
        newPassword: newPass
      };
    } else {
      const oldPass = this.$('input[name=user-old-password]').val();
      const { user } = this._router;
      targetUrl = this._config.changePassUrl.replace('{{key}}', user.get('key'));
      dataToSend = {
        oldPassword: oldPass,
        newPassword: newPass
      };
    }

    const req = $.ajax({
      type: 'PUT',
      url: targetUrl,
      data: JSON.stringify(dataToSend),
      contentType: 'application/json'
    });

    Loading.start(this.$form);

    req.done(() => {
      Loading.stop(this.$form);
      this.eventsBus.trigger('user:openmenu');
    });

    req.fail($xhr => {
      const data = $xhr.responseJSON;
      let errorMessage = 'user.forgotPass.errorAskNewPass';
      if (data && data.message === 'Wrong password') {
        errorMessage = 'user.errors.badOldPassword';
      }
      ToastrUtil.error($.i18n.t(errorMessage), 8000);
      Loading.stop(this.$form);
    });
  }

});

module.exports = ForgotPassView;
