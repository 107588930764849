import {
  CATALOG_DEFAULT_ORDER_INDEX, SEPARATOR_OCEANO_ID,
  SEPARATOR_CATEGORY_LEVEL01, CATALOG_OBSERVATIONS, CATALOG_ATLAS, CATALOG_OCEANO, CATALOG_COTIER
} from '../constants';

import AtlasHelper from '../atlas/atlas-utils';

const _ = require('underscore');
const $ = require('jquery');
const OceanoLayerUtils = require('./oceano-layer.js');
const CategoryUtil = require('../category');

const OceanoJsonUtils = function () {

};

OceanoJsonUtils.prototype.getTimeseriesNameFromDeliveryJSON = function (layer, model, deliveryJSON) {
  const nameModel = deliveryJSON.models[model];
  if (!nameModel) {
    return null;
  }

  const layerTitle = layer.get('title');
  const nameParam = nameModel.vars[layerTitle];
  return nameParam.timeseriesName;
};

OceanoJsonUtils.prototype.getOptionsFromDeliveryJSON = function (layer, model, deliveryJSON) {
  const optionsModel = deliveryJSON.models[model];
  if (!optionsModel) {
    return null;
  }

  const layerTitle = layer.get('title');
  const optionsParam = optionsModel.vars[layerTitle];

  if (!optionsParam) {
    return null;
  }

  return optionsParam.options;
};

OceanoJsonUtils.prototype.getLayerIndexFromCatalogJSON = function (layer, catalogJSON) {
  if (!catalogJSON || !catalogJSON.layers) {
    return CATALOG_DEFAULT_ORDER_INDEX;
  }
  const layerDeliveryIdentifier = AtlasHelper.isAtlasLayer(layer) ? layer.get('identifier') : layer.get('deliveryIdentifier');
  const layerConfig = catalogJSON.layers.find(layerconf => layerconf.id === layerDeliveryIdentifier);
  if (layerConfig && layerConfig.index) {
    return layerConfig.index;
  }
  return CATALOG_DEFAULT_ORDER_INDEX;
};

OceanoJsonUtils.prototype.getLegendUrlFromDeliveryJSON = function (layer, model, deliveryJSON) {
  const jsonModel = deliveryJSON.models[model];
  if (!jsonModel) {
    return null;
  }
  const layerTitle = layer.get('title');
  const optionsParam = jsonModel.vars[layerTitle];
  if (!optionsParam) {
    return null;
  }
  const legendUrl = optionsParam.legendUrl || null;
  if (legendUrl === null) {
    return null;
  }
  return window.CONFIG.oceano.legendUrl + legendUrl;
};

OceanoJsonUtils.prototype.getCopernicusLinkFromDeliveryJSON = function (layer, model, deliveryJSON) {
  const jsonModel = deliveryJSON.models[model];
  if (!jsonModel) {
    return null;
  }
  const optionsParam = jsonModel.vars[layer.get('title')];
  if (!optionsParam) {
    return null;
  }

  return (optionsParam.copernicusLink || null);
};

OceanoJsonUtils.prototype.getCategoryInfosFromDeliveryJSON = function (model, deliveryJSON) {
  const globalCatalog = window.CATALOG;

  const jsonModel = deliveryJSON.models[model];
  if (!jsonModel) {
    return null;
  }

  const categoryFullpath = CategoryUtil.computeKey(jsonModel.catalog);
  if (!categoryFullpath) {
    return null;
  }

  const categoryIndex = jsonModel.category_index;
  if (!categoryIndex) {
    return null;
  }

  const layerCategory = {
    fullpath: categoryFullpath,
    index: categoryIndex,
    name: _.last(categoryFullpath.split(SEPARATOR_CATEGORY_LEVEL01))
  };

  if (globalCatalog && globalCatalog.categories) {
    const layerCategoryOnCatalog = CategoryUtil.findCategoryForLayer(globalCatalog.categories, categoryFullpath);
    if (layerCategoryOnCatalog) {
      layerCategory.translate = layerCategoryOnCatalog.name;
    }
  }

  return layerCategory;
};

OceanoJsonUtils.prototype.getPortRefAndIsBmFromDeliveryJSON = function (datasetId, model, deliveryJSON) {
  const numAtlas = AtlasHelper.getAtlasNum(datasetId);
  const currentModel = deliveryJSON.models[model];
  if (!currentModel) {
    return null;
  }
  const currentAtlas = currentModel.atlas[numAtlas];
  if (!currentAtlas) {
    return null;
  }
  return { portRef: currentAtlas.Portref, isBm: currentAtlas.isBM };
};

OceanoJsonUtils.prototype.getPaletteFromDeliveryJSON = function (layer, model, deliveryJSON) {
  const optionsModel = deliveryJSON.models[model];
  if (!optionsModel) {
    return null;
  }

  return optionsModel.vars[layer.get('title')];
};

OceanoJsonUtils.prototype.getZoomFromDeliveryJSON = function (datasetId, model, deliveryJSON) {
  const zooms = deliveryJSON.grids[model];
  if (zooms && zooms[datasetId]) {
    return {
      min: zooms[datasetId][0],
      max: zooms[datasetId][1]
    };
  }

  return null;
};

OceanoJsonUtils.prototype.getZoomFromDeliveryAtlasJSON = function (datasetId, model, deliveryJSON) {
  const numAtlas = AtlasHelper.getAtlasNum(datasetId);
  const zooms = deliveryJSON.models[model].atlas[numAtlas].grids[datasetId];
  if (!zooms) { return null; }
  return {
    min: zooms[0],
    max: zooms[1]
  };
};

OceanoJsonUtils.prototype.getTranslationPalettesJSON = function (jsonPals, translations, langs) {
  const translatePals = {};
  for (const i in jsonPals) {
    // eslint-disable-next-line no-prototype-builtins
    if (jsonPals.hasOwnProperty(i)) {
      const pal = jsonPals[i];
      let key = pal.keyName;
      const palName = i;
      translatePals[palName] = {};
      // original key name
      if (key) {
        translatePals[palName].origin = this.getTranslationFromKey(translations, key, langs);
      }
      // automatic key name
      if (pal.auto && pal.auto.keyName) {
        key = pal.auto.keyName;
        translatePals[palName].auto = this.getTranslationFromKey(translations, key, langs);
      }
    }
  }

  return translatePals;
};

OceanoJsonUtils.prototype.getTranslationUnitsJSON = function (jsonUnits, translations, langs) {
  const translateUnits = {};
  for (const i in jsonUnits) {
    if (jsonUnits.hasOwnProperty(i)) {
      const unit = jsonUnits[i];
      const key = unit.name;
      translateUnits[key] = this.getTranslationFromKey(translations, key, langs);
    }
  }
  return translateUnits;
};

OceanoJsonUtils.prototype.getTranslationFromDeliveryJSON = function (layer, model, deliveryJSON, langs) {
  const title = layer.get('title');
  const modelJson = deliveryJSON.models[model];
  if (!modelJson) {
    return null;
  }
  const translations = modelJson.translate;
  if (!translations) {
    return null;
  }
  const translate = {};
  const optionsParam = modelJson.vars[title];
  translate[model] = this.getTranslationFromKey(translations, model, langs);
  translate[title] = this.getTranslationFromKey(translations, title, langs);

  if (layer.get('isArchive')) {
    for (const lang of Object.keys(translate[title])) {
      translate[title][lang] = `${$.i18n.t('oceano.nav.title.archive')} - ${translate[title][lang]}`;
    }
  }

  if (!optionsParam) {
    return translate;
  }
  const { pals } = optionsParam;
  translate.pals = this.getTranslationPalettesJSON(pals, translations, langs);
  translate.units = this.getTranslationUnitsJSON(optionsParam.units, translations, langs);
  return translate;
};

OceanoJsonUtils.prototype.getTranslationFromKey = function (translations, key, langs) {
  if (Array.isArray(langs)) {
    const computedTranslations = {};
    langs.forEach(lang => {
      computedTranslations[lang] = translations[key] && translations[key][lang] || key;
    });
    return computedTranslations;
  }
  return (translations[key] && translations[key][langs])
    ? translations[key][langs] : key;
};

OceanoJsonUtils.prototype.getUnitFromDeliveryJSON = function (layer, modelName, deliveryJSON) {
  const title = layer.get('title');
  const model = deliveryJSON.models[modelName];
  if (model && model.vars) {
    const unit = model.vars[title];
    if (unit) {
      return unit.units;
    }
    return null;
  }
  return null;
};

OceanoJsonUtils.prototype.getConversionFromDeliveryJSON = function (layer, modelName, deliveryJSON) {
  const title = layer.get('title');
  const model = deliveryJSON.models[modelName];
  if (model && model.vars) {
    const conversion = model.vars[title];
    if (conversion) {
      return conversion.conversion;
    }
  }
};

OceanoJsonUtils.prototype.getOriginatorsFromDeliveryJSON = function (layer, modelName, deliveryJSON) {
  const model = deliveryJSON.models[modelName];
  if (model && model.originators) {
    return model.originators;
  }

  return null;
};

OceanoJsonUtils.prototype.getBlackListLayers = function (layers, deliveryJSON) {
  const blacklistLayers = [];
  _.each(layers, layer => {
    const layerId = layer.get('identifier');
    const title = layer.get('title');
    const { thematic } = OceanoLayerUtils.getIdentifiersFromLayerId(layerId);
    if (!deliveryJSON.models[thematic]) {
      return;
    }
    const { variablesBlacklist } = deliveryJSON.models[thematic];
    if (variablesBlacklist && _.contains(variablesBlacklist, title)) {
      blacklistLayers.push(layer);
    }
  });

  return blacklistLayers;
};

OceanoJsonUtils.prototype.setParamsFromDeliveriesJSON = function (collection, deliveriesJSON, isAtlas = false) {
  _.each(deliveriesJSON, _.bind(function (deliveryJSON) {
    this.removeBlacklistParams(collection, deliveryJSON);
    const layersNoFoundInDelivery = this.setParamsFromDeliveryJSON(collection, deliveryJSON, isAtlas);
    collection.remove(layersNoFoundInDelivery);
  }, this));
};

OceanoJsonUtils.prototype.setParamsFromDeliveryJSON = function (collection, deliveryJSON, isAtlas) {
  const layersNoFoundInDelivery = [];
  const catalogJSON = window.CATALOG;
  collection.forEach(_.bind(function (layer) {
    const layerId = layer.get('identifier');
    const layerIdPart = layer.get('identifier').split('/');
    const NCWMSId = OceanoLayerUtils.getIdentifiersFromLayerId(layerId);

    // check layer
    if (!deliveryJSON.models[NCWMSId.thematic]) {
      // not the right json
      return;
    }

    if (!deliveryJSON.models[NCWMSId.thematic].vars[layer.get('title')]) { // Warning: case-sensitive !
      layersNoFoundInDelivery.push(layer);
      return;
    }
    OceanoLayerUtils.setIrregularGrids(layer, deliveryJSON.models[NCWMSId.thematic].irregular_grids || []);

    // set catalog and category
    if (isAtlas) {
      OceanoLayerUtils.setCatalog(layer, CATALOG_ATLAS);
      const category = this.getCategoryInfosFromDeliveryJSON(NCWMSId.thematic, deliveryJSON);
      if (category) {
        OceanoLayerUtils.setCategory(layer, category);
      }
    } else {
      // set catalog
      let { catalog } = deliveryJSON.models[NCWMSId.thematic];
      if (!catalog) {
        catalog = CATALOG_OCEANO;
      }
      if (catalog === CATALOG_COTIER) {
        // uniformisation
        catalog = CATALOG_OBSERVATIONS;
      }
      OceanoLayerUtils.setCatalog(layer, catalog);

      // set category
      let nameCat = NCWMSId.thematic;
      const fullpathCat = catalog + SEPARATOR_CATEGORY_LEVEL01;
      const deliveryCat = deliveryJSON.models[NCWMSId.thematic].vars[layer.get('title')].category;

      if (deliveryCat && !layer.get('isArchive')) {
        nameCat = deliveryCat.toUpperCase();
        if (layerIdPart[0] !== nameCat) {
          layer.set('identifier', nameCat + SEPARATOR_OCEANO_ID + layerIdPart[1]);
          layer.set('deliveryIdentifier', NCWMSId.thematic + SEPARATOR_OCEANO_ID + layerIdPart[1]);
        }
      }

      const category = {
        name: nameCat,
        fullpath: fullpathCat.concat(nameCat)
      };
      OceanoLayerUtils.setCategory(layer, category);
    }

    const { downloadBlacklist } = deliveryJSON.models[NCWMSId.thematic];

    if (downloadBlacklist) {
      OceanoLayerUtils.setDownloadBlacklist(layer, downloadBlacklist);
    }

    const tsName = this.getTimeseriesNameFromDeliveryJSON(layer, NCWMSId.thematic, deliveryJSON);
    if (tsName) {
      OceanoLayerUtils.setTimeseriesName(layer, tsName);
    }

    const palette = this.getPaletteFromDeliveryJSON(
      layer,
      NCWMSId.thematic,
      deliveryJSON
    );
    palette && OceanoLayerUtils.setNCWMSPalette(layer, palette);

    const translation = this.getTranslationFromDeliveryJSON(layer, NCWMSId.thematic, deliveryJSON, ['fr', 'en']);
    if (translation) {
      OceanoLayerUtils.setTranslation(layer, translation);
    }

    const unit = this.getUnitFromDeliveryJSON(layer, NCWMSId.thematic, deliveryJSON);
    if (unit) {
      OceanoLayerUtils.setUnit(layer, unit);
    }

    const conversion = this.getConversionFromDeliveryJSON(layer, NCWMSId.thematic, deliveryJSON);
    if (conversion) {
      OceanoLayerUtils.setConversion(layer, conversion);
    }

    const originators = this.getOriginatorsFromDeliveryJSON(layer, NCWMSId.thematic, deliveryJSON);
    if (originators) {
      const { image_directory } = window.CONFIG.oceano;
      OceanoLayerUtils.setOriginators(layer, originators, image_directory);
    }

    const options = this.getOptionsFromDeliveryJSON(layer, NCWMSId.thematic, deliveryJSON);
    if (options) {
      OceanoLayerUtils.setNCWMSOptions(layer, options);
    }

    const indexInCat = this.getLayerIndexFromCatalogJSON(layer, catalogJSON);
    if (indexInCat) {
      OceanoLayerUtils.setIndexInCat(layer, indexInCat);
    }

    const legendUrl = this.getLegendUrlFromDeliveryJSON(layer, NCWMSId.thematic, deliveryJSON);
    if (legendUrl) {
      OceanoLayerUtils.setNCWMSLegendUrl(layer, legendUrl);
    }

    const copernicusLink = this.getCopernicusLinkFromDeliveryJSON(layer, NCWMSId.thematic, deliveryJSON);
    if (copernicusLink) {
      OceanoLayerUtils.setCopernicusLink(layer, copernicusLink);
    }

    const olLayer = layer.get('olLayer');
    const regLayers = olLayer.getLayers().getArray();
    const types = isAtlas ? [deliveryJSON.models[NCWMSId.thematic].type] : deliveryJSON.models[NCWMSId.thematic].types;
    for (let i = 0; i < regLayers.length; i++) {
      const regLayerId = regLayers[i].get('identifier');
      const regLayerNCWMSId = OceanoLayerUtils.getIdentifiersFromLayerId(regLayerId);
      const ncwmsIdentifier = regLayers[i].get('identifier').split('/')[0];
      let zoom;
      if (!isAtlas) {
        zoom = this.getZoomFromDeliveryJSON(ncwmsIdentifier, NCWMSId.thematic, deliveryJSON);
      } else {
        zoom = this.getZoomFromDeliveryAtlasJSON(ncwmsIdentifier, NCWMSId.thematic, deliveryJSON);
        this.setAtlasInfos(layer, ncwmsIdentifier, NCWMSId.thematic, deliveryJSON);
      }
      OceanoLayerUtils.setZoomAllowed(regLayers[i], zoom);

      types && OceanoLayerUtils.setModelType(regLayers[i], regLayerNCWMSId.datasetId, types);
      // set layer region visible to false
      regLayers[i].set('visible', false);
    }
  }, this));

  return layersNoFoundInDelivery;
};

OceanoJsonUtils.prototype.setAtlasInfos = function (layer, datasetId, model, deliveryJSON) {
  const modelJson = deliveryJSON.models[model];
  if (!modelJson) {
    return;
  }
  const translations = modelJson.translate;
  if (!translations) {
    return;
  }
  const translation = layer.get('translation') || {};
  const numAtlas = AtlasHelper.getAtlasNum(datasetId);
  translation[numAtlas] = this.getTranslationFromKey(translations, numAtlas, ['fr', 'en']);
  OceanoLayerUtils.setTranslation(layer, translation);
  OceanoLayerUtils.setTitle(layer, datasetId);
  OceanoLayerUtils.setNumAtlas(layer, numAtlas);
  const { portRef, isBm } = this.getPortRefAndIsBmFromDeliveryJSON(datasetId, model, deliveryJSON);
  if (portRef) {
    OceanoLayerUtils.setPortRef(layer, portRef);
  }
  OceanoLayerUtils.setIsBm(layer, !!isBm);
};

OceanoJsonUtils.prototype.removeBlacklistParams = function (collection, deliveryJSON) {
  const blacklistLayers = this.getBlackListLayers(
    collection.models,
    deliveryJSON
  );
  collection.remove(blacklistLayers);
};

module.exports = new OceanoJsonUtils();
