const $ = require('jquery');
const _ = require('underscore');
const moment = require('moment');
const ShomView = require('../../core/shom-view');
const OceanogrammeService = require('../../service/oceanogramme');
const template = require('../../../template/oceano/ocea-download-modal.hbs');
const Loading = require('../../utils/loading');
const userValidation = require('../../utils/user-validation');

module.exports = ShomView.build({

  className: 'hitable modal-dialog',

  events: {
    'click #ocea-current-irregular-grid-radio': '_onChangeGridTypeRadio',
    'click #ocea-current-regular-grid-radio': '_onChangeGridTypeRadio',
    'click #ocea-current-date-download-btn': '_onCurrentDateDownloadButton',
    'click #ocea-custom-date-download-btn': '_onCustomDateDownloadButton',
    'click .close-modal': '_onCloseModal',
    'click .ocea-download-archive-alert-close': '_onCloseAlert',
    'click .oceano-download-archive-instruction-show': '_onArchiveInstructionShow',
    'click .oceano-download-archive-instruction-hide': '_onArchiveInstructionHide',
    'click .oceano-download-current-instruction-show': '_onCurrentInstructionShow',
    'click .oceano-download-current-instruction-hide': '_onCurrentInstructionHide'
  },

  initialize(options = {}) {
    this._parentView = options.parentView;
    this._layerIdentifier = options.identifier || '';
    this._layerCurrentDate = options.date || '';
    this._layerRegularSize = options.regularSize || '';
    this._layerIrregularSize = options.irregularSize || '';
    this._modalView = options.modalView || window.MODALVIEW;
    this._config = options.config || window.CONFIG;
    this._irregularParent = options.irregularParent || '';
    this._enableDirectDl = !!options.enableDirectDl;
    this._enableArchiveDl = !!options.enableArchiveDl;

    this.values = options.values || [];
    this._model = this._layerIdentifier.split('/')[0];
    this._strDate = this._config.oceanogramme.archiveFirstDate;
    this._modelType = options.modelType;
    this._gribModels = this._config.oceano.gribModels || [];
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    const downloadRegularSize = `${Math.ceil(this._layerRegularSize / 1000)} Ko`;
    const layerFormat = (this._gribModels && _.contains(this._gribModels, this._modelType) === true) ? 'GRIB' : 'NetCDF';
    this.$el.html(template({
      archivedData: !(this._config.disabledFeatures && this._config.disabledFeatures.Ls),
      model: this._model,
      layerDate: this._layerCurrentDate,
      layerFormat,
      layerRegularSize: downloadRegularSize,
      irregular_parent: this._irregularParent,
      enableDirectDl: this._enableDirectDl,
      enableArchiveDl: this._enableArchiveDl
    }));

    this._$oceaCurrentInstructionContainer = this.$('.oceano-download-current-instruction-container');
    this._$oceaCurrentInstructionMessage = this.$('.oceano-download-current-instruction-message');
    // add i18n translation here because the message contains HTML balises or parameters
    this._$oceaCurrentInstructionMessage.html($.i18n.t('oceano.modal.download.currentData.instructionMessage'));

    this._$emailInput = this.$('#email-input');
    this._$dateInput = this.$('#date-input');
    this._$archiveDatePicker = this.$('#oceano-archive-date-group');

    this._$alertContainer = this.$('.ocea-download-archive-alert');
    this._$alertMessage = this.$('.ocea-download-archive-alert-message');

    this._$oceaArchiveFrom = this.$('.ocea-download-archive-form');
    this._$oceaArchiveDateMessage = this.$('.ocea-download-archive-date-message');

    this._$oceaArchiveDownloadButton = this.$('#ocea-custom-date-download-btn');

    this._$oceaArchiveInstructionContainer = this.$('.oceano-download-archive-instruction-container');
    this._$oceaArchiveInstructionMessage = this.$('.oceano-download-archive-instruction-message');

    this._$oceaArchiveDownloadMessage = this.$('.oceano-download-modal-message');

    // add i18n translation here because the message contains HTML balises or parameters
    this._$oceaArchiveInstructionMessage.html($.i18n.t('oceano.modal.download.archivedData.instructionMessage'));
    this._$oceaArchiveDownloadMessage.html($.i18n.t('oceano.modal.download.archivedData.message', {
      dateMetropolis: this._strDate.metropolis,
      dateOverseas: this._strDate.overseas
    }));

    this._initDatePicker();

    return this;
  },

  _initDatePicker() {
    this._$archiveDatePicker.datepicker({
      startDate: moment(this._strDate.metropolis, 'DD/MM/YYYY').toDate(),
      language: $.i18n.lng()
    });
    this._$archiveDatePicker.on('changeDate', _.bind(this._onChangeDate, this));
  },

  /** ***************************** */
  /** ****** EVENT FUNCTION ******* */
  /** ***************************** */

  _onChangeGridTypeRadio() {
    const size = this.$('#ocea-current-irregular-grid-radio').is(':checked') ? this._layerIrregularSize : this._layerRegularSize;
    this.$('#ocea-current-layer-size').html(`${Math.ceil(size / 1000)} Ko`);

    this.$('#ocea-current-grid-type').html(this.$('#ocea-current-irregular-grid-radio').is(':checked') ? this._irregularParent : this._model);

    this._parentView._createDownloadUrl(this.$('#ocea-current-irregular-grid-radio').is(':checked') ? this._irregularParent : this._model, this._parentView.getCurrentDate());

    if (this._$archiveDatePicker.datepicker('getDate')) {
      this._onChangeDate();
    }

    return true;
  },

  _onCurrentDateDownloadButton() {
    this._parentView.downloadFile();
    this._modalView.hide();
    return false;
  },

  _onCustomDateDownloadButton() {
    const mail = this._$emailInput.val();
    const date = moment(this._$archiveDatePicker.datepicker('getDate')).format('YYYYMMDD');
    const model = this.$('#ocea-current-irregular-grid-radio').is(':checked') ? this._irregularParent : this._model;

    if (!userValidation.isMailValid(mail)) {
      const message = $.i18n.t('oceano.modal.download.archivedData.errorMail');
      this._showAlertError(message);
    } else {
      Loading.start(this._$oceaArchiveFrom);

      OceanogrammeService()
        .then(service => service.downloadArchive(model, mail, date))
        .then(_.bind(function () {
          const successMessage = $.i18n.t('oceano.modal.download.archivedData.success');
          this._showAlertSuccess(successMessage);
        }, this))
        .fail(_.bind(function (error) {
          const errorMessage = (error.status === 404)
            ? $.i18n.t('oceano.modal.download.archivedData.errorNotFound')
            : $.i18n.t('oceano.modal.download.archivedData.error');
          this._showAlertError(errorMessage);
        }, this))
        .always(_.bind(function () {
          Loading.stop(this._$oceaArchiveFrom);
        }, this));
    }
  },

  _showAlertError(msg) {
    this._onCloseAlert();
    this._$alertContainer.addClass('alert-danger');
    this._$alertMessage.html(msg);
    this._$alertContainer.removeClass('hidden');
  },

  _showAlertSuccess(msg) {
    this._onCloseAlert();
    this._$alertContainer.addClass('alert-success');
    this._$alertMessage.html(msg);
    this._$alertContainer.removeClass('hidden');
  },

  _enableArchiveDownload(isArchiveExist) {
    let message;
    let styleClass;

    this._$oceaArchiveDateMessage.removeClass('ocea-archive-date-message-notAvailable');
    this._$oceaArchiveDateMessage.removeClass('ocea-archive-date-message-available');

    if (isArchiveExist) {
      message = '<i class="material-icons">done</i>';
      message += $.i18n.t('oceano.modal.download.archivedData.dateAvailable');
      styleClass = 'ocea-archive-date-message-available';
      this._$oceaArchiveDownloadButton.removeAttr('disabled');
    } else {
      message = '<i class="material-icons">error_outline</i>';
      message += $.i18n.t('oceano.modal.download.archivedData.dateNotAvailable');
      styleClass = 'ocea-archive-date-message-notAvailable';
    }

    this._$oceaArchiveDateMessage.html(message);
    this._$oceaArchiveDateMessage.addClass(styleClass);
    this._$oceaArchiveDateMessage.removeClass('hidden');

    this._$dateInput.removeAttr('disabled');
  },

  _onChangeDate() {
    const date = moment(this._$archiveDatePicker.datepicker('getDate')).format('YYYYMMDD');
    const model = this.$('#ocea-current-irregular-grid-radio').is(':checked') ? this._irregularParent : this._model;

    if (!this._$alertContainer.hasClass('hidden')) {
      this._$alertContainer.addClass('hidden');
    }

    this._$dateInput.attr('disabled', 'disabled');
    this._$oceaArchiveDownloadButton.attr('disabled', 'disabled');
    Loading.start(this._$oceaArchiveDateMessage
      .html('')
      .removeClass('hidden'));

    OceanogrammeService()
      .then(service => service.isArchiveExist(model, date))
      .then(_.bind(function (isArchiveExist) {
        this._enableArchiveDownload(isArchiveExist);
      }, this))
      .fail(_.bind(function () {
        this._enableArchiveDownload(false);
      }, this))
      .always(() => {
        Loading.stop(this._$oceaArchiveDateMessage);
      });

    return true;
  },

  _onCloseAlert() {
    this._$alertContainer.removeClass('alert-danger');
    this._$alertContainer.removeClass('alert-success');
    this._$alertMessage.html('');
    this._$alertContainer.addClass('hidden');
  },

  _onCloseModal() {
    this._modalView.hide();
    return false;
  },

  _onCurrentInstructionShow() {
    this._$oceaCurrentInstructionContainer.removeClass('hidden');
  },

  _onCurrentInstructionHide() {
    this._$oceaCurrentInstructionContainer.addClass('hidden');
  },

  _onArchiveInstructionShow() {
    this._$oceaArchiveInstructionContainer.removeClass('hidden');
  },

  _onArchiveInstructionHide() {
    this._$oceaArchiveInstructionContainer.addClass('hidden');
  }
});
