const moment = require('moment');
const DDMService = require('../../service/ddm.js');
const DDMLayer = require('../gis/ddm-layer');

const DDMUtils = module.exports = function (config) {
  this.config = config;
  this._ddmLayer = new DDMLayer(this.config);
};

DDMUtils.prototype.getTideGaugesFeatures = function (network) {
  return DDMService(this.config).getTideGauges(network)
    .then(this._readJSONFeatures.bind(this));
};

DDMUtils.prototype.getDownloadObservations = function (tideGauges, sources, start, end, type, name, mail, isContact) {
  return DDMService(this.config).getDownloadObservations(tideGauges, sources, start, end, type, name, mail, isContact);
};

DDMUtils.prototype.searchTideGaugeId = function (pattern) {
  return DDMService(this.config).searchTideGaugeId((`${pattern}`).toUpperCase());
};

DDMUtils.prototype.getLastValueAndDate = function (tideGaugeId) {
  return this.getTideGaugeStatistics(tideGaugeId)
    .then(this._detectLastDate.bind(this))
    .then(this._getLastValue.bind(this));
};

DDMUtils.prototype._detectLastDate = function (statistics) {
  let lastDate = null; let
    source = null;
  for (const i in statistics) {
    if (!statistics.hasOwnProperty(i) || statistics[i].source > 6) {
      continue;
    }
    const currentLastDate = moment.utc(statistics[i].last_date);
    if (lastDate === null || lastDate < currentLastDate) {
      lastDate = currentLastDate;
      source = statistics[i].source;
    }
  }

  if (lastDate === null) {
    return null;
  }

  const tideGaugeId = statistics[0].tide_gauge_id;
  const startDate = moment.utc(lastDate, 'DD/MM/YYYY HH:mm').subtract(60, 'minute').toISOString();
  const endDate = moment.utc(lastDate, 'DD/MM/YYYY HH:mm').toISOString();
  const sourceFlags = [false, false, false, false, false, false, false, false, false, false];
  sourceFlags[source - 1] = true;

  return this.getSimpleObservationsBetweenDates(tideGaugeId, startDate, endDate, sourceFlags, 1, 'json');
};

DDMUtils.prototype._getLastValue = function (response) {
  if (response === null || !response.data || response.data.length < 1) {
    return {
      date: null,
      value: null
    };
  }
  const data = response.data.slice(response.data.length - 1);
  return {
    date: moment.utc(data[0].timestamp, 'YYYY/MM/DD HH:mm:ss'),
    value: parseFloat(data[0].value)
  };
};

DDMUtils.prototype.getNearestObservationForSrcPeriod = function (tideGaugeId, beginDate, endDate, sourceFlags) {
  return DDMService(this.config).getNearestObs(tideGaugeId, beginDate, endDate, sourceFlags);
};

DDMUtils.prototype.getSourceWithDataInInterval = function (tideGaugeId, beginDate, endDate) {
  return DDMService(this.config).getSourceWithDataInInterval(tideGaugeId, beginDate, endDate);
};

DDMUtils.prototype.getSimpleObservationsBetweenDates = function (tideGaugeId, beginDate, endDate, sourceFlags, interval, format) {
  const offeringId = `${this.config.ddm.url_def}offering/${tideGaugeId}`;
  return DDMService(this.config).getJsonData(offeringId, beginDate, endDate, sourceFlags, interval, format);
};

DDMUtils.prototype.getSensorDesc = function (sensorId) {
  return DDMService(this.config).getSensorDesc(sensorId);
};

DDMUtils.prototype.getTideGaugeStatistics = function (tideGaugeId) {
  return DDMService(this.config).getStatistics(tideGaugeId);
};

DDMUtils.prototype._readJSONFeatures = function (JSONFeatures) {
  const features = [];
  for (let i = 0; i < JSONFeatures.length; i++) {
    const featureMember = JSONFeatures[i];
    if (featureMember.state === this.config.ddm.states.hidden) {
      continue;
    }

    const feature = this._ddmLayer.createFeature(featureMember);
    features.push(feature);
  }
  return features;
};

DDMUtils.prototype.getTidegaugeObservations = function (pattern, withStartDate = true) {
  return DDMService(this.config).getTidegaugeObservations(pattern, withStartDate);
};
