const ShomView = require('../core/shom-view');
const template = require('../../template/logo.hbs');

module.exports = ShomView.build({

  tagName: 'li',

  className: 'hitable',

  initialize(options) {
    const optionsToUse = options || {};
    this.originator = optionsToUse.originator;
  },

  render() {
    this.$el.html(template({
      originator: this.originator
    }));
    return this;
  }
});
