const ShomView = require('../core/shom-view');
const template = require('../../template/legal-notice.hbs');

module.exports = ShomView.build({

  initialize(options = {}) {
    this._config = options.config || window.CONFIG;
  },

  render() {
    this.$el.html(template({
      hrefLegalNotice: this._config.legalNotice[window.portalLang],
      hrefPersonalData: this._config.personalData[window.portalLang],
      hrefAccessibility: this._config.accessibility[window.portalLang]
    }));
    return this;
  },

  postTranslate() {
    this.$('.legal-notice').attr('href', this._config.legalNotice[window.portalLang]);
    this.$('.personal-data').attr('href', this._config.personalData[window.portalLang]);
    this.$('.accessibility').attr('href', this._config.accessibility[window.portalLang]);
  }
});
