import VectorLayer from 'ol/layer/Vector';
import { boundingExtent } from 'ol/extent.js';
import {
  CATALOG_OCEANO,
  LAYERTYPE_REFMAR,
  LAYERTYPE_FORECAST,
  CATALOG_OBSERVATIONS,
  SEPARATOR_CATEGORY_LEVEL01,
  NCWMS_LAYERTYPE_OCEANO,
  NCWMS_LAYERTYPE_ATLAS
} from './constants';

const $ = require('jquery');
const _ = require('underscore');
const GisUtils = require('./gis');

const Builder = {};
const Layers = require('../collection/layers');
const LayersFactory = require('./layers-factory');
const LayerFactory = require('./layer-factory');
const CategoryUtil = require('./category');
const OceanoJsonUtils = require('./oceano/oceano-json.js');
const OceanoNcwmsUtils = require('./oceano/oceano-ncwms.js');
const AtlasHelper = require('./atlas/atlas-utils.js');
const CoordsHelper = require('./gis/coordinates-helper.js');
const NCWMSService = require('../service/ncwms');

const _getDefferedUrlsArray = function (conf, user) {
  const deffered = [];
  _.each(conf.getCaps, baseUrl => {
    const url = baseUrl.replace('{{key}}', user ? user.get('key') : conf.defaultKey);
    deffered.push(
      $.ajax({
        url,
        cache: true,
        beforeSend(jxhr) {
          jxhr.targetUrl = url;
        }
      })
    );
  });
  return deffered;
};

const addHelpLinks = collection => {
  const helpLinks = window.HELPLINKS;
  if (helpLinks) {
    _.each(collection.models, layer => {
      if (layer.get('identifier')) {
        layer.set('helpLinks', helpLinks[layer.get('identifier')]);
      }
    });
  }
};

Builder.build = function (conf, user) {
  let wmc;
  const defer = $.Deferred();
  $.get(conf.urlWMC)
    .then(wmcDocument => {
      wmc = GisUtils.readWmcConf(wmcDocument);
      return $.when.apply(null, _getDefferedUrlsArray(conf, user));
    })
    .then(function () {
      const args = Array.prototype.slice.call(arguments);
      const mapperDocumentUrl = [];
      if (args[0] instanceof Array) {
        _.map(args, (a, index) => {
          mapperDocumentUrl[index] = { doc: a[0], url: a[2].targetUrl };
        });
      } else {
        mapperDocumentUrl[0] = { doc: args[0], url: args[2].targetUrl };
        return mapperDocumentUrl;
      }
      return mapperDocumentUrl;
    })
    .then(getCaps => {
      const collections = _.map(getCaps, getCap => {
        const layersFromGetCap = GisUtils.readGetCap(getCap.doc, getCap.url);
        return GisUtils.buildBackboneCollection(wmc, layersFromGetCap, window.CATALOG, window.ADDITIONALINFO);
      });

      const collection = collections[0];
      for (let getCapIdx = 1; getCapIdx <= getCaps.length - 1; getCapIdx++) {
        collection.add(collections[getCapIdx].models);
      }

      addHelpLinks(collection);

      defer.resolve(collection);
    })
    .fail(err => {
      console.error('an error occured : ', err);
      defer.resolve(GisUtils.buildBackboneCollection());
    });

  return defer;
};

function resetAndAddInfos(currCollection, layers) {
  currCollection.reset(
    OceanoNcwmsUtils.buildNCWMSCollection(layers)
  );
  _.each(currCollection.models, layer => {
    // additional info
    if (window.ADDITIONALINFO && window.ADDITIONALINFO[layer.get('identifier')]) {
      layer.set('additionalInfo', window.ADDITIONALINFO[layer.get('identifier')]);
    }
  });
}

Builder.buildOceano = function () {
  const oceanoCollection = LayersFactory.build();
  this.ncwWMSService = {};

  const defer = $.Deferred();

  NCWMSService({ ncwmsLayerType: NCWMS_LAYERTYPE_OCEANO })
    .then(ncwWMSService => {
      this.ncwWMSService = ncwWMSService;
      return this.ncwWMSService.getLayersFromCapabilities();
    })
    .then(layers => resetAndAddInfos(oceanoCollection, layers))
    .then(() => this.ncwWMSService.getDeliveriesJSON())
    .then(deliveriesJSON => {
      OceanoJsonUtils.setParamsFromDeliveriesJSON(
        oceanoCollection,
        deliveriesJSON
      );
      OceanoNcwmsUtils.addMetadataUrlToLayers(oceanoCollection);

      addHelpLinks(oceanoCollection);

      defer.resolve(oceanoCollection.models);
    })
    .fail(err => {
      console.error('an error occured :  ', err);
      defer.resolve(oceanoCollection);
    });

  return defer;
};

Builder.buildMaregraphie = function () {
  const ddmCollection = new Layers();
  // maxExtent available on maximal zoom.
  const bounds = [-180, -90, 180, 90];
  const coord = CoordsHelper.convertLonLatBboxToMercator(bounds);
  const maxExtent = boundingExtent(
    [
      [coord[0], coord[1]],
      [coord[2], coord[3]]
    ]
  );
    // add originators
  const logoName = window.CONFIG.ddm.originator.name;
  const logoDirectory = window.CONFIG.ddm.originator.logo_url + window.CONFIG.ddm.originator.folder;
  const logoPath = logoDirectory + logoName;
  const originators = [{
    logo: logoPath,
    name: logoName.split('.')[0],
    url: window.CONFIG.ddm.originator.url
  }];

  const categoryInfo = {
    name: LAYERTYPE_REFMAR,
    fullpath: CATALOG_OBSERVATIONS + SEPARATOR_CATEGORY_LEVEL01 + LAYERTYPE_REFMAR,
    translate: {
      fr: $.i18n.options.resStore.fr.translation.ddm.category,
      en: $.i18n.options.resStore.en.translation.ddm.category
    }
  };
  if (window.CATALOG && window.CATALOG.categories) {
    const categoryTranslations = CategoryUtil.findCategoryForLayer(window.CATALOG.categories, categoryInfo.fullpath);
    if (categoryTranslations) {
      categoryInfo.translate = categoryTranslations.name;
    }
  }

  const identifier1 = `${LAYERTYPE_REFMAR}/RONIM`;
  const refmarLayer1 = LayerFactory.build(new VectorLayer({
    maxExtent,
    layerType: LAYERTYPE_REFMAR,
    identifier: identifier1
  }), {
    originators,
    catalog: CATALOG_OBSERVATIONS,
    category: categoryInfo,
    layerType: LAYERTYPE_REFMAR,
    identifier: identifier1,
    title: $.i18n.t('ddm.layer1'),
    priorities: {
      model: 1,
      param: 1
    },
    translation: { [LAYERTYPE_REFMAR]: categoryInfo.translate },
    external: false,
    legendUrl: window.CONFIG.ddm.legendUrl.ronim,
    metadataURL: window.CONFIG.ddm.metadataURL.ronim
  });

  const identifier2 = `${LAYERTYPE_REFMAR}/OTHER`;
  const refmarLayer2 = LayerFactory.build(new VectorLayer({
    maxExtent,
    layerType: LAYERTYPE_REFMAR,
    identifier: identifier2
  }), {
    originators,
    catalog: CATALOG_OBSERVATIONS,
    category: categoryInfo,
    layerType: LAYERTYPE_REFMAR,
    identifier: identifier2,
    title: $.i18n.t('ddm.layer2'),
    priorities: {
      model: 2,
      param: 2
    },
    translation: { [LAYERTYPE_REFMAR]: categoryInfo.translate },
    external: false,
    legendUrl: window.CONFIG.ddm.legendUrl.other,
    metadataURL: window.CONFIG.ddm.metadataURL.other
  });

  if (window.ADDITIONALINFO && window.ADDITIONALINFO[refmarLayer1.get('identifier')]) {
    refmarLayer1.set('additionalInfo', window.ADDITIONALINFO[refmarLayer1.get('identifier')]);
  }
  if (window.ADDITIONALINFO && window.ADDITIONALINFO[refmarLayer2.get('identifier')]) {
    refmarLayer2.set('additionalInfo', window.ADDITIONALINFO[refmarLayer2.get('identifier')]);
  }

  // Give translation key to can directly translate in catalog
  refmarLayer1.set('translationKey', 'ddm.layer1');
  refmarLayer2.set('translationKey', 'ddm.layer2');
  ddmCollection.add(refmarLayer1);
  ddmCollection.add(refmarLayer2);

  addHelpLinks(ddmCollection);

  return $.Deferred().resolve(ddmCollection.models);
};

Builder.buildOceanogrammeLayers = function () {
  const ddmCollection = new Layers();
  // maxExtent available on maximal zoom.
  const bounds = [-180, -90, 180, 90];
  const coord = CoordsHelper.convertLonLatBboxToMercator(bounds);
  const maxExtent = boundingExtent(
    [
      [coord[0], coord[1]],
      [coord[2], coord[3]]
    ]
  );

  const originators = [];
  const priority = 99;
  const categoryTranslation = {
    [LAYERTYPE_FORECAST]: {
      fr: $.i18n.options.resStore.fr.translation.oceanogramme.category,
      en: $.i18n.options.resStore.en.translation.oceanogramme.category
    }
  };
  const identifier = `${LAYERTYPE_FORECAST}/OCEANOLAYER`;
  const translationKey = 'oceanogramme.layer1';

  const oceanoForecastLayer = LayerFactory.build(new VectorLayer({
    identifier,
    maxExtent,
    layerType: LAYERTYPE_FORECAST
  }), {
    identifier,
    originators,
    layerType: LAYERTYPE_FORECAST,
    catalog: CATALOG_OCEANO,
    title: $.i18n.t(translationKey),
    priorities: {
      model: priority,
      param: priority
    },
    translation: categoryTranslation,
    external: false,
    legendUrl: ''
  });

  const layerAdditionalInfo = window.ADDITIONALINFO && window.ADDITIONALINFO[oceanoForecastLayer.get('identifier')];
  if (layerAdditionalInfo) {
    oceanoForecastLayer.set('additionalInfo', layerAdditionalInfo);
  }

  // Give translation key to can directly translate in catalog
  oceanoForecastLayer.set('translationKey', translationKey);

  ddmCollection.add(oceanoForecastLayer);

  addHelpLinks(ddmCollection);

  return $.Deferred().resolve(ddmCollection.models);
};

Builder.buildAtlas = function () {
  const atlasCollection = LayersFactory.build();
  this.ncwWMSServiceAtlas = {};

  const defer = $.Deferred();
  NCWMSService({ ncwmsLayerType: NCWMS_LAYERTYPE_ATLAS })
    .then(service => {
      this.ncwWMSServiceAtlas = service;
      return this.ncwWMSServiceAtlas.getLayersFromCapabilities();
    })
    .then(layers => resetAndAddInfos(atlasCollection, layers))
    .then(() => this.ncwWMSServiceAtlas.getDeliveriesJSON('atlas'))
    .then(deliveriesJSON => {
      OceanoJsonUtils.setParamsFromDeliveriesJSON(
        atlasCollection,
        [deliveriesJSON[0]],
        true
      );

      AtlasHelper.addMetadataUrlToLayers(atlasCollection);

      defer.resolve(atlasCollection.models);
    })
    .fail(err => {
      console.error('an error occured :  ', err);
      defer.resolve(atlasCollection);
    });

  return defer;
};

module.exports = Builder;
