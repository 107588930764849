var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none;\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"catalog-search-container\">\n    <label class=\"label-search material-icons\" for=\"catalog-search-input\">search</label>\n    <input class=\"catalog-input-search\" type=\"text\" name=\"name\" data-i18n=\"[placeholder]catalog.search.placeholder\" id=\"catalog-search-input\" autocomplete=\"off\"></input>\n     <span class=\"clear-btn clear-icon-search\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"value") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":47},"end":{"line":4,"column":97}}})) != null ? stack1 : "")
    + ">\n        <button class=\"material-icons\">close</button>\n    </span>\n</div>";
},"useData":true});