const ShomView = require('../../core/shom-view');
const template = require('../../../template/catalog/catalog-search.hbs');

module.exports = ShomView.build({

  id: 'catalog-search-group',
  className: 'catalog-search-group',

  events: {
    'click .clear-icon-search button': '_eraseCatalogSearchInput',
    'keyup #catalog-search-input': '_onSearchInputKeyUp'
  },

  initialize(options = {}) {
    this._value = '';
    this._triggerEventSearchName = options.triggerEventSearchName;
    this._eventBus = options.eventBus || window.EVENTBUS;
    this._eventFuncEraseCatalogSearchInput = this._eraseCatalogSearchInput.bind(this);
    this._eventBus.on('search:clear', this._eventFuncEraseCatalogSearchInput);
  },

  render() {
    this.$el.html(template({ value: this._value }));
    this._$searchInput = this.$('#catalog-search-input');
    this._$clearButton = this.$('.clear-icon-search');
    return this;
  },

  _eraseCatalogSearchInput() {
    this._$searchInput.val('');
    this._onSearchInputKeyUp();
  },

  _onSearchInputKeyUp() {
    const term = this._$searchInput.val();
    if (this._value !== term) {
      this._value = term;
      this.trigger(this._triggerEventSearchName, this._value);
    }
    if (this._value) {
      this._$searchInput.removeClass('no-clear-button-search');
      this._$clearButton.show();
    } else {
      this._$searchInput.addClass('no-clear-button-search');
      this._$clearButton.hide();
    }
  },

  onClose() {
    this._eventBus.off('search:clear', this._eventFuncEraseCatalogSearchInput);
  }
});
