var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "hide";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"legend-panel hitable\">\n    <h2 class=\"header\">\n        <span data-i18n=\"drawing.legend.panel.title\"></span>\n        <a id=\"help_legend\" target=\"_blank\" class=\"anchor-help-link\" data-i18n=\"[title]header.links.help;[aria-label]header.links.help\"><i class=\"far fa-question-circle\"></i></a>\n    </h2>\n    <div class=\"content\">\n        <ul class=\"legend-list\"></ul>\n        <span class=\"no-legend-item "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"legendItemsCount") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":36},"end":{"line":8,"column":71}}})) != null ? stack1 : "")
    + "\" data-i18n=\"drawing.legend.panel.no-legend\"></span>\n    </div>\n</div>\n";
},"useData":true});