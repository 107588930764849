const Backbone = require('backbone');
const DDMDetailView = require('../view/ddm/ddm-detail/ddm-detail.view.js');
const DDMDownloadView = require('../view/ddm/ddm-dl/ddm-download.view.js');
const DDMSensorReader = require('../utils/ddm/ddm-sensor-reader.js');
const SensorBottomView = require('../view/ddm/sensor-bottom.view.js');
const CoordsHelper = require('../utils/gis/coordinates-helper.js');

module.exports = Backbone.Router.extend({

  initialize(options = {}) {
    this.gisView = options.gisView || window.GISVIEW;
    this.collection = options.collection || window.LAYERS;
    this._router = options.router || window.ROUTER;

    this.route('donnees/refmar/:idSensor', '_openDetailledSensor');
    this.route('donnees/refmar/:idSensor/download', '_openDownloadDataSensor');
    this.route('donnees/refmar/download', '_openDownloadDataSensor');
    this.route('donnees/refmar', '_loadRefmarLayers');
    this.route('donnees/refmar/RONIM', '_loadRonimLayers');
    this.route('donnees/refmar/PARTENAIRES', '_loadPartnersLayers');

    this._staticRenderer = options.staticRender;
    this._dynamicRenderer = options.dynamicRenderer;
  },

  _openDetailledSensor(idSensor) {
    this._staticRenderer.hideStaticOLView();
    this._dynamicRenderer.clearAllViews();

    const detailView = new DDMDetailView({
      parentView: this,
      idSensor
    });
    this._dynamicRenderer.renderMainView(detailView);
  },

  _openDownloadDataSensor(idSensor) {
    this._staticRenderer.hideStaticOLView();
    this._dynamicRenderer.clearAllViews();

    const download = new DDMDownloadView({ ddmRouter: this, sensorId: idSensor });
    this._dynamicRenderer.renderMainView(download);
    this.trigger('renderMainView');
  },

  _loadRefmarLayersIfNeeded(tideGaugeDescription) {
    let layer = null;
    const network = DDMSensorReader.getNetwork(tideGaugeDescription);
    const identifier = `REFMAR/${network}`;
    for (const i in this.collection.models) {
      if (this.collection.models.hasOwnProperty(i) && this.collection.models[i].get('identifier') === identifier) {
        layer = this.collection.models[i];
        if (layer.get('includedInMap')) {
          return;
        }
        break;
      }
    }

    const sensorView = new SensorBottomView({
      model: this.layer,
      network
    });

    sensorView.loadFeatures().then(() => {
      sensorView.addLayerFeatures();
      layer.set('includedInMap', true);
      this._router.addRefmarLayer();
    });

    const coords = [tideGaugeDescription.longitude, tideGaugeDescription.latitude];

    this.gisView._updateMapCenter(CoordsHelper.convertLonLatToMercat(coords));
  },

  _loadRefmarLayers() {
    if (new RegExp(/\/donnees\/refmar#([a-z0-9]+)/gi).test(window.location.href)) {
      const url = window.location.href.replace('donnees/refmar#', 'donnees/refmar/');
      const navPath = url.substring(url.indexOf('donnees/refmar/'));
      this.navigate(navPath, true);
    } else {
      this.navigate('donnees/contexte/shom_refmar_context/donnees', true);
    }
  },

  _loadRonimLayers() {
    this.navigate('donnees/contexte/shom_refmar_ronim_context/donnees', true);
  },

  _loadPartnersLayers() {
    this.navigate('donnees/contexte/shom_refmar_partners_context/donnees', true);
  }
});
