const $ = require('jquery');
const _ = require('underscore');

module.exports = {

  Deferred: {

    all(promises) {
      return $.when.apply($, promises)
        .then(function () {
          const args = Array.prototype.slice.call(arguments);

          return _.map(args, a => a);
        });
    },

    allAjax(promises) {
      return $.when.apply($, promises)
        .then(function () {
          const args = Array.prototype.slice.call(arguments);

          return _.map(args, a => a[0]);
        });
    }
  }

};
