import { SC_DRAWING_MODULE } from '../../utils/constants';

const $ = require('jquery');
const _ = require('underscore');
const GlobalDataStore = require('../../core/global-data-store');

const ShomView = require('../../core/shom-view');
const template = require('../../../template/drawing/maps-list-item.hbs');
const CartodynStatsService = require('../../service/drawing/cartodyn-stats');

const ConfirmView = require('../modal/confirm.view');

const MapsListView = ShomView.build({

  tagName: 'li',

  className: 'maps-list-item clearfix',

  events: {
    'click .name': '_onClickOpenMap',
    'click .btn-danger': '_onClickDelete'
  },

  initialize(options = {}) {
    options = options || {};
    this._config = options.config || window.CONFIG;
    this._router = options.router || window.ROUTER;
    this._gisView = options.gisView || window.GISVIEW;
    this._modalView = options.modalView || window.MODALVIEW;
    this._user = options.user || window.ROUTER.user;
    this._session = options.session;

    this._cartodynStatsService = new CartodynStatsService({ cartodynUrl: this._config.cartodyn.statsUrl });
  },

  render() {
    this.$el.html(template({
      model: this.model.toJSON()
    }));
    return this;
  },

  _openMap() {
    this._cartodynStats().then(() => {
      this._router.navigate(`dessin/edit/${this.model.id}`, true);
      $('#my-account').hide();
      $('.my-account i').removeClass('active');
    });
  },

  _onClickOpenMap() {
    if (GlobalDataStore.get(SC_DRAWING_MODULE)) {
      const content = new ConfirmView({
        title: $.i18n.t('drawing.modal.openmap.title'),
        textContent: $.i18n.t('drawing.modal.openmap.message'),
        modalView: this._modalView,
        parent: this,
        confirmCallback: () => {
          $('.btn-close-map').click();
          this._openMap();
        }
      });
      this._modalView.show(content, { keyboard: false, backdrop: 'static' });
    } else {
      this._openMap();
    }
  },

  _onClickDelete() {
    const content = new ConfirmView({
      title: $.i18n.t('drawing.modal.delete.title'),
      textContent: $.i18n.t('drawing.modal.delete.message'),
      modalView: this._modalView,
      parent: this,
      confirmCallback: _.bind(function () {
        this._session.deleteMap(this.model);
      }, this)
    });
    this._modalView.show(content, { keyboard: false, backdrop: 'static' });
  },

  _cartodynStats() {
    const key = this._user.get('key');
    return this._cartodynStatsService.cartodynHit(key);
  }

});

module.exports = MapsListView;
