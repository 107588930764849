const $ = require('jquery');
const DisplayCoordView = require('../view/display-coord.view');
const ChooseCoordDDList = require('../view/choose-coord-dropdown-list.view');
const LogosView = require('../view/logos.view');
const DisplayScaleView = require('../view/scale.view');
const TopRibbonView = require('../view/top-ribbon.view');
const LayersOnMaps = require('../view/layers-on-maps.view');
const LegalNoticeView = require('../view/legal-notice.view');
const OverViewMap = require('../view/overViewMap.view.js');

const StaticViewsRenderer = module.exports = function (options = {}) {
  this._layers = options.layers || window.LAYERS;
  this._eventsBus = options.eventsBus || window.EVENTBUS;
  this._gisView = options.gisView || window.GISVIEW;

  this.$displayCoord = $('#coord');
  this.$displayScale = $('#display-scale');
  this.$displayScaleLine = $('#scale-line');
  this.$topRibbonRoot = $('#top-ribbon-container');
  this.$logosRoot = $('#originators-root');
  this.$legalNoticeRoot = $('.footer-center-part');
  this.$oceanoHUDRoot = $('#ocea-hud-root');
  this.$timelineRoot = $('#timeline-root');
  this.$layersOnMaps = $('#layers-on-maps');

  this.displayCoordView = new DisplayCoordView({
    router: this,
    gisView: this.gisView
  });

  this.chooseCoordDDView = new ChooseCoordDDList({
    router: this,
    gisView: this.gisView
  });

  this.topRibbonView = new TopRibbonView();

  this.logosView = new LogosView({
    collection: this._layers
  });

  this.scaleView = new DisplayScaleView({
    router: this,
    gisView: this.gisView
  });

  this.legalNoticeView = new LegalNoticeView();
  this.layersOnMaps = new LayersOnMaps();

  const overviewMapLayer = this._layers.findWhere({
    identifier: window.CONFIG.overViewMap.layer
  });

  if (overviewMapLayer) {
    this.overViewMap = new OverViewMap({
      layer: overviewMapLayer.get('olLayer')
    });
  }
};

StaticViewsRenderer.prototype.renderStaticViews = function () {
  this.$displayCoord.html(this.displayCoordView.render().$el);
  this.$displayCoord.append(this.chooseCoordDDView.render().$el);
  this.$displayScale.html(this.scaleView.render().$el);
  this.$topRibbonRoot.html(this.topRibbonView.render().$el);
  this.$logosRoot.html(this.logosView.render().$el);
  this.$legalNoticeRoot.html(this.legalNoticeView.render().$el);
  this.overViewMap && this.overViewMap.render();
  this._eventsBus.trigger('change:navigation-bar', {
    checked: false
  });
  this.$layersOnMaps.html(this.layersOnMaps.render().$el);
};

StaticViewsRenderer.prototype.hideStaticOLView = function () {
  this.$displayCoord.hide();
  this.$displayScale.hide();
  this.$displayScaleLine.hide();
  this.$logosRoot.hide();
  this.$oceanoHUDRoot.hide();
  this.$timelineRoot.hide();
  this.$layersOnMaps.hide();
  $('.legend-panel-container').hide();
  $('.sc-carto-map-container').hide();
  $('.btn-print-map').addClass('disabled');
  this.topRibbonView.disableMyShare();
};

StaticViewsRenderer.prototype.showStaticOLView = function () {
  this.$displayCoord.show();
  this.$displayScale.show();
  this.$displayScaleLine.show();
  this.$logosRoot.show();
  this.$oceanoHUDRoot.show();
  this.$timelineRoot.show();
  this.$layersOnMaps.show();
  $('.legend-panel-container').show();
  $('.sc-carto-map-container').show();
  $('.btn-print-map').removeClass('disabled');
  this.topRibbonView.activateMyShare();
};

StaticViewsRenderer.prototype.downRightContent = function () {
  this.$logosRoot.css('bottom', '');
  this.overViewMap && this.overViewMap.moveOverviewMap('');
};
