const $ = require('jquery');

const FileHelper = function () {

};

FileHelper.prototype.readFile = function (file, fileTypeRegex) {
  const promise = $.Deferred();
  if (!fileTypeRegex || file.type.match(fileTypeRegex)) {
    const reader = new FileReader();
    reader.onload = function (event) {
      const fileContent = event.target.result;
      promise.resolve(fileContent);
    };
    reader.onerror = function () {
      promise.reject();
    };
    reader.readAsText(file);
  } else {
    promise.reject('file type mismatch');
  }
  return promise;
};

FileHelper.prototype.readFileWithExtCheck = function (file, fileExtRegex) {
  const promise = $.Deferred();
  const fileExt = file.name.split('.').pop();
  if (!fileExtRegex || fileExt.match(fileExtRegex)) {
    const reader = new FileReader();
    reader.onload = function (event) {
      const fileContent = event.target.result;
      promise.resolve(fileContent);
    };
    reader.onerror = function () {
      promise.reject();
    };
    reader.readAsText(file);
  } else {
    promise.reject('file type mismatch');
  }
  return promise;
};

FileHelper.prototype.saveAs = function (content, name, mimetype, $destContainer) {
  if (!content || typeof content !== 'string') {
    return;
  }

  const ie = navigator.userAgent.match(/MSIE\s([\d.]+)/);
  const ie11 = navigator.userAgent.match(/Trident\/7.0/) && navigator.userAgent.match(/rv:11/);
  const ieEDGE = navigator.userAgent.match(/Edge/g);
  const ieVer = (ie ? ie[1] : (ie11 ? 11 : (ieEDGE ? 12 : -1)));

  if (ie && ieVer < 10) {
    console.warn('No blobs on IE ver<10');
    return;
  }

  mimetype = mimetype || 'text/plain;charset=utf-8';
  name = name || 'file.txt';
  const blob = new Blob([content], { type: mimetype });

  // see http://stackoverflow.com/questions/18755750/saving-text-in-a-local-file-in-internet-explorer-10
  if (ieVer > -1) {
    window.navigator.msSaveBlob(blob, name);
  } else {
    const url = URL.createObjectURL(blob);

    const $a = $('<a>').attr('href', url).attr('download', name);
    if ($destContainer && $destContainer.length) {
      $a.empty().appendTo($destContainer);
    }
    $a.get(0).click();
  }
};

module.exports = FileHelper;
