const _ = require('underscore');
const ShomView = require('../../../core/shom-view');

const template = require('../../../../template/drawing/discuss/invitation-modal.hbs');
const userValidation = require('../../../utils/user-validation');

module.exports = ShomView.build({

  events: {
    'click .confirm-button': '_onClickConfirm',
    'click .cancel-button': '_onClickCancel'
  },

  initialize(options) {
    const optionsToUse = options || {};
    this.config = optionsToUse.config || window.CONFIG;
    this._parent = optionsToUse.parent;
    this._modalView = optionsToUse.modalView;
    this.confirmCallback = optionsToUse.confirmCallback;
    this.cancelCallback = optionsToUse.cancelCallback;
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({
      title: this.title,
      text: this.textContent
    }));
    return this;
  },

  _onClickConfirm() {
    const $emailError = this.$('.invalid-email');
    $emailError.addClass('hidden');
    const email = this.$('#invitation-email').val();
    if (userValidation.isMailValid(email)) {
      this._modalView.$el.one('hidden.bs.modal', _.bind(function () {
        this._email = email;
        this.confirmCallback && this.confirmCallback(this._email);
      }, this));
      this._modalView.hide();
    } else {
      $emailError.removeClass('hidden');
    }
  },

  _onClickCancel() {
    this.cancelCallback && this.cancelCallback();
    this._modalView.hide();
  },

  getEmail() {
    return this._email;
  }
});
