import LayerModelUtils from '../../utils/layer-model-utils';

const _ = require('underscore');
const ShomView = require('../../core/shom-view');

const NCWMS = require('../../service/ncwms');
const OceanoLayerUtils = require('../../utils/oceano/oceano-layer');
const OceanoNcwmsUtils = require('../../utils/oceano/oceano-ncwms.js');

const template = require('../../../template/oceano/ocea-select-palette.hbs');

const MAX_LEGEND_MANTISSA = 6;

module.exports = ShomView.build({

  className: 'ocea-select-palette',

  events: {
    'change #ocean-palette-select': '_onPaletteChange',
    'click #automatic-palette-button': '_onAutomaticPaletteButtonClick',
    'click .ocean-palette-on-map-btn': '_onPaletteOnMapClick'
  },

  initialize(options) {
    const optionsToUse = options || {};
    this._pals = optionsToUse.values;
    this._index = optionsToUse.index;
    this._blockTitle = optionsToUse.blockTitle || 'oceano.nav.palette.title';
    this._hidePalette = !!optionsToUse.hidePalette;
    this._config = optionsToUse.config || window.CONFIG;
    this._gisView = optionsToUse.gisView || window.GISVIEW;

    if (!this.model.get('selectedPalette')) {
      const sortedPalNames = OceanoNcwmsUtils.sortPalettes(this.model);
      this.model.set('selectedPalette', { name: sortedPalNames[0], isAuto: false });
    }
    this._isAuto = this.model.get('selectedPalette').isAuto;

    this.listenTo(this.model, 'change:showPaletteOnMap', this._onLayerShowPaletteOnMapChanged);
    this.listenTo(this.model, 'change:selectedUnit', this.render);

    this.callbackMoveEnd = this._gisView.listenToMoveEnd(() => { this._uploadAutoMinMax(); });
  },

  _onLayerShowPaletteOnMapChanged(model, value) {
    this.$('.ocean-palette-on-map-btn')[value ? 'addClass' : 'removeClass']('active');
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({
      index: this._index,
      showPaletteOnMap: this.model.get('showPaletteOnMap'),
      hidePalette: this._hidePalette
    }));

    if (this._$selectPalette) {
      this._$selectPalette.off('change');
      this._$btnAutoPalette.off('click');
    }

    // title
    this.$el.find('#ocean-palette-block-title').attr('data-i18n', this._blockTitle).i18n();
    // render palette
    this._$selectPalette = this.$el.find(`#ocean-palette-select-${this._index}`);
    this._$btnAutoPalette = this.$el.find(`#automatic-palette-button-${this._index}`);
    this._$imgPalette = this.$(`#ocean-palette-legend-img-${this._index}`);

    this._$paletteMin = this.$('.legend-value-min');
    this._$paletteMid = this.$('.legend-value-mid');
    this._$paletteMax = this.$('.legend-value-max');

    this._$selectPalette.on('change', _.bind(this._onPaletteChange, this));
    this._$btnAutoPalette.on('click', _.bind(this._onAutomaticPaletteButtonClick, this));

    this._renderSelectPalette();

    const selectedPalette = this.model.get('selectedPalette');
    if (this._isAuto = selectedPalette && selectedPalette.isAuto) {
      this._addAutoOption(this.model.get('selectedPalette').name);
      const paletteOnMapView = this._gisView.getPaletteOnMap();
      this.renderPaletteAuto(paletteOnMapView.getPalette());
    } else {
      this._updatePaletteImage();
    }
    return this;
  },

  _renderSelectPalette(previousPalette) {
    let selectPalette = '';
    if (!this._pals) {
      this._$selectPalette.html('');
      return;
    }
    if (!previousPalette) {
      previousPalette = this.model.get('selectedPalette') ? this.model.get('selectedPalette').name : '';
    }

    const sortedPals = OceanoNcwmsUtils.sortPalettes(this.model);
    for (const i in sortedPals) {
      // eslint-disable-next-line no-prototype-builtins
      if (sortedPals.hasOwnProperty(i)) {
        const paletteName = sortedPals[i];
        const { translate } = this._pals[paletteName];
        const translateName = (translate && translate.origin) ? translate.origin[window.portalLang] : paletteName;
        selectPalette += `<option value="${paletteName}"`;
        selectPalette += (previousPalette === paletteName)
          ? 'selected >'
          : ' >';
        selectPalette += `${translateName}</option>`;
      }
    }

    this._$selectPalette.html(selectPalette);
  },

  _renderPaletteOnMap(show) {
    const pal = (show) ? this._currentPalette : null;
    this.trigger('showPaletteOnMap', show);
    this._gisView.setPaletteOnMap(pal, this.model);
  },

  renderPaletteAuto(paletteAuto) {
    this._updatePaletteImage(paletteAuto);
    if (this.model.get('showPaletteOnMap')) {
      this._gisView.setPaletteOnMap(paletteAuto, this.model);
    }
  },

  setValuesAndRender(values, value) {
    this._pals = values;
    const previousValue = value || this.getSelectPalette();
    this._renderSelectPalette(previousValue);
    this._display();
    return this;
  },

  _hide() {
    this._$selectPalette.addClass('hidden');
    this._$btnAutoPalette.addClass('hidden');
  },

  _display() {
    this._$selectPalette.removeClass('hidden');
    this._$btnAutoPalette.removeClass('hidden');
  },

  resetAndHide() {
    this._pals = null;
    this._renderSelectPalette();
    this._hide();
    return this;
  },

  /** ***************************** */
  /** ****** GETTER FUNCTION ****** */
  /** ***************************** */

  getSelectPalette() {
    return this.getSelectOption().val();
  },

  getSelectOption() {
    return this.$el.find(`#ocean-palette-select-${this._index} option:selected`);
  },

  getPalette() {
    const value = this.getSelectPalette();
    if (!value) {
      return;
    }
    const isAuto = this._isValueIsAuto(value);
    const paletteName = (isAuto) ? value.split('__')[1] : value;
    return {
      name: paletteName,
      isAuto
    };
  },

  /** ***************************** */
  /** ****** EVENT FUNCTION ******* */
  /** ***************************** */

  _onPaletteOnMapClick() {
    const show = !this.model.get('showPaletteOnMap');
    this._renderPaletteOnMap(show);
  },

  _onPaletteChange() {
    this._removeAutoOption();
    const palette = this.getPalette();
    this.trigger('change:oceaSelectPalette', {
      palette
    });
    this._updatePaletteImage();
    if (this.model.get('showPaletteOnMap')) {
      this._renderPaletteOnMap(true);
    }
  },

  _uploadAutoMinMax() {
    const palette = this.getPalette();
    this.trigger('change:oceaAutoPaletteParameters', palette);
  },

  _updatePaletteImage(pal) {
    if (!pal) {
      const selectPalette = this.getPalette();
      pal = OceanoLayerUtils.getPaletteFromName(this.model, selectPalette.name);
    }
    this._currentPalette = pal;
    const unitDetails = OceanoLayerUtils.getSelectedUnitFromLayer(this.model);

    const ncwmsServiceOptions = { ncwmsLayerType: LayerModelUtils.getNcwmsLayerType(this.model) };
    NCWMS(ncwmsServiceOptions)
      .then(service => service.getPaletteUrl(pal, true))
      .then(url => {
        const lang = window.portalLang;
        this._$imgPalette.css('background-image', `url("${url}")`);
        let mantissa = parseInt((pal.options) ? pal.options.mantissa : undefined, 10);
        let { min } = pal;
        let { max } = pal;
        if (unitDetails.conversion && unitDetails.conversion.offset && unitDetails.conversion.rate) {
          min = OceanoNcwmsUtils.convertUnit(min, unitDetails.conversion.offset, unitDetails.conversion.rate);
          max = OceanoNcwmsUtils.convertUnit(max, unitDetails.conversion.offset, unitDetails.conversion.rate);
        }

        const gap = ((max - min) / 3);
        // If there is no mantissa, adapt mantissa precision
        if (Number.isNaN(mantissa)) {
          mantissa = 0;
          while (mantissa < MAX_LEGEND_MANTISSA && gap.toFixed(mantissa) < 10 ** -(mantissa)) {
            mantissa += 1;
          }
        }

        const mid = ((max + min) / 2);
        this._$paletteMin.html(min.toFixed(mantissa));
        this._$paletteMid.html(mid.toFixed(mantissa));
        this._$paletteMax.html(max.toFixed(mantissa));
        const translation = this.model.get('translation');
        const unitLabel = translation ? translation.units[unitDetails.name][lang] : unitDetails.name;
        this.$('.unit').html(unitLabel || unitDetails.name);
      });
  },

  _onAutomaticPaletteButtonClick() {
    const palette = this.getPalette();
    if (!palette.isAuto) {
      this._addAutoOption(palette.name);
    }

    this.trigger('change:oceaSelectPalette', {
      palette: this.getPalette()
    });
  },

  /** ***************************** */
  /** ****** UTILS FUNCTION ******* */
  /** ***************************** */

  enableSelect(enabled) {
    if (enabled) {
      this._$selectPalette.removeAttr('disabled');
      this._$btnAutoPalette.removeAttr('disabled');
    } else {
      this._$selectPalette.attr('disabled', 'disabled');
      this._$btnAutoPalette.attr('disabled', 'disabled');
    }
  },

  _addAutoOption(paletteName) {
    const lang = window.portalLang;
    const { translate } = this._pals[paletteName];
    let content;
    if (translate && translate.auto) {
      content = translate.auto[lang];
    } else if (translate && translate.origin) {
      content = `${translate.origin[lang]} (AUTO)`;
    } else {
      content = `${paletteName} (AUTO)`;
    }
    const value = `AUTO__${paletteName}`;
    this._$selectPalette.append(
      `<option value="${value}">${content}</option>`
    );
    this._$selectPalette.val(value);
    this.$autoOption = this.getSelectOption();
  },

  _removeAutoOption() {
    if (this.$autoOption) {
      this.$autoOption.remove();
    }
  },

  _isValueIsAuto(value) {
    return (value.lastIndexOf('AUTO', 0) === 0);
  },

  onClose() {
    this._$selectPalette.off('change');
    this._$btnAutoPalette.off('click');
    this.callbackMoveEnd();
  }
});
