module.exports = {
  getQueryParameterValue(url, paramName) {
    if (!paramName) {
      throw 'paramName is required';
    }
    let result;
    const temp = url.split('?');
    if (temp.length > 1) {
      const queryParamsStr = temp[1];
      const queryParams = queryParamsStr.split('&');
      for (let i = 0; i < queryParams.length; i++) {
        const queryParam = queryParams[i].split('=');
        if (queryParam[0] === paramName) {
          result = (queryParam.length > 1 ? queryParam[1] : '');
          break;
        }
      }
    }
    return result;
  },

  getPathUrl(url) {
    return url.split('?')[0];
  },

  // Set value of query param if it exists otherwise add this param to url
  setQueryParameters(url, params) {
    let [baseUrl, urlParams] = url.split('?');
    if (!urlParams) {
      urlParams = '';
    }
    for (const key in params) {
      if (!params.hasOwnProperty(key)) {
        continue;
      }
      const regExp = new RegExp(`(^|&)${key}=([^&]*)`);
      const match = regExp.exec(urlParams);
      if (match === null) {
        // Add param
        if (urlParams !== '') {
          urlParams += '&';
        }
        urlParams += `${key}=${params[key]}`;
      } else {
        // Set param
        urlParams = urlParams.replace(regExp, `$1${key}=${params[key]}`);
      }
    }
    return `${baseUrl}?${encodeURI(urlParams)}`;
  }
};
