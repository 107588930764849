import ContextUrlObserver from '../utils/context-url-observer';

import { CONTEXT_REFMARLAYERS, CONTEXT_FORECASTLAYERS } from '../utils/constants';

const Backbone = require('backbone');
const $ = require('jquery');
const _ = require('underscore');
const Dialog = require('bootstrap-dialog');
const GisView = require('../view/gis.view');
const EventsBus = require('../utils/events-bus.js');

const User = require('../model/user');
const ModalView = require('../view/modal/modal.view');
const StateNotifier = require('../model/state-notifier');
const PoiModeManager = require('../model/gfi-mode-manager');
const KmlProxy = require('../utils/proxy.js');
const LeftMenuRouter = require('./left-menu-router.js');
const TopMenuRouter = require('./top-menu-router');
const ContextRouter = require('./context-router');
const DDMRouter = require('./ddm-router.js');
const DynamicViewsRenderer = require('./dynamic-views-renderer.js');
const StaticViewsRenderer = require('./static-views-renderer.js');
const RoutesSecurityEnforcer = require('../utils/security/routes-security-enforcer.js');

require('../view/gis/gis-view-mixin.js');

module.exports = Backbone.Router.extend({

  initialize(options = {}) {
    this._layers = options.layers;

    window.ROUTER = this;
    window.EVENTBUS = this.eventsBus = EventsBus;
    window.STATE_NOTIFIER = new StateNotifier({
      router: this
    });

    this.user = new User();
    this.user.anonymous();

    this._context = new Backbone.Model({
      isEditor: true,
      cartoMode: false,
      collaborativeMode: false,
      isGuest: false,
      refmarLayers: 0,
      forecastLayers: 0
    });

    window.GISVIEW = this.gisView = new GisView({
      collection: this._layers,
      eventsBus: this.eventsBus
    });

    this.modalView = new ModalView();
    window.MODALVIEW = this.modalView;

    this._routesSecurityEnforcer = options.routesSecurityEnforcer || new RoutesSecurityEnforcer({
      permissionsProvider: this.user
    });
    this._renderer = options.renderer || new DynamicViewsRenderer();
    this._staticViewsRenderer = options.staticViewsRenderer || new StaticViewsRenderer();

    this._leftMenuRouter = options.leftMenuRouter || new LeftMenuRouter({
      dynamicRenderer: this._renderer,
      staticRenderer: this._staticViewsRenderer
    });

    this._contextRouter = options.contextRouter || new ContextRouter({
      dynamicRenderer: this._renderer,
      staticRenderer: this._staticViewsRenderer
    });

    this._topMenuRouter = options.topMenuRouter || new TopMenuRouter({
      dynamicRenderer: this._renderer,
      staticRenderer: this._staticViewsRenderer
    });

    KmlProxy.url = window.CONFIG.kmlProxy;
    KmlProxy.authUrl = window.CONFIG.kmlAuthProxy;

    this._ddmRouter = options.ddmRouter || new DDMRouter({
      staticRender: this._staticViewsRenderer,
      dynamicRenderer: this._renderer
    });

    this._contextUrlObserver = new ContextUrlObserver({});

    window.POI_MODE_MANAGER = new PoiModeManager({
      eventsBus: this.eventsBus,
      gisView: this.gisView
    });

    // on rend les vues statiques avant toute opération dynamique
    this._staticViewsRenderer.renderStaticViews();
  },

  start(callback) {
    this.$modalRoot = $('#app-modal');
    this.modalView.setElement(this.$modalRoot);

    this._contextUrlObserver.startListening();

    callback();
  },

  promptUserForDisconnect() {
    Dialog.show({
      type: Dialog.TYPE_WARNING,
      title: $.i18n.t('error.application.connection_lost.title'),
      message: $.i18n.t('error.application.connection_lost.message'),
      closable: true,
      buttons: [{
        label: $.i18n.t('error.application.connection_lost.button'),
        action: _.bind(dialog => {
          window.location.hash = 'abonne';
          dialog.close();
        }, this)
      }, {
        label: $.i18n.t('menu.button.close'),
        action: _.bind(dialog => {
          dialog.close();
        }, this)
      }]
    });
  },

  downRightContent() {
    this._staticViewsRenderer.downRightContent();
  },

  getLeftMenuRouter() {
    return this._leftMenuRouter;
  },

  updateContext(update) {
    if (typeof (update) === 'object') {
      this._context.set(update);
    }
  },

  addRefmarLayer() {
    const count = this._context.get(CONTEXT_REFMARLAYERS);
    this.updateContext({ refmarLayers: count >= 0 ? count + 1 : 1 });
  },

  subtractRefmarLayer() {
    const count = this._context.get(CONTEXT_REFMARLAYERS);
    this.updateContext({ refmarLayers: count > 0 ? count - 1 : 0 });
  },

  addForecastLayer() {
    const count = this._context.get(CONTEXT_FORECASTLAYERS);
    this.updateContext({ forecastLayers: count >= 0 ? count + 1 : 1 });
  },

  subtractForecastLayer() {
    const count = this._context.get(CONTEXT_FORECASTLAYERS);
    this.updateContext({ forecastLayers: count > 0 ? count - 1 : 0 });
  },

  getContext() {
    return this._context;
  },

  getStaticViewsRenderer() {
    return this._staticViewsRenderer;
  },

  execute(callback, args) {
    if (callback) {
      callback.apply(this, args);
    }
  }
});
