const ShomView = require('../../../core/shom-view');
const template = require('../../../../template/ddm/custom-view/ddm-footer.hbs');

module.exports = ShomView.build({

  initialize(options) {
    this._router = options.router || window.ROUTER;
    this.config = options.config || window.CONFIG;
  },

  render() {
    this.$el.html(template({
      logos_url: this.config.ddm.originator.logo_url
    }));
    return this;
  }
});
