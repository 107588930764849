const $ = require('jquery');
const ShomView = require('../core/shom-view');
const CoordHelper = require('../utils/gis/coordinates-helper.js');
const ToastrUtil = require('../utils/toastr.js');
const coordTemplate = require('../../template/display-coords.hbs');

const COORD_INPUTS_ID = ['coord-x', 'coord-y', 'coord-input-degree', 'coord-input-degree-ms'];

module.exports = ShomView.build({

  className: 'display-coord coord-font',
  events: {
    'keyup #coord-x, #coord-y, #coord-input-degree, #coord-input-degree-ms': 'adjustMapToCoord',
    'keypress #coord-x, #coord-y': 'isNumber',
    'focusout #coord-x, #coord-y, #coord-input-degree, #coord-input-degree-ms': 'adjustMapToCoord',
    'focusin #coord-x, #coord-y, #coord-input-degree, #coord-input-degree-ms': 'saveCoordBeforeEdition'
  },

  initialize(options = {}) {
    this._gisView = options.gisView || window.GISVIEW;
    this.eventsBus = options.eventsBus || window.EVENTBUS;
    this.listenTo(this.eventsBus, 'change:coord', this.display);
    this.coordinateSystem = '';
    this.enterKeyCode = 13;
    this.numKeyCode = [43, 44, 45, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
  },

  render() {
    return this;
  },

  display(event) {
    if (document.activeElement && COORD_INPUTS_ID.includes(document.activeElement.id)) {
      return;
    }
    const { coord } = event;
    coord[0] = CoordHelper.moduloLongitudeMercator(coord[0]);
    this.coordinateSystem = event.coordSyst;
    const output = CoordHelper.convertCoordinates(this.coordinateSystem, coord, false);
    this.$el.html(this.createInput(output, String(this.coordinateSystem)));
  },

  checkCoordinates(coords) {
    if (!Number.isNaN(coords[0]) && !Number.isNaN(coords[1]) && coords[0] !== '' && coords[1] !== '') {
      this._gisView._updateMapCenter(coords);
    } else {
      ToastrUtil.clear();
      ToastrUtil.error($.i18n.t('oceano.toaster.invalidCoordinates'));
    }
  },

  centerLambertOrMercator() {
    const coords = [this.$el.find('#coord-x').val(), this.$el.find('#coord-y').val()];
    this.checkCoordinates(coords);
  },

  centerWgs84Sexa(parameter) {
    let coords = [CoordHelper.parseDMS(parameter[0]), CoordHelper.parseDMS(parameter[1])];
    coords = CoordHelper.convertLonLatToMercat(coords);
    this.checkCoordinates(coords);
  },

  centerWgs84Dms(parameter) {
    let coords = [CoordHelper.parseDM(parameter[0]), CoordHelper.parseDM(parameter[1])];
    coords = CoordHelper.convertLonLatToMercat(coords);
    this.checkCoordinates(coords);
  },

  centerWgs84Dec(parameter) {
    let coords = [CoordHelper.parseD(parameter[0]), CoordHelper.parseD(parameter[1])];
    coords = CoordHelper.convertLonLatToMercat(coords);
    this.checkCoordinates(coords);
  },

  isNumber(event) {
    return this.numKeyCode.includes(event.keyCode);
  },

  displayLambertCoord(coordValue) {
    let coords = coordValue.replace(/ /g, '').replace(/X=/g, '').replace(/Y=/g, '').replace(/m/g, '')
      .split(',');
    coords = [Number(coords[0]), Number(coords[1])];
    return coordTemplate({
      cssCoordXY: 'coord-xy-l93',
      cssCoordInput: 'coord-input-xy-l93',
      cssLabelUnitX: 'coord-input-unit',
      cssLabelUnitY: 'coord-input-unit',
      coordX: coords[0],
      coordY: coords[1],
      sizeX: String(coords[0]).length - 2,
      sizeY: String(coords[1]).length - 2
    });
  },

  displayMercatorCoord(coordValue) {
    let coords = coordValue.replace(/ /g, '').replace(/X=/g, '').replace(/Y=/g, '').replace(/m/g, '')
      .split(',');
    coords = [Number(coords[0]), Number(coords[1])];
    return coordTemplate({
      cssCoordXY: 'coord-xy-mercator',
      cssCoordInput: 'coord-input-xy-mercator',
      cssLabelUnitX: 'coord-input-unit',
      cssLabelUnitY: 'coord-input-unit-y',
      coordX: coords[0],
      coordY: coords[1],
      sizeX: String(coords[0]).length - 2,
      sizeY: String(coords[1]).length - 2
    });
  },

  adjustMapToCoord(event) {
    const valueLength = event.target.value.length;
    event.target.size = (valueLength <= 3) ? 1 : valueLength - 2;
    if (event.keyCode === this.enterKeyCode || event.type === 'focusout') {
      if (this.coordValueBeforeEdition === event.target.value) {
        return;
      }
      const coordinates = event.target.value.replace(/ /g, '').split(',');
      coordinates.reverse();
      switch (this.coordinateSystem) {
        case 'l93':
          this.centerLambertOrMercator();
          break;
        case 'mercat':
          this.centerLambertOrMercator();
          break;
        case 'wgs84.dms':
          this.centerWgs84Dms(coordinates);
          break;
        case 'wgs84.dec':
          this.centerWgs84Dec(coordinates);
          break;
        case 'wgs84.sexa':
        default:
          this.centerWgs84Sexa(coordinates);
          break;
      }
    }
  },

  createInput(coordValue) {
    switch (this.coordinateSystem) {
      case 'l93':
        return this.displayLambertCoord(coordValue);
      case 'mercat':
        return this.displayMercatorCoord(coordValue);
      case 'wgs84.dec':
        return $(`<i class="fas fa-compass compass-coord"></i>&nbsp;<input id="coord-input-degree" type="text" value="${coordValue}"/>`);
      case 'wgs84.dms':
      case 'wgs84.sexa':
      default:
        return $(`<i class="fas fa-compass compass-coord"></i>&nbsp;
                    <label id="coord_label" for="coord-input-degree-ms" data-i18n="display.coordinates.coordinates"></label>
                    <input id="coord-input-degree-ms" type="text" value="${coordValue}"/>`);
    }
  },

  saveCoordBeforeEdition(event) {
    this.coordValueBeforeEdition = event.target.value;
  }
});
