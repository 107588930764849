const Toastr = require('toastr');

module.exports = {
  options: {
    tapToDismiss: true,

    showMethod: 'fadeIn', // fadeIn, slideDown, and show are built into jQuery
    showDuration: 300,
    showEasing: 'swing', // swing and linear are built into jQuery
    hideMethod: 'fadeOut',
    hideDuration: 0,
    hideEasing: 'swing',

    closeButton: true,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-center',
    timeOut: 10000, // Set timeOut and extendedTimeout to 0 to make it sticky
    newestOnTop: true
  },

  info(message, timeout, keepIt, position, options = {}) {
    this.options = { ...this.options, ...options };
    this.options.positionClass = position || 'toast-bottom-center';
    if (timeout === 0) {
      this.options.timeOut = 0;
      this.options.extendedTimeOut = 0;
    } else {
      this.options.timeOut = timeout || 10000;
    }

    if (keepIt) {
      Toastr.info(message, '', this.options);
    } else {
      this._lastToastr = Toastr.info(message, '', this.options);
    }
  },

  error(message, timeout) {
    if (timeout === 0) {
      this.options.timeOut = 0;
      this.options.extendedTimeOut = 0;
    } else {
      this.options.timeOut = timeout || 5000;
    }
    Toastr.error(message, '', this.options);
  },

  clear(clearAll) {
    if (clearAll) {
      Toastr.clear();
    } else {
      Toastr.clear(this._lastToastr);
    }
  }
};
