const moment = require('moment');
const $ = require('jquery');
const _ = require('underscore');
const ShomView = require('../../../core/shom-view');
const template = require('../../../../template/ddm/ddm-dl/ddm-download.hbs');
const DDMFooterView = require('../custom-view/ddm-footer.view.js');
const DDMDLControlView = require('./ddm-dl-control.view.js');
const DDMDLContactView = require('./ddm-dl-contact.view.js');
const DDMDLTableView = require('./ddm-dl-table.view.js');
const DDMUtils = require('../../../utils/ddm/ddm-utils');
const Loading = require('../../../utils/loading');

module.exports = ShomView.build({

  events: {
    'click #ddm-dl .back-btn': '_onBackClick',
    'click #ddm-dl .btn-dl-validate': '_onDownloadClick'
  },

  initialize(options) {
    this._router = options.router || window.ROUTER;
    this._ddmRouter = options.ddmRouter;
    this.config = options.config || window.CONFIG;
    this._sensorId = options.sensorId;
    this.isFullDownload = false;
    this._ddmUtils = new DDMUtils(this.config);
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  _renderFooterView() {
    this._footerView = new DDMFooterView({});
    this._$footerView = this.$el.find('#ddm-footer');
    this._$footerView.html(this._footerView.render().$el);
  },

  _renderDlControlView() {
    this._controlView = new DDMDLControlView({});
    this._$controlView = this.$el.find('#ddm-dl-control');
    this._$controlView.html(this._controlView.render().$el);
  },

  _renderDlContactView() {
    this._contactView = new DDMDLContactView({});
    this._$contactView = this.$el.find('#ddm-dl-contact');
    this._$contactView.html(this._contactView.render().$el);
  },

  _renderDlTableView() {
    this._tableView = new DDMDLTableView({
      ddmRouter: this._ddmRouter,
      sensorId: this._sensorId,
      parentView: this
    });
    this._$tableView = this.$el.find('#ddm-dl-table');
    this._$tableView.html(this._tableView.render().$el);

    this.listenTo(this._tableView, 'change:selectedRows', _.bind(this._onChangeSelectedRows, this));
  },

  render() {
    this.$el.html(template());
    this._renderFooterView();
    this._renderDlControlView();
    this._renderDlContactView();
    this._renderDlTableView();
    return this;
  },

  /** ***************************** */
  /** ****** UTILS FUNCTION ****** */
  /** ***************************** */

  _validate() {
    let isValidated = true;
    let errorsHtml = '';

    const fillErrorsHtml = error => {
      if (error && error !== '') {
        errorsHtml += `${error}<br />`;
        isValidated = false;
      }
    };

    fillErrorsHtml(this._tableView.validate());
    fillErrorsHtml(this._controlView.validate());
    fillErrorsHtml(this._contactView.validate());

    if (!isValidated) {
      this._showAlertMessage(false, errorsHtml);
    }

    return isValidated;
  },

  _launchDownloadObservations() {
    this._hideAlertMessage();

    const $button = this.$el.find('.btn-dl-validate');
    Loading.start($button);

    let tideGaugeIds = this._tableView.getSelectedIds();
    const params = this._controlView.getControls();
    const contact = this._contactView.getContacts();

    if (params.isFullDownload) {
      params.startDate = moment.utc('1700-01-01');
      params.endDate = moment.utc().add(1, 'day');
    }

    tideGaugeIds = tideGaugeIds.join(',');
    const { name } = contact;
    const { mail } = contact;
    const { isContact } = contact;
    const start = params.startDate.toISOString();
    const end = params.endDate.toISOString();
    const { type } = params;

    let sources = [];
    for (const i in params.sources) {
      if (params.sources.hasOwnProperty(i) && params.sources[i]) {
        sources.push(+i + 1);
      }
    }

    sources = sources.join(',');

    this._ddmUtils.getDownloadObservations(tideGaugeIds, sources, start, end, type, name, mail, isContact)
      .then(_.bind(function () {
        this._showAlertMessage(true, $.i18n.t('ddm.dl.alertForm.publish.success'));
      }, this))
      .fail(_.bind(function () {
        this._showAlertMessage(false, $.i18n.t('ddm.dl.alertForm.publish.fail'));
      }, this))
      .always(() => {
        Loading.stop($button);
      });
  },

  _hideAlertMessage() {
    const $container = this.$el.find('#ddm-dl-alert-container');
    $container.html('');
  },

  _showAlertMessage(isSuccess, message) {
    const alertTypeClass = (isSuccess) ? 'alert-success' : 'alert-danger';

    let html = `<div class='alert ${alertTypeClass} alert-dismissible' role='alert'>`;
    html += '<button type=\'button\' class=\'close\' data-dismiss=\'alert\' aria-label=\'Close\'><span aria-hidden=\'true\'>&times;</span></button>';
    html += message;
    html += '</div>';

    const $container = this.$el.find('#ddm-dl-alert-container');
    $container.html(html);
  },

  /** ***************************** */
  /** ****** EVENT FUNCTION ******* */
  /** ***************************** */

  _onBackClick() {
    this._router.navigate(`donnees/refmar/${this._sensorId}`, true);
  },

  _onChangeSelectedRows(selectedRows) {
    if (selectedRows && selectedRows.length === 1) {
      this._controlView.enableAllData();
    } else {
      this._controlView.disableAllData(false);
    }
  },

  _onDownloadClick() {
    if (!this._validate()) {
      return;
    }

    this._contactView.saveContactSS();

    this._launchDownloadObservations();
  },

  _onClose() {

  }

});
