import WMC from '../../mixins/openlayers/wmc';

const compensateForChromeXmlSerializerBug = function (badlySerializedXml) {
  if (badlySerializedXml.indexOf('xlink:href') > -1 && badlySerializedXml.indexOf('xmlns:xlink') === -1) {
    badlySerializedXml = badlySerializedXml.replace(/xlink:href/g, 'xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href');
  }
  return badlySerializedXml;
};

const ContextWriter = function (options) {
  this._format = new WMC({ shomUser: options.user });
};

ContextWriter.prototype.write = function (map) {
  const context = this._format.toContext(map);
  const result = this._format.write(context);
  return compensateForChromeXmlSerializerBug(result);
};

module.exports = ContextWriter;
