import Overlay from 'ol/Overlay';

const $ = require('jquery');
const _ = require('underscore');

const GisView = require('../gis.view.js');

let initialized = false;
let currentClass;

const initialize = function (options) {
  if (initialized) {
    return;
  }
  initialized = true;

  const optionsToUse = options || this._options || {};
  this._overlayJquerySelector = optionsToUse.overlayJquerySelector || '#popup';

  this.$popupContainer = $(this._overlayJquerySelector);

  this._overlay = new Overlay(({
    element: this.$popupContainer.get(0),
    autoPan: true,
    autoPanAnimation: {
      duration: 250
    },
    className: 'shom-overlay-popup'
  }));

  this._map.addOverlay(this._overlay);
};

GisView.prototype.showPopup = function (coordinate, options) {
  const optionsToUse = options || {};
  initialize.call(this);
  this.setPopupContent('');
  if (currentClass) {
    this.$popupContainer.removeClass(currentClass);
    currentClass = null;
  }
  if (optionsToUse.class) {
    currentClass = optionsToUse.class;
    this.$popupContainer.addClass(optionsToUse.class);
  }
  this.$popupContainer.show();
  this._overlay.setPosition(coordinate);
  return _.bind(this.hidePopup, this);
};

GisView.prototype.hidePopup = function () {
  initialize.call(this);
  this.$popupContainer.hide();
  this._overlay.setPosition(undefined);
};

GisView.prototype.setPopupContent = function (content) {
  initialize.call(this);
  const $el = $(this._overlayJquerySelector);
  $el.html(content);
  return $el;
};
