var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ocea-glob-date-div\">\n        <button id=\"ocea-glob-date-btn-previous-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\"\n                type=\"button\"\n                class=\"btn-default btn-previous-time btn-oceano-select-date\">\n                <i class=\"material-icons\">keyboard_arrow_left</i>\n        </button>\n\n        <span id=\"ocea-glob-date-value-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\" ></span>\n\n        <button id=\"ocea-glob-date-btn-next-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\"\n                type=\"button\"\n                class=\"btn-default btn-next-time btn-oceano-select-date\" >\n                <i class=\"material-icons\">keyboard_arrow_right</i>\n        </button>\n</div>\n\n<a id=\"ocea-glob-date-warning-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\" class=\"ocea-glob-date-warning-div\" data-i18n=\"[title]oceano.nav.date.warning\">\n        <i class=\"material-icons\">warning</i>\n</a>";
},"useData":true});