const $ = require('jquery');

const SPECIALS_CHAR = '@#$%&+=';

module.exports = function () {
  return {
    'user-old-password': {
      validators: {
        notEmpty: {
          message: $.t('user.form.password.missing')
        }
      }
    },
    'user-new-password': {
      validators: {
        notEmpty: {
          message: $.t('user.form.password.missing')
        },
        stringLength: {
          min: 8,
          message: $.t('user.form.password.badlength')
        },
        regexp: {
          regexp: new RegExp(`^((?=.*[a-z])(?=.*[A-Z])(?=.*\\d))|((?=.*[a-z])(?=.*[A-Z])(?=.*[${SPECIALS_CHAR}]))`
                             + `|((?=.*[a-z])(?=.*[${SPECIALS_CHAR}])(?=.*\\d))`
                             + `|((?=.*[${SPECIALS_CHAR}])(?=.*[A-Z])(?=.*\\d))`),
          message: $.t('user.form.password.format')
        }
      }
    },
    'user-confirm-password': {
      validators: {
        notEmpty: {
          message: $.t('user.form.password.missing')
        },
        identical: {
          field: 'user-new-password',
          message: $.t('user.form.confirmPass.identical')
        }
      }
    }
  };
};
