const $ = require('jquery');
const ShomView = require('../../core/shom-view');

module.exports = ShomView.build({

  className: 'hitable modal-dialog',

  events: {
    'hide.bs.modal': '_onHide'
  },

  initialize() {

  },

  render() {
    return this;
  },

  show(contentView, options) {
    if (contentView) {
      if (this._contentView) {
        this._contentView.remove();
      }
      this._contentView = contentView;
      this.$el.html(this._contentView.render().$el);
    }
    const optionsToUse = options || {};
    optionsToUse.backdrop = true;
    optionsToUse.show = true;
    this.$el.modal(optionsToUse);
  },

  hide() {
    this.$el.modal('hide');
  },

  showWithoutBackdrop(contentView) {
    this.show(contentView);
    $('.modal-backdrop.in').css('opacity', '0');
  },

  _onHide() {
    this.trigger('hide');
  }
});
