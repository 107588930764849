const ShomView = require('../../core/shom-view');
const template = require('../../../template/ddm/popup-sensor.hbs');
const workingButton = require('./working-button.js');

const AuthZones = require('../../utils/auth-zones.js');

module.exports = ShomView.build({

  className: 'SensorPopup hitable',

  events: {
    'click .more-button': '_onMoreButtonClick',
    'click .download-button': '_onDownloadButtonClick'
  },

  initialize(options) {
    this._router = options.router || window.ROUTER;
    this.config = options.config || window.CONFIG;
    this._gisView = options.gisView || window.GISVIEW;
    this._infoStation = options.info || {};
    this._closeFunction = options.closeFunction;
    this._infoStation.refmarlogo = `${this.config.ddm.originator.logo_url}REFMAR/REFMAR.png`;
    this._sensorSelectEvent = options.sensorSelectEvent || null;
  },

  render() {
    this.$el.html(template(this._infoStation));
    this.$el.find('.headerPopup').prepend(
      new workingButton({ infoStation: this._infoStation }).render().$el.html()
    );

    AuthZones.checkAuth(this.$el);
    return this;
  },

  _onMoreButtonClick() {
    this._closeFunction && this._closeFunction.apply();
    const { id } = this._infoStation;
    this._sensorSelectEvent && this._sensorSelectEvent.getFeatures().clear();
    this._router.navigate(`donnees/refmar/${id}`, true);
  },

  _onDownloadButtonClick() {
    this._closeFunction && this._closeFunction.apply();
    const { id } = this._infoStation;
    this._sensorSelectEvent && this._sensorSelectEvent.getFeatures().clear();
    this._router.navigate(`donnees/refmar/${id}/download`, true);
  },

  destroy() {
  }
});
