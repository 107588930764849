var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "layer-title-container-with-help";
},"3":function(container,depth0,helpers,partials,data) {
    return " layer-included-in-map";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <a href=\"#\" class=\"layer-help-link\" data-i18n=\"[title]header.links.help;[aria-label]header.links.help\"><i class=\"far fa-question-circle\"></i></a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "    <img title=\"Configurer la couche\" src=\"./img/catalog-cog.png\" class=\"oceano-layer-image\"/>\n    <div class=\"oceano-layer-default\">Par défaut : 26/02/2016 15h30</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"add-remove-root\" data-i18n=\"[title]catalog.button.addremove.tooltip\"></div>\n<a href=\"#\" class=\"layer-title-container "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasHelpLinks") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":41},"end":{"line":2,"column":100}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"includedInMap") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":101},"end":{"line":2,"column":152}}})) != null ? stack1 : "")
    + "\"\n     title=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"layer") : depth0)) != null ? lookupProperty(stack1,"abstract") : stack1), depth0))
    + "\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"layerLocalTitle") || (depth0 != null ? lookupProperty(depth0,"layerLocalTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"layerLocalTitle","hash":{},"data":data,"loc":{"start":{"line":3,"column":32},"end":{"line":3,"column":53}}}) : helper)))
    + "<span class=\"description\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"layer") : depth0)) != null ? lookupProperty(stack1,"abstract") : stack1), depth0))
    + "</span></a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasHelpLinks") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":6,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"oceano") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":10,"column":7}}})) != null ? stack1 : "");
},"useData":true});