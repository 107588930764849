const ShomView = require('../../core/shom-view');
const template = require('../../../template/oceano/ocea-layers-popup.hbs');

module.exports = ShomView.build({
  initialize(options) {
    const optionsToUse = options || {};
    this._feature = optionsToUse.feature;
  },

  render() {
    const hydrodyn = this._feature.get('hydrodyn');
    const meteo = this._feature.get('meteo');
    const niveaux = this._feature.get('niveaux');
    const vagues = this._feature.get('vagues');
    const noTheme = !hydrodyn && !meteo && !niveaux && !vagues;

    this.$el.html(template({
      hydrodyn, meteo, niveaux, vagues, noTheme
    }));

    return this;
  }
});
