const ShomView = require('../../core/shom-view');
const ExternalLayerModalView = require('./external-layers.view');
const JsonFileLoader = require('../../utils/json-file-loader.js');

const template = require('../../../template/catalog/partner-import.hbs');

module.exports = ShomView.build({

  events: {
    'click .add-external-layer': '_onAddExternalLayersButtonClick'
  },

  initialize(options = {}) {
    this._config = options.config || window.CONFIG;
    this._modalView = options.modalView || window.MODALVIEW;
    this._externalFeedsUrl = this._config.feeds;
  },

  render() {
    this.$el.html(template({}));
    return this;
  },

  _onAddExternalLayersButtonClick() {
    JsonFileLoader.loadJson(this._externalFeedsUrl)
      .then(data => this._modalView.show(new ExternalLayerModalView({ collection: data.feeds || [] })));
  }
});
