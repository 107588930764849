import { RegularShape, Stroke } from 'ol/style';

const $ = require('jquery');
const colorsUtils = require('../../colors');

// Used to extract size from the KML font info
// Example : '12px Verdana' extract '12'
const fontSizeAndFamilyRegex = /(\d*)px/i;

// Example : 12, 4
const lineDashRegex = /^([0-9]+)\s*[,]\s*([0-9]+)$/i;

const StyleHelper = {
  /**
   * Return the main style for a feature
   *
   * @param {Feature} feature the concerned feature
   * @return {Style} the style
   */
  getStyle(feature) {
    const style = feature.get('realStyle') || feature.getStyle();
    if (typeof style === 'function') {
      const styles = style.call(feature);
      if (Object.prototype.toString.call(styles) === '[object Array]') {
        return styles[0];
      }
    } else if (Object.prototype.toString.call(style) === '[object Array]') {
      return style[0];
    }
    return style;
  },

  setTextStyleExtensions(style) {
    const textStyle = style.getText();
    if (!textStyle) {
      return;
    }

    // Font info
    let extensions = StyleHelper.getTextFontInfo(textStyle);

    // Stroke info
    const textStrokeStyle = textStyle.getStroke();
    if (textStrokeStyle !== null) {
      extensions = $.extend(extensions, StyleHelper.getTextHaloInfo(textStrokeStyle));
    }

    textStyle.setExtensions(extensions);
  },

  getTextFontInfo(textStyle) {
    const info = {};

    const fontString = textStyle.getFont();
    if (fontString) {
      info.fontFamily = fontString;
      const sizeAndFamilyMatch = fontString.match(fontSizeAndFamilyRegex);
      if (sizeAndFamilyMatch && sizeAndFamilyMatch.length === 2) {
        info.fontSize = +sizeAndFamilyMatch[1];
      }

      if (fontString.match(/bold\s/i)) {
        info.bold = 'bold';
      }

      if (fontString.match(/italic\s/i)) {
        info.italic = 'italic';
      }
    }

    return info;
  },

  getTextHaloInfo(textStrokeStyle) {
    const info = {};

    const strokeWidth = textStrokeStyle.getWidth();
    if (strokeWidth) {
      info.haloRadius = strokeWidth;
    }

    const strokeColor = textStrokeStyle.getColor();
    if (strokeColor) {
      const strokeColorString = strokeColor.toString();
      if (colorsUtils.isRgbaColor(strokeColorString)) {
        info.haloColor = colorsUtils.getDecimalColorFromRgbaColor(strokeColorString);
        info.haloOpacity = colorsUtils.getAlphaFromRgbaColor(strokeColorString);
      } else if (colorsUtils.isHexColor(strokeColorString)) {
        info.haloColor = colorsUtils.getDecimalColorFromHexColor(strokeColorString);
        info.haloOpacity = colorsUtils.getAlphaFromHexColor(strokeColorString);
      }
    }

    return info;
  },

  /**
   * Add specifics stroke information to it extensions
   *
   * @param style the style containing the stroke
   */
  setStrokeExtensions(style) {
    const stroke = style.getStroke();
    if (stroke === null || stroke.getLineDash() === null) {
      return;
    }

    const extensions = {
      lineDash: stroke.getLineDash() ? stroke.getLineDash().toString() : undefined
    };

    stroke.setExtensions(extensions);
  },

  /**
   * Set specifics stroke information from it extensions
   *
    * @param style the style containing the stroke
   */
  setStrokeInfoFromExtensions(style) {
    const stroke = style.getStroke();
    const strokeExt = stroke ? stroke.getExtensions() : null;
    if (strokeExt === null || !strokeExt.lineDash) {
      return;
    }

    const lineDash = this.lineDashFromString(strokeExt.lineDash);
    if (lineDash) {
      stroke.setLineDash(lineDash);
    }
  },

  /**
   * Add specifics regular shape information to it extensions
   *
   * @param style the style containing the regular shape
   */
  setRegularShapeExtensions(style) {
    const image = style.getImage();
    if (image === null || !(image instanceof RegularShape)) {
      return;
    }

    const stroke = image.getStroke();

    const extensions = {
      radius: image.getRadius() ? image.getRadius().toString() : undefined,
      points: image.getPoints() ? image.getPoints().toString() : undefined,
      strokeColor: stroke && stroke.getColor() ? colorsUtils.toHexColor(stroke.getColor(), true) : undefined,
      strokeWidth: stroke && stroke.getWidth() ? stroke.getWidth().toString() : undefined,
      lineDash: stroke && stroke.getLineDash() ? stroke.getLineDash().toString() : undefined
    };

    image.setExtensions(extensions);
  },

  /**
   * Set specifics regular shape information from it extensions
   *
   * @param style the style containing the regular shape
   */
  setRegularShapeInfoFromExtensions(style) {
    const image = style.getImage();
    const imageExt = image ? image.getExtensions() : null;
    if (imageExt === null || !(image instanceof RegularShape)) {
      return;
    }

    const lineDash = this.lineDashFromString(imageExt.lineDash);

    style.setImage(
      new RegularShape({
        fill: image.getFill(),
        stroke: new Stroke({
          color: imageExt.strokeColor ? imageExt.strokeColor : image.getStroke().getColor(),
          width: imageExt.strokeWidth ? parseFloat(imageExt.strokeWidth) : image.getStroke().getWidth(),
          lineDash: lineDash || image.getStroke().getLineDash()
        }),
        radius: imageExt.radius ? parseFloat(imageExt.radius) : image.getRadius(),
        points: imageExt.points ? parseFloat(imageExt.points) : image.getPoints(),
        rotation: image.getRotation()
      })
    );
  },

  /**
   * Parse a line dash string into a line dash array
   *
   * @param lineDashString the string to parse
   * @return {number[]|undefined} the line dash array, or undefined if the string is unparseable
   */
  lineDashFromString(lineDashString) {
    const lineDashRegexMatch = lineDashString && lineDashString.match(lineDashRegex);

    return (lineDashRegexMatch && lineDashRegexMatch.length === 3)
      ? [lineDashRegexMatch[1], lineDashRegexMatch[2]]
      : undefined;
  }
};

module.exports = StyleHelper;
