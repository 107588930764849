const _ = require('underscore');
const $ = require('jquery');
const ShomView = require('../core/shom-view');
const ToastrUtil = require('../utils/toastr.js');

module.exports = ShomView.build({

  className: 'scale',

  events: {
    'keyup #scale-input': 'adjustMapToScale',
    'focusout #scale-input': 'adjustMapToScale',
    'keypress #scale-input': 'isNumber'
  },

  initialize(options = {}) {
    this._router = options.router || window.ROUTER;
    this._gisView = options.gisView || window.GISVIEW;
    this.eventsBus = options.eventsBus || window.EVENTBUS;
    this.listenTo(this.eventsBus, 'change:scale', _.bind(this.render, this));
    $('body').on('lang:update', _.bind(this.render, this));
    this.render();
    this.enterKeyCode = 13;
    this.numKeyCode = [43, 44, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
  },

  render() {
    const scale = this._gisView.getScale().toLocaleString(window.portalLang);
    this.$el.html(this.createInput(scale));
    this.translate();
    return this;
  },

  isNumber(event) {
    return this.numKeyCode.includes(event.keyCode);
  },

  adjustMapToScale(event) {
    if (event.keyCode === this.enterKeyCode || event.type === 'focusout') {
      const scale = parseInt(event.target.value.replace(/\D/g, ''), 10);
      if (!Number.isNaN(scale) && scale >= 0) {
        this._gisView._updateMapScale(scale);
      } else {
        ToastrUtil.clear();
        ToastrUtil.error($.i18n.t('oceano.toaster.invalidCoordinates'));
      }
    }
  },

  createInput(scaleValue) {
    return $(`
        <label for="scale-input" data-i18n="scale"></label>
        <input id="scale-input" type="text" value="${scaleValue}"/>
    `);
  }
});
