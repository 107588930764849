import AtlasHelper from '../../utils/atlas/atlas-utils';

import LayerModelUtils from '../../utils/layer-model-utils';

const moment = require('moment');
const $ = require('jquery');

const i18n = require('i18next-client/i18next.commonjs.withJQuery');
const ShomView = require('../../core/shom-view');
const template = require('../../../template/oceano/ocea-timeseries-modal.hbs');
const NCWMS = require('../../service/ncwms');
const CoordsHelper = require('../../utils/gis/coordinates-helper.js');
const OceanoLayerUtils = require('../../utils/oceano/oceano-layer.js');
const OceanoNcwmsUtils = require('../../utils/oceano/oceano-ncwms.js');
const Loading = require('../../utils/loading');
const DDMDataUtils = require('../../utils/ddm/ddm-data-utils');
const ToastrUtil = require('../../utils/toastr.js');
const GlobalDataStore = require('../../core/global-data-store');

const MODAL_MIN_WIDTH = 350;
const MODAL_MIN_HEIGHT = 300;
const INIT_HEIGHT = 415;

module.exports = ShomView.build({

  className: 'hitable modal-dialog gfi-modal-result',

  initialize(options) {
    const optionsToUse = options || {};
    this._config = optionsToUse.config || window.CONFIG;
    this._gisView = optionsToUse.gisView || window.GISVIEW;
    this._title = optionsToUse.title[GlobalDataStore.get('lang')];
    this._coord = optionsToUse.coord;
    this._coordWGS84 = CoordsHelper.convertMercatToLonLatModulo(this._coord);
    this._layer = optionsToUse.layer || null;
    this._isLoading = false;
    this._isRealValueLoading = false;
    this._ddmDataUtils = options.ddmDataUtils || new DDMDataUtils(this._config);
    this._spmDiffFromNearestPmFormat = this._config.tfs.diffFromNearestPmDateTimeFormat;
    this._isAtlas = AtlasHelper.isAtlasLayer(this.model);
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template());

    const oceaLayerUTC = +this._gisView.getGlobalOceanoCurrentUTC() || 0;
    const oceaLayerMoment = this._gisView.getOceanoLayerCurrentMoment(this.model);
    this._selectedDate = OceanoNcwmsUtils.getModalFormattedDate(oceaLayerMoment, oceaLayerUTC);

    this._$realValueFrame = this.$el.find('#ts-real-value');
    this._requestRealValue();
    this._urlTimeseries = this.constructUrlTimeseries(this.model, this._coordWGS84);

    if (this._error && this._error !== '') {
      this.$el.find('#ocea-timeseries-container').attr('data-i18n', this._error);
    } else {
      this.$el.find('#ts-coord-lat-result')
        .text(this._coordWGS84[1] && this._coordWGS84[1].toFixed(4))
        .attr('title', this._coordWGS84[1]);
      this.$el.find('#ts-coord-lng-result')
        .text(this._coordWGS84[0] && this._coordWGS84[0].toFixed(4))
        .attr('title', this._coordWGS84[0]);
      this.$el.find('#ts-date-selected')
        .text(this._selectedDate);
      this._$timeseriesContainer = this.$el.find('#ocea-timeseries-container');
      this.$('#timeseries-frame').attr('src', this._urlTimeseries)
        .on('load', this._stopLoading.bind(this));
      this._startLoading();

      // Loading during exact value computing
      Loading.start(this._$realValueFrame, { overlay: false });
    }

    this.$('#ocea-result-title').text(this._title);

    this.$('#gfi-layer-count').html(`${$.i18n.t('gfiWmtsPoi.popup.interrogation.informations')} ${$.i18n.t('gfiWmtsPoi.popup.interrogation.layerCount.one')}`);

    this.$('.modal-content').draggable({
      handle: '.modal-header'
    }).resizable({
      minHeight: MODAL_MIN_WIDTH,
      minWidth: MODAL_MIN_HEIGHT
    });

    if (this._isAtlas) {
      this._addEventsForAtlas();
    }

    return this;
  },

  _addEventsForAtlas() {
    // modal resize: reload iframe to have nice timeseries display
    this._addModalResize();
  },

  _addModalResize() {
    // modal resize event: reload iframe to have nice timeseries display
    let resizeTimer;
    this.$('.modal-content').on('resize', () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => this._reloadTimeseriesIframe(), 250);
    });
  },

  _reloadTimeseriesIframe(forceReload = true) {
    if (!forceReload) {
      return;
    }
    const $iframe = this.$('#timeseries-frame');
    this._startLoading();
    // force reload to have correct timeseries display
    $iframe.attr('src', $iframe.attr('src'));
  },

  _requestRealValue() {
    if (!this._layer) {
      this._$realValueFrame.attr('data-i18n', 'oceano.toaster.featureinfo.error.no_layer');
      return;
    }

    NCWMS({ ncwmsLayerType: LayerModelUtils.getNcwmsLayerType(this.model) }).then(NCWMSService => {
      const currentBbox = this._gisView.getCurrentViewBounds();
      const mapSize = this._gisView.getMapSize();
      NCWMSService.getFeatureInfo(this._layer, mapSize, currentBbox, this._coord)
        .then(value => this._insertRealValue(value))
        .fail(err => {
          this._$realValueFrame.attr('data-i18n', 'oceano.toaster.featureinfo.not_found_exact');
          console.error('modal timeseries exact value:', err);
        });
    });
  },

  _insertRealValue(value) {
    const lang = window.portalLang;
    const selectedUnit = OceanoLayerUtils.getSelectedUnitFromLayer(this.model);
    const unitLabel = this.model.get('translation').units[selectedUnit.name][lang];

    const conversion = selectedUnit.conversion || null;
    const options = OceanoLayerUtils.getNCWMSOptions(this.model);
    let newValue;
    if (conversion) {
      newValue = OceanoNcwmsUtils.convertUnit(value, conversion.offset, conversion.rate);
    }
    if (options) {
      const { mantissa } = options;
      newValue = (mantissa) ? parseFloat(value).toFixed(mantissa) : value;
    }
    Loading.stop(this._$realValueFrame);
    this._$realValueFrame.html(`${newValue} ${unitLabel}`);
  },

  constructUrlTimeseries(model, coord) {
    const nameLayer = model.get('timeseriesName');
    let date = this._gisView.getOceanoLayerCurrentMoment(this.model).utc().format('YYYY-MM-DD');
    let utc = +this._gisView.getGlobalOceanoCurrentUTC() || 0;
    if (!date) {
      date = moment().utc().format('YYYY-MM-DD');
    }

    if (utc > this._config.oceanogramme.utcLimits.max || utc < this._config.oceanogramme.utcLimits.min) {
      utc = 0;
    }
    const dateNow = moment().utc().startOf('day');
    let deltaDate = moment(date, 'YYYY-MM-DD').diff(dateNow, 'days');
    const { limitAvailableDays } = this._config.oceanogramme;

    if (deltaDate + this._config.oceanogramme.nbDaysTimeseries.long > limitAvailableDays.max + 1) {
      deltaDate = this._config.oceanogramme.nbDaysTimeseries.long - (limitAvailableDays.max + 1);
    }
    let paramUrl = `parameters=${nameLayer}`;
    paramUrl += `&duration=${model.get('isArchive')
      ? this._config.oceanogramme.nbDaysTimeseries.short
      : this._config.oceanogramme.nbDaysTimeseries.long}`;
    paramUrl += `&delta-date=${deltaDate}`;
    paramUrl += `&lat=${coord[1]}&lon=${coord[0]}`;
    paramUrl += `&utc=${utc}`;
    paramUrl += `&lang=${i18n.lng()}`;
    const layerProps = this._layer.getProperties();
    if (layerProps && layerProps.identifier) {
      paramUrl += `&layer=${layerProps.identifier.split('/')[0]}`;
    }
    const isAtlas = AtlasHelper.isAtlasLayer(model);
    paramUrl += `&atlas=${isAtlas}`;
    if (isAtlas) {
      const coeff = model.get('currentCoeff');
      if (coeff) {
        paramUrl += `&coeff=${coeff}`;
      } else {
        console.error(`Not coeff valid value: ${coeff}`);
        ToastrUtil.error($.i18n.t('oceano.toaster.atlas.not_valid_coeff'));
      }
      const currentPm = model.get('currentPm');
      // set start date time for timeseries to nearest PM -6h, then round it to nearest 15 minutes
      const startDatetime = AtlasHelper.nearestMinutes(15, currentPm.clone().subtract(6, 'h'));
      paramUrl += `&startDatetime=${startDatetime.format(this._config.atlas.oceanoCurrentDateTimeFormat)}`;
    }
    return `${this._config.oceanogramme.timeseriesUrl}?${encodeURI(paramUrl)}`;
  },

  _startLoading() {
    if (this._isLoading) {
      return;
    }
    this._isLoading = true;
    Loading.start(this._$timeseriesContainer, { overlay: false });
  },

  _stopLoading() {
    if (!this._isLoading) {
      return;
    }
    this._isLoading = false;
    Loading.stop(this._$timeseriesContainer);
    this.$('#timeseries-frame').width('100%').height(INIT_HEIGHT);
  }
});
