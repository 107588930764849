import {
  CATALOG_DEFAULT_ORDER_INDEX, CATALOG_REFERENCE, SEPARATOR_CATEGORY_LEVEL01, SEPARATOR_CATEGORY_LEVEL02
} from '../../utils/constants';

const _ = require('underscore');
const ShomView = require('../../core/shom-view');
const LayerView = require('./layer.view');
const template = require('../../../template/catalog/subcategory.hbs');

// used to display 2nd level category (aka subcategory) such as: reference#cartographie##raster_marine
module.exports = ShomView.build({

  className: 'panel-default panel-category',

  events: {
    'click .subpanel-heading': '_onHeadingClick'
  },

  initialize(options = {}) {
    this._parentCategoryId = options.parentCategoryId;
    this._category = options.category;
    // keep index in view to sort all views in a category
    this.index = this._category.index || CATALOG_DEFAULT_ORDER_INDEX;
    this._itemViews = [];
    this._collection = options.collection || window.LAYERS;
  },

  render() {
    this.$el.html(template({
      category: this._category.id,
      parentCategoryId: this._parentCategoryId
    }));
    this._$layersRoot = this.$('.category-layers-root');
    this._$collapseLink = this.$('.link-collapse-category');
    this._removeItemViews();
    this._createItemViews();
    this._renderItemViews();
    return this;
  },

  _removeItemViews() {
    _.each(this._itemViews, itemView => itemView.remove());
    this._itemViews = [];
    this._$layersRoot.html('');
  },

  _createItemViews() {
    const layers = this._getLayers();
    _.each(layers, layer => this._itemViews.push(new LayerView({ model: layer })));
  },

  _renderItemViews() {
    this._itemViews.sort((view1, view2) => view1.index - view2.index);
    _.each(this._itemViews, itemView => this._$layersRoot.append(itemView.render().$el));
  },

  _getLayers() {
    const categoryId = CATALOG_REFERENCE + SEPARATOR_CATEGORY_LEVEL01 + this._parentCategoryId + SEPARATOR_CATEGORY_LEVEL02 + this._category.id;
    return this._collection.filter(item => (item.get('category') ? item.get('category').fullpath === categoryId : false));
  },

  _onHeadingClick() {
    const $collapseItem = this.$el.find('.collapse');
    const expanded = $collapseItem.hasClass('collapse in');
    $collapseItem.collapse('toggle');
    const $panelTitle = this.$el.find('.panel-title');
    const $panelTitleLink = this.$el.find('.panel-title > a');
    if (expanded) {
      $panelTitleLink.addClass('collapsed');
      $panelTitle.removeClass('layer-included-in-map');
    } else {
      $panelTitle.addClass('layer-included-in-map');
      $panelTitleLink.removeClass('collapsed');
    }
  },

  postTranslate(lang) {
    const translatedName = this._category.name && this._category.name[lang] ? this._category.name[lang] : this._category.id;
    this._$collapseLink.text(translatedName);
  },

  onClose() {
    this._removeItemViews();
  }
});
