const _ = require('underscore');
const ShomView = require('../../core/shom-view.js');
const template = require('../../../template/drawing/map-properties-modal.hbs');

const FormValidationData = require('../../data/forms/map-properties-form');

module.exports = ShomView.build({

  events: {
    'click .cancel-button': '_onClickCancel'
  },

  initialize(options) {
    const optionsToUse = options || {};
    this.config = optionsToUse.config || window.CONFIG;
    this._parent = optionsToUse.parent;
    this.title = optionsToUse.title;
    this._modalView = optionsToUse.modalView;
    this.saveCallback = optionsToUse.saveCallback;
    this.okButtonLabel = optionsToUse.okButtonLabel || 'drawing.modal.map-properties.validate';
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({
      title: this.title,
      okButtonLabel: this.okButtonLabel,
      model: this.model.toJSON()
    }));
    this.$('form').bootstrapValidator({
      submitButtons: 'button.save-map-button',
      fields: FormValidationData(this._parent._maps.models, this.model)
    }).on('success.form.bv', _.bind(this.onJoinFormSubmit, this));
    return this;
  },

  onJoinFormSubmit() {
    this.populateModel(this.model);
    this.saveCallback && this.saveCallback(this.model);
    this._modalView.hide();
    return false;
  },

  _onClickCancel() {
    this._modalView.hide();
  },

  populateModel(model) {
    model.set({
      name: this.$('#map-name').val(),
      title: this.$('#map-title').val(),
      description: this.$('#map-description').val()
    });
  }
});
