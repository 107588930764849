const $ = require('jquery');
const ShomView = require('../../core/shom-view');

const template = require('../../../template/modal/confirm.hbs');

module.exports = ShomView.build({

  events: {
    'click .confirm-button': '_onClickConfirm',
    'click .cancel-button': '_onClickCancel'
  },

  initialize(options) {
    const optionsToUse = options || {};
    this.config = optionsToUse.config || window.CONFIG;
    this._parent = optionsToUse.parent;
    this.title = optionsToUse.title;
    this.textContent = optionsToUse.textContent;
    this._modalView = optionsToUse.modalView;
    this.confirmCallback = optionsToUse.confirmCallback;
    this.cancelCallback = optionsToUse.cancelCallback;
    this.confirmLabel = optionsToUse.confirmLabel || $.i18n.t('confirm-dialog.confirm');
    this.cancelLabel = optionsToUse.cancelLabel || $.i18n.t('confirm-dialog.cancel');
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({
      title: this.title,
      text: this.textContent,
      confirmLabel: this.confirmLabel,
      cancelLabel: this.cancelLabel
    }));
    return this;
  },

  _onClickConfirm() {
    this.confirmCallback && this.confirmCallback();
    this._modalView.hide();
  },

  _onClickCancel() {
    this.cancelCallback && this.cancelCallback();
    this._modalView.hide();
  }
});
