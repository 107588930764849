const _ = require('underscore');

/**
 * This helper provides a more fluent syntax for inline ifs. i.e. if
 * embedded in quoted strings and provide optional else support
 *
 * As with Handlebars 'if', if its first argument returns false, undefined,
 * null or [] (a "falsy" value), '' is returned, otherwise returnValTrue
 * argument is rendered.
 *
 * Usage: class='{{ifinline done "done"}}' or class='{{ifinline done "done" "todo"}}'
 */
module.exports = function (value, returnValTrue, options) {
  let returnValFalse = '';
  if (arguments.length === 4) { returnValFalse = options; }
  return (value && !_.isEmpty(value)) ? returnValTrue : returnValFalse;
};
