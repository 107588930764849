import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import {
  Circle, Fill, Stroke, Style, Text
} from 'ol/style.js';

const CoordsHelper = require('./coordinates-helper');

const DDMLayer = module.exports = function (config) {
  this._config = window.CONFIG || config;

  this.colors = {
    OK: {
      stroke: this._config.strokeColorOk,
      fill: this._config.fillColorOk
    },
    PB: {
      stroke: this._config.strokeColorPb,
      fill: this._config.fillColorPb
    },
    ASYNC: {
      stroke: this._config.strokeColorAsync,
      fill: this._config.fillColorAsync
    },
    KO: {
      stroke: this._config.strokeColorKo,
      fill: this._config.fillColorKo
    },
    OLD: {
      stroke: this._config.strokeColorOld,
      fill: this._config.fillColorOld
    }
  };
};

DDMLayer.prototype.createFeature = function (featureMember) {
  const href = `${this._config.ddm.url_def}featureOfInterest/${featureMember.shom_id}`;
  const longitude = parseFloat(featureMember.longitude);
  const latitude = parseFloat(featureMember.latitude);
  // transform lon/lat to mercator coord
  const mercatCoord = CoordsHelper.convertLonLatToMercat([longitude, latitude]);

  const olFeature = new Feature({
    href,
    state: featureMember.state,
    name: featureMember.name,
    geometry: new Point(mercatCoord)
  });

  const styleFunction = this._createPointStyleFunction();
  olFeature.setStyle(styleFunction);

  return olFeature;
};

DDMLayer.prototype._createPointStyleFunction = function () {
  return (feature, resolution) => {
    const state = feature.get('state');

    const { colors } = this;

    const fill = new Fill({
      color: colors[state].fill
    });

    const stroke = new Stroke({
      color: colors[state].stroke,
      width: 1.25
    });

    const textStyle = this._createTextStyle(feature, resolution);

    const style = new Style({
      image: new Circle({
        fill,
        stroke,
        radius: 5
      }),
      text: textStyle
    });

    return [style];
  };
};

DDMLayer.prototype._createTextStyle = function (feature, resolution) {
  const maxResolution = 1300;
  const text = resolution < maxResolution ? feature.get('name') : '';

  return new Text({
    textAlign: 'center',
    textBaseline: 'middle',
    font: 'bold 10px Arial',
    text,
    fill: new Fill({ color: '#000000' }),
    stroke: new Stroke({ color: '#ffffff', width: 1 }),
    offsetX: 0,
    offsetY: 15,
    rotation: 0
  });
};
