const _ = require('underscore');
const $ = require('jquery');
const moment = require('moment');
const ShomView = require('../../../core/shom-view');
const template = require('../../../../template/ddm/ddm-detail/ddm-data.hbs');
const DDMCharts = require('../../charts/tidegauge-graph.view.js');
const DDMDataControlView = require('./ddm-data-control.view');
const DDMDataUtils = require('../../../utils/ddm/ddm-data-utils');
const DDMUtils = require('../../../utils/ddm/ddm-utils');
const DDMSensorReader = require('../../../utils/ddm/ddm-sensor-reader');
const Loading = require('../../../utils/loading.js');
const Dialog = require('bootstrap-dialog');

module.exports = ShomView.build({

  events: {
    'click #chart-button-nearest': '_nearestData',
    'click #chart-button-reset': '_resetZoom',
    'click #chart-button-refresh': '_refreshData',
    'click #chart-export-main': '_exportMain',
    'click #chart-export-surcote': '_exportSurcote'
  },

  initialize(options) {
    this._router = options.router || window.ROUTER;
    this.config = options.config || window.CONFIG;
    this._modalView = options.modalView || window.MODALVIEW;
    this._colors = this.config.ddm.colors;
    this._utils = new DDMDataUtils(this.config);
    this._defaultPeriod = options.defaultPeriod || this.config.ddm.chart.defaultPeriod;
    this._maxPeriod = options.maxPeriod || this.config.ddm.chart.maxPeriod;
    this._sensorDescription = options.sensorDescription;
    this._defaultDisabledSources = [false, false, false, false, false, false, false, false, false, false];
    this._surcoteSources = ['7', '8'];
    this._obsSources = ['0', '1', '2', '3', '4', '5'];
    this._graphType = {
      MAREGRAPHIE: 'maregraphie',
      SURCOTE: 'surcote'
    };
    this._ddmUtils = new DDMUtils(this.config);
    this._initStats = false;
    this._spmData = null;
    this._observations = null;
    this._preventZoomDispatch = 2;
    this._displayHours = false;
    this.initControls();
    this.initCharts();

    this.listenTo(this, 'change:control', _.bind(this._onControlChange, this));
    this.listenTo(this, 'reset:control', _.bind(this._onResetValues, this));
  },

  _exportMain() {
    return this._export(this._graphType.MAREGRAPHIE);
  },

  _exportSurcote() {
    return this._export(this._graphType.SURCOTE);
  },

  _export(type) {
    const sources = [];
    for (const i in this._selectedSources) {
      // eslint-disable-next-line no-prototype-builtins
      if (this._selectedSources.hasOwnProperty(i) && this._selectedSources[i]) {
        if (this._isSourceRelatedToGraph(type, i)) {
          sources.push(+i + 1);
        }
      }
    }

    if (this._npmaEnabled && type === this._graphType.MAREGRAPHIE) {
      sources.push(11);
    }

    const params = [
      `start=${moment.utc(this._mainChartView._chartsUtils.options.datesExtent[0]).format('YYYY-MM-DD')}`,
      `stop=${moment.utc(this._mainChartView._chartsUtils.options.datesExtent[1]).format('YYYY-MM-DD')}`,
      `sources=${sources}`,
      `verticalref=${this._verticalRef}`,
      `timezone=${+this._timezone.replace(/UTC\+?/, '')}`
    ];
    const url = `${this.config.ddm.exportUrl + type}/${this._sensorDescription.shom_id}/?${params.join('&')}`;
    window.open(url, '_blank');
  },

  _resetZoom() {
    if (this._mainChartView) {
      this._mainChartView._createChart();
    }
    if (this._surcoteChartView) {
      this._surcoteChartView._createChart();
    }
  },

  _refreshData() {
    this._preventZoomDispatch += 2;
    this._mainChartView.setTimeScale();
    this._surcoteChartView.setTimeScaleWithDate(this._mainChartView.getTimeScale());
    this._mainChartView.initData(false);
    this._surcoteChartView.initData(true);
  },

  _fetchSpmData() {
    return this._utils.getSPMData(
      DDMSensorReader.getSPMHarbor(this._sensorDescription),
      moment.utc(this._startDate).subtract(1, 'day').toISOString(),
      moment.utc(this._endDate).add(1, 'day').toISOString(),
      true
    );
  },

  _storeSpmData(spmData) {
    return this._spmData = spmData || [];
  },

  _fetchObservationsData() {
    const nbDays = this._displayHours ? 1 : this._utils.getNbDaysBetweenDates(this._startDate, this._endDate);
    const interval = this._utils.getDataInterval(nbDays);
    return this._ddmUtils.getSimpleObservationsBetweenDates(
      this._sensorDescription.shom_id,
      moment.utc(this._startDate).subtract(this._timezone, 'hours').toISOString(),
      moment.utc(this._endDate).subtract(this._timezone, 'hours').toISOString(),
      this._selectedSources,
      interval
    );
  },

  _storeObservationsData(observations) {
    return this._observations = observations || [];
  },

  _getTideGaugeStatistics() {
    return this._ddmUtils.getTideGaugeStatistics(this._sensorDescription.shom_id);
  },

  _saveTideGaugeStatistics(stats) {
    this._initStats = true;
    const sources = [];
    for (const i in stats) {
      // eslint-disable-next-line no-prototype-builtins
      if (stats.hasOwnProperty(i)) {
        sources.push(stats[i].source);
      }
    }
    this._defaultDisabledSources[0] = sources.indexOf(1) === -1;
    this._defaultDisabledSources[1] = sources.indexOf(2) === -1;
    this._defaultDisabledSources[2] = sources.indexOf(3) === -1;
    this._defaultDisabledSources[3] = sources.indexOf(4) === -1;
    this._defaultDisabledSources[4] = sources.indexOf(5) === -1;
    this._defaultDisabledSources[5] = sources.indexOf(6) === -1;
    this._defaultDisabledSources[8] = sources.indexOf(9) === -1;
    this._defaultDisabledSources[9] = sources.indexOf(10) === -1;

    this.initControls();
    this._updateControls();
  },

  initControls() {
    this._defaultValues();

    this._chartControlsView = new DDMDataControlView({
      parentView: this,
      sensorDesc: this._sensorDescription,
      colors: this._colors,
      timezone: this._timezone,
      disabledSources: this._disabledSources,
      selectedSources: this._selectedSources,
      startDate: this._startDate,
      endDate: this._endDate,
      maxPeriod: this._maxPeriod,
      npmaIsEnabled: this._npmaEnabled,
      verticalRef: DDMSensorReader.getVerticalRef(this._sensorDescription)
    });
  },

  zoomDispatcher() {
    if (this._preventZoomDispatch > 0) {
      return this._preventZoomDispatch -= 1;
    }
    this._refreshData();
  },

  initCharts() {
    if (!this._initStats) {
      return this._getTideGaugeStatistics()
        .then(this._saveTideGaugeStatistics.bind(this))
        .then(this.initCharts.bind(this));
    }
    if (this._spmData === null || this._observations === null) {
      return $.when(this._fetchSpmData(), this._fetchObservationsData())
        .then((spmData, obsData) => {
          this._storeSpmData(spmData);
          this._storeObservationsData(obsData);
        })
        .fail(() => {
          this._renderFetchDataAlert();
          this._storeSpmData([]);
          this._storeObservationsData([]);
        })
        .always(() => {
          this.initCharts();
        });
    }

    this._mainChartView = new DDMCharts({
      tideGaugeId: this._sensorDescription.shom_id,
      tideGauge: this._sensorDescription,
      start: this._startDate,
      stop: this._endDate,
      sources: this._selectedSources,
      type: 'main',
      ddmUtils: this._ddmUtils,
      spmData: this._spmData,
      observations: this._observations,
      enableNpma: this._npmaEnabled,
      exportButton: true,
      zoomDispatcher: this.zoomDispatcher.bind(this)
    });

    this._surcoteChartView = new DDMCharts({
      tideGaugeId: this._sensorDescription.shom_id,
      tideGauge: this._sensorDescription,
      start: this._startDate,
      stop: this._endDate,
      sources: this._selectedSources,
      type: this._graphType.SURCOTE,
      ddmUtils: this._ddmUtils,
      spmData: this._spmData,
      observations: this._observations,
      enableNpma: this._npmaEnabled,
      exportButton: true,
      zoomDispatcher: this.zoomDispatcher.bind(this)
    });

    this.updateViews(false);
  },

  render() {
    this.$el.html(template({}));
    return this;
  },

  updateViews(renderControls) {
    this._$mainChart = this.$el.find('#data-main');
    this._$surcoteChart = this.$el.find('#data-surcote');
    this._$surcoteDisabled = this.$el.find('#surcote-disabled');

    if (this._mainChartView) {
      this._$mainChart.html(this._mainChartView.render().$el);
      Loading.start($(`#${this._mainChartView._containerId}`));
    }

    if (this._surcoteEnabled && this._surcoteChartView) {
      this._$surcoteChart.html(this._surcoteChartView.render().$el);
      Loading.start($(`#${this._surcoteChartView._containerId}`));
    } else if (this._surcoteChartView) {
      this._$surcoteChart.html(this._$surcoteDisabled.html());
    }

    return renderControls ? this._updateControls() : this;
  },

  _updateControls() {
    this._$chartControls = this.$el.find('#data-controls');

    this._$chartControls.html(this._chartControlsView.render().$el);
    $('#sourcenpma').css('color', this.config.ddm.colors.nivMoyen);

    return this;
  },

  _nearestData() {
    const beginChartDate = moment.utc(this._mainChartView._chartsUtils.options.datesExtent[0]).subtract(this._timezone, 'hours').toISOString();
    const endChartDate = moment.utc(this._mainChartView._chartsUtils.options.datesExtent[1]).subtract(this._timezone, 'hours').toISOString();
    this._ddmUtils.getNearestObservationForSrcPeriod(
      this._mainChartView._tideGaugeId,
      beginChartDate,
      endChartDate,
      this._mainChartView._selectedSources
    ).then(
      response => {
        if (response && response.length > 0) {
          const newDate = response[0].time;
          this._updateDateTimePicker(newDate);
        }
      }
    );
  },

  _updateDateTimePicker(newDate) {
    this.$startDate = this.$el.find("input[name='startDate']");
    this.$startDate.data('DateTimePicker').date(moment.utc(newDate));
    this.$startDate.trigger('dp.hide');
  },

  _updateCharts(needToGetData) {
    this.updateViews(false);

    if (needToGetData) {
      return $.when(this._fetchSpmData(), this._fetchObservationsData())
        .done((spmData, obsData) => {
          this._storeSpmData(spmData);
          this._storeObservationsData(obsData);
        })
        .fail(() => {
          this._renderFetchDataAlert();
          this._storeSpmData([]);
          this._storeObservationsData([]);
        })
        .always(() => {
          this._mainChartView.setSpmData(this._spmData);
          this._mainChartView.setObservations(this._observations);
          this._surcoteChartView.setSpmData(this._spmData);
          this._surcoteChartView.setObservations(this._observations);
          this._mainChartView.initData(true);
          if (this._surcoteEnabled) {
            this._surcoteChartView.initData(true);
          }
        });
    }
    this._mainChartView.updateChart(true);
    if (this._surcoteEnabled) {
      this._surcoteChartView.updateChart(true);
    }
  },

  _defaultValues() {
    this._timezone = 'UTC';
    this._disabledSources = this._defaultDisabledSources.slice(0);
    this._selectedSources = [!this._disabledSources[0], false, false, false, false, false, true, true, true, false];
    this._startDate = this._utils.setBeginTime(moment.utc().subtract(this._defaultPeriod - 1, 'days').hour(0), this._timezone);
    this._endDate = this._utils.setEndTime(moment.utc().add(1, 'days').hour(0), this._timezone);
    this._surcoteEnabled = true;
    this._npmaEnabled = false;
    this._verticalRef = 0;
    this._verticalRefChanged = false;
    this._SPMHarbor = DDMSensorReader.getSPMHarbor(this._sensorDescription);
    this._noSPMData = this._SPMHarbor === undefined;
    if (this._noSPMData) {
      this._disabledSources[6] = true;
      this._selectedSources[6] = false;
      this._disabledSources[7] = true;
      this._selectedSources[7] = false;
    }
  },

  _getVerticalRef(verticalRef) {
    const ref = verticalRef.zh_ref !== undefined ? verticalRef.zh_ref : verticalRef;
    return parseFloat((`${ref}`).replace(',', '.'));
  },

  _onResetValues() {
    this._spmData = null;
    this.initControls();
    this._updateChartProperties();
    this.updateViews(true);
    this._updateCharts(true);
  },

  _onControlChange(controls) {
    this._startDate = moment.utc(controls.startDate);
    this._endDate = moment.utc(controls.endDate);
    this._verticalRefChanged = controls.verticalRefChanged;
    this._verticalRef = this._getVerticalRef(controls.verticalRef);
    this._timezone = controls.timezone;
    this._selectedSources = controls.selectedSources;
    this._disabledSources = controls.disabledSources;
    this._surcoteEnabled = this._selectedSources[7] || this._selectedSources[8];
    this._npmaEnabled = controls.npmaEnabled;
    this._displayHours = controls.isDisplayedHours;

    this._updateChartProperties();

    this._updateCharts(controls.needToGetData);
  },

  _updateChartProperties() {
    this._mainChartView.updateStart(this._startDate, this._displayHours);
    this._mainChartView.updateStop(this._endDate, this._displayHours);
    this._mainChartView.updateSources(this._selectedSources);
    this._mainChartView.updateTimezone(+this._timezone.replace(/UTC\+?/, ''));
    this._mainChartView.updateVerticalRef(this._verticalRef);
    this._mainChartView.updateNpma(this._npmaEnabled);
    this._surcoteChartView.updateStart(this._startDate);
    this._surcoteChartView.updateStop(this._endDate);
    this._surcoteChartView.updateSources(this._selectedSources);
    this._surcoteChartView.updateTimezone(+this._timezone.replace(/UTC\+?/, ''));
    this._surcoteChartView.updateVerticalRef(this._verticalRef);
    this._surcoteChartView.updateNoSurgeMessage(this._disabledSources[7]);
  },

  _renderFetchDataAlert() {
    Dialog.show({
      type: Dialog.TYPE_DANGER,
      title: $.i18n.t('error.application.loading.title'),
      message: $.i18n.t('error.application.loading.message')
    });
  },

  _isSourceRelatedToGraph(type, source) {
    if (type === this._graphType.SURCOTE) {
      return this._surcoteSources.includes(source)
                // source 8 = diff between SPM and obs source
                // So if source 8 is checked, we add obs source to be able to calculate values
                || (this._selectedSources[7] === true && this._obsSources.includes(source));
    }
    return !this._surcoteSources.includes(source);
  },

  onClose() {
    this._mainChartView && this._mainChartView.remove();
    this._surcoteChartView && this._surcoteChartView.remove();
    this._chartControlsView && this._chartControlsView.remove();
  }

});
