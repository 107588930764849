import proj4 from 'proj4';
import { get as getProjection, transform } from 'ol/proj.js';

const LIMIT_PROJ_MERCATOR_LON = 20037508.34;

const coordsAreOutOfRelevantBounds = function (lonlat, crs) {
  if (crs === 'l93') {
    /// / Source: http://epsg.io/2154
    return lonlat.lon < -9.86 || lonlat.lon > 10.38 || lonlat.lat < 41.15 || lonlat.lat > 51.56;
  }
  return lonlat.lon < -180 || lonlat.lon > 180 || lonlat.lat < -90 || lonlat.lat > 90;
};

const roundedToNDigits = function (number, n) {
  return parseFloat(number.toFixed(n));
};

const CoordHelper = function () {
};

CoordHelper.dmdRegex = /^(\-?) *([0-9]+)(?:°(?: *([0-9]+)(?:\.([0-9]+))?\'?)?)? *$/;
CoordHelper.directions = ['S', 'W', 'E', 'N'];

CoordHelper.getCoordSystemName = function (coordinateSyst) {
  switch (coordinateSyst) {
    case 'l93':
      return 'Lambert93';
    case 'mercat':
      return 'Mercator';
    case 'wgs84.dec':
    case 'wgs84.dms':
    case 'wgs84.sexa':
    default:
      return 'WGS84';
  }
};

CoordHelper.moduloLongitudeMercator = function (lon) {
  let resLon = ((Math.abs(lon) + LIMIT_PROJ_MERCATOR_LON) % (LIMIT_PROJ_MERCATOR_LON * 2)) - LIMIT_PROJ_MERCATOR_LON;
  if (lon < 0) {
    resLon *= -1;
  }
  return resLon;
};

CoordHelper.moduloBoundsMercator = function (bbox) {
  bbox[0] = CoordHelper.moduloLongitudeMercator(bbox[0]);
  bbox[2] = CoordHelper.moduloLongitudeMercator(bbox[2]);
  return bbox;
};

CoordHelper.convertWgs84DecCoordinates = function (coordSystemName, lat, lon, displayCoordSystemName = true) {
  const hemiLAT = this.getHemi('LAT', lat);
  const hemiLON = this.getHemi('LON', lon);
  let latitude = lat; let
    longitude = lon;
  if (hemiLAT === 'S') {
    latitude *= (-1);
  }
  if (hemiLON === 'W') {
    longitude *= (-1);
  }
  const fixedLonParts = longitude.toFixed(6).split('.');
  const fixedLatParts = latitude.toFixed(6).split('.');
  const [lonIntegerPart, lonFloatPart] = fixedLonParts;
  const [latIntegerPart, latFloatPart] = fixedLatParts;
  const latString = `${pad(latIntegerPart, 1)}.${latFloatPart}° ${hemiLAT}`;
  const lonString = `${pad(lonIntegerPart, 1)}.${lonFloatPart}° ${hemiLON}`;
  return `${displayCoordSystemName ? `${coordSystemName}: ` : ''} ${latString}, ${lonString}`;
};

CoordHelper.convertWgs84DmsCoordinates = function (coordSystemName, lat, lon, displayCoordSystemName = true) {
  const coordsLAT = this.toDegMin(lat);
  const coordsLON = this.toDegMin(lon);
  const coordsLAThemi = this.getHemi('LAT', lat);
  const coordsLONhemi = this.getHemi('LON', lon);
  return `${displayCoordSystemName ? `${coordSystemName}: ` : ''} ${coordsLAT} ${coordsLAThemi}, ${coordsLON} ${coordsLONhemi}`;
};

CoordHelper.convertL93DecCoordinates = function (coordSystemName, lat, lon, displayCoordSystemName = true) {
  const lambert = proj4('EPSG:2154');
  const lambertCoord = proj4(lambert, [lon, lat]);
  const xParts = Math.abs(lambertCoord[0]).toFixed(4).split('.');
  const yParts = Math.abs(lambertCoord[1]).toFixed(4).split('.');
  let xIntegerPart = pad(xParts[0], 8);
  const xFloatPart = xParts[1];
  let yIntegerPart = pad(yParts[0], 8);
  const yFloatPart = yParts[1];
  if (lambertCoord[0] < 0) {
    xIntegerPart = `-${xIntegerPart}`;
  }
  if (lambertCoord[1] < 0) {
    yIntegerPart = `-${yIntegerPart}`;
  }

  return `${displayCoordSystemName ? `${coordSystemName}: X =` : 'X ='} ${xIntegerPart}.${xFloatPart} m, Y = ${yIntegerPart}.${yFloatPart} m`;
};

CoordHelper.convertCoordinates = function (coordinateSyst, mercatorCoord, displayCoordSystemName = true) {
  let output = '';
  const lonlat = this.convertMercatToLonLat(mercatorCoord);

  if (coordsAreOutOfRelevantBounds(lonlat, coordinateSyst)) {
    return '';
  }
  const coordSystemName = CoordHelper.getCoordSystemName(coordinateSyst);
  const [lon, lat] = lonlat;
  const [x, y] = mercatorCoord;
  /* jshint ignore:start */
  switch (coordinateSyst) {
    case 'wgs84.dec':
      output = CoordHelper.convertWgs84DecCoordinates(displayCoordSystemName, lat, lon, displayCoordSystemName);
      break;
    case 'wgs84.dms':
      output = CoordHelper.convertWgs84DmsCoordinates(displayCoordSystemName, lat, lon, displayCoordSystemName);
      break;
    case 'l93':
      output = CoordHelper.convertL93DecCoordinates(displayCoordSystemName, lat, lon, displayCoordSystemName);
      break;
    case 'mercat':
      output = `${(displayCoordSystemName ? (`${coordSystemName}: X = `) : 'X = ') + x} m, Y = ${y} m`;
      break;
    default:
      // case "wgs84.sexa":
      const minSec = this.lonLatToWgs84Sexa(lonlat);
      output = `${(displayCoordSystemName ? (`${coordSystemName}: `) : '') + minSec[1]}, ${minSec[0]}`;
  }
  /* jshint ignore:end */
  return output;
};

CoordHelper.convertLonLatToMercat = function (lonlat) {
  // source:https://github.com/openlayers/openlayers/blob/v5.3.0/src/ol/proj.js#L359
  return transform(lonlat, 'EPSG:4326', 'EPSG:3857');
};

CoordHelper.convertMercatToLonLat = function (mercatorCoord) {
  return transform(mercatorCoord, 'EPSG:3857', 'EPSG:4326');
};

CoordHelper.convertMercatToLonLatModulo = function (mercatorCoord) {
  const coords = transform(mercatorCoord, 'EPSG:3857', 'EPSG:4326');
  coords[0] = (((coords[0] % 360) + 540) % 360) - 180;
  return coords;
};

CoordHelper.convertLonLatBboxToMercator = function (bbox) {
  const lonlatMin = [bbox[0], bbox[1]];
  const lonlatMax = [bbox[2], bbox[3]];
  const mercatMin = this.convertLonLatToMercat(lonlatMin);
  const mercatMax = this.convertLonLatToMercat(lonlatMax);

  return [mercatMin[0], mercatMin[1], mercatMax[0], mercatMax[1]];
};

CoordHelper.parseD = function (input) {
  if (!Number.isNaN(Number(input))) {
    return input;
  }
  const [degree, direction] = input.split('°');
  return this.convertDToDD(degree, direction);
};

CoordHelper.parseDM = function (input) {
  if (!Number.isNaN(Number(input))) {
    return input;
  }
  let [degree, minDirection] = input.split('°');
  minDirection = minDirection.split('\'');
  return this.convertDMToDD(degree, minDirection[0], minDirection[1]);
};

CoordHelper.parseDMS = function (input) {
  if (!Number.isNaN(Number(input))) {
    return input;
  }
  const [degree, minSecDirection] = input.split('°');
  const [min, sec, , direction] = minSecDirection.split('\'');
  return this.convertDMSToDD(degree, min, sec, direction);
};

CoordHelper.checkDirection = function (direction) {
  return CoordHelper.directions.includes(direction);
};

CoordHelper.convertDMSToDD = function (degrees, minutes, seconds, direction) {
  if (!CoordHelper.checkDirection(direction) || degrees.trim() === '' || minutes.trim() === '' || seconds.trim() === '') {
    return NaN;
  }
  let dd = Number(degrees) + Number(minutes) / 60 + Number(seconds) / (60 * 60);
  if (direction === 'S' || direction === 'W') {
    dd *= -1;
  }
  return dd;
};

CoordHelper.convertDMToDD = function (degrees, minutes, direction) {
  if (!CoordHelper.checkDirection(direction) || degrees.trim() === '' || minutes.trim() === '') {
    return NaN;
  }
  let dd = Number(degrees) + Number(minutes) / 60;
  if (direction === 'S' || direction === 'W') {
    dd *= -1;
  }
  return dd;
};

CoordHelper.convertDToDD = function (degrees, direction) {
  if (!CoordHelper.checkDirection(direction) || degrees.trim() === '') {
    return NaN;
  }
  let dd = Number(degrees);
  if (direction === 'S' || direction === 'W') {
    dd *= -1;
  }
  return dd;
};

CoordHelper.convertMercatorBboxToLonLat = function (bbox) {
  const mercatMin = [bbox[0], bbox[1]];
  const mercatMax = [bbox[2], bbox[3]];
  const lonlatMin = this.convertMercatToLonLat(mercatMin);
  const lonlatMax = this.convertMercatToLonLat(mercatMax);

  return [lonlatMin[0], lonlatMin[1], lonlatMax[0], lonlatMax[1]];
};

CoordHelper.convertGeomLonLatToMercator = function (geom) {
  geom.transform(getProjection('EPSG:4326'), getProjection('EPSG:3857'));
};

CoordHelper.lonLatToWgs84Sexa = function (lonlat) {
  const [lon, lat] = lonlat;
  const coordsLAT = this.toMinSec(lat);
  const coordsLON = this.toMinSec(lon);
  const coordsLAThemi = this.getHemi('LAT', lat);
  const coordsLONhemi = this.getHemi('LON', lon);
  const outputLat = `${coordsLAT} ${coordsLAThemi}`;
  const outputLon = `${coordsLON} ${coordsLONhemi}`;

  return [outputLon, outputLat];
};

CoordHelper.toMinSec = function (coord) {
  const abscoordinate = Math.abs(coord);
  let coordinatedegrees = Math.floor(abscoordinate);
  let coordinateminutes = (abscoordinate - coordinatedegrees) / (1 / 60);
  const tempcoordinateminutes = coordinateminutes;
  coordinateminutes = Math.floor(coordinateminutes);
  const coordinateseconds = roundedToNDigits((tempcoordinateminutes - coordinateminutes) / (1 / 60), 2);
  const secondsParts = coordinateseconds.toFixed(2).split('.');
  let integerPart = secondsParts[0];
  const relativePart = secondsParts[1];
  if (+integerPart === 60) {
    integerPart = '00';
    coordinateminutes += 1;
  }
  if (coordinateminutes === 60) {
    coordinateminutes = 0;
    coordinatedegrees += 1;
  }
  return `${pad(coordinatedegrees, 1)}° ${pad(coordinateminutes, 2)}' ${pad(integerPart, 2)}.${relativePart}''`;
};

CoordHelper.toSignedDegMin = function (coord) {
  const unsignedDegMin = this.toDegMin(coord);
  return coord < 0 ? `-${unsignedDegMin}` : unsignedDegMin;
};

CoordHelper.toDegMin = function (coord) {
  const abscoordinate = Math.abs(coord);
  let coordinatedegrees = Math.floor(abscoordinate);
  const coordinateminutes = (abscoordinate - coordinatedegrees) / (1 / 60);
  const coordinateMinutesParts = coordinateminutes.toFixed(4).split('.');
  let integerPart = coordinateMinutesParts[0];
  const relativePart = coordinateMinutesParts[1];
  if (+integerPart === 60) {
    integerPart = '00';
    coordinatedegrees += 1;
  }
  return `${pad(coordinatedegrees, 1)}° ${pad(integerPart, 2)}.${relativePart}'`;
};

CoordHelper.getHemi = function (type, coord) {
  let hemi;
  if (type === 'LAT') {
    if (coord >= 0) {
      hemi = 'N';
    } else {
      hemi = 'S';
    }
  } else if (type === 'LON') {
    if (coord >= 0) {
      hemi = 'E';
    } else {
      hemi = 'W';
    }
  }
  return hemi;
};

CoordHelper.convertDMDToDecimal = function (dmdString, digitsAfterComma = 5) {
  const matches = dmdString.match(CoordHelper.dmdRegex);
  try {
    const sign = matches[1] === '-' ? -1 : 1;
    const degrees = parseInt(matches[2], 10) || 0;
    const minutes = parseInt(matches[3], 10) || 0;
    const decimal = parseFloat(`0.${matches[4]}`) || 0;
    return roundedToNDigits(sign * (degrees + (minutes + decimal) / 60), digitsAfterComma);
  } catch (e) {
    return NaN;
  }
};

function pad(str, max) {
  const string = str.toString();
  return string.length < max ? pad(`0${string}`, max) : string;
}

CoordHelper.isValidDD = function (value, type) {
  return (type === 'latitude' && !coordsAreOutOfRelevantBounds({ lon: 0, lat: value }))
        || (type === 'longitude' && !coordsAreOutOfRelevantBounds({ lon: value, lat: 0 }));
};

CoordHelper.intersectBbox = function (bboxA, bboxB) {
  const [aMinX, aMinY, aMaxX, aMaxY] = bboxA;
  const [bMinX, bMinY, bMaxX, bMaxY] = bboxB;
  if (bMaxX < aMinX || bMaxY < aMinY || aMaxX < bMinX || aMaxY < bMinY) {
    // no intersection here
    return null;
  }
  // take the max of two min
  const resultMinX = (aMinX < bMinX) ? bMinX : aMinX;
  const resultMinY = (aMinY < bMinY) ? bMinY : aMinY;
  // take the min of two max
  const resultMaxX = (aMaxX < bMaxX) ? aMaxX : bMaxX;
  const resultMaxY = (aMaxY < bMaxY) ? aMaxY : bMaxY;

  return [resultMinX, resultMinY, resultMaxX, resultMaxY];
};

CoordHelper.convertIJMercatToLonLat = function (bbox, mapSize, coord) {
  const [width, height] = mapSize;
  const bbox4326 = this.convertMercatorBboxToLonLat(bbox);
  const [bboxX1, bboxY1, bboxX2, bboxY2] = bbox4326;
  const coord4326 = this.convertMercatToLonLat(coord);
  const [X, Y] = coord4326;
  // recalculate the I,J coordinate from a lonLat bbox
  const J = (1 - ((Y - bboxY1) / (bboxY2 - bboxY1))) * height;
  const I = ((X - bboxX1) / (bboxX2 - bboxX1)) * width;

  return [Math.round(I), Math.round(J)];
};
module.exports = CoordHelper;
