var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span> "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"errorMsg") : depth0), depth0))
    + "</span> ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"errorMsg") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":66}}})) != null ? stack1 : "")
    + "\n    <div class=\"col-sm-6\" id=\"ddm-chrono-left-inputs\">\n        <div class=\"row\">\n            <label for=\"chrono-select-start-year\" class=\"col-sm-6 control-label\" data-i18n=\"ddm.chrono.startYear\"></label>\n            <div class=\"col-sm-2 input-cont chrono-year-select\">\n                <select id=\"chrono-select-start-year\" class=\"form-control\"></select>\n            </div>\n        </div>\n        <div class=\"row\">\n            <label for=\"chrono-select-end-year\" class=\"col-sm-6 control-label\" data-i18n=\"ddm.chrono.endYear\"></label>\n            <div class=\"col-sm-2 input-cont chrono-year-select\">\n                <select id=\"chrono-select-end-year\" class=\"form-control\"></select>\n            </div>\n        </div>\n    </div>\n    <div class=\"ddm-chrono-infos\">\n        <span class=\"ddm-chrono-infos-title\" data-i18n=\"ddm.chrono.infosTitle\"></span>\n        <span data-i18n=\"[html]ddm.chrono.infosDescription\"></span>\n    </div>\n</div>\n\n<div class=\"row\">\n    <div id=\"data-chrono\" class=\"chronogram-chart\"></div>\n</div>";
},"useData":true});