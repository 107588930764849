const Chart = require('chart.js');

const { helpers } = Chart;

const defaultConfig = {

  // Number - Number of animation steps
  animationSteps: 5,

  // String - Animation easing effect
  animationEasing: 'linear',

  /// Boolean - Whether grid lines are shown across the chart
  scaleShowGridLines: true,

  // String - Colour of the grid lines
  scaleGridLineColor: 'rgba(0,0,0,.05)',

  // Number - Width of the grid lines
  scaleGridLineWidth: 1,

  // Boolean - Whether to show horizontal lines (except X axis)
  scaleShowHorizontalLines: true,

  // Boolean - Whether to show vertical lines (except Y axis)
  scaleShowVerticalLines: true,

  // Boolean - Whether the line is curved between points
  bezierCurve: false,

  // Number - Tension of the bezier curve between points
  bezierCurveTension: 0.9,

  // Boolean - Whether to show a dot for each point
  pointDot: true,

  // Number - Radius of each point dot in pixels
  pointDotRadius: 1,

  // Number - Pixel width of point dot stroke
  pointDotStrokeWidth: 1,

  // Number - amount extra to add to the radius to cater for hit detection outside the drawn point
  pointHitDetectionRadius: 0,

  // Boolean - Whether to show a stroke for datasets
  datasetStroke: true,

  // Number - Pixel width of dataset stroke
  datasetStrokeWidth: 2,

  // Boolean - Whether to fill the dataset with a colour
  datasetFill: false,

  // String - A legend template
  legendTemplate: '<ul class="<%=name.toLowerCase()%>-legend"><% for (var i=0; i<datasets.length; i++){%><li><span style="background-color:<%=datasets[i].strokeColor%>"></span><%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>',

  // String - Template string for single tooltips
  tooltipTemplate: '<%if (label){%><%=label%>: <%}%><%= value %> m',

  // String - Template string for single tooltips
  multiTooltipTemplate: '<%= value %> m',

  scaleLabel: ' <%=value%> m'
};

Chart.Type.extend({
  name: 'DDMLine',
  defaults: defaultConfig,
  initialize(data) {
    // Declare the extension of the default point, to cater for the options passed in to the constructor
    this.PointClass = Chart.Point.extend({
      strokeWidth: this.options.pointDotStrokeWidth,
      radius: this.options.pointDotRadius,
      display: this.options.pointDot,
      hitDetectionRadius: this.options.pointHitDetectionRadius,
      ctx: this.chart.ctx,
      inRange(mouseX) {
        return ((mouseX - this.x) ** 2 < (this.radius + this.hitDetectionRadius) ** 2);
      }
    });

    this.datasets = [];

    // Set up tooltip events on the chart
    if (this.options.showTooltips) {
      helpers.bindEvents(this, this.options.tooltipEvents, function (evt) {
        const activePoints = (evt.type !== 'mouseout') ? this.getPointsAtEvent(evt) : [];
        this.eachPoints(point => {
          point.restore(['fillColor', 'strokeColor']);
        });
        helpers.each(activePoints, activePoint => {
          activePoint.fillColor = activePoint.highlightFill;
          activePoint.strokeColor = activePoint.highlightStroke;
        });
        this.showTooltip(activePoints);
      });
    }

    // Iterate through each of the datasets, and build this into a property of the chart
    helpers.each(data.datasets, function (dataset) {
      const datasetObject = {
        label: dataset.label || null,
        fillColor: dataset.fillColor,
        datasetStrokeWidth: dataset.datasetStrokeWidth,
        strokeColor: dataset.strokeColor,
        pointColor: dataset.pointColor,
        pointStrokeColor: dataset.pointStrokeColor,
        noline: dataset.noline,
        dataInt: dataset.dataInt,
        group: dataset.group || 1,
        points: []
      };

      this.datasets.push(datasetObject);

      helpers.each(dataset.data, function (dataPoint, index) {
        // Add a new point for each piece of data, passing any required data to draw.
        datasetObject.points.push(new this.PointClass({
          group: dataset.group,
          value: dataPoint,
          label: data.labelsTooltip[index],
          unit: data.unit,
          datasetLabel: dataset.label,
          strokeColor: dataset.pointStrokeColor,
          datasetStrokeWidth: dataset.datasetStrokeWidth,
          fillColor: dataset.pointColor,
          highlightFill: dataset.pointHighlightFill || dataset.pointColor,
          highlightStroke: dataset.pointHighlightStroke || dataset.pointStrokeColor
        }));
      }, this);

      this.buildScale(data.labels);

      this.eachPoints(function (point, index) {
        helpers.extend(point, {
          x: this.scale.calculateX(index),
          y: this.scale.endPoint
        });
        point.save();
      }, this);
    }, this);

    this.render();
  },
  update() {
    this.scale.update();
    // Reset any highlight colours before updating.
    helpers.each(this.activeElements, activeElement => {
      activeElement.restore(['fillColor', 'strokeColor']);
    });
    this.eachPoints(point => {
      point.save();
    });
    this.render();
  },
  eachPoints(callback) {
    helpers.each(this.datasets, function (dataset) {
      helpers.each(dataset.points, callback, this);
    }, this);
  },
  getPointsAtEvent(e) {
    const pointsArray = [];
    const eventPosition = helpers.getRelativePosition(e);
    helpers.each(this.datasets, dataset => {
      helpers.each(dataset.points, point => {
        if (point.inRange(eventPosition.x, eventPosition.y)) {
          pointsArray.push(point);
        }
      });
    }, this);
    return pointsArray;
  },
  buildScale(labels) {
    const self = this;

    const dataTotal = function () {
      const values = {
        1: [],
        2: []
      };
      self.eachPoints(point => {
        values[point.group].push(point.value);
      });
      return values;
    };
    const dataTotalAllGroup = dataTotal();

    const scaleOptions = {
      templateString: this.options.scaleLabel,
      height: this.chart.height,
      width: this.chart.width,
      ctx: this.chart.ctx,
      textColor: this.options.scaleFontColor,
      fontSize: this.options.scaleFontSize,
      fontStyle: this.options.scaleFontStyle,
      fontFamily: this.options.scaleFontFamily,
      valuesCount: labels.length,
      beginAtZero: this.options.scaleBeginAtZero,
      integersOnly: this.options.scaleIntegersOnly,
      calculateY1Range(currentHeight) {
        // get steps, stepValue, min, max
        const updatedRanges = helpers.calculateScaleRange(
          dataTotalAllGroup[1],
          currentHeight,
          this.fontSize,
          this.beginAtZero,
          this.integersOnly
        );
        const updatedRangesForGroup1 = {};
        updatedRangesForGroup1.steps1 = updatedRanges.steps;
        updatedRangesForGroup1.stepValue1 = updatedRanges.stepValue;
        updatedRangesForGroup1.min1 = updatedRanges.min;
        updatedRangesForGroup1.max1 = updatedRanges.max;
        helpers.extend(this, updatedRangesForGroup1);
      },
      calculateY2Range(currentHeight) {
        // get steps, stepValue, min, max
        const updatedRanges = helpers.calculateScaleRange(
          dataTotalAllGroup[2],
          currentHeight,
          this.fontSize,
          this.beginAtZero,
          this.integersOnly
        );
        const updatedRangesForGroup2 = {};
        updatedRangesForGroup2.steps2 = updatedRanges.steps;
        updatedRangesForGroup2.stepValue2 = updatedRanges.stepValue;
        updatedRangesForGroup2.min2 = updatedRanges.min;
        updatedRangesForGroup2.max2 = updatedRanges.max;
        helpers.extend(this, updatedRangesForGroup2);
      },
      xLabels: labels,
      font: helpers.fontString(this.options.scaleFontSize, this.options.scaleFontStyle, this.options.scaleFontFamily),
      lineWidth: this.options.scaleLineWidth,
      lineColor: this.options.scaleLineColor,
      secondAxisColor: this.options.scaleLineColor,
      showHorizontalLines: this.options.scaleShowHorizontalLines,
      showVerticalLines: this.options.scaleShowVerticalLines,
      gridLineWidth: (this.options.scaleShowGridLines) ? this.options.scaleGridLineWidth : 0,
      gridLineColor: (this.options.scaleShowGridLines) ? this.options.scaleGridLineColor : 'rgba(0,0,0,0)',
      padding: (this.options.showScale) ? 0 : this.options.pointDotRadius + this.options.pointDotStrokeWidth,
      showLabels: this.options.scaleShowLabels,
      display: this.options.showScale
    };

    if (this.options.scaleOverride) {
      helpers.extend(scaleOptions, {
        // calculateY1Range: helpers.noop,
        // calculateY2Range: helpers.noop,
        steps: this.options.scaleSteps,
        stepValue: this.options.scaleStepWidth,
        min: this.options.scaleStartValue,
        max: this.options.scaleStartValue + (this.options.scaleSteps * this.options.scaleStepWidth),
        secondAxisColor: this.options.secondAxisColor
      });
    }

    this.scale = new Chart.DoubleScale(scaleOptions);
  },
  addData(valuesArray, label) {
    // Map the values array for each of the datasets

    helpers.each(valuesArray, function (value, datasetIndex) {
      // Add a new point for each piece of data, passing any required data to draw.
      this.datasets[datasetIndex].points.push(new this.PointClass({
        value,
        label,
        x: this.scale.calculateX(this.scale.valuesCount + 1),
        y: this.scale.endPoint,
        strokeColor: this.datasets[datasetIndex].pointStrokeColor,
        fillColor: this.datasets[datasetIndex].pointColor
      }));
    }, this);

    this.scale.addXLabel(label);
    // Then re-render the chart.
    this.update();
  },
  removeData() {
    this.scale.removeXLabel();
    // Then re-render the chart.
    helpers.each(this.datasets, dataset => {
      dataset.points.shift();
    }, this);
    this.update();
  },
  reflow() {
    const newScaleProps = helpers.extend({
      height: this.chart.height,
      width: this.chart.width
    });
    this.scale.update(newScaleProps);
  },
  draw(ease) {
    const easingDecimal = ease || 1;
    this.clear();

    const { ctx } = this.chart;

    // Some helper methods for getting the next/prev points
    const hasValue = function (item) {
      return item.value !== null;
    };
    const nextPoint = function (point, collection, index) {
      return helpers.findNextWhere(collection, hasValue, index) || point;
    };
    const previousPoint = function (point, collection, index) {
      return helpers.findPreviousWhere(collection, hasValue, index) || point;
    };

    this.scale.draw(easingDecimal);

    helpers.each(this.datasets, function (dataset) {
      const pointsWithValues = helpers.where(dataset.points, hasValue);

      // Transition each point first so that the line and point drawing isn't out of sync
      // We can use this extra loop to calculate the control points of this dataset also in this loop

      helpers.each(dataset.points, function (point, index) {
        if (point.hasValue()) {
          if (point.group === 1) {
            point.transition({
              y: this.scale.calculateY1(point.value),
              x: this.scale.calculateX(index)
            }, easingDecimal);
          } else {
            point.transition({
              y: this.scale.calculateY2(point.value),
              x: this.scale.calculateX(index)
            }, easingDecimal);
          }
        }
      }, this);

      // Control points need to be calculated in a seperate loop, because we need to know the current x/y of the point
      // This would cause issues when there is no animation, because the y of the next point would be 0, so beziers would be skewed
      if (this.options.bezierCurve) {
        helpers.each(pointsWithValues, function (point, index) {
          const tension = (index > 0 && index < pointsWithValues.length - 1) ? this.options.bezierCurveTension : 0;
          point.controlPoints = helpers.splineCurve(
            previousPoint(point, pointsWithValues, index),
            point,
            nextPoint(point, pointsWithValues, index),
            tension
          );

          // Prevent the bezier going outside of the bounds of the graph

          // Cap puter bezier handles to the upper/lower scale bounds
          if (point.controlPoints.outer.y > this.scale.endPoint) {
            point.controlPoints.outer.y = this.scale.endPoint;
          } else if (point.controlPoints.outer.y < this.scale.startPoint) {
            point.controlPoints.outer.y = this.scale.startPoint;
          }

          // Cap inner bezier handles to the upper/lower scale bounds
          if (point.controlPoints.inner.y > this.scale.endPoint) {
            point.controlPoints.inner.y = this.scale.endPoint;
          } else if (point.controlPoints.inner.y < this.scale.startPoint) {
            point.controlPoints.inner.y = this.scale.startPoint;
          }
        }, this);
      }

      // Draw the line between all the points
      if (!dataset.noline) {
        this._drawLine(ctx, dataset);
      }

      if (this.options.datasetFill && pointsWithValues.length > 0) {
        // Round off the line by going to the base of the chart, back to the start, then fill.
        ctx.lineTo(pointsWithValues[pointsWithValues.length - 1].x, this.scale.endPoint);
        ctx.lineTo(pointsWithValues[0].x, this.scale.endPoint);
        ctx.fillStyle = dataset.fillColor;
        ctx.closePath();
        ctx.fill();
      }

      // Now draw the points over the line
      // A little inefficient double looping, but better than the line
      // lagging behind the point positions
      helpers.each(pointsWithValues, point => {
        if (point.hasValue()) {
          point.draw();
        }
      });
    }, this);
  },

  _drawLine(ctx, dataset) {
    ctx.lineWidth = dataset.datasetStrokeWidth || this.options.datasetStrokeWidth;
    ctx.strokeStyle = dataset.strokeColor;
    ctx.beginPath();
    let drawing = true;
    helpers.each(dataset.points, function (point, index) {
      if (point.hasValue()) {
        if (index > 0) {
          drawing = this._drawNext(ctx, dataset, point, index, drawing);
        } else {
          ctx.moveTo(point.x, point.y);
        }
      } else if (index % dataset.dataInt === 0) {
        ctx.stroke();
        drawing = false;
      }
    }, this);
    ctx.stroke();
  },

  _drawNext(ctx, dataset, point, index, drawing) {
    if (this.options.bezierCurve) {
      ctx.bezierCurveTo(
        dataset.points[index - 1].controlPoints.outer.x,
        dataset.points[index - 1].controlPoints.outer.y,
        point.controlPoints.inner.x,
        point.controlPoints.inner.y,
        point.x,
        point.y
      );
    } else {
      if (!drawing) {
        ctx.beginPath();
        drawing = true;
      }
      ctx.lineTo(point.x, point.y);
    }
    return drawing;
  }
});

Chart.DoubleScale = Chart.Element.extend({
  initialize() {
    this.fit();
  },
  buildY1Labels() {
    this.yLabels1 = [];

    const stepDecimalPlaces = helpers.getDecimalPlaces(this.stepValue1);

    for (let i = 0; i <= this.steps1; i++) {
      this.yLabels1.push(helpers.template(this.templateString, { value: (this.min1 + (i * this.stepValue1)).toFixed(stepDecimalPlaces) }));
    }
    this.yLabelWidth = (this.display && this.showLabels) ? helpers.longestText(this.ctx, this.font, this.yLabels1) : 0;
  },
  buildY2Labels() {
    this.yLabels2 = [];

    let stepDecimalPlaces = helpers.getDecimalPlaces(this.stepValue2);
    stepDecimalPlaces = Math.max(stepDecimalPlaces, 2);

    this.stepDecimalPlacesForYLabels2 = stepDecimalPlaces;

    for (let i = 0; i <= this.steps2; i++) {
      this.yLabels2.push(helpers.template(this.templateString, { value: (this.min2 + (i * this.stepValue2)).toFixed(stepDecimalPlaces) }));
    }
  },
  addXLabel(label) {
    this.xLabels.push(label);
    this.valuesCount += 1;
    this.fit();
  },
  removeXLabel() {
    this.xLabels.shift();
    this.valuesCount -= 1;
    this.fit();
  },
  // Fitting loop to rotate x Labels and figure out what fits there, and also calculate how many Y steps to use
  fit() {
    // First we need the width of the yLabels, assuming the xLabels aren't rotated

    // To do that we need the base line at the top and base of the chart, assuming there is no x label rotation
    this.startPoint = (this.display) ? this.fontSize : 0;
    this.endPoint = (this.display) ? this.height - (this.fontSize * 1.5) - 5 : this.height; // -5 to pad labels

    // Apply padding settings to the start and end point.
    this.startPoint += this.padding;
    this.endPoint -= this.padding;

    // Cache the starting height, so can determine if we need to recalculate the scale yAxis
    let cachedHeight = this.endPoint - this.startPoint;
    let cachedYLabelWidth;

    // Build the current yLabels so we have an idea of what size they'll be to start
    /*
     *	This sets what is returned from calculateScaleRange as static properties of this class:
     *
        this.steps;
        this.stepValue;
        this.min;
        this.max;
     *
     */
    this.calculateY1Range(cachedHeight);
    this.calculateY2Range(cachedHeight);

    // With these properties set we can now build the array of yLabels
    // and also the width of the largest yLabel
    this.buildY1Labels();
    this.buildY2Labels();

    this.calculateXLabelRotation();

    while ((cachedHeight > this.endPoint - this.startPoint)) {
      cachedHeight = this.endPoint - this.startPoint;
      cachedYLabelWidth = this.yLabelWidth;

      this.calculateY1Range(cachedHeight);
      this.calculateY2Range(cachedHeight);

      this.buildY1Labels();
      this.buildY2Labels();

      // Only go through the xLabel loop again if the yLabel width has changed
      if (cachedYLabelWidth < this.yLabelWidth) {
        this.calculateXLabelRotation();
      }
    }
  },
  calculateXLabelRotation() {
    // Get the width of each grid by calculating the difference
    // between x offsets between 0 and 1.

    this.ctx.font = this.font;

    const firstWidth = this.ctx.measureText(this.xLabels[0]).width;
    const lastWidth = this.ctx.measureText(this.xLabels[this.xLabels.length - 1]).width;
    let firstRotated;

    this.xScalePaddingRight = lastWidth / 2 + 3;
    this.xScalePaddingLeft = (firstWidth / 2 > this.yLabelWidth + 10) ? firstWidth / 2 : this.yLabelWidth + 10;

    this.xLabelRotation = 0;
    if (this.display) {
      const originalLabelWidth = helpers.longestText(this.ctx, this.font, this.xLabels);
      let cosRotation;
      this.xLabelWidth = originalLabelWidth;
      // Allow 3 pixels x2 padding either side for label readability
      const xGridWidth = Math.floor(this.calculateX(1) - this.calculateX(0)) - 6;

      // Max label rotate should be 90 - also act as a loop counter
      while ((this.xLabelWidth > xGridWidth && this.xLabelRotation === 0) || (this.xLabelWidth > xGridWidth && this.xLabelRotation <= 90 && this.xLabelRotation > 0)) {
        cosRotation = Math.cos(helpers.radians(this.xLabelRotation));

        firstRotated = cosRotation * firstWidth;

        // We're right aligning the text now.
        if (firstRotated + this.fontSize / 2 > this.yLabelWidth + 8) {
          this.xScalePaddingLeft = firstRotated + this.fontSize / 2;
        }
        this.xScalePaddingRight = this.fontSize / 2;

        this.xLabelRotation += 1;
        this.xLabelWidth = cosRotation * originalLabelWidth;
      }
      if (this.xLabelRotation > 0) {
        this.endPoint -= Math.sin(helpers.radians(this.xLabelRotation)) * originalLabelWidth + 3;
      }
    } else {
      this.xLabelWidth = 0;
      this.xScalePaddingRight = this.padding;
      this.xScalePaddingLeft = this.padding;
    }
  },
  // Needs to be overidden in each Chart type
  // Otherwise we need to pass all the data into the scale class
  calculateY1Range: helpers.noop,
  calculateY2Range: helpers.noop,
  drawingArea() {
    return this.startPoint - this.endPoint;
  },
  calculateY1(value) {
    const scalingFactor = this.drawingArea() / (this.min1 - this.max1);
    return this.endPoint - (scalingFactor * (value - this.min1));
  },
  calculateY2(value) {
    const scalingFactor = this.drawingArea() / (this.min2 - this.max2);
    return this.endPoint - (scalingFactor * (value - this.min2));
  },
  calculateX(index) {
    const innerWidth = this.width - (this.xScalePaddingLeft + this.xScalePaddingRight) - (25 + 10 * this.stepDecimalPlacesForYLabels2);
    const valueWidth = innerWidth / (this.valuesCount - ((this.offsetGridLines) ? 0 : 1));
    let valueOffset = (valueWidth * index) + this.xScalePaddingLeft;

    if (this.offsetGridLines) {
      valueOffset += (valueWidth / 2);
    }

    // save innerhtml
    this.innerWidth = this.width - this.xScalePaddingRight - (25 + 10 * this.stepDecimalPlacesForYLabels2);
    return Math.round(valueOffset);
  },
  update(newProps) {
    helpers.extend(this, newProps);
    this.fit();
  },
  draw() {
    const { ctx } = this;
    const y1LabelGap = (this.endPoint - this.startPoint) / this.steps1;
    const y2LabelGap = (this.endPoint - this.startPoint) / this.steps2;
    const xStart = Math.round(this.xScalePaddingLeft);
    if (this.display) {
      ctx.fillStyle = this.textColor;
      ctx.font = this.font;
      helpers.each(this.yLabels1, function (labelString, indexLabel) {
        const yLabelCenter = this.endPoint - (y1LabelGap * indexLabel);
        let linePositionY = Math.round(yLabelCenter);
        let drawHorizontalLine = this.showHorizontalLines;

        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        if (this.showLabels) {
          ctx.fillText(labelString, xStart - 10, yLabelCenter);
        }

        // This is X axis, so draw it
        if (indexLabel === 0 && !drawHorizontalLine) {
          drawHorizontalLine = true;
        }

        if (drawHorizontalLine) {
          ctx.beginPath();
        }

        if (indexLabel > 0) {
          // This is a grid line in the centre, so drop that
          ctx.lineWidth = this.gridLineWidth;
          ctx.strokeStyle = this.gridLineColor;
        } else {
          // This is the first line on the scale
          ctx.lineWidth = this.lineWidth;
          ctx.strokeStyle = this.lineColor;
        }

        linePositionY += helpers.aliasPixel(ctx.lineWidth);

        if (drawHorizontalLine) {
          ctx.moveTo(xStart, linePositionY);
          ctx.lineTo(this.innerWidth, linePositionY);
          ctx.stroke();
          ctx.closePath();
        }

        ctx.lineWidth = this.lineWidth;
        ctx.strokeStyle = this.lineColor;
        ctx.beginPath();
        ctx.moveTo(xStart - 5, linePositionY);
        ctx.lineTo(xStart, linePositionY);
        ctx.stroke();
        ctx.closePath();
      }, this);

      helpers.each(this.yLabels2, function (labelString, indexLabel) {
        const yLabelCenter = this.endPoint - (y2LabelGap * indexLabel);
        let linePositionY = Math.round(yLabelCenter);

        ctx.fillStyle = this.secondAxisColor;
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';

        if (this.showLabels) {
          ctx.fillText(labelString, xStart + this.width - this.xScalePaddingLeft - this.xScalePaddingRight, yLabelCenter);
        }
        ctx.fillStyle = this.textColor;

        const xStartForY2 = this.innerWidth;
        linePositionY += helpers.aliasPixel(ctx.lineWidth);

        ctx.lineWidth = this.lineWidth;
        ctx.strokeStyle = this.lineColor;
        ctx.beginPath();

        const lab = labelString.trim().split(' ')[0].trim();
        const decimal = lab.split('.')[1];
        let zero = '0.00';
        if (decimal.length === 3) {
          zero = '0.000';
        } else if (decimal.length === 4) {
          zero = '0.0000';
        }
        if (lab.length > 2 && lab === zero) {
          ctx.strokeStyle = this.secondAxisColor;
          ctx.setLineDash([5, 2]);
          ctx.lineWidth = this.gridLineWidth;
          ctx.moveTo(xStart, linePositionY);
        } else {
          ctx.setLineDash([]);
          ctx.moveTo(xStartForY2, linePositionY);
        }

        ctx.lineTo(xStartForY2 + 8, linePositionY);
        ctx.stroke();
        ctx.closePath();
      }, this);

      helpers.each(this.xLabels, function (label, indexLabel) {
        const xPos = this.calculateX(indexLabel) + helpers.aliasPixel(this.lineWidth);
        // Check to see if line/bar here and decide where to place the line
        const linePosition = this.calculateX(indexLabel - (this.offsetGridLines ? 0.5 : 0)) + helpers.aliasPixel(this.lineWidth);
        const isRotated = (this.xLabelRotation > 0);
        let drawVerticalLine = this.showVerticalLines;

        // This is Y axis, so draw it
        if (indexLabel === 0 && !drawVerticalLine) {
          drawVerticalLine = true;
        }

        if (drawVerticalLine) {
          ctx.beginPath();
        }

        if (indexLabel > 0) {
          // This is a grid line in the centre, so drop that
          ctx.lineWidth = this.gridLineWidth;
          ctx.strokeStyle = this.gridLineColor;
        } else {
          // This is the first line on the scale
          ctx.lineWidth = this.lineWidth;
          ctx.strokeStyle = this.lineColor;
        }

        if (drawVerticalLine && label !== '') {
          ctx.moveTo(linePosition, this.endPoint);
          ctx.lineTo(linePosition, this.startPoint - 3);
          ctx.stroke();
          ctx.closePath();
        }

        ctx.lineWidth = this.lineWidth;
        ctx.strokeStyle = this.lineColor;

        // Small lines at the bottom of the base grid line
        if (label !== '') {
          ctx.beginPath();
          ctx.moveTo(linePosition, this.endPoint);
          ctx.lineTo(linePosition, this.endPoint + 5);
          ctx.stroke();
          ctx.closePath();
        }

        ctx.save();
        ctx.translate(xPos, (isRotated) ? this.endPoint + 12 : this.endPoint + 8);
        ctx.rotate(helpers.radians(this.xLabelRotation) * -1);
        ctx.font = this.font;
        ctx.textAlign = (isRotated) ? 'right' : 'center';
        ctx.textBaseline = (isRotated) ? 'middle' : 'top';
        ctx.fillText(label, 0, 0);
        ctx.restore();
      }, this);

      // draw last Y axis
      const index = this.xLabels.length;
      const linePos = this.calculateX(index - (this.offsetGridLines ? 0.5 : 0)) + helpers.aliasPixel(this.lineWidth);
      ctx.beginPath();
      ctx.lineWidth = this.lineWidth;
      ctx.strokeStyle = this.lineColor;
      ctx.moveTo(linePos, this.endPoint + 5);
      ctx.lineTo(linePos, this.startPoint - 3);
      ctx.stroke();
      ctx.closePath();
    }
  }

});
