const $ = require('jquery');
const _ = require('underscore');
const ShomView = require('../../core/shom-view');

const OceaTimelineOnMapView = require('./ocea-timeline-on-map.view');
const OceaAnimationOnMapView = require('./ocea-animation-on-map.view');
const OceaPaletteView = require('./ocea-palette.view');
const OceaLegendView = require('./ocea-legend.view');

const template = require('../../../template/oceano/ocea-hud-on-map.hbs');

module.exports = ShomView.build({

  id: 'hud-on-map',

  /** ***************************** */
  /** ****** INIT FUNCTIONS ******* */
  /** ***************************** */

  initialize(options) {
    this._gisview = options.gisview || window.GISVIEW;
    this._layers = options.layers || window.LAYERS;
    this._eventBus = options.eventBus || window.EVENTBUS;

    this._timelineOnMapView = null;
    this._animationOnMapView = null;
    this._paletteOnMapView = null;
    this._needZoomAndPanRestore = false;
    this._legendOnMapView = null;

    this._initSubViews();
    this._initListeners();
    // If an oceano layer is loaded before initListeners
    this._onDisplayedOceanoLayersChange();
  },

  _initSubViews() {
    this._paletteOnMapView = new OceaPaletteView({});
    this._paletteOnMapDispContext = '';

    this._legendOnMapView = new OceaLegendView({});
  },

  _initListeners() {
    this._layers.on('change:includedInMap', _.bind(this._onDisplayedOceanoLayersChange, this));
  },

  /** ***************************** */
  /** ****** RENDER FUNCTIONS ***** */
  /** ***************************** */

  render() {
    this.$el.html(template());

    // template elements
    this._$animationOnMapRoot = this.$el.find('#animation-root');
    this._$timelineOnMapRoot = this.$el.find('#timeline-root');
    this._$paletteOnMapRoot = this.$el.find('#palette-root');
    this._$legendOnMapRoot = this.$el.find('#legends-root');
    this.$leftMenuRoot = $('#left-menu-root');
    this.$logosRoot = $('#originators-root');

    // render permanent elements
    this._$paletteOnMapRoot.html(this._paletteOnMapView.render().$el);
    this._$legendOnMapRoot.html(this._legendOnMapView.render().$el);

    return this;
  },

  setAndRenderPalette(pal, model) {
    this._paletteOnMapView && this._paletteOnMapView.setAndRenderPalette(pal, model);
  },

  _removeAllSubViews() {
    this.stopListening(this._timelineOnMapView);
    this._timelineOnMapView && this._timelineOnMapView.remove();
    this._paletteOnMapView && this._paletteOnMapView.remove();
    this._legendOnMapView && this._legendOnMapView.remove();
  },

  /** ***************************** */
  /** **** HIDE/SHOW FUNCTIONS **** */
  /** ***************************** */

  showTimelineOnMap() {
    this._timelineOnMapView && this._timelineOnMapView.$el.show();
  },

  hideTimelineOnMap() {
    this._timelineOnMapView && this._timelineOnMapView.$el.hide();
  },

  showLegendOnMap() {
    this._legendOnMapView && this._legendOnMapView.$el.show();
  },

  hideLegendOnMap() {
    this._legendOnMapView && this._legendOnMapView.$el.hide();
  },

  /** ****************************** */
  /** ****** GETTER FUNCTIONS ****** */
  /** ****************************** */

  getCurrentUTC() {
    return this._timelineOnMapView.getCurrentUTC();
  },

  getGlobalCurrentMoment() {
    return this._timelineOnMapView.getGlobalCurrentMoment();
  },

  getLayerCurrentMoment(model) {
    return this._timelineOnMapView.getLayerCurrentMoment(model);
  },

  getTimelineView() {
    return this._timelineOnMapView;
  },

  getPaletteOnMapView() {
    return this._paletteOnMapView;
  },

  getLegendOnMapView() {
    return this._legendOnMapView;
  },

  /** ***************************** */
  /** ****** EVENT FUNCTIONS ****** */
  /** ***************************** */

  _onDisplayedOceanoLayersChange() {
    const oceaLayers = this._gisview.getOceanoDisplayedLayers();
    if (oceaLayers.length > 0) {
      if (!this._timelineOnMapView) { // init the timeline if it does not exist
        this._timelineOnMapView = new OceaTimelineOnMapView({
          gisview: this._gisview,
          layers: this._layers,
          // if timeline start on the first available date or on the current date
          isStartingOnTheFirstDate: oceaLayers.every(layer => layer.get('isArchive'))
        });
        this._eventBus.trigger('create:timeline-view', this._timelineOnMapView);
        this.listenTo(this._timelineOnMapView, 'start:animationOnMapView', _.bind(this._onStartAnimationView, this));
        this._$timelineOnMapRoot.html(this._timelineOnMapView.$el);
        this.$logosRoot.addClass('timeline-on-map');
      }
      this._timelineOnMapView.updateTimeline(false);
      this._timelineOnMapView.render();
    } else {
      this._timelineOnMapView && this._timelineOnMapView.remove();
      this._timelineOnMapView = null;

      this.$logosRoot && this.$logosRoot.removeClass('timeline-on-map');
    }
  },

  _onStartAnimationView(options = {}) {
    this._paletteOnMapDispContext = this._paletteOnMapView.getPaletteDisplayContext();
    this._paletteOnMapView.setPaletteDisplayContext('animation');

    if (this._gisview.getDisplayOptions().navigation) {
      this._needZoomAndPanRestore = true;
      this._executeCartoModuleCommands([['zoom.disable'], ['pan.disable']]);
    }

    this.hideTimelineOnMap();
    this.hideLegendOnMap();
    this.$leftMenuRoot.hide();

    this._animationOnMapView = new OceaAnimationOnMapView({
      gisview: this._gisview,
      paletteOnMapView: this._paletteOnMapView,
      currentMoment: this._timelineOnMapView.getGlobalCurrentMoment(),
      currentUTC: this._timelineOnMapView.getCurrentUTC(),
      sortedUniqueMoments: options ? options.sortedUniqueMoments : [],
      momentsStructure: options ? options.momentsStructure : new Map(),
      disabledTimeIntervals: options ? options.disabledTimeIntervals : [],
      oceaLayers: options ? options.oceaLayers : []
    });
    this.listenTo(this._animationOnMapView, 'close:animationOnMapView', _.bind(this._onStopAnimationView, this));
    this._$animationOnMapRoot.html(this._animationOnMapView.render().$el);
  },

  _onStopAnimationView() {
    this.stopListening(this._animationOnMapView);
    this._animationOnMapView.remove();

    this._gisview.startMouseInteractions();

    this.showTimelineOnMap();
    this.showLegendOnMap();
    if (this._needZoomAndPanRestore) {
      this._executeCartoModuleCommands([['zoom.enable'], ['pan.enable']]);
    }
    this._paletteOnMapView.setPaletteDisplayContext(this._paletteOnMapDispContext);
    this.$leftMenuRoot.show();
    this._timelineOnMapView.setCurrentMoment();
  },

  onClose() {
    this._removeAllSubViews();
  }

});
