import { unByKey } from 'ol/Observable.js';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import TileWMS from 'ol/source/TileWMS';
import AtlasHelper from '../../utils/atlas/atlas-utils';
const ShomProxy = require('../../utils/proxy');

const GisView = require('../gis.view.js');
const GfiView = require('../gfi-modal.view.js');
const ToastrUtil = require('../../utils/toastr.js');
const $ = require('jquery');
const _ = require('underscore');
const GfiUtils = require('../../utils/gis/gfi-utils');
const discussStyles = require('../../utils/gis/drawing/discuss-styles.js');

let clickHandler;
let projection;
let poiLayer;
let collection;

GisView.prototype.startGfiMode = function (gfiModeManager, serviceUrl, layer, proj, requestable, options = {}) {
  this.stopGfiMode();
  collection = this.collection || window.LAYERS;
  this._config = options.config || window.CONFIG;
  this._modalView = options._modalView || window.MODALVIEW;
  this._gfiModeManager = gfiModeManager;

  this.featureSource = new VectorSource({
    wrapX: true
  });
  poiLayer = new VectorLayer({
    source: this.featureSource,
    zIndex: 5
  });
  this.addLayer(poiLayer);
  if (this._gfiModeManager) {
    this._gfiModeManager.setActiveLayer(poiLayer);
  }

  projection = proj;

  if (requestable !== null) {
    this._isRequestable = requestable;
  } else {
    this._isRequestable = true;
  }

  clickHandler = this._map.on('singleclick', evt => {
    const displayClickPoint = this._getAllLayerInformations(evt, serviceUrl, gfiModeManager);
    this.deleteGfiMarker();

    if (displayClickPoint) {
      const point = new Feature({
        type: 'icon',
        geometry: new Point(evt.coordinate)
      });
      point.setStyle(
        discussStyles.rawPoint('rgba(255,0,0,0.3)', 'rgba(255,0,0,1)', 2, 5)
      );
      this.featureSource.addFeature(point);
    }
  });
};

GisView.prototype._getAllLayerInformations = function (evt, serviceUrl, gfiModeManager) {
  let layerCount = 1;
  const treatedOceanoLayer = [];
  const layerDataList = [];

  const pixelDataCoord = this._map.getPixelFromCoordinate(evt.coordinate);
  const gfiModal = new GfiView();

  // Test is a feature has been clicked
  if (this._map.hasFeatureAtPixel(pixelDataCoord)) {
    return;
  }

  this._map.forEachLayerAtPixel(pixelDataCoord, currLayer => {
    if (!currLayer.get('identifier')) {
      // ignore technical layer (like the drawing layer)
      return false;
    }

    const catalogLayer = this._getCatalogFromIdentifier(currLayer.get('identifier'), currLayer.get('parentIdentifier'));

    if (!GfiUtils.isDisplayableGfiInfo(currLayer.get('identifier'), catalogLayer)) {
      return false;
    }

    if (catalogLayer.get('layerType') === 'NCWMS') {
      const isRadarHF = GfiUtils.isRadarHF(catalogLayer);
      if(this._isNcwmsLayerDisplayableInGFIModal(catalogLayer, currLayer, evt.coordinate, treatedOceanoLayer, pixelDataCoord)) {
        treatedOceanoLayer.push(catalogLayer);
        const title = AtlasHelper.isAtlasLayer(catalogLayer) ? AtlasHelper.getLayerTitlesAtlas(catalogLayer)
            : this._getOceanoLayerTitle(catalogLayer, currLayer.get('title'));
        layerDataList.push({
          layerType: isRadarHF ? 'radarhf' : 'oceano',
          coord: evt.coordinate,
          layerTitle: title,
          layerNumber: layerCount,
          gfiModal: gfiModal,
          catalogLayer: catalogLayer,
          olLayer: currLayer
        });
        layerCount+=1;
      }
    } else if (catalogLayer.get('gfiResourceWmsV')) {
      const key = catalogLayer.get('isToUser') ? this._router.user.get('sessid') : this._config.defaultKey;
      const serviceUrlWithKey = serviceUrl.replace('{{key}}', key);
      const currentTile = this._generateUrlTile(serviceUrlWithKey, catalogLayer);
      const url = this._getFeatureInfoRequest(evt.coordinate, currentTile);
      const layerLocalTitle = window.portalLang === 'en' && catalogLayer.get('englishName') ? catalogLayer.get('englishName') : currLayer.get('title');
      layerDataList.push({
        layerType: 'reference',
        coord: evt.coordinate,
        layerTitle: layerLocalTitle,
        layerNumber: layerCount,
        gfiModal,
        url
      });
      layerCount+=1;
    } else if (catalogLayer.get('layerType') === 'PARTNER' && catalogLayer.get('queryable')) {
      let url = catalogLayer.get('url');
      if(!url.includes('ogc-proxy')) {
        url = ShomProxy.getProxifiedUrl(catalogLayer.get('url'));
      }
      const title = catalogLayer.get('title');
      const service = catalogLayer.get('service') ? catalogLayer.get('service').replace('OGC:', '') : 'WMS';
      const infoFormat = catalogLayer.get('infoFormat');
      layerDataList.push({
        layerType: 'PARTNER',
        coord: evt.coordinate,
        layerTitle: title,
        layerNumber: layerCount,
        olLayer: currLayer,
        gfiModal,
        url,
        service,
        infoFormat
      });
      layerCount+=1;
    }
  });

  const displayClickPoint = layerDataList.length >= 1;
  if (displayClickPoint) {
    this._modalView.show(gfiModal);
    gfiModal.startLoading();
    gfiModeManager.gfiLayersHandler(layerDataList, gfiModal);
  } else if (this._isRequestable) {
    gfiModal.setNoData(true);
    this._modalView.show(gfiModal);
  }

  if (!this._isRequestable) {
    ToastrUtil.clear();
    ToastrUtil.info($.i18n.t('oceano.toaster.gfiNoValidLayer'));
  }

  return displayClickPoint;
};

/**
 * Check if oceano layer can be added in gfi modal
 * @typedef import('../../model/layer.js') Layer
 * @typedef {import('openlayers').layer} OlLayer
 * @param catalogLayer {Layer}
 * @param layer {OlLayer}
 * @param coordinate {[number, number]}
 * @param treatedOceanoLayer {[Layer]}
 * @param pixel {Pixel}
 * @return {boolean}
 * @private
 */
GisView.prototype._isNcwmsLayerDisplayableInGFIModal = function(catalogLayer, layer, coordinate, treatedOceanoLayer, pixel) {
  const topLayer = this.getTopNcwmsLayerOnCoord(
    coordinate,
    null,
    null,
    catalogLayer,
    pixel
  );
  if (!topLayer) {
    ToastrUtil.error($.i18n.t('oceano.toaster.featureinfo.no_layer'));
    return false;
  }
  return (!treatedOceanoLayer.includes(catalogLayer));
};

GisView.prototype._getCatalogFromIdentifier = function (identifier, parentIdentifier) {

  const filterValue = parentIdentifier ?? identifier;
  const arrayWMSVLayer = collection.models.filter(model => model.attributes.identifier === filterValue
  || model.attributes.deliveryIdentifier === filterValue);
  if (arrayWMSVLayer.length) {
    return arrayWMSVLayer[0];
  }

  return null;
};

GisView.prototype.stopGfiMode = function () {
  this.deleteGfiMarker();
  if (poiLayer) {
    this._map.removeLayer(poiLayer);
  }
  if (this._gfiModeManager) {
    this._gfiModeManager.clearActiveLayer();
  }
  unByKey(clickHandler);
};

GisView.prototype.deleteGfiMarker = function () {
  if (this.featureSource) {
    this.featureSource.clear();
  }
};

GisView.prototype._getOceanoLayerTitle = function (layer, olLayerTitle) {
  return layer.get('translation')[olLayerTitle];
};

GisView.prototype._getFeatureInfoRequest = function (coordinate, layer) {
  const LANG = window.portalLang.toUpperCase();
  const viewResolution = this._map.getView().getResolution();
  return layer.getFeatureInfoUrl(
    coordinate,
    viewResolution,
    projection,
    {
      'INFO_FORMAT': 'text/html',
      'FEATURE_COUNT': '10',
      LANG
    });
};


GisView.prototype._generateUrlTile = function (url, layer) {
  const WMSTile = new TileWMS({
    url,
    crossOrigin: 'anonymous',
    params: {
      LAYERS: layer.get('gfiResourceWmsV'),
      TRANSPARENT: true,
      format: 'image/png'
    }
  });
  return WMSTile;
};

GisView.prototype.setRequestableLayer = function (requestable) {
  this.defaultCursor = requestable ? 'help' : 'auto';
  this._isRequestable = requestable;
};
