const _ = require('underscore');
const ShomView = require('../../core/shom-view');

const OceanoHelper = require('../../utils/gis/oceano-helper.js');
const AtlasHelper = require('../../utils/atlas/atlas-utils.js');
const TransectMenuView = require('./ocea-transect-view');
const OceaFeatureInfoMenuView = require('./ocea-feature-info.view');
const OceaDownloadMenuView = require('./ocea-download.view.js');
const OceaVerticalProfileView = require('./ocea-vertical-profile.view.js');

const template = require('../../../template/oceano/ocea-tools.hbs');

module.exports = ShomView.build({

  initialize(options = {}) {
    this._index = options.index;
    this._parentView = options.parentView;
    this._gisView = options.gisView || window.GISVIEW;
    this._router = options.router || window.ROUTER;
    this._eventsBus = options.eventsBus || this._router.eventsBus;
    this._blockTitle = options.blockTitle || 'oceano.nav.tools.title';

    // 6 states : default / transect / featureInfo / download / profile (only for 3D layer)
    this._currentState = 'default';
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({
      index: this._index
    }));
    this._initTransectButtonView();
    this._initFeatureInfoButtonView();
    if (this._isDownloadable()) {
      this._initDownloadButtonView();
    }
    this._initVerticalProfileButtonView();
    this._initListenner();
    return this;
  },

  _initListenner() {
    this.listenTo(this._transectMenuView, 'change:startTransect', _.bind(this._onStartTransect, this));
    this.listenTo(this._transectMenuView, 'change:stopTransect', _.bind(this._onStopTransect, this));
    this.listenTo(this._featureInfoMenuView, 'change:startFeatureInfo', _.bind(this._onStartFeatureInfo, this));
    this.listenTo(this._featureInfoMenuView, 'change:stopFeatureInfo', _.bind(this._onStopFeatureInfo, this));
    this.listenTo(this._downloadMenuView, 'change:startDownload', _.bind(this._onStartDownload, this));
    this.listenTo(this._downloadMenuView, 'change:stopDownload', _.bind(this._onStopDownload, this));
    if (this._verticalProfileView) {
      this.listenTo(this._verticalProfileView, 'change:startVerticalProfile', _.bind(this._onStartVerticalProfile, this));
      this.listenTo(this._verticalProfileView, 'change:stopVerticalProfile', _.bind(this._onStopVerticalProfile, this));
    }
  },

  /** ***************************** */
  /** ******* INIT FUNCTION ******* */
  /** ***************************** */

  _initTransectButtonView() {
    this._$transectMenuContainer = this.$el.find(`#ocean-transect-menu-${this._index}`);
    this._transectMenuView = new TransectMenuView({
      model: this.model,
      parentView: this,
      index: this._index
    });
    this._$transectMenuContainer.html(this._transectMenuView.render().$el);
  },

  _initFeatureInfoButtonView() {
    this._$featureInfoMenuContainer = this.$el.find(`#ocean-feature-info-menu-${this._index}`);
    this._featureInfoMenuView = new OceaFeatureInfoMenuView({
      model: this.model,
      parentView: this,
      index: this._index
    });
    this._$featureInfoMenuContainer.html(this._featureInfoMenuView.render().$el);
  },

  _initDownloadButtonView() {
    this.$downloadMenuContainer = this.$el.find(`#ocean-download-menu-${this._index}`);
    this._downloadMenuView = new OceaDownloadMenuView({
      model: this.model,
      parentView: this,
      index: this._index
    });
    this.$downloadMenuContainer.html(this._downloadMenuView.render().$el);
  },

  _initVerticalProfileButtonView() {
    // contextual : this button shall appear only for 3D layers.
    if (!OceanoHelper.is3DNcwmsLayer(this.model.get('olLayer'))) {
      return;
    }

    this.$verticalProfileContainer = this.$el.find(`#ocean-verticalProfile-menu-${this._index}`);
    this._verticalProfileView = new OceaVerticalProfileView({
      model: this.model,
      parentView: this,
      index: this._index
    });
    this.$verticalProfileContainer.html(this._verticalProfileView.render().$el);
  },

  /** ***************************** */
  /** ****** EVENT FUNCTION ******* */
  /** ***************************** */

  _onStartTransect() {
    this.changeState('transect');
  },

  _onStopTransect() {
    this.changeState('default');
  },

  _onStartFeatureInfo() {
    this.changeState('featureInfo');
  },

  _onStopFeatureInfo() {
    this.changeState('default');
  },

  _onStartDownload() {
    this.changeState('download');
  },

  _onStopDownload() {
    this.changeState('default');
  },

  _onStartVerticalProfile() {
    this.changeState('verticalProfile');
  },

  _onStopVerticalProfile() {
    this.changeState('default');
  },

  onClose() {
    this.stopCurrentState();

    this._transectMenuView && this._transectMenuView.remove();
    this._featureInfoMenuView && this._featureInfoMenuView.remove();
    this._downloadMenuView && this._downloadMenuView.remove();
    this._verticalProfileView && this._verticalProfileView.remove();
  },

  /** ***************************** */
  /** ****** STATE FUNCTION ******* */
  /** ***************************** */

  changeState(newState) {
    if (this._currentState === newState) {
      return;
    }

    this.stopCurrentState();
    this._notifyStateChange(newState);

    if (newState === 'default') {
      this._parentView._parentView.changeState('featureInfo');
    } else if (newState === 'transect') {
      this._parentView._parentView.changeState('default');
      this._transectMenuView && this._transectMenuView.startTransect();
    } else if (newState === 'featureInfo') {
      this._parentView._parentView.changeState('default');
      this._featureInfoMenuView && this._featureInfoMenuView.startFeatureInfo();
    } else if (newState === 'download') {
      this._parentView._parentView.changeState('default');
      this._downloadMenuView && this._downloadMenuView.startDownload();
    } else if (newState === 'verticalProfile') {
      this._parentView._parentView.changeState('default');
      this._verticalProfileView && this._verticalProfileView.startVerticalProfile();
    } else { // unknown state
      return;
    }

    this._currentState = newState;
  },

  stopCurrentState() {
    if (this._currentState === 'default') {
      return;
    }
    if (this._currentState === 'transect') {
      this._transectMenuView && this._transectMenuView.stopTransect();
    } else if (this._currentState === 'featureInfo') {
      this._featureInfoMenuView && this._featureInfoMenuView.stopFeatureInfo();
    } else if (this._currentState === 'download') {
      this._downloadMenuView && this._downloadMenuView.stopDownload();
    } else if (this._currentState === 'verticalProfile') {
      this._verticalProfileView && this._verticalProfileView.stopVerticalProfile();
    }

    this._currentState = 'default';
  },

  /** ***************************** */
  /** ****** UTILS FUNCTION ******* */
  /** ***************************** */

  _notifyStateChange(newState) {
    let buttonActivated = true;

    // disable layer configuration to prevent errors in some states
    if (newState === 'default') {
      buttonActivated = false;
    }

    this.trigger('change:oceaToolsState', {
      buttonActivated
    });
  },

  getTileGrid() {
    return this._gisView.getNCWMSTileGrid();
  },

  _isDownloadable() {
    return !(this.model.get('isArchive') || AtlasHelper.isAtlasLayer(this.model));
  }
});
