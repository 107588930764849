const Backbone = require('backbone');
const _ = require('underscore');

module.exports = Backbone.Model.extend({
  defaults: {
    includedInMap: false
  },

  initialize(attributes) {
    if (attributes && attributes.olLayer) {
      this.set('opacity', attributes.olLayer.opacity || 1);
      this.set('visibility', attributes.olLayer.visibility || true);
    }

    this.listenTo(this, 'change:opacity', _.bind(this._updateOpacity, this));
    this.listenTo(this, 'change:visibility', _.bind(this._updateVisibility, this));
  },

  _updateOpacity() {
    this.get('olLayer') && this.get('olLayer').setOpacity(this.get('opacity'));
  },

  _updateVisibility() {
    this.get('olLayer') && this.get('olLayer').setVisible(this.get('visibility'));
  }
});
