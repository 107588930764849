const Backbone = require('backbone');
const $ = require('jquery');
const CatalogView = require('../view/catalog/catalog.view.js');
const OceaLegendItemView = require('../view/oceano/ocea-legend-item.view');
const AlertModal = require('../view/modal/alert.view');
const LeftMenuView = require('../view/left-menu.view');

module.exports = Backbone.Router.extend({
  initialize(options = {}) {
    this.route('donnees/catalogue/:tab', '_openCatalog');
    this.route('donnees/catalogue', '_openCatalog');
    this.route('donnees/legend/*identifier', '_openLegend');
    this.route('donnees/oceano-animation?animationDuration=:dur&utcValue=:utc&opacity=:opacity', '_openOceanoAnimation');
    this.route('donnees/contexte/:ctx(/*menu_path)', '_openWithContext');
    this.route('donnees/archive/:ctx', '_openArchiveContext');
    this.route('donnees/oceanographie', '_openOceanographie');

    this._staticRenderer = options.staticRenderer;
    this._dynamicRenderer = options.dynamicRenderer;
    this._layers = options.layers || window.LAYERS;
    this._gisView = options.gisView || window.GISVIEW;
    this._config = options.config || window.CONFIG;

    this._user = options.user || window.ROUTER.user;

    this._animatedOceanoLayer = null;
  },

  _alert(i18nKeyTitle, i18nKeyMessage) {
    const modalView = this._modalView || window.MODALVIEW;
    const content = new AlertModal({
      modalView,
      title: i18nKeyTitle,
      message: i18nKeyMessage,
      hideOKButton: true,
      parent: this
    });
    modalView.show(content);
  },

  _openOceanographie() {
    this.navigate('donnees/catalogue/oceano', true);
  },

  _openCatalog(tab) {
    if (!this._catalogView || this._dynamicRenderer.currentLeftView !== this._catalogView) {
      this._catalogView = new CatalogView();
      this._dynamicRenderer.renderLeftViewWithButtonBar(
        new LeftMenuView({ currentMenuView: this._catalogView }),
        this._catalogView
      );
    }
    if (!tab) {
      tab = this._user.get('lastCatalogRoute');
    }
    this._catalogView.openTab(tab);
  },

  _openLegend(identifier) {
    // here add content to #legends-root
    const legendItemView = new OceaLegendItemView({
      model: this._layers.findWhere({
        identifier
      }),
      backRoute: 'donnees'
    });
    this._dynamicRenderer.renderLegendView(legendItemView);
  },

  _openWithContext(ctx, menu) {
    if (!this._leftMenuView || this._dynamicRenderer.currentLeftView !== this._leftMenuView) {
      this._leftMenuView = new LeftMenuView();
      this._dynamicRenderer.renderLeftView(this._leftMenuView);
    }
    this._leftMenuView.openMenu('donnees');

    const url = window.CONFIG.context.redirect_url.replace('?', ctx);
    $.get(url)
      .done(data => {
        if (!this._gisView.loadContext(data)) {
          this._alert('context.fromUrlError.title', 'context.fromUrlError.message');
        }
        if (typeof menu === 'string') {
          this.navigate(menu, true);
        }
      })
      .fail(err => {
        console.error(err);
        this._alert('context.fromUrlError.title", "context.fromUrlError.message');
      });
  },

  _openArchiveContext(archiveCtxBase64) {
    try {
      if (!this._leftMenuView || this._dynamicRenderer.currentLeftView !== this._leftMenuView) {
        this._leftMenuView = new LeftMenuView();
        this._dynamicRenderer.renderLeftView(this._leftMenuView);
      }
      const archiveCtx = atob(archiveCtxBase64);
      const url = `${this._config.oceano.archive_context}/${archiveCtx}`;
      $.get(url)
        .done(data => {
          if (!this._gisView.loadContext(data, false, false, null)) {
            this._alert('context.fromUrlError.title', 'context.fromUrlError.message');
          }
        })
        .fail(err => {
          console.error(err);
          this._alert('context.fromUrlError.title", "context.fromUrlError.message');
        });
    } catch (e) {
      console.error('Unable to decode base 64 entry : ', archiveCtxBase64);
    }

    this._leftMenuView.openMenu('donnees');
    // Navigate to avoid reloading this url (it append to much layers because of 'context url')
    this.navigate('donnees');
  },

  setAnimatedLayer(layer) {
    this._animatedOceanoLayer = layer;
  },

  getAnimatedLayer() {
    return this._animatedOceanoLayer;
  }

});
