const Backbone = require('backbone');
const $ = require('jquery');
const _ = require('underscore');
const { DATA_SHOM_TITLE } = require('../utils/constants');

const TitleStack = ['DATA.SHOM.FR'];

module.exports = {};

/**
 * # The SHOM View supports the following features :
 *
 * ## hooks
 *  - preRender : called before the render method
 *       useful to prepare data structures
 *  - postRender: called after the render method
 *       useful in an inheritance context to call
 *       stuffs on the created DOM node
 *  - postTranslate: called after the i18n transform
 *       can be used to provide dynamic translations
 *
 *
 * ## i18next
 * i18next data attributes support is automatically
 * called in a postRender hook :
 *  - the i18nAttrs field can be used to provide values
 *    for sprintf formatted translation values
 *  - if you update the i18nAttrs after the first rendering
 *    you can call .translate() to force translating
 */

const ShomView = Backbone.View.extend({

  preRender() {
    document.title = this.getTitleStack();
  },

  i18nAttrs: {},

  translate(props) {
    this.$el.i18n({
      lng: $.i18n.lng(),
      postProcess: 'sprintf',
      sprintf: this.i18nAttrs
    });
    if (this.postTranslate !== undefined) {
      this.postTranslate(props.lang);
    }
    this.addHelpLink();
  },

  postRender() {
    if (!this.skipTranslation) {
      this.translate({ lang: window.portalLang });
      if (!this._boundTranslate) {
        this._boundTranslate = _.bind(this.translate, this);
        $('body').on('lang:update', this._boundTranslate);
      }
    }
  },

  remove() {
    Backbone.View.prototype.remove.apply(this);
    this.unbind();
    if (this.onClose) {
      this.onClose();
    }
    $('body').off('lang:update', this._boundTranslate);
    this.removeTitleFromStack();
  },

  setTitleStack(title) {
    TitleStack.push(title);
  },

  getTitleStack() {
    return TitleStack[TitleStack.length - 1];
  },

  removeTitleFromStack() {
    if (TitleStack[TitleStack.length - 1] !== DATA_SHOM_TITLE) {
      TitleStack.pop();
    }
  },

  addHelpLink() {
    // this gets anchor points defined in current view: anchors
    const anchors = this.$('a[id^="help_"]');
    const helpLinks = window.HELPLINKS;
    if (helpLinks && anchors) {
      anchors.each((i, anchor) => {
        const anchorJQ = this.$(`#${anchor.id}`);
        if (helpLinks[anchor.id] && helpLinks[anchor.id][window.portalLang]) {
          anchor.href = helpLinks[anchor.id][window.portalLang];
          anchorJQ.removeClass('hide-help-link');
        } else {
          anchorJQ.addClass('hide-help-link');
        }
      });
    }
  },

  onLastMenuItemBlur(event, closeButtonId) {
    event.preventDefault();
    $(closeButtonId).focus();
  },

  onCloseMenuButtonClick(event, menuId) {
    event.preventDefault();
    $(menuId).click().focus();
  },


  /**
   * Send an array of command to the carto module
   *
   * @param commands array of array [command, arg1, arg2]
   * @private
   */
  _executeCartoModuleCommands(commands) {
    commands.forEach(
      command => this._gisView && this._gisView.carto && this._gisView.carto.command(...command)
    );
  }
});

module.exports.build = function (overrides) {
  const myView = ShomView.extend(overrides);

  const baseRdr = myView.prototype.render;

  myView.prototype.render = function () {
    this.preRender();
    const chainable = baseRdr.apply(this, arguments);
    this.postRender();
    return chainable;
  };

  return myView;
};

module.exports.extend = Backbone.View.extend;
