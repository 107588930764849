const _ = require('underscore');
const ShomView = require('../../core/shom-view');
const ItemView = require('./external-ogc-layer.view');

module.exports = ShomView.build({

  initialize() {
    this.listenTo(this.collection, 'reset', _.bind(this.render, this));
    this._itemsViews = [];
  },

  render() {
    this._removeItemsViews();
    this._createItemsViews();
    this._renderItemsViews();
    return this;
  },

  _removeItemsViews() {
    _.each(this._itemsViews, itemView => {
      itemView.remove();
    });
    this._itemsViews = [];
  },

  _createItemsViews() {
    this.collection.each(function (layer) {
      this._itemsViews.push(new ItemView({ model: layer }));
    }, this);
  },

  _renderItemsViews() {
    this.$el.empty();
    if (this._itemsViews.length === 0) {
      this.$el.append('<option value="">Aucune couche disponible</option>');
    } else {
      this.$el.removeAttr('disabled');
      _.each(this._itemsViews, function (itemView) {
        this.$el.append(itemView.render().$el);
      }, this);
    }
  }
});
