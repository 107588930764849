const Backbone = require('backbone');
const $ = require('jquery');
const _ = require('underscore');
const CartoDynMap = require('../model/cartodyn-map.js');

module.exports = Backbone.Collection.extend({

  model: CartoDynMap,

  initialize(options = {}) {
    this._user = options.user || window.ROUTER.user;
    this.listenTo(this, 'request', _.bind(() => this.loading = true, this));
    this.listenTo(this, 'sync', _.bind(() => this.loading = false, this));
    this.listenTo(this, 'error', _.bind(() => this.loading = false, this));
  },

  sync(method, model, options) {
    if (method !== 'read') {
      return $.Deferred.reject();
    }

    const xhr = this._user.doReq(_.extend({
      type: 'POST',
      url: this.url
    }, options));

    model.trigger('request', model, xhr, options);
    return xhr;
  },

  parse(response) {
    return response.filter(e => e !== null && e !== undefined);
  }
});
