import ContextUrlObserver from '../../utils/context-url-observer';

const _ = require('underscore');
const ShomView = require('../../core/shom-view');
const CategoryView = require('./category.view');
const CategoryUtils = require('../../utils/category');
const Loading = require('../../utils/loading');

module.exports = ShomView.build({

  className: 'panel-group',

  id: 'categories-accordion',

  initialize(options = {}) {
    this._config = options.config || window.CONFIG;
    this.collection = options.collection || window.LAYERS;
    this.catalog = options.catalog || window.CATALOG;
    this._eventsBus = options.eventsBus || window.EVENTBUS;
    this._contextUrlObserver = new ContextUrlObserver({});
    this._ncwmsAtlasLayersAvailable = !!(options.ncwmsAtlasLayersAvailable || window.ncwmsAtlasLayersAvailable);

    if (!this._ncwmsAtlasLayersAvailable) {
      this.listenTo(this._eventsBus, 'build:atlas', this._loadingNcwmsLayers.bind(this));
    }
  },

  render() {
    if (this._ncwmsAtlasLayersAvailable) {
      this._loadingNcwmsLayers(true);
    } else {
      this._refreshItemViews();
      Loading.start(this.$el);
    }
    return this;
  },

  _loadingNcwmsLayers(ncwmsLayersAvailable) {
    this._refreshItemViews();
    if (!ncwmsLayersAvailable) {
      Loading.stop(this.$el);
    }
    this._eventsBus.off('build:atlas');
  },

  _refreshItemViews() {
    this._removeItemViews();
    this._createItemViews();
    if (this._itemViews.length > 0) {
      this._renderItemViews();
    } else {
      this.$el.html('<div data-i18n="catalog.nodata"></div>');
    }
    Loading.stop(this.$el);
    this._contextUrlObserver.restoreFromHash();
    return this;
  },

  _removeItemViews() {
    _.each(this._itemViews, itemView => itemView.remove());
    this._itemViews = [];
  },

  _createItemViews() {
    if (!this.catalog.categories || !this.catalog.categories.reference) {
      return;
    }
    const preparedCategories = CategoryUtils.prepareCategories(this.catalog.categories.reference);
    _.each(preparedCategories, category => {
      this._itemViews.push(new CategoryView({
        category,
        collection: this.collection
      }));
    });
  },

  _renderItemViews() {
    this._itemViews.sort((view1, view2) => view1.index - view2.index);
    _.each(this._itemViews, itemView => this.$el.append(itemView.render().$el));
  },

  onClose() {
    this._removeItemViews();
  }
});
