const _ = require('underscore');

_.deepObjectExtend = function (destination, source) {
  for (const property in source) {
    if (typeof source[property] === 'object'
     && source[property] !== null) {
      destination[property] = destination[property] || {};
      _.deepObjectExtend(destination[property], source[property]);
    } else {
      destination[property] = source[property];
    }
  }
  return destination;
};
