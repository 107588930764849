const ShomView = require('../../../core/shom-view');
const template = require('../../../../template/ddm/ddm-detail/ddm-chrono.hbs');
const ChronoChartView = require('../../charts/chrono-graph.view');
const ChronoService = require('../../../service/chrono');
const Loading = require('../../../utils/loading');

const NUMBERS_SOURCES = 6;

module.exports = ShomView.build({
  events: {
    'change select#chrono-select-end-year': '_onStateChange',
    'change select#chrono-select-start-year': '_onStateChange'
  },

  initialize(options = {}) {
    this._chronoSrv = options.chronoSrv || new ChronoService();
    this._sensorDescription = options.sensorDescription;
    this._isChartDataLoaded = false;
    this._listChronoChartViews = [];
    this._tidegaugeId = parseInt(this._sensorDescription.shom_id, 10);
  },

  render() {
    this.$el.html(template({
      startYearList: this._startYearList,
      endYearList: this._endYearList,
      errorMsg: this._errorMsg,
      noSources: !this._obsNumberBySourcesAndYears || this._obsNumberBySourcesAndYears.length === 0,
      endYear: this._endYear
    }));

    this._$startYearSelect = this.$('#chrono-select-start-year');
    this._$endYearSelect = this.$('#chrono-select-end-year');

    this._$chronoChart = this.$('#data-chrono');
    Loading.start(this._$chronoChart);
    this._displaysChronos();
    return this;
  },

  renderChartsView() {
    if (!this._listChronoChartViews.length) {
      this.initChartViews();
    }
    this._$chronoChart.empty();

    this._listChronoChartViews.forEach(chronoView => {
      chronoView.setBoundsYears(this._startYear, this._endYear);
      const $chronoGraph = chronoView.render().$el;
      this._$chronoChart.append($chronoGraph);
    });
    Loading.stop(this._$chronoChart);
  },

  initChartViews() {
    for (let source = 1; source <= NUMBERS_SOURCES; source++) {
      const chronoChartView = new ChronoChartView({
        source,
        startYear: this._startYear,
        endYear: this._endYear,
        oldestStartYear: this._startYear,
        sourceData: this._obsNumberBySourcesAndYears[source] || [],
        $parent: this.$el
      });
      this._listChronoChartViews.push(chronoChartView);
    }
  },

  _displaysChronos() {
    if (this._isChartDataLoaded) {
      // Displays directly if data are loaded
      this.renderChartsView();
    } else {
      // Get sources data from marégraphie.
      this._chronoSrv
        .getAsyncObsNumberBySourcesAndByYearsForTidegauge(this._tidegaugeId)
        .then(chronoData => {
          const { obsNumberBySourcesAndYears, startYear, endYear } = chronoData;
          this._obsNumberBySourcesAndYears = obsNumberBySourcesAndYears;
          this._startYear = startYear;
          this._endYear = endYear;

          this._generateSelectYearSequence();

          this._fillSelectOptions(this._$startYearSelect, this._startYearList, 0);
          this._fillSelectOptions(this._$endYearSelect, this._endYearList, this._endYearList.length - 1);

          this._isChartDataLoaded = true;

          this.renderChartsView();
        })
        .catch(this._displayErrorMessage.bind(this));
    }
  },

  _onStateChange(event) {
    event.preventDefault();
    switch (event.target.id) {
      case 'chrono-select-start-year':
        this._startYear = +event.target.value;
        this._updateSelectsYearLimits();
        break;
      case 'chrono-select-end-year':
        this._endYear = +event.target.value;
        this._updateSelectsYearLimits();
        break;
      default:
        return;
    }
    // SetTimeout to render chraph asynchronously
    setTimeout(() => {
      Loading.start(this._$chronoChart);
      this._displaysChronos();
    });
  },

  _updateSelectsYearLimits() {
    this._$startYearSelect.children('option').each((i, optionElement) => {
      optionElement.disabled = +optionElement.value > this._endYear;
    });
    this._$endYearSelect.children('option').each((i, optionElement) => {
      optionElement.disabled = +optionElement.value < this._startYear;
    });
  },

  _generateSelectYearSequence() {
    this._startYearList = this._generateArrayLengthValues(
      this._endYear - this._startYear + 1,
      this._startYear
    );
    // Create a copy.
    this._endYearList = [...this._startYearList];

    this._startYearList = this._generateArrayObjectValues(
      this._startYearList,
      0
    );

    this._endYearList = this._generateArrayObjectValues(
      this._endYearList,
      this._endYearList.length - 1
    );
  },

  _generateArrayLengthValues(length, startValue) {
    const yearsList = new Array(length);
    for (let i = 0; i < length; i++) {
      yearsList[i] = startValue + i;
    }
    return yearsList;
  },

  _generateArrayObjectValues(array, defaultIdx) {
    return array.map((value, idx) => ({
      label: value,
      value,
      default: idx === defaultIdx
    }));
  },

  /**
   * Fill select options for start and end year select
   * @param $select is the jquery select to fill
   * @param yearList is the list from which options are generated
   * @param index is the index of the option to select by default
   * @private
   */
  _fillSelectOptions($select, yearList, index) {
    $select.append(
      yearList.map((elt, idx) => {
        if (idx === index) {
          return `<option value="${elt.value}" selected="selected">${elt.value}</option>`;
        }
        return `<option value="${elt.value}">${elt.value}</option>`;
      }).concat()
    );
  },

  _displayErrorMessage(err) {
    console.error('ERROR', err);
  },

  onClose() {
    this._listChronoChartViews.forEach(chronoView => {
      chronoView.remove();
    });
    this._listChronoChartViews = [];
  }
});
