const $ = require('jquery');
const _ = require('underscore');

const Dialog = require('bootstrap-dialog');
const ShomView = require('../../core/shom-view');
const DDMUtils = require('../../utils/ddm/ddm-utils');
const PopupSosView = require('./popup-sos.view');
const DDMSensorReader = require('../../utils/ddm/ddm-sensor-reader');

module.exports = ShomView.build({

  className: 'ddm-component bottom-component hitable',

  events: {},

  /** ***************************** */
  /** **** INIT VIEWS FUNCTION **** */
  /** ***************************** */

  initialize(options = {}) {
    this.eventBus = options.eventBus || window.EVENTBUS;
    this.config = options.config || window.CONFIG;
    this._router = options.router || window.ROUTER;
    this._gisView = options.gisView || window.GISVIEW;
    this._url = this.config.ddm.sos_endpoint;
    this._colors = this.config.ddm.colors;
    this._network = options.network || '';

    this._ddmUtils = new DDMUtils(this.config);
  },

  loadFeatures() {
    return this._ddmUtils.getTideGaugesFeatures(this._network)
      .done(_.bind(function (features) {
        return this._features = features;
      }, this))
      .fail(_.bind(function () {
        Dialog.show({
          type: Dialog.TYPE_DANGER,
          title: 'Attention',
          message: $.i18n.t('ddm.noSos'),
          onhide: _.bind(function () {
            this._router.navigate('donnees', true);
          }, this)
        });
      }, this));
  },

  /** ***************************** */
  /** ****  RENDER FUNCTION ******* */
  /** ***************************** */

  render() {
    return this;
  },

  _renderPopup(selectEvent) {
    const popupView = new PopupSosView({
      info: this._sensorDescriptionExcerpt,
      closeFunction: this._closePopupFunction,
      sensorSelectEvent: selectEvent
    });
    this._gisView.setPopupContent(popupView.render().$el);
  },

  addLayerFeatures(layer) {
    const olLayer = this.model ? this.model.get('olLayer') : layer;
    this._gisView.addSensorLayer(olLayer, this._features, this);
  },

  removeLayerFeatures() {
    // 'true' means that view is not centered and zoomed
    // (see code of GisView.prototype.removeSensorLayer in sos-gis-view-mixin.js)
    this._gisView.removeSensorLayer(this.model.get('olLayer'), true);
  },

  /** ***************************** */
  /** ****  EVENT FUNCTION ******** */
  /** ***************************** */

  onFeatureSelect(evt, selectEvent) {
    const feature = evt.selected[0];
    const newCoord = this.buildRenderCoordinates(evt, feature);
    this._closePopupFunction = this._gisView.showPopup(newCoord);

    const featureSplitted = feature.get('href').split('/');
    const tideGaugeId = featureSplitted[featureSplitted.length - 1];

    return this._ddmUtils.getSensorDesc(tideGaugeId)
      .then(sensorDesc => {
        this._sensorDescriptionExcerpt = {
          id: DDMSensorReader.getShomId(sensorDesc),
          latitude: parseFloat(DDMSensorReader.getLatitude(sensorDesc)).toFixed(4),
          longitude: parseFloat(DDMSensorReader.getLongitude(sensorDesc)).toFixed(4),
          name: DDMSensorReader.getName(sensorDesc),
          logo: DDMSensorReader.getLogo(sensorDesc),
          working: DDMSensorReader.getState(sensorDesc),
          async: DDMSensorReader.getState(sensorDesc) === this.config.ddm.states.async,
          lastDate: 'N/A',
          lastValue: 'N/A'
        };

        return this._ddmUtils.getLastValueAndDate(tideGaugeId);
      })
      .then(last => {
        if (last.date) {
          this._sensorDescriptionExcerpt.lastDate = last.date.format('DD/MM/YYYY HH:mm');
        }
        if (last.value) {
          this._sensorDescriptionExcerpt.lastValue = `${parseFloat(last.value).toFixed(3)} m`;
        }
      })
      .then(this._renderPopup.bind(this, selectEvent));
  },

  onFeatureUnselect() {
    this._gisView.hidePopup();
  },

  /** ***************************** */
  /** ****  UTILS FUNCTION ******** */
  /** ***************************** */

  onClose() {
    this._gisView.removeSensorLayer(this.model.get('olLayer'), false);
    this._router.downRightContent();
  },

  buildRenderCoordinates(evt, feature) {
    const featureCoords = feature.getGeometry().getCoordinates();
    const projWidth = this._gisView.getMapProjectionWidth();
    const worldsAway = Math.round(evt.mapBrowserEvent.coordinate[0] / projWidth);
    featureCoords[0] += worldsAway * projWidth;

    return featureCoords;
  }

});
