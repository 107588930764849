const $ = require('jquery');

const ContextFileLoader = {};

ContextFileLoader.loadContextXmlOrKmlFileContent = fileUrl => {
  const promise = $.Deferred();
  $.get(fileUrl, data => {
    promise.resolve(data);
  }).fail(error => {
    console.error(error);
    promise.reject(error);
  });

  return promise;
};

module.exports = ContextFileLoader;
