const i18n = require('i18next-client/i18next.commonjs.withJQuery');
const $ = require('jquery');
const _ = require('underscore');
const template = require('../../template/top-ribbon.hbs');
const ShomView = require('../core/shom-view');
const PrintModal = require('./drawing/print-map-modal.view');

const ACTIVE_CLASS = 'active';
const SHARE_CLASS = 'share-selected';

module.exports = ShomView.build({
  _updateLangEvent: undefined,
  _accountIcon: undefined,
  _$myAccount: undefined,
  _$shareIcon: undefined,
  _$myShare: undefined,
  _triangleBottomRight: undefined,
  _viewSitesContainer: undefined,
  _previousRoute: undefined,

  events: {
    'click .change-lng-fr': 'setFrenchLang',
    'click .change-lng-en': 'setEnglishLang',
    'click .my-account': '_openMyAccount',
    'click .my-share': '_handleMyShare',
    'click #forgot-password-link': '_onForgotPass',
    'click .view-all-sites': '_onSitesClick',
    'click .btn-print-map': '_openHtmlPrintPage',
    'blur #all-sites-last': function(){this.onLastMenuItemBlur(event, '.view-all-sites');}
  },

  initialize(options = {}) {
    this._gisview = options.gisView || window.GISVIEW;
    this._router = options.router || window.ROUTER;
    this._config = options.config || window.CONFIG;
    this._updateLangEvent = $.Event('lang:update');
    this.eventsBus = options.eventsBus || window.EVENTBUS;

    this._user = options.user || window.ROUTER.user;
    this.listenTo(this._user, 'change:isLoggedIn', _.bind(this._onUserLogInOrOut, this));
    this._gisView = options.gisView || window.GISVIEW;
    this._modalView = options.modalView || window.MODALVIEW;
  },

  render() {
    const loggedIn = this._user.get('isLoggedIn');
    this.$el.html(template({
      logoLink: this._config.allSites.institute,
      titleLink: this._config.base.url_data_prod,
      helpPage: this._config.helpPage[window.portalLang],
      loggedIn,
      links: this._config.allSites
    }));
    this._$myAccount = $('#my-account');
    this._$myShare = $('#panel-my-share');

    this._viewSitesContainer = this.$('.view-all-sites-container');
    this._triangleBottomRight = this.$('#triangle-bottom-right');

    return this;
  },

  /**
   * Change language for the whole site
   *
   * @param e the event from which the change happen
   * @param language the wanted new language
   * @param locale the wanted new locale
   * @return {boolean} always false
   * @private
   */
  _setLang(e, language, locale) {
    e.preventDefault();

    this._updateLangEvent.lang = language;
    i18n.setLng(language).done(() => {
      $('body').trigger(this._updateLangEvent);
      this._gisview.carto.changeLanguage(locale);
    });

    return false;
  },

  setFrenchLang(e) {
    return this._setLang(e, 'fr', 'fr-FR');
  },

  setEnglishLang(e) {
    return this._setLang(e, 'en', 'en-US');
  },

  _openMyAccount() {
    this._hideMyShare();

    this._accountIcon = $('.my-account i');
    // if account view is not created
    if (this._$myAccount.is(':empty')) {
      this.eventsBus.trigger('user:openmenu');
      this._showMyAccount();
    } else if (!this._accountIcon.hasClass(ACTIVE_CLASS)) {
      this._showMyAccount();
    } else {
      this._hideMyAccount();
    }
  },

  _hideMyAccount() {
    this._$myAccount.hide();
    this._accountIcon && this._accountIcon.removeClass(ACTIVE_CLASS);
  },

  _showMyAccount() {
    this._$myAccount.show();
    this._accountIcon && this._accountIcon.addClass(ACTIVE_CLASS);
    this._setFocusToAccountMenu();
  },

  _setFocusToAccountMenu() {
    // connected, focus is on map list, else on connexion input
    if($('.my-account').find('.connected').length) {
      this._$myAccount.find('a')[0].focus();
    } else {
      this._$myAccount.find('input')[0].focus();
    }
  },

  _onUserLogInOrOut() {
    this.render();
  },

  _handleMyShare() {
    // always hide others
    this._hideMyAccount();

    this._$shareIcon = $('.my-share i');
    const wasSelected = this._$shareIcon.hasClass(SHARE_CLASS);
    if (wasSelected) {
      this._hideMyShare();
    } else {
      this._$shareIcon.addClass(SHARE_CLASS).addClass(ACTIVE_CLASS);
      this._$myShare.show();
      this._$myShare.find('a')[0].focus();
    }
  },

  _hideMyShare() {
    this._$shareIcon && this._$shareIcon.removeClass(SHARE_CLASS).removeClass(ACTIVE_CLASS);
    this._$myShare.hide();
  },

  disableMyShare() {
    $('.my-share').addClass('disabled');
    this._hideMyShare();
  },

  activateMyShare() {
    $('.my-share').removeClass('disabled');
  },

  _onSitesClick() {
    this._triangleBottomRight.toggleClass('triangle-bottom-right-anim');
    this._viewSitesContainer.slideToggle('fast');
  },

  postTranslate() {
    $('#help-page') ? $('#help-page').attr('href', this._config.helpPage[window.portalLang]) : null;
  },

  _openHtmlPrintPage(e) {
    if (!e.currentTarget.classList.contains('disabled')) {
      e.preventDefault();
      const content = new PrintModal({
        modalView: this._modalView,
        gisView: this._gisView,
        parent: this
      });
      this._modalView.show(content);
      return true;
    }

    return false;
  }
});
