import { isMobileDevice, isMobileRedirection, redirectToMobile } from './utils/mobile';

require('../img/favicon.ico');
require('./mixins/mixins.js');

window.jQuery = require('jquery');

const $ = window.$ = window.jQuery;
const Backbone = require('backbone');

/** Exportless libraries */
require('bootstrap');
require('bootstrap-datepicker');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min');
require('eonasdan-bootstrap-datetimepicker');
require('bootstrapValidator');
require('i18next-client/i18next.commonjs.withJQuery');
require('typeahead.js/dist/typeahead.jquery.js');
require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/widgets/slider');
require('jquery-ui/ui/widgets/draggable');
require('jquery-ui/ui/widgets/resizable');
require('spectrum-colorpicker');
require('datatables.net-bs');
require('datatables.net-select');
require('datatables.net-responsive');
require('datatables.net-responsive-bs');
require('cookieconsent');
require('mark.js/dist/jquery.mark.js');
require('./polyfills');
require('@fortawesome/fontawesome-free');
require('simplebar');
const platform = require('platform');

window.Proj4js = window.proj4;
Backbone.$ = $;

const Dialog = require('bootstrap-dialog');
const GlobalDataStore = require('./core/global-data-store');
const ToastrUtil = require('./utils/toastr.js');
const Router = require('./router/router');
const Config = require('./utils/config-loader');
const Catalog = require('./utils/catalog-builder');
const JsonFileLoader = require('./utils/json-file-loader.js');
const Loading = require('./utils/loading');
const User = require('./model/user');
const Cookies = require('./utils/cookies');
const Contexts = require('./collection/contexts');
const Context = require('./model/context');

let displayMobileToastr = false;
let isRedirection = false;

Loading.start($('body'));

const enTranslations = require('../../locales/en.translation.yaml');
const frTranslations = require('../../locales/fr.translation.yaml');

const i18nOptions = {
  resStore: {
    en: { translation: enTranslations },
    fr: { translation: frTranslations }
  },
  lang: 'fr',
  lngWhitelist: ['fr', 'en'],
  fallbackLng: ['fr', 'en']
};

const setupPushStates = function () {
  Backbone.history.start({
    pushState: true,
    root: window.CONFIG.baseroot,
    hashChange: false
  });

  $(document).on('click', "a[href^='/']", event => {
    let url;
    const href = $(event.currentTarget).attr('href');
    const passThrough = href.indexOf('sign_out') >= 0;
    if (!passThrough && !event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey) {
      event.preventDefault();
      url = href.replace(/^\//, '').replace('\#\!\/', '');
      window.ROUTER.navigate(url, {
        trigger: true
      });
      return false;
    }
  });
};

const retrieveSession = function () {
  if (Cookies.getItem('sessid')) {
    window.ROUTER.user.set('sessionState', 'WAITING');
    let usr;
    if (window.CONFIG && window.CONFIG.isLocal) {
      usr = new User({
        key: Cookies.getItem('userkey'),
        username: Cookies.getItem('username'),
        password: Cookies.getItem('password'),
        sessid: Cookies.getItem('sessid')
      });
    } else {
      usr = new User({
        key: Cookies.getItem('userkey'),
        username: Cookies.getItem('username'),
        sessid: Cookies.getItem('sessid')
      });
    }
    window.ROUTER.user.loggedIn(usr)
      .then(loggedInUser => loggedInUser.fetchLayers())
      .always(() => {
        Loading.stop($('body'));
      });
  } else {
    Loading.stop($('body'));
  }

  window.ROUTER.user.set('instanciated', true);
};

const loadExternalContexts = function () {
  const helpLinks = window.HELPLINKS;
  const config = window.CONFIG;
  const eventBus = window.EVENTBUS;
  window.externalContextsAvailable = false;
  JsonFileLoader.loadJson(config.externalContextUrlFile)
    .then(contextLayers => {
      if (contextLayers.error) {
        console.error('Error while loading external contexts');
        return;
      }

      const contexts = new Contexts();

      contextLayers.forEach(context => {
        const ctx = new Context({
          includedInMap: false,
          title: context.name,
          context: context.context,
          description: context.description,
          name: context.name,
          image: context.image,
          baseUrl: config.externalLayersUrl,
          identifier: context.context.replace(/\.[^/.]+$/, '')
        });

        if (helpLinks[ctx.attributes.identifier]) {
          ctx.attributes.helpLinks = helpLinks[ctx.attributes.identifier];
        }
        contexts.add(ctx);
      });

      window.EXTERNALCONTEXTS = contexts;

      window.externalContextsAvailable = true;
      eventBus.trigger('build:external');
    });
};

function validatePartnersData(data) {
  return data.title && data.title.en && data.title.fr
      && data.message && data.message.en && data.message.fr
      && data.partners && data.partners.title && data.partners.title.en
      && data.partners.title.fr && data.partners.logos
      && data.partners.logos
      .map(logo => logo.title && logo.title.en && logo.title.fr && logo.image && logo.url)
      .reduce((prev, curr) => prev && curr, true)
}

Config.load()
  .then(conf => {
    window.CONFIG = conf;

    if (!conf.isLocal) {
      // eslint-disable-next-line no-undef
      // NOSONAR
      __webpack_public_path__ = window.BASEROOT || window.CONFIG.baseroot;
    }

    if (isMobileDevice()) {
      if (isMobileRedirection()) {
        redirectToMobile();
        isRedirection = true;
      } else {
        displayMobileToastr = true;
      }
    }

    // we have to require css after webpack path set
    require('@shom2/carto/css/styles.css');
    require('../less/shom.less');

    /*
        * No cache for ajax requests
        * Must not affect OpenLayers
        */
    $.ajaxSetup({
      cache: false
    });

    // init cookies and i18n together when config is ready
    i18nOptions.useCookie = navigator.cookieEnabled;
    $.i18n.init(i18nOptions);
    const i18nLang = $.i18n.options.lng;
    window.portalLang = i18nLang === 'fr' || i18nLang === 'fr_FR' ? 'fr' : 'en';
    $('body').on('lang:update', event => {
      GlobalDataStore.set('lang', event.lang);
      window.portalLang = event.lang;
    });

    if (window.localStorage.getItem('startup-modal-dnd') !== 'true') {
      JsonFileLoader.loadAndCheckJson(conf.startup).then(data => {
        if (!validatePartnersData(data)) {
          console.error('Invalid partners json', data);
        } else {
          const StartupModal = require('./view/startup-modal.view');
          const startupModal = new StartupModal({data});
          startupModal.setElement($('#startup-modal'));
          startupModal.render();
        }
      }, err => {
        console.log(err);
      })
    }
    return JsonFileLoader.loadHelpLinks(conf);
  })
  .then(helpLinks => {
    window.HELPLINKS = helpLinks;
    return JsonFileLoader.loadAdditionnalInfo(window.CONFIG);
  })
  .then(additionnalInfo => {
    window.ADDITIONALINFO = additionnalInfo;
    if (additionnalInfo.global) {
      ToastrUtil.info(additionnalInfo.global[window.portalLang], 0, true);
    }
    return JsonFileLoader.loadJson(window.CONFIG.catalogUrl);
  })
  .then(catalog => {
    window.CATALOG = catalog;
    return $.when(
      Catalog.build(window.CONFIG),
      Catalog.buildMaregraphie(),
      Catalog.buildOceanogrammeLayers()
    );
  })
  .done((catalog, tideGauges, oceanogrammeLayers) => {
    catalog.add(tideGauges);
    catalog.add(oceanogrammeLayers);

    return catalog;
  })
  .fail(() => {
    Dialog.show({
      type: Dialog.TYPE_DANGER,
      title: $.i18n.t('error.application.loading.title'),
      message: $.i18n.t('error.application.loading.message')
    });
  })
  .always(catalog => {
    if (isRedirection) {
      return;
    }
    Loading.stop($('body'));
    if (platform.name === 'IE' && platform.version < 10) {
      Dialog.show({
        type: Dialog.TYPE_WARNING,
        title: $.i18n.t('warning.application.ie.title'),
        message: $.i18n.t('warning.application.ie.message')
      });
    }

    window.LAYERS = catalog;
    GlobalDataStore.init({
      layers: catalog,
      lang: window.portalLang
    });
    window.SHOM_GLOBAL_DATA_STORE = GlobalDataStore;
    window.ROUTER = new Router({
      layers: catalog
    });

    setupPushStates();
    window.ROUTER.start(retrieveSession);

    loadExternalContexts();
    if (!window.ncwmsLayersAvailable) {
      Catalog.buildOceano()
        .then(models => {
          catalog.add(models);
          window.ncwmsLayersAvailable = true;
          window.EVENTBUS.trigger('build:oceano');
        }).fail(() => {
          window.ncwmsLayersAvailable = false;
          Dialog.show({
            type: Dialog.TYPE_DANGER,
            title: $.i18n.t('error.application.loading.title'),
            message: $.i18n.t('error.application.loading.message')
          });
        });
    }
    if (displayMobileToastr) {
      ToastrUtil.info($.i18n.t('mobile.toastrMessage'), 0, true, undefined, { onclick: redirectToMobile });
    }

    if (!window.ncwmsAtlasLayersAvailable) {
      Catalog.buildAtlas()
        .then(models => {
          catalog.add(models);
          window.ncwmsAtlasLayersAvailable = true;
          window.EVENTBUS.trigger('build:atlas');
        }).fail(() => {
          window.ncwmsAtlasLayersAvailable = false;
          Dialog.show({
            type: Dialog.TYPE_DANGER,
            title: $.i18n.t('error.application.loading.title'),
            message: $.i18n.t('error.application.loading.message')
          });
        });
    }
  });
