const ShomView = require('../../core/shom-view');
const template = require('../../../template/oceano/ocea-select-unit.hbs');

module.exports = ShomView.build({

  events: {
    'change select': '_onUnitChanged'
  },

  initialize(options) {
    const optionsToUse = options || {};
    this._values = this.model.get('palette').units;
    this._index = optionsToUse.index;
    if (!this.model.get('selectedUnit')) {
      const firstUnit = this._values[0];
      if (firstUnit) {
        this.model.set('selectedUnit', firstUnit.name);
      }
    }
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({
      index: this._index,
      values: this._values
    }));
    // title
    this._$selectUnit = this.$el.find(`#ocean-unit-select-${this._index}`);
    this._renderSelectUnit();
    return this;
  },

  _renderSelectUnit() {
    const chosenUnit = this.model.get('selectedUnit');
    let unitOptions = '';
    const lang = window.portalLang;
    if (this._values && this._values.length > 0) {
      for (const i in this._values) {
        // eslint-disable-next-line no-prototype-builtins
        if (this._values.hasOwnProperty(i)) {
          const { name } = this._values[i];
          const translation = this.model.get('translation').units[name][lang];
          unitOptions += `<option value="${name}"`;
          unitOptions += (chosenUnit === name) ? 'selected >' : ' >';
          unitOptions += `${translation}</option>`;
        }
      }
    } else {
      unitOptions = '';
    }

    this._$selectUnit.html(unitOptions);
  },

  _hide() {
    this._$selectUnit.addClass('hidden');
  },

  _display() {
    this._$selectUnit.removeClass('hidden');
  },

  /** ***************************** */
  /** ****** GETTER FUNCTION ****** */
  /** ***************************** */

  getSelectedUnit() {
    return this._$selectUnit.val();
  },

  /** ***************************** */
  /** ****** EVENT FUNCTION ******* */
  /** ***************************** */

  _onUnitChanged() {
    this.trigger('change:oceaSelectUnit', {
      unit: this.getSelectedUnit()
    });
  },

  /** ***************************** */
  /** ****** UTILS FUNCTION ******* */
  /** ***************************** */

  enableSelect(enabled) {
    if (enabled) {
      this._$selectUnit.removeAttr('disabled');
    } else {
      this._$selectUnit.attr('disabled', 'disabled');
    }
  },

  remove() {
    this._$selectUnit.off('change');
  },

  postTranslate() {
    this._renderSelectUnit();
    // Change selected unit for displaing scale
    this._onUnitChanged();
  }
});
