var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog user-picker-modal\">\n    <div class=\"modal-content\">\n        <div class=\"modal-header\">\n            <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">&times;</button>\n            <h4 class=\"modal-title\" data-i18n=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"titleKey") : depth0), depth0))
    + "\"></h4>\n        </div>\n        <div class=\"modal-body\">\n            <p><label class=\"prompt-text\" for=\"user-list\" data-i18n=\"{this.{messageKey}}\"></label></p>\n            <p class=\"text-center\">\n                <select name=\"user-list\" id=\"user-list\" size=\"5\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"collection") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":13,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n            </p>\n            <p class=\"help-inline invalid-user hidden\" data-i18n=\"discuss.rights.modal.givePermission.invalidUser\"></p>\n        </div>\n        <div class=\"modal-footer\">\n            <button type=\"button\" class=\"ok-button btn btn-primary\" data-i18n=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"okKey") : depth0), depth0))
    + "\"></button>\n            <button type=\"button\" class=\"cancel-button btn btn-default\" data-dismiss=\"modal\" data-i18n=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"cancelKey") : depth0), depth0))
    + "\"></button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});