const LegendPreview = require('./legend-preview.js');
const styleHelper = require('../../../utils/gis/drawing/style-helper.js');
const colorsUtils = require('../../../utils/colors.js');

module.exports = LegendPreview.build({
  render() {
    this._style = this._feature.get('scStyle') || styleHelper.getStyle(this._feature);
    this.clear();

    const _strokeThickness = this._style.getStroke()
      ? this._style.getStroke().getWidth()
      : this.noWidthValue;
    const _rgbaStrokeColor = this._style.getStroke()
      ? colorsUtils.getRgbaColorFromRgba(this._style.getStroke().getColor())
      : this.noColorValue;
    const _rgbaFillColor = this._style.getFill()
      ? colorsUtils.getRgbaColorFromRgba(this._style.getFill().getColor())
      : this.noColorValue;

    this.ctx.save();

    const minX = Math.ceil(_strokeThickness / 2);
    const maxX = ~~(this.el.width - _strokeThickness / 2);
    const minY = 0;
    const maxY = this.el.height;

    this.ctx.strokeStyle = _rgbaStrokeColor;
    this.ctx.lineWidth = _strokeThickness;
    this.ctx.fillStyle = _rgbaFillColor;
    this.ctx.beginPath();

    this.ctx.moveTo(minX, minY);
    this.ctx.lineTo(maxX, minY);
    this.ctx.lineTo(maxX, maxY);
    this.ctx.lineTo(minX, maxY);
    this.ctx.lineTo(minX, minY);

    this.ctx.fill();
    this.ctx.stroke();
    this.ctx.restore();

    return this;
  }
});
