import VectorSource from 'ol/source/Vector';
import Select from 'ol/interaction/Select';
import { never } from 'ol/events/condition.js';
import { unByKey } from 'ol/Observable.js';

const GisView = require('../gis.view.js');

let selectSingleClickEventListenerKey;

/**
 * @param layers { Array<Backbone.collection>}
 * @private
 */
GisView.prototype._createSensorSelectInteraction = function (layers) {
  this._selectSingleClickInteraction = new Select({
    toggleCondition: never,
    layers
  });
  this._map.addInteraction(this._selectSingleClickInteraction);
};

GisView.prototype._initSensorInteractionLayers = function (layer, view) {
  this._sensorInteractionLayers = new Set([layer]);
  this._sensorSelectRenderView = view;
  this._createSensorSelectInteraction(Array.from(this._sensorInteractionLayers));
};

GisView.prototype._addSensorLayerInSelectInteraction = function (layer) {
  this._removeInteractionSelectSingleClickSensor();
  this._map.removeInteraction(this._selectSingleClickInteraction);
  this._sensorInteractionLayers.add(layer);
  this._createSensorSelectInteraction(Array.from(this._sensorInteractionLayers));
};

GisView.prototype._removeSensorLayerInSelectInteraction = function (layer) {
  if (this._sensorInteractionLayers.has(layer)) {
    this._removeInteractionSelectSingleClickSensor();
    this._map.removeInteraction(this._selectSingleClickInteraction);
    // Remove layer from interaction layers array
    this._sensorInteractionLayers.delete(layer);
    this._createSensorSelectInteraction(Array.from(this._sensorInteractionLayers));
  }
};

GisView.prototype.addSensorLayer = function (layer, listFOIs, view) {
  layer.set('source', new VectorSource({
    features: listFOIs || [],
    wrapX: true
  }));

  if (!this._sensorInteractionLayers) {
    this._initSensorInteractionLayers(layer, view);
  } else {
    this._addSensorLayerInSelectInteraction(layer);
  }

  this._addInteractionSelectSingleClickSensor(this._sensorSelectRenderView);
};

GisView.prototype.removeSensorLayer = function (layer, resetMapCenterAndZoom) {
  resetMapCenterAndZoom = resetMapCenterAndZoom === false;
  this._removeSensorLayerInSelectInteraction(layer);
  this._selectSingleClickInteraction && this._selectSingleClickInteraction.getFeatures().clear();
  this.hidePopup();
  if (this._sensorInteractionLayers.size) {
    this._addInteractionSelectSingleClickSensor(this._sensorSelectRenderView);
  } else {
    this._removeInteractionSelectSingleClickSensor();
  }

  if (resetMapCenterAndZoom) {
    this._map.getView().setCenter([this._config.lon, this._config.lat]);
    this._map.getView().setZoom(this._config.zoom);
  }
};

GisView.prototype._addInteractionSelectSingleClickSensor = function (view) {
  selectSingleClickEventListenerKey = this._selectSingleClickInteraction.on('select', evt => {
    if (evt.selected.length > 0) {
      view.onFeatureSelect(evt, this._selectSingleClickInteraction);
    } else if (evt.deselected.length > 0) {
      view.onFeatureUnselect(evt.deselected[0]);
    }
  });
};

GisView.prototype._removeInteractionSelectSingleClickSensor = function () {
  unByKey(selectSingleClickEventListenerKey);
  this._map.removeInteraction(this._selectSingleClickInteraction);
  selectSingleClickEventListenerKey = null;
};
