var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <i class=\"tidegauge-async-warning\"><span data-i18n=\"ddm.popup.async\"></span></i>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"headerPopup\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"async") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":4,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":1},"end":{"line":7,"column":8}}})) != null ? stack1 : "")
    + "</div>\n<div class=\"contentPopup\" style=\"padding-right: 15px\">\n	<div class=\"row\">\n		<div class=\"col-sm-9\">\n            <span data-i18n=\"ddm.popup.last-data\"></span>&nbsp;:<br /><br/>\n            <span data-i18n=\"ddm.datePrefix\"></span> "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"lastDate") : depth0), depth0))
    + " (UTC)<br />\n            <span data-i18n=\"ddm.charts.main\"></span> : <b>"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"lastValue") : depth0), depth0))
    + "</b>\n		</div>\n		<div class=\"col-sm-3\">\n			<img class=\"logo-popup\" src=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"refmarlogo") : depth0), depth0))
    + "\" alt=\"refmar\">\n		</div>\n	</div>\n</div>\n<div class=\"footerPopup\">\n    <button class=\"download-button btn btn-primary\"><span data-i18n=\"ddm.popup.download\"></span></button>\n    <button class=\"more-button btn btn-primary\"><span data-i18n=\"ddm.popup.infos\"></span></button>\n</div>\n\n";
},"useData":true});