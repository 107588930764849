const Backbone = require('backbone');
const _ = require('underscore');

module.exports = Backbone.Model.extend({

  initialize(options) {
    options = options || {};
    this._router = options.router;
    if (this._router) {
      this.listenTo(Backbone.history, 'route', _.bind(this._onRoute, this));
    }
  },

  notify() {
    this.trigger('notification', arguments);
  },

  _onRoute() {
    this.notify();
  }

});
