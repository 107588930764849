import SimpleBar from 'simplebar';
import { SC_DRAWING_MODULE } from '../../utils/constants';

const $ = require('jquery');
const ShomView = require('../../core/shom-view');
const memberTemplate = require('../../../template/user-menu/user-menu-logged-in.hbs');
const GlobalDataStore = require('../../core/global-data-store');

const ConfirmView = require('../modal/confirm.view');

const MemberView = ShomView.build({

  events: {
    'click .btn-disconnect': 'onDisconnectClicked',
    'click .btn-change-pass': 'onChangePassClicked',
    'click #close-account': function(){this.onCloseMenuButtonClick(event, '.my-account');},
    'blur .btn-disconnect': function(){this.onLastMenuItemBlur(event, '#close-account');}
  },

  initialize(options = {}) {
    this._router = options.router || window.ROUTER;
    this._drawList = options.drawList;
    this._drawingPoint = false;
    this.eventsBus = options.eventsBus || window.EVENTBUS;
    this._modalView = options.modalView || window.MODALVIEW;
  },

  render() {
    this.$el.html(memberTemplate());
    this.$el.find('#draw-list-container').html(this._drawList);
    this._addScrollbar();
    return this;
  },

  onDisconnectClicked() {
    if (GlobalDataStore.get(SC_DRAWING_MODULE)) {
      const content = new ConfirmView({
        title: $.i18n.t('drawing.modal.disconnect.title'),
        textContent: $.i18n.t('drawing.modal.disconnect.message'),
        modalView: this._modalView,
        parent: this,
        confirmCallback: () => {
          $('.btn-close-map').click();
          this._router.user.trigger('pre-disconnect');
          this._router.user.anonymous();
        }
      });
      this._modalView.show(content, { keyboard: false, backdrop: 'static' });
    } else {
      this._router.user.trigger('pre-disconnect');
      this._router.user.anonymous();
    }
    this.eventsBus.trigger('catalog:refresh');
  },

  onChangePassClicked() {
    this.eventsBus.trigger('user:changepass');
  },

  _addScrollbar() {
    // eslint-disable-next-line no-new
    new SimpleBar(this.$('#draw-list-container')[0], {
      autoHide: false,
      forceVisible: true,
      scrollbarMaxSize: 50
    });
  }
});

module.exports = MemberView;
