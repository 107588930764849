import d3Tip from 'd3-tip';

const _ = require('underscore');
const $ = require('jquery');
const d3 = require('d3');
const ShomView = require('../../core/shom-view');
const template = require('../../../template/charts/chrono-graph.hbs');

d3.tip = d3Tip;

const LABEL_XAXIS_TRANSLATE_VECTOR = { x: 0, y: 0, rotation: -65 };
const YAXIS_TRANSLATE_VECTOR = { x: 50, y: 10 };
const D3TIP_OFFSET = { x: -11, y: 0 };
const BAR_TRANSLATION_VCETOR = { x: 50, y: 10 };
const LIMIT_TO_DISPLAYS_ALL_YEARS = 40;
const PADDING = {
  top: 20, right: 50, bottom: 50, left: 5
};
const GRAPH_CONTAINER_HEIGHT = 300;
const GRAPH_HEIGHT = GRAPH_CONTAINER_HEIGHT - PADDING.top - PADDING.bottom;
const XAXIS_TRANSLATE_VECTOR = { x: 50, y: GRAPH_HEIGHT + 10 };

module.exports = ShomView.build({
  events: {},

  initialize(options = {}) {
    this._source = options.source;
    this._startYear = options.startYear;
    this._oldestStartYear = options.oldestStartYear;
    this._endYear = options.endYear;
    this._sourceData = options.sourceData;
    this._$parent = options.$parent;
    this._formatSourceDate();
    this._labelYear = $.i18n.t('ddm.chrono.tooltipMessageLabel.year');
    this._labelValue = $.i18n.t('ddm.chrono.tooltipMessageLabel.value');
  },

  render() {
    this.$el.html(template({ source: this._source }));
    this._$chronoAlert = this.$('.chrono-chart-alert');
    this._drawHistogramm();
    return this;
  },

  _formatSourceDate() {
    // if we don't have all years on result observations data (this._observations)
    if ((this._endYear - this._oldestStartYear + 1) !== this._sourceData.length) {
      // get all vectors years observations
      const allYearVectorObservation = _.range(this._oldestStartYear, this._endYear + 1);
      // get vector years who have observations
      const yearVectorWithObservation = _.chain(this._sourceData).map(v => v.year).value();
      // get vector years who do not have observations
      const yearVectorWithoutObservation = _.difference(allYearVectorObservation, yearVectorWithObservation);

      // add observation for each year on vector yearVectorWithoutObservation
      for (let i = 0; i < yearVectorWithoutObservation.length; i++) {
        this._sourceData.push({
          year: yearVectorWithoutObservation[i],
          nb_total_values: 0
        });
      }
    }

    this._sourceData = _.sortBy(this._sourceData, 'year');
  },

  setBoundsYears(startYear, endYear) {
    this._startYear = startYear;
    this._endYear = endYear;
  },

  _drawHistogramm() {
    const svgContainerWidth = this._$parent.width();

    this._svgContainer = d3.select(this.el).select('.chrono-graph-histo');
    this._chart = this._svgContainer.append('svg');

    const width = svgContainerWidth - PADDING.left - PADDING.right;

    let maxValues = 1;

    // Select only useful years
    const offset = this._startYear - this._oldestStartYear;
    const usefulData = this._sourceData.slice(offset, offset + this._endYear - this._startYear + 1);
    let hasNoObservation = true;

    const max = _.max(usefulData, elt => elt.nb_total_values).nb_total_values;
    maxValues = max > 0 ? max : 1;

    // if we have exceeded the LIMIT_TO_DISPLAYS_ALL_YEARS Labels display one year label on five
    const numberTickToOneYear = usefulData.length > LIMIT_TO_DISPLAYS_ALL_YEARS ? 5 : 1;
    usefulData.forEach((d, index) => {
      if (hasNoObservation && d.nb_total_values !== 0) {
        hasNoObservation = false;
      }
      // defines the length of the bar for each year, the minimum value being 0.5 (value from which the bar is visible)
      const percent = (d.nb_total_values * 100) / maxValues;
      d.values_percent = percent < 0.5 ? 0.5 : percent;
      d.xLabelValue = (index % numberTickToOneYear === 0) ? d.year : '';
    });

    if (hasNoObservation) {
      this._$chronoAlert.show();
    }

    this._chart
      .attr('width', width + PADDING.left + PADDING.right)
      .attr('height', GRAPH_HEIGHT + PADDING.top + PADDING.bottom);

    // define x and y scale
    const yScale = d3.scale.linear()
      .range([GRAPH_HEIGHT, 0])
      .domain([0, 100]);
    const xScale = d3.scale.ordinal()
      .rangeRoundBands([0, width], 0)
      .domain(usefulData.map(s => s.year));

    // define yaxis and xaxis scale
    const xAxis = d3.svg.axis()
      .scale(xScale)
      .tickFormat((year, index) => usefulData[index].xLabelValue)
      .orient('bottom')
      .innerTickSize(0)
      .outerTickSize(0);

    const yAxis = d3.svg.axis()
      .scale(yScale)
      .tickFormat(d => `${d}%`)
      .orient('left')
      .tickPadding(5)
      .tickSize(-width, 0, 0);

    // draw the yaxis
    this._chart.append('g')
      .attr('class', 'chrono-y-axis')
      .attr('transform', `translate(${YAXIS_TRANSLATE_VECTOR.x},${YAXIS_TRANSLATE_VECTOR.y})`)
      .call(yAxis);

    // draw the xaxis
    this._chart.append('g')
      .attr('class', 'chrono-x-axis')
      .attr('transform', `translate(${XAXIS_TRANSLATE_VECTOR.x},${XAXIS_TRANSLATE_VECTOR.y})`)
      .call(xAxis)
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', `translate(${LABEL_XAXIS_TRANSLATE_VECTOR.x},${LABEL_XAXIS_TRANSLATE_VECTOR.y})rotate(${LABEL_XAXIS_TRANSLATE_VECTOR.rotation})`);

    if (!this._tip) {
      this._createChronoTip();
    }

    // build tooltip
    this._chart.call(this._tip);
    // draw the bar
    this._chart.selectAll('rect')
      .data(usefulData)
      .enter()
      .append('rect')
      .attr('class', 'chrono-histo-bar')
      .attr('transform', `translate(${BAR_TRANSLATION_VCETOR.x},${BAR_TRANSLATION_VCETOR.y})`)
      .attr({
        x: d => xScale(d.year),
        y: d => yScale(d.values_percent),
        width: xScale.rangeBand() - 2,
        height: d => GRAPH_HEIGHT - yScale(d.values_percent)
      })
      .on('mouseover', this._tip.show)
      .on('mouseout', this._tip.hide)
      .on('wheel', this._tip.hide);
  },

  onClose() {
    d3.select(`#d3-tip-source${this._source}`).remove();
    this._tip = null;
  },

  _createChronoTip() {
    // define tooltip
    this._tip = d3.tip()
      .attr('id', `d3-tip-source${this._source}`)
      .attr('class', 'd3-tip-chrongram')
      .offset([D3TIP_OFFSET.x, D3TIP_OFFSET.y])
      .html(d => `<div class="chrono-tip-container">
                    <span class="chrono-tooltip-label">${this._labelYear}:</span> ${d.year}<br>
                    <span class="chrono-tooltip-label">${this._labelValue}:</span> ${d.nb_total_values}
                </div>`);
  },

  postTranslate() {
    this._labelYear = $.i18n.t('ddm.chrono.tooltipMessageLabel.year');
    this._labelValue = $.i18n.t('ddm.chrono.tooltipMessageLabel.value');
  }

});
