const _ = require('underscore');
const $ = require('jquery');
const Dialog = require('bootstrap-dialog');

const ShomView = require('../../core/shom-view.js');
const template = require('../../../template/drawing/action-tool-bar.hbs');

const CartoDynMap = require('../../model/cartodyn-map.js');
const MapPropertiesModal = require('./map-properties-modal.view');
const AlertModal = require('../modal/alert.view');
const FileHelper = require('../../utils/file.js');
const ExternalLayerModalView = require('../catalog/external-layers.view');
const JsonFileLoader = require('../../utils/json-file-loader.js');

const ToastrUtil = require('../../utils/toastr.js');

const DrawingToolBarView = ShomView.build({

  events: {
    'click .btn-close-map': '_onCloseButtonClick',
    'click .btn-save-map': '_onSaveMapButtonClick',
    'click .btn-save-map-as': '_onSaveMapAsButtonClick',
    'click .btn-map-props': '_onEditPropertiesButtonClick',
    'click .btn-discuss': '_onDiscussClick',
    'click .export-button': '_onExportButtonClick',
    'change .import-button input[type="file"]': '_onFileSelect',
    'click .import-button': '_openExternalDataModal'
  },

  initialize(options = {}) {
    _.bindAll(this, '_onMapSaved', '_onMapSaveFailed');
    this._router = options.router || window.ROUTER;
    this._config = options.config || window.CONFIG;
    this._gisView = options.gisView || window.GISVIEW;
    this._drawingRouter = options.drawingRouter;
    this._user = options.user || this._router.user || window.ROUTER.user;
    this._eventBus = options.eventBus || window.EVENTBUS;
    this._modalView = options.modalView || window.MODALVIEW;
    this._fileHelper = options.fileHelper || new FileHelper();
    this._externalFeedsUrl = this._config.feeds;
    this._maps = options.maps;
    this.listenTo(this._user, 'change:isLoggedIn', this.render, this);
    this.listenTo(this.model, 'change:collaborative', this.render, this);
    this.listenTo(this.model, 'discuss:session:update', this._onSessionUpdate, this);
    this.listenTo(this.model, 'discuss:session:closed', this._onSessionClosed, this);
  },

  render() {
    const map = this.model.get('map');

    if (map) {
      const collaborative = this.model.get('collaborative');
      const isEditor = this.model.isCurrentUserEditor();
      const isOwner = this.model.isCurrentUserOwner();
      const loggedIn = this._user.get('isLoggedIn');
      const permissions = this.model.get('permissions');
      const hasCartoDynPermissions = permissions && (permissions.hasCartoDyn || permissions.hasDiscuss);
      const hasDiscussPermissions = permissions && permissions.hasDiscuss;
      const showSaveButtons = ((hasCartoDynPermissions && !collaborative) || (collaborative && isEditor));
      this.$el.html(template({
        newMap: map.isNew(),
        userLoggedIn: loggedIn,
        collaborative,
        isEditor,
        isOwner,
        showSaveButtons,
        hasDiscussPermissions
      }));
    }

    this._$buttons = this.$('.tool-bar-button');

    return this;
  },

  _onSessionUpdate(updateEvent) {
    if (updateEvent.updateEditor) {
      this._editorKey = updateEvent.editorKey;
      this.render();
    }
  },
  _onSessionClosed() {
    this._editorKey = null;
  },

  _onCloseButtonClick() {
    $('.legend-checkbox').css('display', 'none');

    this.model.set('started', false);
    this._executeCartoModuleCommands([['drawing.disable'], ['drawing.clear']]);
    this._gisView.setCurrentlyDrawing(false);

    this._router.navigate('dessin', true);
  },

	_onSaveMapButtonClick() {
		const map = this.model.get('map');
		if (map.isNew()) {
			const content = new MapPropertiesModal({
				title: 'Test',
				modalView: this._modalView,
				model: map,
				parent: this.model,
				saveCallback: _.bind(() => {
					this.model.saveMap(map)
						.then(this._onMapSaved)
						.fail(this._onMapSaveFailed);
				}, this)
			});
			this._modalView.show(content, {keyboard: false, backdrop: 'static'});
		} else {
			this.model.saveMap(map).then(this._onMapSaved);
		}
	},

  _onSaveMapAsButtonClick() {
    const newMap = new CartoDynMap();
    const name = this._drawingRouter.generateNewMapName();
    newMap.set('name', name);
    this._drawingRouter.setupMap(newMap);
    const content = new MapPropertiesModal({
      title: 'drawing.modal.map-properties.title',
      modalView: this._modalView,
      model: newMap,
      parent: this,
      okButtonLabel: 'drawing.modal.map-properties.save',
      saveCallback: _.bind(function () {
        this.model.saveMap(newMap)
          .then(_.bind(function () {
            this.model.set('map', newMap);
            this._onMapSaved();
          }, this))
          .fail(_.bind(this._onMapSaveFailed, this));
      }, this)
    });
    this._modalView.show(content, { keyboard: false, backdrop: 'static' });
  },

  _onMapSaved() {
    ToastrUtil.info($.i18n.t('drawing.modal.map-saved.message'));
  },

  _onMapSaveFailed() {
    const content = new AlertModal({
      title: 'drawing.modal.map-saved.title',
      message: 'drawing.modal.map-saved.error-message',
      modalView: this._modalView,
      parent: this
    });
    this._modalView.show(content);
  },

  _onEditPropertiesButtonClick() {
    const map = this.model.get('map');
    const content = new MapPropertiesModal({
      title: 'drawing.modal.map-properties.title',
      modalView: this._modalView,
      model: map,
      parent: this.model,
      okButtonLabel: 'drawing.modal.map-properties.validate',
      saveCallback: _.bind(() => {
        this.model.saveMap(map)
          .then(this._onMapSaved)
          .fail(this._onMapSaveFailed);
      }, this)
    });
    this._modalView.show(content, { keyboard: false, backdrop: 'static' });
  },

  _onDiscussClick() {
    this.model.createCollaborativeSession();
  },

  _onExportButtonClick() {
    const kmlDocument = this._gisView.exportKmlData();
    this._fileHelper.saveAs(
      kmlDocument,
      this._config.kmlExportFilename,
      'application/vnd.google-earth.kml+xml;charset=utf-8',
      this.$('.hidden-export-file-container')
    );
  },

  _onFileSelect(evt) {
    try {
      const { files } = evt.target;
      const f = files[0];
      this._fileHelper.readFileWithExtCheck(f, this._config.kmlFileRegex)
        .then(_.bind(function (wmcDocument) {
          const xmlDoc = $.parseXML(wmcDocument);
          const ok = this._gisView.importKmlData(xmlDoc);
          if (!ok) {
            this._onLoadKmlError();
          }
          this._resetInputForm();
        }, this))
        .fail(this._onLoadKmlError);
    } catch (e) {
      this._onLoadKmlError();
    }
  },

  _openExternalDataModal() {
    JsonFileLoader.loadJson(this._externalFeedsUrl)
      .then(data => {
        let externalFeeds;
        if (typeof (data.feeds) === 'undefined') {
          externalFeeds = [];
        } else {
          externalFeeds = data.feeds;
        }

        this._modalView.show(new ExternalLayerModalView({
          collection: externalFeeds,
          startType: 'kml',

          subviewOptions: {
            kmlToDrawingLayer: true
          }
        }));
      });
  },

  _onLoadKmlError() {
    Dialog.show({
      type: Dialog.TYPE_DANGER,
      title: $.i18n.t('error.application.unknown.title'),
      message: $.i18n.t('error.application.context.message')
    });
    this._resetInputForm();
  },

  _resetInputForm() {
    const $input = this.$('.import-button input[type="file"]');
    $input.wrap('<form>').parent('form').trigger('reset');
    $input.unwrap();
  }
});

module.exports = DrawingToolBarView;
