import { html } from 'lit-html';
import * as CartoConversions from '@shom2/carto/utils/conversions';
import {
  Fill, RegularShape, Stroke, Style
} from 'ol/style';

export const svgDrawingLine = `
    <svg xmlns="http://www.w3.org/2000/svg" width="210mm" height="210mm" viewBox="50 90 380 420" version="1.1">
    <g>
      <circle r="40" cy="438" cx="87" />
      <rect width="90" height="25" x="-197.3665" y="364.42188" transform="matrix(0.7,-0.7,0.7,0.7,0,0)" />
      <rect width="90" height="25" x="-81.476357" y="364.2424" transform="matrix(0.7,-0.7,0.7,0.7,0,0)" />
      <rect width="90" height="25" x="33.342373" y="364.20471" transform="matrix(0.7,-0.7,0.7,0.7,0,0)" />
      <circle r="40" cy="142" cx="385" />
    </g>
  </svg>`;

export const svgDrawingPolygon = `
  <svg xmlns="http://www.w3.org/2000/svg" width="210mm" height="210mm" viewBox="0 0 300 340" version="1.1">
    <g>
      <rect transform="matrix(0.8660254,-0.5,0.5,0.8660254,0,0)"  y="77.736397" x="-42.613342" height="25" width="170" />
      <rect transform="matrix(0,-1,1,0,0,0)" y="1.865427" x="-258.69522" height="25" width="170" />
      <rect transform="matrix(-0.8660254,-0.5,0.5,-0.8660254,0,0)" y="-223.10101" x="-301.00009" height="25" width="170" />
    </g>
    <g transform="matrix(-1,0,0,-1,298.24959,347.34797)">
      <rect transform="matrix(0.8660254,-0.5,0.5,0.8660254,0,0)" y="77.736397" x="-42.613342" height="25" width="170" />
      <rect transform="matrix(0,-1,1,0,0,0)" y="1.865427" x="-258.69522" height="25" width="170" />
      <rect transform="matrix(-0.8660254,-0.5,0.5,-0.8660254,0,0)" y="-223.10101" x="-301.00009" height="25" width="170" />
    </g>
  </svg>`;

export const svgDrawingCircle = `
  <svg xmlns="http://www.w3.org/2000/svg" width="210mm" height="210mm" viewBox="0 0 265 270" version="1.1">
    <g>
      <rect ry="15" y="2.0943525" x="100.625" height="25.178572" width="60" />
      <rect transform="matrix(-0.5,0.8660254,-0.8660254,-0.5,0,0)" ry="15"  y="-73.998238" x="22.184847" height="25.178572" width="60" />
      <rect transform="matrix(-0.86602541,0.49999999,-0.49999999,-0.86602541,0,0)" ry="15" y="-75.262192" x="-75.613937" height="25.178572" width="60" />
    </g>
    <g transform="matrix(0,1,-1,0,266.03964,5.3452201)">
      <rect ry="15" y="2.0943525" x="100.625" height="25.178572" width="60" />
      <rect transform="matrix(-0.5,0.8660254,-0.8660254,-0.5,0,0)" ry="15" y="-73.998238" x="22.184847" height="25.178572" width="60" />
      <rect transform="matrix(-0.86602541,0.49999999,-0.49999999,-0.86602541,0,0)" ry="15" y="-75.262192" x="-75.613937" height="25.178572" width="60" />
    </g>
    <g transform="matrix(-1,0,0,-1,263.19438,271.74203)">
      <rect ry="15" y="2.0943525" x="100.625" height="25.178572" width="60" />
      <rect transform="matrix(-0.5,0.8660254,-0.8660254,-0.5,0,0)" ry="15" y="-73.998238" x="22.184847" height="25.178572" width="60" />
      <rect transform="matrix(-0.86602541,0.49999999,-0.49999999,-0.86602541,0,0)" ry="15" y="-75.262192" x="-75.613937" height="25.178572" width="60" />
    </g>
    <g transform="matrix(0,-1,1,0,-2.4881235,266.39677)">
      <rect ry="15" y="2.0943525" x="100.625" height="25.178572" width="60" />
      <rect transform="matrix(-0.5,0.8660254,-0.8660254,-0.5,0,0)" ry="15" y="-73.998238" x="22.184847" height="25.178572" width="60" />
      <rect transform="matrix(-0.86602541,0.49999999,-0.49999999,-0.86602541,0,0)" ry="15" y="-75.262192" x="-75.613937" height="25.178572" width="60" />
    </g>
    <g>
      <rect transform="matrix(0,1,-1,0,0,0)" ry="0" y="-142.87386" x="92.255936" height="25.178572" width="88.571426" />
      <rect ry="0" y="-149.95491" x="-174.33627" height="25.178572" width="88.571426" transform="scale(-1,-1)" />
    </g>
  </svg>`;

export const drawingDefaultStyle = new Style({
  fill: new Fill({
    color: '#66ff3377'
  }),
  stroke: new Stroke({
    color: '#66ff33',
    width: 2
  }),
  image: new RegularShape({
    points: Infinity,
    radius: 3,
    fill: new Fill({
      color: '#66ff3377'
    }),
    stroke: new Stroke({
      color: '#66ff33',
      width: 2
    })
  })
});

/**
 * Template pour le module de mesures
 *
 * @param partials
 * @param i18n le dictionnaire de traduction
 * @return Le template produit
 */
export const measurementTemplate = (partials, i18n) => html`
      <div class='hitable'>
        <div class="measurement-header"></div>
        <div class="measurement-container">
          ${partials.measurementToolsElement}
        </div>
      </div>`;

export const toolTemplateInfos = {
  distance: {
    title: 'MEASUREMENT_DISTANCE_TITLE',
    icon: '<i class="fa-solid fa-ruler"></i>',
    class: 'measurement-distance'
  },
  surface: {
    title: 'MEASUREMENT_SURFACE_TITLE',
    icon: '<i class="fa-solid fa-draw-polygon"></i>',
    class: 'measurement-surface'
  },
  azimuth: {
    title: 'MEASUREMENT_AZIMUTH_TITLE',
    icon: '<i class="fas fa-drafting-compass"></i>',
    class: 'measurement-azimuth'
  },
  radius: {
    title: 'MEASUREMENT_RADIUS_TITLE',
    icon: '<i class="far fa-dot-circle"></i>',
    class: 'measurement-radius'
  }
};

/**
 * Template pour les outils du module de mesures
 *
 * @param listeners les listeners du module
 * @param params
 * @return Le template produit
 */
export const measurementToolTemplate = (listeners, params) => html`
      <div @click='${listeners.click}' class='measurement ${params.class}'>
		<span title='${params.title}'>
		  ${params.icon}
		</span>
	  </div>`;

/**
 * Fonction de formatage des résultats de l'outil de mesure
 *
 * @param measurementValue l'objet résultant de la measure
 * @return le texte à afficher dans la tooltip de résultat
 */
export const measurementFormatFunction = function (measurementValue) {
  const format = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };

  const { i18n } = this;
  const locale = i18n.language;

  return (measurementValue.surface !== undefined
    ? `${i18n.dict.MEASUREMENT_SURFACE_RESULT}
         ${CartoConversions.sqrMetersToSqrKms(measurementValue.surface).toLocaleString(locale, format)}
         ${i18n.dict.KILOMETERS}²
         (${CartoConversions.sqrMetersToSqrNMiles(measurementValue.surface).toLocaleString(locale, format)}
         ${i18n.dict.NAUTIC_MILES}²)`
    : '')
    + (measurementValue.azimuth !== undefined
      ? `${i18n.dict.MEASUREMENT_AZIMUTH_RESULT}
         ${measurementValue.azimuth.toLocaleString(locale, format)}°
         <br>`
      : '')
    + (measurementValue.length !== undefined
      ? `${i18n.dict.MEASUREMENT_DISTANCE_RESULT}
         ${CartoConversions.metersToKms(measurementValue.length).toLocaleString(locale, format)}
         ${i18n.dict.KILOMETERS}
         (${CartoConversions.metersToNMiles(measurementValue.length).toLocaleString(locale, format)}
         ${i18n.dict.NAUTIC_MILES})`
      : '')
    + (measurementValue.radius !== undefined
      ? `${i18n.dict.MEASUREMENT_RADIUS_RESULT}
         ${CartoConversions.metersToKms(measurementValue.radius).toLocaleString(locale, format)}
         ${i18n.dict.KILOMETERS}
         (${CartoConversions.metersToNMiles(measurementValue.radius).toLocaleString(locale, format)}
         ${i18n.dict.NAUTIC_MILES})`
      : '');
};
