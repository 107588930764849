const _ = require('underscore');
const CoordinatesHelper = require('./coordinates-helper');

module.exports = {
  /**
   * Check if getFeatureInfo request is displayable for a layer
   * @param {string | undefined} layerIdentifier
   * @param {import('../../model/layer') | undefined} catalogLayer
   * @returns {boolean}
   */
  isDisplayableGfiInfo: (layerIdentifier, catalogLayer) => {
    if (catalogLayer.get('isArchive')) {
      return false;
    }

    return catalogLayer && (catalogLayer.get('layerType') !== null || catalogLayer.get('gfiResourceWmsV') !== null);
  },

  isRadarHF: catalogLayer => catalogLayer.get('identifier') && catalogLayer.get('identifier').includes('RADARHF'),

  getFeatureInfoPartner(data, gisView) {
    let { url } = data;
    const bbox = gisView.getCurrentViewBounds();
    const mapSize = gisView.getMapSize();
    const layerParams = data.olLayer.getSource().getParams();
    const layer = data.olLayer.get('identifier');
    const IJ = CoordinatesHelper.convertIJMercatToLonLat(bbox, mapSize, data.coord);
    let infoFormat = encodeURIComponent('text/plain');
    if (Array.isArray(data.infoFormat)) {
      if (data.infoFormat.includes('text/html')) {
        infoFormat = encodeURIComponent('text/html');
      } else if (data.infoFormat.includes('text/plain')) {
        infoFormat = encodeURIComponent('text/plain');
      } else if (data.infoFormat.includes('text/xml')) {
        infoFormat = encodeURIComponent('text/xml');
      } else {
        infoFormat = encodeURIComponent('text/plain');
      }
    }

    const preParams = {
      REQUEST: 'GetFeatureInfo',
      SERVICE: data.service,
      VERSION: layerParams.VERSION,
      LAYERS: encodeURIComponent(layerParams.LAYERS),
      TRANSPARENT: layerParams.TRANSPARENT,
      ELEVATION: layerParams.ELEVATION,
      TIME: layerParams.TIME,
      FORMAT: encodeURIComponent(layerParams.FORMAT),
      QUERY_LAYERS: encodeURIComponent(layer),
      BBOX: bbox.join(','),
      X: IJ[0],
      Y: IJ[1],
      INFO_FORMAT: infoFormat,
      WIDTH: mapSize[0],
      HEIGHT: mapSize[1],
      LANG: window.portalLang.toUpperCase()
    };

    if (layerParams.VERSION === '1.3.0') {
      preParams.CRS = encodeURIComponent('EPSG:3857');
    } else {
      preParams.SRS = encodeURIComponent('EPSG:3857');
    }

    const params = _.chain(preParams)
      .pairs()
      .map(pair => pair.join('='))
      .value()
      .join('&');
    if (!url.includes('?')) {
      url += '?';
    }
    url += params;
    return url;
  },

  getLegendUrl(rawLayer) {
    const style = rawLayer.Style;
    if (Array.isArray(style) && style.length) {
      const legendURL = style[0].LegendURL;
      if (legendURL && Array.isArray(legendURL)) {
        return legendURL.length > 0 ? legendURL[0].OnlineResource : null;
      }
    }
  },

  getMetadataUrl(rawLayer) {
    const metadataUrl = rawLayer.MetadataURL;
    if (Array.isArray(metadataUrl)) {
      return metadataUrl.length > 0 ? metadataUrl[0].OnlineResource : null;
    }
  },

  getBaseRequestUrl(request) {
    if (request.GetCapabilities) {
      const DCPType = request.GetCapabilities.DCPType || null;
      if (DCPType && Array.isArray(DCPType) && DCPType[0] && DCPType[0].HTTP) {
        const http = DCPType[0].HTTP;
        if (http.Get) {
          return http.Get.OnlineResource ? http.Get.OnlineResource : null;
        }
      }
    }
  }

};
