const Backbone = require('backbone');
const RoseCourantItem = require('../model/rose-courant-item');

const RoseCourantItems = Backbone.Collection.extend({
  model: RoseCourantItem,

  byHourOnly() {
    return new RoseCourantItems(
      this.filter(roseCourantItem => roseCourantItem.get('datetime').endsWith(':00'))
    );
  }
});

module.exports = RoseCourantItems;
