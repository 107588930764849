const $ = require('jquery');
const _ = require('underscore');

const ShomView = require('../../core/shom-view');
const template = require('../../../template/catalog/catalog-search-results.hbs');

const LayerView = require('./layer.view');
const OceanoLayerView = require('./oceano-layer.view');
const ExternalContextView = require('./external-context-search-result.view');

module.exports = ShomView.build({

  initialize(options = {}) {
    this._config = options.config || window.CONFIG;
    this._eventBus = options.eventBus || window.EVENTBUS;

    this._itemViews = [];

    // Layers used on portal but not accessibles for user
    this._externalContextsAvailable = options.externalContextsAvailable || window.externalContextsAvailable;

    this._searchExcludeLayers = _.values(this._config.catalog.search.excludeLayers);
    this._layers = (options.collection || window.LAYERS).filter(this._filterNoFindableLayers.bind(this));
    this._externalContexts = options.externalContexts || window.EXTERNALCONTEXTS;
    this._currentSearchedTerm = null;

    this._updateTotalCountLayers();

    if (!this._externalContextsAvailable) {
      this._eventBus.on('build:external', () => {
        this._onExternalContextsAvailable();
        this.render();
        if (this._currentSearchedTerm) {
          this.filterLayers(this._currentSearchedTerm);
        }
      });
    }

    this._eventBus.on('build:oceano', () => {
      this._layers = window.LAYERS.filter(this._filterNoFindableLayers.bind(this));
      this._updateTotalCountLayers();
      this.render();
      if (this._currentSearchedTerm) {
        this.filterLayers(this._currentSearchedTerm);
      }
    });
  },

  render() {
    this._removeItemViews();
    this._createItemViews();
    this.$el.html(template({ count: this._itemViews.length }));

    this._$count = this.$('.result-count');
    this._$layerList = this.$('.layer-list');
    this._$resultCountLabel = this.$('.result-count-label');

    if (this._itemViews.length > 0) {
      this._renderItemViews();
    } else {
      this.$el.html('<div class="info-message no-data" data-i18n="plugins.catalog.nodata"></div>');
    }

    return this;
  },

  _createItemViews() {
    this._itemViews = [];

    this._layers.forEach(layer => {
      if (layer.get('external')) {
        return;
      }
      if (layer.get('layerType') === 'NCWMS' || layer.get('layerType') === 'REFMAR'
        || layer.get('layerType') === 'FORECAST') {
        this._itemViews.push(new OceanoLayerView({
          model: layer,
          oceano: true,
          searchView: true
        }));
      } else {
        this._itemViews.push(new LayerView({
          model: layer,
          searchView: true
        }));
      }
    });

    if (window.externalContextsAvailable) {
      const externalContextViews = [];
      this._externalContexts.each(ctx => {
        externalContextViews.push(new ExternalContextView({
          model: ctx,
          collection: this._layers
        }));
      });
      this._itemViews = this._itemViews.concat(externalContextViews);
    }

    this._itemViews.sort((a, b) => {
      const atoLower = (a.model.get('title') || '').toLowerCase();
      const bToLower = (b.model.get('title') || '').toLowerCase();
      return atoLower.localeCompare(bToLower);
    });
  },

  _removeItemViews() {
    this._itemViews.forEach(itemView => {
      itemView.remove();
    });
  },

  _renderItemViews() {
    this._itemViews.forEach(itemView => {
      const $div = $('<div class="layer-item-container"></div>');
      this._$layerList.append($div);
      $div.append(itemView.render().$el);
    }, this);
  },

  filterLayers(term) {
    this.$('.layer-item-container').show();
    this._highlightMatches(term);
    const $unmarkedItems = this.$el.find('.layer-item-container:not(:has(mark))');
    const unmarkedCount = $unmarkedItems.length;
    $unmarkedItems.hide();
    this._updateCounter(this._totalCount - unmarkedCount);
  },

  _highlightMatches(term) {
    this._unmark();
    this._mark(term);
  },

  _updateCounter(countValue) {
    this._$count.html(countValue);
    const langKey = countValue <= 1 ? 'catalog.search.results.single' : 'catalog.search.results.multiple';
    this._$resultCountLabel.attr('data-i18n', langKey).i18n();
  },

  _unmark() {
    this._currentSearchedTerm = null;
    this._$layerList.unmark();
  },

  _mark(term) {
    this._currentSearchedTerm = term;
    this._itemViews.forEach(itemView => {
      itemView.setMark(term);
    });
  },

  _updateTotalCountLayers() {
    this._totalCount = this._layers.filter(layer => !layer.get('external')).length;
    if (this._externalContextsAvailable) {
      this._totalCount += this._externalContexts.length;
    }
  },

  _onExternalContextsAvailable() {
    this._externalContexts = window.EXTERNALCONTEXTS;
    this._externalContextsAvailable = true;
    this._updateTotalCountLayers();
    this._eventBus.off('build:external');
  },

  _filterNoFindableLayers(layer) {
    // Not findable if category index is -1 or layer identifier is in _searchExcludeLayers list
    return this._searchExcludeLayers.indexOf(layer.get('identifier')) === -1
      && (!layer.get('category') || layer.get('category').index !== -1);
  }

});
