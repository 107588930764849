import WMSCapabilitiesFormat from 'ol/format/WMSCapabilities';

import WMTSCapabilitiesFormat from 'ol/format/WMTSCapabilities';
import WfsJsonMapping from '../wfs-jsonix-mapping.js';

const $ = require('jquery');
const _ = require('underscore');
const async = require('async');
const LayersFactory = require('./capabilities-layers-factory');
const ShomProxy = require('../proxy');

let _layersFactory;

const CapabilitiesHelper = function (options = {}) {
  _layersFactory = options.layersFactory || new LayersFactory({ config: options.config });
};

CapabilitiesHelper.prototype.getLayersFromCapabilities = function (ogcService, url, auth, no_proxy, forcedVersion, options = {}) {
  const promise = $.Deferred();
  let layers = [];
  let versionsCounter = 0;
  const versions = this._versions;

  const versionsToUse = forcedVersion ? [forcedVersion] : versions[ogcService];

  if (!versionsToUse) {
    return promise.reject();
  }
  async.until(
    async callback => {
      callback(null, (layers.length !== 0 || versionsCounter >= versionsToUse.length));
    },
    _.bind(callback => {
      this.getLayersFromCapabilitiesWithVersion(ogcService, url, versionsToUse[versionsCounter], auth, no_proxy, options)
        .then(foundLayers => {
          layers = foundLayers;
          versionsCounter += 1;
          callback();
        })
        .fail(() => {
          versionsCounter += 1;
          callback();
        });
    }, this),
    err => {
      if (err || layers.length === 0) {
        promise.reject();
      } else {
        promise.resolve(layers);
      }
    }
  );

  return promise;
};

CapabilitiesHelper.prototype.getLayersFromCapabilitiesWithVersion = function (ogcService, url, version, auth, noProxy, options = {}) {
  let format;
  if (ogcService === 'WFS') {
    format = WfsJsonMapping;
  } else {
    let formatClass;
    switch (ogcService) {
      case 'WMS':
        formatClass = WMSCapabilitiesFormat;
        break;
      case 'WMTS':
        formatClass = WMTSCapabilitiesFormat;
        break;
      default:
        formatClass = WMSCapabilitiesFormat;
    }

    format = new formatClass({
      version,
      yx: {
        'EPSG:4326': true
      }
    });
  }
  let urlToGet = '';

  if (noProxy) {
    urlToGet = url;
  } else {
    urlToGet = ShomProxy.getProxifiedUrl(url, auth);
  }
  return $.get({
    url: urlToGet,
    cache: true,
    data: {
      service: this._servicesClass[ogcService],
      request: 'GetCapabilities',
      version
    }
  }).then(data => {
    if (!data) {
      return null;
    }
    const capabilities = (ogcService === 'WFS') ? format.read(data).value : format.read(data);
    return _layersFactory.build(capabilities, ogcService, urlToGet, version, options);
  }).then(layers => {
    if (!layers || layers.length === 0) {
      console.error('Missing layers');
    }
    return layers;
  });
};

CapabilitiesHelper.prototype._versions = {
  WMS: ['1.3.0', '1.1.0'],
  WFS: ['1.1.0', '1.0.0'],
  WMTS: ['1.0.0'],
  NCWMS: ['1.3.0', '1.1.0']
};

CapabilitiesHelper.prototype._servicesClass = {
  WMS: 'WMS',
  WFS: 'WFS',
  WMTS: 'WMTS',
  NCWMS: 'WMS'
};

module.exports = CapabilitiesHelper;
