var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <label for=\"ocean-unit-select-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\" class=\"col-md-6 oceano-select-unit-title\" data-i18n=\"oceano.nav.unit.selector\" ></label>\n    <div class=\"col-md-6 oceano-select-unit-div\" >\n        <div class=\"select-wrapper\">\n            <select class=\"ocean-select ocea-select-unit\" id=\"ocean-unit-select-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\"></select>\n            <i class=\"fas fa-chevron-down\"></i>\n        </div>\n    </div>\n</div>";
},"useData":true});