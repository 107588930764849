const moment = require('moment');

/**
 * Pipe datetimeformat
 *
 * take a string date '2098-12-07T05:16:11.000Z' and format
 * and return a date on format 2098-12-07 05:16:11
 *
 * Usage: {{datetimeformat datestring format}}
 */
module.exports = function (value, format) {
  const dateTime = moment(value).utc();
  if (dateTime.isValid()) {
    return dateTime.format(format);
  }
  return value;
};
