const d3 = require('d3');
const moment = require('moment');
const $ = require('jquery');

const valXChartTitle = -60;
const valYChartTitle = -15;

const ChartsUtils = module.exports = function (options) {
  options = options || {};
  this.config = options.config || window.CONFIG;
  this._colors = options.colors;
  this._formatTime = d3.time.format.utc(options.timeFormatter || '%d/%m %H:%M');
  this._title = options.title || '';
  this._horizontalLine = +options.horizontalLine === options.horizontalLine ? +options.horizontalLine : null;

  this._legend = [
    { color: this.config.ddm.colors.source1, id: $.i18n.t('ddm.labels.source1') },
    { color: this.config.ddm.colors.source2, id: $.i18n.t('ddm.labels.source2') },
    { color: this.config.ddm.colors.source3, id: $.i18n.t('ddm.labels.source3') },
    { color: this.config.ddm.colors.source4, id: $.i18n.t('ddm.labels.source4') },
    { color: this.config.ddm.colors.source5, id: $.i18n.t('ddm.labels.source5') },
    { color: this.config.ddm.colors.source6, id: $.i18n.t('ddm.labels.source6') },
    { color: this.config.ddm.colors.source7, id: $.i18n.t('ddm.labels.source7') },
    { color: this.config.ddm.colors.source8, id: $.i18n.t('ddm.labels.source8') },
    { color: this.config.ddm.colors.source9, id: $.i18n.t('ddm.labels.source9') },
    { color: this.config.ddm.colors.source10, id: $.i18n.t('ddm.labels.source10') }
  ];
};

ChartsUtils.prototype.drawDotChart = function (ids, data, options) {
  this.data = data;
  this.options = options || {};
  this.options.xTicks = options.xTicks || 15;
  this.options.yTicks = options.yTicks || 10;
  this.options.unit = options.unit || '';
  this.options.watermarks = options.watermarks || [];
  this._toolTipId = ids.toolTipId;
  this._containerId = ids.containerId;
  this._nphma = +options.nphma === options.nphma ? +options.nphma : null;
  this._npbma = +options.npbma === options.npbma ? +options.npbma : null;
  this._nivMoyen = +options.nivMoyen === options.nivMoyen ? +options.nivMoyen : null;
  this._enableNpma = options.enableNpma === true;
  this._zoomTimeout = false;
  this._zoomDispatcher = options.zoomDispatcher || null;
  this._dataSource = options.dataSource || '';
  this._selectedSources = options.selectedSources;
  this._surcoteSources = [7, 8];

  this._setExtents(options);

  // Empty the container
  $(`#${this._containerId}`).html('');

  // Prevent scroll on mousewheel
  $('.chart-render').bind('mousewheel DOMMouseScroll', () => false);

  // Set the dimensions of the canvas / graph
  const margin = {
    top: 30,
    right: 30,
    bottom: 110,
    left: 60
  };
  const width = $(`#${this._containerId}`).width() - margin.left - margin.right;
  const height = $(`#${this._containerId}`).height() - margin.top - margin.bottom;

  // Set the x & y scale
  const valueGap = (this.options.valuesExtent[1] - this.options.valuesExtent[0]) * 0.05;
  const minValueRange = this.options.valuesExtent[0] - valueGap;
  const maxValueRange = this.options.valuesExtent[1] + valueGap;
  this.x = d3.scale.linear().domain([this.options.datesExtent[0], this.options.datesExtent[1]]).range([0, width]);
  this.y = d3.scale.linear().domain([minValueRange, maxValueRange]).range([height, 0]);

  // Adds the svg canvas
  this.svg = d3.select(`#${this._containerId}`)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  // Define valueline
  this.valueline = d3.svg.line()
    .x(d => this.x(d.date))
    .y(d => this.y(d.value));

  // Add everything else
  this._addXTitle(this._formatYearTitle(options.start, options.end))
    ._addDotXAxis(height)
    ._addDotYAxis(width)
    ._addWatermarks(this.options.watermarks, width, height)
    ._addZoomBox(width, height, margin)
    ._addTipBox(width, height)
    ._addLines()
    ._addDataSource(width, height)
    ._addLegend(width, height)
    ._draw();

  return this._setChartReady();
};

ChartsUtils.prototype._setExtents = function (options) {
  this.options.datesExtent = options.start && options.end ? [options.start, options.end] : this._getExtent(this.data, 'date');
  this.options.valuesExtent = this._getExtent(this.data, 'value');

  if (this._enableNpma && this._npbma !== null && this._npbma < this.options.valuesExtent[0]) {
    this.options.valuesExtent[0] = this._npbma;
  }
  if (this._enableNpma && this._nphma !== null && this._nphma > this.options.valuesExtent[1]) {
    this.options.valuesExtent[1] = this._nphma;
  }

  const margin = (this.options.valuesExtent[1] - this.options.valuesExtent[0]) * 0.04;
  this.options.valuesExtent[0] -= margin;
  this.options.valuesExtent[1] += margin;
};

ChartsUtils.prototype._setChartReady = function () {
  // WARNING dirty-fix for trigger phantomjs
  // Render div when all watermarks are loaded
  if (!this.chartReady) {
    return setTimeout(this._setChartReady.bind(this), 100);
  }
  $(`#${this._containerId}`).append('<span id=\'render-chart-done\'></span>');
  return this;
};

ChartsUtils.prototype._draw = function () {
  this._updateDotXAxis();
  this._updateDotYAxis();

  this._updateLines();
  this._updateAll();
  this._zoom_update();

  this._adjustGrounds();

  return this;
};

ChartsUtils.prototype._selectCircle = function () {
  return this.svg.select('g.scatter')
    .data([[[]]])
    .selectAll('dot')
    .data(d => d);
};

ChartsUtils.prototype._updateLines = function () {
  // Add the horizontal line
  if (this._horizontalLine !== null) {
    this.svg.selectAll('.mean-line')
      .attr('x1', this.x(this.options.datesExtent[0]))
      .attr('y1', this.y(this._horizontalLine))
      .attr('x2', this.x(this.options.datesExtent[1]))
      .attr('y2', this.y(this._horizontalLine));
  }

  // Add NPHMA, NPBMA, niveau moyen
  if (this._enableNpma && this._nphma !== null) {
    this.svg.selectAll('.nphma-line')
      .attr('x1', this.x(this.options.datesExtent[0]))
      .attr('y1', this.y(this._nphma))
      .attr('x2', this.x(this.options.datesExtent[1]))
      .attr('y2', this.y(this._nphma));

    // Position is from a X or Y value plus/minus pixels
    this.svg.selectAll('.nphma-text')
      .attr('x', this.x(this.options.datesExtent[1]) - 3)
      .attr('y', this.y(this._nphma) - 5);
  }

  if (this._enableNpma && this._npbma !== null) {
    this.svg.selectAll('.npbma-line')
      .attr('x1', this.x(this.options.datesExtent[0]))
      .attr('y1', this.y(this._npbma))
      .attr('x2', this.x(this.options.datesExtent[1]))
      .attr('y2', this.y(this._npbma));

    // Position is from a X or Y value plus/minus pixels
    this.svg.selectAll('.npbma-text')
      .attr('x', this.x(this.options.datesExtent[1]) - 3)
      .attr('y', this.y(this._npbma) + 15);
  }

  if (this._enableNpma && this._nivMoyen !== null) {
    const deltaHeight = this._nphma - this._nivMoyen > this._nivMoyen - this._npbma ? -5 : 15;
    this.svg.selectAll('.nivmoyen-line')
      .attr('x1', this.x(this.options.datesExtent[0]))
      .attr('y1', this.y(this._nivMoyen))
      .attr('x2', this.x(this.options.datesExtent[1]))
      .attr('y2', this.y(this._nivMoyen));

    // Position is from a X or Y value plus/minus pixels
    this.svg.selectAll('.nivmoyen-text')
      .attr('x', this.x(this.options.datesExtent[1]) - 3)
      .attr('y', this.y(this._nivMoyen) + deltaHeight);
  }
};

ChartsUtils.prototype._updateAll = function () {
  this.svg.selectAll('circle.points').remove();
  this.svg.selectAll('path.vline').remove();

  for (const i in this.data) {
    if (this.data.hasOwnProperty(i) && this.data[i] && this.data[i].length > 0) {
      this._update(i);
    }
  }

  return this;
};

ChartsUtils.prototype._update = function (source) {
  const scatterBox = this.svg.select('g.scatter').data([this.data[source]]);

  const circle = scatterBox.selectAll('dot')
    .data(d => d);

  // Add the dots
  circle
    .attr('cx', d => this.x(d.date))
    .attr('cy', d => this.y(d.value))
    .enter()
    .append('svg:circle')
    .attr('class', `points point${source}`)
    .style('fill', this._colors[source])
    .attr('r', +source === 6 ? 1 : 2)
    .attr('cx', d => this.x(d.date))
    .attr('cy', d => this.y(d.value));

  // Add the valueline
  scatterBox.append('path')
    .attr('class', `vline line${source}`)
    .style('stroke', this._colors[source])
    .attr('d', this.valueline(this.data[source]));

  circle.exit().remove();

  return this;
};

ChartsUtils.prototype._launchZoomTimeout = function () {
  if (this._zoomTimeout) {
    clearTimeout(this._zoomTimeout);
  }
  this._zoomTimeout = setTimeout(this._dispatchZoom.bind(this), 250);
};

ChartsUtils.prototype._dispatchZoom = function () {
  if (this._zoomDispatcher) {
    this._zoomDispatcher();
  }
};

ChartsUtils.prototype._zoom_update = function () {
  this._launchZoomTimeout();
  this.xyzoom = d3.behavior.zoom()
    .x(this.x)
    .y(this.y)
    .on('zoom', this._draw.bind(this));

  this.xzoom = d3.behavior.zoom()
    .x(this.x)
    .on('zoom', this._draw.bind(this));

  this.yzoom = d3.behavior.zoom()
    .y(this.y)
    .on('zoom', this._draw.bind(this));

  this.svg.select('rect.zoom.xy.box').call(this.xyzoom);
  this.svg.select('rect.zoom.x.box').call(this.xzoom);
  this.svg.select('rect.zoom.y.box').call(this.yzoom);
};

ChartsUtils.prototype._addXTitle = function (date) {
  this.svg.append('text')
    .attr('id', 'xTitle')
    .attr('x', valXChartTitle)
    .attr('y', valYChartTitle)
    .style('text-anchor', 'start')
    .style('font-size', '130%')
    .text(`${this._title} ${date}`);

  return this;
};

ChartsUtils.prototype._updateXTitle = function (date) {
  this.svg.select('#xTitle').remove();
  this._addXTitle(date);
};

ChartsUtils.prototype._addDotXAxis = function (height) {
  // X-axis' definition
  this.xAxis = d3.svg.axis().scale(this.x)
    .orient('bottom')
    .innerTickSize(-height)
    .outerTickSize(0)
    .tickPadding(10)
    .ticks(this.options.xTicks)
    .tickFormat(this._formatDate);

  // Add X-axis
  this.svg.append('g')
    .attr('class', 'x axis')
    .attr('transform', `translate(0,${height})`);

  return this._updateDotXAxis();
};

ChartsUtils.prototype._updateDotXAxis = function () {
  this.svg.select('g.x.axis')
    .call(this.xAxis)
    .selectAll('text')
    .style('text-anchor', 'end')
    .attr('dx', '-.8em')
    .attr('dy', '.15em')
    .attr('transform', 'rotate(-45)translate(0,-5)');

  return this;
};

ChartsUtils.prototype._addDotYAxis = function (width) {
  // Y-axis' definition
  this.yAxis = d3.svg.axis().scale(this.y)
    .orient('left')
    .innerTickSize(-width)
    .outerTickSize(0)
    .tickPadding(10)
    .ticks(this.options.yTicks)
    .tickFormat(value => {
      value = Math.floor(value * 100) / 100;
      return value + (this.options.unit ? ` ${this.options.unit}` : '');
    });

  // Add Y-axis
  this.svg.append('g')
    .attr('class', 'y axis');

  return this._updateDotYAxis();
};

ChartsUtils.prototype._updateDotYAxis = function () {
  this.svg.select('g.y.axis').call(this.yAxis);

  return this;
};

ChartsUtils.prototype._addWatermarks = function (watermarks, width, height) {
  this.chartReady = false;
  if (watermarks.length === 0) {
    return this;
  }

  const nbImg = watermarks.length;
  let nbLoaded = 0;

  for (const i in watermarks) {
    if (!watermarks.hasOwnProperty(i)) {
      continue;
    }
    const xPosition = Math.ceil(width / (watermarks.length + 1) * (+i + 1) - 50);
    this.svg.append('svg:image')
      .on('load', () => {
        nbLoaded += 1;
        if (nbImg === nbLoaded) {
          this.chartReady = true;
        }
      })
      .attr('y', Math.ceil(height / 2 - 50))
      .attr('x', xPosition)
      .attr('width', 100)
      .attr('height', 100)
      .attr('opacity', '0.2')
      .attr('xlink:href', watermarks[i]);
  }

  return this;
};

ChartsUtils.prototype._addDataSource = function (width, height) {
  this.chartReady = false;
  this.svg.append('text')
    .attr('class', 'xDataSource')
    .attr('x', Math.ceil((width / 2) - 80))
    .attr('y', Math.ceil(height + 100))
    .style('text-anchor', 'start')
    .style('font-size', '100%')
    .text(this._dataSource);

  return this;
};

ChartsUtils.prototype._addLegend = function (width, height) {
  this.chartReady = false;

  let j = 0;

  let sources = this._selectedSources;
  if (this._containerId.indexOf('surcote') !== -1) {
    sources = sources.map((_source, index) => this._surcoteSources.indexOf(index) !== -1);
  }

  const sourceLength = sources.filter(Boolean).length;
  this._legend.forEach((legend, i) => {
    if (sources[i]) {
      const xPosition = Math.ceil(width / (sourceLength + 1) * (+j + 1));
      this.svg.append('text')
        .attr('class', 'xLegend')
        .attr('x', xPosition)
        .attr('y', Math.ceil(height + 80))
        .style('text-anchor', 'start')
        .style('font-size', '100%')
        .style('fill', legend.color)
        .text(legend.id);
      j += 1;
    }
  });
  return this;
};

ChartsUtils.prototype._addZoomBox = function (width, height, margin) {
  this.svg.append('defs')
    .append('clipPath')
    .attr('id', 'clip')
    .append('rect')
    .attr('width', width)
    .attr('height', height);

  this.svg.append('svg:rect')
    .attr('class', 'border')
    .attr('width', width)
    .attr('height', height)
    .style('stroke', 'none')
    .style('fill', 'none');

  this.svg.append('g')
    .attr('class', 'scatter')
    .attr('clip-path', 'url(#clip)');

  this.svg.append('svg:rect')
    .attr('class', 'zoom xy box')
    .attr('width', width)
    .attr('height', height)
    .style('visibility', 'hidden')
    .attr('pointer-events', 'all');

  this.svg.append('svg:rect')
    .attr('class', 'zoom x box')
    .attr('width', width)
    .attr('height', margin.bottom)
    .attr('transform', `translate(${0},${height})`)
    .style('visibility', 'hidden')
    .attr('pointer-events', 'all');

  this.svg.append('svg:rect')
    .attr('class', 'zoom y box')
    .attr('width', margin.left)
    .attr('height', height)
    .attr('transform', `translate(${-margin.left},${0})`)
    .style('visibility', 'hidden')
    .attr('pointer-events', 'all');

  return this;
};

ChartsUtils.prototype._addTipBox = function () {
  this.tooltip = d3.select(`#${this._toolTipId}`);
  this.tipBox = this.svg.select('rect.zoom.xy.box')
    .on('mousemove', this._drawTooltip.bind(this))
    .on('mouseout', this._removeTooltip.bind(this));

  return this;
};

ChartsUtils.prototype._addLines = function () {
  const circle = this._selectCircle();

  // Add the horizontal line
  if (this._horizontalLine !== null) {
    circle.enter().append('line')
      .attr('class', 'mean-line')
      .style('stroke', this._colors[0]);
  }

  // Add NPHMA, NPBMA & niveau moyen
  if (this._enableNpma && this._nphma !== null) {
    circle.enter().append('line')
      .attr('class', 'nphma-line')
      .style('stroke', this.config.ddm.colors.nphma)
      .style('stroke-dasharray', '3, 3');

    circle.enter().append('text')
      .attr('class', 'nphma-text')
      .style('fill', this.config.ddm.colors.nphma)
      .style('text-anchor', 'end')
      .text($.i18n.t('ddm.charts.nphma'));
  }

  if (this._enableNpma && this._npbma !== null) {
    circle.enter().append('line')
      .attr('class', 'npbma-line')
      .style('stroke', this.config.ddm.colors.npbma)
      .style('stroke-dasharray', '3, 3');

    circle.enter().append('text')
      .attr('class', 'npbma-text')
      .style('fill', this.config.ddm.colors.npbma)
      .style('text-anchor', 'end')
      .text($.i18n.t('ddm.charts.npbma'));
  }

  if (this._enableNpma && this._nivMoyen !== null) {
    circle.enter().append('line')
      .attr('class', 'nivmoyen-line')
      .style('stroke', this.config.ddm.colors.nivMoyen)
      .style('stroke-dasharray', '3, 3');

    circle.enter().append('text')
      .attr('class', 'nivmoyen-text')
      .style('fill', this.config.ddm.colors.nivMoyen)
      .style('text-anchor', 'end')
      .text($.i18n.t('ddm.charts.nivmoyen'));
  }

  return this;
};

ChartsUtils.prototype._adjustGrounds = function () {
  this.svg.selectAll('.point0').moveToFront();
  this.svg.selectAll('.point1').moveToFront();
  this.svg.selectAll('.point2').moveToFront();
  this.svg.selectAll('.point3').moveToFront();
  this.svg.selectAll('.point4').moveToFront();
  this.svg.selectAll('.point5').moveToFront();
  this.svg.selectAll('.point9').moveToFront();

  if (this._enableNpma && this._nphma !== null) {
    this.svg.select('.nphma-line').moveToFront();
    this.svg.select('.nphma-text').moveToFront();
  }

  if (this._enableNpma && this._npbma !== null) {
    this.svg.select('.npbma-line').moveToFront();
    this.svg.select('.npbma-text').moveToFront();
  }

  if (this._enableNpma && this._nivMoyen !== null) {
    this.svg.select('.nivmoyen-line').moveToFront();
    this.svg.select('.nivmoyen-text').moveToFront();
  }

  return this;
};

ChartsUtils.prototype._formatDate = function (date) {
  return moment.utc(date).format('DD/MM HH:mm');
};

ChartsUtils.prototype._formatYearTitle = function (dateStart, dateEnd) {
  const momentStart = moment.utc(dateStart).format('YYYY');
  const momentStop = moment.utc(dateEnd).format('YYYY');
  if (momentStart === momentStop) {
    return momentStart;
  }
  return `${momentStart}-${momentStop}`;
};

ChartsUtils.prototype._getExtent = function (data, field) {
  let min = null; let
    max = null;
  for (const i in data) {
    if (!data.hasOwnProperty(i)) {
      continue;
    }
    for (const j in data[i]) {
      if (!data[i].hasOwnProperty(j)) {
        continue;
      }
      if (min === null || data[i][j][field] < min) {
        min = data[i][j][field];
      }
      if (max === null || data[i][j][field] > max) {
        max = data[i][j][field];
      }
    }
  }

  return [min, max];
};

ChartsUtils.prototype._getElementPosition = function (element) {
  const elementPosition = element.getBoundingClientRect();
  const elementX = (elementPosition.x || elementPosition.x === 0) ? +elementPosition.x : +elementPosition.left;
  const elementY = (elementPosition.y || elementPosition.y === 0) ? +elementPosition.y : +elementPosition.top;
  return [elementX, elementY];
};

ChartsUtils.prototype._drawTooltip = function () {
  const date = this.x.invert(d3.mouse(this.tipBox.node())[0]);

  const mousePosition = d3.mouse(document.body);
  const elementPosition = this._getElementPosition($(`#${this._containerId}`)[0]);

  const x = mousePosition[0] - elementPosition[0] + 15;
  const y = mousePosition[1] - elementPosition[1] - 15;

  this.tooltip.html(`<b>${this._formatDate(date)}</b>`)
    .style('display', 'block')
    .style('left', `${x}px`)
    .style('top', `${y}px`)
    .selectAll()
    .data(this.data)
    .enter()
    .append('div')
    .html((data, source) => {
      const momentDate = moment(date);
      let value = null;
      let minDiff = 1000 * 60 * 15;

      for (const i in data) {
        if (!data.hasOwnProperty(i)) {
          continue;
        }
        const difference = Math.abs(momentDate.diff(moment(data[i].date)));
        if (difference < minDiff) {
          minDiff = difference;
          value = data[i].value;
        }
      }

      if (value === null) {
        return '';
      }

      return `<span class="color_square" style="background-color: ${this._colors[source]}"></span>${value}${this.options.unit ? ` ${this.options.unit}` : ''}`;
    });
};

ChartsUtils.prototype._removeTooltip = function () {
  this.tooltip.style('display', 'none');
};

ChartsUtils.prototype.updateData = function (data, options) {
  this._enableNpma = options.enableNpma === true;
  this._setExtents(options);
  this._updateXTitle(this._formatYearTitle(options.start, options.end));
  this.data = data;
  this._draw();
};

ChartsUtils.prototype.getTimeInfos = function () {
  return [
    this.x.domain()[0],
    this.x.domain()[1]
  ];
};

d3.selection.prototype.moveToFront = function () {
  return this.each(function () {
    this.parentNode.appendChild(this);
  });
};

d3.selection.prototype.moveToBack = function () {
  return this.each(function () {
    const { firstChild } = this.parentNode;
    if (firstChild) {
      this.parentNode.insertBefore(this, firstChild);
    }
  });
};

module.exports = ChartsUtils;
