const $ = require('jquery');

/**
 * Add specific boostrap validators
 */
(function ($) {
  $.fn.bootstrapValidator.validators.uniqueMapName = {
    /**
     * Validate that the map name is unique for this user
     *
     * @param validator unused
     * @param $field the jquery field to validate
     * @param options specific options for validator :
          models: The existing maps model array
          currentModel: the current map model
     * @return {boolean} false if the name is already used by an existing map
     */
    validate(validator, $field, options) {
      const name = $field.val();
      const currentName = options.currentModel.attributes.name;
      for (const map of options.models) {
        if (map.attributes.name === name && map.attributes.name !== currentName) {
          return false;
        }
      }

      return true;
    }
  };
}(window.jQuery));

module.exports = function (models, currentModel) {
  return {
    'map-name': {
      validators: {
        notEmpty: {
          message: $.i18n.t('drawing.modal.map-properties.mapNameMissing')
        },
        uniqueMapName: {
          models,
          currentModel,
          message: $.i18n.t('drawing.modal.map-properties.mapNameAlreadyExist')
        }
      }
    }
  };
};
