const Dialog = require('bootstrap-dialog');
const $ = require('jquery');
const TopRibbonView = require('../view/top-ribbon.view');
const JsonFileLoader = require('../utils/json-file-loader.js');
const ContextFileLoader = require('../utils/context-file-loader.js');
const Styles = require('../utils/gis/drawing/discuss-styles');
const kmlHelper = require('../utils/gis/kml-helper');

const ShareService = {

  sendExportContextFile() {
    // Generate new id context
    this._idContext = this._generateId();

    // Get kml and xml data from gis
    this.kmlDocument = this._gisView.exportKmlData();
    this.contextDocument = this._gisView.exportContext();
    const form = new FormData();
    const xml = new Blob([this.contextDocument], { type: 'text/xml', name: 'context.xml' });
    form.append('contexts', xml, 'context.xml');
    if (this.kmlDocument) {
      const kml = new Blob([this.kmlDocument], { type: 'text/xml', name: 'context.kml' });
      form.append('contexts', kml, 'context.kml');
    }
    form.append('contextId', this._idContext);
    return this._gisView._user.doReq({
      method: 'POST',
      url: this._config.share.shareContextApi,
      data: form,
      contentType: false,
      processData: false
    });
  },

  _generateId() {
    const min = 100000;
    const max = 999999;
    const stamp = new Date().getTime();
    const numbers = Math.floor(Math.random() * (max - min)) + min;
    return `${stamp}-${numbers}`;
  },

  getIdContext() {
    return this._idContext;
  },

  loadSharedContext() {
    if (this._lang === 'fr') {
      this._topRibbonView.setFrenchLang({
        preventDefault() {
        }
      });
    } else if (this._lang === 'en') {
      this._topRibbonView.setEnglishLang({
        preventDefault() {
        }
      });
    }
    this.getContextInfos();
  },

  getContextInfos() {
    JsonFileLoader.loadContextInfo(`${this._config.share.shareContextApi.concat(this._contextId)}`).then(context => {
      if (context && context.contextId) {
        this._context = context;
        this.loadContext();
      } else {
        return Dialog.show({
          type: Dialog.TYPE_DANGER,
          title: $.i18n.t('modal.confirmation.title'),
          message: $.i18n.t('share.context.notFound')
        });
      }
    });
  },

  loadContext() {
    ContextFileLoader.loadContextXmlOrKmlFileContent(`${this._config.share.shareBaseUrl.concat(this._context.contextUrl)}`)
      .then(
        contextData => {
          this._gisView.loadContext(contextData);
          if (this._context.kmlUrl) {
            this.loadKmlFileContent(`${this._config.share.shareBaseUrl.concat(this._context.kmlUrl)}`)
              .then(layer => {
                if (layer) {
                  this._gisView.collection.add(layer);
                  layer.set('includedInMap', true);
                }
              }, error => {
                console.error(error);
                this.alertError('kml');
              });
          }
        },
        errorContextXml => {
          console.error(errorContextXml);
          if (this._context.kmlUrl) {
            this.loadKmlFileContent(`${this._config.share.shareBaseUrl.concat(this._context.kmlUrl)}`)
              .then(layer => {
                if (layer) {
                  this._gisView.collection.add(layer);
                  layer.set('includedInMap', true);
                }
                this.alertError('xmlAndKmlPresents');
              }, errorKmlData => {
                console.error(errorKmlData);
                this.alertError('nofile');
              });
          } else {
            this.alertError('nofile');
          }
        }
      );
  },

  loadKmlFileContent(fileUrl) {
    return $.get(fileUrl)
      .then(data => kmlHelper.createKmlLayer(
        kmlHelper.createVectorLayer(kmlHelper.kmlToFeatures(data)),
        $.i18n.t('share.kmlLayerTitle')
      )).fail(error => {
        console.error(error);
      });
  },

  alertError(fileType) {
    return Dialog.show({
      type: Dialog.TYPE_DANGER,
      title: $.i18n.t('modal.confirmation.title'),
      message: $.i18n.t(`share.context.notFoundFile.${fileType}`)
    });
  }
};

module.exports = function (options) {
  const service = Object.create(ShareService);
  service._gisView = options.gisView || window.GISVIEW;
  service._topRibbonView = options.topRibbonView;
  service._config = options.config || window.CONFIG;
  service._lang = options.lang;
  service._contextId = options.contextId;
  return service;
};
