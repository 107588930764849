import VectorSource from 'ol/source/Vector';
import ImageLayer from 'ol/layer/Image';
import ImageStaticSource from 'ol/source/ImageStatic';
import ImageWMSSource from 'ol/source/ImageWMS';
import VectorLayer from 'ol/layer/Vector';
import { Stroke, Style } from 'ol/style.js';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';

const GisView = require('../gis.view.js');
const CoordsHelper = require('../../utils/gis/coordinates-helper.js');

GisView.prototype.addMntImageLayer = function (url, data) {
  const splittedData = data.split(',');
  const y = (parseFloat(splittedData[3]) - parseFloat(splittedData[2])) / 2;

  const lonlatExtent = [parseFloat(splittedData[0]), parseFloat(splittedData[2]) - y, parseFloat(splittedData[1]), parseFloat(splittedData[3])];
  const extent = CoordsHelper.convertLonLatBboxToMercator(lonlatExtent);
  const lonlatBbox = [parseFloat(splittedData[0]), parseFloat(splittedData[2]), parseFloat(splittedData[1]), parseFloat(splittedData[3])];
  const bbox = CoordsHelper.convertLonLatBboxToMercator(lonlatBbox);
  this.zoomToExtent(extent);
  this._mntImageLayer = new ImageLayer({
    source: new ImageStaticSource({
      url,
      imageExtent: bbox
    })
  });
  this._mntImageLayer.set('isMntLayer', true);
  this._map.addLayer(this._mntImageLayer);
};

GisView.prototype.removeMntLayer = function () {
  if (this._mntImageLayer) {
    this._map.removeLayer(this._mntImageLayer);
    delete this._mntImageLayer;
  }
};

GisView.prototype.getMntLayer = function () {
  return this._mntImageLayer;
};

GisView.prototype.addMntBlendedWmsLayer = function (layerName, modelBbox, geoserverUrl) {
  const splittedModelBbox = modelBbox.split('/');
  const lonlatBbox = [parseFloat(splittedModelBbox[2]), parseFloat(splittedModelBbox[0]), parseFloat(splittedModelBbox[3]), parseFloat(splittedModelBbox[1])];
  const bbox = CoordsHelper.convertLonLatBboxToMercator(lonlatBbox);
  const y = (lonlatBbox[3] - lonlatBbox[1]) / 2;
  const x = (lonlatBbox[2] - lonlatBbox[0]) / 6;

  const extentLonlat = [lonlatBbox[0] + x, lonlatBbox[1] - y, lonlatBbox[2] - x, lonlatBbox[3]];
  const extent = CoordsHelper.convertLonLatBboxToMercator(extentLonlat);
  this.zoomToExtent(extent);
  this._mntBlendedWmsLayer = new ImageLayer({
    source: new ImageWMSSource({
      extent: bbox,
      ratio: 1,
      url: geoserverUrl,
      params: {
        LAYERS: `MNTLAYERS:${layerName}`,
        VERSION: '1.1.1',
        FORMAT_OPTIONS: 'layout:mnt_decoration'
      },
      serverType: 'geoserver'
    })
  });
  this._mntBlendedWmsLayer.set('isMntLayer', true);
  this._map.addLayer(this._mntBlendedWmsLayer);
};

GisView.prototype.removeMntBlendedWmsLayer = function () {
  if (this._mntBlendedWmsLayer) {
    this._map.removeLayer(this._mntBlendedWmsLayer);
    delete this._mntBlendedWmsLayer;
  }
};

GisView.prototype.getMntBlendedWmsLayer = function () {
  return this._mntBlendedWmsLayer;
};

GisView.prototype.getShomMntLayer = function () {
  return this._shomMntLayer;
};

GisView.prototype.removeShomMntLayer = function () {
  if (this._shomMntLayer) {
    this._map.removeLayer(this._shomMntLayer);
    delete this._shomMntLayer;
  }
};

GisView.prototype.drawShomMnts = function (shomMnts) {
  const features = [];
  const style = new Style({
    stroke: new Stroke({
      color: '#3399cc',
      width: 3
    })
  });
  for (let i = 0; i < shomMnts.length; i++) {
    const points = [];

    const point1 = [parseFloat(shomMnts[i].bbox.east), parseFloat(shomMnts[i].bbox.north)];
    const point2 = [parseFloat(shomMnts[i].bbox.east), parseFloat(shomMnts[i].bbox.south)];
    const point3 = [parseFloat(shomMnts[i].bbox.west), parseFloat(shomMnts[i].bbox.south)];
    const point4 = [parseFloat(shomMnts[i].bbox.west), parseFloat(shomMnts[i].bbox.north)];

    points.push(CoordsHelper.convertLonLatToMercat(point1));
    points.push(CoordsHelper.convertLonLatToMercat(point2));
    points.push(CoordsHelper.convertLonLatToMercat(point3));
    points.push(CoordsHelper.convertLonLatToMercat(point4));

    features.push(new Feature({
      geometry: new Polygon([points]),
      target: 'polygon',
      name: shomMnts[i].name
    }));
  }

  const source = new VectorSource();
  source.addFeatures(features);

  this._shomMntLayer = new VectorLayer({
    source,
    style,
    title: 'Couche de représentation SHOM MNT'
  });

  this._map.addLayer(this._shomMntLayer);
};
