const $ = require('jquery');
const _ = require('underscore');

const readNginxPage = function (htmlData, regex) {
  const res = [];
  const lines = htmlData.split(/\n/);
  _.each(lines, line => {
    const matches = line.match(/<a\s*href="(.*)">(.*)<\/a>/);
    if (matches) {
      const href = matches[1];
      if (!regex || (regex && href.match(regex))) {
        res.push(href);
      }
    }
  });
  return res;
};

const NginxWebPageBrowser = function (rootUrl) {
  this._rootUrl = rootUrl;
};

NginxWebPageBrowser.prototype.getLinksFromFolder = function (folderRelativePath = [], regex = null) {
  const promise = $.Deferred();

  $.get(`${this._rootUrl}/${folderRelativePath}`)
    .then(htmlData => {
      promise.resolve(readNginxPage(htmlData, regex));
    })
    .fail(error => {
      promise.reject(error);
    });

  return promise;
};

module.exports = NginxWebPageBrowser;
