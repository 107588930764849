const $ = require('jquery');
const _ = require('underscore');
const ShomView = require('../../../core/shom-view.js');
const template = require('../../../../template/ddm/ddm-dl/ddm-dl-modal.hbs');
const Loading = require('../../../utils/loading');

const DDMService = require('../../../service/ddm.js');

module.exports = ShomView.build({

  initialize(options) {
    const optionsToUse = options || {};
    this.config = optionsToUse.config || window.CONFIG;
    this._title = optionsToUse.title;
    this._info = optionsToUse.info;
    this._idSensor = optionsToUse.idSensor;
    this._modalView = optionsToUse.modalView;
    this._directDownload = optionsToUse.directDownload || false;

    this._ddmService = new DDMService(this.config);
  },

  /** ***************************** */
  /** ****** RENDER FUNCTION ****** */
  /** ***************************** */

  render() {
    this.$el.html(template({
      directDownload: this._directDownload
    }));

    const helperUrl = this.config.ddm.ddm_dl.stream.helper_url;

    this.$title = this.$el.find('#ddm-dl-modal-title');
    this.$title.attr('data-i18n', this._title);
    this.$info = this.$el.find('#ddm-dl-modal-info');
    this.$info.attr('data-i18n', this._info);

    this.$helperUrl = this.$('#ddm-dl-modal-helper-link');
    this.$helperUrl.html(helperUrl);
    this.$helperUrl.attr('href', helperUrl);

    this.$sendButton = this.$el.find('.send-button');
    this.$sendButton.click(_.bind(this._onSendButton, this));

    this._inputName = this.$el.find('#ddm-dl-name');
    this._inputMail = this.$el.find('#ddm-dl-mail');
    this._checkboxContact = this.$el.find('#ddm-dl-contact');

    return this;
  },

  _showErrors(nameValue, mailValue) {
    const $mailContainer = this.$el.find('#mail-error-label');
    const $nameContainer = this.$el.find('#name-error-label');
    if (!this._mailValidation(mailValue)) {
      $mailContainer.html(
        '<div class="alert alert-danger" role="alert">'
                + '<span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>'
                + `<span class="sr-only">Error:</span> ${
                  $.i18n.t('ddm.dl.stream.error.mail')
                }</div>`
      );
    } else {
      $mailContainer.html('');
    }
    if (!this._nameValidation(nameValue)) {
      $nameContainer.html(
        '<div class="alert alert-danger" role="alert">'
                + '<span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>'
                + `<span class="sr-only">Error:</span> ${
                  $.i18n.t('ddm.dl.stream.error.name')
                }</div>`
      );
    } else {
      $nameContainer.html('');
    }
  },

  /** ***************************** */
  /** ****** EVENT FUNCTION ******* */
  /** ***************************** */

  _onSendButton() {
    const name = this._inputName.val();
    const mail = this._inputMail.val();
    const contact = this._checkboxContact.prop('checked');

    const $button = this.$el.find('.send-button');

    this._hideMessages();
    Loading.start($button);

    if (this._nameValidation(name) && this._mailValidation(mail)) {
      const dataToSend = {
        name,
        mail,
        contact,
        type: 'FLUX',
        idSensor: this._idSensor
      };

      this._ddmService.getDlStream(dataToSend)
        .then(_.bind(this._modalValidation, this))
        .then(() => {
          Loading.stop($button);
        })
        .fail(
          _.bind(err => {
            console.error('error ', err);
            Loading.stop($button);
          }, this)
        );
    } else {
      Loading.stop($button);
      this._showErrors(name, mail);
    }
  },

  _modalValidation() {
    this.$title.attr('data-i18n', 'ddm.dl.modal.validation.title').i18n();
    this.$info.attr('data-i18n', 'ddm.dl.modal.validation.info').i18n();

    this._showValidationMessage();
  },

  _hideMessages() {
    const $validationContainer = this.$el.find('#ddm-dl-alert-container');
    $validationContainer.html('');
    const $mailContainer = this.$el.find('#mail-error-label');
    $mailContainer.html('');
    const $nameContainer = this.$el.find('#name-error-label');
    $nameContainer.html('');
  },

  _showValidationMessage() {
    let html = '<div class=\'alert alert-success alert-dismissible\' role=\'alert\'>';
    html += '<button type=\'button\' class=\'close\' data-dismiss=\'alert\' aria-label=\'Close\'><span aria-hidden=\'true\'>&times;</span></button>';
    html += $.i18n.t('ddm.dl.stream.validation');
    html += '</div>';

    const $container = this.$el.find('#ddm-dl-modal-alert-container');
    $container.html(html);
  },

  /** ***************************** */
  /** ****** UTILS FUNCTION ******* */
  /** ***************************** */

  _mailValidation(textValue) {
    if (textValue === '') {
      return false;
    }
    const reg = /^[a-z0-9]+([_|.|-]{1}[a-z0-9]+)*@[a-z0-9]+([_|.|-]{1}[a-z0-9]+)*[.]{1}[a-z]{2,6}$/i;
    return reg.test(textValue);
  },

  _nameValidation(textValue) {
    if (textValue === '') {
      return false;
    }
    const reg = new RegExp(/[A-Za-z0-9]+/);
    return reg.test(textValue);
  }
});
