const $ = require('jquery');

const JsonFileLoader = {};

JsonFileLoader.loadAdditionnalInfo = conf => JsonFileLoader.loadAndCheckJson(conf.additionalInfo);
JsonFileLoader.loadContextInfo = url => JsonFileLoader.loadAndCheckJson(url);
JsonFileLoader.loadHelpLinks = conf => JsonFileLoader.loadAndCheckJson(conf.helpLinksUrl);

JsonFileLoader.loadAndCheckJson = url => {
  const promise = $.Deferred();
  $.getJSON(url).then(data => {
    promise.resolve(data);
  }, (response, errorName, error) => {
    if (errorName === 'parsererror') {
      console.error('JSON file is invalid !');
      console.error(error);
      promise.resolve({});
    } else {
      // no file : no additional data
      promise.resolve({});
    }
  });
  return promise;
};

JsonFileLoader.loadJson = fileUrl => {
  const promise = $.Deferred();

  $.getJSON(fileUrl, data => {
    promise.resolve(data);
  }).fail(error => {
    console.error(error);
    promise.resolve({ error: true });
  });

  return promise;
};

module.exports = JsonFileLoader;
