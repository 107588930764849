import XMLFeature from 'ol/format/XMLFeature.js';
import * as XMLFormat from 'ol/format/XML';
import VectorSource from 'ol/source/Vector';
import TileWMSSource from 'ol/source/TileWMS';
import TileSource from 'ol/source/Tile';
import TileLayer from 'ol/layer/Tile';
import Group from 'ol/layer/Group';
import { toSize } from 'ol/size.js';
import { boundingExtent } from 'ol/extent.js';
import { getAllTextContent } from 'ol/xml.js';

const _ = require('underscore');
const $ = require('jquery');

const wrongNamespace = 'http://www.opengeospatial.net/context';

/**
 * @classdesc
 * Format for reading WMS capabilities data
 *
 * @constructor
 * @extends {ol.format.XML}
 * @api
 */
const WMC = function (options = {}) {
  this.version = options.version || '1.0.0';

  this.namespaces = {
    ol: 'http://openlayers.org/context',
    wmc: 'http://www.opengis.net/context',
    sld: 'http://www.opengis.net/sld',
    xlink: 'http://www.w3.org/1999/xlink',
    xsi: 'http://www.w3.org/2001/XMLSchema-instance',
    shom: 'http://www.shom.fr/context'
  };

  this.defaultPrefix = 'wmc';

  if (options && options.wrongNamespace === true) {
    this.namespaces.wmc = wrongNamespace;
  }

  this._shomUser = options.shomUser;
};

WMC.__proto__ = XMLFeature;
WMC.prototype = Object.create(XMLFeature && XMLFeature.prototype);
WMC.prototype.constructor = WMC;

WMC.prototype.initialize = function () {
  XMLFeature.call(this);
};

WMC.prototype.layerToContext = function (olLayer) {
  const context = {
    name: olLayer.get('identifier'),
    title: olLayer.get('title') || '',
    abstract: olLayer.get('abstract') || olLayer.get('title'),
    metadataURL: olLayer.get('metadataURL'),
    legendUrl: olLayer.get('legendUrl'),
    queryable: !!olLayer.get('queryable'),
    opacity: olLayer.getOpacity()
  };

  const source = this._getSource(olLayer);
  let url = '';
  let params = '';
  if (source && olLayer.get('title')) {
    if (source instanceof VectorSource) {
      url = source.getUrl();
    } else {
      const urls = source.getUrls();
      url = urls && (urls[0] || '');
    }
    params = source.getParams ? source.getParams() : '';
  }

  if (olLayer.get('dimensions')) {
    context.dimensions = this._formatDimensions(olLayer.get('dimensions'));
  }

  url = this._addSessionIdInUrl(url);

  const extent = olLayer.getExtent();
  if (extent) {
    context.maxExtent = extent;
  }

  return this._addServerAndFormatsToContext(context, source, url, params);
};

/**
 * @param {string} url
 * @returns {string}
 * @private
 */
WMC.prototype._addSessionIdInUrl = function (url) {
  // we have to replace session ID with user key for private layers
  if (this._shomUser && this._shomUser.get('isLoggedIn')) {
    return url.replace(this._shomUser.get('sessid'), this._shomUser.get('key'));
  }
  return url;
};

WMC.prototype._addServerAndFormatsToContext = function (context, source, url, params) {
  if (!source || source instanceof VectorSource) {
    if (context.name !== undefined) {
      context.server = {
        version: '1.0.0',
        url: 'https://services.data.shom.fr'
      };
      context.formats = [{ value: 'application/json' }];
      return context;
    }
    return null;
  }

  if (source instanceof TileWMSSource) {
    context.server = {
      service: 'OGC:WMS',
      version: params.VERSION,
      url
    };
    context.formats = [{ value: params.FORMAT }];
  } else if (source instanceof TileSource) {
    context.server = {
      version: source.getVersion(),
      service: 'OGC:WMTS',
      url
    };
    context.formats = [{ value: source.getFormat() }];
  }
  return context;
};

/**
 * Format dimensions from olLayer to return wmc writable dimension list
 * @param {object} olDimensions
 * @returns {{}}
 * @private
 */
WMC.prototype._formatDimensions = function (olDimensions) {
  const formatedDimensions = {};

  for (const dimName in olDimensions) {
    if (!olDimensions.hasOwnProperty(dimName)) {
      continue;
    }
    let formatedDimension;
    const dimension = olDimensions[dimName];
    if (dimName === 'processedTime') {
      // Fromat 'proecessedTime' and change dimension name to 'time'
      formatedDimension = {
        values: this._formatDimensionProcessedTime(dimension),
        name: 'time',
        multipleValues: 'true',
        current: 'true'
      };
    } else if (Array.isArray(dimension)) {
      formatedDimension = {
        values: dimension,
        name: dimName
      };
    }

    if (formatedDimension) {
      formatedDimensions[dimName] = formatedDimension;
    }
  }
  return formatedDimensions;
};

/**
 * Format time entry from dimension layer. Example:
 * processedTime: {'2020-31-01': ['00:00:00.000Z', '01:00:00.000Z', '02:00:00.000Z']}
 * returns: ['2020-31-01T00:00:00.000Z', '2020-31-01T01:00:00.000Z', 2020-31-01T02:00:00.000Z']
 * @param {Object.<string, array<string>>} processedTime
 * @returns array<string>
 * @private
 */
WMC.prototype._formatDimensionProcessedTime = function (processedTime) {
  let times = [];
  for (const date in processedTime) {
    if (!processedTime.hasOwnProperty(date)) {
      continue;
    }
    times = times.concat(processedTime[date].map(time => `${date}T${time}`));
  }
  return times;
};

WMC.prototype._getSource = function (olLayerOrGroup) {
  let source;
  if (olLayerOrGroup instanceof Group) {
    const firstLayer = this._getFirstLayer(olLayerOrGroup);
    if (firstLayer) {
      source = firstLayer.getSource();
    }
  } else {
    source = olLayerOrGroup.getSource();
  }
  return source;
};

WMC.prototype._getFirstLayer = function (olLayerOrGroup) {
  if (olLayerOrGroup instanceof Group) {
    const layers = olLayerOrGroup.getLayers().getArray();
    if (layers.length > 0) {
      const firstLayer = layers[0];
      if (firstLayer instanceof Group) {
        return this._getFirstLayer(firstLayer);
      }

      if (firstLayer instanceof TileLayer) {
        return firstLayer;
      }
    }
    return null;
  }

  return olLayerOrGroup;
};

/**
 * Method: toContext
 * Create a context object free from layer given a map or a
 * context object.
 *
 * Parameters:
 * obj - {<OpenLayers.Map> | Object} The map or context.
 *
 * Returns:
 * {Object} A context object.
 */
WMC.prototype.toContext = function (obj) {
  const context = {};

  const layers = obj.getLayers().getArray();
  const metadata = obj.metadata || {};
  context.size = obj.getSize();
  context.bounds = obj.getView().calculateExtent(obj.getSize());
  context.projection = obj.getView().getProjection();
  context.title = obj.get('title') || '';
  context.keywords = metadata.keywords;
  context.abstract = metadata.abstract;
  context.logo = metadata.logo;
  context.descriptionURL = metadata.descriptionURL;
  context.contactInformation = metadata.contactInformation;
  context.maxExtent = obj.maxExtent;

  if (context.layersContext === undefined) {
    context.layersContext = [];
  }

  // let's convert layers into layersContext object (if any)
  if (layers !== undefined) {
    this._convertLayersIntoLayersContexts(layers, context);
  }
  return context;
};

WMC.prototype._convertLayersIntoLayersContexts = function (layers, context) {
  for (let i = 0, len = layers.length; i < len; i++) {
    const layerContext = this.layerToContext(layers[i]);
    if (layerContext !== null) {
      context.layersContext.push(layerContext);
    }
  }
};

WMC.prototype.read = function (data) {
  if (typeof data === 'string') {
    data = $.parseXML(data);
  }
  const root = data.documentElement;
  this.rootPrefix = root.prefix;
  const context = {
    version: root.getAttribute('version')
  };
  this.runChildNodes(context, root);
  return context;
};

WMC.prototype.runChildNodes = function (obj, node) {
  const children = node.childNodes;
  let childNode; let processor; let prefix; let
    local;
  let i = 0;
  const len = children.length;
  for (; i < len; ++i) {
    childNode = children[i];
    if (+childNode.nodeType === 1) {
      prefix = this.getNamespacePrefix(childNode.namespaceURI);
      local = childNode.nodeName.split(':').pop();
      processor = this[`read_${prefix}_${local}`];
      if (processor) {
        processor.apply(this, [obj, childNode]);
      }
    }
  }
};

WMC.prototype.getChildValue = function (node) {
  return getAllTextContent(node, false);
};

WMC.prototype.read_shom_Layer = function (context, node) {
  this.runChildNodes(context, node);
};

WMC.prototype.read_shom_Category = function (context, node) {
  const category = this.getChildValue(node);
  const index = +node.getAttribute('index') || 0;
  if (category) {
    context.category = {
      name: category,
      index
    };
  }
};

WMC.prototype.read_shom_Downloadable = function (context, node) {
  const downloadable = this.getChildValue(node);
  if (downloadable) {
    context.downloadable = (downloadable === 'true');
  }
};

WMC.prototype.read_shom_englishName = function (context, node) {
  const englishName = this.getChildValue(node);
  if (englishName) {
    context.englishName = englishName;
  }
};

WMC.prototype.read_shom_GfiResourceWmsV = function (context, node) {
  const name = this.getChildValue(node);
  if (name) {
    context.gfiResourceWmsV = name;
  }
};

WMC.prototype.read_shom_ExtractableResourceWms = function (context, node) {
  const name = this.getChildValue(node);
  if (name) {
    context.extractableResourceWms = name;
  }
};

WMC.prototype.read_shom_ExtractableResourceWfs = function (context, node) {
  const name = this.getChildValue(node);
  if (name) {
    context.extractableResourceWfs = name;
  }
};

WMC.prototype.read_shom_ProductId = function (context, node) {
  const value = this.getChildValue(node);
  if (value) {
    context.productId = value;
  }
};

WMC.prototype.read_shom_LegendUrl = function (context, node) {
  const value = this.getOnlineResource_href(node);
  if (value) {
    context.legendUrl = value;
  }
};

WMC.prototype.read_shom_Originators = function (context, node) {
  const originators = [];
  this.runChildNodes(originators, node);
  context.originators = originators;
};

WMC.prototype.read_shom_Originator = function (context, node) {
  const originator = {};
  originator.name = node.getAttribute('Name');
  this.runChildNodes(originator, node);
  if (context instanceof Array) {
    context.push(originator);
  }
};

WMC.prototype.read_shom_Logo = function (context, node) {
  const value = this.getChildValue(node);
  if (value) {
    context.logo = value;
  }
};

WMC.prototype.read_shom_URL = function (context, node) {
  const value = this.getChildValue(node);
  if (value) {
    context.url = value;
  }
};

WMC.prototype.read_shom_Opacity = function (context, node) {
  const value = this.getChildValue(node);
  if (value) {
    context.opacity = parseFloat(value);
  }
};

WMC.prototype.getNamespacePrefix = function (uri) {
  let prefix = null;
  if (uri == null) {
    prefix = this.namespaces[this.defaultPrefix];
  } else {
    for (prefix in this.namespaces) {
      if (this.namespaces[prefix] === uri) {
        break;
      }
    }
  }
  return prefix;
};

/**
 * Method: getOnlineResource_href
 */
WMC.prototype.getOnlineResource_href = function (node) {
  const object = {};
  const links = node.getElementsByTagName('OnlineResource');
  if (links.length > 0) {
    this.read_wmc_OnlineResource(object, links[0]);
  }
  // we have to replace user key with current sesion id for private layers
  if (this._shomUser && this._shomUser.get('isLoggedIn')) {
    object.href = object.href.replace(this._shomUser.get('key'), this._shomUser.get('sessid'));
  }
  return object.href;
};

/**
 * Method: read_wmc_General
 */
WMC.prototype.read_wmc_General = function (context, node) {
  this.runChildNodes(context, node);
};

/**
 * Method: read_wmc_BoundingBox
 */
WMC.prototype.read_wmc_BoundingBox = function (context, node) {
  context.projection = node.getAttribute('SRS');
  context.bounds = boundingExtent(
    [
      [+node.getAttribute('minx'), +node.getAttribute('miny')],
      [+node.getAttribute('maxx'), +node.getAttribute('maxy')]
    ]
  );
};

/**
 * Method: read_wmc_LayerList
 */
WMC.prototype.read_wmc_LayerList = function (context, node) {
  // layersContext is an array containing info for each layer
  context.layersContext = [];
  this.runChildNodes(context, node);
};

/**
 * Method: read_wmc_Layer
 */
WMC.prototype.read_wmc_Layer = function (context, node) {
  const layerContext = {
    visibility: (+node.getAttribute('hidden') !== 1),
    queryable: (+node.getAttribute('queryable') === 1),
    formats: [],
    styles: [],
    metadata: {}
  };

  this.runChildNodes(layerContext, node);
  // set properties common to multiple objects on layer options/params
  context.layersContext.push(layerContext);
};

/**
 * Method: read_wmc_Extension
 */
WMC.prototype.read_wmc_Extension = function (obj, node) {
  this.runChildNodes(obj, node);
};

/**
 * Method: read_ol_units
 */
WMC.prototype.read_ol_units = function (layerContext, node) {
  layerContext.units = this.getChildValue(node);
};

/**
 * Method: read_ol_maxExtent
 */
WMC.prototype.read_ol_maxExtent = function (obj, node) {
  const minx = +node.getAttribute('minx') || 0;
  const maxx = +node.getAttribute('maxx') || 0;
  const miny = +node.getAttribute('miny') || 0;
  const maxy = +node.getAttribute('maxy') || 0;
  const bounds = boundingExtent([[minx, miny], [maxx, maxy]]);
  obj.maxExtent = bounds;
};

/**
 * Method: read_ol_transparent
 */
WMC.prototype.read_ol_transparent = function (layerContext, node) {
  layerContext.transparent = this.getChildValue(node);
};

/**
 * Method: read_ol_numZoomLevels
 */
WMC.prototype.read_ol_numZoomLevels = function (layerContext, node) {
  layerContext.numZoomLevels = parseInt(this.getChildValue(node), 10);
};

/**
 * Method: read_ol_opacity
 */
WMC.prototype.read_ol_opacity = function (layerContext, node) {
  layerContext.opacity = parseFloat(this.getChildValue(node));
};

/**
 * Method: read_ol_legendUrl
 */
WMC.prototype.read_ol_legendUrl = function (layerContext, node) {
  layerContext.legendUrl = this.getChildValue(node);
};

/**
 * Method: read_ol_singleTile
 */
WMC.prototype.read_ol_singleTile = function (layerContext, node) {
  layerContext.singleTile = (this.getChildValue(node) === 'true');
};

/**
 * Method: read_ol_tileSize
 */
WMC.prototype.read_ol_tileSize = function (layerContext, node) {
  const obj = { width: node.getAttribute('width'), height: node.getAttribute('height') };
  layerContext.tileSize = obj;
};

/**
 * Method: read_ol_gutter
 */
WMC.prototype.read_ol_gutter = function (layerContext, node) {
  layerContext.gutter = parseInt(this.getChildValue(node), 10);
};

/**
 * Method: read_ol_isBaseLayer
 */
WMC.prototype.read_ol_isBaseLayer = function (layerContext, node) {
  layerContext.isBaseLayer = (this.getChildValue(node) === 'true');
};

/**
 * Method: read_ol_displayInLayerSwitcher
 */
WMC.prototype.read_ol_displayInLayerSwitcher = function (layerContext, node) {
  layerContext.displayInLayerSwitcher = (this.getChildValue(node) === 'true');
};

/**
 * Method: read_ol_attribution
 */
WMC.prototype.read_ol_attribution = function (obj, node) {
  obj.attribution = {};
  this.runChildNodes(obj.attribution, node);
};

/**
 * Method: read_wmc_Server
 */

WMC.prototype.read_wmc_Server = function (layerContext, node) {
  layerContext.version = node.getAttribute('version');
  layerContext.service = node.getAttribute('service');
  layerContext.url = this.getOnlineResource_href(node);
  layerContext.metadata.servertitle = node.getAttribute('title');
};

/**
 * Method: read_wmc_FormatList
 */
WMC.prototype.read_wmc_FormatList = function (layerContext, node) {
  this.runChildNodes(layerContext, node);
};

/**
 * Method: read_wmc_Format
 */
WMC.prototype.read_wmc_Format = function (layerContext, node) {
  const format = {
    value: this.getChildValue(node)
  };
  if (+node.getAttribute('current') === 1) {
    format.current = true;
  }
  layerContext.formats.push(format);
};

/**
 * Method: read_wmc_StyleList
 */
WMC.prototype.read_wmc_StyleList = function (layerContext, node) {
  this.runChildNodes(layerContext, node);
};

/**
 * Method: read_wmc_Style
 */
WMC.prototype.read_wmc_Style = function (layerContext, node) {
  const style = {};
  this.runChildNodes(style, node);
  if (+node.getAttribute('current') === 1) {
    style.current = true;
  }
  layerContext.styles.push(style);
};

/**
 * Method: read_wmc_SLD
 */
WMC.prototype.read_wmc_SLD = function (style, node) {
  this.runChildNodes(style, node);
  // style either comes back with an href or a body property
};

/**
 * Method: read_sld_StyledLayerDescriptor
 */
WMC.prototype.read_sld_StyledLayerDescriptor = function (sld, node) {
  sld.body = OpenLayers.Format.XML.prototype.write.apply(this, [node]);
};

/**
 * Method: read_wmc_OnlineResource
 */
WMC.prototype.read_wmc_OnlineResource = function (obj, node) {
  obj.href = node.getAttributeNodeNS(this.namespaces.xlink, 'href').textContent;
};

/**
 * Method: read_wmc_Name
 */
WMC.prototype.read_wmc_Name = function (obj, node) {
  const name = this.getChildValue(node);
  if (name) {
    obj.name = name;
  }
};

/**
 * Method: read_wmc_Title
 */
WMC.prototype.read_wmc_Title = function (obj, node) {
  const title = this.getChildValue(node);
  if (title) {
    obj.title = title;
  }
};

/**
 * Method: read_wmc_MetadataURL
 */
WMC.prototype.read_wmc_MetadataURL = function (layerContext, node) {
  layerContext.metadataURL = this.getOnlineResource_href(node);
};

/**
 * Method: read_wmc_KeywordList
 */
WMC.prototype.read_wmc_KeywordList = function (context, node) {
  context.keywords = [];
  this.runChildNodes(context.keywords, node);
};

/**
 * Method: read_wmc_Keyword
 */
WMC.prototype.read_wmc_Keyword = function (keywords, node) {
  keywords.push(this.getChildValue(node));
};

/**
 * Method: read_wmc_Abstract
 */
WMC.prototype.read_wmc_Abstract = function (obj, node) {
  const abst = this.getChildValue(node);
  if (abst) {
    obj.abstract = abst;
  }
};

/**
 * Method: read_wmc_LogoURL
 */
WMC.prototype.read_wmc_LogoURL = function (context, node) {
  context.logo = {
    width: node.getAttribute('width'),
    height: node.getAttribute('height'),
    format: node.getAttribute('format'),
    href: this.getOnlineResource_href(node)
  };
};

/**
 * Method: read_wmc_DescriptionURL
 */
WMC.prototype.read_wmc_DescriptionURL = function (context, node) {
  context.descriptionURL = this.getOnlineResource_href(node);
};

/**
 * Method: read_wmc_ContactInformation
 */
WMC.prototype.read_wmc_ContactInformation = function (obj, node) {
  const contact = {};
  this.runChildNodes(contact, node);
  obj.contactInformation = contact;
};

/**
 * Method: read_wmc_ContactPersonPrimary
 */
WMC.prototype.read_wmc_ContactPersonPrimary = function (contact, node) {
  const personPrimary = {};
  this.runChildNodes(personPrimary, node);
  contact.personPrimary = personPrimary;
};

/**
 * Method: read_wmc_ContactPerson
 */
WMC.prototype.read_wmc_ContactPerson = function (primaryPerson, node) {
  const person = this.getChildValue(node);
  if (person) {
    primaryPerson.person = person;
  }
};

/**
 * Method: read_wmc_ContactOrganization
 */
WMC.prototype.read_wmc_ContactOrganization = function (primaryPerson, node) {
  const organization = this.getChildValue(node);
  if (organization) {
    primaryPerson.organization = organization;
  }
};

/**
 * Method: read_wmc_ContactPosition
 */
WMC.prototype.read_wmc_ContactPosition = function (contact, node) {
  const position = this.getChildValue(node);
  if (position) {
    contact.position = position;
  }
};

/**
 * Method: read_wmc_ContactAddress
 */
WMC.prototype.read_wmc_ContactAddress = function (contact, node) {
  const contactAddress = {};
  this.runChildNodes(contactAddress, node);
  contact.contactAddress = contactAddress;
};

/**
 * Method: read_wmc_AddressType
 */
WMC.prototype.read_wmc_AddressType = function (contactAddress, node) {
  const type = this.getChildValue(node);
  if (type) {
    contactAddress.type = type;
  }
};

/**
 * Method: read_wmc_Address
 */
WMC.prototype.read_wmc_Address = function (contactAddress, node) {
  const address = this.getChildValue(node);
  if (address) {
    contactAddress.address = address;
  }
};

/**
 * Method: read_wmc_City
 */
WMC.prototype.read_wmc_City = function (contactAddress, node) {
  const city = this.getChildValue(node);
  if (city) {
    contactAddress.city = city;
  }
};

/**
 * Method: read_wmc_StateOrProvince
 */
WMC.prototype.read_wmc_StateOrProvince = function (contactAddress, node) {
  const stateOrProvince = this.getChildValue(node);
  if (stateOrProvince) {
    contactAddress.stateOrProvince = stateOrProvince;
  }
};

/**
 * Method: read_wmc_PostCode
 */
WMC.prototype.read_wmc_PostCode = function (contactAddress, node) {
  const postcode = this.getChildValue(node);
  if (postcode) {
    contactAddress.postcode = postcode;
  }
};

/**
 * Method: read_wmc_Country
 */
WMC.prototype.read_wmc_Country = function (contactAddress, node) {
  const country = this.getChildValue(node);
  if (country) {
    contactAddress.country = country;
  }
};

/**
 * Method: read_wmc_ContactVoiceTelephone
 */
WMC.prototype.read_wmc_ContactVoiceTelephone = function (contact, node) {
  const phone = this.getChildValue(node);
  if (phone) {
    contact.phone = phone;
  }
};

/**
 * Method: read_wmc_ContactFacsimileTelephone
 */
WMC.prototype.read_wmc_ContactFacsimileTelephone = function (contact, node) {
  const fax = this.getChildValue(node);
  if (fax) {
    contact.fax = fax;
  }
};

/**
 * Method: read_wmc_ContactElectronicMailAddress
 */
WMC.prototype.read_wmc_ContactElectronicMailAddress = function (contact, node) {
  const email = this.getChildValue(node);
  if (email) {
    contact.email = email;
  }
};

/**
 * Method: read_wmc_DataURL
 */
WMC.prototype.read_wmc_DataURL = function (layerContext, node) {
  layerContext.dataURL = this.getOnlineResource_href(node);
};

/**
 * Method: read_wmc_LegendURL
 */
WMC.prototype.read_wmc_LegendURL = function (style, node) {
  const legend = {
    width: node.getAttribute('width'),
    height: node.getAttribute('height'),
    format: node.getAttribute('format'),
    href: this.getOnlineResource_href(node)
  };
  style.legend = legend;
};

/**
 * Method: read_wmc_DimensionList
 */
WMC.prototype.read_wmc_DimensionList = function (layerContext, node) {
  layerContext.dimensions = {};
  this.runChildNodes(layerContext.dimensions, node);
};
/**
 * Method: read_wmc_Dimension
 */
WMC.prototype.read_wmc_Dimension = function (dimensions, node) {
  const name = node.getAttribute('name').toLowerCase();

  const dim = {
    name,
    units: node.getAttribute('units') || '',
    unitSymbol: node.getAttribute('unitSymbol') || '',
    userValue: node.getAttribute('userValue') || '',
    nearestValue: node.getAttribute('nearestValue') === '1',
    multipleValues: node.getAttribute('multipleValues') === '1',
    current: node.getAttribute('current') === '1',
    default: node.getAttribute('default') || ''
  };
  const values = this.getChildValue(node);
  dim.values = values.split(',');

  dimensions[dim.name] = dim;
};

/**
 * Method: write
 *
 * Parameters:
 * context - {Object} An object representing the map context.
 * options - {Object} Optional object.
 *
 * Returns:
 * {String} A WMC document string.
 */
WMC.prototype.write = function (context, options) {
  let root = this.createElementDefaultNS('ViewContext');
  this.setAttributes(root, {
    version: this.version,
    id: (options && typeof options.id === 'string') ? options.id : 'OpenLayers_Context_'
  });

  // add schemaLocation attribute
  this.setAttributeNS(
    root,
    this.namespaces.xsi,
    'xsi:schemaLocation',
    this.schemaLocation
  );

  // required General element
  root.appendChild(this.write_wmc_General(context));

  // required LayerList element
  root.appendChild(this.write_wmc_LayerList(context));

  let data;
  if (this.xmldom) {
    data = root.xml;
  } else {
    const serializer = new XMLSerializer();
    if (+root.nodeType === 1) {
      // Add nodes to a document before serializing. Everything else
      // is serialized as is. This may need more work. See #1218 .
      const doc = document.implementation.createDocument('', '', null);
      if (doc.importNode) {
        root = doc.importNode(root, true);
      }
      doc.appendChild(root);
      data = serializer.serializeToString(doc);
    } else {
      data = serializer.serializeToString(root);
    }
  }
  return data;
};

/**
 * APIMethod: createElementNS
 * Create a new element with namespace.  This node can be appended to
 *     another node with the standard node.appendChild method.  For
 *     cross-browser support, this method must be used instead of
 *     document.createElementNS.
 *
 * Parameters:
 * uri - {String} Namespace URI for the element.
 * name - {String} The qualified name of the element (prefix:localname).
 *
 * Returns:
 * {Element} A DOM element with namespace.
 */
WMC.prototype.createElementNS = function (uri, name) {
  let element;
  if (this.xmldom) {
    if (typeof uri === 'string') {
      element = this.xmldom.createNode(1, name, uri);
    } else {
      element = this.xmldom.createNode(1, name, '');
    }
  } else {
    element = document.createElementNS(uri, name);
  }
  return element;
};

/**
 * Method: createElementDefaultNS
 * Shorthand for createElementNS with namespace from <defaultPrefix>.
 *     Can optionally be used to set attributes and a text child value.
 *
 * Parameters:
 * name - {String} The qualified node name.
 * childValue - {String} Optional value for text child node.
 * attributes - {Object} Optional object representing attributes.
 *
 * Returns:
 * {Element} An element node.
 */
WMC.prototype.createElementDefaultNS = function (name, childValue, attributes) {
  const node = this.createElementNS(
    this.namespaces[this.defaultPrefix],
    name
  );
  if (childValue) {
    node.appendChild(this.createTextNode(childValue));
  }
  if (attributes) {
    this.setAttributes(node, attributes);
  }
  return node;
};

/**
 * Method: setAttributes
 * Set multiple attributes given key value pairs from an object.
 *
 * Parameters:
 * node - {Element} An element node.
 * obj - {Object} An object whose properties represent attribute names and
 *     values represent attribute values.
 */
WMC.prototype.setAttributes = function (node, obj) {
  let value;
  for (const name in obj) {
    if (!obj.hasOwnProperty(name)) {
      continue;
    }
    value = obj[name].toString();
    if (value.match(/[A-Z]/)) {
      // safari lowercases attributes with setAttribute
      this.setAttributeNS(node, null, name, value);
    } else {
      node.setAttribute(name, value);
    }
  }
};

/**
 * APIMethod: setAttributeNS
 * Adds a new attribute or changes the value of an attribute with the given
 *     namespace and name.
 *
 * Parameters:
 * node - {Element} Element node on which to set the attribute.
 * uri - {String} Namespace URI for the attribute.
 * name - {String} Qualified name (prefix:localname) for the attribute.
 * value - {String} Attribute value.
 */
WMC.prototype.setAttributeNS = function (node, uri, name, value) {
  if (node.setAttributeNS) {
    node.setAttributeNS(uri, name, value);
  } else if (this.xmldom) {
    if (uri) {
      const attribute = node.ownerDocument.createNode(2, name, uri);
      attribute.nodeValue = value;
      node.setAttributeNode(attribute);
    } else {
      node.setAttribute(name, value);
    }
  } else {
    throw 'setAttributeNS not implemented';
  }
};

/**
 * APIMethod: createTextNode
 * Create a text node.  This node can be appended to another node with
 *     the standard node.appendChild method.  For cross-browser support,
 *     this method must be used instead of document.createTextNode.
 *
 * Parameters:
 * text - {String} The text of the node.
 *
 * Returns:
 * {DOMElement} A DOM text node.
 */
WMC.prototype.createTextNode = function (text) {
  let node;
  if (typeof text !== 'string') {
    text = String(text);
  }
  if (this.xmldom) {
    node = this.xmldom.createTextNode(text);
  } else {
    node = document.createTextNode(text);
  }
  return node;
};

/**
 * Method: write_wmc_General
 * Create a General node given an context object.
 *
 * Parameters:
 * context - {Object} Context object.
 *
 * Returns:
 * {Element} A WMC General element node.
 */
WMC.prototype.write_wmc_General = function (context) {
  const node = this.createElementDefaultNS('General');

  // optional Window element
  if (context.size) {
    node.appendChild(this.createElementDefaultNS(
      'Window',
      null,
      {
        width: context.size[0],
        height: context.size[1]
      }
    ));
  }

  // required BoundingBox element
  const { bounds } = context;
  node.appendChild(this.createElementDefaultNS(
    'BoundingBox',
    null,
    {
      minx: bounds[0].toPrecision(18),
      miny: bounds[1].toPrecision(18),
      maxx: bounds[2].toPrecision(18),
      maxy: bounds[3].toPrecision(18),
      SRS: context.projection.getCode()
    }
  ));

  // required Title element
  node.appendChild(this.createElementDefaultNS('Title', context.title));

  // optional KeywordList element
  if (context.keywords) {
    node.appendChild(this.write_wmc_KeywordList(context.keywords));
  }

  // optional Abstract element
  if (context.abstract) {
    node.appendChild(this.createElementDefaultNS('Abstract', context.abstract));
  }

  // Optional LogoURL element
  if (context.logo) {
    node.appendChild(this.write_wmc_URLType('LogoURL', context.logo.href, context.logo));
  }

  // Optional DescriptionURL element
  if (context.descriptionURL) {
    node.appendChild(this.write_wmc_URLType('DescriptionURL', context.descriptionURL));
  }

  // Optional ContactInformation element
  if (context.contactInformation) {
    node.appendChild(this.write_wmc_ContactInformation(context.contactInformation));
  }

  // OpenLayers specific map properties
  node.appendChild(this.write_ol_MapExtension(context));

  return node;
};

/**
 * Method: write_wmc_KeywordList
 */
WMC.prototype.write_wmc_KeywordList = function (keywords) {
  const node = this.createElementDefaultNS('KeywordList');

  let i = 0;
  const len = keywords.length;
  for (; i < len; i++) {
    node.appendChild(this.createElementDefaultNS('Keyword', keywords[i]));
  }
  return node;
};
/**
 * Method: write_wmc_ContactInformation
 */
WMC.prototype.write_wmc_ContactInformation = function (contact) {
  const node = this.createElementDefaultNS('ContactInformation');

  if (contact.personPrimary) {
    node.appendChild(this.write_wmc_ContactPersonPrimary(contact.personPrimary));
  }
  if (contact.position) {
    node.appendChild(this.createElementDefaultNS('ContactPosition', contact.position));
  }
  if (contact.contactAddress) {
    node.appendChild(this.write_wmc_ContactAddress(contact.contactAddress));
  }
  if (contact.phone) {
    node.appendChild(this.createElementDefaultNS('ContactVoiceTelephone', contact.phone));
  }
  if (contact.fax) {
    node.appendChild(this.createElementDefaultNS('ContactFacsimileTelephone', contact.fax));
  }
  if (contact.email) {
    node.appendChild(this.createElementDefaultNS('ContactElectronicMailAddress', contact.email));
  }
  return node;
};

/**
 * Method: write_wmc_ContactPersonPrimary
 */
WMC.prototype.write_wmc_ContactPersonPrimary = function (personPrimary) {
  const node = this.createElementDefaultNS('ContactPersonPrimary');
  if (personPrimary.person) {
    node.appendChild(this.createElementDefaultNS('ContactPerson', personPrimary.person));
  }
  if (personPrimary.organization) {
    node.appendChild(this.createElementDefaultNS('ContactOrganization', personPrimary.organization));
  }
  return node;
};

/**
 * Method: write_wmc_ContactAddress
 */
WMC.prototype.write_wmc_ContactAddress = function (contactAddress) {
  const node = this.createElementDefaultNS('ContactAddress');
  if (contactAddress.type) {
    node.appendChild(this.createElementDefaultNS('AddressType', contactAddress.type));
  }
  if (contactAddress.address) {
    node.appendChild(this.createElementDefaultNS('Address', contactAddress.address));
  }
  if (contactAddress.city) {
    node.appendChild(this.createElementDefaultNS('City', contactAddress.city));
  }
  if (contactAddress.stateOrProvince) {
    node.appendChild(this.createElementDefaultNS('StateOrProvince', contactAddress.stateOrProvince));
  }
  if (contactAddress.postcode) {
    node.appendChild(this.createElementDefaultNS('PostCode', contactAddress.postcode));
  }
  if (contactAddress.country) {
    node.appendChild(this.createElementDefaultNS('Country', contactAddress.country));
  }
  return node;
};

/**
 * Method: write_ol_MapExtension
 */
WMC.prototype.write_ol_MapExtension = function (context) {
  const node = this.createElementDefaultNS('Extension');

  const bounds = context.maxExtent;
  if (bounds) {
    const maxExtent = this.createElementNS(this.namespaces.ol, 'ol:maxExtent');
    this.setAttributes(maxExtent, {
      minx: bounds.left.toPrecision(18),
      miny: bounds.bottom.toPrecision(18),
      maxx: bounds.right.toPrecision(18),
      maxy: bounds.top.toPrecision(18)
    });
    node.appendChild(maxExtent);
  }

  return node;
};

/**
 * Method: write_wmc_LayerList
 * Create a LayerList node given an context object.
 *
 * Parameters:
 * context - {Object} Context object.
 *
 * Returns:
 * {Element} A WMC LayerList element node.
 */
WMC.prototype.write_wmc_LayerList = function (context) {
  const list = this.createElementDefaultNS('LayerList');

  let i = 0;
  const len = context.layersContext.length;
  for (; i < len; ++i) {
    list.appendChild(this.write_wmc_Layer(context.layersContext[i]));
  }

  return list;
};

/**
 * Method: write_wmc_Layer
 * Create a Layer node given a layer context object.
 *
 * Parameters:
 * context - {Object} A layer context object.}
 *
 * Returns:
 * {Element} A WMC Layer element node.
 */
WMC.prototype.write_wmc_Layer = function (context) {
  const node = this.createElementDefaultNS('Layer', null, {
    queryable: context.queryable ? '1' : '0',
    hidden: context.visibility ? '0' : '1'
  });

  // required Server element
  node.appendChild(this.write_wmc_Server(context));

  // required Name element
  node.appendChild(this.createElementDefaultNS('Name', context.name));

  // required Title element
  node.appendChild(this.createElementDefaultNS('Title', context.title));

  // optional Abstract element
  if (context.abstract) {
    node.appendChild(this.createElementDefaultNS('Abstract', context.abstract));
  }

  // optional DataURL element
  if (context.dataURL) {
    node.appendChild(this.write_wmc_URLType('DataURL', context.dataURL));
  }

  // optional MetadataURL element
  if (context.metadataURL) {
    node.appendChild(this.write_wmc_URLType('MetadataURL', context.metadataURL));
  }

  // min/max scale denominator elements go before the 4th element in v1
  this._addScaleMinMaxToNode(node, context);

  // optional SRS element(s)
  if (context.srs) {
    for (const name in context.srs) {
      if (context.srs.hasOwnProperty(name)) {
        node.appendChild(this.createElementDefaultNS('SRS', name));
      }
    }
  }

  // optional FormatList element
  node.appendChild(this.write_wmc_FormatList(context));

  // optional StyleList element
  node.appendChild(this.write_wmc_StyleList(context));

  // optional DimensionList element
  if (context.dimensions) {
    node.appendChild(this.write_wmc_DimensionList(context));
  }

  // OpenLayers specific properties go in an Extension element
  node.appendChild(this.write_wmc_LayerExtension(context));

  return node;
};

WMC.prototype._addScaleMinMaxToNode = function (node, context) {
  if (context.maxScale) {
    const minSD = this.createElementNS(this.namespaces.sld, 'sld:MinScaleDenominator');
    minSD.appendChild(this.createTextNode(context.maxScale.toPrecision(16)));
    node.appendChild(minSD);
  }

  if (context.minScale) {
    const maxSD = this.createElementNS(this.namespaces.sld, 'sld:MaxScaleDenominator');
    maxSD.appendChild(this.createTextNode(context.minScale.toPrecision(16)));
    node.appendChild(maxSD);
  }
};

/**
 * Method: write_ol_attribution
 * Create an attribution node given a layer attribution object.
 *
 * Parameters:
 * attribution - {<Object>} or {<String>} A layer attribution object or string
 *
 * Returns:
 * {Element} A ol:attribution element node.
 */
WMC.prototype.write_ol_attribution = function (attribution) {
  if (typeof attribution === 'string') {
    attribution = { title: attribution };
  }
  const node = this.createElementNS(this.namespaces.ol, 'ol:attribution');
  node.appendChild(this.createElementDefaultNS('Title', attribution.title));
  if (attribution.href) {
    node.appendChild(this.write_wmc_OnlineResource(attribution.href));
  }
  if (attribution.logo) {
    node.appendChild(
      this.write_wmc_URLType(
        'LogoURL',
        attribution.logo.href,
        attribution.logo
      )
    );
  }
  return node;
};

/**
 * Method: write_wmc_LayerExtension
 * Add OpenLayers specific layer parameters to an Extension element.
 *
 * Parameters:
 * context - {Object} A layer context object.
 *
 * Returns:
 * {Element} A WMC Extension element (for a layer).
 */
WMC.prototype.write_wmc_LayerExtension = function (context) {
  const node = this.createElementDefaultNS('Extension');

  const bounds = context.maxExtent;
  const maxExtent = this.createElementNS(this.namespaces.ol, 'ol:maxExtent');
  if (bounds) {
    this.setAttributes(maxExtent, {
      minx: bounds[0].toPrecision(18),
      miny: bounds[1].toPrecision(18),
      maxx: bounds[2].toPrecision(18),
      maxy: bounds[3].toPrecision(18)
    });
    node.appendChild(maxExtent);
  }

  if (context.tileSize && !context.singleTile) {
    const size = this.createElementNS(this.namespaces.ol, 'ol:tileSize');
    this.setAttributes(size, context.tileSize);
    node.appendChild(size);
  }

  const properties = [
    'transparent', 'numZoomLevels', 'units', 'isBaseLayer',
    'opacity', 'displayInLayerSwitcher', 'singleTile', 'gutter', 'legendUrl'
  ];
  let child;
  let i = 0;
  const len = properties.length;
  for (; i < len; ++i) {
    child = this.createOLPropertyNode(context, properties[i]);
    if (child) {
      node.appendChild(child);
    }
  }

  if (context.attribution) {
    const attribution = this.write_ol_attribution(context.attribution);
    node.appendChild(attribution);
  }

  return node;
};

/**
 * Method: createOLPropertyNode
 * Create a node representing an OpenLayers property.  If the property is
 *     null or undefined, null will be returned.
 *
 * Parameters:
 * obj - {Object} An object.
 * prop - {String} A property.
 *
 * Returns:
 * {Element} A property node.
 */
WMC.prototype.createOLPropertyNode = function (obj, prop) {
  let node = null;
  if (obj[prop] != null) {
    node = this.createElementNS(this.namespaces.ol, `ol:${prop}`);
    node.appendChild(this.createTextNode(obj[prop].toString()));
  }
  return node;
};

/**
 * Method: write_wmc_Server
 * Create a Server node given a layer context object.
 *
 * Parameters:
 * context - {Object} Layer context object.
 *
 * Returns:
 * {Element} A WMC Server element node.
 */
WMC.prototype.write_wmc_Server = function (context) {
  const { server } = context;
  const node = this.createElementDefaultNS('Server');
  const attributes = {
    service: 'OGC:WMS',
    version: server.version
  };
  if (server.title) {
    attributes.title = server.title;
  }
  if (server.service) {
    attributes.service = server.service;
  }
  this.setAttributes(node, attributes);

  // required OnlineResource element
  node.appendChild(this.write_wmc_OnlineResource(server.url));

  return node;
};

/**
 * Method: write_wmc_URLType
 * Create a LogoURL/DescriptionURL/MetadataURL/DataURL/LegendURL node given a object and elementName.
 *
 * Parameters:
 * elName - {String} Name of element (LogoURL/DescriptionURL/MetadataURL/LegendURL)
 * url - {String} URL string value
 * attr - {Object} Optional attributes (width, height, format)
 *
 * Returns:
 * {Element} A WMC element node.
 */
WMC.prototype.write_wmc_URLType = function (elName, url, attr) {
  const node = this.createElementDefaultNS(elName);
  node.appendChild(this.write_wmc_OnlineResource(url));
  if (attr) {
    const optionalAttributes = ['width', 'height', 'format'];
    for (let i = 0; i < optionalAttributes.length; i++) {
      if (optionalAttributes[i] in attr) {
        node.setAttribute(optionalAttributes[i], attr[optionalAttributes[i]]);
      }
    }
  }
  return node;
};

/**
 * Method: write_wmc_DimensionList
 */
WMC.prototype.write_wmc_DimensionList = function (context) {
  const node = this.createElementDefaultNS('DimensionList');
  for (const dim in context.dimensions) {
    if (!context.dimensions.hasOwnProperty(dim)) {
      continue;
    }
    const attributes = {};
    const dimension = context.dimensions[dim];
    for (const name in dimension) {
      if (typeof dimension[name] === 'boolean') {
        attributes[name] = Number(dimension[name]);
      } else {
        attributes[name] = dimension[name];
      }
    }
    let values = '';
    if (attributes.values) {
      values = attributes.values.join(',');
      delete attributes.values;
    }

    node.appendChild(this.createElementDefaultNS('Dimension', values, attributes));
  }
  return node;
};

/**
 * Method: write_wmc_FormatList
 * Create a FormatList node given a layer context.
 *
 * Parameters:
 * context - {Object} Layer context object.
 *
 * Returns:
 * {Element} A WMC FormatList element node.
 */
WMC.prototype.write_wmc_FormatList = function (context) {
  const node = this.createElementDefaultNS('FormatList');
  let i = 0;
  const len = context.formats.length;
  for (; i < len; i++) {
    const format = context.formats[i];
    node.appendChild(this.createElementDefaultNS(
      'Format',
      format.value,
      (format.current && format.current === true)
        ? { current: '1' } : null
    ));
  }

  return node;
};

/**
 * Method: write_wmc_StyleList
 * Create a StyleList node given a layer context.
 *
 * Parameters:
 * layer - {Object} Layer context object.
 *
 * Returns:
 * {Element} A WMC StyleList element node.
 */
WMC.prototype.write_wmc_StyleList = function (layer) {
  const node = this.createElementDefaultNS('StyleList');

  const { styles } = layer;
  if (!Array.isArray(styles)) {
    return node;
  }

  for (let i = 0, len = styles.length; i < len; i++) {
    const s = styles[i];
    // three style types to consider
    // [1] linked SLD
    // [2] inline SLD
    // [3] named style
    // running child nodes always gets name, optionally gets href or body
    const styleEl = this.createElementDefaultNS(
      'Style',
      null,
      (s.current && s.current === true)
        ? { current: '1' } : null
    );
    if (s.href) { // [1]
      const sld = this._createSLDNodeFromStyle(s);
      const link = this.write_wmc_OnlineResource(s.href);
      sld.appendChild(link);
      styleEl.appendChild(sld);
    } else if (s.body) { // [2]
      const sld = this._createSLDNodeFromStyle(s);
      // read in body as xml doc - assume proper namespace declarations
      const imported = this._importNodeFromXmlStyleDoc(sld, style);
      sld.appendChild(imported);
      styleEl.appendChild(sld);
    } else { // [3]
      // both Name and Title are required.
      styleEl.appendChild(this.createElementDefaultNS('Name', s.name));
      styleEl.appendChild(this.createElementDefaultNS('Title', s.title));
      // Abstract is optional
      // abstract is a js keyword
      if (s.abstract) {
        styleEl.appendChild(this.createElementDefaultNS('Abstract', s.abstract));
      }
      // LegendURL is optional
      if (s.legend) {
        styleEl.appendChild(this.write_wmc_URLType('LegendURL', s.legend.href, s.legend));
      }
    }
    node.appendChild(styleEl);
  }

  return node;
};

/**
 * @param style {object}
 * @returns {Element}
 * @private
 */
WMC.prototype._createSLDNodeFromStyle = function (style) {
  const sld = this.createElementDefaultNS('SLD');
  if (style.name) {
    sld.appendChild(this.createElementDefaultNS('Name', style.name));
  }

  if (style.title) {
    sld.appendChild(this.createElementDefaultNS('Title', style.title));
  }
  // LegendURL is always optional
  if (style.legend) {
    sld.appendChild(this.write_wmc_URLType('LegendURL', style.legend.href, style.legend));
  }
  return sld;
};

WMC.prototype._importNodeFromXmlStyleDoc = function (sld, style) {
  const doc = XMLFormat.prototype.read.apply(this, [style.body]);
  // append to StyledLayerDescriptor node
  let imported = doc.documentElement;
  if (sld.ownerDocument && sld.ownerDocument.importNode) {
    imported = sld.ownerDocument.importNode(imported, true);
  }
  return imported;
};

/**
 * Method: write_wmc_OnlineResource
 * Create an OnlineResource node given a URL.
 *
 * Parameters:
 * href - {String} URL for the resource.
 *
 * Returns:
 * {Element} A WMC OnlineResource element node.
 */
WMC.prototype.write_wmc_OnlineResource = function (href) {
  const node = this.createElementDefaultNS('OnlineResource');
  this.setAttributeNS(node, this.namespaces.xlink, 'xlink:type', 'simple');
  this.setAttributeNS(node, this.namespaces.xlink, 'xlink:href', href);
  return node;
};

/**
 * Method: getLayerFromContext
 * Create a WMS layer from a layerContext object.
 *
 * Parameters:
 * layerContext - {Object} An object representing a WMS layer.
 *
 * Returns:
 * {<OpenLayers.Layer.WMS>} A WMS layer.
 */
WMC.prototype.getLayerFromContext = function (layerContext) {
  // fill initial options object from layerContext
  const options = this._createLayerFromContextInitialOptions(layerContext);

  if (this.layerOptions) {
    _.defaults(options, this.layerOptions);
  }

  const params = {
    LAYERS: layerContext.name,
    TRANSPARENT: layerContext.transparent,
    VERSION: layerContext.version
  };

  const format = this._createParamFormatFromLayerContext(layerContext);
  if (format) {
    params.FORMAT = format;
  }

  if (layerContext.styles && layerContext.styles.length > 0) {
    for (let i = 0, len = layerContext.styles.length; i < len; i++) {
      const style = layerContext.styles[i];
      if (style.current === true) {
        // three style types to consider
        // 1) linked SLD
        // 2) inline SLD
        // 3) named style
        if (style.href) {
          params.sld = style.href;
        } else if (style.body) {
          params.sld_body = style.body;
        } else {
          params.STYLES = style.name;
        }
        break;
      }
    }
  }

  if (this.layerParams) {
    _.defaults(params, this.layerParams);
  }

  return this._createLayerFromContextLayer(layerContext, params);
};

WMC.prototype._createLayerFromContextLayer = function (layerContext, params) {
  if (layerContext.categoryLayer !== true) {
    return new TileLayer({
      identifier: layerContext.name,
      title: layerContext.title || layerContext.name,
      maxExtent: layerContext.maxExtent,
      source: new TileWMSSource({
        url: layerContext.url,
        crossOrigin: 'anonymous',
        params
      })
    });
  }
  return null;
};

WMC.prototype._createLayerFromContextInitialOptions = function (layerContext) {
  return {
    queryable: layerContext.queryable, // keep queryable for api compatibility
    visibility: layerContext.visibility,
    maxExtent: layerContext.maxExtent,
    metadata: _.defaults(layerContext.metadata, {
      styles: layerContext.styles,
      formats: layerContext.formats,
      abstract: layerContext.abstract,
      dataURL: layerContext.dataURL
    }),
    numZoomLevels: layerContext.numZoomLevels,
    units: layerContext.units,
    isBaseLayer: layerContext.isBaseLayer,
    opacity: layerContext.opacity,
    displayInLayerSwitcher: layerContext.displayInLayerSwitcher,
    singleTile: layerContext.singleTile,
    englishName: layerContext.englishName,
    tileSize: (layerContext.tileSize)
      ? toSize(
        [layerContext.tileSize.width, layerContext.tileSize.height]
      ) : undefined,
    minScale: layerContext.minScale || layerContext.maxScaleDenominator,
    maxScale: layerContext.maxScale || layerContext.minScaleDenominator,
    srs: layerContext.srs,
    dimensions: layerContext.dimensions,
    metadataURL: layerContext.metadataURL,
    legendUrl: layerContext.legendUrl
  };
};

WMC.prototype._createParamFormatFromLayerContext = function (layerContext) {
  let formatParam;
  if (layerContext.formats && layerContext.formats.length > 0) {
    // set default value for params if current attribute is not positionned
    formatParam = layerContext.formats[0].value;
    for (let i = 0, len = layerContext.formats.length; i < len; i++) {
      const format = layerContext.formats[i];
      if (format.current === true) {
        formatParam = format.value;
        break;
      }
    }
  }
  return formatParam;
};

export default WMC;
