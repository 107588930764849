const ShomView = require('../../../core/shom-view');
const template = require('../../../../template/ddm/ddm-detail/ddm-organism.hbs');

const DDMSensorReader = require('../../../utils/ddm/ddm-sensor-reader');

module.exports = ShomView.build({

  initialize(options) {
    this._router = options.router || window.ROUTER;
    this.config = options.config || window.CONFIG;
    this.parentView = options.parentView;
    this._sensorDescription = options.sensorDesc ? options.sensorDesc.members[0].system : null;
    this._values = {};
  },

  render() {
    this.$el.html(template(
      this._values
    ));
    return this;
  },

  setValuesAndRender(sensorDesc) {
    this._sensorDescription = sensorDesc;

    this._values.organismes = DDMSensorReader.getOrganisme(this._sensorDescription);

    this.render();
  }
});
