import { SC_DRAWING_MODULE } from '../utils/constants';

const Backbone = require('backbone');
const _ = require('underscore');
const DrawingMenuView = require('../view/drawing/drawing-menu.view.js');
const OpenMapView = require('../view/drawing/open-map.view');
const EditMapView = require('../view/drawing/edit-map.view');
const CartoDynMap = require('../model/cartodyn-map.js');
const Session = require('../model/cartodyn-session.js');
const CartoDynMaps = require('../collection/cartodyn-maps.js');
const ToastrUtil = require('../utils/toastr.js');

const InvitationChecker = require('../service/drawing/discuss/invitation-checker');
const InvitationLoginModal = require('../view/drawing/discuss/invitation-login-modal.view');
const AlertModal = require('../view/modal/alert.view');
const GlobalDataStore = require('../core/global-data-store');

const DrawingMenuRouter = Backbone.Router.extend({
  initialize(options = {}) {
    this._router = options.router || window.ROUTER;
    this._gisView = options.gisView || window.GISVIEW;
    this._menuRouter = options.menuRouter;
    this._user = options.user || this._router.user;
    this._cartoDynMaps = options.cartoDynMaps || new CartoDynMaps();
    this._config = options.config || window.CONFIG;
    this._session = options.session || new Session({ maps: this._cartoDynMaps, drawingRouter: this });
    this._newMap = options.newMap || new CartoDynMap();
    this._hadDisplayedAdditionalInfo = false;
    this.route('dessin(/)', '_openDrawing');
    this.route('dessin/edit(/)', '_editNewMap');
    this.route('dessin/edit/:id', '_editMap');
    this.route('dessin/co-edit/hash/:hash', '_joinSession');
    this.route('dessin/print-page', '_openPrintPage');
    this.listenTo(this._session, 'change:permissions', this._onUserPermissionsChanged.bind(this));
  },

  _openDrawing() {
    this._drawingMenuView = this._menuRouter.openMenu(() => {
      if (!this._hadDisplayedAdditionalInfo && window.ADDITIONALINFO && window.ADDITIONALINFO.drawing) {
        ToastrUtil.info(window.ADDITIONALINFO.drawing[window.portalLang]);
        this._hadDisplayedAdditionalInfo = true;
      }
      return new DrawingMenuView();
    });
    if (this._session.get('collaborative')) {
      this.navigate(`dessin/edit/${this._session.get('map').id || ''}`, true);
      return;
    }
    if (this._session.get('started')) {
      this.navigate(`dessin/edit/${this._session.get('map').id || ''}`, true);
    } else {
      this._drawingOpenMapView && this._drawingOpenMapView.remove();
      this._drawingOpenMapView = new OpenMapView({ collection: this._cartoDynMaps, session: this._session });
      this._drawingMenuView.setContent(this._drawingOpenMapView.render().$el);
    }
  },

  setupMap(map) {
    map.authHeader = this._user.authHeader();
    map.url = this._config.cartodyn.url;
  },

  _editNewMap() {
    GlobalDataStore.set(SC_DRAWING_MODULE, true);
    if (!this._drawingMenuView || !this._drawingMenuView.$el.is(':visible')) {
      this._openDrawing();
    }
    this._newMap = new CartoDynMap();
    this._editMap(this._newMap);
  },

  _editMap(mapId) {
    if (!this._drawingMenuView || !this._drawingMenuView.$el.is(':visible')) {
      this._openDrawing();
    }
    let map = (mapId instanceof CartoDynMap) ? mapId : this._cartoDynMaps.get(mapId);
    if (!map && this._cartoDynMaps.loading) {
      // Case where the route is called before maps are loaded
      this.listenToOnce(this._cartoDynMaps, 'sync', _.partial(this._editMap, mapId));
      return;
    }
    if (this._session.get('collaborative')) {
      map = this._session.get('map');
    }
    if (!map) {
      return;
    }
    // Génération d'un nom de carte (pour une nouvelle carte)
    if (map.isNew() && this._router.user.get('isLoggedIn')) {
      const name = this.generateNewMapName(this._cartoDynMaps);
      this._newMap.set('name', name);
    }
    this.setupMap(map);
    this._session.set({
      started: true,
      map
    });
    if (!this._editView) {
      this._editView = new EditMapView({
        model: this._session,
        drawingRouter: this,
        user: this._user,
        maps: this._cartoDynMaps,
        drawingMenuView: this._drawingMenuView
      });
    }
    this._editView.extraSetup();
    this._drawingMenuView.setContent(this._editView.render().$el);

    this._gisView && this._gisView.carto && this._gisView.carto.command('drawing.enable');
    this._gisView.setCurrentlyDrawing(true);
  },

  _onUserPermissionsChanged() {
    this._cartoDynMaps.reset();
    if (!this._user.get('isLoggedIn') || !this._session.get('permissions') || !this._session.get('permissions').hasCartoDyn) {
      return;
    }
    this._cartoDynMaps.authHeader = this._user.authHeader();
    this._cartoDynMaps.url = this._config.cartodyn.url + this._config.cartodyn.endpoints.getmaps;
    this._cartoDynMaps.fetch();
  },

  _joinSession(hash) {
    if (!hash) {
      return;
    }
    const invitationChecker = new InvitationChecker({
      user: this._user,
      config: this._config
    });
    invitationChecker.checkInvitation(hash)
      .then(() => {
        const { modalView } = this._router;
        const loginModal = new InvitationLoginModal({
          modalView,
          parent: this,
          session: this._session,
          confirmCallback: _.bind(function (userInfo) {
            invitationChecker.joinSession(hash, userInfo.alias)
              .then(_.bind(this._onSessionJoined, this))
              .fail(_.bind(function (resp) {
                this._onJoinSessionFailed.apply(this, resp);
              }, this));
          }, this)
        });
        modalView.show(loginModal, { keyboard: false, backdrop: 'static' });
      })
      .fail(resp => {
        this._onJoinSessionFailed.apply(this, resp);
      });
  },

  _onSessionJoined(data) {
    this._session.joinSession(data);
    if (!this._drawingMenuView) {
      this._openDrawing();
    } else {
      this.navigate(`dessin/edit/${this._session.get('map').id || ''}`, true);
    }
    this._router.updateContext({
      cartoMode: true,
      collaborativeMode: true,
      isGuest: true
    });
  },

  _onJoinSessionFailed(xhr) {
    switch (xhr.responseText) {
      case 'TOO_MANY_USERS_ON_SESSION':
        this._alert('discuss.tooManyUsers.title', 'discuss.tooManyUsers.message');
        break;
      case 'SESSION_CLOSED':
        this._alert('discuss.sessionExpired.title', 'discuss.sessionExpired.message');
        break;
      default:
        this._alert('discuss.invalidInvitation.title', 'discuss.invalidInvitation.message');
        break;
    }
    if (!this._drawingMenuView) {
      this._openDrawing();
    }
    this.navigate('dessin', true);
  },

  generateNewMapName(userMaps) {
    userMaps = userMaps || this._cartoDynMaps;
    const defaultName = this._config.cartodyn.default_map_name;
    let i = 1; let
      ok = false;
    while (!ok) {
      const candidate = `${defaultName}_${i}`;
      i += 1;
      ok = !userMaps.findWhere({ name: candidate });
      if (ok) {
        return candidate;
      }
    }

    return null;
  },

  _alert(i18nKeyTitle, i18nKeyMessage) {
    const modalView = this._modalView || window.MODALVIEW;
    const content = new AlertModal({
      modalView,
      title: i18nKeyTitle,
      message: i18nKeyMessage,
      hideOKButton: true,
      parent: this
    });
    modalView.show(content);
  },
  _openPrintPage() {
    this.navigate('dessin/print-page', true);
  }
});

module.exports = DrawingMenuRouter;
