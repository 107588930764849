const ShomView = require('../core/shom-view');
const LogoView = require('./logo.view');

module.exports = ShomView.build({

  tagName: 'ul',

  className: 'list-inline',

  initialize(options) {
    this.eventBus = options.eventBus || window.EVENTBUS;
    this.collection && this.listenTo(this.collection, 'change:includedInMap', function () { this.render(); });
    this._itemViews = [];
  },

  render() {
    this._removeItemViews();
    const toRender = this._getOriginators();
    toRender.forEach(originator => {
      this._renderItemView(originator);
    }, this);
    return this;
  },

  _removeItemViews() {
    this._itemViews.forEach(itemView => {
      itemView.remove();
    });
    this._itemViews = [];
    this.$el.empty();
  },

  _getOriginators() {
    const includedLayers = this.collection.where({ includedInMap: true });
    const originators = this._getOriginatorsArray(includedLayers);
    return Array.from(new Set(originators.map(originator => originator.name)))
      .map(name => originators.find(originator => originator.name === name));
  },

  _getOriginatorsArray(layers) {
    const array = [];
    layers.forEach(layer => {
      const originators = layer.get('originators');
      originators && array.push(...originators);
    });
    return array;
  },

  _renderItemView(originator) {
    if (originator) {
      this.$el.append(new LogoView({ originator }).render().$el);
    }
  }
});
