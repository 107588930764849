import METERS_PER_UNIT from 'ol/proj/Units';

/*
OpenLayers assumes that there is 4374754 inches per degree.
This number is not correct regarding the SHOM TMS "4326" which assume that
there is 111319.4908 meters per degree.

Hence, we override OpenLayers constant

 */
METERS_PER_UNIT.degrees = 111319.4908;
