import { DEFAULT_RADIUS, getDistance } from 'ol/sphere';

const AzimuthCoordsDistanceHelper = function () {};

// Earth mean radius in meters  source: https://openlayers.org/en/latest/apidoc/module-ol_sphere.html
AzimuthCoordsDistanceHelper.EARTH_MEAN_RADIUS = DEFAULT_RADIUS;
// https://www.mathwarehouse.com/trigonometry/radians/convert-degee-to-radians.php
AzimuthCoordsDistanceHelper.DEGREE_CONVERT_VALUE = 180;
// convert unity between meter and nautical miles source: http://www.the-converter.net/fr/lengths/NM/m
AzimuthCoordsDistanceHelper.DISTANCE_CONVERT_VALUE = 0.00054;
AzimuthCoordsDistanceHelper.AZIMUTH_MAX_VALUE = 360;
AzimuthCoordsDistanceHelper.azimuthRegex = /^([0-9]*)\.([0-9]*)\°$|^([0-9]*)\°$|^([0-9]*)$|^([0-9]*)\.([0-9]*)$/;
AzimuthCoordsDistanceHelper.distanceRegex = /^([0-9]*)\.([0-9]*)$|^([0-9]*)$/;

AzimuthCoordsDistanceHelper.radToDeg = function (radianValue) {
  return (radianValue * AzimuthCoordsDistanceHelper.DEGREE_CONVERT_VALUE) / Math.PI;
};

AzimuthCoordsDistanceHelper.degToRad = function (degreeValue) {
  return (degreeValue * Math.PI) / AzimuthCoordsDistanceHelper.DEGREE_CONVERT_VALUE;
};

AzimuthCoordsDistanceHelper.convertDistMeterToNauticalMil = function (distance) {
  return (distance * AzimuthCoordsDistanceHelper.DISTANCE_CONVERT_VALUE).toFixed(5);
};

AzimuthCoordsDistanceHelper.convertDistNauticalMilToMeter = function (distance) {
  return (distance / AzimuthCoordsDistanceHelper.DISTANCE_CONVERT_VALUE).toFixed(3);
};

/**
 * @param coords {Array<Number>|ol.coordinate}
 * @param azimuth {number}
 * @param distOnMeter {number}
 * @param coordinateLayout {ol.geom.GeometryLayout=}
 * @returns {[number, number]|[number, number, number]}
 */
AzimuthCoordsDistanceHelper.computeCoordinates = function (coords, azimuth, distOnMeter, coordinateLayout) {
  const azimuthOnRadian = AzimuthCoordsDistanceHelper.degToRad(azimuth);
  const latOnRadian = AzimuthCoordsDistanceHelper.degToRad(coords[1]);
  const longOnRadian = AzimuthCoordsDistanceHelper.degToRad(coords[0]);
  const radius = AzimuthCoordsDistanceHelper.EARTH_MEAN_RADIUS;
  // computing new coordinates
  const lat = Math.asin(Math.sin(latOnRadian) * Math.cos(distOnMeter / radius)
        + Math.cos(latOnRadian) * Math.sin(distOnMeter / radius) * Math.cos(azimuthOnRadian));
  const long = longOnRadian + Math.atan2(Math.sin(azimuthOnRadian) * Math.sin(distOnMeter / radius)
        * Math.cos(latOnRadian), Math.cos(distOnMeter / radius) - Math.sin(latOnRadian) * Math.sin(lat));
  const latOnDeg = AzimuthCoordsDistanceHelper.radToDeg(lat);
  const longOnDeg = AzimuthCoordsDistanceHelper.radToDeg(long);
  if (coordinateLayout === 'XYZ') {
    return [longOnDeg, latOnDeg, 0];
  }
  return [longOnDeg, latOnDeg];
};

AzimuthCoordsDistanceHelper.computeAzimuth = function (coordsOriginPoint, coordsDestinationPoint) {
  const latOriginPoint = AzimuthCoordsDistanceHelper.degToRad(coordsOriginPoint[1]);
  const latDestinationPoint = AzimuthCoordsDistanceHelper.degToRad(coordsDestinationPoint[1]);
  const longOriginPoint = AzimuthCoordsDistanceHelper.degToRad(coordsOriginPoint[0]);
  const longDestinationPoint = AzimuthCoordsDistanceHelper.degToRad(coordsDestinationPoint[0]);
  const longDelta = longDestinationPoint - longOriginPoint;
  const y = Math.sin(longDelta) * Math.cos(latDestinationPoint);
  const x = Math.cos(latOriginPoint) * Math.sin(latDestinationPoint)
        - Math.sin(latOriginPoint) * Math.cos(latDestinationPoint) * Math.cos(longDelta);
  let azimuth = AzimuthCoordsDistanceHelper.radToDeg(Math.atan2(y, x));
  if (azimuth < 0) {
    azimuth += AzimuthCoordsDistanceHelper.AZIMUTH_MAX_VALUE;
  }
  return azimuth.toFixed(1);
};

AzimuthCoordsDistanceHelper.computeDistance = function (coordsOriginPoint, coordsDestinationPoint) {
  // source: https://github.com/openlayers/openlayers/blob/v4.3.0/src/ol/sphere.js line 67-69
  const distance = AzimuthCoordsDistanceHelper.convertDistMeterToNauticalMil(
    getDistance(coordsOriginPoint, coordsDestinationPoint)
  );
  return parseFloat(distance).toFixed(3);
};
module.exports = AzimuthCoordsDistanceHelper;
