const $ = require('jquery');
const ShomView = require('../../core/shom-view.js');
const template = require('../../../template/drawing/drawing-menu.hbs');

const DrawingMenuView = ShomView.build({

  className: 'left-menu-content',

  events: {
    'click input:checkbox[name=display-measurement]': 'handlerMeasurement',
    'click input:checkbox[name=display-legend]': 'handlerLegend'
  },

  initialize(options = {}) {
    this._gisView = options.gisView || window.GISVIEW;
    this._router = options.router || window.ROUTER;
    this._gfiModeManager = options.gfiModeManager || window.POI_MODE_MANAGER;
    this._displayOptions = this._gisView.getDisplayOptions();
  },

  render() {
    this.$el.html(template());
    this.updateCheckboxes();
    return this;
  },

  setContent(content) {
    this.$el.find('.drawing-menu-content').html(content);
  },

  updateCheckboxes() {
    this.$('input[type=checkbox][name=display-measurement]').prop('checked', this._displayOptions.measurement);
    this.$('input[type=checkbox][name=display-legend]').prop('checked', this._displayOptions.legend);
  },

  handlerMeasurement() {
    const checked = $('input[type=checkbox][name=display-measurement]').is(':checked');
    this._displayOptions.measurement = checked;
    this._executeCartoModuleCommands(checked ? [['measurement.enable']] : [['measurement.disable']]);
    checked && $('.sc-measurement').draggable({
      handle: '.measurement-header',
      containment: '.map-container'
    });
  },

  handlerLegend() {
    const checked = $('input[type=checkbox][name=display-legend]').is(':checked');
    this._displayOptions.legend = checked;
    $('.legend-panel-container').css('display', checked ? 'block' : 'none');
  }

});

module.exports = DrawingMenuView;
