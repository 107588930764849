const $ = require('jquery');
const ShomView = require('../core/shom-view');
const template = require('../../template/choose-coord-dropdown-list.hbs');

module.exports = ShomView.build({

  initialize(options) {
    const optionsToUse = options || {};
    this._router = optionsToUse.router || window.ROUTER;
    this.gisView = optionsToUse.gisView || window.GISVIEW;
    this.eventsBus = optionsToUse.eventsBus || window.EVENTBUS;
  },
  events: {
    'click a': 'chooseCoordinate'
  },

  render() {
    this.$el.html(template());
    return this;
  },

  chooseCoordinate(event) {
    const selectedCoord = $(event.currentTarget);
    const coordinateSyst = selectedCoord.attr('value');
    this.gisView.setCoordSyst(coordinateSyst);
  }
});
