const ShomView = require('../../../core/shom-view.js');
const template = require('../../../../template/drawing/legend/legend-item.hbs');

const StylePreviewFacory = require('./feature-style-preview-factory');

const LegendItemView = ShomView.build({
  tagName: 'li',

  className: 'legend-item',

  events: {
    'blur textarea': '_onTextareaBlur',
    'click .delete': 'delete'
  },

  initialize(options) {
    this.listenToOnce(this.model, 'destroy', this.remove);
    this._isEditor = options.isEditor || false;
  },

  render() {
    this.$el.html(template({
      model: this.model.toJSON(),
      isEditor: this._isEditor
    }));
    this._renderStyleSample();
    return this;
  },

  _renderStyleSample() {
    if (!this.model.get('feature')) {
      // should not append but avoid breaking the rendering
      return;
    }
    const stylePreview = StylePreviewFacory.getStylePreviewInstance(this.model.get('feature'));
    stylePreview.render();
    this.$('.legend-style-preview').html(stylePreview.$el);
  },

  _onTextareaBlur() {
    this.model.set('label', this.$('textarea').val());
  },

  delete(options) {
    this.model.destroy(options);
  },

  remove() {
    LegendItemView.__super__.remove.apply(this);
  }
});

module.exports = LegendItemView;
