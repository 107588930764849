const ShomLayerView = require('../../core/shom-layer-view');
const IncludedInMapView = require('../layer-included-in-map.view');
const ToastrUtil = require('../../utils/toastr.js');
const LayerUtil = require('../../utils/layer.js');

const template = require('../../../template/catalog/layer.hbs');
const { CATALOG_DEFAULT_ORDER_INDEX } = require('../../utils/constants');

module.exports = ShomLayerView.build({

  className: 'catalog-layer-item',

  events: {
    'click .layer-title-container': '_onTitleClick',
    'click .layer-help-link': 'onHelpLinkClick'
  },

  initialize(options = {}) {
    this._markTerm = null;
    this.oceano = options.oceano || false;
    this._gfiModeManager = options.gfiModeManager || window.POI_MODE_MANAGER;
    this._router = options.router || window.ROUTER;
    this._config = options.config || window.CONFIG;
    this._eventBus = options.eventBus || window.EVENTBUS;
    this._gisView = options.gisView || window.GISVIEW;
    this.searchView = options.searchView || false;
    // keep index in view to sort all views in a category
    this.index = this.model.get('indexInCat') || CATALOG_DEFAULT_ORDER_INDEX;
  },

  render() {
    const hasHelpLinks = !!(this.model.attributes.helpLinks);
    this.$el.html(template({
      layer: this.model.toJSON(),
      layerLocalTitle: this._getLayerLocalTitle(),
      oceano: this.oceano,
      hasHelpLinks,
      includedInMap: this.model.get('includedInMap')
    }));

    this._$layerTitle = this.$('.layer-title-container');
    this._$layerDescription = this.$('.description');
    this._$addRemoveContainer = this.$('.add-remove-root');
    this._renderAddRemoveView();

    if (this._markTerm) {
      this.setMark(this._markTerm);
    }

    return this;
  },

  _renderAddRemoveView() {
    this._addRemoveView && this._addRemoveView.remove();
    this._addRemoveView = new IncludedInMapView({
      model: this.model
    });
    this._$addRemoveContainer.html(this._addRemoveView.render().$el);
  },

  _getLayerLocalTitle() {
    return window.portalLang === 'en' && this.model.get('englishName') ? this.model.get('englishName') : this.model.get('title');
  },

  _onTitleClick() {
    const includedInMap = this.model.get('includedInMap');
    if (this.model.get('productId') === this._config.copernicus.layerDescBase.productId) {
      if (!includedInMap) {
        this._gisView.initCopernicusGis(this.model.get('name'));
      } else {
        this._gisView.removeCopernicusGis(this.model.get('name'));
      }
    } else {
      this.model.set('includedInMap', !includedInMap);
      this.model.set('visibility', (includedInMap) ? this.model.get('visibility') : true);
      this._gfiModeManager.startGfiRequestableLayer(false);
    }

    this._showLayerAdditionnalInfo();
  },

  _showLayerAdditionnalInfo() {
    if (this.model.get('includedInMap') && this.model.get('additionalInfo')) {
      ToastrUtil.info(this.model.get('additionalInfo')[window.portalLang]);
    }
  },

  postTranslate(lang) {
    let translatedTitle = '';
    if (this.searchView) {
      translatedTitle = LayerUtil.buildLayerNameWithCategory(this.model, lang);
    } else {
      const englishName = this.model.get('englishName');
      if (!englishName) {
        return;
      }
      translatedTitle = lang === 'fr' ? this.model.get('title') : englishName;
    }

    const abstract = this.model.get('abstract');

    this._$layerTitle
      .empty()
      .append(translatedTitle)
      .append(`<span class="description">${abstract}</span>`);
  }

});
