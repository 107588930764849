const $ = require('jquery');

module.exports = function () {
  return {
    'user-login': {
      validators: {
        notEmpty: {
          message: $.t('user.form.login.missing')
        },
        emailAddress: {
          message: $.t('user.form.login.emailError')
        }
      }
    },
    'user-password': {
      validators: {
        notEmpty: {
          message: $.t('user.form.password.missing')
        }
      }
    }
  };
};
