var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"atlas-infos\">\n            <span class=\"list-group-item-text\" data-i18n=\"legend.atlas.ref\"></span>:\n            <span class=\"atlas-infos-portref list-group-item-text\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"pmBmLabel") || (depth0 != null ? lookupProperty(depth0,"pmBmLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pmBmLabel","hash":{},"data":data,"loc":{"start":{"line":7,"column":67},"end":{"line":7,"column":80}}}) : helper)))
    + "&nbsp;<a class=\"portref-link\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"portRefUrl") || (depth0 != null ? lookupProperty(depth0,"portRefUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"portRefUrl","hash":{},"data":data,"loc":{"start":{"line":7,"column":116},"end":{"line":7,"column":132}}}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"portRef") || (depth0 != null ? lookupProperty(depth0,"portRef") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"portRef","hash":{},"data":data,"loc":{"start":{"line":7,"column":150},"end":{"line":7,"column":161}}}) : helper)))
    + "</a></span>\n            <span class=\"list-group-item-text\" data-i18n=\"legend.atlas.timeLabel\"></span>&nbsp;<span class=\"atlas-infos-pm list-group-item-text\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"pmBmValue") || (depth0 != null ? lookupProperty(depth0,"pmBmValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pmBmValue","hash":{},"data":data,"loc":{"start":{"line":8,"column":145},"end":{"line":8,"column":158}}}) : helper)))
    + "</span>\n            (<span class=\"list-group-item-text\" data-i18n=\"legend.atlas.coeffLabel\"></span>&nbsp;<span class=\"atlas-infos-coeff list-group-item-text\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"coeff") || (depth0 != null ? lookupProperty(depth0,"coeff") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"coeff","hash":{},"data":data,"loc":{"start":{"line":9,"column":150},"end":{"line":9,"column":159}}}) : helper)))
    + "</span>)\n        </div>\n        <br/>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isOceanoLayer") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":2},"end":{"line":19,"column":13}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "			<li>\n				<h2 class=\"legend-title legend-button\"><a href=\"#\" role=\"button\" data-i18n=\"legend.layer.legend\"></a></h2>\n			</li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "		<li>\n			<h5 class=\"legend-error\" data-i18n=\"legend.layer.error\"></h5>\n		</li>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"metadata") : depth0)) != null ? lookupProperty(stack1,"limitations") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":2},"end":{"line":40,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"metadata") : depth0)) != null ? lookupProperty(stack1,"contact") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":2},"end":{"line":50,"column":9}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<li>\n				<ul class=\"list-group-item-text list-unstyled limitations-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"metadata") : depth0)) != null ? lookupProperty(stack1,"limitations") : stack1),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":5},"end":{"line":37,"column":14}}})) != null ? stack1 : "")
    + "				</ul>\n			</li>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "					<li><i class=\"fas fa-exclamation-triangle\"></i>&nbsp; "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</li>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<li>\n				<ul class=\"list-group-item-text list-unstyled\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"metadata") : depth0)) != null ? lookupProperty(stack1,"contact") : stack1),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":5},"end":{"line":47,"column":14}}})) != null ? stack1 : "")
    + "				</ul>\n			</li>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<li><span data-i18n=\"legend.layer.contact\"></span> "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + ": "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "</li>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "		<li>\n			<span class=\"metadata-error\" data-i18n=\"metadata.error\"></span>\n		</li>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<li>\n			<a class=\"metadata-link\" href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"metadataURL") || (depth0 != null ? lookupProperty(depth0,"metadataURL") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"metadataURL","hash":{},"data":data,"loc":{"start":{"line":60,"column":34},"end":{"line":60,"column":51}}}) : helper)))
    + "\" target=\"_blank\" data-i18n=\"legend.layer.link\"></a>\n		</li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"list-group\">\n\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAtlasLayer") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":12,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"layer") : depth0)) != null ? lookupProperty(stack1,"legendUrl") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":14,"column":1},"end":{"line":24,"column":8}}})) != null ? stack1 : "")
    + "\n	<li>\n		<p class=\"list-group-item-text list-unstyled layer-abstract\"></p>\n	</li>\n\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"metadata") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":31,"column":1},"end":{"line":55,"column":8}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"metadataURL") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":1},"end":{"line":62,"column":8}}})) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true});