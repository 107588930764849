const templateBaseAtlas = require('../../template/modal/gfi-modal-atlas-base-content.hbs');
const templateAtlas = require('../../template/modal/gfi-modal-atlas.hbs');
const templateOceano = require('../../template/modal/gfi-modal-oceano.hbs');
const templateBaseGfi = require('../../template/modal/gfi-modal-base-content.hbs');

const GFIModalContent = {

  /**
     * @param title {string}
     * @param number {number}
     * @param toogleCollapse {string}
     * @param collapse {string}
     * @param htmlTSContent {string}
     * @param isRadarHF {?boolean}
     * @returns {string}
     */
  addHtmlBaseContent(title, number, toogleCollapse, collapse, htmlTSContent, isRadarHF) {
    return templateBaseGfi({
      title, number, toogleCollapse, collapse, htmlTSContent, isRadarHF
    });
  },

  addHtmlAtlasBaseContent(title, number, toogleCollapse, collapse, htmlTSContent, isAtlas, portRef) {
    return templateBaseAtlas({
      title, number, toogleCollapse, collapse, htmlTSContent, isAtlas, portRef
    });
  },

  addHtmlOceanoContent(number) {
    return templateOceano({ number });
  },

  addHtmlAtlasContent(number) {
    return templateAtlas({ number });
  }
};

module.exports = GFIModalContent;
