const ShomProxy = module.exports = {
  url: '',
  authUrl: ''
};

ShomProxy.getProxifiedUrl = function (url, auth) {
  let proxifiedUrl = url;
  if (auth) {
    const encodedAuth = window.btoa(`${auth.login}:${auth.password}`);
    proxifiedUrl = `${this.authUrl}/${encodedAuth}/${url.replace('http://', 'http:/').replace('https://', 'https:/')}`;
  } else if (this.url) {
    proxifiedUrl = `${this.url}/${url.replace('http://', 'http:/').replace('https://', 'https:/')}`;
  }
  return proxifiedUrl;
};
