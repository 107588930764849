const $ = require('jquery');
const Dialog = require('bootstrap-dialog');
const GlobalDataStore = require('../../core/global-data-store.js');
const ShomLayerView = require('../../core/shom-layer-view');
const IncludedInMapView = require('../layer-included-in-map.view');
const OceanoLayerUtils = require('../../utils/oceano/oceano-layer');
const ExternalLayersHelper = require('../../utils/external-contexts');

const template = require('../../../template/catalog/partner-layer.hbs');

module.exports = ShomLayerView.build({

  className: 'catalog-layer-item',

  events: {
    'click .layer-title-container': '_onTitleClick',
    'click .layer-help-link': 'onHelpLinkClick'
  },

  initialize(options = {}) {
    this._gisView = options.gisView || window.GISVIEW;
    this._gfiModeManager = options.gfiModeManager || window.POI_MODE_MANAGER;
    this._helper = options.helper || new ExternalLayersHelper();
  },

  render() {
    const translatedTitle = OceanoLayerUtils.getLayerTitle(this.model, window.portalLang);
    const hasHelpLinks = !!(this.model.attributes.helpLinks);
    this.$el.html(template({
      hasHelpLinks,
      translatedTitle
    }));
    this._$oceanoTitleContainer = this.$('.layer-title-container');
    this._$layerDescription = this.$('.description');

    this._$addRemoveContainer = this.$('.add-remove-root');
    this._renderAddRemoveView();
    this._gfiModeManager.startGfiRequestableLayer(false);

    return this;
  },

  _renderAddRemoveView() {
    this._addRemoveView && this._addRemoveView.remove();
    // Warning: when refreshing window ([F5]), external layer model attribute 'includedInMap' is always false, even if layer is displayed
    this._addRemoveView = new IncludedInMapView({
      model: this.model,
      parentView: this
    });
    this._$addRemoveContainer.html(this._addRemoveView.render().$el);
  },

  _onTitleClick() {
    const context = this.model.get('context');
    const externalContext = this.collection.findWhere({
      context
    });

    const includedInMap = externalContext && externalContext.get('includedInMap');
    const visibility = (includedInMap) ? true : this.model.get('visibility');

    this.model.set('includedInMap', includedInMap);
    this.model.set('visibility', visibility);

    if (!includedInMap) {
      const url = `${this.model.get('baseUrl')}/${context}`;
      $.get(url).then(wmcDocument => {
        this._gisView.loadContext(wmcDocument, false, null, context, {}, this.model);
        this._helper.setIncludedInMapStatus(this.collection, this.model, true);
        this._gfiModeManager.startGfiRequestableLayer(false);
      })
        .fail(() => {
          Dialog.show({
            type: Dialog.TYPE_DANGER,
            title: $.i18n.t('error.application.unknown.title'),
            message: $.i18n.t('error.application.unknown.message')
          });
        });
    } else if (this.collection && context !== undefined) {
      const layerToRemove = [];
      GlobalDataStore.getDisplayedLayers().where(layer => {
        if (layer.get('contextName') === context) {
          layerToRemove.push(layer);
        }
      });
      layerToRemove.forEach(layer => {
        layer.set('includedInMap', false);
        layer.set('visibility', false);
      });
    }
  }

});
