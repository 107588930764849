const DDMSensorReader = function () {

};

DDMSensorReader.getShomId = function (system) {
  return system.shom_id;
};

DDMSensorReader.getLongitude = function (system) {
  return system.longitude;
};

DDMSensorReader.getLatitude = function (system) {
  return system.latitude;
};

DDMSensorReader.getName = function (system) {
  return system.name;
};

DDMSensorReader.getState = function (system) {
  return system.state;
};

DDMSensorReader.getVerticalRef = function (system) {
  return system.verticalRef;
};

DDMSensorReader.getTimezone = function (system) {
  return system.timezone;
};

DDMSensorReader.getLogo = function (system) {
  return system.organismes.length ? system.organismes[0].logo : null;
};

DDMSensorReader.getSPMHarbor = function (system) {
  return system.spm;
};

DDMSensorReader.getOrganisme = function (system) {
  return system.organismes;
};

DDMSensorReader.getOrganismesLogos = function (system) {
  return system.organismes.map(elt => elt.logo);
};

DDMSensorReader.getMembers = function (system) {
  return system.members;
};

DDMSensorReader.getNPHMA = function (system) {
  return parseFloat(system.nphma);
};

DDMSensorReader.getNPBMA = function (system) {
  return parseFloat(system.npbma);
};

DDMSensorReader.getNiveauMoyen = function (system) {
  return parseFloat(system.niveau_moyen);
};

DDMSensorReader.getProducer = function (system) {
  return system.gestionnaire || system.producer;
};

DDMSensorReader.getNetwork = function (system) {
  return system.reseau === 'RONIM' ? 'RONIM' : 'OTHER';
};

module.exports = DDMSensorReader;
