const { Jsonix } = require('@opentempl/jsonix');

const { XLink_1_0 } = require('w3c-schemas/lib/XLink_1_0');
const { OWC_0_3_1 } = require('ogc-schemas/lib/OWC_0_3_1');
const { OWS_1_0_0 } = require('ogc-schemas/lib/OWS_1_0_0');
const { OWS_1_1_0 } = require('ogc-schemas/lib/OWS_1_1_0');
const { SLD_1_0_0 } = require('ogc-schemas/lib/SLD_1_0_0');
const { WFS_1_1_0 } = require('ogc-schemas/lib/WFS_1_1_0');
const { Filter_1_0_0 } = require('ogc-schemas/lib/Filter_1_0_0');
const { Filter_1_1_0 } = require('ogc-schemas/lib/Filter_1_1_0');
const { Filter_2_0 } = require('ogc-schemas/lib/Filter_2_0');
const { GML_2_1_2 } = require('ogc-schemas/lib/GML_2_1_2');
const { GML_3_1_1 } = require('ogc-schemas/lib/GML_3_1_1');
const { SMIL_2_0 } = require('ogc-schemas/lib/SMIL_2_0');
const { SMIL_2_0_Language } = require('ogc-schemas/lib/SMIL_2_0_Language');

const WfsJsonixMapping = function () {
  const mappings = [OWS_1_0_0, OWS_1_1_0, SLD_1_0_0, XLink_1_0, GML_2_1_2, GML_3_1_1, Filter_1_0_0, Filter_1_1_0, Filter_2_0, SMIL_2_0, SMIL_2_0_Language, OWC_0_3_1, WFS_1_1_0];
  const nsPrefixes = {
    'http://www.opengis.net/cat/csw/2.0.2': 'csw',
    'http://www.opengis.net/ogc': 'ogc',
    'http://www.opengis.net/gml': 'gml',
    'http://www.w3.org/1999/xlink': 'xlink',
    'http://www.opengis.net/ows': 'ows',
    'http://www.opengis.net/wfs': 'wfs'
  };
  const writeContext = new Jsonix.Context(mappings, {
    namespacePrefixes: nsPrefixes
  });
  const readContext = new Jsonix.Context(mappings);
  this._unmarshaller = readContext.createUnmarshaller();
  this._marshaller = writeContext.createMarshaller();
};

WfsJsonixMapping.prototype.read = function (data) {
  if (!data) {
    return {};
  }
  if (typeof data === 'string') {
    return this._unmarshaller.unmarshalString(data);
  }
  return this._unmarshaller.unmarshalDocument(data);
};

WfsJsonixMapping.prototype.write = function (json) {
  return this._marshaller.marshalString(json);
};

export default (new WfsJsonixMapping());
