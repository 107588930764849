import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Draw from 'ol/interaction/Draw';
import {
  Circle, Fill, Stroke, Style
} from 'ol/style.js';

const source = new VectorSource();

let sketch = null;

let map = null;

let firstTransectHandler = null;
let lastTransectHandler = null;

const vector = new VectorLayer({
  source,
  style: new Style({
    stroke: new Stroke({
      color: '#FF4500',
      width: 2
    }),
    image: new Circle({
      radius: 7,
      fill: new Fill({
        color: '#FF4500'
      })
    })
  })
});

const drawInteraction = new Draw({
  source,
  type: 'LineString',
  style: new Style({
    stroke: new Stroke({
      color: '#FF4500',
      width: 2
    }),
    image: new Circle({
      radius: 2,
      stroke: new Stroke({
        color: '#FF4500'
      }),
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)'
      })
    })
  })
});

drawInteraction.on('drawstart', evt => {
  source.clear();
  sketch = evt.feature;
  if (sketch === null) {
    return;
  }
  firstTransectHandler(sketch.getGeometry().getFirstCoordinate());
});

drawInteraction.on('drawend', () => {
  if (sketch === null) {
    return;
  }
  lastTransectHandler(sketch.getGeometry().getCoordinates());
  sketch = null;
});

const transectControl = {
  start(mapp, firstHandler, lastHandler) {
    map = mapp;
    firstTransectHandler = firstHandler;
    lastTransectHandler = lastHandler;
    map.addLayer(vector);
    map.addInteraction(drawInteraction);
  },
  stop() {
    source.clear();
    map.removeLayer(vector);
    map.removeInteraction(drawInteraction);
  }
};

module.exports = transectControl;
