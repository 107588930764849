const Backbone = require('backbone');
const _ = require('underscore');
const $ = require('jquery');

const SessionUpdater = require('./drawing/discuss/session-updater');

const CartoDynMap = require('./cartodyn-map');

const ToastrUtil = require('../utils/toastr.js');

const CollaborativeChatView = require('../view/drawing/discuss/collaborative-chat.view');
const AlertModal = require('../view/modal/alert.view');

const CartoDynSession = Backbone.Model.extend({

  defaults: {
    started: false,
    collaborative: false,
    sessionExternalShutdown: false
  },

  initialize(options) {
    options = options || {};
    this._gisView = options.gisView || window.GISVIEW;
    this._drawingRouter = options.drawingRouter;
    this._user = options.user || window.ROUTER.user;
    this._config = options.config || window.CONFIG;
    this._router = options.router || window.ROUTER;
    this._maps = options.maps;
    this._eventsBus = options.eventsBus || this._router.eventsBus;
    this.set({ permissions: null }, { silent: true });
    this.listenTo(this._user, 'change:isLoggedIn', this._onUserLoggedInChanged.bind(this));
    this.listenTo(this._user, 'pre-disconnect', this._onUserPreDisconnect.bind(this));
    _.bindAll(this, '_onUnloadBody', '_onSessionUpdate');
    this.set('collaborative', false);
    this.listenTo(this, 'change:started', this._toggleSession.bind(this));
    this.listenTo(this, 'change:collaborative', this._toggleCollaborative.bind(this));
    this._sessionUpdater = new SessionUpdater();
    this.listenTo(this._sessionUpdater, 'discuss:session:update', this._onSessionUpdate);
    this.listenTo(this._sessionUpdater, 'discuss:session:closed', this._onSessionEnded);
  },

  _onUserLoggedInChanged() {
    const loggedIn = this._user.get('isLoggedIn');
    if (loggedIn) {
      this._user.fetchPermissions()
        .then(permissions => {
          this.set('permissions', permissions);
        });
    } else {
      this.set('permissions', null);
    }
  },

  _onUserPreDisconnect() {
    this.set('started', false);
  },

  _toggleSession(session) {
    const started = session.get('started');
    if (started === session.previous('started')) {
      return;
    }
    this[`_do${started ? 'Start' : 'Stop'}Session`].call(this);
  },

  _doStartSession() {
    const map = this.get('map');
    if (!this.get('dataFetched') && !map.isNew() && !this.get('collaborative')) {
      this.set('fetchingData', true);
      this.trigger('data:fetch');
      this.fetchMapFiles(map)
        .done(this._saveMapData.bind(this))
        .done(this._doStartSession.bind(this))
        .fail(this._saveLoadingError.bind(this))
        .fail(this._doStopSession.bind(this));
      return;
    }

    if (map.isNew() || this.get('collaborative')) {
      this._context = null;
      this._legends = null;
      this._croquis = null;
    }
    this._gisView.loadContext(this._context);
    this._gisView.addDrawingLayer();
    this._gisView.importKmlData(this._croquis);
    this._gisView.addLegends(this._legends, this);
    this._router.updateContext({ cartoMode: true });
  },

  _doStopSession() {
    if (this.get('collaborative') === true) {
      this.set('collaborative', false);
    }
    this._gisView.removeDrawingLayer();
    this._gisView.removeLegends();
    const permissions = this.get('permissions');
    this.clear({ silent: true });
    this.set(_.extend({}, this.defaults, { permissions }), { silent: true });
    this._router.updateContext({ cartoMode: false });
  },

  _toggleCollaborative() {
    const collaborative = this.get('collaborative');
    if (collaborative === this.previous('collaborative')) {
      return;
    }
    this[`_do${collaborative ? 'Start' : 'Stop'}Collaborative`].call(this);
  },

  _doStartCollaborative() {
    if (this.get('started') && this.get('collaborative')) {
      this.set('lastUpdateEvent', null);
      const map = this.get('map');
      $(window).on('unload', this._onUnloadBody);
      this._addChat();
      this._sessionUpdater.set('getUpdatesUrl', this._config.cartodyn.url + map.getUpdates);
      this._sessionUpdater.set('sessionId', this.get('sessionInfo').sessionId);
      this._sessionUpdater.set('user', this._user);
      this._sessionUpdater.authHeader = this._user.authHeader();
      this._sessionUpdater.update();
      this._sessionUpdater.start();
      this._router.updateContext({
        cartoMode: true,
        collaborativeMode: true
      });
    }
  },

  _doStopCollaborative() {
    this.set('lastUpdateEvent', null);
    $(window).off('unload', this._onUnloadBody);
    this._sessionUpdater.reset();
    if (this.get('sessionExternalShutdown') !== true) {
      this._leaveSession(true);
    }
    this.set('sessionExternalShutdown', false);
    if (!this._user.get('isLoggedIn')) {
      this._user.anonymous();
    }
    this.set('sessionInfo', null);
    this._sessionUpdater.set('user', null);
    this.get('map').cleanMapFromSession();
    this._router.updateContext({
      collaborativeMode: false,
      isEditor: true,
      isGuest: false
    });
    this._eventsBus.trigger('discuss:editor:update', true);
  },

  _addChat() {
    if (!this._chatView) {
      this._chatView = new CollaborativeChatView({ model: this });
    }
    this._chatView.setElement($('#collaborative-chat-root'));
    this._chatView.render();
  },

  _onUnloadBody() {
    return this._leaveSession(false);
  },

  forceCheckUpdates() {
    this._sessionUpdater.update();
  },

  _onGetSessionInfo(sessionInfo) {
    const keyFromSession = this._getUserKeyFromSession(sessionInfo);
    this._user.set('key', keyFromSession);
    const filteredProperties = _.pick(sessionInfo, CartoDynMap.MAP_PROPERTIES);
    const otherProperties = _.omit(sessionInfo, CartoDynMap.MAP_PROPERTIES);
    const map = new CartoDynMap(filteredProperties);
    _.extend(map, otherProperties);
    this._drawingRouter.setupMap(map);
    this.set('sessionInfo', sessionInfo);
    this.set('map', map);
    this.set({
      collaborative: true,
      started: true
    }, { silent: true });
    this.trigger('change:started', this);
    this.trigger('change:collaborative', this);
  },

  _getUserKeyFromSession(sessionInfo) {
    return sessionInfo.getCroquis.split('/')[1];
  },

  _onSessionUpdate(event) {
    if (event && event.editorKey !== this._user.get('key') && (event.updateKML || event.updateWMC || event.updateLegend)) {
      const map = this.get('map');
      this.fetchMapFiles(map)
        .done(this._saveMapData.bind(this))
        .done(() => {
          const mapParams = {
            center: this._gisView.getMapCenter(),
            zoom: this._gisView.getZoom()
          };
          this._gisView.loadContext(this._context, true, true);
          this._gisView.clearDrawingLayer();
          this._gisView.importKmlData(this._croquis);
          this._gisView.addLegends(this._legends, this);
          this._gisView._updateMapZoom(mapParams.zoom);
          this._gisView._updateMapCenter(mapParams.center);
        })
        .fail(this._saveLoadingError.bind(this));
    }
    const lastUpdateEvent = this.get('lastUpdateEvent');
    const oldEditor = lastUpdateEvent ? lastUpdateEvent.editorKey : this.get('map').get('ownerKey');
    if (oldEditor !== event.editorKey) {
      if (event.editorKey === this._user.get('key')) {
        this._router.updateContext({ isEditor: true });
        // warn the user is now the editor of the map
        ToastrUtil.info($.i18n.t('discuss.editor.youAreTheEditor'));
      } else if (oldEditor === this._user.get('key')) {
        this._router.updateContext({ isEditor: false });
        // warn the user is no more the editor of the map
        ToastrUtil.info($.i18n.t('discuss.editor.youAreNoLongerTheEditor'));
        // disable active drawing tool if necessary
        this._gisView.leaveCurrentDrawMode();
      }
    }
    this.set('lastUpdateEvent', event);
    this.trigger('discuss:session:update', event);
    this._eventsBus.trigger('discuss:editor:update', event.updateEditor);
  },

  isCurrentUserEditor() {
    if (this.get('collaborative') === false) {
      return true;
    }

    if (this._sessionUpdater) {
      return this._sessionUpdater.get('editorKey') === this._user.get('key');
    }
    return false;
  },

  _onSessionEnded(cause) {
    // ending session cause the end of collaborative mode too
    this.set('sessionExternalShutdown', true);
    this.set('started', false);
    this.set('map', null);
    this.trigger('discuss:session:closed');
    this._drawingRouter.navigate('dessin', true);
    let errorName;
    switch (cause) {
      case 'ACCESS_FORBIDDEN':
        errorName = 'accessDenied'; break;
      case 'SESSION_UNFOUND':
        errorName = 'sessionExpired'; break;
      default:
        console.error(`Unexpected error while getting update ${cause}`);
        return;
    }
    this._alert(`discuss.${errorName}.title`, `discuss.${errorName}.message`);
  },

  _alert(i18nKeyTitle, i18nKeyMessage) {
    const modalView = this._modalView || window.MODALVIEW;
    const content = new AlertModal({
      title: i18nKeyTitle,
      message: i18nKeyMessage,
      modalView,
      hideOKButton: true,
      parent: this
    });
    modalView.show(content);
  },

  isCurrentUserOwner() {
    if (this.get('collaborative') === false) {
      return true;
    }

    if (this._sessionUpdater) {
      return this.get('map').get('ownerKey') === this._user.get('key');
    }
    return false;
  },

  createCollaborativeSession() {
    if (this.get('started') && !this.get('collaborative')) {
      this._sessionUpdater.reset();
      this.saveMap(this.get('map'))
        .then(this._createSession.bind(this))
        .then(this._onGetSessionInfo.bind(this))
        .then(() => {
          this._router.updateContext({
            cartoMode: true,
            collaborativeMode: true,
            isGuest: false
          });
        });
    }
  },

  _createSession() {
    return this.get('map').createSession(this._user);
  },

  _leaveSession(async) {
    const leaveSessionURL = this._config.cartodyn.url + this.get('sessionInfo').leaveSession;
    const params = {
      method: 'POST',
      url: leaveSessionURL,
      async: (async === true)
    };
    this.set('sessionInfo', null);
    return this._user.doReq(params);
  },

  _hasAccessToSession(session, userKey) {
    const users = new Backbone.Collection(session.get('connectedUsers'));
    return !!users.findWhere({ key: userKey });
  },

  joinSession(data) {
    this._router.updateContext({
      cartoMode: true,
      collaborativeMode: true,
      isEditor: false,
      isGuest: true
    });
    this._onGetSessionInfo(data);
  },

  resetEditorRights() {
    const ownerKey = this.get('map').get('ownerKey');
    return this.giveEditorRights(ownerKey);
  },

  giveEditorRights(userKey) {
    const giveRightsUrl = this.get('map').url + this.get('map').giveEditorRigths + userKey;
    const params = {
      method: 'POST',
      url: giveRightsUrl,
      data: ''
    };
    return this._user.doReq(params);
  },

  getChatLines(timestamp) {
    const getChatLinesUrl = this.get('map').url + this.get('map').getChat + timestamp;
    const params = {
      method: 'POST',
      url: getChatLinesUrl,
      data: ''
    };
    return this._user.doReq(params);
  },

  putChatLine(message) {
    const getChatLinesUrl = this.get('map').url + this.get('map').putChat;
    const params = {
      method: 'POST',
      url: getChatLinesUrl,
      data: message
    };
    return this._user.doReq(params);
  },

  kickUser(userKey) {
    const kickUserURL = this._config.cartodyn.url + this.get('sessionInfo').leaveSession + userKey;
    const params = {
      method: 'POST',
      url: kickUserURL,
      data: ''
    };
    return this._user.doReq(params);
  },

  _saveLoadingError() {
    this.set('dataFetched', false);
    this.set('dataFetchError', true);
    this.set('fetchingData', false);
    this.trigger('data:fetch:error');
  },

  _saveMapData() {
    this._context = arguments[0][0];
    this._legends = arguments[1][0];
    this._croquis = arguments[2][0];
    this.set('dataFetched', true);
    this.set('dataFetchError', false);
    this.set('fetchingData', false);
    this.trigger('data:fetch:done');
  },

  fetchMapFiles(map) {
    return $.when(map.getContext(), map.getLegends(), map.getCroquis());
  },

  saveMap(map) {
    const deferred = $.Deferred();

    this._legends = this._gisView.getLegends();
    $.when(map.saveMap(this._user.get('key')))
      .done(rawMap => {
        map.set(rawMap);
        $.when(
          map.putContext(this._gisView.exportContext()),
          map.putCroquis(this._gisView.exportKmlData()),
          map.putLegends(this._legends)
        )

          .done(() => {
            map.trigger('sync', map);
            deferred.resolve();
            if (this.isCurrentUserOwner()) {
              this._maps.fetch();
            }
          }).fail(() => {
            deferred.reject();
          });
      }).fail(() => {
        deferred.reject();
      });
    return deferred.promise();
  },

  deleteMap(map) {
    this._drawingRouter.setupMap(map);
    $.when(map.deleteMap(this._user.get('key')))
      .done(() => {
        map.destroy();
        this._maps.fetch();
      }).fail(() => {
        console.error('delete map failed');
      });
  },

  sendInvitation(email) {
    const sessionInfo = this.get('sessionInfo');
    if (sessionInfo) {
      const inviteURL = this._config.cartodyn.url + sessionInfo.sendInvitation + email;
      const params = {
        method: 'POST',
        url: inviteURL,
        data: 'data'
      };
      return this._user.doReq(params);
    }
    throw `Cannot send an invitation if not in a multiuser session (${email})`;
  }

});
module.exports = CartoDynSession;
