var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\"\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"default") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":16},"end":{"line":28,"column":64}}})) != null ? stack1 : "")
    + "\n              >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</option>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " selected=\"selected\" ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"checkbox row "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isHidden") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":35},"end":{"line":57,"column":69}}})) != null ? stack1 : "")
    + "\">\n            <label "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":19},"end":{"line":59,"column":47}}})) != null ? stack1 : "")
    + ">\n              <input type=\"checkbox\" name=\"source"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\" value=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\"\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isChecked") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":16},"end":{"line":61,"column":63}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":16},"end":{"line":62,"column":65}}})) != null ? stack1 : "")
    + "/>\n              <span style=\"color:"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + "\"\n                    data-i18n=\"ddm.labels.source"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\"></span>\n            </label>\n            <span class=\"source"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "-info-button-root\" data-i18n=\"[title]ddm.help\"></span>\n          </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"7":function(container,depth0,helpers,partials,data) {
    return "data-i18n=\"[title]ddm.labels.nodataSource\"\n              class=\"ddm-sourcedisabled\"";
},"9":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\" ";
},"11":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"13":function(container,depth0,helpers,partials,data) {
    return "class=\"ddm-sourcedisabled\"";
},"15":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-group\">\n\n  <div class=\"row\">\n\n    <div class=\"col-sm-12\" id=\"ddm-control-left-inputs\">\n      <div class=\"row\" id=\"addOneLineSpace\">\n        <label for=\"startDate\" class=\"col-lg-12 col-sm-12 control-label \"\n               data-i18n=\"ddm.labels.startDate\">Date de début :</label>\n        <div class=\"col-lg-12 col-sm-12 input-cont\">\n            <input id=\"startDate\" name=\"startDate\" class=\"form-control\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"startDate") : depth0), depth0))
    + "\" />\n        </div>\n      </div>\n      <div class=\"row\">\n        <label for=\"endDate\" class=\"col-sm-12 control-label\"\n               data-i18n=\"ddm.labels.endDate\"></label>\n        <div class=\"col-lg-12 col-sm-12 input-cont\">\n          <input id=\"endDate\" name=\"endDate\" class=\"form-control\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"endDate") : depth0), depth0))
    + "\"/>\n        </div>\n      </div>\n\n      <div class=\"row\">\n        <label for=\"timezone\" class=\"col-sm-12 control-label\"\n               data-i18n=\"ddm.labels.timezone\"></label>\n        <div class=\"col-lg-12 col-sm-12 input-cont\">\n          <select id=\"timezone\" name=\"timezone\" class=\"form-control\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"timezones") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":30,"column":21}}})) != null ? stack1 : "")
    + "          </select>\n        </div>\n      </div>\n\n      <div class=\"row\">\n        <label for=\"refverticale\" class=\"col-sm-12 control-label\"\n               data-i18n=\"ddm.labels.refverticale\"></label>\n        <div class=\"col-lg-12 col-sm-12 input-cont\">\n          <select id=\"refverticale\" name=\"refverticale\" class=\"form-control\">\n          </select>\n        </div>\n      </div>\n\n    </div>\n\n    <div class=\"col-sm-12\">\n      <div class=\"row\">\n        <div class=\"col-lg-12\">\n          <label for=\"source\" class=\"col-sm-12 control-label ddm-graph-title\"\n                 data-i18n=\"ddm.labels.source\"></label>\n        </div>\n      </div>\n\n      <div class=\"col-lg-12 col-mg-12 col-sm-12 col-xs-12\">\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"sources") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":8},"end":{"line":68,"column":17}}})) != null ? stack1 : "")
    + "\n      </div>\n\n      <div class=\"col-lg-12 col-mg-12 col-sm-12 col-xs-12\">\n        <div class=\"checkbox row\">\n          <label "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"spm") : depth0)) != null ? lookupProperty(stack1,"isDisabled") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":17},"end":{"line":74,"column":72}}})) != null ? stack1 : "")
    + ">\n            <input type=\"checkbox\" name=\"source"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"spm") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"spm") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\"\n              "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"spm") : depth0)) != null ? lookupProperty(stack1,"isChecked") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":14},"end":{"line":76,"column":60}}})) != null ? stack1 : "")
    + "\n              "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"spm") : depth0)) != null ? lookupProperty(stack1,"isDisabled") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":14},"end":{"line":77,"column":62}}})) != null ? stack1 : "")
    + "/>\n            <span style=\"color:"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"spm") : depth0)) != null ? lookupProperty(stack1,"color") : stack1), depth0))
    + "\" data-i18n=\"ddm.labels.source"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"spm") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\"></span>\n          </label>\n          <span class=\"prediction-info-button-root\" data-i18n=\"[title]ddm.help\"></span>\n        </div>\n\n        <div class=\"checkbox row\">\n          <label "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"hth") : depth0)) != null ? lookupProperty(stack1,"isDisabled") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":84,"column":17},"end":{"line":84,"column":72}}})) != null ? stack1 : "")
    + ">\n            <input type=\"checkbox\" name=\"source"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"hth") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"hth") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\"\n              "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"hth") : depth0)) != null ? lookupProperty(stack1,"isChecked") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":14},"end":{"line":86,"column":60}}})) != null ? stack1 : "")
    + "\n              "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"hth") : depth0)) != null ? lookupProperty(stack1,"isDisabled") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":14},"end":{"line":87,"column":62}}})) != null ? stack1 : "")
    + "/>\n            <span style=\"color:"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"hth") : depth0)) != null ? lookupProperty(stack1,"color") : stack1), depth0))
    + "\" data-i18n=\"ddm.labels.source"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"hth") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\"></span>\n          </label>\n          <span class=\"hth-info-button-root\" data-i18n=\"[title]ddm.help\"></span>\n        </div>\n\n        <div class=\"checkbox row\">\n          <label>\n            <input type=\"checkbox\" name=\"sourcenpma\" value=\"npma\"\n                   "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"npma") : depth0)) != null ? lookupProperty(stack1,"isChecked") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":19},"end":{"line":96,"column":65}}})) != null ? stack1 : "")
    + "/>\n            <span id=\"sourcenpma\" data-i18n=\"ddm.labels.sourcenpma\"></span>\n          </label>\n          <span class=\"npbma-info-button-root\" data-i18n=\"[title]ddm.help\"></span>\n        </div>\n      </div>\n\n      <div class=\"separation col-lg-12 col-mg-12 col-sm-12 col-xs-12\"></div>\n\n      <div class=\"row\">\n        <div class=\"col-lg-12\">\n          <label for=\"source\" class=\"col-sm-12 control-label ddm-graph-title\"\n                 data-i18n=\"ddm.labels.surgeLegendsTitle\"></label>\n        </div>\n      </div>\n\n      <div class=\"col-lg-12 col-mg-12 col-sm-12 col-xs-12\">\n\n        <div class=\"checkbox row\">\n          <label data-i18n=\"[title]ddm.labels.surgeTitle\"\n                 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"surcote") : depth0)) != null ? lookupProperty(stack1,"isDisabled") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":116,"column":17},"end":{"line":116,"column":76}}})) != null ? stack1 : "")
    + ">\n            <input type=\"checkbox\" name=\"source"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"surcote") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"surcote") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\"\n              "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"surcote") : depth0)) != null ? lookupProperty(stack1,"isChecked") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":118,"column":14},"end":{"line":118,"column":64}}})) != null ? stack1 : "")
    + "\n              "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"surcote") : depth0)) != null ? lookupProperty(stack1,"isDisabled") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":119,"column":14},"end":{"line":119,"column":66}}})) != null ? stack1 : "")
    + "/>\n            <span style=\"color:"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"surcote") : depth0)) != null ? lookupProperty(stack1,"color") : stack1), depth0))
    + "\"\n                  data-i18n=\"ddm.labels.source"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"surcote") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\"></span>\n          </label>\n          <span class=\"surcote-info-button-root\" data-i18n=\"[title]ddm.help\"></span>\n        </div>\n\n\n        <div class=\"checkbox row\">\n          <span class=\"surcoteCalc-info-button-root\" data-i18n=\"[title]ddm.help\"></span>\n          <label "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"surcoteCalc") : depth0)) != null ? lookupProperty(stack1,"isDisabled") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":129,"column":17},"end":{"line":129,"column":80}}})) != null ? stack1 : "")
    + ">\n            <input type=\"checkbox\" name=\"source"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"surcoteCalc") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\"\n                   value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"surcoteCalc") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\"\n              "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"surcoteCalc") : depth0)) != null ? lookupProperty(stack1,"isChecked") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":14},"end":{"line":132,"column":68}}})) != null ? stack1 : "")
    + "\n              "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"surcoteCalc") : depth0)) != null ? lookupProperty(stack1,"isDisabled") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":133,"column":14},"end":{"line":133,"column":70}}})) != null ? stack1 : "")
    + "/>\n            <span style=\"color:"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"surcoteCalc") : depth0)) != null ? lookupProperty(stack1,"color") : stack1), depth0))
    + "\"\n                  data-i18n=\"ddm.labels.source"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"surcoteCalc") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\"></span>\n          </label>\n        </div>\n\n        <div class=\"reset-parameters-container row\">\n          <button class=\"reset-parameters-btn btn btn-primary\"\n                  data-i18n=\"ddm.dl.resetParams\"></button>\n        </div>\n      </div>\n    </div>\n\n  </div>\n\n</div>\n";
},"useData":true});