const _ = require('underscore');
const $ = require('jquery');
const ShomView = require('../core/shom-view');
const template = require('../../template/choose-coord-list.hbs');

module.exports = ShomView.build({

  coordsSystems: ['wgs84.dec', 'wgs84.sexa', 'wgs84.dms', 'mercat', 'l93'],

  initialize(options = {}) {
    this._router = options.router || window.ROUTER;
    this.gisView = options.gisView || window.GISVIEW;
    this.eventsBus = options.eventsBus || window.EVENTBUS;
    this.listenTo(this.eventsBus, 'coordSyst:change', this.updateCoordSyst);
  },

  render() {
    const currentSyst = this.gisView.coordSyst || 'wgs84.sexa';
    const coordsInfo = _.map(this.coordsSystems, sys => ({ name: sys, selected: sys === currentSyst }));

    this.$el.html(template({ systems: coordsInfo }));
    return this;
  },

  updateCoordSyst(event) {
    $('#chooseCoord').val(event.coordSyst);
  }
});
