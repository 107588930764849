import AtlasHelper from '../utils/atlas/atlas-utils';
import { NCWMS_LAYERTYPE_OCEANO, NCWMS_LAYERTYPE_ARCHIVE, NCWMS_LAYERTYPE_ATLAS } from '../utils/constants';

const $ = require('jquery');
const _ = require('underscore');
const CapabilitiesFetcher = require('../utils/gis/capabilities-fetcher');
const JqHelper = require('../utils/jquery-helpers.js');
const OceanoNcwmsUtils = require('../utils/oceano/oceano-ncwms');
const OceanoLayerUtils = require('../utils/oceano/oceano-layer');
const CoordinatesHelper = require('../utils/gis/coordinates-helper.js');

const NCWMS = {
  _url: {},
  // Static property To store deliveriesJSON requested
  _deliveriesJSON: null,
  _deliveriesAtlasJSON: null,
  url() {
    return '';
  },

  asGETParams(data) {
    return _.chain(data)
      .pairs()
      .map(pair => pair.join('='))
      .value()
      .join('&');
  },

  getTransectUrl(linestring, olLayer, options = {}) {
    let url = this._getUrl();
    const layerParams = olLayer.getSource().getParams();
    const preParams = this._createParamsForAnimationOrNot(layerParams, options);
    if (options.layerModel) {
      const paletteParams = this._createPaletteParamsForTransectUrl(layerParams, options);
      preParams.MINMAXSCALE = paletteParams.MINMAXSCALE;
      preParams.BELOWMINCOLOR = paletteParams.BELOWMINCOLOR;
      preParams.ABOVEMAXCOLOR = paletteParams.ABOVEMAXCOLOR;
      const coeff = options.layerModel.get('currentCoeff');
      if (coeff) {
        preParams.COEFF = coeff;
      }
    }
    preParams.REQUEST = 'GetShomTransect';
    preParams.LAYERS = OceanoNcwmsUtils.getIdWithNcwmsValue(layerParams.LAYERS);
    preParams.CRS = layerParams.CRS || 'EPSG:3857';
    preParams.ELEVATION = layerParams.ELEVATION || 0;
    preParams.LINESTRING = linestring;
    preParams.FORMAT = 'image/png';
    preParams.LOGSCALE = false;
    preParams.VERSION = '1.1.1';
    preParams.PALETTE = layerParams.STYLES.split('/')[1];

    if (options.sectionElevation) {
      preParams['SECTION-ELEVATION'] = options.sectionElevation.join('/');
    }
    if (options.unit) {
      preParams.UNIT = options.unit;
    }
    const currentLang = $.i18n.lng();
    preParams.LANG = `${currentLang}_${currentLang.toUpperCase()}`;
    const params = this.asGETParams(preParams);
    url += `?${params}`;

    return url;
  },

  /**
     *
     * @param {Object} layerParams
     * @param {Object} options
     * @returns {{TIME: *, ANIMATION: boolean|undefined, ?FRAMERATE: number|undefined}}
     * @private
     */
  _createParamsForAnimationOrNot(layerParams, options) {
    // Check if this is an animation
    if (options.time && options.time.length > 1) {
      return {
        ANIMATION: true,
        TIME: options.time.join('/'),
        FRAMERATE: options.framerate || 2 // default framerate is arbitrary set to 2 if not provided or equals to 0
      };
    }
    return { TIME: layerParams.TIME };
  },

  /**
     *
     * @param {Object} layerParams
     * @param {Object} options
     * @returns {{MINMAXSCALE: string, BELOWMINCOLOR: *, ABOVEMAXCOLOR: *}}
     * @private
     */
  _createPaletteParamsForTransectUrl(layerParams, options) {
    const { isAuto } = options.layerModel.get('selectedPalette');
    const palette = OceanoLayerUtils.getPaletteFromName(options.layerModel, layerParams.STYLES);
    if (palette && !isAuto) {
      return {
        MINMAXSCALE: `${palette.min}/${palette.max}`,
        BELOWMINCOLOR: palette.belowMinColor,
        ABOVEMAXCOLOR: palette.aboveMaxColor
      };
    }
    // this is an auto palette case
    let [min, max] = layerParams.COLORSCALERANGE.split(',').map(parseFloat);
    const unitDetails = OceanoLayerUtils.getSelectedUnitFromLayer(options.layerModel);
    if (unitDetails.conversion && unitDetails.conversion.offset && unitDetails.conversion.rate) {
      min = OceanoNcwmsUtils.convertUnit(min, unitDetails.conversion.offset, unitDetails.conversion.rate);
      max = OceanoNcwmsUtils.convertUnit(max, unitDetails.conversion.offset, unitDetails.conversion.rate);
    }
    return {
      MINMAXSCALE: `${min}/${max}`,
      BELOWMINCOLOR: layerParams.BELOWMINCOLOR,
      ABOVEMAXCOLOR: layerParams.ABOVEMAXCOLOR
    };
  },

  _getUrl() {
    if (NCWMS_LAYERTYPE_ARCHIVE === this.ncwmsLayerType) {
      return this.config.oceano.ncwms_archive;
    }

    if (NCWMS_LAYERTYPE_ATLAS === this.ncwmsLayerType) {
      return this.config.atlas.ncwms_endpoint;
    }
    return this.config.oceano.ncwms_endpoint;
  },

  getMinMaxUrl(olRegLayer, time, depth, bbox) {
    let url = this._getUrl();
    bbox = CoordinatesHelper.convertMercatorBboxToLonLat(bbox);
    const bboxParam = `${bbox[0]},${bbox[1]},${
      bbox[2]},${bbox[3]}`;
    const ncwmsLayer = olRegLayer;
    const layerParams = ncwmsLayer.getSource().getParams();
    const preParams = {};
    preParams.REQUEST = 'GetMetadata';
    preParams.LAYERS = layerParams.LAYERS;
    preParams.SRS = 'epsg:4326';
    preParams.ELEVATION = depth || layerParams.ELEVATION;
    preParams.TIME = time || layerParams.TIME;
    preParams.item = 'minmax';
    preParams.BBOX = bboxParam;
    preParams.HEIGHT = 256;
    preParams.WIDTH = 256;
    preParams.VERSION = '1.1.1';
    preParams.STYLES = layerParams.STYLES ? layerParams.STYLES.split('/')[0] : '';

    if (layerParams.COEFF) {
      preParams.COEFF = layerParams.COEFF;
    }

    const params = this.asGETParams(preParams);
    url += `?${params}`;
    return url;
  },

  _getMinMaxRequest(url) {
    const def = $.Deferred();
    const noDataError = 'No data in this area';
    $.get(url)
      .then(data => def.resolve([data]))
      .fail(err => {
        // if the region is displayed but no data is visible on the screen
        if (err.status === 400 && err.responseText.indexOf(noDataError) !== -1) {
          // we return the value null encapsulated in a array because of the allAjax mechanism, that takes the
          // first item of the array.
          def.resolve([null]);
        } else {
          def.reject(err);
        }
      });
    return def;
  },

  getMinMaxLayers(layers, bbox) {
    const def = $.Deferred();
    const urls = [];
    for (let i = 0; i < layers.length; ++i) {
      const layer = layers[i];
      const intersectBbox = CoordinatesHelper.intersectBbox(bbox, layer.get('regExtent'));
      if (intersectBbox) {
        const ncwmsLayer = layer.getLayers().item(1);
        urls.push(this.getMinMaxUrl(ncwmsLayer, null, null, intersectBbox));
      }
    }
    if (urls.length === 1) {
      $.get(urls[0])
        .then(minMax => {
          def.resolve(OceanoNcwmsUtils.getMinMax([minMax]));
        })
        .fail(() => {
          def.reject();
        });
    } else {
      JqHelper.Deferred.allAjax(urls.map(this._getMinMaxRequest))
        .then(dataArr => {
          dataArr = _.without(dataArr, null);
          if (dataArr.length > 0) {
            def.resolve(OceanoNcwmsUtils.getMinMax(dataArr));
          } else {
            def.reject();
          }
        })
        .fail(() => {
          def.reject();
        });
    }
    return def;
  },

  getMinMaxLayersMoments(layers, dates, bbox) {
    const def = $.Deferred();
    const urls = [];
    for (let layerIdx = 0; layerIdx < layers.length; layerIdx++) {
      const layer = layers[layerIdx];
      const intersectBbox = CoordinatesHelper.intersectBbox(bbox, layer.get('regExtent'));
      if (intersectBbox) {
        const ncwmsLayer = layer.getLayers().item(1);
        for (let dateIdx = 0; dateIdx < dates.length; dateIdx++) {
          urls.push(
            this.getMinMaxUrl(ncwmsLayer, dates[dateIdx].toISOString(), null, intersectBbox)
          );
        }
      }
    }
    if (urls.length === 1) {
      $.get(urls[0]).then(minMax => {
        const res = [minMax];
        def.resolve(res);
      }).fail(() => {
        def.reject();
      });
    } else {
      JqHelper.Deferred.allAjax(urls.map(this._getMinMaxRequest))
        .then(dataArr => {
          dataArr = _.without(dataArr, null);
          if (dataArr.length > 0) {
            def.resolve(dataArr);
          } else {
            def.reject();
          }
        })
        .fail(() => {
          def.reject();
        });
    }
    return def;
  },

  getMaximumDepth(x, y, olLayer, mapSize, bbox) {
    let url = this._getUrl();
    const layerParams = olLayer.getSource().getParams();
    const preParams = {};
    preParams.REQUEST = 'GetMaximumDepth';
    const layerName = OceanoNcwmsUtils.getIdWithNcwmsValue(layerParams.LAYERS);
    preParams.LAYERS = layerName;
    preParams.QUERY_LAYERS = layerName;
    preParams.SRS = 'EPSG:3857';
    preParams.TIME = layerParams.TIME;
    preParams.VERSION = '1.1.1';
    preParams.BBOX = bbox;
    preParams.X = x;
    preParams.Y = y;
    preParams.WIDTH = mapSize[0];
    preParams.HEIGHT = mapSize[1];

    url += `?${this.asGETParams(preParams)}`;

    const promise = $.Deferred();
    $.get(url)
      .then(result => {
        promise.resolve(result);
      })
      .fail(() => {
        promise.reject();
      });
    return promise;
  },
  /**
     * Construct url to get profile url for specific parameters
     * @param {number} x
     * @param {number} y
     * @param {ol.Layer} olLayer
     * @param {{mapSize: number[], bbox: number[], unit: string, minDepth: number, maxDepth: number}} params
     * @param {?{time: ?array, framerate: ?number}} options
     * @returns {string} url
     */
  getProfileUrl(x, y, olLayer, params, options = {}) {
    const {
      mapSize, bbox, unit, minDepth, maxDepth
    } = params;
    const url = this._getUrl();
    const layerParams = olLayer.getSource().getParams();
    const preParams = this._createParamsForAnimationOrNot(layerParams, options);
    preParams.REQUEST = 'GetShomVerticalProfile';
    const layerName = OceanoNcwmsUtils.getIdWithNcwmsValue(layerParams.LAYERS);
    preParams.LAYERS = layerName;
    preParams.QUERY_LAYERS = layerName;
    preParams.SRS = 'EPSG:3857';
    preParams.VERSION = '1.1.1';
    preParams.INFO_FORMAT = 'image/png';
    preParams.BBOX = bbox;
    preParams.X = x;
    preParams.Y = y;
    preParams.WIDTH = mapSize[0];
    preParams.HEIGHT = mapSize[1];
    preParams.STYLES = 'default/default';
    preParams['SECTION-ELEVATION'] = `${minDepth}/${maxDepth}`;

    if (unit) {
      preParams.UNIT = unit;
    }

    const currentLang = $.i18n.lng();
    preParams.LANG = `${currentLang}_${currentLang.toUpperCase()}`;

    return `${url}?${this.asGETParams(preParams)}`;
  },

  getFeatureInfo(olLayer, mapSize, bbox, coord) {
    let url = this._getUrl();
    const layerParams = olLayer.getSource().getParams();
    const preParams = {};
    preParams.REQUEST = 'GetFeatureInfo';
    preParams.SERVICE = 'WMS';
    const layer = OceanoNcwmsUtils.getIdWithNcwmsValue(layerParams.LAYERS);
    preParams.LAYERS = layer;
    preParams.QUERY_LAYERS = layer;
    preParams.SRS = 'EPSG:4326';
    preParams.ELEVATION = layerParams.ELEVATION;
    preParams.TIME = layerParams.TIME;
    if (layerParams.COEFF) {
      preParams.COEFF = layerParams.COEFF;
    }

    const IJ = CoordinatesHelper.convertIJMercatToLonLat(bbox, mapSize, coord);
    preParams.BBOX = CoordinatesHelper.convertMercatorBboxToLonLat(bbox);
    preParams.X = IJ[0];
    preParams.Y = IJ[1];
    preParams.FORMAT = 'image/png';
    preParams.INFO_FORMAT = 'text/xml';
    preParams.WIDTH = mapSize[0];
    preParams.HEIGHT = mapSize[1];
    preParams.VERSION = '1.1.1';
    preParams.LANG = this.LANG;

    preParams.STYLES = 'default/default';

    const params = this.asGETParams(preParams);
    url += `?${params}`;

    const promise = $.Deferred();
    $.get(url)
      .then(xml => {
        if (!xml) {
          return promise.reject();
        }
        let value = '';
        $(xml).find('value').each(function () { // keep function() in order to have $(this) reference 'value' item
          value = $(this).text();
        });
        promise.resolve(value);
      })
      .fail(e => {
        promise.reject(e);
      });

    return promise;
  },

  /**
     * Get magnitude and direction of layer for a date and a coeff
     * @param data catalog layer
     * @param coeff coefficient used to retrieve infos
     * @returns a promise
     */
  getMagnitudeDirection(data, coeff) {
    const layerId = data.olLayer.get('identifier');
    const { coord } = data;

    let url = this._getUrl();
    const preParams = {};
    preParams.REQUEST = 'GetShomSeriesLonLat';
    preParams.VERSION = '1.1.1';
    preParams.VERTICAL_SECTION = false;
    const params = this.asGETParams(preParams);
    url += `?${params}`;

    const bodyReq = {};
    bodyReq.LAYER = layerId;
    bodyReq.SRS = 'EPSG:4326';
    bodyReq.STYLES = 'default/default';
    bodyReq.TIME = this.config.atlas.ncwms_timerange;
    bodyReq.TIME_STEP = this.config.atlas.ncwms_timestep;
    bodyReq.DELTA = this.config.atlas.ncwms_delta;

    const coordLonLat = CoordinatesHelper.convertMercatToLonLatModulo(coord);
    [bodyReq.LON, bodyReq.LAT] = coordLonLat;
    bodyReq.IS_ATLAS = AtlasHelper.isAtlasLayer(data.catalogLayer);
    bodyReq.COEFF = +coeff;
    bodyReq.IDENTIFIER = `courant_2d_mag_dir_${bodyReq.COEFF}_${bodyReq.LAYER.split('/')[0]}`;

    const promise = $.Deferred();

    $.post({
      url,
      data: JSON.stringify([bodyReq]),
      contentType: 'application/json'
    })
      .then(data => {
        const formattedData = this._prepareDataMagnitudeDirection(data);
        promise.resolve(formattedData);
      })
      .fail(err => promise.reject(err));

    return promise;
  },

  /**
     * Build a map with a time as key and with an object as value. This object has two properties: magnitude and direction
     * @param jsonData the json result of POST request
     * @returns {Map<any, any>}
     * @private
     */
  _prepareDataMagnitudeDirection(jsonData) {
    const mapMagnitude = new Map();
    const mapDirection = new Map();
    const mapResult = new Map();
    if (!jsonData.length) {
      return mapResult;
    }

    const dataObj = jsonData[0];
    // eslint-disable-next-line no-prototype-builtins
    const items = dataObj.hasOwnProperty('items') ? dataObj.items : [];

    if (!items.length) {
      return mapResult;
    }

    items.forEach(item => {
      // eslint-disable-next-line no-prototype-builtins
      if (!item.hasOwnProperty('parameterId') || !item.hasOwnProperty('data')) {
        return;
      }

      const currParameterId = item.parameterId.toLowerCase();
      const currDataArray = item.data;

      if (currParameterId !== 'u:v-mag' && currParameterId !== 'u:v-dir') {
        return;
      }

      currDataArray.forEach(dateValueObject => {
        // eslint-disable-next-line no-prototype-builtins
        if (!dateValueObject.hasOwnProperty('date') || !dateValueObject.hasOwnProperty('value')) {
          return;
        }

        const currDate = dateValueObject.date;
        const currValue = dateValueObject.value;

        if (currParameterId === 'u:v-mag') {
          mapMagnitude.set(currDate, currValue);
        } else if (currParameterId === 'u:v-dir') {
          mapDirection.set(currDate, currValue);
        }
      });
    });

    // we should have 49 values, the 0:00 value will match to index 24
    const nbValues = mapMagnitude.size;
    const idxMiddle = Math.trunc(nbValues / 2);
    let idxCpt = 0;
    mapMagnitude.forEach((value, key, map) => {
      if (mapDirection.has(key)) {
        const valueInMinutes = (idxCpt - idxMiddle) * 15;
        const sign = (valueInMinutes === 0) ? '' : (valueInMinutes < 0 ? '-' : '+');
        const hours = Math.floor(Math.abs(valueInMinutes) / 60);
        const minutes = Math.abs(valueInMinutes) % 60;
        const newKey = `${sign}${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
        let dir = mapDirection.get(key);
        if (dir < 0) {
          dir += 360;
        }
        mapResult.set(newKey, { mag: value, dir });
        idxCpt += 1;
      }
    });
    return mapResult;
  },

  getLayersFromCapabilities() {
    const promise = $.Deferred();
    const url = NCWMS_LAYERTYPE_ATLAS === this.ncwmsLayerType ? this.config.atlas.ncwms_endpoint : this.config.oceano.ncwms_endpoint;
    const serviceName = NCWMS_LAYERTYPE_ATLAS === this.ncwmsLayerType ? this.config.atlas.ncwms_service : this.config.oceano.ncwms_service;
    this.capabilitiesHelper.getLayersFromCapabilities(serviceName, url, null, true, false, { ncwmsLayerType: this.ncwmsLayerType })
      .always(layers => {
        if (!layers || layers.length === 0) {
          promise.reject();
        } else {
          promise.resolve(layers);
        }
      });
    return promise;
  },

  getDeliveriesJSON(deliveryType = '') {
    const isAtlas = deliveryType === 'atlas';
    if (isAtlas && !NCWMS.deliveriesAtlasJSON) {
      const urls = [];
      for (const i in this.config.atlas.delivery_url) {
        if (Object.prototype.hasOwnProperty.call(this.config.atlas.delivery_url, i)) {
          const url = this.config.atlas.delivery_url[i];
          urls.push(url);
        }
      }
      NCWMS.deliveriesAtlasJSON = JqHelper.Deferred.all(urls.map($.getJSON));
    }

    if (!isAtlas && !NCWMS.deliveriesJSON) {
      const urls = [];
      for (const i in this.config.oceano.delivery_url) {
        if (Object.prototype.hasOwnProperty.call(this.config.oceano.delivery_url, i)) {
          const url = this.config.oceano.delivery_url[i];
          urls.push(url);
        }
      }
      NCWMS.deliveriesJSON = JqHelper.Deferred.allAjax(urls.map($.getJSON));
    }
    return isAtlas ? NCWMS.deliveriesAtlasJSON : NCWMS.deliveriesJSON;
  },

  getPaletteUrl(palette, horizontal) {
    let url = this._getUrl();
    const preParams = {};
    const style = palette.autoName || palette.name;
    const paletteName = style.split('/')[1];

    preParams.REQUEST = 'GetLegendGraphic';
    preParams.LANG = this.LANG;
    if (palette.numColorBands) {
      preParams.NUMCOLORBANDS = palette.numColorBands;
    }
    preParams.PALETTE = paletteName;
    preParams.COLORBARONLY = true;
    if (horizontal === true) {
      preParams.VERTICAL = false;
    } else {
      preParams.VERTICAL = true;
    }

    const params = this.asGETParams(preParams);
    url += `?${params}`;
    return url;
  },

  getAnimationExportUrl(params) {
    const ncwms_endpoint = this._getUrl();
    const GETParamsTransformer = this.asGETParams;
    const preParams = {};

    // Animation related params
    preParams.SERVICE = 'WMS';
    preParams.REQUEST = 'GetShomLayersAnimation';
    preParams.ANIMATION = 'true';
    preParams.FORMAT = 'image/gif';
    preParams.VERSION = '1.1.1';
    preParams.SRS = 'EPSG:4326';
    preParams.BBOX = CoordinatesHelper.convertMercatorBboxToLonLat(params.bbox);
    preParams.HEIGHT = params.mapDimensions.height;
    preParams.WIDTH = params.mapDimensions.width;
    preParams.FRAMERATE = params.framerate;
    preParams.TIME = params.time;

    // layer related params
    const layergroupModel = params.layergroup;
    const displayedLayers = layergroupModel.get('olLayer').getLayers().getArray().filter(layer => layer.getVisible());
    preParams.LAYERS = displayedLayers.map(layer => layer.get('identifier')).join(',');
    preParams.ELEVATION = layergroupModel.get('selectedElevation') ? layergroupModel.get('selectedElevation') : 0;
    if (layergroupModel.get('currentCoeff')) {
      preParams.COEFF = layergroupModel.get('currentCoeff');
    }

    // palette related params
    const promise = $.Deferred();
    this._getPaletteAnimationParams(layergroupModel, params.animationMoments, params.bbox)
      .then(palette => {
        preParams.STYLES = Array.apply(null, Array(displayedLayers.length)).map(String.prototype.valueOf, palette.STYLES).join(',');
        preParams.COLORSCALERANGE = palette.COLORSCALERANGE;
        preParams.ABOVEMAXCOLOR = palette.ABOVEMAXCOLOR;
        preParams.BELOWMINCOLOR = palette.BELOWMINCOLOR;
        preParams.NUMCOLORBANDS = palette.NUMCOLORBANDS;
        preParams.NUMCONTOURS = palette.NUMCONTOURS;

        const downloadParams = GETParamsTransformer(preParams);
        const downloadUrl = `${ncwms_endpoint}?${downloadParams}`;
        promise.resolve(downloadUrl);
      })

      .fail(err => {
        promise.reject(err);
      });

    return promise;
  },

  _getPaletteAnimationParams(layergroupModel, animationMoments, bbox) {
    const paletteId = layergroupModel.get('selectedPalette');
    const displayedLayers = layergroupModel.get('olLayer').getLayers().getArray().filter(layer => layer.getVisible());

    const promise = $.Deferred();
    if (!paletteId.isAuto) { // ---- regular palette
      let palette = OceanoLayerUtils.getPaletteFromName(layergroupModel, paletteId.name);
      palette = OceanoLayerUtils.getNCWMSPaletteParams(palette, palette);
      promise.resolve(palette);
    } else {
      // -------------------- auto palette (needs to be recomputed over the animation frames).
      const unitLayer = OceanoLayerUtils.getNCWMSUnit(layergroupModel);
      const conversionLayer = OceanoLayerUtils.getNCWMSConversion(layergroupModel);
      const paletteAutoParams = OceanoLayerUtils.getPaletteAutoParams(layergroupModel, paletteId.name);
      const options = OceanoLayerUtils.getNCWMSOptions(layergroupModel);

      this.getMinMaxLayersMoments(displayedLayers, animationMoments, bbox)
        .then(datas => OceanoNcwmsUtils.getMinMax(datas)).then(minMax => {
          const extremeAnimationValues = OceanoLayerUtils.roundMinMaxValue(
            minMax.min,
            minMax.max,
            options
          );
          const palette = OceanoLayerUtils.getPaletteFromName(layergroupModel, paletteId.name);
          let paletteAuto = {
            name: paletteId.name,
            autoName: paletteAutoParams.name,
            min: extremeAnimationValues.min,
            max: extremeAnimationValues.max,
            unit: unitLayer,
            conversion: conversionLayer,
            options,
            numColorBands: paletteAutoParams.numColorBands,
            isAuto: true
          };
          paletteAuto = OceanoLayerUtils.getNCWMSPaletteParams(paletteAuto, palette);
          promise.resolve(paletteAuto);
        }).fail(err => {
          promise.reject(err);
        });
    }
    return promise;
  },

  _setStyleParams(preParams, layerParams) {
    if (layerParams.NUMCOLORBANDS) {
      preParams.NUMCOLORBANDS = layerParams.NUMCOLORBANDS;
    } else {
      preParams.NUMCOLORBANDS = 250;
    }

    if (layerParams.STYLES) {
      preParams.STYLES = layerParams.STYLES;
    } else {
      preParams.STYLES = 'rainbow';
    }

    if (layerParams.ABOVEMAXCOLOR) {
      preParams.ABOVEMAXCOLOR = layerParams.ABOVEMAXCOLOR;
    }

    if (layerParams.BELOWMINCOLOR) {
      preParams.BELOWMINCOLOR = layerParams.BELOWMINCOLOR;
    }

    if (layerParams.NUMCONTOURS) {
      preParams.NUMCONTOURS = layerParams.NUMCONTOURS;
    }

    if (layerParams.COLORSCALERANGE) {
      preParams.COLORSCALERANGE = layerParams.COLORSCALERANGE;
    }
    return preParams;
  }
};

module.exports = (options = {}) => {
  const defer = $.Deferred();
  const config = options.config || window.CONFIG;
  const LANG = window.portalLang.toUpperCase();
  const capabilitiesHelper = options.capabilitiesHelper || new CapabilitiesFetcher({ config });
  const ncwmsLayerType = options.ncwmsLayerType || NCWMS_LAYERTYPE_OCEANO;

  const service = Object.create(NCWMS);
  service.config = config;
  service.LANG = LANG;
  service.capabilitiesHelper = capabilitiesHelper;
  service.ncwmsLayerType = ncwmsLayerType;
  defer.resolve(service);

  return defer;
};
