const ShomView = require('../../../core/shom-view');
const template = require('../../../../template/drawing/discuss/discuss.hbs');

const SessionView = require('./session.view');
const DiscussActionToolbarView = require('./discuss-action-toolbar.view');

const DiscussView = ShomView.build({
  initialize(options) {
    this._map = this.model.get('map');
    this._user = options.user || window.ROUTER.user;
    this.listenTo(this._map, 'sync', this.render);
    this.listenTo(this.model, 'change:collaborative', this.render);
  },

  render() {
    this.$el.html(template({
      userLoggedIn: this._user.get('isLoggedIn'),
      newMap: this._map.isNew(),
      model: this.model.toJSON()
    }));
    if (!this._sessionView) {
      this._sessionView = new SessionView({ model: this.model });
    }
    if (!this._actionToolbarView) {
      this._actionToolbarView = new DiscussActionToolbarView({ model: this.model });
    }
    this._sessionView.setElement(this.$('#discuss-session-root'));
    this._actionToolbarView.setElement(this.$('#discuss-action-toolbar-root'));
    this._sessionView.render();
    this._actionToolbarView.render();
  }
});

module.exports = DiscussView;
