const $ = require('jquery');
const Dialog = require('bootstrap-dialog');
const ShomView = require('../../core/shom-view');
const ValidatorFields = require('../../data/forms/external-kml');
const FileHelper = require('../../utils/file');
const template = require('../../../template/catalog/external-kml.hbs');
const kmlHelper = require('../../utils/gis/kml-helper');

module.exports = ShomView.build({

  events: {
    'change #external-kml-source': '_updateSourceContent'
  },

  initialize(options) {
    const optionsToUse = options || {};
    this._fileHelper = optionsToUse.fileHelper || new FileHelper();
    this._importKmlToDrawingLayer = (optionsToUse.kmlToDrawingLayer === true);
    this.collection = this.collection || window.LAYERS;
    this._modalView = optionsToUse.modalView || window.MODALVIEW;
    this._gisView = optionsToUse.gisView || window.GISVIEW;
  },

  render() {
    this.$el.html(template({
      importKmlToDrawingLayer: this._importKmlToDrawingLayer
    }));
    this.$sourceSelect = this.$el.find('#external-kml-source');

    this._updateSourceContent();

    return this;
  },

  getFormValidatorFields() {
    return ValidatorFields();
  },

  _onFormSubmit(event) {
    if (event.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
    }
    const source = this.$sourceSelect.val();
    // Import from local file
    if (source === 'local') {
      this._importFromLocal();
    } else if (source === 'remote' && !this._importKmlToDrawingLayer) {
      this._importFromRemote();
    } else {
      Dialog.show({
        type: Dialog.TYPE_DANGER,
        title: $.i18n.t('error.application.unknown.title'),
        message: $.i18n.t('error.application.unknown.message')
      });
    }
  },

  _importFromLocal() {
    const name = this.$el.find("input[name='name']").val();
    const file = this.$el.find("input[name='file']").prop('files')[0];
    if (file) {
      return this._fileHelper.readFile(file).done(kml => {
        if (this._importKmlToDrawingLayer) {
          // Import to drawing layer
          this._gisView.importKmlData(kml);
        } else {
          // Import to external layer
          const layer = kmlHelper.createKmlLayer(kmlHelper.createVectorLayer(kmlHelper.kmlToFeatures(kml)), name);
          this.collection.add(layer);
          layer.set('includedInMap', true);
        }
        this._modalView.hide();
      }).fail(() => {
        Dialog.show({
          type: Dialog.TYPE_DANGER,
          title: $.i18n.t('error.application.unknown.title'),
          message: $.i18n.t('error.application.unknown.message')
        });
      });
    } else {
      return $.Deferred().reject();
    }
  },

  _importFromRemote() {
    const name = this.$el.find("input[name='name']").val();
    const url = this.$el.find("input[name='url']").val();
    return $.Deferred().resolve(() => {
      const layer = kmlHelper.createKmlLayer(kmlHelper.createVectorLayerFromUrl(url), name);
      this.collection.add(layer);
      layer.set('includedInMap', true);
    });
  },

  _updateSourceContent() {
    const source = this.$sourceSelect.val();
    this.$el.find(`.kml-source:not(.kml-${source}-source)`).hide();
    this.$el.find(`.kml-source:not(.kml-${source}-source) input`).attr('disabled', 'disabled');
    this.$el.find(`.kml-source.kml-${source}-source`).show();
    this.$el.find(`.kml-source.kml-${source}-source input`).removeAttr('disabled');

    if (this._importKmlToDrawingLayer) {
      this.$('#external-kml-name').attr('disabled', 'disabled').closest('.form-group').hide();
    }
  }
});
