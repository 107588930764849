import View from 'ol/View';
import OverviewMap from 'ol/control/OverviewMap';

const $ = require('jquery');
const _ = require('underscore');
const ShomView = require('../core/shom-view');

module.exports = ShomView.build({

  initialize(options = {}) {
    this.gisView = options.gisView || window.GISVIEW;
    this.eventsBus = options.eventsBus || window.EVENTBUS;
    this._config = window.CONFIG || options.config;
    this.listenTo(this.eventsBus, 'change:overView', this.handlerOverView);
    this.map = this.gisView._map;
    this.visible = true;
    this._layer = _.clone(options.layer);

    const mapOverViewOptions = {
      className: 'ol-overviewmap ol-custom-overviewmap',
      layers: [this._layer],
      collapsible: false,
      view: new View({
        projection: this._config.projection
      })
    };

    this.overViewMap = new OverviewMap(mapOverViewOptions);
    this.map.addControl(this.overViewMap);

    this.addOverViewMap();
    this.handlerOverView({ checked: this.gisView.getDisplayOptions().overviewMap });
  },

  handlerOverView(event) {
    if (event.checked === true) {
      this.addOverViewMap();
    } else {
      this.removeOverViewMap();
    }
  },

  addOverViewMap() {
    this.visible = true;
    $('.ol-custom-overviewmap').show();
    this.trigger(this.eventsBus, 'enabled:overView');
  },

  removeOverViewMap() {
    this.visible = false;
    $('.ol-custom-overviewmap').hide();
    this.trigger(this.eventsBus, 'disabled:overView');
  },

  moveOverviewMap(bottomInPx) {
    $('.ol-custom-overviewmap').css('bottom', bottomInPx);
  }
});
