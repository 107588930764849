import WMC from '../../mixins/openlayers/wmc';

const _ = require('underscore');
const Reader = require('./context-read');
const Writer = require('./context-write');
const CoordsHelper = require('./coordinates-helper.js');
const CapabilitiesFetcher = require('./capabilities-fetcher');

const ContextHelper = function (options) {
  this._user = options.user;
  this._reader = new Reader({ user: this._user });
  this._writer = new Writer({ user: this._user });
};

ContextHelper.prototype.read = function (doc) {
  return this._reader.read(doc);
};

ContextHelper.prototype.write = function (map, layers) {
  return this._writer.write(map, layers);
};

ContextHelper.prototype.instanciate = function (layerContext, globalContext) {
  const format = new WMC({ shomUser: this._user });
  if (!layerContext.maxExtent && globalContext && globalContext.bounds) {
    layerContext.maxExtent = globalContext.bounds;
  }

  if (globalContext && globalContext.projection === 'EPSG:4326') {
    layerContext.maxExtent = CoordsHelper.convertLonLatBboxToMercator(layerContext.maxExtent);
  }
  return format.getLayerFromContext(layerContext);
};

ContextHelper.prototype.instanciateWMTS = function (layerContext) {
  const capabilitiesFetcher = new CapabilitiesFetcher();

  return capabilitiesFetcher.getLayersFromCapabilities('WMTS', layerContext.url, null, true)
    .then(listLayers => {
      const resLayer = listLayers.filter(layer => layer.getSource().getLayer() === layerContext.name);
      if (resLayer.length >= 1) {
        return resLayer[0];
      }
      return null;
    });
};

/**
 * Convert layer from Web Map Context to layer as if it retrive from a getCap
 * @param {object} contextLayer
 * @param {array} boundingBox
 * @param {string} projection
 * @returns {{queryable: (boolean|string), opaque: boolean, Abstract: *, BoundingBox: array, EX_GeographicBoundingBox: *, Title: *, Name: *}}
 */
ContextHelper.prototype.convertContextLayerToGetCapRawLayer = function (contextLayer, boundingBox, projection = 'CRS:84') {
  const rawLayer = {
    Abstract: contextLayer.abstract,
    BoundingBox: [{
      extent: boundingBox,
      crs: projection
    }],
    EX_GeographicBoundingBox: boundingBox,
    Name: contextLayer.name,
    Title: contextLayer.title,
    queryable: contextLayer.queryable,
    opaque: contextLayer.opacity === 0
  };

  if (contextLayer.dimensions) {
    rawLayer.Dimension = this._createGetCapRawLayerDimensions(contextLayer.dimensions);
  }
  return rawLayer;
};

ContextHelper.prototype._createGetCapRawLayerDimensions = function (contextLayerDimensions) {
  const dimensions = [];
  for (const k in contextLayerDimensions) {
    // eslint-disable-next-line no-prototype-builtins
    if (contextLayerDimensions.hasOwnProperty(k)) {
      const dimension = _.clone(contextLayerDimensions[k]);
      if (dimension.values && Array.isArray(dimension.values)) {
        dimension.values = dimension.values.join(',');
      }
      dimensions.push(dimension);
    }
  }
  return dimensions;
};

module.exports = ContextHelper;
