var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<div class=\"panel-heading\" role=\"tab\" id=\"\">\n    <h4 class=\"panel-title\">\n        <a class=\"accordion-toggle in\" data-toggle=\"collapse\" href=\"#collapse-discuss-tool-bar\" data-i18n=\"discuss.panel.title\"></a>\n    </h4>\n</div>\n<div id=\"collapse-discuss-tool-bar\" class=\"panel-collapse in\">\n    <div class=\"panel-body text-center\">\n        <div id=\"discuss-session-root\"></div>\n        <div id=\"discuss-action-toolbar-root\"></div>\n    </div>\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? lookupProperty(stack1,"collaborative") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":13,"column":7}}})) != null ? stack1 : "");
},"useData":true});