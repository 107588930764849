import Feature from 'ol/format/Feature.js';
import { parse as parseXML } from 'ol/xml';
import { default as XMLFormat } from 'ol/format/XML';

/**
 *  WARNING : Partial implementation of CSWGetRecord format - inspired from Openlayers2
 *  - writer is not implemented
 *  - only useful readers for data.shom.fr are implemented
 */

/**
 * Class: CSWGetRecords.v2_0_2_Shom
 *     A format for creating CSWGetRecords v2_0_2_Shom transactions.
 *     Create a new instance with the
 *     <CSWGetRecords.v2_0_2_Shom> constructor.
 *
 */
const CSWGetRecords = function (options = {}) {
  this.version = options.version || '1.0.0';

  /**
     * Property: namespaces
     * {Object} Mapping of namespace aliases to namespace URIs.
     */
  this.namespaces = {
    csw: 'http://www.opengis.net/cat/csw/2.0.2',
    dc: 'http://purl.org/dc/elements/1.1/',
    dct: 'http://purl.org/dc/terms/',
    gmd: 'http://www.isotc211.org/2005/gmd',
    geonet: 'http://www.fao.org/geonetwork',
    ogc: 'http://www.opengis.net/ogc',
    ows: 'http://www.opengis.net/ows',
    xlink: 'http://www.w3.org/1999/xlink',
    xsi: 'http://www.w3.org/2001/XMLSchema-instance',
    xmlns: 'http://www.w3.org/2000/xmlns/'
  };

  /**
     * Property: defaultPrefix
     * {String} The default prefix.
     */
  this.defaultPrefix = 'csw';

  /**
     * Property: schemaLocation
     * {String} http://www.opengis.net/cat/csw/2.0.2
     *   http://schemas.opengis.net/csw/2.0.2/CSW-discovery.xsd
     */
  this.schemaLocation = 'http://www.opengis.net/cat/csw/2.0.2 http://schemas.opengis.net/csw/2.0.2/CSW-discovery.xsd';

  this.CLASS_NAME = 'CSWGetRecords';
};

// inherits from Feature
CSWGetRecords.__proto__ = Feature;
CSWGetRecords.prototype = Object.create(Feature && Feature.prototype);
CSWGetRecords.prototype.constructor = CSWGetRecords;

CSWGetRecords.prototype.read_csw_GetRecordResponse = function (node, obj) {
  obj.records = [];
  this.readChildNodes(node, obj);
};

CSWGetRecords.prototype.read_csw_RequestId = function (node, obj) {
  obj.RequestId = this.getChildValue(node);
};

CSWGetRecords.prototype.read_csw_SearchStatus = function (node, obj) {
  obj.SearchStatus = {};
};

CSWGetRecords.prototype.read_csw_SearchResults = function (node, obj) {
  this.readChildNodes(node, obj);
  const attrs = node.attributes;
  const SearchResults = {};
  for (let i = 0, len = attrs.length; i < len; ++i) {
    if ((attrs[i].name === 'numberOfRecordsMatched')
            || (attrs[i].name === 'numberOfRecordsReturned')
            || (attrs[i].name === 'nextRecord')) {
      SearchResults[attrs[i].name] = parseInt(attrs[i].nodeValue, 10);
    } else {
      SearchResults[attrs[i].name] = attrs[i].nodeValue;
    }
  }
  obj.SearchResults = SearchResults;
};

CSWGetRecords.prototype.read_csw_SummaryRecord = function (node, obj) {
  const record = { type: 'SummaryRecord' };
  this.readChildNodes(node, record);
  obj.records.push(record);
};
CSWGetRecords.prototype.read_csw_BriefRecord = function (node, obj) {
  const record = { type: 'BriefRecord' };
  this.readChildNodes(node, record);
  obj.records.push(record);
};
CSWGetRecords.prototype.read_csw_DCMIRecord = function (node, obj) {
  const record = { type: 'DCMIRecord' };
  this.readChildNodes(node, record);
  obj.records.push(record);
};
CSWGetRecords.prototype.read_csw_Record = function (node, obj) {
  const record = { type: 'Record' };
  this.readChildNodes(node, record);
  obj.records.push(record);
};

CSWGetRecords.prototype.read_gmd_MD_Metadata = function (node, obj) {
  obj.identifiers = obj.identifiers || [];
  this.readChildNodes(node, obj);
};

CSWGetRecords.prototype.read_gmd_fileIdentifier = function (node, obj) {
  obj.identifiers.push(node.querySelector('CharacterString').textContent);
};

/**
 * Constructor: CSWGetRecords.v2_0_2_Shom
 * A class for parsing and generating CSWGetRecords v2.0.2 transactions.
 *
 * Parameters:
 * options - {Object} Optional object whose properties will be set on the
 *     instance.
 *
 * Valid options properties (documented as class properties):
 * - requestId
 * - resultType
 * - outputFormat
 * - outputSchema
 * - startPosition
 * - maxRecords
 * - DistributedSearch
 * - ResponseHandler
 * - Query
 */
CSWGetRecords.prototype.initialize = function (options) {
  XMLFormat.prototype.initialize.apply(this, [options]);
};

/**
 * APIMethod: read
 * Parse the response from a GetRecords request.
 */
CSWGetRecords.prototype.read = function (data) {
  let dataToRead = data;
  if (typeof dataToRead === 'string') {
    dataToRead = parseXML(dataToRead);
  }
  if (dataToRead && dataToRead.nodeType === 9) {
    dataToRead = dataToRead.documentElement;
  }
  const obj = {};
  this.readChildNodes(dataToRead, obj);
  return obj;
};

CSWGetRecords.prototype.readChildNodes = function (node, obj) {
  const children = node.childNodes;
  let childNode; let processor; let prefix; let
    local;
  for (let i = 0, len = children.length; i < len; ++i) {
    childNode = children[i];
    if (childNode.nodeType === 1) {
      prefix = this.getNamespacePrefix(childNode.namespaceURI);
      local = childNode.nodeName.split(':').pop();
      processor = this[`read_${prefix}_${local}`];
      if (processor) {
        processor.apply(this, [childNode, obj]);
      }
    }
  }
};

CSWGetRecords.prototype.getNamespacePrefix = function (uri) {
  let prefix = null;
  if (uri === null) {
    prefix = this.namespaces[this.defaultPrefix];
  } else {
    for (prefix in this.namespaces) {
      if (this.namespaces[prefix] === uri) {
        break;
      }
    }
  }
  return prefix;
};

module.exports = CSWGetRecords;
