const $ = require('jquery');

const WFSService = {

  getFeaturesCountOnPoint(lon, lat) {
    const promise = $.Deferred();
    let urlGetFeature = this.config.getFeatureUrl + this.config.landsLayer;
    urlGetFeature = urlGetFeature.replace('{{key}}', this.config.key);

    // intersects point with geom
    urlGetFeature += `&CQL_FILTER=INTERSECTS%28the_geom,POINT(${lon} ${lat}))`;

    $.get(urlGetFeature).done(receivedData => {
      promise.resolve(receivedData.totalFeatures);
    })
      .fail(err => {
        promise.reject(err);
      });

    return promise;
  },

  getFeaturePropertiesByCst(cst) {
    const promise = $.Deferred();

    let urlGetFeature = this.config.getFeatureUrl + this.config.spotsLayer;
    urlGetFeature = urlGetFeature.replace('{{key}}', this.config.key);
    urlGetFeature += `&CQL_FILTER=cst='${cst}'`;

    $.get(urlGetFeature).done(receivedData => {
      if (receivedData.features.length === 0) {
        promise.resolve(null);
        return;
      }

      promise.resolve(receivedData.features[0].properties);
    })
      .fail(err => {
        promise.reject(err);
      });

    return promise;
  }
};

module.exports = function (options) {
  const service = Object.create(WFSService);
  service.config = {};
  service.config.getFeatureUrl = options.getFeatureUrl;
  service.config.landsLayer = options.landsLayer;
  service.config.spotsLayer = options.spotsLayer;
  service.config.key = options.key;
  return service;
};
