/**
 * This helper provides a if inline comparing two values.
 *
 * If the two values are strictly equals ('===') return the returnValue
 * argument, '' otherwise.
 *
 * Usage: class='{{ifequalsinline type "details" "active"}}' or class='{{ifequalsinline type "details" "active" "inactive"}}'
 */
module.exports = function (value1, value2, returnValTrue, options) {
  let returnValFalse = '';
  if (arguments.length === 5) { returnValFalse = options; }
  return (value1 === value2) ? returnValTrue : returnValFalse;
};
