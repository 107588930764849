var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog\" role=\"document\">\n    <div class=\"modal-content\">\n        <div class=\"modal-header\">\n            <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">&times;</button>\n            <span id=\"print-modal-title\" class=\"modal-title\" data-i18n=\"drawing.modal.print.title\"></span>\n            <a id=\"help_print\" target=\"_blank\" class=\"anchor-help-link\" data-i18n=\"[title]header.links.help;[aria-label]header.links.help\"><i class=\"far fa-question-circle\"></i></a>\n        </div>\n        <div class=\"modal-body\">\n            <div id=\"map-properties-modal-content\">\n                <div>\n                    <label for=\"map-name-print\" class=\"control-label col-sm-4\"\n                           data-i18n=\"drawing.modal.map-properties.mapName\" required> </label>\n                    <div class=\"col-sm-8\">\n                        <input id=\"map-name-print\" type=\"text\" class=\"form-control map-name\" name=\"map-name\"\n                               value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\"/>\n                    </div>\n                </div>\n                <div>\n                    <label for=\"map-description-print\" class=\"control-label col-sm-4\"\n                           data-i18n=\"drawing.modal.map-properties.description\"></label>\n\n                    <div class=\"col-sm-8\">\n                    <textarea id=\"map-description-print\" class=\"form-control\"\n                              rows=\"3\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "</textarea>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class=\"modal-footer\">\n            <button type=\"button\" class=\"print-map-button btn btn-primary\" data-i18n=\"drawing.modal.print.ok\"></button>\n            <button type=\"button\" class=\"cancel-button btn btn-default\" data-dismiss=\"modal\" data-i18n=\"drawing.modal.print.cancel\"></button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});