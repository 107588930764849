const $ = require('jquery');
const _ = require('underscore');
const ShomView = require('../../../core/shom-view');
const template = require('../../../../template/ddm/ddm-detail/ddm-data-textual.items.hbs');

module.exports = ShomView.build({

  initialize(options) {
    this._config = options.config || window.CONFIG;
    this._tidegaugeObservations = options.tidegaugeObservations || window.tidegaugeObservations;
    this._tidegaugeName = options.tidegaugeName || window.tidegaugeName;
    this._idSensor = options.idSensor || window.idSensor;
  },

  render() {
    const observationDateFormat = $.i18n.lng() === 'en' ? this._config.ddm.textualData.observationDateFormatEn : this._config.ddm.textualData.observationDateFormatFr;
    this.$el.html(template({
      tidegaugeName: this._tidegaugeName,
      idSensor: this._idSensor,
      observationDateFormat,
      tidegaugeObservations: this._tidegaugeObservations
    }));
    return this;
  },

  updateAndRender(tidegaugeObservations) {
    if (tidegaugeObservations.observations) {
      _.each(tidegaugeObservations.observations, observation => {
        observation.value = Number(Math.floor(observation.value * 1000) / 1000);
      });
    }
    this._tidegaugeObservations = tidegaugeObservations;
    this.render();
  }

});
