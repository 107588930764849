import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';

const GisView = require('../gis.view.js');
const discussStyles = require('../../utils/gis/drawing/discuss-styles.js');

GisView.prototype.initCesium = function(cesiumModule) {
  window.Cesium = cesiumModule;
};

GisView.prototype.isGlobeEnabled = function() {
  return this._globeEnabled;
};

/**
 *
 * @param {number} resolution Cesium resolution for rendering globe
 */
GisView.prototype.startGlobe = function(resolution) {
  this._globeEnabled = true;
  if(!this._isGlobeCreated) {
    return this.createGlobe()
      .then(() => {
        this._isGlobeCreated = true;
        this._configureGlobe(resolution);
        this._initGlobeEvents();
        return true;
      });
  }
  this._configureGlobe(resolution);
  this._initGlobeEvents();
  return Promise.resolve();
};

/**
 * @param {number} resolution Cesium resolution for rendering globe
 * @private
 */
GisView.prototype._configureGlobe = function(resolution) {
  this._olCesium.setResolutionScale(resolution);
  this._olCesium.setEnabled(true);
  // Fixing VectorLayer loading VectorSource
  const globalGroup = this._map.getLayerGroup();
  globalGroup.setVisible(true);
};

GisView.prototype.stopGlobe = function() {
  if(this._globeEnabled) {
    this._globeEnabled = false;
    this._olCesium.setEnabled(false);
    this._removeGlobeEvents();
    // reset map with north on top and default zoom
    this._map.getView().setRotation(0);
    this._map.getView().setZoom(this._config.zoom);
  }
};

GisView.prototype.createGlobe = function() {
  this._globeMapPointerMoveEvent = this._globeMapPointerMoveEvent.bind(this);
  this._globePointerMoveEvent = this._globePointerMoveEvent.bind(this);
  this._globeClickEvent = this._globeClickEvent.bind(this);

  return this._loadCesiumModules()
    .then(({cesiumModule, OLCesium, OlcsVectorSynchronizer, OlcsOverlaySynchronizer, OlcsRasterSynchronizer}) => {
      this.initCesium(cesiumModule);
      this._olCesium = new OLCesium({
        map: this._map,
        createSynchronizers: (map, scene) => {
          return [
            new OlcsRasterSynchronizer(map, scene),
            new OlcsVectorSynchronizer(map, scene),
            new OlcsOverlaySynchronizer(map, scene)
          ];
        }
      });

      return true;
    }).catch(error => {
      console.error('Loading error: ', error);
    });
};

GisView.prototype._loadCesiumModules = function() {
  if(this._isGlobeCreated) {
    return Promise.resolve();
  }
  return Promise.all([
    import(/* webpackChunkName: "cesium" */ CESIUM_RELATIVE_BASE_PATH),
    import(/* webpackChunkName: "olcesium" */ 'olcs/core'),
    import(/* webpackChunkName: "olcesium" */ 'olcs/core/OLImageryProvider'),
    import(/* webpackChunkName: "olcesium" */ 'olcs/OLCesium'),
    import(/* webpackChunkName: "olcesium" */ 'olcs/VectorSynchronizer'),
    import(/* webpackChunkName: "olcesium" */ 'olcs/OverlaySynchronizer'),
    import(/* webpackChunkName: "olcesium" */ 'olcs/RasterSynchronizer'),
    import(/* webpackChunkName: "olcesium" */ 'olcs/util')
  ]).then(([
      cesiumModule,
      {default: OlcsCore},
      {default: OLImageryProvider},
      {default: OLCesium},
      {default: OlcsVectorSynchronizer},
      {default: OlcsOverlaySynchronizer},
      {default: OlcsRasterSynchronizer},
      {default: OlcsUtil}
    ]) => {
      return {
        cesiumModule,
        OLCesium,
        OlcsVectorSynchronizer,
        OlcsOverlaySynchronizer,
        OlcsRasterSynchronizer
      };
  }).catch(error => {
    console.error('error: ', error);
  });
};

GisView.prototype._initGlobeEvents = function() {
  this._olCesium.getOlMap().addEventListener('pointermove', this._globeMapPointerMoveEvent);
  this._olCesium.container_.addEventListener('pointermove', this._globePointerMoveEvent);
  this._olCesium.container_.addEventListener('click', this._globeClickEvent);
};

GisView.prototype._removeGlobeEvents = function() {
  this._olCesium.getOlMap().removeEventListener('pointermove', this._globeMapPointerMoveEvent);
  this._olCesium.container_.removeEventListener('pointermove', this._globePointerMoveEvent);
  this._olCesium.container_.removeEventListener('click', this._globeClickEvent);
};

GisView.prototype._globeMapPointerMoveEvent = function() {
  this._hasGlobePointerMoved = true;
};

GisView.prototype._globePointerMoveEvent = function(evt) {
  const pixel = this._map.getEventPixel(evt);
  const position = this._map.getCoordinateFromPixel(pixel);
  this.eventsBus.trigger('change:coord', {
    coordSyst: this.coordSyst,
    coord: position
  });
};

GisView.prototype._globeClickEvent = function(evt) {
  if(this._hasGlobePointerMoved) {
    this._hasGlobePointerMoved = false;
    return;
  }
  const pixel = this._map.getEventPixel(evt);
  evt.coordinate = this._map.getCoordinateFromPixel(pixel);
  const displayClickPoint = this._getAllLayerInformations(evt, this._gfiModeManager._serviceUrl, this._gfiModeManager);
  this.deleteGfiMarker();

  if (displayClickPoint) {
    const point = new Feature({
      type: 'icon',
      geometry: new Point(evt.coordinate)
    });
    point.setStyle(
      discussStyles.rawPoint('rgba(255,0,0,0.3)', 'rgba(255,0,0,1)', 2, 5)
    );
    this.featureSource.addFeature(point);
  }
};
