var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"contact-menu\" class=\"contact-menu\">\n  <button id=\"close-contact-menu\" type=\"button\" class=\"close close-left-menu\" data-i18n=\"[aria-label]menu.button.close\">×</button>\n  <div class=\"contact-item-div\">\n    <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"contact") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\" target=\"_blank\" data-i18n=\"menu.infonaut.label\" class=\"contact-item\"></a>\n    <a id=\"help_infonaut\" target=\"_blank\" class=\"anchor-help-link\"\n       data-i18n=\"[title]header.links.help;[aria-label]header.links.help\"><i class=\"far fa-question-circle\"></i></a>\n  </div>\n  <div class=\"contact-mail-item-div\">\n    <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"contact") : depth0)) != null ? lookupProperty(stack1,"email") : stack1), depth0))
    + "\" id=\"contact\" class=\"contact-item\" data-i18n=\"header.links.contact\"></a>\n  </div>\n</div>\n\n";
},"useData":true});