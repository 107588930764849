import { SC_DRAWING_MODULE } from '../utils/constants';

const _ = require('underscore');
const $ = require('jquery');
const ShomView = require('../core/shom-view');
const LayerView = require('./displayed-layer.view');
const OceaLayerView = require('./displayed-ocea-layer.view');
const OceanoLayerUtils = require('../utils/oceano/oceano-layer.js');
const GlobalDataStore = require('../core/global-data-store');

module.exports = ShomView.build({

  tagName: 'ul',
  className: 'list-unstyled',
  id: 'list-layers-on-map',

  initialize(options = {}) {
    this._config = options.config || window.CONFIG;
    this._includedInMap = options.includedInMap === true;
    this._itemViews = [];
    this._oceanoItems = [];
    this._oceanoItemViews = [];
    this.collection = this.collection || window.LAYERS;
    this._gfiModeManager = options.poiModeManager || window.POI_MODE_MANAGER;
    this._gisView = options.gisView || window.GISVIEW;
    this._anyRequestableLayer = false;
    this._currentState = 'default';
    this._gisView = options.gisView || window.GISVIEW;

    this._initListener();
  },

  _initListener() {
    this.listenTo(this.collection, 'sort', _.bind(this.render, this));
  },

  render() {
    // reset previous render
    this.$el.html('');

    this._removeItemViews();
    this._createItemViews();
    if (this._itemViews.length > 0) {
      this._renderItemViews();
    }

    this.from = 0;
    $('.list-unstyled').sortable({
      start: (evt, ui) => {
        const layerToMove = this._refreshDisplayedLayers(ui);
        this.from = layerToMove.indexToMove;
      },
      update: (event, ui) => {
        const layerToMove = this._refreshDisplayedLayers(ui);
        const indexDiff = layerToMove.indexToMove - this.from;
        if (this.from < layerToMove.indexToMove) {
          this._gisView.moveLayerUp(indexDiff, layerToMove.layer);
        } else {
          this._gisView.moveLayerDown(indexDiff, layerToMove.layer);
        }
      }
    });
    $('.list-unstyled').disableSelection();
    return this;
  },

  _getLayerFromIdentifier(identifier, displayedLayer) {
    const layer = displayedLayer.filter(element => element.get('identifier') === identifier);
    return layer[0];
  },

  /**
   * Return the layer and the index where it should be placed after dragging
   * @param ui the ui jQuery ui element
   * @returns {{layer: *, indexToMove: *}}
   * @private
   */
  _refreshDisplayedLayers(ui) {
    // Get the id(layer identifier) of the dragged element
    const draggedElementIdentifier = ui.item[0].id;

    // Get the new order of the layer list
    const elements = Object.values($('#list-layers-on-map').children('li'));
    const filteredElementsId = elements.filter(el => el.id !== undefined && el.id !== '');
    const tabIdElementReordered = filteredElementsId.map(element => element.id);

    // Get the position of the layer dragged
    const indexDraggedElement = tabIdElementReordered.indexOf(draggedElementIdentifier);

    // Get the displayed layers
    const displayedLayers = this.collection.where({
      includedInMap: this._includedInMap
    });

    // Get the displayed layers position
    const tabIndexLayerOnMap = [];
    displayedLayers.forEach(layerDisplayed => {
      tabIndexLayerOnMap.push(layerDisplayed.get('index'));
    });

    const indexToMove = tabIndexLayerOnMap[indexDraggedElement];
    const layer = this._getLayerFromIdentifier(draggedElementIdentifier, displayedLayers);

    return { indexToMove, layer };
  },

  _removeItemViews() {
    _.each(this._itemViews, itemView => {
      itemView.remove();
    });
    this._itemViews = [];
    this._oceanoItems.forEach(e => {
      this.stopListening(e, 'change:showPaletteOnMap');
    });
    this._oceanoItemViews.forEach(e => {
      this.stopListening(e, 'displayedViewToolsState');
    });
    this._oceanoItems = [];
    this._oceanoItemViews = [];
  },

  _createItemViews() {
    let displayedLayers = this.collection.where({
      includedInMap: this._includedInMap
    });

    // remove oceanogramme layers from displayed layer in right list
    displayedLayers = this._filterRemoveOceanogrammeLayers(displayedLayers);

    this._oceanoItems = _.filter(displayedLayers, e => e.get('layerType') === 'NCWMS');
    this._oceanoItemViews = [];

    _.each(displayedLayers, (displayedLayer, index) => {
      let itemView;
      if (displayedLayer.get('layerType') === 'NCWMS') {
        itemView = new OceaLayerView({
          parentView: this,
          model: displayedLayer,
          first: index === 0,
          last: index === displayedLayers.length - 1,
          index
        });
        this._oceanoItemViews.push(itemView);
      } else {
        itemView = new LayerView({
          parentView: this,
          model: displayedLayer,
          first: index === 0,
          last: index === displayedLayers.length - 1,
          index
        });
      }
      this._itemViews.push(itemView);
    }, this);

    this._itemViews.sort((a, b) => ((a.index > b.index) ? 1 : -1));

    this._oceanoItemViews.forEach(e => {
      this.listenTo(e, 'displayedViewToolsState', this._onDisplayedViewToolsStateChange.bind(this));
    });

    this._oceanoItems.forEach(e => {
      this.listenTo(e, 'change:showPaletteOnMap', this._showHideOnMapNcWMSLegend);
    });
    this._setRequestableLayer(displayedLayers);
  },

  _filterRemoveOceanogrammeLayers(displayedLayers) {
    return _.filter(displayedLayers, l => !this._gisView.isOceanogrammeLayer(l));
  },

  _showHideOnMapNcWMSLegend(layer, value) {
    if (value === true) {
      const displayed = _.filter(this._oceanoItems, l => l.get('showPaletteOnMap') === true);
      displayed.forEach(l => {
        if (l !== layer) {
          l.set('showPaletteOnMap', false);
        }
      });
    }
  },

  _renderItemViews() {
    _.each(this._itemViews, itemView => {
      this.$el.append(itemView.render().$el);

      // restore oceano layers context if it exists
      if (itemView.getLayerType() === 'oceano') {
        const params = OceanoLayerUtils.getSelectParamsFromGroupLayer(itemView.model);
        itemView.setValuesAndRender(params);
      }
    });
  },

  _onDisplayedViewToolsStateChange(event) {
    _.each(this._itemViews, itemView => {
      if (event.layerIndex === itemView.index) {
        return;
      }
      itemView.resetToolsState();
    });
  },

  onClose() {
    _.each(this._itemViews, itemView => {
      itemView.remove();
    });
  },

  _setRequestableLayer(displayedLayers) {
    const requestable = this._gfiModeManager.isLayerRequestable(displayedLayers);
    if (requestable) {
      this._anyRequestableLayer = true;
    }
    this.changeState('featureInfo');
  },

  /** ***************************** */
  /** ****** STATE FUNCTION ******* */
  /** ***************************** */

  changeState(newState) {
    this.stopCurrentState();
    this._currentState = newState;
    if (newState === 'featureInfo' && !GlobalDataStore.get(SC_DRAWING_MODULE)) {
      this._gfiModeManager && this._gfiModeManager.start(this._anyRequestableLayer);
    }
  },

  stopCurrentState() {
    if (this._currentState === 'default') {
      return;
    }

    if (this._currentState === 'featureInfo') {
      this._gfiModeManager && this._gfiModeManager.stop();
    }
    this._currentState = 'default';
  }

});
