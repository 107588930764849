var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form action=\"\">\n\n    <div class=\"row atlas-transect-anim\">\n        <h5><span data-i18n=\"oceano.nav.transect.animation.beginmoment\"></span></h5>\n        <div class=\"row first-moment-container\">\n            <div class=\"select-wrapper atlas-select-moment\">\n                <select class=\"first-moment-select ocean-select\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"availableMoments") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":10,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n                <i class=\"fas fa-chevron-down\"></i>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row atlas-transect-anim\">\n        <h5><span data-i18n=\"oceano.nav.transect.animation.endmoment\"></span></h5>\n        <div class=\"row last-moment-container\">\n            <div class=\"select-wrapper atlas-select-moment\">\n                <select class=\"last-moment-select ocean-select\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"availableMoments") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":24,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n                <i class=\"fas fa-chevron-down\"></i>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row atlas-transect-anim\">\n        <h5><span data-i18n=\"oceano.nav.transect.animation.coeff\"></span></h5>\n        <div class=\"row atlas-coeff\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"coeff") || (depth0 != null ? lookupProperty(depth0,"coeff") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"coeff","hash":{},"data":data,"loc":{"start":{"line":33,"column":37},"end":{"line":33,"column":46}}}) : helper)))
    + "</div>\n    </div>\n\n    <div class=\"row\"><h5 data-i18n=\"oceano.nav.animation.control.framerate.title\"></h5></div>\n    <div class=\"row\">\n        <div class=\"transect-slider-animation col-sm-5\"></div>\n        <div class=\"col-sm-2\">\n            <span class=\"transect-slider-animation-value\">0</span>\n            <span class=\"ips\" data-i18n=\"oceano.nav.animation.control.framerate.dimension\"></span>\n        </div>\n\n        <div class=\"col-sm-4 text-right\">\n            <button type=\"submit\" class=\"btn btn-primary transect-animation-button\" data-i18n=\"oceano.nav.transect.animation.loadAnimation\"></button>\n        </div>\n    </div>\n\n    <div class=\"row\" style=\"display: none\">\n        <div>\n            <ul class=\"errors\">\n                <li data-i18n=\"oceano.nav.transect.animation.badDates\"></li>\n            </ul>\n        </div>\n\n    </div>\n\n</form>\n\n<div class=\"text-center\">\n    <img id=\"transect-animation\" style=\"display: none;\">\n    <p><small data-i18n=\"oceano.nav.transect.animation.atlasnote\"></small></p>\n</div>\n";
},"useData":true});