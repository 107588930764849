const _ = require('underscore');

const NavigationHistory = function (options) {
  const optionsToUse = options || {};

  this._previous = [];
  this._current = optionsToUse.init;
  this._next = [];
  this._skipUpdate = false;
};

NavigationHistory.prototype.createNewPosition = function (pos) {
  if (!this._skipUpdate) {
    this._previous.push(_.clone(this._current));
    this._current = _.clone(pos);
    this._next = [];
  }
  this._skipUpdate = false;
};

NavigationHistory.prototype.getPrevious = function () {
  if (this._previous.length === 0) {
    return this.current;
  }
  this._next.unshift(_.clone(this._current));
  this._current = this._previous.pop();

  this._skipUpdate = true;

  return this._current;
};

NavigationHistory.prototype.getNext = function () {
  if (this._next.length === 0) {
    return this._current;
  }
  this._previous.push(_.clone(this._current));
  this._current = this._next.shift();

  this._skipUpdate = true;

  return this._current;
};

module.exports = NavigationHistory;
