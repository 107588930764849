import WMSCapabilitiesFormat from 'ol/format/WMSCapabilities';

import WMTSCapabilitiesFormat from 'ol/format/WMTSCapabilities';
import TileLayer from 'ol/layer/Tile';
import { default as WMTSSource, optionsFromCapabilities } from 'ol/source/WMTS';
import WMC from '../mixins/openlayers/wmc';
import WfsJsonMapping from './wfs-jsonix-mapping.js';
import { SEPARATOR_CATEGORY_LEVEL01 } from './constants';

const _ = require('underscore');
const Layers = require('../collection/layers');
const LayersFactory = require('./gis/capabilities-layers-factory');
const urlHelper = require('./url-helper');
const CategoryUtil = require('./category');
const Cookies = require('./cookies.js');

const getLayerWmcDetails = function (identifier, wmc) {
  return _.findWhere(wmc.layersContext, {
    name: identifier
  });
};

const getLayersFromWMTSCapabilities = function (result, instances, layerConf) {
  try {
    if (layerConf.TileMatrixSetLink[0].TileMatrixSet !== '3857') {
      return;
    }
    const options = optionsFromCapabilities(
      result,
      {
        layer: layerConf.Identifier,
        matrixSet: '3857'
      }
    );
    // we have to put anonymous to print map but we need authentification for private layers, so we replace key with session
    options.crossOrigin = 'anonymous';
    if (Cookies.getItem('sessid')) {
      options.urls = [options.urls[0].replace(Cookies.getItem('userkey'), Cookies.getItem('sessid'))];
    }
    options.wrapX = true;
    const source = new WMTSSource(options);
    this._layersFactory = this._layersFactory || new LayersFactory({ config: window.CONFIG });
    const maxExtent = this._layersFactory.getMaxExtent(layerConf);
    const tile = new TileLayer({
      title: layerConf.Title,
      identifier: layerConf.Identifier,
      opacity: 1,
      maxExtent,
      source
    });
    instances.push(tile);
  } catch (err) {
    console.error(`error when adding a layer (${layerConf.Title}) to catalog : `, err);
  }
};

const GisUtils = function () {

};

GisUtils.readWmcConf = function (wmcDocument) {
  const format = new WMC();
  const wmc = format.read(wmcDocument);

  // Hack for Copernicus layers, that are not in Configuration service nor in delivery.json
  wmc.layersContext.push(
    JSON.parse(JSON.stringify({ ...window.CONFIG.copernicus.layerDescBase, ...window.CONFIG.copernicus.layerDescWaves })),
    JSON.parse(JSON.stringify({ ...window.CONFIG.copernicus.layerDescBase, ...window.CONFIG.copernicus.layerDescTemp })),
    JSON.parse(JSON.stringify({ ...window.CONFIG.copernicus.layerDescBase, ...window.CONFIG.copernicus.layerDescSalinity }))
  );

  return wmc;
};

GisUtils.readGetCap = function (getCapDocument, getCapUrl) {
  let instances = [];
  if (!getCapDocument) {
    return instances;
  }

  let format; let result; let
    version;

  if (getCapDocument.documentElement.tagName === 'WMS_Capabilities') {
    // Instanciate WMS layers
    this._layersFactory = this._layersFactory || new LayersFactory({ config: window.CONFIG });
    format = new WMSCapabilitiesFormat({
      yx: {
        'EPSG:3857': true
      }
    });
    result = format.read(getCapDocument);
    version = getCapDocument.documentElement.attributes.version.value;
    const wmsLayers = this._layersFactory.getRawLayersFromCapabilities(result, 'WMS');
    instances = _.map(wmsLayers, _.bind(function (rawLayer) {
      return this._layersFactory.instanciateLayer(rawLayer, 'WMS', urlHelper.getPathUrl(getCapUrl), version, result);
    }, this));
  } else if (getCapDocument.documentElement.tagName === 'wfs:WFS_Capabilities') {
    // Instanciate WFS layers
    this._layersFactory = this._layersFactory || new LayersFactory({ config: window.CONFIG });

    result = WfsJsonMapping.read(getCapDocument);
    version = getCapDocument.documentElement.attributes.version.value;
    const wfsLayers = this._layersFactory.getRawLayersFromCapabilities(result.value, 'WFS');
    instances = _.map(wfsLayers, _.bind(function (rawLayer) {
      return this._layersFactory.instanciateLayer(rawLayer, 'WFS', urlHelper.getPathUrl(getCapUrl), version, result.value);
    }, this));
  } else {
    // Instanciate WMTS layers
    format = new WMTSCapabilitiesFormat({
      yx: {
        'EPSG:3857': true
      }
    });
    result = format.read(getCapDocument);

    _.each(result.Contents.Layer, getLayersFromWMTSCapabilities.bind(this, result, instances));
  }

  return instances;
};

GisUtils.buildBackboneCollection = function (wmc, layers, catalog, additionalInfos) {
  const collection = new Layers();
  _.each(layers, layer => {
    const identifier = layer.get('identifier') || layer.identifier;
    const extension = getLayerWmcDetails(identifier, wmc);

    if (!extension) {
      console.warn(identifier);
      return;
    }

    const lay = _.extend(extension, {
      identifier,
      olLayer: layer
    });

    lay.category.fullpath = CategoryUtil.computeKey(lay.category.name);
    lay.category.name = _.last(lay.category.fullpath.split(SEPARATOR_CATEGORY_LEVEL01));

    if (catalog && catalog.categories) {
      const layerCategoryOnCatalog = CategoryUtil.findCategoryForLayer(catalog.categories, lay.category.fullpath);
      if (layerCategoryOnCatalog) {
        lay.category.translate = layerCategoryOnCatalog.name;
      }
    }

    if (catalog && catalog.layers) {
      const layerConfig = catalog.layers.find(layerconf => layerconf.id === identifier);
      if (layerConfig) {
        if (layerConfig.index) {
          lay.indexInCat = layerConfig.index;
        }
        if (layerConfig.downloadUrl) {
          lay.downloadUrl = layerConfig.downloadUrl;
        }
      }
    }

    if (additionalInfos && additionalInfos[lay.identifier]) {
      lay.additionalInfo = additionalInfos[lay.identifier];
    }

    collection.add(lay);
  });

  return collection;
};

module.exports = GisUtils;
