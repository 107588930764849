var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"gfi-modal-oceano-data\" id=\"ocea-result-title-option\">\n  <span class=\"gfi-modal-oceano-lon\">\n    <span class=\"gfi-lon-result\"></span>\n    <span id=\"gfi-coord-lat-result-"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":4,"column":35},"end":{"line":4,"column":45}}}) : helper)))
    + "\"></span>\n  </span>\n  <span class=\"gfi-modal-oceano-lat\">\n    <span class=\"gfi-lat-result\"></span>\n    <span id=\"gfi-coord-lng-result-"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":8,"column":35},"end":{"line":8,"column":45}}}) : helper)))
    + "\"></span>\n  </span>\n  <span class=\"gfi-modal-oceano-portref\">\n    <span class=\"gfi-portref-label\"></span>\n    <span id=\"gfi-portref-"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":12,"column":26},"end":{"line":12,"column":36}}}) : helper)))
    + "\"></span>\n  </span>\n  <span class=\"gfi-modal-oceano-realval\">\n    <span class=\"gfi-real-value-label\"></span>\n    <span id=\"gfi-real-value-"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":16,"column":29},"end":{"line":16,"column":39}}}) : helper)))
    + "\"></span>\n  </span>\n  <span class=\"gfi-modal-oceano-ds\">\n    <span class=\"gfi-date-selected-label\">\n    </span><span id=\"gfi-date-selected-"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":20,"column":39},"end":{"line":20,"column":49}}}) : helper)))
    + "\"></span>\n  </span>\n</div>\n";
},"useData":true});